import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';

import { refreshToken, resetUserAndMerchantStore } from '../redux/actions';
import TopNav from '../containers/navs/Topnav/Topnav';
import Sidebar from '../containers/navs/Sidebar/Sidebar';
import { getPermissionIdMenuItems } from './../helpers/Access';

import { AppLayoutProps } from './interface';
import { ROUTE_CHANGE_PASSWORD, ROUTE_FALLBACK_LABEL, ROUTE_SIGNIN } from '../constants/app';
import { isUserAuth } from '../helpers/Access';

class AppLayout extends Component<AppLayoutProps> {
  componentDidMount(): void {
    const {
      location: { pathname },
      refreshTokenAction
    } = this.props;

    const permissionId = getPermissionIdMenuItems(pathname);
    refreshTokenAction(permissionId);
  }

  componentDidUpdate(prevProps: Readonly<AppLayoutProps>): void {
    const {
      location: { pathname },
      refreshTokenAction,
      resetUserAndMerchantStoreAction,
      isAuth,
      loading
    } = this.props;

    if (prevProps.location.pathname !== pathname) {
      const permissionId = getPermissionIdMenuItems(pathname);
      refreshTokenAction(permissionId);
    }
    if (!isAuth && !loading) {
      resetUserAndMerchantStoreAction();
    }
  }

  render(): ReactNode {
    const {
      containerClassnames,
      history,
      children,
      isAuth,
      location,
      shouldChangePassword,
      loading,
      user,
      hasPermissions
    } = this.props;

    if (shouldChangePassword) {
      return <Redirect to={{ pathname: ROUTE_CHANGE_PASSWORD, state: { necessarilyChange: true } }} />;
    }

    if (!user.loading && !user.data) {
      return <Redirect to={`${ROUTE_SIGNIN}?${ROUTE_FALLBACK_LABEL}=${location.pathname}`} />;
    }

    if (isAuth && location.pathname === '/') {
      return <Redirect to="/start-page" />;
    }

    if (isAuth && !loading && !hasPermissions) {
      return <Redirect to="/permission-denied" />;
    }

    return !loading ? (
      <div id="app-container" className={`${containerClassnames} h-100`}>
        <TopNav history={history} />
        <Sidebar />
        <main>
          <div className="container-fluid">{children}</div>
        </main>
      </div>
    ) : null;
  }
}
const mapStateToProps = ({ menu, authUser }) => {
  const {
    loginStatus,
    user: { data, loading, error },
    refresh_token: { has_user_permissions },
    user
  } = authUser;

  return {
    isAuth: isUserAuth(loginStatus, loading, error, data),
    loading,
    user,
    shouldChangePassword: data && data.is_password_expired ? data.is_password_expired : false,
    containerClassnames: menu.containerClassnames,
    hasPermissions: data && has_user_permissions
  };
};

const mapDispatchToProps = {
  resetUserAndMerchantStoreAction: resetUserAndMerchantStore,
  refreshTokenAction: refreshToken
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AppLayout)
);
