import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { SupportEmailLinkProps, SupportEmailLinkState } from '../interface';
import { getSupportEmail } from '../../../../helpers/CustomDesign';

class SupportEmailLink extends Component<SupportEmailLinkProps, SupportEmailLinkState> {
  constructor(props: SupportEmailLinkProps) {
    super(props);
    const { customDesign } = this.props;
    this.state = { email: customDesign.loading ? '' : getSupportEmail(customDesign.data) };
  }

  componentDidUpdate(prevProps: Readonly<SupportEmailLinkProps>, prevState: any, snapshot?: any): void {
    const { customDesign } = this.props;
    if (!isEqual(customDesign.loading, prevProps.customDesign.loading)) {
      this.setState({ email: getSupportEmail(customDesign.data) });
    }
  }

  render(): ReactNode {
    const { email } = this.state;
    return <a href={`mailto:${email}`}>{email}</a>;
  }
}

const mapStateToProps = ({ settings }) => ({ customDesign: settings.customDesign });
export default connect(
  mapStateToProps,
  {}
)(SupportEmailLink);
