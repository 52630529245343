import React, { ChangeEvent, Component, ReactNode } from 'react';
import { Form } from 'formik';
import { Input } from 'reactstrap';

import Message from './Messages/Message';

import { ChatProps, ChatState } from './interface';
import { withTranslation } from 'react-i18next';

class Chat extends Component<ChatProps, ChatState> {
  state: ChatState = {
    uploadFile: undefined,
    message: ''
  };

  sendMessage = (file?: File | undefined) => {
    const { onMessageSend, requestId } = this.props;
    const { message } = this.state;

    (message || file) && onMessageSend(requestId, message, file);
    this.cleanState();
  };

  cleanState = () => {
    this.setState({
      message: '',
      uploadFile: undefined
    });
  };

  render(): ReactNode {
    const { header, messages, t } = this.props;
    const { message } = this.state;

    return (
      <div className="request-chat">
        <div className="sc-chat-window">
          <div className="header">
            <div className="text">{header}</div>
          </div>
          <div className="message-list">
            {messages &&
              messages.map((message, index) => {
                return <Message message={message} key={index} />;
              })}
          </div>

          <div className="text-input">
            <Form className="message-input">
              <Input
                className="input"
                type="textarea"
                name="message"
                value={message}
                placeholder={t('chat.enter_message')}
                onChange={({ currentTarget }: ChangeEvent<HTMLInputElement>) =>
                  this.setState({ message: currentTarget.value })
                }
              />
              <div className="action-icons">
                <input
                  type="file"
                  id="doc_file"
                  name="doc_file"
                  style={{ display: 'none' }}
                  onChange={({ currentTarget }) => {
                    if (currentTarget.files) {
                      this.setState({ uploadFile: currentTarget.files[0] });
                      this.sendMessage(currentTarget.files[0]);
                    }
                  }}
                />
                <label htmlFor="doc_file">
                  <i className="icon file simple-icon-paper-clip" />
                </label>
                <i className="icon send simple-icon-paper-plane" onClick={() => this.sendMessage()} />
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Chat);
