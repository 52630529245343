import React from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
const TopnavEasyAccess = ({ t }) => (
  <div className="position-relative">
    <UncontrolledDropdown className="dropdown-menu-right">
      <DropdownToggle className="header-icon" color="empty">
        <i className="simple-icon-grid" />
      </DropdownToggle>
      <DropdownMenu className="position-absolute mt-3" right>
        <NavLink to="/support">
          <DropdownItem>{t('writeToTechnicalSupport')}</DropdownItem>
        </NavLink>
        <NavLink to="/downloads">
          <DropdownItem>{t('topnav.easyAccess.downloadPage')}</DropdownItem>
        </NavLink>
      </DropdownMenu>
    </UncontrolledDropdown>
  </div>
);

export default TopnavEasyAccess;
