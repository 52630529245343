import React, { Component, ReactNode } from 'react';
import { Button, FormGroup, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { Field, Form, Formik } from 'formik';
import { NavLink } from 'react-router-dom';

import { GOOGLE_PASSWORD_LENGTH, RESTORE_LABELS, RESTORE_TYPES } from './constant';
import { GoogleAuthModalProps } from './interface';
import { Colxx } from '../../../components/common/CustomBootstrap';
import { intFieldValue } from '../../operations/helpers';
import setInputFocus from './helper';
import SupportEmailLink from '../../settings/CustomDesign/Views/SupportEmailLink';
import { withTranslation } from 'react-i18next';

class GoogleAuthModal extends Component<GoogleAuthModalProps> {
  constructor(props: GoogleAuthModalProps) {
    super(props);
    this.codeInput = React.createRef();
  }

  private codeInput: any;

  componentDidUpdate(prevProps: Readonly<GoogleAuthModalProps>, prevState: Readonly<{}>, snapshot?: any): void {
    setInputFocus(this.codeInput);
  }

  handleSubmit = ({ googlePassword }) => {
    const { googleAuthAction, onSubmit } = this.props;
    if (onSubmit) {
      onSubmit(googlePassword);
    } else {
      googleAuthAction(googlePassword);
    }
  };

  skipTwoFactorAuth = (): void => {
    localStorage.setItem('tfs', '');
  };

  validateGooglePassword = (value: string): string | undefined => {
    const { t } = this.props;

    let error;
    if (!value) {
      error = t('tooltip.enter_key');
    } else if (value.length < GOOGLE_PASSWORD_LENGTH || value.length > GOOGLE_PASSWORD_LENGTH) {
      error = t('tooltip.key_length');
    }
    return error;
  };

  render(): ReactNode {
    const { isShowForgotLink, isOpen, cancellation, loading, t } = this.props;

    return (
      <Modal isOpen={isOpen} toggle={cancellation}>
        <ModalHeader>{t('google_2fa_login')}</ModalHeader>
        <ModalBody>
          <Formik initialValues={{ googlePassword: '' }} onSubmit={this.handleSubmit}>
            {({ touched, errors, setFieldValue }) => (
              <Form>
                <FormGroup className="form-group has-float-label">
                  <Label className="float-label">{t('key')}</Label>
                  <Field
                    className="form-control"
                    type="text"
                    name="googlePassword"
                    validate={this.validateGooglePassword}
                    onChange={({ target: { value } }) => intFieldValue(value, 'googlePassword', setFieldValue)}
                    tabIndex="0"
                    innerRef={(node) => {
                      this.codeInput = node;
                    }}
                  />
                  {errors.googlePassword && touched.googlePassword && (
                    <div className="invalid-feedback d-block">{errors.googlePassword}</div>
                  )}
                </FormGroup>
                <Row>
                  <Colxx xxs="12" className="d-flex justify-content-end">
                    <Button
                      type="submit"
                      color="primary"
                      disabled={loading}
                      className={`btn-multiple-state ${loading ? 'show-spinner' : ''}`}
                    >
                      <span className="spinner d-inline-block">
                        <span className="bounce1" />
                        <span className="bounce2" />
                        <span className="bounce3" />
                      </span>
                      <span className="label">{t('button.Sign In')}</span>
                    </Button>
                  </Colxx>
                </Row>
              </Form>
            )}
          </Formik>
          {isShowForgotLink ? (
            <div className="d-block">
              <NavLink
                to={{
                  pathname: '/user/forgot-password',
                  state: { restoreType: RESTORE_TYPES.Restore2FA }
                }}
              >
                {RESTORE_LABELS(t)[RESTORE_TYPES.Restore2FA].text}
              </NavLink>
            </div>
          ) : (
            <div className="mt-3">
              <div className="w-60 text-center m-auto">
                {t('second_factor.problems')} <SupportEmailLink />
                <br />
                <NavLink
                  onClick={this.skipTwoFactorAuth}
                  to={{
                    pathname: '/'
                  }}
                >
                  {t('button.link')}
                </NavLink>
              </div>
            </div>
          )}
        </ModalBody>
      </Modal>
    );
  }
}

export default withTranslation()(GoogleAuthModal);
