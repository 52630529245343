import React from 'react';
import { CellInfo } from 'react-table';
import { TreeNode } from 'react-dropdown-tree-select';
import format from '../../localization';

import { ALL_TREE, DATE_FORMAT_BASIC } from '../../constants/app';
import { createQueryString } from '../../helpers/Utils';
import { CONFIG } from '../../config/appConfig';

export const getDocumentsTableColumns = (serviceType: number, t: any) => [
  {
    Header: t('documents.document_id'),
    accessor: 'journal_id'
  },
  {
    Header: t('documents.number'),
    accessor: 'contract_number'
  },
  {
    Header: t('documents.period'),
    accessor: 'date_begin',
    show: false
  },
  {
    Header: t('documents.period'),
    accessor: 'date_begin',
    Cell: ({ value, original }: CellInfo) =>
      `${format(value, DATE_FORMAT_BASIC)} - ${format(original.date_end, DATE_FORMAT_BASIC)}`
  },
  {
    Header: t('documents.load_link'),
    accessor: 'journal_id',
    Cell: ({ value, original }: CellInfo) => (
      <a
        href={`${CONFIG.apiURL()}/statements/${value}/?${createQueryString({
          service_type: serviceType,
          hash: original.doc_hash
        })}`}
        className="btn pl-0"
        download={true}
      >
        <i className="simple-icon-cloud-download left-text-icon" />
      </a>
    )
  }
];

export const getDocumentTreeData = (options, t: any): TreeNode[] => {
  if (options && options.length) {
    return [
      {
        ...ALL_TREE(t),
        children: [
          ...options.map(({ label, value }, index) => ({
            key: `${label}_${index}`,
            label,
            value
          }))
        ]
      }
    ];
  } else {
    return [ALL_TREE(t)];
  }
};
