import { all, call, fork, put, takeLatest, SimpleEffect } from 'redux-saga/effects';
import { CombinatorEffect } from '@redux-saga/types';

import { ApiRequest, ContentType } from '../../helpers/ApiRequest/ApiRequest';
import { createQueryString } from '../../helpers/Utils';

import {
  GET_SUMMARY_STATISTICS,
  getSummaryStatisticsSuccess,
  getSummaryStatisticsError,
  GET_CUSTOMER_STATISTICS,
  getCustomerStatisticsSuccess,
  getCustomerStatisticsError,
  GET_CHARTS,
  getChartsDataSuccess,
  getChartsDataError,
  GET_CONVERSION_CHART,
  getConversionChartDataSuccess,
  getConversionChartDataError,
  getPeriodSummaryStatisticSuccess,
  getPeriodSummaryStatisticError,
  GET_PERIOD_SUMMARY_STATISTICS,
  GET_PERIOD_SUMMARY_STATISTICS_FILE,
  getPeriodSummaryStatisticFileError,
  getPeriodSummaryStatisticFileSuccess
} from '../actions';
import {
  GetChartsAction,
  GetSummaryStatisticsAction,
  GetCustomerStatisticsAction,
  GetConversionChartAction,
  GetPeriodSummaryStatistic,
  GetPeriodSummaryStatisticFile
} from './interface';

// GET SUMMARY STATISTICS
export function* getSummaryStatistics({
  payload
}: GetSummaryStatisticsAction): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { userId, showRefund, ...queryParams } = payload;

    const url = userId ? `/users/${userId}/account-operations/stats/` : '/user/account-operations/stats/';
    const { data, error } = yield call(
      ApiRequest.post,
      url,
      createQueryString(queryParams),
      {},
      ContentType.ApplicationFormUrlencoded
    );

    if (!error) {
      yield put(getSummaryStatisticsSuccess(data[0], showRefund));
    } else {
      yield put(getSummaryStatisticsError(`${error}`));
    }
  } catch (error) {
    yield put(getSummaryStatisticsError(`${error}`));
  }
}

// GET CUSTOMER STATISTICS
export function* getCustomerStatistics({
  payload
}: GetCustomerStatisticsAction): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { userId, ...queryParams } = payload;

    const url = userId
      ? `/users/${userId}/account-operations/country-stats/`
      : '/user/account-operations/country-stats/';

    const { data, error } = yield call(
      ApiRequest.post,
      url,
      createQueryString(queryParams),
      {},
      ContentType.ApplicationFormUrlencoded
    );

    if (error) {
      yield put(getCustomerStatisticsError(`${error}`));
    } else {
      yield put(getCustomerStatisticsSuccess(data));
    }
  } catch (error) {
    yield put(getCustomerStatisticsError(`${error}`));
  }
}

// GET CHARTS
export function* getCharts({ payload }: GetChartsAction): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { userId, showRefund, ...queryParams } = payload;

    const url = userId ? `/users/${userId}/account-operations/graph/` : '/user/account-operations/graph/';

    const { data, error } = yield call(
      ApiRequest.post,
      url,
      createQueryString(queryParams),
      {},
      ContentType.ApplicationFormUrlencoded
    );

    if (error) {
      yield put(getChartsDataError(`${error}`));
    } else {
      yield put(getChartsDataSuccess(data, payload.period, showRefund));
    }
  } catch (error) {
    yield put(getChartsDataError(`${error}`));
  }
}

// GET CONVERSION CHART
export function* getConversionChart({
  payload
}: GetConversionChartAction): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { userId, ...queryParams } = payload;

    const url = userId ? `/users/${userId}/account-operations/status-graph/` : '/user/account-operations/status-graph/';

    const { data, error } = yield call(
      ApiRequest.post,
      url,
      createQueryString(queryParams),
      {},
      ContentType.ApplicationFormUrlencoded
    );

    if (error) {
      yield put(getConversionChartDataError(`${error}`));
    } else {
      yield put(getConversionChartDataSuccess(data, payload.period, payload.status));
    }
  } catch (error) {
    yield put(getConversionChartDataError(`${error}`));
  }
}

// GET CONVERSION CHART
export function* getPeriodSummaryStatistic({
  payload
}: GetPeriodSummaryStatistic): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { userId, ...queryParams } = payload;

    const url = userId
      ? `/users/${userId}/account-operations/account-graph/`
      : '/user/account-operations/account-graph/';

    const { data, error } = yield call(
      ApiRequest.post,
      url,
      createQueryString(queryParams),
      {},
      ContentType.ApplicationFormUrlencoded
    );

    if (error) {
      yield put(getPeriodSummaryStatisticError(`${error}`));
    } else {
      yield put(getPeriodSummaryStatisticSuccess(data, payload.currency, payload.period, payload.byAccount));
    }
  } catch (error) {
    yield put(getPeriodSummaryStatisticError(`${error}`));
  }
}

// GET CONVERSION CHART CSV
export function* getPeriodSummaryStatisticFile({
  payload
}: GetPeriodSummaryStatisticFile): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { userId, ...queryParams } = payload;

    const url = userId
      ? `/users/${userId}/account-operations/account-graph/csv/`
      : '/user/account-operations/account-graph/csv/';

    const { id, error } = yield call(
      ApiRequest.post,
      url,
      createQueryString(queryParams),
      {},
      ContentType.ApplicationFormUrlencoded
    );

    if (error) {
      yield put(getPeriodSummaryStatisticFileError(`${error}`));
    } else {
      yield put(getPeriodSummaryStatisticFileSuccess(id));
    }
  } catch (error) {
    yield put(getPeriodSummaryStatisticFileError(`${error}`));
  }
}

// WATCHERS
export function* watchGetSummaryStatistics(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(GET_SUMMARY_STATISTICS, getSummaryStatistics);
}

export function* watchGetCharts(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(GET_CHARTS, getCharts);
}

export function* watchGetConversionChart(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(GET_CONVERSION_CHART, getConversionChart);
}

export function* watchGetCustomerStatistics(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(GET_CUSTOMER_STATISTICS, getCustomerStatistics);
}

export function* watchGetPeriodSummaryStatistic(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(GET_PERIOD_SUMMARY_STATISTICS, getPeriodSummaryStatistic);
}

export function* watchGetPeriodSummaryStatisticFile(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(GET_PERIOD_SUMMARY_STATISTICS_FILE, getPeriodSummaryStatisticFile);
}

export default function* rootSaga(): Generator<CombinatorEffect<string, any>, any, any> {
  yield all([
    fork(watchGetSummaryStatistics),
    fork(watchGetCustomerStatistics),
    fork(watchGetConversionChart),
    fork(watchGetPeriodSummaryStatistic),
    fork(watchGetCharts),
    fork(watchGetPeriodSummaryStatisticFile)
  ]);
}
