import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LocalStorageBackend from 'i18next-localstorage-backend';
import Backend from "i18next-chained-backend";
import HttpApi from "i18next-http-backend";
import LanguageDetector from 'i18next-browser-languagedetector';
import { CONFIG } from './config/appConfig';
import { languageStorageKey } from './constants/defaultValues';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      backends: [
        LocalStorageBackend,
        HttpApi
      ],
      backendOptions: [{
        prefix: 'i18next_res_',
        expirationTime: 60*60*24,
      }, {
        fallbackLng: 'ru',
        whitelist: ['ru', 'en'],
        detection: {lookupLocalStorage: languageStorageKey},
        react: { 
          useSuspense: false
        },
        keySeparator: false,
        loadPath: `${CONFIG.apiURL()}/translation/?lang={{lng}}`,
        addPath: `${CONFIG.apiURL()}/translation/?lang={{lng}}`,
      }]
    }
  });

export default i18n;