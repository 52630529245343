import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { BrowserBarProps, BrowserBarState } from '../interface';
import { DEFAULT_FAVICON, DEFAULT_TITLE, getImage } from '../../../../helpers/CustomDesign';
import { withTranslation } from 'react-i18next';
import i18n from './../../../../i18n';
const { t } = i18n;
class BrowserBar extends Component<BrowserBarProps, BrowserBarState> {
  constructor(props: BrowserBarProps) {
    super(props);
    this.state = {
      favicon: this.getFavicon(),
      title: this.getTitle()
    };
  }

  componentDidUpdate(prevProps: Readonly<BrowserBarProps>, prevState: Readonly<BrowserBarState>, snapshot?: any): void {
    const { designCurrentConfig } = this.props;
    if (!isEqual(designCurrentConfig, prevProps.designCurrentConfig)) {
      this.setState({
        favicon: this.getFavicon(),
        title: this.getTitle()
      });
    }
  }

  getTitle = (): string => {
    const { designCurrentConfig } = this.props;
    if (designCurrentConfig === null) {
      return '';
    }
    if (designCurrentConfig.settings && 'tabTitle' in designCurrentConfig.settings) {
      return designCurrentConfig.settings.tabTitle || t(DEFAULT_TITLE);
    }
    return t(DEFAULT_TITLE);
  };

  getFavicon = (): string => {
    const { designCurrentConfig } = this.props;
    if (designCurrentConfig === null) {
      return '';
    }
    return getImage('favicon', designCurrentConfig) || DEFAULT_FAVICON;
  };

  render(): ReactNode {
    const { favicon, title } = this.state;
    const { hint } = this.props;
    return (
      <div className="browser-frame">
        <div className="tabs-bar">
          <div className="tab">
            <img src={favicon} alt="" className="favicon" width="20px" height="20px" />
            <div className="title">{title}</div>
          </div>
          {hint && <div className="number-hint">1</div>}
        </div>
        <div className="address-bar">
          <div className="control-buttons">
            <svg viewBox="0 0 16 16">
              <path d="M16,7H3.8l5.6-5.6L8,0L0,8l8,8l1.4-1.4L3.8,9H16V7z" />
            </svg>
            <svg viewBox="0 0 16 16">
              <path d="M8,0L6.6,1.4L12.2,7H0v2h12.2l-5.6,5.6L8,16l8-8L8,0z" />
            </svg>
            <svg viewBox="0 0 16 16">
              <path
                d="M13.6,2.3C12.2,0.9,10.2,0,8,0C3.6,0,0,3.6,0,8s3.6,8,8,8c3.7,0,6.8-2.5,7.7-6h-2.1c-0.8,2.3-3,4-5.6,4c-3.3,0-6-2.7-6-6
s2.7-6,6-6c1.7,0,3.1,0.7,4.2,1.8L9,7h7V0L13.6,2.3z"
              />
            </svg>
          </div>
          <div className="address-line" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => ({ customDesignCurrent: settings.customDesign });

export default withTranslation()(
  connect(
    mapStateToProps,
    {}
  )(BrowserBar)
);
