import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './assets/css/vendor/bootstrap.min.css';
import './assets/css/vendor/bootstrap.rtl.only.min.css';
import 'react-circular-progressbar/dist/styles.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-table/react-table.css';
import './i18n';

import {
  isMultiColorActive,
  defaultColor,
  themeColorStorageKey,
  isDarkSwitchActive,
  languageStorageKey,
  defaultLanguage
} from './constants/defaultValues';

const color: any =
  (isMultiColorActive || isDarkSwitchActive) && localStorage.getItem(themeColorStorageKey)
    ? localStorage.getItem(themeColorStorageKey)
    : defaultColor;

localStorage.setItem(themeColorStorageKey, color);

const language: any = localStorage.getItem(languageStorageKey)
  ? localStorage.getItem(languageStorageKey)
  : defaultLanguage;

localStorage.setItem(languageStorageKey, language);

const render = () => {
  import(`./assets/css/sass/themes/gogo.${color}.scss`).then((x) => {
    require('./AppRenderer');
  });
};

render();
