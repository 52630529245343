import React, { Component, ReactNode } from 'react';
import { Row } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import i18n from '../../i18n';

export default class SectionInDevelopment extends Component {
  render(): ReactNode {
    return (
      <Row>
        <Colxx xxs="12">
          <h1>{i18n.t('in_development')}</h1>
        </Colxx>
      </Row>
    );
  }
}
