import React, { Component, ReactNode } from 'react';
import { ColorPickerProps } from './interface';
import { FormGroup, Label } from 'reactstrap';
import { Field } from 'formik';
import { COLOR_PLACEHOLDER } from './constants';
import WithClearButton from '../WithClearButton';
import { withTranslation } from 'react-i18next';

class ColorPicker extends Component<ColorPickerProps, any> {
  constructor(props) {
    super(props);
    this.setColor = this.setColor.bind(this);
  }

  setColor(event: React.ChangeEvent<HTMLInputElement>): void {
    this.props.setValue(this.props.name, event.target.value);
  }

  render(): ReactNode {
    const { value, name, onClear, t } = this.props;
    return (
      <div className="color-picker my-2">
        <FormGroup className="has-float-label">
          <Label className="float-label">
            <div className="label-text">{t(`customDesign.${name}`)}</div>
          </Label>
          <div className="d-flex">
            <Field
              className="form-control"
              name={`${name}-color-input`}
              type="color"
              value={value || COLOR_PLACEHOLDER}
              onChange={this.setColor}
            />
            <WithClearButton onClear={onClear || (() => void 0)}>
              <Field
                className="form-control"
                name={`${name}-color-picker`}
                type="text"
                placeholder="#cccccc"
                value={value || ''}
                onChange={this.setColor}
              />
            </WithClearButton>
          </div>
        </FormGroup>
      </div>
    );
  }
}

export default withTranslation()(ColorPicker);
