import { FormikValues } from 'formik';
import { RouteComponentProps } from 'react-router';
import { WithTranslation } from 'react-i18next';

export interface FilterProps extends FormikValues {
  isAcquiring?: boolean;
}

export interface IPayoutsProps extends RouteComponentProps, WithTranslation {
  user: any;
}
export interface IPayoutsState {
  menuItems: any[];
}

export interface ConfirmationModalProps {
  onInit: () => void;
  onCloseModal: () => void;
  onTimeIsOver: () => void;
  onAttemptsIsOver: () => void;
  onSubmit: (code: string) => void;
  modalIsOpen: boolean;
  userPhone: string;
  counter: number;
  attempts: number;
  init: boolean;
  confirmationLabel?: string | null;
}

export interface ConfirmationModalState {
  code: string;
  seconds: number;
  minutes: number;
}

export const MAX_PAYOUT_CONFIRMATION_ATTEMPTS = 3;

export interface ResultModalProps {
  onCloseModal: () => void;
  modalIsOpen: boolean;
  payoutId: number;
  modalType: number;
  confirmationLabel?: string | null;
}

export enum PayoutsModalWindows {
  Confirmation,
  Result,
  CancelConfirmation
}

export enum ResultModalTypes {
  Success,
  CodeInvalid,
  CodeExpired,
  Canceled,
  Error
}
