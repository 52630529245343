import { History } from 'history';

import { UserInformation } from '../settings/interface';
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  LOGIN_USER_ERROR,
  GOOGLE_AUTH,
  GOOGLE_AUTH_SUCCESS,
  GOOGLE_AUTH_ERROR,
  GET_GOOGLE_QR,
  GET_GOOGLE_QR_SUCCESS,
  GET_GOOGLE_QR_ERROR,
  GET_TEMPORARY_PASSWORD,
  GET_TEMPORARY_PASSWORD_SUCCESS,
  GET_TEMPORARY_PASSWORD_ERROR,
  CHECK_TMP_CODE,
  CHECK_TMP_CODE_SUCCESS,
  CHECK_TMP_CODE_ERROR,
  LOGOUT_USER_SUCCESS,
  SMS_AUTH,
  SMS_AUTH_SUCCESS,
  SMS_AUTH_ERROR,
  HANDLE_NON_AUTHORIZED,
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
  SECOND_FACTOR_CANCELLATION,
  GET_GOOGLE_CODE,
  GET_GOOGLE_CODE_SUCCESS,
  GET_GOOGLE_CODE_ERROR,
  REFRESH_TOKEN,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_ERROR,
  RESTORE_2FA,
  RESTORE_2FA_SUCCESS,
  RESTORE_2FA_ERROR
} from '../actions';

import {
  LoginActionResult,
  LoginActionSuccess,
  LoginCredentials,
  LogoutActionResult,
  LoginActionError,
  GetTmpPassActionResult,
  GetTmpPassActionSuccess,
  GetTmpPassActionError,
  ICheckTmpCode,
  ICheckTmpCodeSuccess,
  ICheckTmpCodeError,
  LogoutActionSuccess,
  GoogleAuthAction,
  GoogleAuthSuccessAction,
  GoogleAuthErrorAction,
  SmsAuthAction,
  SmsAuthSuccessAction,
  SmsAuthErrorAction,
  GetGoogleQrAction,
  GetGoogleQrSuccessAction,
  GetGoogleQrErrorAction,
  GetUserAction,
  GetUserSuccess,
  GetUserError,
  HandleNonAuthorizedAction,
  SecondFactorCancellation,
  GetGoogleCodeErrorAction,
  GetGoogleCodeSuccessAction,
  GetGoogleCodeAction,
  Restore2FA,
  Restore2FASuccess,
  Restore2FAError,
  RefreshTokenSuccess,
  RefreshTokenError,
  RefreshTokenData
} from './interface';

// LOGIN
export const loginUser = (user: LoginCredentials, history: History): LoginActionResult => ({
  type: LOGIN_USER,
  payload: { user, history }
});

export const loginUserSuccess = (userLogin: string): LoginActionSuccess => ({
  type: LOGIN_USER_SUCCESS,
  payload: userLogin
});

export const loginUserError = (error: string): LoginActionError => ({
  type: LOGIN_USER_ERROR,
  payload: { error }
});

// LOGOUT
export const logoutUser = (): LogoutActionResult => ({
  type: LOGOUT_USER
});

export const logoutUserSuccess = (): LogoutActionSuccess => ({
  type: LOGOUT_USER_SUCCESS
});

// GOOGLE AUTH
export const googleAuth = (code: string): GoogleAuthAction => ({
  type: GOOGLE_AUTH,
  payload: { code }
});

export const googleAuthSuccess = (): GoogleAuthSuccessAction => ({
  type: GOOGLE_AUTH_SUCCESS
});

export const googleAuthError = (error: string): GoogleAuthErrorAction => ({
  type: GOOGLE_AUTH_ERROR,
  payload: { error }
});

// SMS AUTH
export const smsAuth = (password: string): SmsAuthAction => ({
  type: SMS_AUTH,
  payload: { password }
});

export const smsAuthSuccess = (): SmsAuthSuccessAction => ({
  type: SMS_AUTH_SUCCESS
});

export const smsAuthError = (error: string): SmsAuthErrorAction => ({
  type: SMS_AUTH_ERROR,
  payload: { error }
});

// GET GOOGLE QR
export const getGoogleQr = (): GetGoogleQrAction => ({
  type: GET_GOOGLE_QR
});

export const getGoogleQrSuccess = (data: string): GetGoogleQrSuccessAction => ({
  type: GET_GOOGLE_QR_SUCCESS,
  payload: { data }
});

export const getGoogleQrError = (error): GetGoogleQrErrorAction => ({
  type: GET_GOOGLE_QR_ERROR,
  payload: { error }
});

// GET GOOGLE CODE
export const getGoogleCode = (): GetGoogleCodeAction => ({
  type: GET_GOOGLE_CODE
});

export const getGoogleCodeSuccess = (data: string): GetGoogleCodeSuccessAction => ({
  type: GET_GOOGLE_CODE_SUCCESS,
  payload: { data }
});

export const getGoogleCodeError = (error): GetGoogleCodeErrorAction => ({
  type: GET_GOOGLE_CODE_ERROR,
  payload: { error }
});

// RESTORE PASSWORD
export const getTemporaryPassword = ({ email }: { email: string }): GetTmpPassActionResult => ({
  type: GET_TEMPORARY_PASSWORD,
  payload: { userLogin: email }
});

export const getTemporaryPasswordSuccess = (): GetTmpPassActionSuccess => ({
  type: GET_TEMPORARY_PASSWORD_SUCCESS
});

export const getTemporaryPasswordError = (error: string): GetTmpPassActionError => ({
  type: GET_TEMPORARY_PASSWORD_ERROR,
  payload: { error }
});

// RESTORE 2FA
export const restore2FA = ({ restoreType }: { restoreType: number }): Restore2FA => ({
  type: RESTORE_2FA,
  payload: { restoreType }
});

export const restore2FASuccess = (): Restore2FASuccess => ({
  type: RESTORE_2FA_SUCCESS
});

export const restore2FAError = (error: string): Restore2FAError => ({
  type: RESTORE_2FA_ERROR,
  payload: { error }
});

// RESTORE PASSWORD CHECK TEMPORARY CODE
export const checkTmpCode = (code: string): ICheckTmpCode => ({
  type: CHECK_TMP_CODE,
  payload: { code }
});

export const checkTmpCodeSuccess = (): ICheckTmpCodeSuccess => ({
  type: CHECK_TMP_CODE_SUCCESS
});

export const checkTmpCodeError = (error: string): ICheckTmpCodeError => ({
  type: CHECK_TMP_CODE_ERROR,
  payload: { error }
});

// GET USER
export const getUser = (): GetUserAction => ({
  type: GET_USER
});

export const getUserSuccess = (data: UserInformation): GetUserSuccess => ({
  type: GET_USER_SUCCESS,
  payload: { data }
});

export const getUserError = (error: string): GetUserError => ({
  type: GET_USER_ERROR,
  payload: { error }
});

// HANDLE NON AUTHORIZED
export const handleNonAuthorized = (): HandleNonAuthorizedAction => ({
  type: HANDLE_NON_AUTHORIZED
});

// SECOND FACTOR CANCELLATION
export const secondFactorCancellation = (): SecondFactorCancellation => ({
  type: SECOND_FACTOR_CANCELLATION
});

// REFRESH TOKEN
export const refreshToken = (permitionId: number | number[]) => ({
  type: REFRESH_TOKEN,
  payload: { permitionId }
});

export const refreshTokenSuccess = (data: RefreshTokenData): RefreshTokenSuccess => ({
  type: REFRESH_TOKEN_SUCCESS,
  payload: data
});

export const refreshTokenError = (error: string): RefreshTokenError => ({
  type: REFRESH_TOKEN_ERROR,
  payload: { error }
});
