import React, { Component, Fragment, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { Button, Card, CardBody, CustomInput, FormGroup, Label, Row } from 'reactstrap';
import { Colxx } from '../../../../components/common/CustomBootstrap';
import { INITIAL_VALUES, LIMITS } from './constants';
import IntlMessages from '../../../../helpers/IntlMessages';
import { getSystemSettings, putSystemSettings } from '../../../../redux/settings/actions';
import { VaultSettingsProps } from './interface';
import { Loader } from '../../../../components/common/Loader';
import { getConditionToProperty } from '../Gcm/helpers';
import { formatISO9075 } from 'date-fns';
import UpdateVaultConfirm from '../../../app/UpdateVaultConfirm';
import { NotificationManager } from '../../../../components/common/react-notifications';
import { NOTIFICATION_TIMEOUT } from '../../../../constants/defaultValues';
import { SystemVaultState } from './../interface';
import { withTranslation } from 'react-i18next';
class Vault extends Component<VaultSettingsProps, SystemVaultState> {
  constructor(props: any) {
    super(props);
    this.state = {
      updateVault: false
    };
  }

  componentDidUpdate(prevProps: Readonly<VaultSettingsProps>, prevState: Readonly<any>, snapshot?: any): void {
    const { error } = this.props;

    if (!prevProps.error && error) {
      NotificationManager.info(error, 'Ошибка', NOTIFICATION_TIMEOUT, null, null, '');
    }
  }

  update = (): void => {
    this.props.putSettings({
      ...this.props.data,
      update_vault_data: true
    });
    this.props.getSettings();
    this.setState({ updateVault: false });
  };

  onSubmit = (values): void => {
    this.props.putSettings(values);
  };

  isNoChanges = (values): boolean => {
    const { data } = this.props;

    return (
      data &&
      Boolean(
        getConditionToProperty(values, data, 'vault_data_synced_at') &&
          getConditionToProperty(values, data, 'update_vault_interval') &&
          getConditionToProperty(values, data, 'is_update_vault_data')
      )
    );
  };

  validate = (values): { [key: string]: string } => {
    let errors = {};
    if (!Number.isInteger(values.update_vault_interval)) {
      errors = { update_vault_interval: <IntlMessages id="systemSettings.integerOnly" /> };
    }
    if (values.update_vault_interval > LIMITS.update_vault_interval.max) {
      errors = { update_vault_interval: 'Максимальное значение ' + LIMITS.update_vault_interval.max };
    }
    if (values.update_vault_interval < LIMITS.update_vault_interval.min) {
      errors = { update_vault_interval: 'Минимальное значение ' + LIMITS.update_vault_interval.min };
    }
    return errors;
  };

  render(): ReactNode {
    const { data, loading, t } = this.props;
    const disabled = Boolean(loading || !data);
    const { updateVault } = this.state;
    return (
      <Fragment>
        {loading && <Loader />}

        <Formik
          validate={this.validate}
          validateOnBlur={false}
          enableReinitialize={true}
          initialValues={data ? data : INITIAL_VALUES}
          onSubmit={this.onSubmit}
        >
          {({ setFieldValue, errors, isValid, values }) => {
            const buttonsDisabled = this.isNoChanges(values) || !isValid || disabled;
            return (
              <Form>
                <Card className="mb-4 vault-settings">
                  <CardBody>
                    <Row>
                      <Colxx xxs="12" md="6" xl="4" className="gms-settings-column">
                        <FormGroup className="form-group gms-settings">
                          <Label className="gms-settings-label">{t('settings.lastUpdateTime')}</Label>
                          <Field
                            className="form-control vault-data-synced-at"
                            name="vault_data_synced_at"
                            value={
                              data
                                ? formatISO9075(Date.parse(data.vault_data_synced_at))
                                : INITIAL_VALUES.vault_data_synced_at
                            }
                            type="text"
                            onChange={() => {}}
                            disabled="disabled"
                          />
                        </FormGroup>
                      </Colxx>
                      <Colxx xxs="12" md="6" xl="4" className="gms-settings-column">
                        <Button
                          className="btn-block update-button"
                          color="primary"
                          onClick={() => {
                            this.setState({ updateVault: true });
                          }}
                        >
                          {t('settings.update')}
                        </Button>
                      </Colxx>
                    </Row>
                    <Row>
                      <Colxx xxs="12" md="6" xl="4" className="gms-settings-column">
                        <FormGroup className="form-group gms-settings">
                          <Label className="gms-settings-label">{t('settings.updateInterval')}</Label>
                          <Field
                            className="form-control"
                            name="update_vault_interval"
                            type="number"
                            min="1"
                            disabled={!values.is_update_vault_data}
                            onChange={(e) => {
                              setFieldValue('update_vault_interval', Number(e.target.value) || '');
                            }}
                          />
                          {errors.update_vault_interval && (
                            <div className="invalid-feedback d-block">{errors.update_vault_interval}</div>
                          )}
                        </FormGroup>
                      </Colxx>
                      <Colxx xxs="12" md="6" xl="4" className="gms-settings-column">
                        <CustomInput
                          id="regularly_data_update"
                          className="update-checkbox"
                          name="is_update_vault_data"
                          type="checkbox"
                          label={t('settings.regularlyDataUpdate')}
                          checked={values.is_update_vault_data}
                          onChange={(e) => {
                            setFieldValue('is_update_vault_data', !values.is_update_vault_data);
                          }}
                        />
                      </Colxx>
                    </Row>
                    <Row>
                      <Colxx xxs="12" className="d-flex justify-content-end">
                        <Button color="primary" type="submit" disabled={buttonsDisabled}>
                          {t('button.save')}
                        </Button>
                        <Button color="primary" type="reset" className="ml-2" disabled={buttonsDisabled}>
                          {t('button.cancel')}
                        </Button>
                      </Colxx>
                    </Row>
                  </CardBody>
                </Card>
              </Form>
            );
          }}
        </Formik>

        {updateVault && (
          <UpdateVaultConfirm onCloseModal={() => this.setState({ updateVault: false })} onConfirmModal={this.update} />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ settings }) => settings.gcmSettings;

const mapDispatchToProps = {
  putSettings: putSystemSettings,
  getSettings: getSystemSettings
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Vault)
);
