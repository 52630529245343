import { GET_DOCUMENTS, GET_DOCUMENTS_SUCCESS, GET_DOCUMENTS_ERROR, RESET_DOCUMENTS } from '../actions';
import {
  GetDocumentsActions,
  GetDocumentsSuccessActions,
  GetDocumentsErrorActions,
  DocumentsRequestValues,
  ResetDocuments
} from './interface';

// GET DOCUMENTS LIST
export const getDocuments = (requestValues: DocumentsRequestValues): GetDocumentsActions => ({
  type: GET_DOCUMENTS,
  payload: requestValues
});

export const getDocumentsSuccess = (rows: any): GetDocumentsSuccessActions => ({
  type: GET_DOCUMENTS_SUCCESS,
  payload: { rows }
});

export const getDocumentsError = (error: string): GetDocumentsErrorActions => ({
  type: GET_DOCUMENTS_ERROR,
  payload: { error }
});

// RESET DOCUMENTS
export const resetDocuments = (): ResetDocuments => ({
  type: RESET_DOCUMENTS
});
