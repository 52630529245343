import { SummaryStatistics } from '../../../redux/statistics/interface';
import { MAX_PERCENT } from '../../../constants/app';
import { roundDigit } from '../../../helpers/Utils';

interface ISummaryDataFields {
  total_amount: string | number;
  avg_amount: string | number;
  total: string | number;
  paid_amount: string | number;
  paid: string | number;
  paid_percentage: string | number;
  not_paid_amount: string | number;
  not_paid: string | number;
  not_paid_percentage: string | number;
  processing: string | number;
  refund_total: string | number;
  refund_total_percentage: string | number;
  refund_total_amount: string | number;
}

enum SummaryDataFields {
  TotalAmount = 'total_amount',
  AvgAmount = 'avg_amount',
  Total = 'total',
  PaidAmount = 'paid_amount',
  Paid = 'paid',
  PaidPercentage = 'paid_percentage',
  NotPaidAmount = 'not_paid_amount',
  NotPaid = 'not_paid',
  NotPaidPercentage = 'not_paid_percentage',
  Processing = 'processing',
  RefundTotalAmount = 'refund_total_amount',
  RefundTotal = 'refund_total',
  RefundTotalPercentage = 'refund_total_percentage'
}

const dataFields: ISummaryDataFields = {
  total_amount: '0.00',
  avg_amount: '0.00',
  total: '0',
  paid_amount: '0.00',
  paid: '0',
  paid_percentage: '0',
  not_paid_amount: '0.00',
  not_paid: '0',
  not_paid_percentage: '0',
  processing: '0',
  refund_total_amount: '0.00',
  refund_total: '0',
  refund_total_percentage: '0'
};

export const prepareData = (data: SummaryStatistics): ISummaryDataFields | null => {
  if (data) {
    return Object.keys(dataFields).reduce((acc, key) => {
      switch (key) {
        case SummaryDataFields.TotalAmount:
          return { ...acc, [key]: roundDigit(data.paid_amount + data.not_paid_amount + data.refund_amount) };
        case SummaryDataFields.AvgAmount:
          return { ...acc, [key]: roundDigit(data.avg_paid_amount) };
        case SummaryDataFields.Total:
          return { ...acc, [key]: roundDigit(data.total, 0) };
        case SummaryDataFields.PaidAmount:
          return { ...acc, [key]: roundDigit(data.paid_amount) };
        case SummaryDataFields.Paid:
          return { ...acc, [key]: roundDigit(data.paid, 0) };
        case SummaryDataFields.PaidPercentage:
          return {
            ...acc,
            [key]: Boolean(data.paid && data.total) ? roundDigit((data.paid * MAX_PERCENT) / data.total) : acc[key]
          };
        case SummaryDataFields.NotPaidAmount:
          return { ...acc, [key]: roundDigit(data.not_paid_amount) };
        case SummaryDataFields.NotPaid:
          return { ...acc, [key]: roundDigit(data.not_paid, 0) };
        case SummaryDataFields.NotPaidPercentage:
          return {
            ...acc,
            [key]: Boolean(data.not_paid && data.total)
              ? roundDigit((data.not_paid * MAX_PERCENT) / data.total)
              : acc[key]
          };
        case SummaryDataFields.Processing:
          return { ...acc, [key]: roundDigit(data.processing, 0) };
        case SummaryDataFields.RefundTotalAmount:
          return { ...acc, [key]: roundDigit(data.refund_amount) };
        case SummaryDataFields.RefundTotal:
          return { ...acc, [key]: roundDigit(data.refund, 0) };
        case SummaryDataFields.RefundTotalPercentage:
          return {
            ...acc,
            [key]: Boolean(data.refund && data.total) ? roundDigit((data.refund * MAX_PERCENT) / data.total) : acc[key]
          };
        default:
          return acc;
      }
    }, dataFields);
  } else {
    return null;
  }
};
