export const PERIOD_UNITS = [
  {
    key: 0,
    label: 'День',
    value: 'day'
  },
  {
    key: 1,
    label: 'Месяц',
    value: 'month'
  },
  {
    key: 2,
    label: 'Год',
    value: 'year'
  }
];

export const PERMISSIONS_DEFAULT = (t: any) => [
  {
    name: 'services',
    label: t('access_rights.services'),
    checked: false,
    subItems: [
      {
        id: 4,
        name: 'services-accuiring',
        label: t('access_rights.acquiring'),
        checked: false
      },
      {
        id: 5,
        name: 'services-mobile-ecommerce',
        label: t('access_rights.mc'),
        checked: false
      },
      {
        id: 6,
        name: 'services-payouts',
        label: t('access_rights.payouts'),
        checked: false
      }
    ]
  },
  {
    name: 'stats',
    label: t('access_rights.statistics'),
    checked: false,
    subItems: [
      {
        id: 1,
        name: 'stats-summary',
        label: t('access_rights.consolidated'),
        checked: false
      },
      {
        id: 2,
        name: 'stats-graphs',
        label: t('access_rights.charts'),
        checked: false
      },
      {
        id: 3,
        name: 'stats-clients',
        label: t('access_rights.by_customer'),
        checked: false
      },
      {
        id: 33,
        name: 'stats-graphs-conversion',
        label: t('access_rights.conversion'),
        checked: false
      },
      {
        id: 34,
        name: 'stats-period-summary',
        label: t('access_rights.by_period'),
        checked: false
      }
    ]
  },
  {
    name: 'operation',
    label: t('access_rights.transactions'),
    checked: false,
    subItems: [
      {
        id: 13,
        name: 'operation-acquiring',
        label: t('access_rights.search'),
        checked: false
      },
      {
        id: 14,
        name: 'operation-acquiring-refund',
        label: t('access_rights.acquiring_refund'),
        checked: false
      },
      {
        id: 15,
        name: 'operation-acquiring-callback',
        label: t('access_rights.acquiring_callback'),
        checked: false
      },
      {
        id: 8,
        name: 'operation-payouts-masscreate',
        label: t('access_rights.create_batch_transaction'),
        checked: false
      },
      {
        id: 7,
        name: 'operation-payouts-single',
        label: t('access_rights.create_single_transaction'),
        checked: false
      },
      {
        id: 16,
        name: 'operation-payouts-history',
        label: t('access_rights.batch_history'),
        checked: false
      }
    ]
  },
  {
    name: 'finance',
    label: t('access_rights.finance_documents'),
    checked: false,
    id: 18,
    subItems: []
  },
  {
    name: 'finance-disposals',
    label: t('access_rights.order'),
    checked: false,
    subItems: [
      {
        id: 19,
        name: 'finance-disposal-view',
        label: t('access_rights.order_view'),
        checked: false
      },
      {
        id: 20,
        name: 'finance-disposal-manage',
        label: t('access_rights.order_manage'),
        checked: false
      }
    ]
  },
  {
    name: 'balance',
    label: t('access_rights.balance'),
    checked: false,
    subItems: [
      {
        id: 10,
        name: 'balance-current-view',
        label: t('access_rights.current_balance'),
        checked: false
      },
      {
        id: 11,
        name: 'balance-operations',
        label: t('access_rights.balance_history'),
        checked: false
      }
    ]
  }
];

export const PERMISSIONS_ADMIN = (t: any) => [
  {
    name: 'logs',
    label: t('access_rights.user_logs'),
    checked: false,
    id: 21,
    subItems: []
  },
  {
    name: 'glossary',
    label: t('access_rights.directory'),
    checked: false,
    subItems: [
      {
        id: 22,
        name: 'glossary-users',
        label: t('access_rights.users_list'),
        checked: false
      },
      {
        id: 23,
        name: 'glossary-partners',
        label: t('access_rights.merchants_list'),
        checked: false
      },
      {
        id: 24,
        name: 'glossary-contract',
        label: t('access_rights.contracts_list'),
        checked: false
      }
    ]
  },
  {
    name: 'settings',
    label: t('access_rights.settings'),
    checked: false,
    subItems: [
      {
        id: 25,
        name: 'settings-services',
        label: t('access_rights.service_settings'),
        checked: false
      },
      {
        id: 26,
        name: 'settings-currencies',
        label: t('access_rights.currency'),
        checked: false
      },
      {
        id: 27,
        name: 'settings-permissions',
        label: t('access_rights.access_settings'),
        checked: false
      },
      {
        id: 29,
        name: 'settings-account',
        label: t('access_rights.profile'),
        checked: false
      },
      {
        id: 30,
        name: 'custom-design',
        label: t('access_rights.design'),
        checked: false
      }
    ]
  },
  {
    name: 'common-settings',
    label: t('access_rights.system'),
    checked: false,
    subItems: [
      {
        id: 28,
        name: 'settings-system',
        label: t('access_rights.gmc'),
        checked: false
      },
      {
        id: 31,
        name: 'settings-vault',
        label: t('access_rights.vault'),
        checked: false
      },
      {
        id: 32,
        name: 'settings-cache',
        label: t('access_rights.reset_cache'),
        checked: false
      }
    ]
  }
];

export const PERMISSION_INITIAL_VALUES = {
  user: {
    value: '',
    key: '',
    label: ''
  },
  merchant: {
    value: '',
    key: '',
    label: ''
  }
};

export interface PermissionSelectOption {
  label: string;
  value: number;
  key: number | string;
}

// const (
// _                   PermissionFlags = 1 << iota
// SumStat                             // Сводная статистика
// Graphs                              // Графики
// ClientStat                          // Статистика по клиентам
// FilterSearch                        // Поиск по фильтру
// AttributesSearch                    // Поиск по ид, емэйл, телефону
// DownloadRequest                     // Заказ выгрузки
// SinglePayout                        // Единичная выплата
// MassPayout                          // Массовая выплата
// PayoutList                          // Просмотр списка выплат
// BalanceInfo                         // Информация о текущем балансе
// BalanceChange                       // Информация об изменении баланса
// StatementSearch                     // Поиск финансовых документов
// StatementView                       // Просмотр финансовых документов
// StatementDownload                   // Загрузка финансовых документов
// BalanceNotification                 // Уведомления по балансу
// )
