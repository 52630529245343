import {
  USER_CHANGE_PASSWORD,
  USER_CHANGE_PASSWORD_SUCCESS,
  USER_CHANGE_PASSWORD_ERROR,
  USER_UPDATE_PASSWORD,
  USER_UPDATE_PASSWORD_SUCCESS,
  USER_UPDATE_PASSWORD_ERROR
} from '../actions';

import {
  ChangePasswordRequestValues,
  ChangePasswordActionResult,
  ChangePasswordActionError,
  ChangePasswordActionSuccess,
  UpdatePasswordRequestValues,
  UpdatePasswordActionResult,
  UpdatePasswordActionError,
  UpdatePasswordActionSuccess
} from './interface';

// CHANGE PASSWORD
export const changePassword = ({
  oldPassword,
  newPassword,
  tmpCode,
  _2faCode
}: ChangePasswordRequestValues): ChangePasswordActionResult => ({
  type: USER_CHANGE_PASSWORD,
  payload: {
    oldPassword,
    newPassword,
    tmpCode,
    _2faCode
  }
});

export const updatePassword = ({
  oldPassword,
  newPassword
}: UpdatePasswordRequestValues): UpdatePasswordActionResult => ({
  type: USER_UPDATE_PASSWORD,
  payload: {
    oldPassword,
    newPassword
  }
});

export const changePasswordSuccess = (): ChangePasswordActionSuccess => ({
  type: USER_CHANGE_PASSWORD_SUCCESS
});

export const changePasswordError = (error: string, status?: number): ChangePasswordActionError => ({
  type: USER_CHANGE_PASSWORD_ERROR,
  payload: {
    error,
    status
  }
});

export const updatePasswordSuccess = (): UpdatePasswordActionSuccess => ({
  type: USER_UPDATE_PASSWORD_SUCCESS
});

export const updatePasswordError = (error: string, status?: number): UpdatePasswordActionError => ({
  type: USER_UPDATE_PASSWORD_ERROR,
  payload: {
    error,
    status
  }
});
