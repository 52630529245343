import { all, call, fork, put, takeLatest, SimpleEffect } from 'redux-saga/effects';
import { CombinatorEffect } from '@redux-saga/types';

import { CheckTmpCodeResponse, GetTmpPassResponse, LoginWithEmailResponse } from '../../api.interface';
import { ApiRequest } from '../../helpers/ApiRequest/ApiRequest';

import {
  GET_TEMPORARY_PASSWORD,
  getTemporaryPasswordSuccess,
  getTemporaryPasswordError,
  CHECK_TMP_CODE,
  checkTmpCodeSuccess,
  checkTmpCodeError,
  LOGIN_USER,
  loginUserSuccess,
  loginUserError,
  LOGOUT_USER,
  logoutUserSuccess,
  GOOGLE_AUTH,
  googleAuthSuccess,
  googleAuthError,
  SMS_AUTH,
  smsAuthSuccess,
  smsAuthError,
  GET_GOOGLE_QR,
  getGoogleQrSuccess,
  getGoogleQrError,
  GET_USER,
  getUserSuccess,
  getUserError,
  GET_SYSTEM_SETTINGS,
  getGoogleCodeError,
  getGoogleCodeSuccess,
  GET_GOOGLE_CODE,
  REFRESH_TOKEN,
  RESTORE_2FA,
  restore2FASuccess,
  restore2FAError,
  RESET_USER_AND_MERCHANT_STORE,
  refreshTokenSuccess,
  refreshTokenError
} from '../actions';

import {
  GetTmpPassActionResult,
  ICheckTmpCode,
  GoogleAuthAction,
  LoginActionResult,
  SmsAuthAction,
  Restore2FA,
  RefreshToken
} from './interface';

// LOGIN
export function* loginWithEmailPassword({
  payload
}: LoginActionResult): Generator<SimpleEffect<string, any>, any, any> {
  const { username, password } = payload.user;
  try {
    const loginUser: LoginWithEmailResponse = yield call(ApiRequest.post, '/user/sign-in/', { username, password });
    if (loginUser.error) {
      yield put(loginUserError(`${loginUser.error}`));
    } else {
      localStorage.setItem('userLogin', username);
      yield put(loginUserSuccess(username));

      if (loginUser && 'tfs' in loginUser) {
        localStorage.setItem('tfs', loginUser.tfs.toString());
        yield put({ type: GET_USER });
      }
    }
  } catch (error) {
    yield put(loginUserError(`${error}`));
  }
}

// LOGOUT
function* logout(): Generator<SimpleEffect<string, any>, any, any> {
  try {
    yield call(ApiRequest.post, '/user/sign-out/');
    yield put(logoutUserSuccess());
    yield put({ type: RESET_USER_AND_MERCHANT_STORE });
    localStorage.removeItem('userLogin');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('currentMerchant');
  } catch (error) {}
}

// GOOGLE AUTH
function* googleAuth({ payload }: GoogleAuthAction): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { code } = payload;
    const { error } = yield call(ApiRequest.post, '/user/code/', { code });

    if (error) {
      yield put(googleAuthError(error));
    } else {
      localStorage.removeItem('tfs');
      yield put(googleAuthSuccess());
      yield put({ type: GET_USER });
    }
  } catch (error) {
    yield put(googleAuthError(error));
  }
}

// SMS AUTH
function* smsAuth({ payload }: SmsAuthAction): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { password } = payload;
    const { error } = yield call(ApiRequest.post, '/user/sms-auth/', { password });

    if (error) {
      yield put(smsAuthError(error));
    } else {
      localStorage.removeItem('tfs');
      yield put(smsAuthSuccess());
      yield put({ type: GET_USER });
    }
  } catch (error) {
    yield put(smsAuthError(error));
  }
}

// GET GOOGLE QR CODE
function* getGoogleQr(): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { data, error } = yield call(ApiRequest.get, '/user/qr-code/');

    if (error) {
      yield put(getGoogleQrError(error));
    } else {
      yield put(getGoogleQrSuccess(data));
    }
  } catch (error) {
    yield put(getGoogleQrError(error));
  }
}

// GET GOOGLE CODE
function* getGoogleCode(): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { data, error } = yield call(ApiRequest.get, '/user/code/');

    if (error) {
      yield put(getGoogleCodeError(error));
    } else {
      yield put(getGoogleCodeSuccess(data));
    }
  } catch (error) {
    yield put(getGoogleCodeError(error));
  }
}

// RESTORE PASSWORD
function* getTmpPass({ payload }: GetTmpPassActionResult): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { userLogin } = payload;

    const getTmpPassResponse: GetTmpPassResponse = yield call(ApiRequest.post, '/user/forgot-password/', {
      email: userLogin
    });

    if (getTmpPassResponse.error) {
      yield put(getTemporaryPasswordError(`${getTmpPassResponse.error}`));
    } else {
      yield put(getTemporaryPasswordSuccess());
    }
  } catch (error) {
    yield put(getTemporaryPasswordError(`${error}`));
  }
}

// RESTORE 2FA
function* restore2FA({ payload }: Restore2FA): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { restoreType } = payload;

    const getTmpPassResponse: GetTmpPassResponse = yield call(ApiRequest.post, '/user/forgot-code/', {
      restore_type: restoreType
    });

    if (getTmpPassResponse.error) {
      yield put(restore2FAError(`${getTmpPassResponse.error}`));
    } else {
      yield put(restore2FASuccess());
    }
  } catch (error) {
    yield put(restore2FAError(`${error}`));
  }
}

function* checkTmpCode({ payload }: ICheckTmpCode): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const checkTmpCodeResponse: CheckTmpCodeResponse = yield call(ApiRequest.post, '/user/restore-code/', {
      code: payload.code
    });

    if (checkTmpCodeResponse.error) {
      yield put(checkTmpCodeError(`${checkTmpCodeResponse.error}`));
    } else {
      if (checkTmpCodeResponse && 'tfs' in checkTmpCodeResponse) {
        localStorage.setItem('tfs', checkTmpCodeResponse.tfs.toString());
      }
      if (checkTmpCodeResponse && 'restore_type' in checkTmpCodeResponse) {
        localStorage.setItem('restore_type', checkTmpCodeResponse.restore_type.toString());
      }
      yield put(checkTmpCodeSuccess());
    }
  } catch (error) {
    yield put(checkTmpCodeError(`${error}`));
  }
}

// GET USER
export function* getUser(): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { error, data } = yield call(ApiRequest.get, '/user/');

    if (error) {
      yield put(getUserError(`${error}`));
    } else {
      yield put(getUserSuccess(data));
      if (data.is_admin) {
        yield put({ type: GET_SYSTEM_SETTINGS });
      }
    }
  } catch (error) {
    yield put(getUserError(`${error}`));
  }
}

// REFRESH TOKEN
export function* refreshToken({ payload }: RefreshToken): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { error, data } = yield call(ApiRequest.post, '/user/refresh-token/', {
      permitionId: payload.permitionId
    });
    if (error) {
      yield put(refreshTokenError(`${error}`));
    } else {
      yield put(refreshTokenSuccess(data));
    }
  } catch (e) {
    console.warn(e);
  }
}

export function* watchLoginUser(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(LOGIN_USER, loginWithEmailPassword);
}

export function* watchLogoutUser(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(LOGOUT_USER, logout);
}

export function* watchGoogleAuth(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(GOOGLE_AUTH, googleAuth);
}

export function* watchSmsAuth(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(SMS_AUTH, smsAuth);
}

export function* watchGetGoogleQr(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(GET_GOOGLE_QR, getGoogleQr);
}

export function* watchGetGoogleCode(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(GET_GOOGLE_CODE, getGoogleCode);
}

export function* watchGetTmpPass(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(GET_TEMPORARY_PASSWORD, getTmpPass);
}

export function* watchRestore2FA(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(RESTORE_2FA, restore2FA);
}

export function* watchCheckTmpCode(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(CHECK_TMP_CODE, checkTmpCode);
}

export function* watchGetUser(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(GET_USER, getUser);
}

export function* watchRefreshToken(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(REFRESH_TOKEN, refreshToken);
}

export default function* rootSaga(): Generator<CombinatorEffect<string, any>, any, any> {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchGoogleAuth),
    fork(watchSmsAuth),
    fork(watchGetGoogleQr),
    fork(watchGetGoogleCode),
    fork(watchGetTmpPass),
    fork(watchRestore2FA),
    fork(watchCheckTmpCode),
    fork(watchGetUser),
    fork(watchRefreshToken)
  ]);
}
