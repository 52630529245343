import {
  GET_DEFAULT_PAGE,
  GET_DEFAULT_PAGE_SUCCESS,
  GET_DEFAULT_PAGE_ERROR,
  GET_USER_BY_ID,
  GET_USER_BY_ID_SUCCESS,
  GET_USER_BY_ID_ERROR
} from '../actions';

import {
  GetDefaultPageAction,
  GetDefaultPageSuccessAction,
  GetDefaultPageErrorAction,
  GetUserByIdAction,
  GetUserByIdError,
  GetUserByIdSuccess
} from './interface';
import { UserInformation } from '../settings/interface';

export const getDefaultPage = (): GetDefaultPageAction => ({
  type: GET_DEFAULT_PAGE
});

export const getDefaultPageSuccess = (data: string): GetDefaultPageSuccessAction => ({
  type: GET_DEFAULT_PAGE_SUCCESS,
  payload: { data }
});

export const getDefaultPageError = (error: string): GetDefaultPageErrorAction => ({
  type: GET_DEFAULT_PAGE_ERROR,
  payload: { error }
});

// GET USER BY ID
export const getUserById = (id: number): GetUserByIdAction => ({
  type: GET_USER_BY_ID,
  payload: { id }
});

export const getUserByIdSuccess = (data: UserInformation): GetUserByIdSuccess => ({
  type: GET_USER_BY_ID_SUCCESS,
  payload: { data }
});

export const getUserByIdError = (error: string): GetUserByIdError => ({
  type: GET_USER_BY_ID_ERROR,
  payload: { error }
});
