import { Merchant } from '../menu/interface';
import {
  CHANGE_DISPOSAL_STATUS,
  CHANGE_DISPOSAL_STATUS_ERROR,
  CHANGE_DISPOSAL_STATUS_SUCCESS,
  CREATE_DISPOSAL,
  CREATE_DISPOSAL_ERROR,
  CREATE_DISPOSAL_SUCCESS,
  DELETE_DISPOSAL,
  DELETE_DISPOSAL_ERROR,
  DELETE_DISPOSAL_SUCCESS,
  GET_DISPOSALS,
  GET_DISPOSALS_ERROR,
  GET_DISPOSALS_PARTNERS,
  GET_DISPOSALS_PARTNERS_ERROR,
  GET_DISPOSALS_PARTNERS_SUCCESS,
  GET_DISPOSALS_SUCCESS,
  GET_STATEMENTS,
  GET_STATEMENTS_ERROR,
  GET_STATEMENTS_SUCCESS,
  RESET_STATEMENTS
} from '../actions';
import {
  ChangeDisposalStatus,
  ChangeDisposalStatusError,
  ChangeDisposalStatusSuccess,
  CreateDisposal,
  CreateDisposalError,
  CreateDisposalSuccess,
  DeleteDisposal,
  DeleteDisposalError,
  DeleteDisposalSuccess,
  DisposalsItem,
  GetAuthUserPartners,
  GetAuthUserPartnersError,
  GetAuthUserPartnersSuccess,
  GetDisposals,
  GetDisposalsError,
  GetDisposalsSuccess,
  GetStatements,
  GetStatementsError,
  GetStatementsSuccess,
  ResetStatements,
  StatementItem,
  DisposalsRequest
} from './interface';
import { DisposalStatus } from '../../views/disposals/constants';
import { CreateDisposalRequest } from '../../views/disposals/interface';

// GET DISPOSALS
export const getDisposals = (userId, values: DisposalsRequest): GetDisposals => ({
  type: GET_DISPOSALS,
  payload: {
    user_id: userId,
    values
  }
});

export const getDisposalsSuccess = (data: DisposalsItem[]): GetDisposalsSuccess => ({
  type: GET_DISPOSALS_SUCCESS,
  payload: { data }
});

export const getDisposalsError = (error: string): GetDisposalsError => ({
  type: GET_DISPOSALS_ERROR,
  payload: { error }
});

// DELETE DISPOSAL
export const deleteDisposal = (id: number[]): DeleteDisposal => ({
  type: DELETE_DISPOSAL,
  payload: { id }
});

export const deleteDisposalSuccess = (): DeleteDisposalSuccess => ({
  type: DELETE_DISPOSAL_SUCCESS
});

export const deleteDisposalError = (error: string): DeleteDisposalError => ({
  type: DELETE_DISPOSAL_ERROR,
  payload: { error }
});

// GET DISPOSALS PARTNERS
export const getAuthUserPartners = (): GetAuthUserPartners => ({
  type: GET_DISPOSALS_PARTNERS
});

export const getAuthUserPartnersSuccess = (data: Merchant[]): GetAuthUserPartnersSuccess => ({
  type: GET_DISPOSALS_PARTNERS_SUCCESS,
  payload: { data }
});

export const getAuthUserPartnersError = (error: string): GetAuthUserPartnersError => ({
  type: GET_DISPOSALS_PARTNERS_ERROR,
  payload: { error }
});

// CHANGE DISPOSAL STATUS
export const changeDisposalStatus = (id: number[], status: DisposalStatus, comment: string): ChangeDisposalStatus => ({
  type: CHANGE_DISPOSAL_STATUS,
  payload: { id, status, comment }
});

export const changeDisposalStatusSuccess = (): ChangeDisposalStatusSuccess => ({
  type: CHANGE_DISPOSAL_STATUS_SUCCESS
});

export const changeDisposalStatusError = (error: string): ChangeDisposalStatusError => ({
  type: CHANGE_DISPOSAL_STATUS_ERROR,
  payload: { error }
});

// CREATE DISPOSAL
export const createDisposal = (request: CreateDisposalRequest): CreateDisposal => ({
  type: CREATE_DISPOSAL,
  payload: { request }
});

export const createDisposalSuccess = (id: number): CreateDisposalSuccess => ({
  type: CREATE_DISPOSAL_SUCCESS,
  payload: { id }
});

export const createDisposalError = (error: string): CreateDisposalError => ({
  type: CREATE_DISPOSAL_ERROR,
  payload: { error }
});

// GET STATEMENTS
export const getStatements = (partner: number, account: number[]): GetStatements => ({
  type: GET_STATEMENTS,
  payload: { partner, account }
});

export const getStatementsSuccess = (data: StatementItem[]): GetStatementsSuccess => ({
  type: GET_STATEMENTS_SUCCESS,
  payload: { data }
});

export const getStatementsError = (error: string): GetStatementsError => ({
  type: GET_STATEMENTS_ERROR,
  payload: { error }
});

export const resetStatements = (): ResetStatements => ({
  type: RESET_STATEMENTS
});
