import { CellInfo } from 'react-table';
import { CustomInput } from 'reactstrap';
import React from 'react';
import { StatementItem } from '../../../redux/disposals/interface';
import { CurrenciesData } from '../../../redux/operations/interface';

export const ERROR_STATEMENT_TO_ACCOUNT_TOOLTIP = 'Не выбраны стейтменты / аккаунт';
export const ERROR_STATEMENT_TO_CHECKING_ACCOUNT_TOOLTIP = 'Не выбраны стейтменты';
export const ERROR_ACCOUNT_TO_ACCOUNT_TOOLTIP = 'Не выбраны аккаунты';
export const ERROR_TOTAL_AMOUNT_TOOLTIP = 'Сумма превышает итоговое значение';

export const AMOUNT_TOOLTIP = 'Сумма не должна превышать итогового значения';
export const SCROLL_TABLE_STYLE = { maxHeight: '45vh' };

export const StatementsStatusesNames = {
  1: 'Доступен',
  2: 'В обработке',
  3: 'Использован'
};

export const AccountsStatusesNames = {
  0: 'В обработке',
  1: 'Доступен'
};

const numberWithSpaces = (x: number) => {
  const parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return parts.join('.');
};

export const getStatementsTableColumn = (
  selectStatement: (statement: StatementItem, isChecked: boolean) => void,
  currencies,
  t: any
) => [
  {
    maxWidth: 100,
    Header: t('disposals.statement_id'),
    accessor: 'source_id'
  },
  {
    Header: t('disposals.amount'),
    accessor: 'amount',
    Cell: ({ value, original }: CellInfo) => {
      const currCurrency =
        currencies &&
        currencies.length &&
        currencies.find((currency: CurrenciesData) => currency.id === Number(original.currency));
      return `${numberWithSpaces(value)} ${currCurrency ? currCurrency.name : ''}`;
    }
  },
  {
    Header: t('disposals.status'),
    accessor: 'status',
    Cell: ({ value }: CellInfo) => t(StatementsStatusesNames[value]) || t(value)
  },
  {
    width: 30,
    accessor: 'checked',
    Cell: ({ value, original }: CellInfo) => (
      <CustomInput
        type="checkbox"
        key={`${original.id}_statement`}
        id={`${original.id}_statement`}
        name={`${original.id}_statement`}
        defaultChecked={value}
        onChange={({ target: { checked } }) => {
          selectStatement(original, checked);
        }}
      />
    )
  }
];

export const getAccountTableColumn = (
  selectAccount: (account: StatementItem) => void,
  currencies,
  t: any,
  isSecondTable = false
) => [
  {
    Header: t('disposals.account'),
    accessor: 'name'
  },
  {
    Header: t('disposals.balance'),
    accessor: 'balance',
    Cell: ({ value, original }: CellInfo) => {
      const currCurrency =
        currencies &&
        currencies.length &&
        currencies.find((currency: CurrenciesData) => currency.id === Number(original.currency));
      return `${numberWithSpaces(value)} ${currCurrency ? currCurrency.name : ''}`;
    }
  },
  {
    Header: t('disposals.status'),
    accessor: 'status',
    Cell: ({ value }: CellInfo) => t(AccountsStatusesNames[value]) || t(value)
  },
  {
    width: 30,
    accessor: 'checked',
    Cell: ({ original, value }: CellInfo) => (
      <CustomInput
        type="radio"
        className="custom-radio-input"
        key={`${original.id}_account${isSecondTable ? '_2' : ''}`}
        id={`${original.id}_account${isSecondTable ? '_2' : ''}`}
        name={`${original.id}_account${isSecondTable ? '_2' : ''}`}
        defaultChecked={value}
        disabled={original.disabled}
        onChange={({ target: { checked } }) => checked && selectAccount(original)}
      />
    )
  }
];
