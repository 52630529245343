import React from 'react';
import { Redirect, Route, Switch } from 'react-router';

import Summary from './Summary/Summary';
import ByCustomers from './ByCustomers/ByCustomers';
import Charts from './Charts/Charts';
import ConversionChart from './ConversionChart/ConversionChart';
import PeriodSummary from './PeriodSummary/PeriodSummary';

const Statistics = ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}/summary`} component={Summary} />
    <Route exact path={`${match.url}/by-customers`} component={ByCustomers} />
    <Route exact path={`${match.url}/charts`} component={Charts} />
    <Route exact path={`${match.url}/conversion-chart`} component={ConversionChart} />
    <Route exact path={`${match.url}/period-summary`} component={PeriodSummary} />
    <Redirect from={match.url} to={`${match.url}/summary`} />
    <Redirect to={'/error'} />
  </Switch>
);

export default Statistics;
