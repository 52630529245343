import { startOfDay } from 'date-fns';

import {
  OPERATIONS_ACCOUNTS_OPTIONS,
  OPERATIONS_INCOME_DIRECTIONS_OPTIONS,
  OPERATIONS_INCOME_SITE_OPTIONS
} from '../constants';
import { ALL_CURRENCY_OPTION, OPERATION_STATUSES_OPTIONS_WITH_ALL, RUB_CURRENCY_OPTION } from '../../../constants/app';
import { OPERATIONS_OUTCOME_DIRECTIONS_OPTIONS } from '../Payouts/constants';
import { IFilterInitialValues } from './interface';

export enum ServiceTypes {
  Income = 1,
  Mc,
  Outcome
}

// FILTER
export const OPERATIONS_LIST_FILTER_INITIAL_VALUES = (t: any): IFilterInitialValues => ({
  [ServiceTypes.Income]: {
    dateFrom: startOfDay(new Date()),
    dateTo: new Date(),
    direction: OPERATIONS_INCOME_DIRECTIONS_OPTIONS[0],
    orderNumber: [],
    pid: [],
    amount: '',
    card_number: '',
    account: OPERATIONS_ACCOUNTS_OPTIONS(t),
    currency: ALL_CURRENCY_OPTION(t)[0],
    status: OPERATION_STATUSES_OPTIONS_WITH_ALL(t)[0],
    site: OPERATIONS_INCOME_SITE_OPTIONS[0],
    byEndDate: false
  },
  [ServiceTypes.Mc]: {
    dateFrom: startOfDay(new Date()),
    dateTo: new Date(),
    orderNumber: [],
    pid: [],
    card_number: '',
    amount: '',
    account: OPERATIONS_ACCOUNTS_OPTIONS(t),
    currency: RUB_CURRENCY_OPTION,
    status: OPERATION_STATUSES_OPTIONS_WITH_ALL(t)[0]
  },
  [ServiceTypes.Outcome]: {
    dateFrom: startOfDay(new Date()),
    dateTo: new Date(),
    direction: OPERATIONS_OUTCOME_DIRECTIONS_OPTIONS[0],
    orderNumber: [],
    card_number: '',
    pid: [],
    amount: '',
    account: OPERATIONS_ACCOUNTS_OPTIONS(t),
    currency: ALL_CURRENCY_OPTION(t)[0],
    status: OPERATION_STATUSES_OPTIONS_WITH_ALL(t)[0],
    byEndDate: false
    // TODO Бэк пока не готов
    // registryPayments: false,
    // singlePayments: false
  }
});

// TABLE

export enum IncomePaymentMethodNames {
  Acquiring = 'Эквайринг',
  Acquiring2 = 'Эквайринг',
  MobilePay = 'MobilePay',
  All = 'Все'
}

export enum DirectionKeys {
  Acquiring = 3,
  Acquiring2 = 4,
  MobilePay = 1
}

export enum DestinationKeys {
  ApplePay = 1,
  GooglePay,
  SamsungPay
}

export enum RefundSources {
  FatpayNet = 3,
  SecureWinPay24Ru = 4,
  SecureItpartnersIo = 5
}

export const REFUND_ALLOW_SOURCES = [
  RefundSources.FatpayNet,
  RefundSources.SecureWinPay24Ru,
  RefundSources.SecureItpartnersIo
];

export const RETURN_PERMISSION_CODE = 14;
export const CALLBACK_PERMISSION_CODE = 15;
