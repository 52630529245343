import React, { PureComponent, ReactNode } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { ModalCreatedInfoProps } from '../interface';

class ModalCreatedInfo extends PureComponent<ModalCreatedInfoProps> {
  render(): ReactNode {
    const { onCloseModal, t } = this.props;

    return (
      <Modal isOpen={true} toggle={onCloseModal}>
        <ModalHeader toggle={onCloseModal}>{t('modal.create_order.title')}</ModalHeader>
        <ModalBody>{t('modal.create_order.description')}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onCloseModal}>
            {t('ok')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default withTranslation()(ModalCreatedInfo);
