import React, { Component, MouseEvent, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import Select from 'react-select';
import { UncontrolledDropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import { TreeNode } from 'react-dropdown-tree-select';
import isEqual from 'lodash/isEqual';

import { NotificationManager } from '../../../components/common/react-notifications';
import LanguageSelect from '../../../components/LanguageSelect';

import { NOTIFICATION_TIMEOUT, searchPath, menuHiddenBreakpoint } from '../../../constants/defaultValues';
import {
  setContainerClassnames,
  clickOnMobileMenu,
  logoutUser,
  settingsChangeLocale,
  settingsChangeUser,
  menuGetMerchants,
  menuGetUsers,
  settingsChangeMerchant,
  getNotifications
} from '../../../redux/actions';
import { CustomDropdownTreeSelect } from '../../../components/common/CustomDropdownTreeSelect';
import { MobileMenuIcon, MenuIcon } from '../../../components/svg';
import TopnavNotifications from './Topnav.Notifications';
import TopnavEasyAccess from './Topnav.EasyAccess';

import { CURRENT_USER_LOCAL_STORAGE_KEY } from '../../../redux/settings/constants';
import { Merchant } from '../../../redux/menu/interface';
import { User } from '../../../redux/authUser/interface';
import { localStorageValueToNumber } from '../../../helpers/Utils';
import { MAX_HEADER_NOTIFICATIONS, RESIZE_PAGE_TIMEOUT } from './constant';
import { SelectOption, TopNavState } from './interface';
import { getPartnersOption } from './helpers';
import { defaultMenuItems } from '../../../constants/menu';
import { getPermissionIdMenuItems, isAllowed } from '../../../helpers/Access';
import { withTranslation } from 'react-i18next';
class TopNav extends Component<any, TopNavState> {
  state: TopNavState = {
    isInFullScreen: false,
    searchKeyword: '',
    prevPathname: null
  };

  componentDidMount(): void {
    const { getMerchants, getUsers, isAdmin, authUserId, selectedUser, isAuth, getNotificationsAction } = this.props;
    const userId = isAdmin && selectedUser && selectedUser.id !== authUserId ? selectedUser.id : null;

    isAuth && isAdmin && getUsers();
    isAuth && getMerchants(userId);
    getNotificationsAction({ limit: MAX_HEADER_NOTIFICATIONS });
  }

  componentDidUpdate(prevProps: any): void {
    const {
      getMerchants,
      merchants,
      selectedMerchants,
      changeMerchant,
      users,
      history,
      selectedUser,
      changeUser,
      isAdmin,
      isAuth,
      authUserId,
      getUsers,
      allowMultiPartner,
      history: {
        location: { pathname }
      }
    } = this.props;
    const { prevPathname } = this.state;

    const userId = isAdmin && selectedUser && selectedUser.id !== authUserId ? selectedUser.id : null;

    if (prevProps.isAuth !== isAuth && isAuth) {
      isAdmin && !users.data && getUsers();
    }

    if (prevProps.isAdmin !== isAdmin && isAdmin) {
      getUsers();
    }

    if (prevProps.selectedUser && selectedUser && prevProps.selectedUser.is_admin !== selectedUser.is_admin) {
      if (selectedUser.is_admin) {
        if (prevPathname != null) {
          history.push(prevPathname);
          this.setState({
            prevPathname: null
          });
        }
      } else {
        const isAdminLink = this.isAdminMenuLink();
        if (isAdminLink) {
          this.setState({
            prevPathname: pathname
          });
          history.push('/empty');
        }
      }
    }

    if (isAdmin && !selectedUser && users.data) {
      const storageUser = localStorageValueToNumber(localStorage.getItem(CURRENT_USER_LOCAL_STORAGE_KEY));
      const currentUserId = storageUser || authUserId;
      const currentUser = users.data.find((user: User) => user.id === currentUserId) || users.data[0];

      changeUser(currentUser);
      currentUser && getMerchants(currentUser.id);
    }

    if (isAdmin && selectedUser && prevProps.selectedUser && prevProps.selectedUser.id !== selectedUser.id) {
      getMerchants(userId);
      const permissionId = getPermissionIdMenuItems(pathname);

      if (!isAllowed(selectedUser, permissionId)) {
        window.location.href = '/';
        return;
      }
    }

    if (
      (!selectedMerchants && merchants.data) ||
      (prevProps.merchants.data &&
        merchants.data &&
        (!isEqual(prevProps.merchants.data, merchants.data) ||
          !isEqual(prevProps.allowMultiPartner, allowMultiPartner)))
    ) {
      merchants.data && changeMerchant(allowMultiPartner ? merchants.data : [merchants.data[0]]);
    }

    if (prevProps.merchants.data && !merchants.data && !isEqual(prevProps.merchants.data, merchants.data)) {
      changeMerchant(null);
    }

    if (!prevProps.merchants.error && merchants.error) {
      NotificationManager.info(merchants.error, 'Ошибка загрузки мерчантов', NOTIFICATION_TIMEOUT, null, null, '');
    }

    if (!prevProps.users.error && users.error) {
      NotificationManager.info(users.error, 'Ошибка загрузки пользователей', NOTIFICATION_TIMEOUT, null, null, '');
    }
  }

  isAdminMenuLink = (): boolean => {
    const {
      history: {
        location: { pathname }
      }
    } = this.props;
    const section = pathname.split('/').filter((x: string) => x)[0];

    const menuItem = defaultMenuItems.find(({ to }) => {
      const currSection = to.split('/').filter((x: string) => x)[0];
      return currSection === section;
    });

    return !menuItem;
  };

  handleLogout = (): void => {
    this.props.logoutUser();
  };

  menuButtonClick = (e: MouseEvent, menuClickCount: number, containerClassnames: string): void => {
    e.preventDefault();

    const { setContainerClassnames: setContainerClassnamesAction, selectedMenuHasSubItems } = this.props;

    setTimeout(() => {
      const event = document.createEvent('HTMLEvents');
      event.initEvent('resize', false, false);
      window.dispatchEvent(event);
    }, RESIZE_PAGE_TIMEOUT);

    setContainerClassnamesAction(menuClickCount + 1, containerClassnames, selectedMenuHasSubItems);
  };

  mobileMenuButtonClick = (e: MouseEvent, containerClassnames: string): void => {
    e.preventDefault();
    this.props.clickOnMobileMenu(containerClassnames);
  };

  handleSearchIconClick = (e) => {
    if (window.innerWidth < menuHiddenBreakpoint) {
      let elem = e.target;
      if (!e.target.classList.contains('search')) {
        if (e.target.parentElement.classList.contains('search')) {
          elem = e.target.parentElement;
        } else if (e.target.parentElement.parentElement.classList.contains('search')) {
          elem = e.target.parentElement.parentElement;
        }
      }

      if (elem.classList.contains('mobile-view')) {
        this.search();
        elem.classList.remove('mobile-view');
        this.removeEventsSearch();
      } else {
        elem.classList.add('mobile-view');
        this.addEventsSearch();
      }
    } else {
      this.search();
    }
  };
  addEventsSearch = () => {
    document.addEventListener('click', this.handleDocumentClickSearch, true);
  };
  removeEventsSearch = () => {
    document.removeEventListener('click', this.handleDocumentClickSearch, true);
  };

  handleDocumentClickSearch = (e) => {
    let isSearchClick = false;
    if (
      e.target &&
      e.target.classList &&
      (e.target.classList.contains('navbar') || e.target.classList.contains('simple-icon-magnifier'))
    ) {
      isSearchClick = true;
      if (e.target.classList.contains('simple-icon-magnifier')) {
        this.search();
      }
    } else if (
      e.target.parentElement &&
      e.target.parentElement.classList &&
      e.target.parentElement.classList.contains('search')
    ) {
      isSearchClick = true;
    }

    if (!isSearchClick) {
      const input = document.querySelector('.mobile-view');
      if (input && input.classList) input.classList.remove('mobile-view');
      this.removeEventsSearch();
      this.setState({
        searchKeyword: ''
      });
    }
  };

  handleSearchInputChange = (e) => {
    this.setState({
      searchKeyword: e.target.value
    });
  };
  handleSearchInputKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.search();
    }
  };

  search = () => {
    this.props.history.push(`${searchPath}/${this.state.searchKeyword}`);
    this.setState({ searchKeyword: '' });
  };

  isInFullScreen = () =>
    (document.fullscreenElement && true) ||
    (document['webkitFullscreenElement'] && document['webkitFullscreenElement'] !== null) ||
    (document['mozFullScreenElement'] && document['mozFullScreenElement'] !== null) ||
    (document['msFullscreenElement'] && document['msFullscreenElement'] !== null);

  toggleFullScreen = () => {
    const isInFullScreen = this.isInFullScreen();

    const docElm = document.documentElement;
    if (!isInFullScreen) {
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
      } else if (docElm['mozRequestFullScreen']) {
        docElm['mozRequestFullScreen']();
      } else if (docElm['webkitRequestFullScreen']) {
        docElm['webkitRequestFullScreen']();
      } else if (docElm['msRequestFullscreen']) {
        docElm['msRequestFullscreen']();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document['webkitExitFullscreen']) {
        document['webkitExitFullscreen']();
      } else if (document['mozCancelFullScreen']) {
        document['mozCancelFullScreen']();
      } else if (document['msExitFullscreen']) {
        document['msExitFullscreen']();
      }
    }
    this.setState({
      isInFullScreen: !isInFullScreen
    });
  };

  render(): ReactNode {
    const {
      containerClassnames,
      menuClickCount,
      users,
      selectedUser,
      changeUser,
      history,
      merchants,
      selectedMerchants,
      changeMerchant,
      isAdmin,
      notifications,
      allowMultiPartner,
      t
    } = this.props;

    const merchantOptions =
      merchants && merchants.data
        ? merchants.data.map((merchant: Merchant) => ({ label: merchant.name, value: merchant.id }))
        : [];

    const usersOptions =
      users && users.data && users.data.length
        ? users.data.map((user: User) => ({ label: user.fio, value: user.id }))
        : [];

    const isTouchDevice = 'ontouchstart' in window || 'onmsgesturechange' in window;
    const fullScreenButtonStyle = `header-icon btn btn-empty pb-3 d-none ${!isTouchDevice &&
      'd-none d-md-inline-block'}`;

    return (
      <nav className="navbar fixed-top">
        <div className="d-flex align-items-center">
          <NavLink
            to="#"
            className="menu-button d-none d-lg-block"
            onClick={(e: MouseEvent) => this.menuButtonClick(e, menuClickCount, containerClassnames)}
          >
            <MenuIcon />
          </NavLink>
          <NavLink
            to="#"
            className="menu-button-mobile d-xs-block d-sm-block d-lg-none"
            onClick={(e: MouseEvent) => this.mobileMenuButtonClick(e, containerClassnames)}
          >
            <MobileMenuIcon />
          </NavLink>

          <LanguageSelect />
          {/*   TODO Раздел временно скрыт */}
          {/*<div className="search" data-search-path="/app/pages/search">*/}
          {/*  <Input*/}
          {/*    name="searchKeyword"*/}
          {/*    id="searchKeyword"*/}
          {/*    placeholder="Поиск"*/}
          {/*    value={this.state.searchKeyword}*/}
          {/*    onChange={(e: any) => this.handleSearchInputChange(e)}*/}
          {/*    onKeyPress={(e: any) => this.handleSearchInputKeyPress(e)}*/}
          {/*  />*/}
          {/*  {*/}
          {/*    // TODO Типизировать события*/}
          {/*  }*/}
          {/*  <span className="search-icon" onClick={(e: any) => this.handleSearchIconClick(e)}>*/}
          {/*    <i className="simple-icon-magnifier" />*/}
          {/*  </span>*/}
          {/*</div>*/}
        </div>

        <a className="navbar-logo" href="/">
          <span className="logo d-block" />
        </a>

        <div className="dropdowns d-block d-sm-inline-block">
          {!merchants.loading && merchants.data && merchants.data.length > 1 && (
            <div className="d-inline-block merchants-dropdown">
              {allowMultiPartner ? (
                <CustomDropdownTreeSelect
                  className="custom-tree-select topnav"
                  name="partner"
                  data={getPartnersOption(merchants.data, t)}
                  onChange={(changedValue, selectedValues) => {
                    const values =
                      selectedValues.length === 1 && !selectedValues[0].value
                        ? merchants.data
                        : selectedValues.map((item: TreeNode) => ({ name: item.label, id: item.value }));
                    changeMerchant(values);
                  }}
                />
              ) : (
                <Select
                  className="custom-dropdown-select"
                  classNamePrefix="react-select"
                  value={
                    selectedMerchants && selectedMerchants[0]
                      ? { label: selectedMerchants[0].name, value: selectedMerchants[0].id }
                      : ''
                  }
                  onChange={(option: SelectOption) => changeMerchant([{ name: option.label, id: option.value }])}
                  options={merchantOptions}
                  placeholder=""
                  noOptionsMessage={() => 'Нет данных'}
                />
              )}
            </div>
          )}

          {isAdmin && users.data && selectedUser && (
            <div
              className={`d-inline-block users-dropdown ${
                merchants.data && merchants.data.length <= 1 ? 'only-one' : ''
              }`}
            >
              <Select
                className="custom-dropdown-select"
                classNamePrefix="react-select"
                value={{ label: selectedUser.fio, value: selectedUser.id, key: selectedUser.id }}
                onChange={(option: SelectOption) =>
                  changeUser(users.data.find((user: User) => Number(user.id) === Number(option.value)))
                }
                options={usersOptions}
                placeholder=""
                noOptionsMessage={() => 'Нет данных'}
              />
            </div>
          )}
        </div>

        <div className="navbar-icons d-inline-block align-middle">
          <div className="user d-inline-block">
            <UncontrolledDropdown className="dropdown-menu-right">
              <DropdownToggle className="header-icon" color="empty">
                <i className="simple-icon-user" />
              </DropdownToggle>
              <DropdownMenu className="mt-3" right>
                <DropdownItem onClick={() => history.push('/profile')}>{t('topnav.user.profile')}</DropdownItem>
                <DropdownItem onClick={() => this.handleLogout()}>{t('topnav.user.exit')}</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>

          <div className="d-inline-block">
            <TopnavEasyAccess t={t} />
          </div>

          <div className="d-inline-block">
            <TopnavNotifications notifications={notifications} />
          </div>

          <div className="d-inline-block mr-3">
            <button
              className={fullScreenButtonStyle}
              type="button"
              id="fullScreenButton"
              onClick={this.toggleFullScreen}
            >
              {this.state.isInFullScreen ? (
                <i className="simple-icon-size-actual d-block" />
              ) : (
                <i className="simple-icon-size-fullscreen d-block" />
              )}
            </button>
          </div>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = ({ menu, settings, authUser, notifications }) => {
  const { containerClassnames, menuClickCount, selectedMenuHasSubItems, users, merchants } = menu;
  const { locale, selectedUser, selectedMerchants } = settings;

  const isAdmin = authUser.user.data && authUser.user.data.is_admin ? authUser.user.data.is_admin : false;

  return {
    containerClassnames,
    menuClickCount,
    selectedMenuHasSubItems,
    locale,
    selectedUser,
    users,
    selectedMerchants,
    merchants,
    isAdmin,
    notifications: notifications.data,
    isAuth: Boolean(!authUser.user.error && !authUser.user.loading && authUser.user.data),
    authUserId: authUser.user && authUser.user.data && authUser.user.data.id ? authUser.user.data.id : 0,
    allowMultiPartner: isAdmin
      ? selectedUser
        ? selectedUser.allow_multi_partner
        : false
      : authUser.user.data
      ? authUser.user.data.allow_multi_partner
      : false
  };
};

const mapDispatchToProps = {
  setContainerClassnames,
  clickOnMobileMenu,
  logoutUser,
  changeMerchant: settingsChangeMerchant,
  getMerchants: menuGetMerchants,
  changeLocale: settingsChangeLocale,
  changeUser: settingsChangeUser,
  getUsers: menuGetUsers,
  getNotificationsAction: getNotifications
};

export default withTranslation()(
  injectIntl(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(TopNav)
  )
);
