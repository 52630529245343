import { all, call, fork, put, takeLatest, SimpleEffect } from 'redux-saga/effects';
import { CombinatorEffect } from '@redux-saga/types';

import { ApiRequest, ContentType } from '../../helpers/ApiRequest/ApiRequest';
import {
  CHANGE_DISPOSAL_STATUS,
  CREATE_DISPOSAL,
  DELETE_DISPOSAL,
  GET_DISPOSALS,
  GET_DISPOSALS_PARTNERS,
  GET_STATEMENTS
} from '../actions';
import {
  changeDisposalStatusError,
  changeDisposalStatusSuccess,
  createDisposalError,
  createDisposalSuccess,
  deleteDisposalError,
  deleteDisposalSuccess,
  getAuthUserPartnersError,
  getAuthUserPartnersSuccess,
  getDisposalsError,
  getDisposalsSuccess,
  getStatementsError,
  getStatementsSuccess
} from './actions';
import { ChangeDisposalStatus, CreateDisposal, DeleteDisposal, GetDisposals, GetStatements } from './interface';
import { createQueryString } from '../../helpers/Utils';

// GET DISPOSALS

export function* getDisposals({ payload }: GetDisposals): Generator<SimpleEffect<string, any>, any, any> {
  const { user_id, values } = payload;

  try {
    const url = `/user/disposals/list/${user_id}/`;
    const { data, error } = yield call(
      ApiRequest.post,
      url,
      createQueryString(values),
      {},
      ContentType.ApplicationFormUrlencoded
    );

    if (!error) {
      yield put(getDisposalsSuccess(data));
    } else {
      yield put(getDisposalsError(`${error}`));
    }
  } catch (error) {
    yield put(getDisposalsError(`${error}`));
  }
}

// CREATE DISPOSAL
export function* createDisposal({ payload }: CreateDisposal): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { request } = payload;

    const { id, error } = yield call(
      ApiRequest.post,
      '/user/disposals/create/',
      createQueryString(request),
      {},
      ContentType.ApplicationFormUrlencoded
    );

    if (!error) {
      yield put(createDisposalSuccess(id));
    } else {
      yield put(createDisposalError(`${error}`));
    }
  } catch (error) {
    yield put(createDisposalError(`${error}`));
  }
}

// DELETE DISPOSALS
export function* deleteDisposal({ payload }: DeleteDisposal): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { error } = yield call(ApiRequest.remove, `/user/disposals/?${createQueryString(payload)}`, {});

    if (!error) {
      yield put(deleteDisposalSuccess());
    } else {
      yield put(deleteDisposalError(`${error}`));
    }
  } catch (error) {
    yield put(deleteDisposalError(`${error}`));
  }
}

// GET DISPOSALS PARTNERS
export function* getAuthUserPartners(): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { error, data } = yield call(ApiRequest.get, '/user/partners/');

    if (!error) {
      yield put(getAuthUserPartnersSuccess(data));
    } else {
      yield put(getAuthUserPartnersError(`${error}`));
    }
  } catch (error) {
    yield put(getAuthUserPartnersError(`${error}`));
  }
}

// CHANGE DISPOSALS STATUS
export function* changeDisposalStatus({
  payload
}: ChangeDisposalStatus): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { error } = yield call(ApiRequest.put, `/user/disposals/change/?${createQueryString(payload)}`, {});

    if (!error) {
      yield put(changeDisposalStatusSuccess());
    } else {
      yield put(changeDisposalStatusError(`${error}`));
    }
  } catch (error) {
    yield put(changeDisposalStatusError(`${error}`));
  }
}

// GET STATEMENTS
export function* getStatements({ payload }: GetStatements): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { error, data } = yield call(ApiRequest.get, `/user/statements/?${createQueryString(payload)}`);

    if (!error) {
      yield put(getStatementsSuccess(data));
    } else {
      yield put(getStatementsError(`${error}`));
    }
  } catch (error) {
    yield put(getStatementsError(`${error}`));
  }
}

export function* watchGetUserById(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(GET_DISPOSALS_PARTNERS, getAuthUserPartners);
}
export function* watchGetDisposals(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(GET_DISPOSALS, getDisposals);
}
export function* watchGetStatements(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(GET_STATEMENTS, getStatements);
}
export function* watchDeleteDisposal(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(DELETE_DISPOSAL, deleteDisposal);
}
export function* watchCreateDisposal(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(CREATE_DISPOSAL, createDisposal);
}
export function* watchChangeDisposalStatus(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(CHANGE_DISPOSAL_STATUS, changeDisposalStatus);
}

export default function* rootSaga(): Generator<CombinatorEffect<string, any>, any, any> {
  yield all([
    fork(watchGetUserById),
    fork(watchGetDisposals),
    fork(watchCreateDisposal),
    fork(watchDeleteDisposal),
    fork(watchGetStatements),
    fork(watchChangeDisposalStatus)
  ]);
}
