export const PASSWORD_MIN_LENGTH = 3;
export const LOGIN_MIN_LENGTH = 2;
export const GOOGLE_PASSWORD_LENGTH = 6;
export const SMS_PASSWORD_LENGTH = 6;

export enum CookieAuthStatus {
  Authorized = '0',
  GoogleRegReq = '1',
  GoogleAuthReq = '2',
  SmsAuthReq = '3'
}

export enum RESTORE_2FA_KEYS {
  RestoreSecondFactor = 1,
  ResetSecondFactor
}

export const RESTORE_2FA_TEXT = (t: any) => ({
  [RESTORE_2FA_KEYS.RestoreSecondFactor]: t('restore_2fa'),
  [RESTORE_2FA_KEYS.ResetSecondFactor]: t('reset_2fa')
});

export const RESTORE_2FA_LABEL = (t: any) => ({
  [RESTORE_2FA_KEYS.RestoreSecondFactor]: t('restore_2fa_header'),
  [RESTORE_2FA_KEYS.ResetSecondFactor]: t('reset_2fa_header')
});

export enum RESTORE_TYPES {
  RestorePassword,
  Restore2FA
}

export const RESTORE_LABELS = (t: any) => ({
  [RESTORE_TYPES.RestorePassword]: {
    text: t('login.restore-password'),
    type: RESTORE_TYPES.RestorePassword
  },
  [RESTORE_TYPES.Restore2FA]: {
    text: t('login.restore-2fa'),
    type: RESTORE_TYPES.Restore2FA
  }
});
