import React, { Component, Fragment, ReactNode } from 'react';
import { version } from '../config/appVersion.json';

class UserLayout extends Component<any, any> {
  componentDidMount(): void {
    document.body.classList.add('background');
  }

  componentWillUnmount(): void {
    document.body.classList.remove('background');
  }

  render(): ReactNode {
    return (
      <Fragment>
        <div className="fixed-background" />
        <main>
          <div className="container">{this.props.children}</div>
          <div className="app-version">v{version}</div>
        </main>
      </Fragment>
    );
  }
}

export default UserLayout;
