import {
  addDays,
  differenceInMonths,
  differenceInSeconds,
  differenceInYears,
  differenceInCalendarDays,
  startOfDay,
  addSeconds
} from 'date-fns';
import { TreeNode } from 'react-dropdown-tree-select';

import { defaultDirection } from '../constants/defaultValues';
import { AMOUNT_FRACTION_DIGITS, EMPTY_FIELD_ERROR, IncomePaymentMethodCodes, SOURCE_OPTIONS } from '../constants/app';
import format from '../localization';
import { ChartsPeriodValue } from '../views/statistics/interface';
import { MAX_DATE_LIMITS, MIN_DATE_LIMIT } from '../constants/dateValues';
import { Merchant } from '../redux/menu/interface';
import { ServiceTypes } from '../views/operations/OperationsList/constants';
import { DIRECTIONS_VALUES } from '../views/statistics/constants';
import { DestinationsData } from '../redux/operations/interface';
import i18n from '../i18n';

export const mapOrder = (array, order, key) => {
  array.sort((a, b) => {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const getDirection = () => {
  let direction = defaultDirection;
  if (localStorage.getItem('direction')) {
    const localValue = localStorage.getItem('direction');
    if (localValue === 'rtl' || localValue === 'ltr') {
      direction = localValue;
    }
  }
  return {
    direction,
    isRtl: direction === 'rtl'
  };
};

export const setDirection = (localValue) => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  localStorage.setItem('direction', direction);
};

export const localStorageValueToNumber = (value: string | null): number | undefined =>
  value === null ? undefined : parseInt(value, 10);

export const validateEmail = (value: string): boolean => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);

export const createQueryString = <T>(values: T): string =>
  Object.entries(values)
    .reduce((prevValue: (string | number)[], [key, value]: (string | number)[]) => {
      if (Array.isArray(value)) {
        return value.length
          ? [...prevValue, ...value.map((el: string | number) => `${key}=${encodeURIComponent(el)}`)]
          : prevValue;
      }
      return value === '' || value === undefined ? prevValue : [...prevValue, `${key}=${encodeURIComponent(value)}`];
    }, [])
    .join('&');

// export const getCookieValue = (cookie: string): string => {
//   const cookies = document.cookie.split(';');
//   const parsedCookies = new Map();
//
//   cookies.forEach((cookieString) => {
//     const [key, value] = cookieString.trim().split('=');
//     parsedCookies.set(key, value);
//   });
//
//   return parsedCookies.get(cookie);
// };

export const unmaskPhoneNumber = (phone: string): string => {
  const unmaskedPhone = phone.replace(/[-_()+]/g, '');
  if (unmaskedPhone) {
    return unmaskedPhone[0] === '+' ? unmaskedPhone : `+${unmaskedPhone}`;
  }

  return '';
};

export const fileToBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error: ProgressEvent<FileReader>) => reject(error);
  });

export const scrollToTarget = (target) => {
  const scrollOffset = 80;
  target &&
    window.scrollTo({
      top: target.offsetTop - scrollOffset,
      behavior: 'smooth'
    });
};

export const getFieldErrorsCount = (errors): number => Object.keys(errors).length;

export const setDateFrom = (prevDate, newDate) => {
  if (!newDate || !prevDate) {
    return newDate;
  }
  // return !isSameDay(newDate, prevDate) ? startOfDay(newDate) : newDate;

  return newDate;
};

export const setDateTo = (prevDate, newDate) => {
  if (!newDate || !prevDate) {
    return newDate;
  }

  // let curr = newDate;
  // if (!isSameDay(newDate, new Date()) && !isSameDay(newDate, prevDate)) {
  //   curr = endOfDay(newDate);
  // }

  // if (isSameDay(newDate, prevDate) || (isSameDay(newDate, new Date()) && isSameDay(prevDate, new Date()))) {
  //   curr = newDate;
  // }

  // if (isSameDay(newDate, new Date()) && !isSameDay(newDate, prevDate)) {
  //   curr = new Date();
  // }

  // return curr
  return newDate;
};

export const trimSpaces = (values) => {
  const items = values;

  if (items && typeof items === 'object' && Object.keys(items).length) {
    Object.keys(items).forEach(
      (prop: string | number) => (items[prop] = typeof items[prop] === 'string' ? items[prop].trim() : items[prop])
    );
    return items;
  }

  if (items && typeof items === 'string') {
    return items.trim();
  }

  return items;
};

export const roundAmount = (amount: number): string => {
  if (amount) {
    const amountString = parseFloat(amount.toString()).toFixed(2);
    return amountString.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }
  return '0.00';
};

export const roundDigit = (digit, fractionDigits = AMOUNT_FRACTION_DIGITS) => {
  if (digit) {
    return Number(digit)
      .toLocaleString('ru-RU', {
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits
      })
      .replace(',', '.');
  } else {
    return 0;
  }
};

export const toISOString = (dateValue: Date | string, offset: number = 3): string => {
  const date = new Date(dateValue);
  const timeZone = `+0${offset}:00`;
  return `${format(date, 'yyyy-MM-dd')}T${format(date, 'HH:mm:ss')}${timeZone}`;
};

export const toLocalTime = (dateValue: string | Date): Date => {
  if (!dateValue) {
    return new Date();
  }

  // date comes from backend in string format
  if (typeof dateValue === 'string') {
    const reg = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2})[0-9.:]*[+](\d{2,4}):(\d{2,4})*/;

    if (Boolean(dateValue.match(reg))) {
      const plusIndex = dateValue.lastIndexOf('+');
      const toString = plusIndex && dateValue.substring(0, plusIndex);
      return new Date(toString);
    } else {
      const data = new Date(dateValue);
      const timezoneIndex = 60000;
      return new Date(data.valueOf() + data.getTimezoneOffset() * timezoneIndex);
    }
  }

  return new Date(dateValue);
};

export function toBinary(str: string): string {
  const codeUnits = new Uint16Array(str.length);
  for (let i = 0; i < codeUnits.length; i++) {
    codeUnits[i] = str.charCodeAt(i);
  }
  // @ts-ignore
  return btoa(String.fromCharCode(...new Uint8Array(codeUnits.buffer)));
}

export function fromBinary(bin: string): string {
  const decodedBin = atob(bin);
  const bytes = new Uint8Array(decodedBin.length);
  for (let i = 0; i < bytes.length; i++) {
    bytes[i] = decodedBin.charCodeAt(i);
  }
  // @ts-ignore
  return String.fromCharCode(...new Uint16Array(bytes.buffer));
}

export const chartValidation = (values, t, validateСurrency = true) => {
  const { dateFrom, dateTo, period, currency } = values;
  const errors: { [key: string]: string } = {};

  if (values.direction === null) {
    errors['direction'] = EMPTY_FIELD_ERROR;
  }

  if (currency === null || (validateСurrency && !currency.value)) {
    errors['currency'] = i18n.t('choose_currency');
  }

  const diffInSec = dateFrom && dateTo ? differenceInSeconds(dateTo, dateFrom) : 0;
  const diffInMonths = dateFrom && dateTo ? differenceInMonths(dateTo, addDays(dateFrom, 1)) : 0;
  const diffInYears = dateFrom && dateTo ? differenceInYears(dateTo, addDays(dateFrom, 1)) : 0;

  if (diffInSec < 1 && ChartsPeriodValue.Hour === period.value) {
    errors['dateFrom'] = MIN_DATE_LIMIT.text;
  }

  switch (period.value) {
    case ChartsPeriodValue.Hour:
      if (diffInSec > MAX_DATE_LIMITS(t)[ChartsPeriodValue.Hour].value) {
        errors['dateFrom'] = MAX_DATE_LIMITS(t)[ChartsPeriodValue.Hour].text;
      }
      break;
    case ChartsPeriodValue.Day:
      if (diffInMonths >= MAX_DATE_LIMITS(t)[ChartsPeriodValue.Day].value) {
        errors['dateFrom'] = MAX_DATE_LIMITS(t)[ChartsPeriodValue.Day].text;
      }
      break;
    case ChartsPeriodValue.Week:
      if (diffInYears >= MAX_DATE_LIMITS(t)[ChartsPeriodValue.Week].value) {
        errors['dateFrom'] = MAX_DATE_LIMITS(t)[ChartsPeriodValue.Week].text;
      }
      break;
    case ChartsPeriodValue.Month:
      if (diffInYears >= MAX_DATE_LIMITS(t)[ChartsPeriodValue.Month].value) {
        errors['dateFrom'] = MAX_DATE_LIMITS(t)[ChartsPeriodValue.Month].text;
      }
      break;
    default:
      break;
  }
  return errors;
};

export const prepareDateByPeriod = (dateFrom, dateTo, period) => {
  const periodIsHour = period === ChartsPeriodValue.Hour;

  const diffInDays = differenceInCalendarDays(dateTo, new Date());

  const dateFromValue = periodIsHour ? toISOString(dateFrom) : toISOString(startOfDay(dateFrom));
  const dateToValue =
    !periodIsHour && diffInDays ? toISOString(startOfDay(addDays(dateTo, 1))) : toISOString(addSeconds(dateTo, 1));

  return {
    dateTo: dateToValue,
    dateFrom: dateFromValue
  };
};

export const setDirectionsData = (selectedValues: TreeNode[], useNullOnEmpty: boolean = false) => {
  if (selectedValues && selectedValues.length === 1 && !selectedValues[0].value) {
    if (useNullOnEmpty) {
      return null;
    } else {
      return {
        value: {
          source: selectedValues[0].source,
          destination: [DIRECTIONS_VALUES.WP, DIRECTIONS_VALUES.PT, DIRECTIONS_VALUES.FP]
        }
      };
    }
  } else {
    let sources: number[] = [];
    selectedValues.forEach((item: TreeNode) => {
      sources = sources.concat(item.source);
    });

    const reducedSources = sources.filter((value: number, index: number) => value && sources.indexOf(value) === index);

    let destinations: number[] = [];
    selectedValues.forEach((item: TreeNode) => {
      if (Array.isArray(item.destination)) {
        destinations = [...destinations, ...item.destination];
      } else {
        destinations.push(item.destination);
      }
    });

    const reducedDestinations = destinations.filter(
      (value: number, index: number) => value && destinations.indexOf(value) === index
    );

    if (reducedSources.find((item: number) => item === IncomePaymentMethodCodes.Acquiring)) {
      reducedSources.push(IncomePaymentMethodCodes.Acquiring2);
      reducedSources.push(IncomePaymentMethodCodes.Acquiring3);
    }

    if (selectedValues && selectedValues.length) {
      return {
        value: {
          source: reducedSources,
          destination: reducedDestinations
        }
      };
    } else {
      return null;
    }
  }
};

export const directionsOptions = (selectedService: number, destinations, t: any): TreeNode[] => {
  if (selectedService === ServiceTypes.Outcome && destinations) {
    return [
      {
        label: t('all'),
        value: t('all'),
        checked: true,
        source: [DIRECTIONS_VALUES.WP, DIRECTIONS_VALUES.CB],
        children: [
          ...destinations.map((destination: DestinationsData) => ({
            label: destination.name,
            value: [DIRECTIONS_VALUES.WP, DIRECTIONS_VALUES.CB],
            expanded: true,
            key: destination.id,
            source: [DIRECTIONS_VALUES.WP, DIRECTIONS_VALUES.CB],
            destination: destination.id
          }))
        ]
      }
    ];
  } else {
    return SOURCE_OPTIONS(t);
  }
};

export const getMerchantIdList = (merchants: Merchant[]): number[] =>
  merchants && merchants.length ? merchants.map((merchant: Merchant) => merchant && Number(merchant.id)) : [];
