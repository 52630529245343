import React, { Component, ReactNode } from 'react';
import { Button, FormGroup, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import { Field, Form, Formik, FormikValues } from 'formik';
import { intFieldValue } from '../../operations/helpers';
import { Colxx } from '../../../components/common/CustomBootstrap';
import { ConfirmationModalProps, ConfirmationModalState, MAX_PAYOUT_CONFIRMATION_ATTEMPTS } from '../interface';

export class ConfirmationModal extends Component<ConfirmationModalProps, ConfirmationModalState> {
  constructor(props: ConfirmationModalProps) {
    super(props);
    this.interval = (): void => {};
    this.values = { code: '' };
    this.state = {
      code: '',
      minutes: this.minutesCount(),
      seconds: this.secondsCount()
    };
  }
  private interval;
  private values: FormikValues;

  componentDidUpdate(
    prevProps: Readonly<ConfirmationModalProps>,
    prevState: Readonly<ConfirmationModalState>,
    snapshot?: any
  ): void {
    const { onAttemptsIsOver, attempts, modalIsOpen, counter, init, onInit } = this.props;

    if (init && init !== prevProps.init) {
      this.setState({
        minutes: this.minutesCount(),
        seconds: this.secondsCount()
      });
      this.runTimer(this.props.onTimeIsOver);
      onInit();
    }

    if (attempts <= 0 && prevProps.attempts !== attempts) {
      onAttemptsIsOver();
    }

    if (modalIsOpen && counter > 0 && (modalIsOpen !== prevProps.modalIsOpen || counter !== prevProps.counter)) {
      this.runTimer(this.props.onTimeIsOver);
    }
  }

  componentWillUnmount(): void {
    clearInterval(this.interval);
  }

  minutesCount = (): number => Math.floor(this.props.counter / 60) % 60;
  secondsCount = (): number => Math.floor(this.props.counter - this.minutesCount() * 60);

  runTimer = (cb: () => void): void => {
    clearInterval(this.interval);
    this.interval = setInterval(() => {
      if (this.state.seconds === 0) {
        if (this.state.minutes === 0) {
          clearInterval(this.interval);
          cb();
        } else {
          this.setState(({ minutes }) => ({
            minutes: minutes - 1,
            seconds: 59
          }));
        }
      } else {
        this.setState(({ seconds }) => ({
          seconds: seconds - 1
        }));
      }
    }, 1000);
  };

  hidePhoneChars = (phone: string): string => {
    const chars = phone.split('');
    const length = chars.length;
    return `${chars[0]}${chars[1]} *** ***${chars[length - 4]}${chars[length - 3]}${chars[length - 2]}${
      chars[length - 1]
    }`;
  };

  validate = (values) => {
    const errors: { [key: string]: string } = {};

    if (String(values.code).length <= 1) {
      errors.code = 'payouts.error.codeLength';
    }

    return errors;
  };

  render(): ReactNode {
    const {
      modalIsOpen,
      onCloseModal,
      userPhone,
      attempts,
      confirmationLabel = 'payouts.inputCodeToConfirm'
    } = this.props;
    const { minutes, seconds } = this.state;
    return (
      <Modal isOpen={modalIsOpen} toggle={onCloseModal}>
        <ModalHeader toggle={onCloseModal}>
          <IntlMessages id="payouts.actionConfirm" />
        </ModalHeader>
        <ModalBody>
          <div style={{ fontSize: 15 }}>
            <p>
              <IntlMessages id="payouts.toNumber" /> {this.hidePhoneChars(userPhone)}{' '}
              <IntlMessages id="payouts.smsWasSend" />
            </p>
            <p>
              <IntlMessages id={confirmationLabel} />
            </p>
            {attempts < MAX_PAYOUT_CONFIRMATION_ATTEMPTS && (
              <p className="text-danger">
                <IntlMessages id="payouts.attemptsWarning" />
                <br />
                <IntlMessages id="payouts.attemptsLeft" /> {attempts}
              </p>
            )}
            <Formik
              initialValues={this.state}
              onSubmit={() => {
                this.props.onSubmit(this.values.code);
              }}
              validate={this.validate}
            >
              {({ values, setFieldValue, setFieldTouched, isValid, errors, touched, resetForm }) => {
                this.values = values;
                return (
                  <Form>
                    <Colxx xxs="6" sm="6" md="6" lg="6" className="pl-0 pt-3">
                      <FormGroup className="has-float-label mb-0">
                        <Label className="float-label">
                          <IntlMessages id="payouts.code" />
                        </Label>
                        <Field
                          className="form-control"
                          type="text"
                          name="code"
                          onChange={({ target: { value } }) => intFieldValue(value, 'code', setFieldValue)}
                          minLength="1"
                          maxLength="6"
                        />
                      </FormGroup>
                      <p className="small text-muted">
                        <IntlMessages id="payouts.codeDeadline" /> {minutes}:{seconds < 10 ? '0' : ''}
                        {seconds}
                      </p>
                    </Colxx>
                    <Colxx xxs="12" className="d-flex flex-row justify-content-end align-items-center px-0">
                      <Button
                        color="primary"
                        type="button"
                        className="mr-2"
                        onClick={() => {
                          this.props.onCloseModal();
                        }}
                      >
                        <IntlMessages id="button.cancel" />
                      </Button>
                      <Button color="primary" type="submit" disabled={!isValid}>
                        <IntlMessages id="button.confirm" />
                      </Button>
                    </Colxx>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}
