import {
  SEND_SUPPORT_MESSAGE,
  SEND_SUPPORT_MESSAGE_SUCCESS,
  SEND_SUPPORT_MESSAGE_ERROR,
  GET_ALL_REQUESTS,
  GET_ALL_REQUESTS_ERROR,
  GET_ALL_REQUESTS_SUCCESS,
  GET_REQUEST_SUCCESS,
  GET_REQUEST,
  GET_REQUEST_ERROR,
  RESET_REQUESTS,
  OPEN_REQUEST_SUCCESS,
  OPEN_REQUEST,
  OPEN_REQUEST_ERROR,
  GET_REQUEST_TYPES_ERROR,
  GET_REQUEST_TYPES_SUCCESS,
  GET_REQUEST_TYPES
} from '../actions';

import { TechnicalSupportInterface } from './interface';
import { addNewMessage } from '../../views/support/helpers';

const INIT_STATE: TechnicalSupportInterface = {
  loading: false,
  error: '',
  requests: {
    activeRequests: null,
    archiveRequests: null,
    loading: false,
    error: ''
  },
  currentRequest: {
    data: null,
    loading: false,
    error: ''
  },
  openRequest: {
    loading: false,
    error: ''
  },
  sendMessage: {
    loading: false,
    error: ''
  },
  types: {
    data: null,
    loading: false,
    error: ''
  }
};

export default (state: TechnicalSupportInterface = INIT_STATE, { type, payload }): TechnicalSupportInterface => {
  switch (type) {
    case GET_ALL_REQUESTS:
      return {
        ...state,
        requests: {
          ...state.requests,
          loading: true,
          error: ''
        }
      };
    case GET_ALL_REQUESTS_SUCCESS:
      return {
        ...state,
        requests: {
          activeRequests: payload.requests,
          archiveRequests: payload.requests,
          loading: false,
          error: ''
        }
      };
    case GET_ALL_REQUESTS_ERROR:
      return {
        ...state,
        requests: {
          activeRequests: null,
          archiveRequests: null,
          loading: false,
          error: payload.error
        }
      };

    case GET_REQUEST:
      return {
        ...state,
        currentRequest: {
          ...state.currentRequest,
          loading: true,
          error: ''
        }
      };
    case GET_REQUEST_SUCCESS:
      return {
        ...state,
        currentRequest: {
          data: payload.currentRequest,
          loading: false,
          error: ''
        }
      };
    case GET_REQUEST_ERROR:
      return {
        ...state,
        currentRequest: {
          data: null,
          loading: false,
          error: payload.error
        }
      };

    case SEND_SUPPORT_MESSAGE:
      return {
        ...state,
        sendMessage: {
          loading: true,
          error: ''
        }
      };
    case SEND_SUPPORT_MESSAGE_SUCCESS:
      return {
        ...state,
        sendMessage: {
          loading: false,
          error: ''
        },
        currentRequest: {
          ...state.currentRequest,
          data: addNewMessage(state.currentRequest.data, payload.message, payload.file),
          loading: false,
          error: ''
        }
      };
    case SEND_SUPPORT_MESSAGE_ERROR:
      return {
        ...state,
        sendMessage: {
          error: payload.error,
          loading: false
        }
      };

    case OPEN_REQUEST:
      return {
        ...state,
        openRequest: {
          loading: true,
          error: ''
        }
      };
    case OPEN_REQUEST_SUCCESS:
      return {
        ...state,
        openRequest: {
          loading: false,
          error: ''
        }
      };
    case OPEN_REQUEST_ERROR:
      return {
        ...state,
        openRequest: {
          error: payload.error,
          loading: false
        }
      };

    case GET_REQUEST_TYPES:
      return {
        ...state,
        types: {
          ...state.types,
          loading: true,
          error: ''
        }
      };
    case GET_REQUEST_TYPES_SUCCESS:
      return {
        ...state,
        types: {
          data: payload.types,
          loading: false,
          error: ''
        }
      };
    case GET_REQUEST_TYPES_ERROR:
      return {
        ...state,
        types: {
          data: null,
          loading: false,
          error: payload.error
        }
      };

    case RESET_REQUESTS:
      return {
        ...state,
        requests: {
          ...INIT_STATE.requests
        }
      };
    default:
      return { ...state };
  }
};
