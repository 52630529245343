import { all, call, fork, put, takeLatest, SimpleEffect } from 'redux-saga/effects';
import { CombinatorEffect } from '@redux-saga/types';

import { ApiRequest } from '../../helpers/ApiRequest/ApiRequest';
import { GetUserLogsAction } from './interface';
import { getUserLogsError, getUserLogsSuccess } from './actions';
import { GET_USER_LOGS } from '../actions';
import { createQueryString } from '../../helpers/Utils';

// GET USER LOGS
function* getUserLogs({ payload }: GetUserLogsAction): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { user_id, ...params } = payload;
    const url = user_id
      ? `/users/${user_id}/logs/?${createQueryString(params)}`
      : `/logs/?${createQueryString(params)}`;

    const { count, data, error } = yield call(ApiRequest.get, url);

    if (error) {
      yield put(getUserLogsError(`${error}`));
    } else {
      yield put(getUserLogsSuccess(data, count));
    }
  } catch (error) {
    yield put(getUserLogsError(`${error}`));
  }
}

export function* watchGetUserLogs(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(GET_USER_LOGS, getUserLogs);
}

export default function* rootSaga(): Generator<CombinatorEffect<string, any>, any, any> {
  yield all([fork(watchGetUserLogs)]);
}
