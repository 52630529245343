import React, { Component, Fragment, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, Row } from 'reactstrap';
import ReactTable from 'react-table';

import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import { Loader } from '../../../components/common/Loader';
import {
  getServicesByMerchant,
  getServiceTypeDocs,
  deleteServiceTypeDocs,
  postServiceTypeDocs
} from '../../../redux/actions';
import DataTablePagination from '../../../components/DatatablePagination';
import { NotificationManager } from '../../../components/common/react-notifications';
import ServiceContent from './ServiceContent';
import Breadcrumb from '../../../containers/navs/Breadcrumb';
import { withTranslation } from 'react-i18next';
import { NOTIFICATION_TIMEOUT } from '../../../constants/defaultValues';
import { ServiceTypes } from '../../operations/OperationsList/constants';
import { MAX_FILE_SIZE, getTableColumns, TABLE_ROWS } from './constants';
import { GlossaryServiceTableRow, ServicesListProps, ServicesListState } from './interface';

class ServicesList extends Component<ServicesListProps, ServicesListState> {
  state: ServicesListState = {
    expanded: {},
    lastChangedService: null,
    lastLoadedService: null
  };

  componentDidUpdate(prevProps: Readonly<ServicesListProps>, prevState: Readonly<ServicesListState>): void {
    const { error, removing, saving, getServiceTypeDocsAction } = this.props;
    const { lastChangedService } = this.state;

    if (prevProps.error !== error && error) {
      NotificationManager.info(error, 'Ошибка', NOTIFICATION_TIMEOUT, null, null, '');
    }

    if (!removing && prevProps.removing !== removing && !error && lastChangedService) {
      getServiceTypeDocsAction(lastChangedService);
    }

    if (!saving && prevProps.saving !== saving && !error && lastChangedService) {
      getServiceTypeDocsAction(lastChangedService);
    }
  }

  sendFile = (files: FileList | null) => {
    const { postServiceTypeDocsAction } = this.props;
    const { lastLoadedService } = this.state;

    const message = 'Превышен максимальный размер файла (50 МБ)';
    const rangeAmount = 1024;

    if (files && files.length && lastLoadedService) {
      if (files[0].size / rangeAmount / rangeAmount > MAX_FILE_SIZE) {
        NotificationManager.info(message, 'Ошибка', NOTIFICATION_TIMEOUT, null, null, '');
      } else {
        this.setState({ lastChangedService: lastLoadedService });
        postServiceTypeDocsAction(lastLoadedService, files[0]);
      }
    }
  };

  handleLastChangedService = (serviceType: ServiceTypes) => {
    this.setState({ lastChangedService: serviceType });
  };

  handleLastLoadedService = (serviceType: ServiceTypes) => {
    this.setState({ lastLoadedService: serviceType });
  };

  render(): ReactNode {
    const {
      data,
      loading,
      removing,
      saving,
      getServiceTypeDocsAction,
      deleteServiceTypeDocsAction,
      match,
      t
    } = this.props;
    const { expanded } = this.state;

    const preparedData = TABLE_ROWS.map((row: GlossaryServiceTableRow) => ({ ...row, data: data[row.serviceType] }));

    return (
      <Fragment>
        <Row className="flex-shrink-0">
          <Colxx xxs="12">
            <Breadcrumb heading="menu.settings" match={match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>

        {(loading || removing || saving) && <Loader />}

        <Row className="mb-4">
          <Colxx xxs="12">
            <Card>
              <CardBody>
                <ReactTable
                  data={preparedData}
                  columns={getTableColumns(t)}
                  defaultPageSize={0}
                  onExpandedChange={(newExpanded: object, [expandedRowNumber]: number[]) => {
                    if (newExpanded[expandedRowNumber]) {
                      !data[expandedRowNumber + 1] && getServiceTypeDocsAction(expandedRowNumber + 1);
                    }
                    this.setState({ expanded: newExpanded });
                  }}
                  sortable={false}
                  filterable={false}
                  showPagination={false}
                  showPageJump={false}
                  showPageSizeOptions={false}
                  expanded={expanded}
                  loading={loading}
                  PaginationComponent={DataTablePagination}
                  SubComponent={({ original }) => (
                    <ServiceContent
                      serviceType={original.serviceType}
                      services={original.data}
                      onRemove={deleteServiceTypeDocsAction}
                      onSubmit={this.sendFile}
                      onLoad={this.handleLastLoadedService}
                      onServiceChange={this.handleLastChangedService}
                    />
                  )}
                  loadingText=""
                  noDataText=""
                  manual
                />
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ glossary }) => {
  const { services } = glossary;

  return {
    data: services.data,
    loading: services.loading,
    saving: services.saving,
    removing: services.removing,
    error: services.error
  };
};

const mapDispatchToProps = {
  getServicesByMerchantAction: getServicesByMerchant,
  getServiceTypeDocsAction: getServiceTypeDocs,
  deleteServiceTypeDocsAction: deleteServiceTypeDocs,
  postServiceTypeDocsAction: postServiceTypeDocs
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ServicesList)
);
