import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import UserProfile from './Profile/UserProfile';
import ChangePasswordLayout from './ChangePassword/Layout';
import { ROUTE_TMP_CODE_LABEL } from '../../constants/app';

const Profile = ({ match }) => {
  const tmpCode = new URLSearchParams(window.location.search).get(ROUTE_TMP_CODE_LABEL);

  return !tmpCode ? (
    <Switch>
      <Route exact path={`${match.url}/`} component={UserProfile} />
      <Route path={`${match.url}/change-password`} component={ChangePasswordLayout} />
    </Switch>
  ) : (
    <Redirect to={`/user/forgot-password?code=${tmpCode}`} />
  );
};

export default Profile;
