import React, { PureComponent, ReactNode } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, FormGroup, Label, Input } from 'reactstrap';

import { ModalConfirmStatusChangeProps, ModalConfirmStatusChangeState } from './interface';
import { CONFIRMATION_TEXT } from './constants';

import { Form } from 'formik';
import { withTranslation } from 'react-i18next';

class ModalConfirmStatusChange extends PureComponent<ModalConfirmStatusChangeProps, ModalConfirmStatusChangeState> {
  constructor(props: ModalConfirmStatusChangeProps) {
    super(props);
    const { mustBeChanged } = this.props;
    this.state = {
      comment: mustBeChanged ? mustBeChanged.comment : ''
    };
  }
  onSubmit = () => {
    const { onConfirmModal, mustBeChanged, onCloseModal } = this.props;
    const { comment } = this.state;

    mustBeChanged && onConfirmModal(mustBeChanged.id, mustBeChanged.status, comment);
    onCloseModal();
  };

  render(): ReactNode {
    const { onCloseModal, mustBeChanged, t } = this.props;
    const modalText = (mustBeChanged && CONFIRMATION_TEXT(t)[mustBeChanged.status]) || CONFIRMATION_TEXT(t)['default'];
    const { comment } = this.state;
    return (
      <Modal isOpen={true} toggle={onCloseModal}>
        <ModalHeader toggle={onCloseModal}>{modalText.title}</ModalHeader>
        <ModalBody>
          <div className="mb-5">{modalText.body}</div>

          <Form>
            <FormGroup className="has-float-label">
              <Label for="currency" className="float-label">
                {t('comment')}
              </Label>
              <Input
                maxLength={210}
                type="textarea"
                name="currency"
                placeholder={t('tooltip.add_comment')}
                className="form-control"
                value={comment}
                onChange={({ target: { value } }) => {
                  this.setState({
                    comment: value
                  });
                }}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.onSubmit}>
            {t('yes')}
          </Button>{' '}
          <Button color="secondary" onClick={onCloseModal}>
            {t('no')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default withTranslation()(ModalConfirmStatusChange);
