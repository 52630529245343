import React, { Component, ReactNode } from 'react';
import { Button, FormGroup, Input, Label, CardTitle } from 'reactstrap';
import { connect } from 'react-redux';
import { Form, Formik } from 'formik';

import { RESTORE_2FA_KEYS, RESTORE_2FA_TEXT, RESTORE_2FA_LABEL } from '../Login/constant';
import { restore2FA } from '../../../redux/authUser/actions';
import { RestoreTypeSelectProps } from './interface';
import { withTranslation } from 'react-i18next';

class Restore2FA extends Component<RestoreTypeSelectProps> {
  render(): ReactNode {
    const { restoreCodeAction, loading, t } = this.props;

    return (
      <Formik initialValues={{ restoreType: RESTORE_2FA_KEYS.RestoreSecondFactor }} onSubmit={restoreCodeAction}>
        {({ values, setFieldValue }) => (
          <Form className="av-tooltip tooltip-label-bottom">
            <CardTitle className="mb-4">
              <Label for="reset_type">{RESTORE_2FA_LABEL(t)[values.restoreType]}</Label>
            </CardTitle>
            <FormGroup className="form-group ml-4">
              <Input
                type="radio"
                id="restore_type"
                name="restoreType"
                checked={values.restoreType === RESTORE_2FA_KEYS.RestoreSecondFactor}
                onChange={() => setFieldValue('restoreType', RESTORE_2FA_KEYS.RestoreSecondFactor)}
              />
              <Label for="restore_type">{RESTORE_2FA_TEXT(t)[RESTORE_2FA_KEYS.RestoreSecondFactor]}</Label>
            </FormGroup>
            <FormGroup className="form-group ml-4">
              <Input
                type="radio"
                id="reset_type"
                name="restoreType"
                checked={values.restoreType === RESTORE_2FA_KEYS.ResetSecondFactor}
                onChange={() => setFieldValue('restoreType', RESTORE_2FA_KEYS.ResetSecondFactor)}
              />
              <Label for="reset_type">{RESTORE_2FA_TEXT(t)[RESTORE_2FA_KEYS.ResetSecondFactor]}</Label>
            </FormGroup>
            <div className="d-flex justify-content-start">
              <Button type="submit" color="primary" className={`btn-multiple-state ${loading ? 'show-spinner' : ''}`}>
                <span className="spinner d-inline-block">
                  <span className="bounce1" />
                  <span className="bounce2" />
                  <span className="bounce3" />
                </span>
                <span className="label">{RESTORE_2FA_TEXT(t)[values.restoreType]}</span>
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

const mapDispatchToProps = {
  restoreCodeAction: restore2FA
};

export default withTranslation()(
  connect(
    null,
    mapDispatchToProps
  )(Restore2FA)
);
