import { Service } from '../../../redux/services/interface';
import format from '../../../localization';
import { DATE_FORMAT_BASIC } from '../../../constants/app';

export const reduceData = (data: Service[], t) => {
  if (!data || !Object.keys(data).length) {
    return {};
  } else {
    const convertedData = {};
    data.forEach((item: Service) => {
      const key = `${t('services.contract')} ${item.contract_name} ${t('services.from')} ${format(
        item.contract_date,
        DATE_FORMAT_BASIC
      )}`;
      if (!convertedData[key]) {
        convertedData[key] = {
          data: [],
          key,
          contract_name: item.contract_name,
          contract_date: item.contract_date
        };
      }
      convertedData[key].data.push(item);
    });

    return sortByDate(convertedData);
  }
};

export const sortByDate = (data: Object) => {
  if (!(data && Object.keys(data).length)) {
    return {};
  }

  const arrayData = Object.keys(data).map((key: string) => [key, data[key]]);
  const objectData = {};

  const sortedArrayData = arrayData.sort((item1, item2) => {
    const value1 = item1[1];
    const value2 = item2[1];
    const date1 = new Date(value1.contract_date);
    const date2 = new Date(value2.contract_date);

    if (date1 > date2) {
      return -1;
    }
    if (date1 < date2) {
      return 1;
    }
    return 0;
  });

  sortedArrayData.forEach((item) => {
    const [key, value] = item;
    objectData[key] = {
      ...value,
      data: sortByStatus(value.data)
    };
  });
  return objectData;
};

export const sortByStatus = (data: Service[]) => {
  if (!(data && data.length)) {
    return [];
  }
  const active = data.filter((item: Service) => item.is_active);
  const notActive = data.filter((item: Service) => !item.is_active);

  const sortedActive = sortByAlphabet(active);
  const sortedNotActive = sortByAlphabet(notActive);

  return sortedActive.concat(sortedNotActive);
};

export const sortByAlphabet = (data: Service[]) => {
  if (!(data && data.length)) {
    return [];
  }
  return data.sort((item1, item2) => {
    const nameA = item1.name.toLowerCase();
    const nameB = item2.name.toLowerCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
};
