import { all, fork, put, takeLatest, SimpleEffect, call } from 'redux-saga/effects';
import { CombinatorEffect } from '@redux-saga/types';

import {
  getNotificationsError,
  getNotificationsSuccess,
  getPayoutNotificationError,
  readNotificationError,
  readNotificationSuccess
} from './actions';
import { GET_NOTIFICATIONS, GET_PAYOUT_NOTIFICATION, READ_NOTIFICATION } from '../actions';
import { ApiRequest } from '../../helpers/ApiRequest/ApiRequest';
import { createQueryString } from '../../helpers/Utils';

import { GetPayoutNotifications, ReadNotification, GetNotifications } from './interface';

function* getNotifications({ payload }: GetNotifications): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { error, data, count, unread } = yield call(
      ApiRequest.get,
      `/user/notifications/?${createQueryString(payload)}`
    );

    if (error) {
      yield put(getNotificationsError(`${error}`));
    } else {
      yield put(getNotificationsSuccess(data, count, unread));
    }
  } catch (error) {
    yield put(getNotificationsError(`${error}`));
  }
}

function* readNotification({ payload }: ReadNotification): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { id } = payload;

    const formData = new FormData();
    id.forEach((itemId, index) => formData.append('id', String(itemId)));

    const { error } = yield call(ApiRequest.put, '/user/notifications/', formData);
    if (error) {
      yield put(readNotificationError(`${error}`));
    } else {
      yield put(readNotificationSuccess(id));
    }
  } catch (error) {
    yield put(readNotificationError(`${error}`));
  }
}

function* getPayoutNotification({ payload }: GetPayoutNotifications): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { id } = payload;
    const { error } = yield call(ApiRequest.post, '/payout/', { id });

    if (error) {
      yield put(getPayoutNotificationError(`${error}`));
    }
  } catch (error) {
    yield put(getPayoutNotificationError(`${error}`));
  }
}

export function* watchGetNotifications(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(GET_NOTIFICATIONS, getNotifications);
}
export function* watchReadNotification(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(READ_NOTIFICATION, readNotification);
}
export function* watchGetPayoutNotification(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(GET_PAYOUT_NOTIFICATION, getPayoutNotification);
}

export default function* rootSaga(): Generator<CombinatorEffect<string, any>, any, any> {
  yield all([fork(watchGetNotifications), fork(watchReadNotification), fork(watchGetPayoutNotification)]);
}
