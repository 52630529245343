import React from 'react';
import { CellInfo } from 'react-table';
import { toISOString } from '../../helpers/Utils';
import format from '../../localization';

import {
  CREATE_DISPOSAL_TYPE,
  DISPOSAL_ACTIVE_ACCOUNTS_SOURCE,
  DISPOSAL_ACTIVE_ACCOUNTS_SOURCE_TYPE,
  DISPOSAL_ACTIVE_STATEMENTS_SOURCE_TYPE,
  CREATE_DISPOSAL_TYPE_OPTIONS,
  DisposalStatus,
  STATEMENT_TYPE_OPTIONS,
  DisposalStatusNames,
  SumStatementType,
  CreateDisposalType
} from './constants';
import { DisposalsRequest, StatementItem } from '../../redux/disposals/interface';
import { SelectorOption } from '../../interfaces/app';
import { CurrenciesData } from '../../redux/operations/interface';
import { Service } from '../../redux/services/interface';
import { Merchant } from '../../redux/menu/interface';
import { CustomInput } from 'reactstrap';
import { TreeNode } from 'react-dropdown-tree-select';
import { DATE_TIME_FORMAT, DATE_FORMAT_BASIC, ALL_CURRENCY_OPTION, CurrenciesOptions } from '../../constants/app';

export const getDisposalsTableColumns = (
  isAdmin: boolean,
  currencies,
  partners,
  accounts,
  t: any,
  selectDisposal: (id: number, checked: boolean) => void,
  selectedDisposals: number[] = []
) => [
  {
    Header: t('disposals.id'),
    accessor: 'id',
    Cell: ({ value, original, row }: CellInfo) => (
      <CustomInput
        type="checkbox"
        label={original.id}
        key={`${original.id}_is_active`}
        id={`${original.id}_is_active`}
        name={`${original.id}_is_active`}
        checked={selectedDisposals.includes(original.id)}
        disabled={[DisposalStatus.Completed, DisposalStatus.Canceled].includes(row.status_id)}
        onChange={({ target: { checked } }) => selectDisposal(original.id, checked)}
      />
    )
  },
  {
    Header: t('disposals.partner'),
    accessor: 'partner_id',
    Cell: ({ value }: CellInfo) => {
      const currPartner = partners && partners.length && partners.find((partner: Merchant) => partner.id === value);
      return currPartner ? currPartner.name : '';
    }
  },
  {
    Header: t('disposals.Create date'),
    accessor: 'created_at',
    Cell: ({ value }: CellInfo) => (
      <span data-tip={format(value, DATE_TIME_FORMAT)}>{format(value, DATE_FORMAT_BASIC)}</span>
    )
  },
  {
    Header: t('disposals.Processed date'),
    accessor: 'updated_at',
    Cell: ({ value }: CellInfo) => (
      <span data-tip={format(value, DATE_TIME_FORMAT)}>{format(value, DATE_FORMAT_BASIC)}</span>
    )
  },
  {
    Header: t('period.Destination'),
    accessor: 'type_id',
    Cell: ({ value }: CellInfo) => t(CREATE_DISPOSAL_TYPE[value])
  },
  {
    Header: t('settings.currency'),
    accessor: 'currency',
    Cell: ({ value }: CellInfo) => {
      const currCurrency =
        currencies && currencies.length && currencies.find((currency: CurrenciesData) => currency.id === value);
      return currCurrency ? currCurrency.name : '';
    }
  },
  {
    Header: t('disposals.amount'),
    accessor: 'amount'
  },
  {
    Header: t('operations.status'),
    accessor: 'status_id',
    Cell: ({ value }: CellInfo) => DisposalStatusNames(t)[value]
  },
  {
    Header: t('disposals.Contract'),
    accessor: 'contract'
  },
  {
    Header: t('disposals.Source'),
    accessor: 'sources',
    Cell: ({ row }: CellInfo) => {
      let source = row.sources || [];
      if (
        ![CreateDisposalType.StatementToAccount, CreateDisposalType.StatementToCheckingAccount].includes(
          row.amount_type_id
        )
      ) {
        source = row.sources.reduce((arr, id) => {
          const name = accounts[Number(id)] || id;
          arr.push(name);
          return arr;
        }, []);
      }
      return React.createElement('div', { dangerouslySetInnerHTML: { __html: source.sort().join('<br />') } });
    }
  },
  {
    Header: t('disposals.Recipient'),
    accessor: 'account_to',
    Cell: ({ row }: CellInfo) => {
      const account = accounts[Number(row.account_to)];

      if (account) {
        return account;
      } else {
        return row.account_to;
      }
    }
  },
  {
    Header: t('disposals.comment'),
    accessor: 'comment',
    Cell: ({ value }: CellInfo) => value
  },
  { Header: t('Assigned'), accessor: 'updater' }
];

export const getContractOptions = (accounts: { [serviceType: number]: Service[] }): SelectorOption[] => {
  if (!accounts) {
    return [];
  }
  let allAccounts: Service[] = [];
  const contracts = {};

  accounts &&
    Object.keys(accounts).length &&
    Object.values(accounts).forEach((service: Service[]) => {
      allAccounts = allAccounts.concat(service);
    });

  if (allAccounts && allAccounts.length) {
    allAccounts.forEach((item: Service) => {
      if (!contracts[item.contract_name]) {
        contracts[item.contract_name] = [item.id];
      } else {
        contracts[item.contract_name].push(item.id);
      }
    });
  }

  if (contracts) {
    return Object.entries(contracts).map(([key, value]) => ({ key, value, label: key }));
  }
  return [];
};

export const getActiveAccounts = (
  statements: StatementItem[],
  partnerId: number,
  accounts: Service[],
  currency: string,
  currencyCode: string,
  flagSource: boolean = false,
  contractName: string | null
) => {
  const result: StatementItem[] = [];
  if (accounts && accounts.length && statements && statements.length) {
    const accountFromStatement: { [serviceType: number]: number[] } = {};
    const accountFromStatementId: { [serviceType: number]: number } = {};
    const accountFromStatementFrozen: { [serviceType: number]: boolean } = {};

    statements.forEach((item: StatementItem) => {
      item.status = item['currency_status'][String(currency)] || item.status;
      if (item.source_type === DISPOSAL_ACTIVE_ACCOUNTS_SOURCE_TYPE && item['amount4'][currency]) {
        if (item['currency_status'][String(currency)] === 2) {
          accountFromStatementFrozen[Number(item.source_id)] = true;
        }
        if (accountFromStatement[Number(item.source_id)]) {
          if (item['currency_status'][String(currency)] !== 2) {
            accountFromStatement[Number(item.source_id)] += item['amount4'][currency];
          }
        } else {
          accountFromStatementId[Number(item.source_id)] = item.id;
          accountFromStatement[Number(item.source_id)] = item['amount4'][currency];
        }
      }
    });

    accounts.forEach((item: Service) => {
      if (!item.is_active) return;
      if (item.partner_id === partnerId && item.source === DISPOSAL_ACTIVE_ACCOUNTS_SOURCE) {
        let id: number | null = null;
        if (flagSource) {
          id = accountFromStatementId[item.id] ? accountFromStatementId[item.id] : null;
        } else {
          id = item.id;
        }
        if (
          id != null &&
          new RegExp(currencyCode, 'g').test(item.name) &&
          ((contractName && item.contract_name === contractName) || !contractName)
        ) {
          result.push({
            id,
            checked: false,
            source_id: item.source_id || item.id,
            name: item.name,
            status: item.is_active && !accountFromStatementFrozen[item.source_id || item.id] ? 1 : 0,
            balance: accountFromStatement[item.id] ? String(accountFromStatement[item.id]) : '0.00',
            currency
          });
        }
      }
    });
  }

  return result;
};

export const getActiveStatements = (statements: StatementItem[], service: number, currency: string) => {
  const result: StatementItem[] = [];

  if (statements && statements.length) {
    statements.forEach((item: StatementItem) => {
      const amountAttrName = 'amount'.concat(service.toString());
      let amount = null;
      item.status = item['currency_status'] ? item['currency_status'][String(currency)] || item.status : item.status;

      if (item.source_type === DISPOSAL_ACTIVE_STATEMENTS_SOURCE_TYPE) {
        if (
          typeof item[amountAttrName] === 'number' &&
          CurrenciesOptions.RUB.toString() === String(currency) &&
          service === SumStatementType.MC
        ) {
          amount = item[amountAttrName];
        } else if (typeof item[amountAttrName] === 'object' && item[amountAttrName][currency]) {
          amount = item[amountAttrName][currency];
        }
        if (amount != null) {
          result.push({
            id: item.id,
            checked: false,
            amount,
            source_id: item.source_id,
            status: item.status,
            currency
          });
        }
      }
    });
  }

  return result;
};

export const getDisposalCurrenciesOptions = (currencies: CurrenciesData[]) => {
  const activeCurrencies =
    currencies && currencies.length ? currencies.filter((item: CurrenciesData) => item.is_disposals_active) : [];

  return activeCurrencies.map(({ id, name }: CurrenciesData) => ({ key: id, value: id, label: name }));
};

export const getAccountsFromStatements = (data: StatementItem[]) => data;

export const getCurrenciesOptions = (currencies: CurrenciesData[], t) => {
  if (currencies && currencies.length) {
    return [
      {
        key: 0,
        label: t('all'),
        value: '',
        checked: true,
        expanded: true,
        children: [
          ...getDisposalCurrenciesOptions(currencies).map(({ label, key }) => ({
            label,
            value: key
          }))
        ]
      }
    ];
  } else {
    return ALL_CURRENCY_OPTION(t);
  }
};

export const getOneLevelSelectedData = (selectedValues: TreeNode[]) => {
  if (selectedValues && selectedValues.length === 1 && selectedValues[0].value === '') {
    return selectedValues[0].value;
  } else {
    return selectedValues && selectedValues.length
      ? { value: selectedValues.map((item: TreeNode) => item.value).flat() }
      : null;
  }
};

export const getStatusesOptions = (t: any) => {
  const statuses = [
    { id: 1, name: t('disposals.status.new') },
    { id: 2, name: t('disposals.status.in_process') },
    { id: 3, name: t('disposals.status.done') },
    { id: 4, name: t('disposals.status.cancelled') }
  ];
  return [
    {
      key: 1,
      label: t('all'),
      value: '',
      checked: true,
      expanded: true,
      children: [
        ...statuses.map(({ name, id }) => ({
          label: name,
          value: id
        }))
      ]
    }
  ];
};

export const getTypeOptions = (t: any) => [
  {
    key: 1,
    label: t('all'),
    value: '',
    checked: true,
    expanded: true,
    children: CREATE_DISPOSAL_TYPE_OPTIONS(t)
  }
];

export const getServiceOptions = (t: any) => [
  {
    key: 1,
    label: t('all'),
    value: '',
    checked: true,
    expanded: true,
    children: STATEMENT_TYPE_OPTIONS(t)
  }
];

export const getPartnerOption = (partners: Merchant[], t: any, flat: boolean = false) => {
  if (partners && partners.length) {
    if (flat) {
      return partners.map(({ name, id }) => ({ label: name, value: id, key: id }));
    } else {
      return [
        {
          key: 1,
          label: t('all'),
          value: '',
          checked: true,
          expanded: true,
          children: [
            ...partners.map(({ name, id }) => ({
              label: name,
              value: id
            }))
          ]
        }
      ];
    }
  } else {
    return flat ? [] : ALL_CURRENCY_OPTION(t);
  }
};

export const prepareSubmitValues = (values: any, csv: boolean = false): DisposalsRequest => {
  const newValues: any = Object.entries(values).reduce((a, [k, v]) => (v ? ((a[k] = v), a) : a), {});
  if (newValues.partner) {
    newValues.partner = newValues.partner.value;
  }
  if (newValues.type) {
    newValues.type = newValues.type.value;
  }
  if (newValues.service) {
    newValues.service = newValues.service.value;
  }
  if (newValues.status) {
    newValues.status = newValues.status.value;
  }
  if (newValues.currency) {
    newValues.currency = newValues.currency.value;
  }

  return {
    csv,
    from: toISOString(newValues.dateFrom, 0),
    to: toISOString(newValues.dateTo, 0),
    ...newValues
  };
};
