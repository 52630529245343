import Disposals from './Disposals';
import CreateDisposal from './CreateDisposal/CreateDisposal';

import React, { ReactNode } from 'react';

import { Redirect, Route, Switch } from 'react-router';
import { NavLink } from 'react-router-dom';
import { Row, Card, CardBody, NavItem, Nav } from 'reactstrap';

import { Colxx, Separator } from '../../components/common/CustomBootstrap';
import Breadcrumb from '../../containers/navs/Breadcrumb';

import { DISPOSAL_MENU_ITEMS } from './constants';
import { TIMEZONE_TOOLTIP_TEXT } from '../../constants/app';
import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';

import { IDisposalsProps, IDisposalsState } from './interface';

import { checkItems } from '../../helpers/Access';

class ViewDisposals extends React.Component<IDisposalsProps, IDisposalsState> {
  state: IDisposalsState = {
    menuItems: []
  };

  componentDidUpdate(prevProps: Readonly<IDisposalsProps>): void {
    const { user, t } = this.props;
    if (prevProps.user !== user || (prevProps.user && user && prevProps.user.id !== user.id)) {
      this.setState({ menuItems: checkItems(user, DISPOSAL_MENU_ITEMS(t)) });
    }
  }

  componentDidMount(): void {
    const { user, t } = this.props;
    if (user) {
      this.setState({ menuItems: checkItems(user, DISPOSAL_MENU_ITEMS(t)) });
    }
  }

  render(): ReactNode {
    const { match, location, t } = this.props;
    const { menuItems } = this.state;

    return (
      <>
        <Row className="flex-shrink-0">
          <Colxx xxs="12">
            <Breadcrumb heading="menu.disposals" match={match} />
            <div className="time-tip  d-none d-lg-inline-block">{t(TIMEZONE_TOOLTIP_TEXT)}</div>
            <Separator className="mb-5" />
          </Colxx>
        </Row>

        <Row>
          <Colxx xxs="12">
            <Card className="mb-5">
              <CardBody>
                <Nav className="nav-pills nav-fill">
                  {menuItems.map((menuItem: { label: string; path: string }) => (
                    <Colxx key={menuItem.path} xxs="12" lg="3">
                      <NavItem key={menuItem.path}>
                        <NavLink
                          className="payouts nav-link px-0 text-lg-center"
                          isActive={() => location.pathname === menuItem.path}
                          to={`${menuItem.path}`}
                        >
                          {menuItem.label}
                        </NavLink>
                      </NavItem>
                    </Colxx>
                  ))}
                </Nav>
              </CardBody>
            </Card>
          </Colxx>
        </Row>

        <Switch>
          <Route exact path={`${match.url}`} component={Disposals} />
          <Route path={`${match.url}/create`} component={CreateDisposal} />
          <Redirect to={'/error'} />
        </Switch>
      </>
    );
  }
}

const mapStateToProps = ({ authUser }) => ({
  user: authUser.user.data ? authUser.user.data : null
});

const mapDispatchToProps = {};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ViewDisposals as any)
);
