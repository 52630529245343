import { startOfMonth, startOfHour, subDays } from 'date-fns';

import { ChartsPeriod, ChartsPeriodValue } from './interface';
import { OPERATIONS_ACCOUNTS_OPTIONS } from '../operations/constants';
import { CurrenciesOptions, NOT_SELECTED_LABEL, DestinationCodes } from '../../constants/app';

export enum StatisticType {
  Summary,
  ByCustomers,
  Charts,
  ConversionChart,
  PeriodSummary
}

export enum STATISTICS_SERVICES {
  INCOME,
  MC,
  OUTCOME
}
enum ServiceTypes {
  Income = 1,
  Mc,
  Outcome
}

export enum DIRECTIONS_VALUES {
  WP = 1,
  PT,
  FP,
  SWP,
  SB,
  CB
}

export const STATISTICS_INCOME_DIRECTIONS_OPTIONS = (t: any) => [
  {
    key: 1,
    label: t('all'),
    value: {
      destination: [
        Number(DestinationCodes.ApplePay),
        Number(DestinationCodes.GooglePay),
        Number(DestinationCodes.SamsungPay)
      ],
      source: [
        DIRECTIONS_VALUES.WP,
        DIRECTIONS_VALUES.FP,
        DIRECTIONS_VALUES.SWP,
        DIRECTIONS_VALUES.SB,
        DIRECTIONS_VALUES.CB
      ]
    }
  },
  {
    key: 2,
    label: 'Эквайринг',
    value: {
      destination: [],
      source: [DIRECTIONS_VALUES.FP, DIRECTIONS_VALUES.SWP, DIRECTIONS_VALUES.SB]
    }
  },
  {
    key: 3,
    label: 'MobilePay',
    value: {
      destination: [],
      source: [DIRECTIONS_VALUES.WP]
    }
  }
];

export const STATISTICS_MC_DIRECTIONS_OPTIONS = (t: any) => [
  {
    key: 1,
    label: t('all'),
    value: {
      source: [DIRECTIONS_VALUES.PT, DIRECTIONS_VALUES.CB]
    }
  }
];

export const STATISTICS_OUTCOME_DIRECTIONS_OPTIONS = (t: any) => [
  {
    key: 1,
    label: t('all'),
    value: {
      source: [DIRECTIONS_VALUES.WP, DIRECTIONS_VALUES.CB]
    }
  }
];

export const STATISTICS_SERVICES_OPTIONS = (t: any) => [
  {
    key: ServiceTypes.Income,
    label: t('acquiring'),
    value: {
      operationType: ServiceTypes.Income,
      directionsOptions: STATISTICS_INCOME_DIRECTIONS_OPTIONS(t),
      source: [
        DIRECTIONS_VALUES.WP,
        DIRECTIONS_VALUES.FP,
        DIRECTIONS_VALUES.SWP,
        DIRECTIONS_VALUES.SB,
        DIRECTIONS_VALUES.CB
      ]
    }
  },
  {
    key: ServiceTypes.Mc,
    label: t('mobile-commerce'),
    value: {
      operationType: ServiceTypes.Mc,
      directionsOptions: STATISTICS_MC_DIRECTIONS_OPTIONS(t),
      source: [DIRECTIONS_VALUES.PT, DIRECTIONS_VALUES.CB]
    }
  },
  {
    key: ServiceTypes.Outcome,
    label: t('payouts'),
    value: {
      operationType: ServiceTypes.Outcome,
      directionsOptions: STATISTICS_OUTCOME_DIRECTIONS_OPTIONS(t),
      source: [DIRECTIONS_VALUES.WP, DIRECTIONS_VALUES.CB]
    }
  }
];

export const ALL_CURRENCY_OPTION = (t: any) => [
  {
    key: CurrenciesOptions.ALL,
    label: t('all'),
    value: ''
  }
];

export const NOT_SELECTED_CURRENCY_OPTION = (t: any) => ({
  key: CurrenciesOptions.ALL,
  label: t(NOT_SELECTED_LABEL),
  value: ''
});

export const STATISTICS_PERIOD_OPTIONS = (t: any): ChartsPeriod[] => [
  {
    key: 1,
    label: t('hour'),
    value: ChartsPeriodValue.Hour
  },
  {
    key: 2,
    label: t('day'),
    value: ChartsPeriodValue.Day
  },
  {
    key: 3,
    label: t('week'),
    value: ChartsPeriodValue.Week
  },
  {
    key: 4,
    label: t('month'),
    value: ChartsPeriodValue.Month
  }
];

export const CONVERSION_CHART_STATUSES = (t: any) => [
  {
    label: t('Paid'),
    value: '1',
    key: '1',
    color: '#7d9c52'
  },
  {
    label: t('Unpaid'),
    value: '2',
    key: '2',
    color: '#e7523e'
  },
  {
    label: t('Not found'),
    value: '3',
    key: '3',
    color: '#d6cdca'
  },
  {
    label: t('Processing'),
    value: '4',
    key: '4',
    color: '#ffc107'
  }
];

export const STATISTICS_INITIAL_COMMON_VALUES = (t: any) => ({
  service: STATISTICS_SERVICES_OPTIONS(t)[0],
  direction: STATISTICS_INCOME_DIRECTIONS_OPTIONS(t)[0],
  dateTo: new Date()
});

export const STATISTICS_INITIAL_VALUES = (t: any) => ({
  [StatisticType.Summary]: {
    ...STATISTICS_INITIAL_COMMON_VALUES(t),
    currency: NOT_SELECTED_CURRENCY_OPTION(t),
    dateFrom: startOfMonth(new Date()),
    account: []
  },
  [StatisticType.ByCustomers]: {
    ...STATISTICS_INITIAL_COMMON_VALUES(t),
    currency: ALL_CURRENCY_OPTION(t)[0],
    dateFrom: startOfMonth(new Date()),
    account: []
  },
  [StatisticType.Charts]: {
    ...STATISTICS_INITIAL_COMMON_VALUES(t),
    currency: NOT_SELECTED_CURRENCY_OPTION(t),
    period: STATISTICS_PERIOD_OPTIONS(t)[0],
    dateFrom: startOfHour(subDays(new Date(), 1)),
    account: OPERATIONS_ACCOUNTS_OPTIONS(t)
  },
  [StatisticType.ConversionChart]: {
    ...STATISTICS_INITIAL_COMMON_VALUES(t),
    currency: ALL_CURRENCY_OPTION(t)[0],
    period: STATISTICS_PERIOD_OPTIONS(t)[0],
    dateFrom: startOfHour(subDays(new Date(), 1)),
    status: CONVERSION_CHART_STATUSES(t),
    account: OPERATIONS_ACCOUNTS_OPTIONS(t)
  },
  [StatisticType.PeriodSummary]: {
    ...STATISTICS_INITIAL_COMMON_VALUES(t),
    currency: NOT_SELECTED_CURRENCY_OPTION(t),
    period: STATISTICS_PERIOD_OPTIONS(t)[0],
    dateFrom: startOfHour(subDays(new Date(), 1)),
    status: CONVERSION_CHART_STATUSES(t),
    account: OPERATIONS_ACCOUNTS_OPTIONS(t),
    byAccount: true
  }
});

export enum ChartTypes {
  SumPaid = 'SumPaid',
  SumNotPaid = 'SumNotPaid',
  Paid = 'Paid',
  NotPaidOrNotFound = 'NotPaidOrNotFound',
  Total = 'Total',
  TotalAmount = 'TotalAmount',
  AvgAmount = 'AvgAmount',
  RefundTotal = 'RefundTotal',
  RefundTotalAmount = 'RefundTotalAmount'
}

export enum ChartLabels {
  SumPaid = 'SumPaid',
  SumNotPaid = 'SumNotPaid',
  Paid = 'Paid',
  NotPaidOrNotFound = 'NotPaidOrNotFound',
  Total = 'Total',
  TotalAmount = 'TotalAmount',
  AvgAmount = 'AvgAmount',
  RefundTotal = 'RefundTotal',
  RefundTotalAmount = 'RefundTotalAmount'
}

export const DEFAULT_CONVERSION_CHART_HEIGHT = 450;
export const AVG_CNT_ITEMS = 2;
export const DAYS_COUNT_IN_WEEK = 7;

export const DATE_FROM_TOOLTIP_TEXT = 'Временной интервал с 00:00:00';
export const DATE_TO_TOOLTIP_TEXT = 'Временной интервал по 23:59:59';

export const ALL_SOURCES = [DIRECTIONS_VALUES.WP, DIRECTIONS_VALUES.SWP, DIRECTIONS_VALUES.FP];
