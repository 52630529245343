import {
  MENU_SET_CLASSNAMES,
  MENU_CONTAINER_ADD_CLASSNAME,
  MENU_CLICK_MOBILE_MENU,
  MENU_CHANGE_HAS_SUB_ITEM_STATUS,
  MENU_GET_USERS,
  MENU_GET_USERS_SUCCESS,
  MENU_GET_USERS_ERROR,
  MENU_GET_MERCHANTS,
  MENU_GET_MERCHANTS_SUCCESS,
  MENU_GET_MERCHANTS_ERROR,
  MENU_SET_PERMISSION_ID
} from '../actions';

import {
  User,
  GetUserAction,
  GetUserSuccessAction,
  GetUserErrorAction,
  Merchant,
  GetMerchantAction,
  GetMerchantSuccessAction,
  GetMerchantErrorAction
} from './interface';

export const setMenuPermissionId = (setMenuPmId: number) => ({
  type: MENU_SET_PERMISSION_ID,
  payload: { setMenuPmId }
});

export const changeSelectedMenuHasSubItems = (selectedMenuHasSubItems: boolean) => ({
  type: MENU_CHANGE_HAS_SUB_ITEM_STATUS,
  payload: { selectedMenuHasSubItems }
});

export const addContainerClassname = (classname: string, strCurrentClasses: string) => {
  const containerClassnames = !(strCurrentClasses.indexOf(classname) > -1)
    ? `${strCurrentClasses} ${classname}`
    : strCurrentClasses;
  return {
    type: MENU_CONTAINER_ADD_CLASSNAME,
    payload: { containerClassnames }
  };
};

export const clickOnMobileMenu = (strCurrentClasses: string) => {
  const currentClasses = strCurrentClasses
    ? strCurrentClasses.split(' ').filter((x: string) => x !== '' && x !== 'sub-show-temporary')
    : [];
  let nextClasses = '';
  if (currentClasses.includes('main-show-temporary')) {
    nextClasses = currentClasses.filter((x: string) => x !== 'main-show-temporary').join(' ');
  } else {
    nextClasses = `${currentClasses.join(' ')} main-show-temporary`;
  }
  return {
    type: MENU_CLICK_MOBILE_MENU,
    payload: {
      containerClassnames: nextClasses,
      menuClickCount: 0
    }
  };
};

export const setContainerClassnames = (
  clickIndex: number,
  strCurrentClasses: string,
  selectedMenuHasSubItems: boolean
) => {
  const currentClasses = strCurrentClasses ? strCurrentClasses.split(' ').filter((x: string) => x !== '') : '';
  let menuClickCount = clickIndex;
  let nextClasses = '';

  if (!selectedMenuHasSubItems) {
    if (currentClasses.includes('menu-default') && (menuClickCount % +'4' === 0 || menuClickCount % +'4' === +'3')) {
      menuClickCount = 1;
    }
    if (currentClasses.includes('menu-sub-hidden') && menuClickCount % +'4' === +'2') {
      menuClickCount = 0;
    }
    if (currentClasses.includes('menu-hidden') && (menuClickCount % +'4' === +'2' || menuClickCount % +'4' === +'3')) {
      menuClickCount = 0;
    }
  }

  if (menuClickCount % +'4' === 0) {
    if (currentClasses.includes('menu-default') && currentClasses.includes('menu-sub-hidden')) {
      nextClasses = 'menu-default menu-sub-hidden';
    } else if (currentClasses.includes('menu-default')) {
      nextClasses = 'menu-default';
    } else if (currentClasses.includes('menu-sub-hidden')) {
      nextClasses = 'menu-sub-hidden';
    } else if (currentClasses.includes('menu-hidden')) {
      nextClasses = 'menu-hidden';
    }
    menuClickCount = 0;
  } else if (menuClickCount % +'4' === 1) {
    if (currentClasses.includes('menu-default') && currentClasses.includes('menu-sub-hidden')) {
      nextClasses = 'menu-default menu-sub-hidden main-hidden sub-hidden';
    } else if (currentClasses.includes('menu-default')) {
      nextClasses = 'menu-default sub-hidden';
    } else if (currentClasses.includes('menu-sub-hidden')) {
      nextClasses = 'menu-sub-hidden main-hidden sub-hidden';
    } else if (currentClasses.includes('menu-hidden')) {
      nextClasses = 'menu-hidden main-show-temporary';
    }
  } else if (menuClickCount % +'4' === +'2') {
    if (currentClasses.includes('menu-default') && currentClasses.includes('menu-sub-hidden')) {
      nextClasses = 'menu-default menu-sub-hidden sub-hidden';
    } else if (currentClasses.includes('menu-default')) {
      nextClasses = 'menu-default main-hidden sub-hidden';
    } else if (currentClasses.includes('menu-sub-hidden')) {
      nextClasses = 'menu-sub-hidden sub-hidden';
    } else if (currentClasses.includes('menu-hidden')) {
      nextClasses = 'menu-hidden main-show-temporary sub-show-temporary';
    }
  } else if (menuClickCount % +'4' === +'3') {
    if (currentClasses.includes('menu-default') && currentClasses.includes('menu-sub-hidden')) {
      nextClasses = 'menu-default menu-sub-hidden sub-show-temporary';
    } else if (currentClasses.includes('menu-default')) {
      nextClasses = 'menu-default sub-hidden';
    } else if (currentClasses.includes('menu-sub-hidden')) {
      nextClasses = 'menu-sub-hidden sub-show-temporary';
    } else if (currentClasses.includes('menu-hidden')) {
      nextClasses = 'menu-hidden main-show-temporary';
    }
  }
  if (currentClasses.includes('menu-mobile')) {
    nextClasses += ' menu-mobile';
  }
  return {
    type: MENU_SET_CLASSNAMES,
    payload: {
      containerClassnames: nextClasses,
      menuClickCount
    }
  };
};

export const menuGetUsers = (): GetUserAction => ({ type: MENU_GET_USERS });

export const menuGetUsersSuccess = (users: User[]): GetUserSuccessAction => ({
  type: MENU_GET_USERS_SUCCESS,
  payload: { users }
});

export const menuGetUsersError = (error: string): GetUserErrorAction => ({
  type: MENU_GET_USERS_ERROR,
  payload: { error }
});

export const menuGetMerchants = (userId?: number): GetMerchantAction => ({
  type: MENU_GET_MERCHANTS,
  payload: { userId }
});

export const menuGetMerchantsSuccess = (merchants: Merchant[]): GetMerchantSuccessAction => ({
  type: MENU_GET_MERCHANTS_SUCCESS,
  payload: { merchants }
});

export const menuGetMerchantsError = (error: string): GetMerchantErrorAction => ({
  type: MENU_GET_MERCHANTS_ERROR,
  payload: { error }
});
