import { ru, enUS } from 'date-fns/locale';
import { format } from 'date-fns';
import { toLocalTime } from './helpers/Utils';
import i18n from './i18n.js';

const MIN_YEAR = 1970;

enum DateValues {
  year,
  month,
  day,
  hour,
  min,
  sec
}

export default (date: string | Date, formatStr = 'PP') => {
  const locale = i18n.language === 'en' ? enUS : ru;

  if (new Date(date).getFullYear() < MIN_YEAR) {
    return '';
  }

  if (date && typeof date === 'string') {
    const stringArray = date.split(/\D/);
    const convertedData = stringArray
      ? new Date(
          Date.UTC(
            Number(stringArray[DateValues.year] ? stringArray[DateValues.year] : 0),
            Number(stringArray[DateValues.month] ? stringArray[DateValues.month] : 0) - 1,
            Number(stringArray[DateValues.day] ? stringArray[DateValues.day] : 0),
            Number(stringArray[DateValues.hour] ? stringArray[DateValues.hour] : 0),
            Number(stringArray[DateValues.min] ? stringArray[DateValues.min] : 0),
            Number(stringArray[DateValues.sec] ? stringArray[DateValues.sec] : 0)
          )
        )
      : new Date();
    const isoDate = convertedData.toISOString();

    return format(toLocalTime(isoDate), formatStr, {
      locale
    });
  }

  return format(toLocalTime(date), formatStr, {
    locale
  });
};
