import React, { ReactNode } from 'react';
import { Button, Card, CardBody, FormGroup, Label, Row } from 'reactstrap';
import { Field, Form, Formik } from 'formik';
import InputMask from 'react-input-mask';
import { differenceInSeconds } from 'date-fns';
import { withTranslation } from 'react-i18next';
import { Colxx } from '../../components/common/CustomBootstrap';
import { DatePickerLocaled } from '../../components/common/DatePickerLocaled/DatePickerLocaled';
import { CustomDropdownTreeSelect } from '../../components/common/CustomDropdownTreeSelect';

import {
  DATE_TIME_FORMAT_WITH_SEC,
  EMPTY_FIELD_ERROR,
  ZERO_FIELD_ERROR,
  FULL_DATETIME_MASK
} from '../../constants/app';

import { DisposalFilterProps, DisposalFilterState } from './interface';
import { getFieldErrorsCount, setDateFrom, setDateTo } from '../../helpers/Utils';
import { DISPOSAL_LIST_FILTER_INITIAL_VALUES } from './constants';
import { MIN_DATE_LIMIT } from '../../constants/dateValues';

import {
  getPartnerOption,
  getCurrenciesOptions,
  getOneLevelSelectedData,
  getStatusesOptions,
  getServiceOptions,
  getTypeOptions
} from './helpers';
class FilterPanel extends React.Component<DisposalFilterProps, DisposalFilterState> {
  state: DisposalFilterState = {
    shouldResetField: false,
    activeCurrencies: []
  };
  resetForm;
  setFieldValue;

  componentDidUpdate(prevProps: DisposalFilterProps): void {
    const { shouldResetForm } = this.props;

    if (prevProps.shouldResetForm !== shouldResetForm && shouldResetForm) {
      this.resetForm();
      this.setState({ shouldResetField: true });
    }

    this.state.shouldResetField && this.setState({ shouldResetField: false });
  }

  validate = (values) => {
    const errors: { [key: string]: string } = {};
    const { dateFrom, dateTo } = values;

    const diffInSec = dateFrom && dateTo ? differenceInSeconds(dateTo, dateFrom) : 0;

    if (values.direction === null) {
      errors['direction'] = EMPTY_FIELD_ERROR;
    }

    if (values.account === null) {
      errors['account'] = EMPTY_FIELD_ERROR;
    }

    if (values.currency === null) {
      errors['currency'] = EMPTY_FIELD_ERROR;
    }

    if (values.card_number === null) {
      errors['card_number'] = EMPTY_FIELD_ERROR;
    }

    if (values.amount && Number(values.amount) === 0) {
      errors['amount'] = ZERO_FIELD_ERROR;
    }

    if (values.status === null) {
      errors['status'] = EMPTY_FIELD_ERROR;
    }

    if (diffInSec < 1) {
      errors['dateFrom'] = MIN_DATE_LIMIT.text;
    }

    return errors;
  };

  prepareSiteData = (options) => {
    if (options && options.length) {
      // Временный костыль (до ввыполнения задачи GMC-1192)
      enum blockSource {
        Source2 = 2,
        Source6 = 6
      }

      return options.filter(({ source_id }) => !Object.values(blockSource).includes(source_id));
    } else {
      return [];
    }
  };

  render(): ReactNode {
    const { onSubmit, exportFile, currencies, selectedMerchants, showExportButton, t } = this.props;
    const { shouldResetField } = this.state;

    return (
      <div>
        <Formik initialValues={DISPOSAL_LIST_FILTER_INITIAL_VALUES} validate={this.validate} onSubmit={onSubmit}>
          {({ values, setFieldValue, errors, touched, setFieldTouched, resetForm }) => {
            this.resetForm = resetForm;
            this.setFieldValue = setFieldValue;
            return (
              <Form className="mb-4">
                <Card>
                  <CardBody>
                    <Row>
                      <Colxx xxs="12" sm="6">
                        <FormGroup className="has-float-label">
                          <Label for="dateFrom" className="float-label">
                            {t('disposals.date from')}
                          </Label>
                          <DatePickerLocaled
                            id="dateFrom"
                            name="dateFrom"
                            selected={values.dateFrom}
                            autoComplete="off"
                            dateFormat={DATE_TIME_FORMAT_WITH_SEC}
                            customInput={<InputMask mask={FULL_DATETIME_MASK} />}
                            maxDate={values.dateTo ? values.dateTo : new Date()}
                            onChange={(d: Date) => {
                              const dateFrom = setDateFrom(values.dateFrom, d);
                              setFieldValue('dateFrom', dateFrom);
                            }}
                            onSelect={(d: Date) => {
                              const dateFrom = setDateFrom(values.dateFrom, d);
                              setFieldValue('dateFrom', dateFrom);
                            }}
                            onBlur={() => setFieldTouched('dateFrom', true)}
                          />
                          {errors.dateFrom && <div className="invalid-feedback d-block">{errors.dateFrom}</div>}
                        </FormGroup>
                      </Colxx>
                      <Colxx xxs="12" sm="6">
                        <FormGroup className="has-float-label">
                          <Label for="dateTo" className="float-label">
                            {t('disposals.date by')}
                          </Label>
                          <DatePickerLocaled
                            id="dateTo"
                            name="dateTo"
                            selected={values.dateTo}
                            autoComplete="off"
                            dateFormat={DATE_TIME_FORMAT_WITH_SEC}
                            customInput={<InputMask mask={FULL_DATETIME_MASK} />}
                            minDate={values.dateFrom ? values.dateFrom : undefined}
                            maxDate={new Date()}
                            onChange={(d: Date) => {
                              const dateTo = setDateTo(values.dateTo, d);
                              setFieldValue('dateTo', dateTo);
                            }}
                            onSelect={(d: Date) => {
                              const dateTo = setDateTo(values.dateTo, d);
                              setFieldValue('dateTo', dateTo);
                            }}
                            onBlur={() => setFieldTouched('dateTo', true)}
                          />
                          {errors.dateTo && touched.dateTo && (
                            <div className="invalid-feedback d-block">{errors.dateTo}</div>
                          )}
                        </FormGroup>
                      </Colxx>

                      <Colxx xxs="12" sm="6" lg="3">
                        <FormGroup className="has-float-label">
                          <Label className="float-label">{t('disposals.partner')}</Label>
                          <CustomDropdownTreeSelect
                            name="partner"
                            data={getPartnerOption(selectedMerchants, t)}
                            shouldReset={shouldResetField}
                            onBlur={() => setFieldTouched('partner', true)}
                            onChange={(changedValue, selectedValues) =>
                              setFieldValue('partner', getOneLevelSelectedData(selectedValues))
                            }
                          />
                          {errors.partner && <div className="invalid-feedback d-block">{errors.partner}</div>}
                        </FormGroup>
                      </Colxx>
                      <Colxx xxs="12" sm="6" lg="3">
                        <FormGroup className="has-float-label">
                          <Label className="float-label">{t('disposals.destination')}</Label>
                          <CustomDropdownTreeSelect
                            name="type"
                            data={getTypeOptions(t)}
                            shouldReset={shouldResetField}
                            onBlur={() => setFieldTouched('type', true)}
                            onChange={(changedValue, selectedValues) =>
                              setFieldValue('type', getOneLevelSelectedData(selectedValues))
                            }
                          />
                          {errors.type && <div className="invalid-feedback d-block">{errors.type}</div>}
                        </FormGroup>
                      </Colxx>

                      <Colxx xxs="12" sm="6" lg="3">
                        <FormGroup className="has-float-label">
                          <Label className="float-label">{t('disposals.services')}</Label>
                          <CustomDropdownTreeSelect
                            name="service"
                            data={getServiceOptions(t)}
                            shouldReset={shouldResetField}
                            onBlur={() => setFieldTouched('service', true)}
                            onChange={(changedValue, selectedValues) =>
                              setFieldValue('service', getOneLevelSelectedData(selectedValues))
                            }
                          />
                          {errors.type && <div className="invalid-feedback d-block">{errors.type}</div>}
                        </FormGroup>
                      </Colxx>

                      <Colxx xxs="12" sm="6" lg="3">
                        <FormGroup className="has-float-label">
                          <Label className="float-label">{t('disposals.currency')}</Label>
                          <CustomDropdownTreeSelect
                            name="currency"
                            data={getCurrenciesOptions(currencies, t)}
                            shouldReset={shouldResetField}
                            onBlur={() => setFieldTouched('currency', true)}
                            onChange={(changedValue, selectedValues) =>
                              setFieldValue('currency', getOneLevelSelectedData(selectedValues))
                            }
                          />
                          {errors.currency && <div className="invalid-feedback d-block">{errors.currency}</div>}
                        </FormGroup>
                      </Colxx>
                      <Colxx xxs="12" sm="6" lg="3">
                        <FormGroup className="has-float-label">
                          <Label className="float-label">{t('disposals.status')}</Label>
                          <CustomDropdownTreeSelect
                            name="status"
                            data={getStatusesOptions(t)}
                            shouldReset={shouldResetField}
                            onBlur={() => setFieldTouched('status', true)}
                            onChange={(changedValue, selectedValues) =>
                              setFieldValue('status', getOneLevelSelectedData(selectedValues))
                            }
                          />
                          {errors.status && <div className="invalid-feedback d-block">{errors.status}</div>}
                        </FormGroup>
                      </Colxx>
                      <Colxx xxs="12" sm="6" lg="3">
                        <FormGroup className="has-float-label">
                          <Label className="float-label">{t('disposals.id')}</Label>
                          <Field
                            className="form-control"
                            type="text"
                            name="id"
                            onChange={({ target: { value } }) => setFieldValue('id', value.trim())}
                          />
                          {errors.id && touched.id && <div className="invalid-feedback d-block">{errors.id}</div>}
                        </FormGroup>
                      </Colxx>

                      <Colxx xxs="12" className="d-flex justify-content-end">
                        {showExportButton && (
                          <Button color="primary" onClick={exportFile} className="mr-3">
                            {t('disposals.button.download file')}
                          </Button>
                        )}
                        <Button disabled={!!getFieldErrorsCount(errors)} color="primary" type="submit">
                          {t('disposals.button.search')}
                        </Button>
                      </Colxx>
                    </Row>
                  </CardBody>
                </Card>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

export default withTranslation()(FilterPanel);
