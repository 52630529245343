import React, { PureComponent, ReactNode } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { ModalFileIsCreatedProps } from './interface';

class ModalFileIsCreated extends PureComponent<ModalFileIsCreatedProps> {
  render(): ReactNode {
    const { onCloseModal, t } = this.props;

    return (
      <Modal isOpen={true} toggle={onCloseModal}>
        <ModalHeader toggle={onCloseModal}>{t('modal.file_created.title')}</ModalHeader>
        <ModalBody>{t('modal.file_created.description')}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onCloseModal}>
            {t('ok')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default withTranslation()(ModalFileIsCreated);
