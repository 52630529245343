import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  GET_TEMPORARY_PASSWORD,
  GET_TEMPORARY_PASSWORD_SUCCESS,
  GET_TEMPORARY_PASSWORD_ERROR,
  CHECK_TMP_CODE,
  CHECK_TMP_CODE_SUCCESS,
  CHECK_TMP_CODE_ERROR,
  LOGOUT_USER_SUCCESS,
  GOOGLE_AUTH,
  GOOGLE_AUTH_SUCCESS,
  GOOGLE_AUTH_ERROR,
  SMS_AUTH,
  SMS_AUTH_SUCCESS,
  SMS_AUTH_ERROR,
  GET_GOOGLE_QR,
  GET_GOOGLE_QR_SUCCESS,
  GET_GOOGLE_QR_ERROR,
  HANDLE_NON_AUTHORIZED,
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
  SECOND_FACTOR_CANCELLATION,
  GET_GOOGLE_CODE,
  GET_GOOGLE_CODE_SUCCESS,
  GET_GOOGLE_CODE_ERROR,
  RESTORE_2FA,
  RESTORE_2FA_SUCCESS,
  RESTORE_2FA_ERROR,
  GET_ACCOUNT_SETTINGS_SUCCESS,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_ERROR
} from '../actions';
import { AuthReducerState } from './interface';

const INIT_STATE: AuthReducerState = {
  loginStatus: localStorage.getItem('tfs') || null,
  userLogin: localStorage.getItem('userLogin'),
  restoreCode: null,
  googleQr: null,
  googleCode: null,
  loading: false,
  error: '',
  checkTmpCodeState: {
    loading: false,
    error: ''
  },
  user: {
    data: null,
    loading: true,
    error: ''
  },
  restore2Fa: {
    loading: false,
    error: ''
  },
  refresh_token: {
    has_user_permissions: true
  }
};

export default (state: AuthReducerState = INIT_STATE, { type, payload }): AuthReducerState => {
  switch (type) {
    case LOGIN_USER:
      return {
        ...state,
        loading: true,
        error: ''
      };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        userLogin: payload,
        loading: false,
        loginStatus: localStorage.getItem('tfs'),
        error: ''
      };
    case LOGIN_USER_ERROR:
      return {
        ...state,
        ...payload,
        loading: false
      };
    case LOGOUT_USER_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          data: null
        },
        userLogin: localStorage.getItem('userLogin'),
        loginStatus: localStorage.getItem('tfs')
      };
    case GOOGLE_AUTH:
      return {
        ...state,
        loading: true,
        error: ''
      };
    case GOOGLE_AUTH_SUCCESS:
      return {
        ...state,
        loading: false,
        loginStatus: localStorage.getItem('tfs')
      };
    case GOOGLE_AUTH_ERROR:
      return {
        ...state,
        error: payload.error,
        loading: false
      };
    case SMS_AUTH:
      return {
        ...state,
        loading: true,
        error: ''
      };
    case SMS_AUTH_SUCCESS:
      return {
        ...state,
        loading: false,
        loginStatus: localStorage.getItem('tfs')
      };
    case SMS_AUTH_ERROR:
      return {
        ...state,
        error: payload.error,
        loading: false
      };
    case GET_GOOGLE_QR:
      return {
        ...state,
        loading: true,
        error: ''
      };
    case GET_GOOGLE_QR_SUCCESS:
      return {
        ...state,
        googleQr: payload.data,
        loading: false
      };
    case GET_GOOGLE_QR_ERROR:
      return {
        ...state,
        error: payload.error,
        loading: false
      };
    case GET_GOOGLE_CODE:
      return {
        ...state,
        loading: true,
        error: ''
      };
    case GET_GOOGLE_CODE_SUCCESS:
      return {
        ...state,
        googleCode: payload.data,
        loading: false
      };
    case GET_GOOGLE_CODE_ERROR:
      return {
        ...state,
        error: payload.error,
        loading: false
      };
    case GET_TEMPORARY_PASSWORD:
      return {
        ...state,
        ...payload,
        loading: true,
        error: ''
      };
    case GET_TEMPORARY_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: ''
      };
    case GET_TEMPORARY_PASSWORD_ERROR:
      return {
        ...state,
        ...payload,
        loading: false
      };
    case RESTORE_2FA:
      return {
        ...state,
        loading: true,
        error: '',
        restore2Fa: {
          loading: true,
          error: ''
        }
      };
    case RESTORE_2FA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        restore2Fa: {
          error: '',
          loading: false
        }
      };
    case RESTORE_2FA_ERROR:
      return {
        ...state,
        ...payload,
        loading: false,
        restore2Fa: {
          error: payload.error,
          loading: false
        }
      };
    case CHECK_TMP_CODE:
      return {
        ...state,
        checkTmpCodeState: {
          ...state.checkTmpCodeState,
          loading: true,
          error: ''
        }
      };
    case CHECK_TMP_CODE_SUCCESS:
      return {
        ...state,
        loginStatus: localStorage.getItem('tfs'),
        restoreCode: localStorage.getItem('restore_type'),
        checkTmpCodeState: {
          ...state.checkTmpCodeState,
          loading: false
        }
      };
    case CHECK_TMP_CODE_ERROR:
      return {
        ...state,
        checkTmpCodeState: {
          ...state.checkTmpCodeState,
          error: payload.error,
          loading: false
        }
      };
    case GET_USER:
      return {
        ...state,
        user: {
          ...state.user,
          loading: true,
          error: ''
        }
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          data: payload.data,
          loading: false
        }
      };
    case GET_USER_ERROR:
      return {
        ...state,
        user: {
          ...state.user,
          loading: false
        }
      };
    case HANDLE_NON_AUTHORIZED:
      return {
        ...state,
        user: {
          ...state.user,
          data: null
        },
        userLogin: localStorage.getItem('userLogin'),
        loginStatus: localStorage.getItem('tfs')
      };
    case SECOND_FACTOR_CANCELLATION:
      return {
        ...state,
        userLogin: localStorage.getItem('userLogin'),
        loginStatus: localStorage.getItem('tfs')
      };
    case GET_ACCOUNT_SETTINGS_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          data: state.user.data && state.user.data.id === payload.data.id ? payload.data : state.user.data
        }
      };
    case REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        refresh_token: {
          ...state.refresh_token,
          has_user_permissions: payload.has_user_permissions
        }
      };
    case REFRESH_TOKEN_ERROR:
      return {
        ...state
      };
    default:
      return { ...state };
  }
};
