import { CustomDesignConfig, CustomDesignState } from '../redux/settings/interface';
import { CONFIG } from '../config/appConfig';
import { IMAGE_URL } from '../views/settings/CustomDesign/constants';
import { createQueryString } from './Utils';
import { themeColors, themeColorStorageKey } from '../constants/defaultValues';
import i18n from './../i18n';
const { t } = i18n;

export const DEFAULT_FAVICON = `${new URL(process.env.PUBLIC_URL as any, window.location.href).origin}/favicon.svg`;
export const DEFAULT_TITLE = 'GWP';
export const DEFAULT_SUPPORT_EMAIL = 'support@gwp.digital';

export const GenCustomDesignStyles = (customDesignState: CustomDesignState, isPreview = false): string => {
  const customDesignData = customDesignState.data;
  if (!customDesignData) {
    if (!customDesignState.loading) {
      return getDefaultStyle();
    }
    return '';
  }

  if (
    !isPreview &&
    customDesignData.settings &&
    'theme' in customDesignData.settings &&
    localStorage.getItem(themeColorStorageKey) !== customDesignData.settings.theme
  ) {
    localStorage.setItem(themeColorStorageKey, customDesignData.settings.theme);
    window.location.reload();
  }

  return genCustomDesignStyles(customDesignData, isPreview);
};

export const getImage = (field: string, customDesign: CustomDesignConfig): string => {
  const { images, imagesBase64 } = customDesign;
  if (imagesBase64 && imagesBase64[field]) {
    return imagesBase64[field];
  }

  if (images && images[field] && customDesign.domain) {
    const queryParams = { field };
    queryParams['domain'] = customDesign.domain;
    queryParams['t'] = (Math.random() + 1).toString(36).substring(2, 15); // pass name to prevent browser cache

    return `${CONFIG.apiURL()}${IMAGE_URL}?${createQueryString(queryParams)}`;
  }

  return '';
};

export const getSupportEmail = (customDesignConfig: CustomDesignConfig | null): string => {
  if (customDesignConfig && customDesignConfig.settings && 'supportEmail' in customDesignConfig.settings) {
    return customDesignConfig.settings.supportEmail || DEFAULT_SUPPORT_EMAIL;
  }
  return DEFAULT_SUPPORT_EMAIL;
};

function setPageFavicon(customDesign: CustomDesignConfig): void {
  const currentFavicon = document.querySelector("link[rel*='icon']");
  if (currentFavicon) {
    currentFavicon.remove();
  }
  const linkTag = document.createElement('link');
  linkTag.type = 'image/x-icon'; // todo set type by mimetype
  linkTag.rel = 'shortcut icon';
  linkTag.href = getImage('favicon', customDesign) || DEFAULT_FAVICON;

  document.getElementsByTagName('head')[0].appendChild(linkTag);
}

function setPageTitle(customDesign: CustomDesignConfig): void {
  if (customDesign.settings && 'tabTitle' in customDesign.settings && customDesign.settings.tabTitle) {
    document.title = customDesign.settings.tabTitle;
  } else {
    document.title = t(DEFAULT_TITLE);
  }
}

function getDefaultStyle(): string {
  return `
      .logo-single, .navbar .navbar-logo .logo, .navbar .navbar-logo .logo-mobile {
        background-position: center center;
        background-size: contain;
      }`;
}

function getMenuTextHoverColor(customDesign: CustomDesignConfig): string {
  if (
    customDesign.settings &&
    'menuTextHoverColor' in customDesign.settings &&
    customDesign.settings.menuTextHoverColor
  ) {
    return `
      .sidebar .main-menu ul li a:hover, .sidebar .main-menu ul li a:focus,
      .sub-menu .nav-item a:hover, .sub-menu .nav-item a:active,
      .sidebar .sub-menu ul li.active i, .sidebar .sub-menu ul li.active a,
      .sidebar .main-menu ul li.active a {
        color: ${customDesign.settings.menuTextHoverColor} !important;
      }
      .sidebar .sub-menu ul li.active:after, .sidebar .main-menu ul li.active:after {
        background: ${customDesign.settings.menuTextHoverColor};
      }`;
  }
  return '';
}

function genCustomDesignStyles(customDesign: CustomDesignConfig, isPreview: boolean): string {
  const currentTheme =
    customDesign.settings && 'theme' in customDesign.settings ? customDesign.settings.theme : 'light.gmc';
  const currentThemeColor = themeColors[currentTheme];

  let customStyles = '';
  if (customDesign.settings && 'cardColor' in customDesign.settings && customDesign.settings.cardColor) {
    customStyles += `
      .card {
        background: ${customDesign.settings.cardColor};
      }`;
  }

  let buttonColor = currentThemeColor;
  if (customDesign.settings && 'buttonColor' in customDesign.settings && customDesign.settings.buttonColor) {
    buttonColor = customDesign.settings.buttonColor;
  }
  customStyles += `
    .btn-primary {
      background-color: ${buttonColor};
      border-color: ${buttonColor};
    }
    .btn-primary:hover {
      background-color: ${buttonColor};
      border-color: ${buttonColor};
      opacity: 0.9;
    }
    .btn-primary.disabled, .btn-primary:disabled {
      background-color: ${buttonColor};
      border-color: ${buttonColor};
      opacity: 0.7;
    }`;

  if (customDesign.settings && 'menuColor' in customDesign.settings && customDesign.settings.menuColor) {
    customStyles += `.navbar .search.mobile-view, .sidebar .main-menu {
      background: ${customDesign.settings.menuColor};
    }`;
  }

  if (customDesign.settings && 'submenuColor' in customDesign.settings && customDesign.settings.submenuColor) {
    customStyles += `
      .sidebar .sub-menu {
        background: ${customDesign.settings.submenuColor};
      }`;
  }

  if (customDesign.settings && 'backgroundColor' in customDesign.settings && customDesign.settings.backgroundColor) {
    customStyles += `body, html{
      background:${customDesign.settings.backgroundColor}
    }`;
  }

  customStyles += `
    .navbar .navbar-logo {
      width: 200px;
      height: 50px;
  }`;

  if (customDesign.settings && 'logoSize' in customDesign.settings && customDesign.settings.logoSize) {
    const size = Number(customDesign.settings.logoSize);
    customStyles += `
    @media (min-width: 1199px) {
      .navbar .navbar-logo{
        width:${size * 3}px;
        height:${size}px;
      }
    }
    @media (min-width: 420px) and (max-width: 1199px) {
      .navbar .navbar-logo{
        width:${size}px;
        height:100px;
        bottom: 0;
        margin-left: 35px;
      }
    }
    @media (min-width: 991px) and (max-width: 1199px) {
      .navbar .navbar-logo{
        margin-left: 110px;
      }
    }`;
    if (size > 100) {
      const difference = size - 100;
      customStyles += `
      @media (min-width: 420px) {

        .navbar{
          height:${size + 10}px;
        }
        .sidebar{
          padding-top:${100 + difference}px;
        }
        main{
          padding-top:${145 + difference}px;
        }
      }

      @media (min-width: 420px) and (max-width: 1199px) {
        .navbar .navbar-icons {
          position: absolute;
          top: calc(25% - 25px);
          right: 15px;
        }

        .navbar .navbar-logo{
          margin-left: 35px;
        }

        .navbar .menu-button-mobile {
          display: block !important;
        }

        .navbar .menu-button {
          display: none !important;
        }

        .navbar .dropdowns {
          position: absolute;
          width: 100%;
          padding: 0;
          text-align: center;
          justify-content: flex-end;
        }
      }`;
    }
  }

  if (customDesign.settings && 'loginLogoSize' in customDesign.settings && customDesign.settings.loginLogoSize) {
    const size = Number(customDesign.settings.loginLogoSize);
    customStyles += `
    .logo-block{
      height: ${size}px !important;
      width: 100%;
      margin-bottom: 10px;
    }`;
  }

  if (
    customDesign.settings &&
    'loginLogoPlacement' in customDesign.settings &&
    customDesign.settings.loginLogoPlacement
  ) {
    customStyles += `
      .login-modal .logo-single{
        background-position: center ${customDesign.settings.loginLogoPlacement} !important;
      }`;
  }

  if (customDesign.settings && 'menuTextColor' in customDesign.settings && customDesign.settings.menuTextColor) {
    customStyles += `
    .sidebar .main-menu ul li.active a,
    .sidebar .sub-menu ul li.active a,
    .sidebar .main-menu ul li a,
    .sidebar .sub-menu ul li a {
      color: ${customDesign.settings.menuTextColor};
    }
    .sidebar .main-menu ul li.active:after, .sidebar .sub-menu ul li.active:after,
    .sidebar .main-menu ul li:after, .sidebar .sub-menu ul li:after {
      background: ${customDesign.settings.menuTextColor};
    }`;
  } else {
    customStyles += `
    .sidebar .main-menu ul li.active a,
    .sidebar .sub-menu ul li.active a{
      color: ${currentThemeColor};
    }
    .sidebar .main-menu ul li.active:after, .sidebar .sub-menu ul li.active:after,
    .sidebar .main-menu ul li:after, .sidebar .sub-menu ul li:after {
      background: ${currentThemeColor};
    }`;
  }

  if (customDesign.settings && 'loginCardColor' in customDesign.settings && customDesign.settings.loginCardColor) {
    customStyles += `
      .login-form .card {
        background: ${customDesign.settings.loginCardColor};
      }`;
  }

  if (
    customDesign.settings &&
    'loginBackgroundColor' in customDesign.settings &&
    customDesign.settings.loginBackgroundColor
  ) {
    customStyles += `
      .fixed-background {
        background: ${customDesign.settings.loginBackgroundColor};
      }
      .fixed-background {
        background-image: none;
      }`;
  }

  const logoImage = getImage('logo', customDesign);
  if (logoImage) {
    customStyles += `
      .navbar .navbar-logo .logo {
        background: url("${logoImage}");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }`;
  } else {
    customStyles += `
      .navbar .navbar-logo .logo, .navbar .navbar-logo .logo-mobile {
        background: url("/assets/img/logo-gwp.svg") no-repeat;
        background-position: center center;
        background-size: contain;
      }`;
  }

  const loginLogoImage = getImage('loginLogo', customDesign);
  if (loginLogoImage) {
    customStyles += `
      .logo-single {
        background-image: url("${loginLogoImage}");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
      .login-modal a.white.active {
        display: flex;
        justify-content: center;
      }`;
  } else {
    customStyles += `
      .login-modal .logo-single {
        background: url("/assets/img/logo-gwp.svg") no-repeat;
        background-position: center center;
        background-size: contain;
      }`;
  }

  const loginBackgroundImage = getImage('loginBackground', customDesign);
  if (
    loginBackgroundImage &&
    !(
      customDesign.settings &&
      'loginBackgroundColor' in customDesign.settings &&
      customDesign.settings.loginBackgroundColor
    )
  ) {
    customStyles += `
      .fixed-background {
        background-image: url("${loginBackgroundImage}");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }`;
  } else {
    customStyles += `
      .fixed-background {
        background: url("/assets/img/login-main-gkm.jpg") no-repeat center center fixed;
      }`;
  }

  const loginCardBackgroundImage = getImage('loginCardBackground', customDesign);
  if (
    loginCardBackgroundImage &&
    !(customDesign.settings && 'loginCardColor' in customDesign.settings && customDesign.settings.loginCardColor)
  ) {
    customStyles += `
      .login-form .card {
        background-image: url("${loginCardBackgroundImage}");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }`;
  }

  if (customDesign.settings && 'customCss' in customDesign.settings && customDesign.settings.customCss) {
    customStyles += customDesign.settings.customCss;
  }

  customStyles += getMenuTextHoverColor(customDesign);
  if (!isPreview) {
    setPageFavicon(customDesign);
    setPageTitle(customDesign);
  }

  return customStyles;
}
