import { all, call, fork, put, takeLatest, SimpleEffect } from 'redux-saga/effects';
import { CombinatorEffect } from '@redux-saga/types';

import { ApiRequest } from '../../helpers/ApiRequest/ApiRequest';
import { createQueryString } from '../../helpers/Utils';

import {
  GET_SERVICES_BY_MERCHANT,
  GET_SERVICES_BY_NAME,
  getServicesSuccess,
  getServicesError,
  GET_PAYOUT_ACCOUNTS_BY_SERVICE,
  getPayoutAccountsByServiceSuccess,
  getPayoutAccountsByServiceError,
  setPayoutAccountsLastOperation,
  GET_ACCOUNTS_BALANCE,
  getAccountsBalanceSuccess,
  getAccountsBalanceError
} from '../actions';
import { GetPayoutAccountsByServiceAction, GetServiceByMerchantAction, GetServicesByNameAction } from './interface';

// GET SERVICES
function* getServices({
  payload,
  type
}: GetServicesByNameAction | GetServiceByMerchantAction): Generator<SimpleEffect<string, any>, any, any> {
  try {
    let url: string;
    switch (type) {
      case GET_SERVICES_BY_MERCHANT:
        url = `/user/partners/${(payload as { merchantId: string }).merchantId}/service-accounts/`;
        break;
      default:
        url = `/user/accounts/?${createQueryString(payload)}`;
    }
    const servicesResponse = yield call(ApiRequest.get, url);
    if (servicesResponse.error) {
      yield put(getServicesError(type, `${servicesResponse.error}`));
    } else {
      yield put(getServicesSuccess(type, servicesResponse.data));
    }
  } catch (error) {
    yield put(getServicesError(type, `${error}`));
  }
}

function* getAccountsBalance({ payload }: any): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { merchantIds, userId } = payload;

    const url = userId ? `/users/${userId}/balance-accounts/` : '/user/balance-accounts/';
    const queryParams = {
      p: merchantIds
    };

    const { data, error } = yield call(ApiRequest.get, `${url}?${createQueryString(queryParams)}`);

    if (error) {
      yield put(getAccountsBalanceError(`${error}`));
    } else {
      yield put(getAccountsBalanceSuccess(data));
    }
  } catch (error) {
    getAccountsBalanceError(`${error}`);
  }
}

// GET PAYOUT ACCOUNTS BY SERVICE
function* getPayoutAccountsByService({
  payload
}: GetPayoutAccountsByServiceAction): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { merchantId, serviceId, offset } = payload;

    const payoutAccountsByServiceResponse = yield call(
      ApiRequest.get,
      `/user/payout-accounts/?partner_id=${merchantId}&service_id=${serviceId}`
    );

    if (payoutAccountsByServiceResponse.error) {
      yield put(getPayoutAccountsByServiceError(`${payoutAccountsByServiceResponse.error}`));
    } else {
      if (offset === 0) {
        yield put(setPayoutAccountsLastOperation(payoutAccountsByServiceResponse.data[0]));
      }
      yield put(getPayoutAccountsByServiceSuccess(payoutAccountsByServiceResponse.data));
    }
  } catch (error) {
    yield put(getPayoutAccountsByServiceError(`${error}`));
  }
}

export function* watchGetServices(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(GET_SERVICES_BY_NAME, getServices);
  yield takeLatest(GET_SERVICES_BY_MERCHANT, getServices);
}

export function* watchGetAccountsBalance(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(GET_ACCOUNTS_BALANCE, getAccountsBalance);
}

export function* watchGetPayoutAccountsByService(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(GET_PAYOUT_ACCOUNTS_BY_SERVICE, getPayoutAccountsByService);
}

export default function* rootSaga(): Generator<CombinatorEffect<string, any>, any, any> {
  yield all([fork(watchGetServices), fork(watchGetPayoutAccountsByService), fork(watchGetAccountsBalance)]);
}
