import React, { PureComponent, ReactNode, Fragment } from 'react';
import { Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { Colxx } from '../../../components/common/CustomBootstrap';
import { CONFIG } from '../../../config/appConfig';
import { ServiceContentProps, ServiceFile } from './interface';

class ServiceContent extends PureComponent<ServiceContentProps> {
  fileUploadComponent = (): ReactNode => {
    const { serviceType, onLoad, onSubmit, t } = this.props;

    return (
      <Row className="w-100 mb-2">
        <Colxx xxs="12" className="text-center">
          <label
            htmlFor="serviceDocFile"
            className="text-muted cursor-pointer upload-label"
            onClick={() => onLoad(serviceType)}
          >
            <i className="simple-icon-cloud-upload left-text-icon" />
            {t('settings.services')}
          </label>
          <input
            type="file"
            id="serviceDocFile"
            name="serviceDocFile"
            style={{ display: 'none' }}
            onChange={({ target, currentTarget: { files } }) => {
              onSubmit(files);
              target.value = '';
            }}
          />
        </Colxx>
      </Row>
    );
  };

  render(): ReactNode {
    const { serviceType, services, onRemove, onServiceChange, t } = this.props;

    if (!services || !services.length) {
      return this.fileUploadComponent();
    }

    return (
      <Fragment>
        {Boolean(services && services.length) &&
          services.map(({ id, name }: ServiceFile) => (
            <Row key={id} className="flex-row align-items-center w-100 ml-4">
              <Colxx xxs="8">{name}</Colxx>
              <Colxx xxs="2">
                <a href={`${CONFIG.apiURL()}/service-type-docs/${id}/`} className="btn pl-0" download={true}>
                  {t('settings.download')}
                </a>
              </Colxx>
              <Colxx xxs="2">
                <a
                  href="/"
                  className="btn pl-0"
                  onClick={(e: any) => {
                    e.preventDefault();
                    onRemove(id);
                    onServiceChange(serviceType);
                  }}
                >
                  {t('settings.delete')}
                </a>
              </Colxx>
            </Row>
          ))}

        {this.fileUploadComponent()}
      </Fragment>
    );
  }
}
export default withTranslation()(ServiceContent);
