import { all, call, fork, put, takeLatest, SimpleEffect } from 'redux-saga/effects';
import { CombinatorEffect } from '@redux-saga/types';

import { ApiRequest } from '../../helpers/ApiRequest/ApiRequest';
import { createQueryString } from '../../helpers/Utils';

import {
  CHANGE_LOCALE,
  CHANGE_USER,
  CHANGE_MERCHANT,
  GET_SYSTEM_SETTINGS,
  PUT_SYSTEM_SETTINGS,
  getSystemSettingsSuccess,
  getSystemSettingsError,
  putSystemSettingsSuccess,
  putSystemSettingsError,
  GET_CURRENCIES,
  getCurrenciesSuccess,
  getCurrenciesError,
  GET_PERMISSIONS_SETTINGS,
  PUT_PERMISSIONS_SETTINGS,
  getPermissionsSettingsSuccess,
  getPermissionsSettingsError,
  putPermissionsSettingsSuccess,
  putPermissionsSettingsError,
  GET_ACCOUNT_SETTINGS,
  PUT_ACCOUNT_SETTINGS,
  getAccountSettingsSuccess,
  getAccountSettingsError,
  putAccountSettingsSuccess,
  putAccountSettingsError,
  CHANGE_CURRENCY,
  changeCurrenciesSuccess,
  changeCurrenciesError,
  getSitesSettingsSuccess,
  getSitesSettingsError,
  GET_SITES_SETTINGS,
  PUT_CUSTOM_DESIGN,
  GET_CUSTOM_DESIGN,
  DELETE_CUSTOM_DESIGN,
  applyCustomDesign,
  putCustomDesignError,
  getCustomDesignError,
  deleteCustomDesignError,
  deleteCustomDesignSuccess,
  PUT_SYSTEM_CACHE,
  putSystemCacheError,
  putSystemCacheSuccess
} from '../actions';
import {
  ChangeLocaleAction,
  ChangeUserAction,
  ChangeMerchantAction,
  GetPermissionsSettingsAction,
  PutPermissionsSettingsAction,
  GetAccountSettingsAction,
  PutAccountSettingsAction,
  PutSystemSettingsAction,
  ChangeCurrencyAction,
  GetSiteSettingsAction,
  PutCustomDesignAction,
  GetCustomDesignAction,
  DeleteCustomDesignAction
} from './interface';
import {
  CURRENT_LOCALE_LOCAL_STORAGE_KEY,
  CURRENT_USER_LOCAL_STORAGE_KEY,
  CURRENT_MERCHANT_LOCAL_STORAGE_KEY
} from './constants';
import {
  GetCustomDesign,
  PutCustomDesign,
  GetUserAccountSettings,
  PutUserAccountSettings,
  ClearCacheResponse
} from '../../api.interface';
import { Merchant } from '../menu/interface';

// LOCAL SETTINGS
export function changeLocale({ payload: { locale } }: ChangeLocaleAction): void {
  localStorage.setItem(CURRENT_LOCALE_LOCAL_STORAGE_KEY, locale);
}

export function changeUser({ payload: { selectedUser } }: ChangeUserAction): void {
  localStorage.setItem(CURRENT_USER_LOCAL_STORAGE_KEY, `${selectedUser.id}`);
}

export function changeMerchant({ payload: { selectedMerchants } }: ChangeMerchantAction): void {
  const merchantsArray =
    selectedMerchants && selectedMerchants.length ? selectedMerchants.map((merchant: Merchant) => merchant.id) : [];
  localStorage.setItem(CURRENT_MERCHANT_LOCAL_STORAGE_KEY, `${merchantsArray}`);
}

// GET CURRENCIES
export function* getCurrencies(): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { data, error } = yield call(ApiRequest.get, '/currency/');

    if (!error) {
      yield put(getCurrenciesSuccess(data));
    } else {
      yield put(getCurrenciesError(`${error}`));
    }
  } catch (error) {
    yield put(getCurrenciesError(`${error}`));
  }
}

export function* changeCurrency({ payload }: ChangeCurrencyAction): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { id, currency } = payload;
    const { error } = yield call(ApiRequest.put, `/currency/${id}/`, { ...currency });

    if (!error) {
      yield put(changeCurrenciesSuccess());
    } else {
      yield put(changeCurrenciesError(`${error}`));
    }
  } catch (error) {
    yield put(changeCurrenciesError(`${error}`));
  }
}

export function* putCustomDesign({ payload }: PutCustomDesignAction): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const formData = new FormData();
    const { imageFiles, startPage, partnerID } = payload;
    const params = {
      partner_id: partnerID || 1,
      image_fields: {},
      settings: payload.settings ? JSON.stringify(payload.settings) : null
    };
    if (imageFiles) {
      for (const [fieldName, fileData] of Object.entries(imageFiles)) {
        formData.append('images', fileData);
        params.image_fields[fileData.name] = fieldName;
      }
    }
    if (startPage) {
      formData.append('start_page', startPage);
    }
    params.image_fields = Object.keys(params.image_fields).length === 0 ? '' : JSON.stringify(params.image_fields);

    const { data, error }: PutCustomDesign = yield call(
      ApiRequest.post,
      `/custom-design/?domain=${payload.domain || ''}&${createQueryString(params)}`,
      formData
    );

    if (!error) {
      yield put(applyCustomDesign(data));
    } else {
      yield put(putCustomDesignError(`${error}`));
    }
  } catch (error) {
    yield put(putCustomDesignError(`${error}`));
  }
}

export function* getCustomDesign({ payload }: GetCustomDesignAction): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { merchantId, domain } = payload;
    let queryParams = {};
    if (merchantId) {
      queryParams = { partner_id: merchantId };
    } else if (domain) {
      queryParams = { domain };
    }
    const { data, error }: GetCustomDesign = yield call(
      ApiRequest.get,
      `/custom-design/?${createQueryString(queryParams)}`
    );

    if (!error) {
      yield put(applyCustomDesign(data));
    } else {
      yield put(getCustomDesignError(`${error}`));
    }
  } catch (error) {
    yield put(getCustomDesignError(`${error}`));
  }
}

export function* deleteCustomDesign({
  payload
}: DeleteCustomDesignAction): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const queryParams = createQueryString({ domain: payload.domain });
    const { error } = yield call(ApiRequest.remove, `/custom-design/?${queryParams}`);

    if (!error) {
      yield put(deleteCustomDesignSuccess());
    } else {
      yield put(deleteCustomDesignError(`${error}`));
    }
  } catch (error) {
    yield put(deleteCustomDesignError(`${error}`));
  }
}

// GCM SETTINGS
export function* getSystemSettings(): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const getSystemSettingsResponse = yield call(ApiRequest.get, '/settings/');
    if (!getSystemSettingsResponse.error) {
      yield put(getSystemSettingsSuccess(getSystemSettingsResponse));
    } else {
      yield put(getSystemSettingsError(`${getSystemSettingsResponse.error}`));
    }
  } catch (error) {}
}

export function* putSystemSettings({
  payload
}: PutSystemSettingsAction): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const putSystemSettingsResponse = yield call(ApiRequest.put, '/settings/', payload);
    if (putSystemSettingsResponse.error) {
      yield put(putSystemSettingsError(`${putSystemSettingsResponse.error}`));
    } else {
      yield put(putSystemSettingsSuccess());
      yield put({ type: GET_SYSTEM_SETTINGS });
    }
  } catch (error) {
    yield put(putSystemSettingsError(`${error}`));
  }
}

// PERMISSIONS SETTINGS
export function* getPermissionsSettings({
  payload
}: GetPermissionsSettingsAction): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const getPermissionsSettingsResponse = yield call(ApiRequest.get, `/users/${payload.id}/permissions/`);

    if (!getPermissionsSettingsResponse.error) {
      yield put(getPermissionsSettingsSuccess(getPermissionsSettingsResponse));
    } else {
      yield put(getPermissionsSettingsError(`${getPermissionsSettingsResponse.error}`));
    }
  } catch (error) {}
}

export function* putPermissionsSettings({
  payload
}: PutPermissionsSettingsAction): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { id, permissions } = payload;

    const putPermissionsResponse = yield call(ApiRequest.put, `/users/${id}/permissions/`, { permissions });
    if (!putPermissionsResponse.error) {
      yield put(putPermissionsSettingsSuccess(permissions));
    } else {
      yield put(putPermissionsSettingsError(`${putPermissionsResponse.error}`));
    }
  } catch (error) {}
}

// ACCOUNT SETTINGS
export function* getAccountSettings({
  payload
}: GetAccountSettingsAction): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { id } = payload;
    const { data, error }: GetUserAccountSettings = yield call(ApiRequest.get, `/users/${id}/`);
    if (error) {
      yield put(getAccountSettingsError(`${error}`));
    } else {
      yield put(getAccountSettingsSuccess(data));
    }
  } catch (error) {
    yield put(getAccountSettingsError(`${error}`));
  }
}

export function* putAccountSettings({
  payload
}: PutAccountSettingsAction): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { id, settings } = payload;
    const { error }: PutUserAccountSettings = yield call(ApiRequest.put, `/users/${id}/`, settings);
    if (error) {
      yield put(putAccountSettingsError(`${error}`));
    } else {
      yield put(putAccountSettingsSuccess());
    }
  } catch (error) {
    yield put(putAccountSettingsError(`${error}`));
  }
}

// AVAILABLE SITES
export function* getSitesSettings({ payload }: GetSiteSettingsAction): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const getSiteSettingsResponse = yield call(ApiRequest.get, `/users/${payload.id}/sites/`);

    if (!getSiteSettingsResponse.error) {
      yield put(getSitesSettingsSuccess(getSiteSettingsResponse));
    } else {
      yield put(getSitesSettingsError(`${getSiteSettingsResponse.error}`));
    }
  } catch (error) {}
}

// CLEAR CACHE

export function* putCache({ payload }: PutAccountSettingsAction): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { error }: ClearCacheResponse = yield call(ApiRequest.put, '/settings/clear-cache/', payload);
    if (error) {
      yield put(putSystemCacheError(`${error}`));
    } else {
      yield put(putSystemCacheSuccess());
    }
  } catch (error) {
    yield put(putSystemCacheError(`${error}`));
  }
}

// WATCHERS
export function* watchChangeLocale(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(CHANGE_LOCALE, changeLocale);
}

export function* watchChangeUser(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(CHANGE_USER, changeUser);
}

export function* watchGetCurrencies(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(GET_CURRENCIES, getCurrencies);
}
export function* watchChangeCurrency(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(CHANGE_CURRENCY, changeCurrency);
}

export function* watchPutCustomDesign(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(PUT_CUSTOM_DESIGN, putCustomDesign);
}

export function* watchGetCustomDesign(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(GET_CUSTOM_DESIGN, getCustomDesign);
}

export function* watchDeleteCustomDesign(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(DELETE_CUSTOM_DESIGN, deleteCustomDesign);
}

export function* watchChangeMerchant(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(CHANGE_MERCHANT, changeMerchant);
}

export function* watchGetSystemSettings(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(GET_SYSTEM_SETTINGS, getSystemSettings);
}

export function* watchPutSystemSettings(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(PUT_SYSTEM_SETTINGS, putSystemSettings);
}

export function* watchGetPermissionsSettings(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(GET_PERMISSIONS_SETTINGS, getPermissionsSettings);
}

export function* watchGetSitesSettings(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(GET_SITES_SETTINGS, getSitesSettings);
}

export function* watchPutPermissionsSettings(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(PUT_PERMISSIONS_SETTINGS, putPermissionsSettings);
}

export function* watchGetAccountSettings(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(GET_ACCOUNT_SETTINGS, getAccountSettings);
}

export function* watchPutAccountSettings(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(PUT_ACCOUNT_SETTINGS, putAccountSettings);
}

export function* watchputCache(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(PUT_SYSTEM_CACHE, putCache);
}

export default function* rootSaga(): Generator<CombinatorEffect<string, any>, any, any> {
  yield all([
    fork(watchChangeLocale),
    fork(watchChangeUser),
    fork(watchChangeMerchant),
    fork(watchGetCurrencies),
    fork(watchChangeCurrency),
    fork(watchPutCustomDesign),
    fork(watchGetCustomDesign),
    fork(watchDeleteCustomDesign),
    fork(watchGetSystemSettings),
    fork(watchPutSystemSettings),
    fork(watchPutPermissionsSettings),
    fork(watchGetPermissionsSettings),
    fork(watchGetAccountSettings),
    fork(watchPutAccountSettings),
    fork(watchGetSitesSettings),
    fork(watchputCache)
  ]);
}
