import format from '../../localization';

import { CellInfo } from 'react-table';
import { MessageType, SenderKeys, SupportStateType } from './constants';
import { Message, TypeSelectItem } from './interface';
import { DATE_TIME_FORMAT, DATE_TIME_FORMAT_WITH_SEC } from '../../constants/app';
import { Merchant } from '../../redux/menu/interface';
import { SelectorOption } from '../../interfaces/app';
import { Service } from '../../redux/services/interface';

export const getRequestColumns = (type: SupportStateType, merchants: Merchant[], t: any) => {
  switch (type) {
    case SupportStateType.Active:
      return [
        {
          Header: t('support.id'),
          accessor: 'id'
        },
        {
          Header: t('support.request_type'),
          accessor: 'type_name',
          Cell: ({ value }: CellInfo) => t(value)
        },
        {
          Header: t('support.merchant'),
          accessor: 'partner_id',
          Cell: ({ value }: CellInfo) => {
            const currMerchants =
              merchants && merchants.length && merchants.find((merchant: Merchant) => merchant.id === value);
            return currMerchants ? currMerchants.name : '';
          }
        },
        {
          Header: t('support.create_date'),
          accessor: 'create_time',
          Cell: ({ value }: CellInfo) => format(value, DATE_TIME_FORMAT_WITH_SEC)
        },
        {
          Header: t('support.status'),
          accessor: 'status',
          Cell: ({ value }: CellInfo) => value && t(`support.status.${value}`)
        },
        {
          Header: t('support.assigned_to'),
          accessor: 'executor'
        }
        // ---- Пока нет реализации на бэке
        // {
        //   Header: '',
        //   width: 30,
        //   accessor: 'id',
        //   Cell: ({ original }: CellInfo) => (
        //     <div onClick={cancelRequest(original.id)}>
        //       <i className="simple-icon-close support-icon" />
        //     </div>
        //   )
        // }
      ];

    case SupportStateType.Archive:
      return [
        {
          Header: t('support.id'),
          accessor: 'id'
        },
        {
          Header: t('support.request_type'),
          accessor: 'type_name',
          Cell: ({ value }: CellInfo) => t(value)
        },
        {
          Header: t('support.merchant'),
          accessor: 'partner_id',
          Cell: ({ value }: CellInfo) => {
            const currMerchants =
              merchants && merchants.length && merchants.find((merchant: Merchant) => merchant.id === value);
            return currMerchants ? currMerchants.name : '';
          }
        },
        {
          Header: t('support.create_date'),
          accessor: 'create_time',
          Cell: ({ value }: CellInfo) => format(value, DATE_TIME_FORMAT_WITH_SEC)
        },
        {
          Header: t('support.close_date'),
          accessor: 'change_time',
          Cell: ({ value }: CellInfo) => format(value, DATE_TIME_FORMAT_WITH_SEC)
        },
        {
          Header: t('support.closed_by'),
          accessor: 'executor'
        }
      ];

    default:
      break;
  }
};

export const reduceData = (data: { [serviceType: number]: Service[] }): SelectorOption[] => {
  if (!data) {
    return [];
  }
  let allAccounts: Service[] = [];

  data &&
    Object.keys(data).length &&
    Object.values(data).forEach((account) => {
      allAccounts = allAccounts.concat(account);
    });

  const convertedData = {};
  allAccounts.forEach((item: Service) => {
    if (!convertedData[item.contract_name]) {
      convertedData[item.contract_name] = {
        accounts: [],
        value: item.contract_name,
        key: item.contract_name,
        id: item.contract_name,
        label: item.contract_name
      };
    }
    convertedData[item.contract_name].accounts.push({ value: item.id, key: item.name, label: item.name });
  });
  return Object.values(convertedData);
};

export const addNewMessage = (prevData: Message[] | null, message?: string, file?: File): Message[] => {
  const newMessage = {
    source: SenderKeys.Me,
    type: file ? MessageType.File : MessageType.Text,
    message,
    create_time: format(new Date(), DATE_TIME_FORMAT),
    file_link: file ? file.name : '',
    file_name: file ? file.name : ''
  };

  return prevData ? [...prevData, newMessage] : [newMessage];
};

export const getTreeSelectData = (types, t): TypeSelectItem[] => {
  if (types && types.length) {
    return types.map(({ id, name, children }) => ({
      title: t(name),
      key: id,
      value: id,
      selectable: !Boolean(children),
      children: getTreeSelectData(children, t)
    }));
  }
  return [];
};
