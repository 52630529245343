import React, { Component, Fragment, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Row, Label, FormGroup, Button, Card, CardBody } from 'reactstrap';
import { Field, Form, Formik } from 'formik';

import { NotificationManager } from '../../../../components/common/react-notifications';
import { Loader } from '../../../../components/common/Loader';
import { Colxx } from '../../../../components/common/CustomBootstrap';
import { putSystemSettings, putSystemCache } from '../../../../redux/actions';

import { checkIntValue, getConditionToProperty } from './helpers';
import { INITIAL_VALUES, LIMITS } from './constants';
import { NOTIFICATION_TIMEOUT } from '../../../../constants/defaultValues';
import { SystemSettingsProps } from './interface';
import { SystemSettingsState } from './../interface';
import { withTranslation } from 'react-i18next';
import Select from 'react-select';
import { isAllowed } from '../../../../helpers/Access';

class Gcm extends Component<SystemSettingsProps, SystemSettingsState> {
  constructor(props: any) {
    super(props);
    this.state = {
      tabItems: [],
      selectedCache: null
    };
  }

  componentDidUpdate(prevProps: SystemSettingsProps): void {
    const { error } = this.props;

    if (error && error !== prevProps.error) {
      NotificationManager.info(error, 'Ошибка', NOTIFICATION_TIMEOUT, null, null, '');
    }
  }

  onSubmit = (values): void => {
    this.props.putSettings(values);
  };

  onCacheClear = (values): void => {
    const { selectedCache } = this.state;
    const payload = { type: String(selectedCache) };
    this.props.putCache(payload);
  };

  isNoChanges = (values): boolean => {
    const { data } = this.props;

    return (
      data &&
      Boolean(
        getConditionToProperty(values, data, 'max_graph_days') &&
          getConditionToProperty(values, data, 'max_csv_days') &&
          getConditionToProperty(values, data, 'max_display_records') &&
          getConditionToProperty(values, data, 'max_login_attempts') &&
          getConditionToProperty(values, data, 'max_failed_2fa') &&
          getConditionToProperty(values, data, 'max_password_days') &&
          getConditionToProperty(values, data, 'max_restore_minutes') &&
          getConditionToProperty(values, data, 'max_operation_days') &&
          getConditionToProperty(values, data, 'max_page_size') &&
          getConditionToProperty(values, data, 'max_refunds')
      )
    );
  };

  validate = (values): { [key: string]: string } =>
    Object.keys(values).reduce(
      (accumulator, key) =>
        LIMITS[key] && values[key] > LIMITS[key].max
          ? { ...accumulator, [key]: `Максимальное значение ${LIMITS[key].max}` }
          : LIMITS[key] && values[key] < LIMITS[key].min
          ? { ...accumulator, [key]: `Минимальное значение ${LIMITS[key].min}` }
          : accumulator,
      {}
    );

  validateCache = (values): { [key: string]: string } =>
    Object.keys(values).reduce(
      (accumulator, key) => (values[key] ? accumulator : { ...accumulator, [key]: 'Не может быть пустым' }),
      {}
    );

  render(): ReactNode {
    const { data, loading, user, selectedUser, t } = this.props;
    const disabled = Boolean(loading || !data);
    const cacheOptions = [{ label: 'permissions', value: 'permissions' }, { label: 'sites', value: 'sites' }];
    const currentUser = selectedUser ? selectedUser : user;
    const allowToClearCache = isAllowed(currentUser, 32);
    const defaultCache = {};
    return (
      <Fragment>
        {loading && <Loader />}

        <Formik
          validate={this.validate}
          validateOnBlur={false}
          enableReinitialize={true}
          initialValues={data ? data : INITIAL_VALUES}
          onSubmit={this.onSubmit}
        >
          {({ setFieldValue, errors, isValid, values }) => {
            const buttonsDisabled = this.isNoChanges(values) || !isValid || disabled;
            return (
              <Form>
                <Card className="mb-4">
                  <CardBody>
                    <Row>
                      <Colxx xxs="12" md="6" xl="4" className="gms-settings-column">
                        <FormGroup className="form-group gms-settings">
                          <Label className="gms-settings-label">{t('settings.maximum period to display')}</Label>
                          <Field
                            disabled={disabled}
                            className="form-control"
                            name="max_graph_days"
                            type="text"
                            onChange={({ target: { value } }) => {
                              if (checkIntValue(value)) {
                                setFieldValue('max_graph_days', value ? Number(value) : '');
                              }
                            }}
                          />
                          {errors.max_graph_days && (
                            <div className="invalid-feedback d-block">{errors.max_graph_days}</div>
                          )}
                        </FormGroup>
                      </Colxx>

                      <Colxx xxs="12" md="6" xl="4" className="gms-settings-column">
                        <FormGroup className="form-group">
                          <Label className="gms-settings-label">{t('settings.maximum period to generate')}</Label>
                          <Field
                            disabled={disabled}
                            className="form-control"
                            name="max_csv_days"
                            type="text"
                            onChange={({ target: { value } }) => {
                              if (checkIntValue(value)) {
                                setFieldValue('max_csv_days', value ? Number(value) : '');
                              }
                            }}
                          />
                          {errors.max_csv_days && <div className="invalid-feedback d-block">{errors.max_csv_days}</div>}
                        </FormGroup>
                      </Colxx>

                      <Colxx xxs="12" md="6" xl="4" className="gms-settings-column">
                        <FormGroup className="gms-settings">
                          <Label className="gms-settings-label">{t('settings.maximum lines count to display')}</Label>
                          <Field
                            disabled={disabled}
                            className="form-control"
                            name="max_display_records"
                            type="text"
                            onChange={({ target: { value } }) => {
                              if (checkIntValue(value)) {
                                setFieldValue('max_display_records', value ? Number(value) : '');
                              }
                            }}
                          />
                          {errors.max_display_records && (
                            <div className="invalid-feedback d-block">{errors.max_display_records}</div>
                          )}
                        </FormGroup>
                      </Colxx>

                      <Colxx xxs="12" md="6" xl="4" className="gms-settings-column">
                        <FormGroup className="gms-settings">
                          <Label className="gms-settings-label">{t('settings.maximum log in attempts')}</Label>
                          <Field
                            disabled={disabled}
                            className="form-control"
                            name="max_login_attempts"
                            type="text"
                            onChange={({ target: { value } }) => {
                              if (checkIntValue(value)) {
                                setFieldValue('max_login_attempts', value ? Number(value) : '');
                              }
                            }}
                          />
                          {errors.max_login_attempts && (
                            <div className="invalid-feedback d-block">{errors.max_login_attempts}</div>
                          )}
                        </FormGroup>
                      </Colxx>
                      <Colxx xxs="12" md="6" xl="4" className="gms-settings-column">
                        <FormGroup className="gms-settings">
                          <Label className="gms-settings-label">{t('settings.maximum 2FA enter code attempts')}</Label>
                          <Field
                            disabled={disabled}
                            className="form-control"
                            name="max_failed_2fa"
                            type="text"
                            onChange={({ target: { value } }) => {
                              if (checkIntValue(value)) {
                                setFieldValue('max_failed_2fa', value ? Number(value) : '');
                              }
                            }}
                          />
                          {errors.max_failed_2fa && (
                            <div className="invalid-feedback d-block">{errors.max_failed_2fa}</div>
                          )}
                        </FormGroup>
                      </Colxx>

                      <Colxx xxs="12" md="6" xl="4" className="gms-settings-column">
                        <FormGroup className="gms-settings">
                          <Label className="gms-settings-label">
                            {t('settings.maximum period before password reset')}
                          </Label>
                          <Field
                            disabled={disabled}
                            className="form-control"
                            name="max_password_days"
                            type="text"
                            onChange={({ target: { value } }) => {
                              if (checkIntValue(value)) {
                                setFieldValue('max_password_days', value ? Number(value) : '');
                              }
                            }}
                          />
                          {errors.max_password_days && (
                            <div className="invalid-feedback d-block">{errors.max_password_days}</div>
                          )}
                        </FormGroup>
                      </Colxx>

                      <Colxx xxs="12" md="6" xl="4" className="gms-settings-column">
                        <FormGroup className="gms-settings">
                          <Label className="gms-settings-label">
                            {t('settings.password reset link lifetime (minutes)')}
                          </Label>
                          <Field
                            disabled={disabled}
                            className="form-control"
                            name="max_restore_minutes"
                            type="text"
                            onChange={({ target: { value } }) => {
                              if (checkIntValue(value)) {
                                setFieldValue('max_restore_minutes', value ? Number(value) : '');
                              }
                            }}
                          />
                          {errors.max_restore_minutes && (
                            <div className="invalid-feedback d-block">{errors.max_restore_minutes}</div>
                          )}
                        </FormGroup>
                      </Colxx>

                      <Colxx xxs="12" md="6" xl="4" className="gms-settings-column">
                        <FormGroup className="form-group gms-settings">
                          <Label className="gms-settings-label">{t('settings.maximum difference')}</Label>
                          <Field
                            disabled={disabled}
                            className="form-control"
                            name="max_operation_days"
                            type="text"
                            onChange={({ target: { value } }) => {
                              if (checkIntValue(value)) {
                                setFieldValue('max_operation_days', value ? Number(value) : '');
                              }
                            }}
                          />
                          {errors.max_operation_days && (
                            <div className="invalid-feedback d-block">{errors.max_operation_days}</div>
                          )}
                        </FormGroup>
                      </Colxx>

                      <Colxx xxs="12" md="6" xl="4" className="gms-settings-column">
                        <FormGroup className="form-group gms-settings">
                          <Label className="gms-settings-label">{t('settings.maximum_row_in_table')}</Label>
                          <Field
                            disabled={disabled}
                            className="form-control"
                            name="max_page_size"
                            type="text"
                            onChange={({ target: { value } }) => {
                              if (checkIntValue(value)) {
                                setFieldValue('max_page_size', value ? Number(value) : '');
                              }
                            }}
                          />
                          {errors.max_page_size && (
                            <div className="invalid-feedback d-block">{errors.max_page_size}</div>
                          )}
                        </FormGroup>
                      </Colxx>

                      <Colxx xxs="12" md="6" xl="4" className="gms-settings-column">
                        <FormGroup className="form-group gms-settings">
                          <Label className="gms-settings-label">Максимальное к-во операций возврата в день (шт.)</Label>
                          <Field
                            disabled={disabled}
                            className="form-control"
                            name="max_refunds"
                            type="text"
                            onChange={({ target: { value } }) => {
                              if (checkIntValue(value)) {
                                setFieldValue('max_refunds', value ? Number(value) : '');
                              }
                            }}
                          />
                          {errors.max_refunds && <div className="invalid-feedback d-block">{errors.max_refunds}</div>}
                        </FormGroup>
                      </Colxx>

                      <Colxx xxs="12" className="d-flex justify-content-end">
                        <Button disabled={buttonsDisabled} color="primary" type="submit">
                          {t('button.save')}
                        </Button>
                        <Button disabled={buttonsDisabled} color="primary" type="reset" className="ml-2">
                          {t('settings.button.cancel')}
                        </Button>
                      </Colxx>
                    </Row>
                  </CardBody>
                </Card>
              </Form>
            );
          }}
        </Formik>
        <Formik
          validate={this.validateCache}
          validateOnBlur={false}
          initialValues={defaultCache}
          enableReinitialize={true}
          onSubmit={this.onCacheClear}
        >
          {() => (
            <Form>
              <Card className="mb-4">
                <CardBody>
                  <Row>
                    <Colxx xxs="12" className="mb-4">
                      <Row>
                        <Colxx xxs="12" sm="12" md="3" lg="3">
                          <Label className="float-label">{t('settings.cache type')}</Label>
                          <Select
                            name="type"
                            className="react-select"
                            classNamePrefix="react-select"
                            options={cacheOptions ? cacheOptions : []}
                            onChange={(cache: any) => {
                              this.setState({ selectedCache: cache.value });
                            }}
                            disabled={!allowToClearCache}
                            placeholder={t('settings.cache type')}
                          />
                        </Colxx>
                        <Colxx xxs="12" md="3" xl="3" className="gms-settings-column d-flex justify-content-end">
                          <Button disabled={!allowToClearCache} color="primary" type="submit">
                            {t('settings.reset cache')}
                          </Button>
                        </Colxx>
                      </Row>
                    </Colxx>
                  </Row>
                </CardBody>
              </Card>
            </Form>
          )}
        </Formik>
      </Fragment>
    );
  }
}

const mapDispatchToProps = {
  putSettings: putSystemSettings,
  putCache: putSystemCache
};

const mapStateToProps = ({ authUser, settings }) => ({
  ...settings.gcmSettings,
  user: authUser.user.data ? authUser.user.data : null,
  selectedUser: settings.selectedUser
});

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Gcm)
);
