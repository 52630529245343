import { GET_CSV_LIST, GET_CSV_LIST_SUCCESS, GET_CSV_LIST_ERROR, RESET_DOWNLOADS } from '../actions';

import { CsvListData, GetCsvList, GetCsvListSuccess, GetCsvListError, ResetDownloads } from './interface';

// GET CSV LIST
export const getCsvList = (userId?: number): GetCsvList => ({
  type: GET_CSV_LIST,
  payload: { userId }
});

export const getCsvListSuccess = (data: CsvListData[]): GetCsvListSuccess => ({
  type: GET_CSV_LIST_SUCCESS,
  payload: { data }
});

export const getCsvListError = (error: string): GetCsvListError => ({
  type: GET_CSV_LIST_ERROR,
  payload: { error }
});

// RESET DOWNLOADS

export const resetDownloads = (): ResetDownloads => ({
  type: RESET_DOWNLOADS
});
