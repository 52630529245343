import { ALL_OPTION } from '../journal/constants';
import { startOfDay } from 'date-fns';
import { IDisposalListFilterValues } from './interface';

export const DISPOSAL_MENU_ITEMS = (t: any) => [
  {
    label: t('disposals.history'),
    permission: 19,
    path: '/disposals'
  },
  {
    label: t('disposals.create'),
    permission: 20,
    path: '/disposals/create',
    expectAdmin: true
  }
];

export enum DisposalStatus {
  New = 1,
  Accepted,
  Completed,
  Canceled
}

export const DisposalStatusNames = (t: any) => ({
  [DisposalStatus.New]: t('disposals.status.new'),
  [DisposalStatus.Accepted]: t('disposals.status.in_process'),
  [DisposalStatus.Completed]: t('disposals.status.done'),
  [DisposalStatus.Canceled]: t('disposals.status.cancelled')
});

export const DEFAULT_SORTER = [
  {
    id: 'id',
    desc: true
  }
];

export enum CreateDisposalType {
  StatementToAccount = 1,
  StatementToCheckingAccount,
  AccountToAccount
}

export enum SumStatementType {
  Acquiring = 1,
  MobilePay,
  MC,
  Payouts,
  All
}

export const CREATE_DISPOSAL_TYPE = (t: any) => ({
  [CreateDisposalType.StatementToAccount]: t('disposals.To payout'),
  [CreateDisposalType.StatementToCheckingAccount]: t('disposals.To merchant account'),
  [CreateDisposalType.AccountToAccount]: t('disposals.To bank account')
});

export const SUM_STATEMENT_TYPE = (t: any) => ({
  [SumStatementType.All]: t('all'),
  [SumStatementType.Acquiring]: t('disposals.service.bank_cards'),
  [SumStatementType.MobilePay]: t('disposals.service.mobile_pay'),
  [SumStatementType.MC]: t('disposals.service.mobile_commerce'),
  [SumStatementType.Payouts]: t('disposals.service.payouts')
});

export const CREATE_DISPOSAL_TYPE_OPTIONS = (t: any) => [
  {
    key: CreateDisposalType.StatementToAccount,
    value: CreateDisposalType.StatementToAccount,
    label: CREATE_DISPOSAL_TYPE(t)[CreateDisposalType.StatementToAccount]
  },
  {
    key: CreateDisposalType.StatementToCheckingAccount,
    value: CreateDisposalType.StatementToCheckingAccount,
    label: CREATE_DISPOSAL_TYPE(t)[CreateDisposalType.StatementToCheckingAccount]
  },
  {
    key: CreateDisposalType.AccountToAccount,
    value: CreateDisposalType.AccountToAccount,
    label: CREATE_DISPOSAL_TYPE(t)[CreateDisposalType.AccountToAccount]
  }
];

export const SUM_STATEMENT_TYPE_OPTIONS = (t: any) => [
  {
    key: SumStatementType.Acquiring,
    value: SumStatementType.Acquiring,
    label: SUM_STATEMENT_TYPE(t)[SumStatementType.Acquiring]
  },
  {
    key: SumStatementType.MobilePay,
    value: SumStatementType.MobilePay,
    label: SUM_STATEMENT_TYPE(t)[SumStatementType.MobilePay]
  },
  {
    key: SumStatementType.MC,
    value: SumStatementType.MC,
    label: SUM_STATEMENT_TYPE(t)[SumStatementType.MC]
  }
];

export const GROUPED_STATEMENT_TYPE_OPTIONS = (t: any) => [
  {
    key: SumStatementType.MC,
    value: SumStatementType.MC,
    label: SUM_STATEMENT_TYPE(t)[SumStatementType.MC]
  },
  {
    key: SumStatementType.Payouts,
    value: SumStatementType.Payouts,
    label: SUM_STATEMENT_TYPE(t)[SumStatementType.Payouts]
  },
  {
    label: t('disposals.service.acquiring'),
    options: [
      {
        key: SumStatementType.Acquiring,
        value: SumStatementType.Acquiring,
        label: SUM_STATEMENT_TYPE(t)[SumStatementType.Acquiring]
      },
      {
        key: SumStatementType.MobilePay,
        value: SumStatementType.MobilePay,
        label: SUM_STATEMENT_TYPE(t)[SumStatementType.MobilePay]
      }
    ]
  }
];

export const STATEMENT_TYPE_OPTIONS = (t: any) => [
  {
    key: SumStatementType.MC,
    value: SumStatementType.MC,
    label: SUM_STATEMENT_TYPE(t)[SumStatementType.MC]
  },
  {
    key: SumStatementType.Payouts,
    value: SumStatementType.Payouts,
    label: SUM_STATEMENT_TYPE(t)[SumStatementType.Payouts]
  },
  {
    key: SumStatementType.Acquiring,
    value: SumStatementType.Acquiring,
    label: SUM_STATEMENT_TYPE(t)[SumStatementType.Acquiring]
  },
  {
    key: SumStatementType.MobilePay,
    value: SumStatementType.MobilePay,
    label: SUM_STATEMENT_TYPE(t)[SumStatementType.MobilePay]
  }
];

export const DEFAULT_PARTNER_OPTION = {
  key: 0,
  label: '',
  value: 0
};
export const DEFAULT_CONTRACT_OPTION = {
  key: 0,
  label: '',
  value: []
};

export const CREATE_DISPOSAL_INITIAL_VALUES = (t: any) => ({
  partner: DEFAULT_PARTNER_OPTION,
  contract: DEFAULT_CONTRACT_OPTION,
  type: CREATE_DISPOSAL_TYPE_OPTIONS(t)[0],
  service: SUM_STATEMENT_TYPE_OPTIONS(t)[0],
  amount: '',
  currency: ALL_OPTION(t),
  currency_income: ALL_OPTION(t),
  checking_account: '',
  selectedTableItems: 0,
  comment: ''
});

export const DISPOSAL_CONTRACT_ACCOUNT_TYPE = 3;
export const DISPOSAL_ACTIVE_ACCOUNTS_SOURCE_TYPE = 2;
export const DISPOSAL_ACTIVE_ACCOUNTS_SOURCE = 1;
export const DISPOSAL_ACTIVE_STATEMENTS_SOURCE_TYPE = 1;

export const CONFIRMATION_TEXT = (t: any) => ({
  default: {
    title: t('disposals.processing.title'),
    body: t('disposals.processing.body')
  },
  [DisposalStatus.Canceled]: {
    title: t('disposals.decline.title'),
    body: t('disposals.decline.body')
  },
  [DisposalStatus.Completed]: {
    title: t('disposals.processed.title'),
    body: t('disposals.processed.body')
  }
});

export enum ServiceTypes {
  Income = 1,
  Mc,
  Outcome
}

export const DISPOSAL_LIST_FILTER_INITIAL_VALUES: IDisposalListFilterValues = {
  dateFrom: startOfDay(new Date()),
  dateTo: new Date(),
  id: '',
  partner: ALL_OPTION[0],
  currency: ALL_OPTION[0],
  status: ALL_OPTION[0],
  type: ALL_OPTION[0]
};
