import React, { Component, ReactNode } from 'react';
import { DropzoneComponent, DropzoneComponentConfig, DropzoneComponentHandlers } from 'react-dropzone-component';
import 'dropzone/dist/min/dropzone.min.css';
import { DropzoneOptions } from 'dropzone';
import ReactDOMServer from 'react-dom/server';
import { FILE_TOO_BIG, INVALID_FILE_TYPE } from '../../constants/app';
import { withTranslation } from 'react-i18next';
import { DropZoneProps } from './interface';

class DropZone extends Component<DropZoneProps> {
  render(): ReactNode {
    const {
      init = undefined,
      addedfile = undefined,
      removedfile = undefined,
      maxfilesexceeded = undefined,
      postUrl = 'no-url',
      autoProcessQueue = false,
      maxFilesize = 2,
      maxFiles = null,
      acceptedFiles = undefined,
      error = undefined,
      t
    } = this.props;

    const eventHandlers: DropzoneComponentHandlers = {
      init,
      addedfile,
      removedfile,
      maxfilesexceeded,
      error
    };

    const dropzoneComponentConfig: DropzoneComponentConfig = { postUrl };

    const dropzoneConfig: DropzoneOptions = {
      autoProcessQueue,
      maxFilesize,
      maxFiles,
      dictDefaultMessage: `<span class="label"><i class="simple-icon-cloud-upload left-text-icon" /> ${t(
        'customDesign.services'
      )}${maxFiles === 1 ? '' : 'ы'}</span>`,
      dictFileTooBig: FILE_TOO_BIG,
      dictInvalidFileType: INVALID_FILE_TYPE,
      thumbnailHeight: 160,
      acceptedFiles: acceptedFiles,
      previewTemplate: ReactDOMServer.renderToStaticMarkup(
        <div className="dz-preview dz-file-preview mb-3">
          <div className="d-flex flex-row">
            <div className="p-0 w-30 position-relative">
              <div className="dz-error-mark">
                <span>
                  <i />{' '}
                </span>
              </div>
              <div className="dz-success-mark">
                <span>
                  <i />
                </span>
              </div>
              <div className="preview-container">
                <img data-dz-thumbnail className="img-thumbnail border-0" alt="thumbnail" />
                <i className="simple-icon-doc preview-icon" />
              </div>
            </div>
            <div className="pl-3 pt-2 pr-2 pb-1 w-70 dz-details position-relative">
              <div>
                {' '}
                <span data-dz-name />{' '}
              </div>
              <div className="text-primary text-extra-small" data-dz-size />
              {autoProcessQueue && (
                <div className="dz-progress">
                  <span className="dz-upload" data-dz-uploadprogress />
                </div>
              )}
              <div className="dz-error-message">
                <span data-dz-errormessage />
              </div>
            </div>
          </div>
          <a href="#/" className="remove" data-dz-remove>
            {' '}
            <i className="glyph-icon simple-icon-trash" />{' '}
          </a>
        </div>
      )
    };

    return (
      <DropzoneComponent eventHandlers={eventHandlers} config={dropzoneComponentConfig} djsConfig={dropzoneConfig} />
    );
  }
}
export default withTranslation()(DropZone);
