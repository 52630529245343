import React, { Component, ReactNode } from 'react';
import { Row } from 'reactstrap';
import ReactTable from 'react-table';
import isEqual from 'lodash/isEqual';

import { Colxx } from '../../../components/common/CustomBootstrap';
import { NotFoundMessage } from '../../../components/common/NotFoundMessage';

import { getAccountTableColumn, getStatementsTableColumn, SCROLL_TABLE_STYLE } from './constants';
import { StatementItem } from '../../../redux/disposals/interface';
import { StatementToAccountProps } from '../interface';
import { withTranslation } from 'react-i18next';

class StatementToAccount extends Component<StatementToAccountProps> {
  selectStatement = (statement: StatementItem, isChecked: boolean) =>
    this.props.onStatementsSelect(statement, isChecked);

  selectAccount = (account: StatementItem) => this.props.onAccountSelect(account);

  shouldComponentUpdate(nextProps: Readonly<StatementToAccountProps>): boolean {
    return !isEqual(nextProps, this.props);
  }

  render(): ReactNode {
    const { statements, accounts, sumAmount, currencies, t } = this.props;

    return (
      <>
        <Row>
          <Colxx xxs="12" className="mb-4">
            {statements && statements.length > 0 && (
              <Row className="d-flex align-content-center">
                <Colxx xxs="12" md="12" className="pr-4">
                  <div className="font-weight-bold">{`${t('disposals.total_amount')}: ${sumAmount}`}</div>
                </Colxx>
              </Row>
            )}
            <Row className="d-flex align-content-center">
              <Colxx xxs="12" md="6" className="pr-4">
                {statements && statements.length > 0 && (
                  <>
                    <ReactTable
                      className="disposals-table"
                      data={statements}
                      columns={getStatementsTableColumn(this.selectStatement, currencies, t)}
                      pageSize={statements && statements.length ? statements.length : 0}
                      sortable={false}
                      filterable={false}
                      showPageJump={false}
                      showPagination={false}
                      showPageSizeOptions={false}
                      loadingText=""
                      noDataText="Нет данных"
                      style={SCROLL_TABLE_STYLE}
                    />
                  </>
                )}
                {statements && !statements.length && <NotFoundMessage text="Нет данных стейтментов" />}
              </Colxx>

              <Colxx xxs="12" md="6" className="pl-4">
                {accounts && accounts.length > 0 && (
                  <ReactTable
                    className="disposals-table"
                    data={accounts}
                    columns={getAccountTableColumn(this.selectAccount, currencies, t)}
                    pageSize={accounts && accounts.length ? accounts.length : 0}
                    sortable={false}
                    filterable={false}
                    showPageJump={false}
                    showPagination={false}
                    showPageSizeOptions={false}
                    loadingText=""
                    noDataText="Нет данных"
                    style={SCROLL_TABLE_STYLE}
                  />
                )}
                {accounts && !accounts.length && <NotFoundMessage text="Нет данных аккаунтов" />}
              </Colxx>
            </Row>
          </Colxx>
        </Row>
      </>
    );
  }
}

export default withTranslation()(StatementToAccount);
