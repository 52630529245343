export const SUPPORT_PAGE_SIZE = 50;

export enum SupportStateType {
  Active,
  Archive
}

export const FINANCES_IDS = [13, 14, 15, 16, 17];

export const SUPPORT_INITIAL_VALUES = {
  partner: {
    value: '',
    label: '',
    key: 0
  },
  contract: {
    value: '',
    label: '',
    key: 0
  },
  account: {
    value: '',
    label: '',
    key: 0
  },
  message: '',
  request_type: '',
  doc_file: undefined
};

export enum SenderKeys {
  Me = 1,
  Them = 2
}
export enum SenderNames {
  Me = 'me',
  Them = 'them'
}
export const Sender = {
  [SenderKeys.Me]: SenderNames.Me,
  [SenderKeys.Them]: SenderNames.Them
};

export enum MessageSender {
  Sent = 'sent',
  Received = 'received'
}

export enum MessageType {
  Text = 'text',
  File = 'file'
}
