import React, { Component, ReactNode } from 'react';
import { Formik, Form, Field } from 'formik';
import { Label, FormGroup, Button } from 'reactstrap';
import * as cn from 'classnames';

import { PasswordStrengthMeter } from '../../../components/common/PasswordStrengthMeter';
import { trimSpaces } from '../../../helpers/Utils';
import { MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH } from '../../profile/ChangePassword/constant';
import { withTranslation } from 'react-i18next';

class SetNewPasswordForm extends Component<any, any> {
  state = {
    showNewPassword: false,
    showNewPasswordConfirm: false
  };

  validate = (values) => {
    const reducedValues: typeof values = trimSpaces(values);
    const { newPassword, newPasswordConfirm } = reducedValues;
    const { t } = this.props;

    const errors = Object.getOwnPropertyNames(values).reduce(
      (accumulator, key) => (values[key] ? accumulator : { ...accumulator, [key]: t('Поле не может быть пустым') }),
      {}
    );

    const digitMatch = newPassword ? newPassword.match(/[0-9]/g) : null;
    const uppercaseMatch = newPassword ? newPassword.match(/[A-Z]/g) : null;
    const symbolsMatch = newPassword ? newPassword.match(/[!@#$%^&*(),.?":;{}+\-|<>[\]/_]/g) : null;

    if (newPassword && !(digitMatch && uppercaseMatch && symbolsMatch)) {
      errors['newPassword'] = t('tooltip.password_contains');
    }

    if ((newPassword && newPassword.length < MIN_PASSWORD_LENGTH) || newPassword.length > MAX_PASSWORD_LENGTH) {
      errors['newPassword'] = t('tooltip.password_length_between');
    }

    if (newPassword && newPasswordConfirm && newPassword !== newPasswordConfirm) {
      errors['newPasswordConfirm'] = t('password_not_match');
    }

    return errors;
  };

  render(): ReactNode {
    const { onSubmit, loading, t } = this.props;
    const { showNewPassword, showNewPasswordConfirm } = this.state;

    return (
      <Formik initialValues={{ newPassword: '', newPasswordConfirm: '' }} validate={this.validate} onSubmit={onSubmit}>
        {({ errors, touched, values, isValid, setFieldValue, setErrors, setFieldTouched }) => (
          <Form className="av-tooltip tooltip-label-bottom">
            <FormGroup className="form-group has-float-label tooltip-right-bottom mb-5">
              <Label className="float-label">{t('user.new-password')}</Label>
              <Field
                className="form-control"
                type={showNewPassword ? 'text' : 'password'}
                name="newPassword"
                onChange={(e) => {
                  const { newPassword, ...newErrors } = errors;
                  setErrors(newErrors);
                  setFieldValue('newPassword', e.target.value);
                }}
                onBlur={() => setFieldTouched('newPassword', true)}
              />
              <div
                className={cn('simple-icon-eye show-password-eye cursor-pointer', {
                  'color-theme-1': showNewPassword
                })}
                onClick={() => this.setState({ showNewPassword: !showNewPassword })}
              />
              {errors.newPassword && touched.newPassword && (
                <div className="invalid-feedback d-block">{errors.newPassword}</div>
              )}
            </FormGroup>

            <FormGroup className="form-group has-float-label tooltip-right-bottom mb-5">
              <Label className="float-label">{t('user.repeat-new-password')}</Label>
              <Field
                className="form-control"
                type={showNewPasswordConfirm ? 'text' : 'password'}
                name="newPasswordConfirm"
                onChange={(e) => {
                  const { newPasswordConfirm, ...newErrors } = errors;
                  setErrors(newErrors);
                  setFieldValue('newPasswordConfirm', e.target.value);
                }}
                onBlur={() => setFieldTouched('newPasswordConfirm', true)}
              />
              <div
                className={cn('simple-icon-eye show-password-eye cursor-pointer', {
                  'color-theme-1': showNewPasswordConfirm
                })}
                onClick={() => this.setState({ showNewPasswordConfirm: !showNewPasswordConfirm })}
              />
              {errors.newPasswordConfirm && touched.newPasswordConfirm && (
                <div className="invalid-feedback d-block">{errors.newPasswordConfirm}</div>
              )}
            </FormGroup>

            <FormGroup className="mb-5">
              <PasswordStrengthMeter value={values.newPassword} />
            </FormGroup>

            <div className="d-flex justify-content-center">
              <Button
                type="submit"
                color="primary"
                className={`btn-multiple-state ${loading ? 'show-spinner' : ''}`}
                disabled={!isValid}
              >
                <span className="spinner d-inline-block">
                  <span className="bounce1" />
                  <span className="bounce2" />
                  <span className="bounce3" />
                </span>
                <span className="label">{t('button.save')}</span>
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default withTranslation()(SetNewPasswordForm);
