import { defaultMenuItems, adminMenuItems } from './../constants/menu';
import { CookieAuthStatus } from '../views/user/Login/constant';

export const checkMenuItems = (user, merchantIdList) => {
  const allItems = user.is_admin ? [...defaultMenuItems, ...adminMenuItems] : [...defaultMenuItems];
  return checkItems(user, allItems);
};

export const isAllowed = (user, permission) => {
  const permissions = Object.values(user.pm).flat();
  let allowed = false;
  if (Array.isArray(permission)) {
    allowed =
      permission.filter((value) => {
        return permissions.includes(value);
      }).length > 0;
  } else {
    allowed = permissions.includes(permission);
  }
  return allowed;
};

export const checkItems = (user, menuItems) => {
  const allItems = JSON.parse(JSON.stringify(menuItems));
  const items: any[] = [];

  for (const item of allItems) {
    if (
      (item.onlyAdmin && !user.is_admin) ||
      !isAllowed(user, item.permission) ||
      (item.expectAdmin && user.is_admin)
    ) {
      continue;
    } else {
      if (item.subs && item.subs.length) {
        item.subs = item.subs.filter((i) => {
          return (
            ((i.onlyAdmin && user.is_admin) || !i.onlyAdmin || !(item.expectAdmin && user.is_admin)) &&
            isAllowed(user, i.permission)
          );
        });
      }
      items.push(item);
    }
  }
  return items;
};

export const isUserAuth = (loginStatus, loading, error, data) => {
  if (loading && !data) {
    return true;
  }
  return (!loginStatus || loginStatus === CookieAuthStatus.Authorized) && !loading && !error && data !== null;
};

export const getPermissionIdMenuItems = (pathname): number | number[] => {
  let permissionId: number | number[] = 0;

  for (const item of defaultMenuItems) {
    if (item.to === pathname) {
      permissionId = item.permission;
      break;
    } else {
      if (item.subs && item.subs.length) {
        for (const i of item.subs) {
          if (i.to === pathname) {
            permissionId = i.permission;
            break;
          }
        }
      }
      if (typeof item.includes !== 'undefined') {
        if (item.includes && item.includes.length) {
          for (const i of item.includes) {
            if (i.to === pathname) {
              permissionId = i.permission;
              break;
            }
          }
        }
      }
    }
  }

  return Array.isArray(permissionId) ? permissionId[0] : permissionId;
};
