import React, { PureComponent, ReactNode } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { ModalFileConfirmProps } from './interface';

class ModalFileConfirm extends PureComponent<ModalFileConfirmProps> {
  render(): ReactNode {
    const { onCloseModal, onConfirmModal, t } = this.props;

    return (
      <Modal isOpen={true} toggle={onCloseModal}>
        <ModalHeader toggle={onCloseModal}>{t('modal.file_confirm.title')}</ModalHeader>
        <ModalBody>{t('modal.file_confirm.description')}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onConfirmModal}>
            {t('yes')}
          </Button>
          <Button color="secondary" onClick={onCloseModal}>
            {t('no')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default withTranslation()(ModalFileConfirm);
