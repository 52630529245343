import React, { ReactNode } from 'react';
import { connect } from 'react-redux';

import { refundOperation, sendCallback } from '../../../redux/operations/actions';
import { NotificationManager } from '../../../components/common/react-notifications';
import { RefundModal } from './RefundModal';

import { TableSubComponentProps, TableSubComponentState } from './interface';
import { NOTIFICATION_TIMEOUT } from '../../../constants/defaultValues';
import { IncomePaymentMethodNames, ServiceTypes, REFUND_ALLOW_SOURCES } from './constants';
import { SiteNames } from '../constants';
import { Button } from 'reactstrap';
import { ResultModalTypes, PayoutsModalWindows } from '../interface';
import pull from 'lodash/pull';
import { ConfirmationModal } from '../Payouts/ConfirmationModal';
import { ResultModal } from '../Payouts/ResultModal';
import { CancelConfirmationModal } from '../Payouts/CancelConfirmationModal';
import { PAYOUT_CONFIRMATION_ATTEMPTS, PAYOUT_CONFIRMATION_EXPIRE_TIME } from '../Payouts/constants';

import { withTranslation } from 'react-i18next';

export class TableSubComponent extends React.Component<TableSubComponentProps, TableSubComponentState> {
  state: TableSubComponentState = {
    modalsOpen: [],
    confirmationWindowInit: false,
    paymentType: null,
    resultModal: ResultModalTypes.Error,
    isRefundModalOpen: false,
    isSmsModalOpen: false
  };
  values;
  componentDidMount(): void {
    const { item } = this.props;

    const paymentType = Object.values(SiteNames).find((value: string) => item.payment_type === value)
      ? IncomePaymentMethodNames.Acquiring
      : IncomePaymentMethodNames.MobilePay;

    item && this.setState({ paymentType });
  }

  componentDidUpdate(prevProps: Readonly<TableSubComponentProps>, prevState: Readonly<TableSubComponentState>): void {
    const { refundError, callbackError, loading, confirmation, error } = this.props;
    const { isSmsModalOpen } = this.state;

    if (prevProps.loading && !loading && !error && !confirmation) {
      this.setState({ confirmationWindowInit: true });
      this.openModal(PayoutsModalWindows.Confirmation);
    }

    if (isSmsModalOpen && prevState.isSmsModalOpen !== isSmsModalOpen) {
      this.setState({ isRefundModalOpen: false });
    }

    if (refundError && refundError !== prevProps.refundError) {
      NotificationManager.info(refundError, 'Ошибка', NOTIFICATION_TIMEOUT, null, null, '');
    }
    if (callbackError && callbackError !== prevProps.callbackError) {
      NotificationManager.info(callbackError, 'Ошибка', NOTIFICATION_TIMEOUT, null, null, '');
    }
  }

  commonTableRow = (title: string, name: string): ReactNode => {
    const { item } = this.props;

    return (
      <>
        <tr key={name}>
          <td key="title">
            <b>{title}</b>
          </td>
          <td key="value">{item[name]}</td>
        </tr>
      </>
    );
  };

  destinationTableRow = (): ReactNode => {
    const { item, t } = this.props;

    return (
      <>
        <tr key={'destination'}>
          <td key="title">
            <b>{t('operations.destination')}</b>
          </td>
          <td key="value">{t(item['destination'].toString())}</td>
        </tr>
      </>
    );
  };

  partnerAccountTableRow = (): ReactNode => {
    const { item, t } = this.props;
    const { shop_name, phone } = item;

    return (
      <>
        {shop_name && (
          <tr key="shop_name_phone">
            <td key="title">
              <b>{t('operations.account')}</b>
            </td>
            <td key="value">{`${shop_name} ${phone}`}</td>
          </tr>
        )}
      </>
    );
  };

  getIncomeSubComponent = (): ReactNode => {
    const { t } = this.props;
    const { paymentType } = this.state;

    if (paymentType === IncomePaymentMethodNames.MobilePay) {
      return (
        <>
          {this.commonTableRow(t('operations.payment_type'), 'payment_type')}
          {this.commonTableRow(t('operations.shop_name'), 'shop_name')}
        </>
      );
    } else {
      return (
        <>
          {this.commonTableRow(t('operations.payment_type'), 'card_number')}
          {this.commonTableRow(t('operations.cardholder'), 'cardholder')}
          {this.commonTableRow(t('operations.payment_type'), 'payment_type')}
          {this.commonTableRow(t('operations.shop_name'), 'shop_name')}
          {this.commonTableRow(t('operations.payer_ip'), 'payer_ip')}
          {this.commonTableRow(t('operations.payer_country'), 'payer_country')}
          {this.commonTableRow(t('operations.issuer_name'), 'issuer_name')}
          {this.commonTableRow(t('operations.bin_country'), 'bin_country')}
        </>
      );
    }
  };

  getMcSubComponent = (): ReactNode => {
    const { t } = this.props;

    return (
      <>
        {this.commonTableRow(t('operations.card_number'), 'card_number')}
        {this.partnerAccountTableRow()}
      </>
    );
  };

  getOutcomeSubComponent = (): ReactNode => {
    const { t } = this.props;
    return (
      <>
        {this.partnerAccountTableRow()}
        {this.destinationTableRow()}
        {this.commonTableRow(t('operations.client'), 'client')}
      </>
    );
  };

  getSubComponent = (): ReactNode => {
    const { serviceType } = this.props;

    switch (serviceType) {
      case ServiceTypes.Income:
        return this.getIncomeSubComponent();
      case ServiceTypes.Mc:
        return this.getMcSubComponent();
      case ServiceTypes.Outcome:
        return this.getOutcomeSubComponent();
      default:
        return this.getIncomeSubComponent();
    }
  };

  refundRequest = (values) => {
    const { refundOperationAction, item } = this.props;
    const { partner_account_id, shop_name, source, order_number, partner_id, currency } = item;

    refundOperationAction({
      id: String(order_number),
      source,
      account_id: partner_account_id,
      shop_name,
      partner_id,
      currency: String(currency),
      amount: values.amount ? parseFloat(values.amount) : 0
    });
  };

  confirmRefundAction = (code) => {
    const { refundOperationAction, item } = this.props;
    const { partner_account_id, shop_name, source, order_number, partner_id, currency } = item;

    refundOperationAction({
      id: String(order_number),
      source,
      account_id: partner_account_id,
      shop_name,
      partner_id,
      code: String(code),
      currency: String(currency),
      amount: item.amount
    });
  };

  sendCallBack = () => {
    const { sendCallbackAction, item } = this.props;
    const { partner_account_id, source, pid } = item;

    sendCallbackAction({
      id: pid,
      source,
      account_id: partner_account_id
    });
  };

  isOpenModal = (payoutsModalWindow: number): boolean => this.state.modalsOpen.includes(payoutsModalWindow);

  openModal = (payoutsModalWindow: number): void => {
    if (this.isOpenModal(payoutsModalWindow)) {
      return;
    }
    this.setState({ modalsOpen: [...this.state.modalsOpen, payoutsModalWindow] });
  };

  closeModal = (payoutsModalWindow: number): void => {
    this.setState({ modalsOpen: pull(this.state.modalsOpen, payoutsModalWindow) });
  };

  render(): ReactNode {
    const { serviceType, smsPhone, item, canReturn, canCallback, confirmation, t, attempt } = this.props;
    const { isRefundModalOpen, resultModal, confirmationWindowInit } = this.state;

    const tableRows = this.getSubComponent();

    return (
      <>
        <div className="d-flex justify-content-start mt-3 mb-3">
          {tableRows ? (
            <table className="sub-component-table">
              <tbody>{tableRows}</tbody>
            </table>
          ) : null}
        </div>

        <div className="d-flex justify-content-end m-3 mt-0">
          {serviceType === ServiceTypes.Income && REFUND_ALLOW_SOURCES.includes(item.source) && canReturn && (
            <Button color="primary" className="mr-3" onClick={() => this.setState({ isRefundModalOpen: true })}>
              {t('operations.Refund')}
            </Button>
          )}
          {canCallback && (
            <Button color="primary" onClick={this.sendCallBack}>
              {t('operations.Callback')}
            </Button>
          )}
        </div>

        {isRefundModalOpen && (
          <RefundModal
            amount={item.amount}
            onConfirmModal={this.refundRequest}
            onCloseModal={() => this.setState({ isRefundModalOpen: false })}
            t={t}
          />
        )}

        <ConfirmationModal
          init={confirmationWindowInit}
          onInit={() => this.setState({ confirmationWindowInit: false })}
          modalIsOpen={this.isOpenModal(PayoutsModalWindows.Confirmation)}
          onCloseModal={() => {
            this.closeModal(PayoutsModalWindows.Confirmation);
            this.openModal(PayoutsModalWindows.CancelConfirmation);
          }}
          onTimeIsOver={() => {
            this.closeModal(PayoutsModalWindows.Confirmation);
            this.setState({ resultModal: ResultModalTypes.CodeExpired });
            this.openModal(PayoutsModalWindows.Result);
          }}
          onAttemptsIsOver={() => {
            this.closeModal(PayoutsModalWindows.Confirmation);
            this.setState({ resultModal: ResultModalTypes.CodeInvalid });
            this.openModal(PayoutsModalWindows.Result);
          }}
          onSubmit={(code) => {
            this.confirmRefundAction(Number(code));
          }}
          attempts={confirmation ? PAYOUT_CONFIRMATION_ATTEMPTS - Number(attempt) : PAYOUT_CONFIRMATION_ATTEMPTS}
          counter={PAYOUT_CONFIRMATION_EXPIRE_TIME}
          userPhone={smsPhone}
          confirmationLabel="payouts.inputCodeToRefundConfirm"
        />

        <CancelConfirmationModal
          modalIsOpen={this.isOpenModal(PayoutsModalWindows.CancelConfirmation)}
          onPaymentCancel={() => {
            this.closeModal(PayoutsModalWindows.CancelConfirmation);
            this.setState({ resultModal: ResultModalTypes.Canceled });
            this.openModal(PayoutsModalWindows.Result);
          }}
          onPaymentResume={() => {
            this.closeModal(PayoutsModalWindows.CancelConfirmation);
            this.openModal(PayoutsModalWindows.Confirmation);
          }}
          confirmationLabel="payouts.cancelRefundext"
        />

        <ResultModal
          modalIsOpen={this.isOpenModal(PayoutsModalWindows.Result)}
          onCloseModal={() => {
            this.closeModal(PayoutsModalWindows.Result);
          }}
          payoutId={this.values && this.values.id ? this.values.id : 0}
          modalType={resultModal}
          confirmationLabel="payouts.refundID"
        />
      </>
    );
  }
}

const mapStateToProps = ({ operations }) => ({
  smsPhone:
    operations.refund && operations.refund.response && operations.refund.response.phone
      ? operations.refund.response.phone
      : '',
  refundError: operations.refund.error,
  callbackError: operations.sendCallback.error,
  attempt: operations.refund.response ? operations.refund.response.attempt : 0,
  confirmation: operations.createPayout.confirmation ? operations.createPayout.confirmation : null,
  loading: operations.refund.loading,
  error: operations.refund.error
});

const mapDispatchToProps = {
  refundOperationAction: refundOperation,
  sendCallbackAction: sendCallback
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TableSubComponent)
);
