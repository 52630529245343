export {};
declare global {
  interface Window {
    BASE_URL: string;
    BASE_WS_URL: string;
  }
}

const url = {
  rouge: 'http://localhost:3001',
  dev: 'http://localhost:9000/api/v1',
  test: '',
  production: window.BASE_URL
};

const wsUrl = {
  rouge: 'wss://localhost:3001',
  dev: 'wss://localhost:9000/api/v1',
  production: window.BASE_WS_URL
};

export const CONFIG = {
  apiURL(): string {
    return process.env.NODE_ENV === 'production'
      ? `${window.location.origin || url.production}/backend/api/v1`
      : process.env.REACT_APP_DEV_ENV
      ? url[process.env.REACT_APP_DEV_ENV]
      : url.dev;
  }
};

export const WS_CONFIG = {
  apiURL(): string {
    return process.env.NODE_ENV === 'production'
      ? `${window.location.host ? `wss://${window.location.host}` : wsUrl.production}/backend/api/v1`
      : process.env.REACT_APP_DEV_ENV
      ? wsUrl[process.env.REACT_APP_DEV_ENV]
      : wsUrl.dev;
  }
};
