import { AMOUNT_FRACTION_DIGITS } from '../../constants/app';

export const checkAmountFieldValue = (value: string): boolean => {
  const splittedValue = value.split('.');
  const allowedFractionDigitsLength =
    splittedValue.length === AMOUNT_FRACTION_DIGITS ? splittedValue[1].length <= AMOUNT_FRACTION_DIGITS : true;
  return Boolean(
    value.match(/^[0-9.]*$/) &&
      splittedValue.length <= AMOUNT_FRACTION_DIGITS &&
      value[0] !== '.' &&
      allowedFractionDigitsLength
  );
};

export const checkNumberFieldValue = (value: string): boolean => Boolean(value.match(/^[0-9]*$/));

export const checkNumberLetterFieldValue = (value: string): boolean => Boolean(value.match(/^[a-zA-Z0-9_]*$/));

export const floatFieldValue = (value: string, fieldName: string, setFieldValue) => {
  if (checkAmountFieldValue(value)) {
    setFieldValue(fieldName, value);
  }
};

export const intFieldValue = (value: string, fieldName: string, setFieldValue) => {
  if (checkNumberFieldValue(value)) {
    setFieldValue(fieldName, value);
  }
};
