import React from 'react';
import { Redirect, Route, Switch, RouteComponentProps } from 'react-router';

import OperationsList from './OperationsList/OperationsList';
import { ServiceTypes } from './OperationsList/constants';

import AcquiringHistory from './AcquiringHistory/AcquiringHistory';
import Payouts from './Payouts/Payouts';

const Operations = ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}/acquiring/history/:orderNumber`} component={AcquiringHistory} />
    <Route
      exact
      path={`${match.url}/acquiring`}
      component={(props: RouteComponentProps) => <OperationsList {...props} serviceType={ServiceTypes.Income} />}
    />
    <Route
      exact
      path={`${match.url}/mobile-commerce`}
      component={(props: RouteComponentProps) => <OperationsList {...props} serviceType={ServiceTypes.Mc} />}
    />
    <Route path={`${match.url}/payouts`} component={(props: RouteComponentProps) => <Payouts {...props} />} />
    <Redirect from={match.url} to={`${match.url}/acquiring`} />
    <Redirect to={'/error'} />
  </Switch>
);

export default Operations;
