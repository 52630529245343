import { GET_CSV_LIST, GET_CSV_LIST_SUCCESS, GET_CSV_LIST_ERROR, RESET_DOWNLOADS } from '../actions';

import { DownloadsState } from './interface';

const INIT_STATE: DownloadsState = {
  csvList: {
    data: null,
    loading: false,
    error: ''
  }
};

export default (state: DownloadsState = INIT_STATE, { type, payload }): DownloadsState => {
  switch (type) {
    case GET_CSV_LIST:
      return {
        ...state,
        csvList: {
          ...state.csvList,
          loading: true,
          error: ''
        }
      };
    case GET_CSV_LIST_SUCCESS:
      return {
        ...state,
        csvList: {
          ...state.csvList,
          data: payload.data,
          loading: false
        }
      };
    case GET_CSV_LIST_ERROR:
      return {
        ...state,
        csvList: {
          ...state.csvList,
          error: payload.error,
          loading: false
        }
      };
    case RESET_DOWNLOADS:
      return {
        ...state,
        csvList: {
          ...state.csvList,
          data: null,
          loading: false,
          error: ''
        }
      };
    default:
      return { ...state };
  }
};
