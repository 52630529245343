import React from 'react';
import { Line } from 'react-chartjs-2';
import * as zoom from 'chartjs-plugin-zoom';
import { addWeeks } from 'date-fns';
import format from '../../../localization';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

import { ThemeColors } from '../../../helpers/ThemeColors';
import { ConversionChart } from '../../../redux/statistics/interface';
import { ChartsPeriodValue } from '../interface';
import { toLocalTime } from '../../../helpers/Utils';
import { roundChartData, updateScrollPane, onPaneScrollX } from '../helpers';
import { CONVERSION_CHART_STATUSES } from '../constants';

import { ConversionChartDataSet, ConversionChartPaneProps, ConversionChartSelectOption } from './interface';
import { DATE_FORMAT_BASIC, MONTH_FORMAT, TIME_FORMAT } from '../../../constants/app';
import { withTranslation } from 'react-i18next';

const DEFAULT_ELEMENTS_COUNT = 30;

const ConversionChartPane = ({ data, period, statuses, chartRef, height, t }: ConversionChartPaneProps) => {
  if (!data) {
    return (
      <div className="mt-4">
        <div className="font-italic">Нет данных</div>
      </div>
    );
  }

  let lineChartRef;
  let scrollRef;
  let scrollPaneRef;

  // TODO TypeScript не можут принять тип (string[] | [string][]), выдает ошибку
  const labels: any = [];
  const datasets: ConversionChartDataSet[] = [];
  const colors = ThemeColors();

  data.forEach((item: ConversionChart) => {
    const { period_time: date } = item;

    switch (Number(period)) {
      case ChartsPeriodValue.Month:
        labels.push(format(date, MONTH_FORMAT));
        break;
      case ChartsPeriodValue.Week:
        labels.push([
          t('chart.week'),
          `${t('chart.from')} ${format(date, DATE_FORMAT_BASIC)}`,
          `${t('chart.to')} ${format(addWeeks(toLocalTime(date), 1), DATE_FORMAT_BASIC)}`
        ]);
        break;
      case ChartsPeriodValue.Day:
        labels.push(format(date, DATE_FORMAT_BASIC));
        break;
      default:
        labels.push([`${format(date, DATE_FORMAT_BASIC)}`, `${format(date, TIME_FORMAT)}`]);
    }
  });

  let roundedLabels: any = [];

  statuses.forEach((status: string, index: number) => {
    const currentStatus = CONVERSION_CHART_STATUSES(t).find(
      (statusOption: ConversionChartSelectOption) => status === statusOption.value
    );

    const currentColor = currentStatus ? currentStatus.color : '#ffc107';

    const values: any = [];

    data.forEach((dataItem: ConversionChart) => {
      const { status_total: totalByStatus } = dataItem;
      values.push(totalByStatus[index]);
    });

    const roundedData = roundChartData(DEFAULT_ELEMENTS_COUNT, values, labels);
    roundedLabels = roundedData.labels;

    const dataSet: any = {
      label: currentStatus ? currentStatus.label : '',
      data: roundedData.values,
      borderColor: currentColor,
      pointBackgroundColor: colors.foregroundColor,
      pointBorderColor: currentColor,
      pointHoverBackgroundColor: currentColor,
      pointHoverBorderColor: colors.foregroundColor,
      pointRadius: 0,
      pointBorderWidth: 1,
      pointHoverRadius: 6,
      fill: false
    };

    datasets.push(dataSet);
  });

  const options = {
    legend: {
      onClick: null,
      display: false
    },
    tooltips: {
      mode: 'index',
      intersect: false,
      callbacks: {
        title(item: any, prevData: any): string {
          // any потому что не установлена типизация для react-chartjs-2
          return `${prevData.labels[item[0].index]}:`;
        }
      }
    },
    hover: {
      mode: 'index',
      intersect: false
    },
    scales: {
      xAxes: [
        {
          ticks: {
            callback: (label: string): string | string[] => label,
            maxRotation: 0,
            padding: 10,
            autoSkip: true,
            autoSkipPadding: 20
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            callback: (label: string): number | null =>
              Number.isInteger(parseFloat(label)) ? parseInt(label, 10) : null,
            beginAtZero: true,
            suggestedMin: 0
          }
        }
      ]
    },
    plugins: {
      zoom
    },
    pan: {
      enabled: true,
      drag: false,
      mode: 'x',
      rangeMin: {
        x: 0,
        y: 0
      },
      onPan: (): void => updateScrollPane(lineChartRef, scrollRef, scrollPaneRef)
    },
    zoom: {
      enabled: true,
      mode: 'x',
      rangeMin: {
        x: 0,
        y: 0
      },
      onZoom: (): void => updateScrollPane(lineChartRef, scrollRef, scrollPaneRef)
    }
  };

  if (height) {
    options['maintainAspectRatio'] = false;
  }

  return (
    <div ref={chartRef} style={height ? { height } : undefined}>
      <Line
        data={{
          labels: roundedLabels,
          datasets
        }}
        options={options}
        ref={(ref: any) => (lineChartRef = ref)}
      />

      <PerfectScrollbar
        ref={(ref: any) => (scrollRef = ref)}
        onScrollX={(scrollBar: any) => onPaneScrollX(scrollBar, lineChartRef)}
        className="charts-scrollbar"
      >
        <div ref={(ref: any) => (scrollPaneRef = ref)} className="scroll-pane" />
      </PerfectScrollbar>
    </div>
  );
};

export default withTranslation()(ConversionChartPane);
