import { CellInfo } from 'react-table';

export enum SERVICE_TYPES_CODES {
  INCOME = 1,
  MC,
  OUTCOME
}

export enum SERVICE_TYPES_NAMES {
  INCOME = 'Прием',
  MC = 'Мобильная коммерция',
  OUTCOME = 'Выплаты'
}

export enum SERVICE_TYPES_SLUGS {
  INCOME = 'acquiring',
  MC = 'mobile-commerce',
  OUTCOME = 'payouts'
}

export const SERVICE_TYPES_NAMES_BY_SLUGS = {
  [SERVICE_TYPES_SLUGS.INCOME]: SERVICE_TYPES_NAMES.INCOME,
  [SERVICE_TYPES_SLUGS.MC]: SERVICE_TYPES_NAMES.MC,
  [SERVICE_TYPES_SLUGS.OUTCOME]: SERVICE_TYPES_NAMES.OUTCOME
};

export const SERVICE_TYPES = {
  [SERVICE_TYPES_CODES.INCOME]: SERVICE_TYPES_NAMES.INCOME,
  [SERVICE_TYPES_CODES.MC]: SERVICE_TYPES_NAMES.MC,
  [SERVICE_TYPES_CODES.OUTCOME]: SERVICE_TYPES_NAMES.OUTCOME
};

export const SERVICE_TYPES_BY_NAME = {
  [SERVICE_TYPES_NAMES.INCOME]: SERVICE_TYPES_CODES.INCOME,
  [SERVICE_TYPES_NAMES.MC]: SERVICE_TYPES_CODES.MC,
  [SERVICE_TYPES_NAMES.OUTCOME]: SERVICE_TYPES_CODES.OUTCOME
};

export const getTableColumns = (t: any) => [
  {
    Header: t('services.merchnat'),
    accessor: 'name'
  },
  {
    Header: t('services.status'),
    accessor: 'is_active',
    Cell: ({ value }: CellInfo) => (value ? t('services.active') : t('services.inactive'))
  }
];
