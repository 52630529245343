import React from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import IntlMessages from '../../../helpers/IntlMessages';
import { Colxx } from '../../../components/common/CustomBootstrap';

export const CancelConfirmationModal = ({
  modalIsOpen,
  onPaymentCancel,
  onPaymentResume,
  confirmationLabel = 'payouts.cancelConfirmationText'
}) => (
  <Modal isOpen={modalIsOpen} toggle={onPaymentResume}>
    <ModalHeader toggle={onPaymentResume}>
      <IntlMessages id="payouts.cancel" />
    </ModalHeader>
    <ModalBody>
      <IntlMessages id={confirmationLabel} />
      <Colxx xxs="12" className="d-flex flex-row justify-content-end align-items-center px-0 pt-4">
        <Button
          disabled={false}
          color="primary"
          type="button"
          className="mr-2"
          onClick={() => {
            onPaymentCancel();
          }}
        >
          <IntlMessages id="button.yes" />
        </Button>
        <Button
          disabled={false}
          color="primary"
          type="submit"
          onClick={() => {
            onPaymentResume();
          }}
        >
          <IntlMessages id="button.no" />
        </Button>
      </Colxx>
    </ModalBody>
  </Modal>
);
