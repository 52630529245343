import React, { ReactNode } from 'react';

import { Redirect, Route, Switch, RouteComponentProps } from 'react-router';
import { NavLink } from 'react-router-dom';
import { Row, Card, CardBody, NavItem, Nav } from 'reactstrap';

import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import Breadcrumb from '../../../containers/navs/Breadcrumb';

import OperationsList from '../OperationsList/OperationsList';
import { ServiceTypes } from '../OperationsList/constants';

import { getPayoutsMenuItems } from './constants';
import { TIMEZONE_TOOLTIP_TEXT } from '../../../constants/app';

import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { IPayoutsProps, IPayoutsState } from '../interface';

import { checkItems } from '../../../helpers/Access';
import SectionInDevelopment from '../../app/SectionInDevelopment';

import CreatePayouts from './CreatePayouts/CreatePayouts';

class Payouts extends React.Component<IPayoutsProps, IPayoutsState> {
  state: IPayoutsState = {
    menuItems: []
  };

  componentDidUpdate(prevProps: Readonly<IPayoutsProps>): void {
    const { user, t } = this.props;
    if (prevProps.user !== user || (prevProps.user && user && prevProps.user.id !== user.id)) {
      this.setState({ menuItems: checkItems(user, getPayoutsMenuItems(t)) });
    }
  }

  componentDidMount(): void {
    const { user, t } = this.props;
    if (user) {
      this.setState({ menuItems: checkItems(user, getPayoutsMenuItems(t)) });
    }
  }

  render(): ReactNode {
    const { match, location, t } = this.props;
    const { menuItems } = this.state;

    return (
      <>
        <Row className="flex-shrink-0">
          <Colxx xxs="12">
            <Breadcrumb heading="menu.operations" match={match} />
            <div className="time-tip  d-none d-lg-inline-block">{t(TIMEZONE_TOOLTIP_TEXT)}</div>
            <Separator className="mb-5" />
          </Colxx>
        </Row>

        <Row>
          <Colxx xxs="12">
            <Card className="mb-5">
              <CardBody>
                <Nav className="nav-pills nav-fill">
                  {menuItems.map((menuItem: { label: string; path: string }) => (
                    <Colxx key={menuItem.path} xxs="12" lg="3">
                      <NavItem key={menuItem.path}>
                        <NavLink
                          className="payouts nav-link px-0 text-lg-center"
                          isActive={() => location.pathname.split('/').includes(menuItem.path)}
                          to={`${match.url}/${menuItem.path}`}
                        >
                          {menuItem.label}
                        </NavLink>
                      </NavItem>
                    </Colxx>
                  ))}
                </Nav>
              </CardBody>
            </Card>
          </Colxx>
        </Row>

        <Switch>
          <Redirect exact from={match.url} to={`${match.url}/history`} />
          <Route exact path={`${match.url}/create`} component={CreatePayouts} />
          <Route exact path={`${match.url}/create-mass`} component={SectionInDevelopment} />
          <Route
            exact
            path={`${match.url}/history`}
            component={(props: RouteComponentProps) => <OperationsList {...props} serviceType={ServiceTypes.Outcome} />}
          />
          <Route exact path={`${match.url}/registries-history`} component={SectionInDevelopment} />
          <Redirect to={'/error'} />
        </Switch>
      </>
    );
  }
}

const mapStateToProps = ({ authUser }) => ({
  user: authUser.user.data ? authUser.user.data : null
});

const mapDispatchToProps = {};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Payouts as any)
);
