import React, { Fragment, Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Row, Card, CardBody, Label, FormGroup, Button } from 'reactstrap';
import ReactTable from 'react-table';
import { Form, Formik } from 'formik';
import Select from 'react-select';
import InputMask from 'react-input-mask';
import { isAfter, isSameDay, startOfDay, format } from 'date-fns';
import isEqual from 'lodash/isEqual';

import { withTranslation } from 'react-i18next';
import { NotificationManager } from '../../components/common/react-notifications';
import { NOTIFICATION_TIMEOUT } from '../../constants/defaultValues';
import DataTablePagination from '../../components/DatatablePagination';
import { Colxx, Separator } from '../../components/common/CustomBootstrap';
import { NotFoundMessage } from '../../components/common/NotFoundMessage';
import { CustomDropdownTreeSelect } from '../../components/common/CustomDropdownTreeSelect';
import { DatePickerLocaled } from '../../components/common/DatePickerLocaled/DatePickerLocaled';
import { Loader } from '../../components/common/Loader';
import Breadcrumb from '../../containers/navs/Breadcrumb';
import { ACCOUNT_ID, DATE_FORMAT_BASIC, DATE_MASK, EMPTY_FIELD_ERROR, EMPTY_ACCOUNTS } from '../../constants/app';
import { SelectorOption } from '../../interfaces/app';
import { getDocuments, resetDocuments, getPayoutAccounts } from '../../redux/actions';

import { getDocumentsTableColumns, getDocumentTreeData } from './helpers';
import { DOCUMENTS_SERVICES_OPTIONS, DOCUMENTS_INITIAL_VALUES, DEFAULT_SORTER } from './constants';
import { DocumentsProps, DocumentsState, DocumentsFormValues } from './interface';
import { Merchant } from '../../redux/menu/interface';

class Documents extends Component<DocumentsProps, DocumentsState> {
  state: DocumentsState = {
    serviceType: 1,
    page: 0,
    partner: null,
    accountIdOptions: [],
    shouldReset: false,
    dataLoading: true
  };
  setFieldValue;
  resetForm;

  componentDidMount(): void {
    const {
      merchantIdList,
      isAdmin,
      authUserId,
      selectedUserId,
      getPayoutAccountsAction,
      selectedMerchants
    } = this.props;

    if (merchantIdList && merchantIdList.length) {
      const userId =
        isAdmin && authUserId && selectedUserId && selectedUserId !== authUserId ? selectedUserId : undefined;

      if (selectedMerchants && selectedMerchants.length >= 1) {
        this.setPartner(selectedMerchants[0]);
        getPayoutAccountsAction([selectedMerchants[0].id], userId);
      } else {
        getPayoutAccountsAction(merchantIdList, userId);
      }
    }
  }

  componentDidUpdate(prevProps: Readonly<DocumentsProps>, prevState: Readonly<DocumentsState>): void {
    const {
      loading,
      accounts,
      error,
      merchantIdList,
      isAdmin,
      authUserId,
      selectedUserId,
      getPayoutAccountsAction
    } = this.props;
    const { serviceType, accountIdOptions } = this.state;

    if (loading !== prevProps.loading || accounts.loading !== prevProps.accounts.loading) {
      if (loading || accounts.loading) {
        this.setState({ dataLoading: true });
      } else if (!loading && !accounts.loading) {
        this.setState({ dataLoading: false });
      }
    }

    if (error && error !== prevProps.error) {
      NotificationManager.info(error, 'Ошибка', NOTIFICATION_TIMEOUT, null, null, '');
    }

    if (prevProps.accounts.data !== accounts.data || prevState.serviceType !== serviceType) {
      const accountsByType = this.getAccountOptions(serviceType);
      this.setState({ accountIdOptions: accountsByType });
    }

    if (prevState.accountIdOptions !== accountIdOptions) {
      this.setFieldValue('accountId', accountIdOptions.length ? accountIdOptions : null);
    }

    if (
      merchantIdList &&
      merchantIdList.length &&
      (!isEqual(prevProps.merchantIdList, merchantIdList) || (isAdmin && prevProps.selectedUserId !== selectedUserId))
    ) {
      const userId =
        isAdmin && authUserId && selectedUserId && selectedUserId !== authUserId ? selectedUserId : undefined;
      merchantIdList.length === 1 && getPayoutAccountsAction(merchantIdList, userId);

      this.handleResetForm();
      this.props.resetDocumentsAction();
    }

    this.state.shouldReset && this.setState({ shouldReset: false });
  }

  componentWillUnmount(): void {
    const { resetDocumentsAction, getPayoutAccountsAction, merchantIdList } = this.props;

    resetDocumentsAction();
    getPayoutAccountsAction(merchantIdList || []);
  }

  setPartner = (firstSelectedMerchant: Merchant) => {
    const { getPayoutAccountsAction, isAdmin, authUserId, selectedUserId } = this.props;
    if (firstSelectedMerchant) {
      const partner = {
        label: firstSelectedMerchant.name,
        value: firstSelectedMerchant.id,
        key: `${firstSelectedMerchant.id}_${firstSelectedMerchant.name}`
      };

      this.setFieldValue('partner', partner);
      this.setState({ partner });

      getPayoutAccountsAction(
        [partner.value],
        isAdmin && authUserId && selectedUserId && selectedUserId !== authUserId ? selectedUserId : undefined
      );
    } else {
      return;
    }
  };

  handleResetForm = () => {
    const { selectedMerchants } = this.props;

    this.resetForm();
    this.setState({ shouldReset: true });
    this.setState({ serviceType: DOCUMENTS_INITIAL_VALUES.serviceType.value });
    this.setPartner(selectedMerchants[0]);
  };

  getDocuments = ({ dateFrom, dateTo, serviceType, accountId }) => {
    const { partner } = this.state;
    this.setState({ serviceType: serviceType.value }, () => {
      this.props.getDocumentsAction({
        from: format(startOfDay(dateFrom), 'yyyy-MM-dd'),
        to: format(dateTo, 'yyyy-MM-dd'),
        service_type: serviceType.value,
        partner_id: partner.value,
        [ACCOUNT_ID]: accountId.map((accountIdItem: SelectorOption) => accountIdItem.value).flat()
      });
    });
  };

  validate = (values) => {
    const errors: { [key: string]: string } = {};
    const { dateFrom, dateTo, accountId } = values;
    const { accountIdOptions } = this.state;
    const { accounts, t } = this.props;

    if (!dateFrom) {
      errors.dateFrom = t(EMPTY_FIELD_ERROR);
    }

    if (!dateTo) {
      errors.dateTo = t(EMPTY_FIELD_ERROR);
    }

    if (dateFrom && dateTo && isAfter(dateFrom, dateTo) && !isSameDay(dateFrom, dateTo)) {
      errors.dateFrom = t('Начальная дата должна быть меньше или равна конечной');
    }

    if ((!accountId || !accountId.length) && !accounts.loading) {
      if (accountIdOptions.length) {
        errors.accountId = t(EMPTY_FIELD_ERROR);
      } else {
        errors.accountId = t(EMPTY_ACCOUNTS);
      }
    }

    return errors;
  };

  getAccountOptions = (serviceType: number): SelectorOption[] => {
    const { accounts } = this.props;

    if (accounts.data && accounts.data[serviceType]) {
      return accounts.data[serviceType].reduce((options, account): SelectorOption[] => {
        const accountOptionByContractIdIndex = options.findIndex(
          ({ label }: SelectorOption) => label === account.contract_name
        );

        if (accountOptionByContractIdIndex >= 0) {
          const idExist = Boolean(
            options[accountOptionByContractIdIndex].value.find(
              (accountOptionByContractIdValue: number) => accountOptionByContractIdValue === account.id
            )
          );

          if (idExist) {
            return options;
          } else {
            options[accountOptionByContractIdIndex].value.push(account.id);
            return options;
          }
        }

        return [...options, { label: account.contract_name, value: [account.id] }];
      }, []);
    }

    return [];
  };

  setNewPage = (pageNumber: number) => {
    this.setState({ page: pageNumber });
  };

  getPartnerOption = (partner: Merchant[]) =>
    partner && partner.length ? partner.map(({ name, id }) => ({ label: name, value: id, key: `${id}_${name}` })) : [];

  render(): ReactNode {
    const {
      rows,
      loading,
      match,
      merchantIdList,
      selectedMerchants,
      getPayoutAccountsAction,
      isAdmin,
      selectedUserId,
      maxPageSize,
      authUserId,
      t
    } = this.props;
    const { page, accountIdOptions, shouldReset, dataLoading } = this.state;
    const currentRows = rows ? rows : [];
    const pageSize = currentRows.length < maxPageSize ? currentRows.length : maxPageSize;

    return (
      <Fragment>
        {dataLoading && <Loader />}

        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.documents" match={match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>

        <Formik
          initialValues={DOCUMENTS_INITIAL_VALUES}
          validate={this.validate}
          onSubmit={(values: DocumentsFormValues) => this.getDocuments(values)}
        >
          {({ values, setFieldValue, setFieldTouched, errors, touched, resetForm }) => {
            this.setFieldValue = setFieldValue;
            this.resetForm = resetForm;

            return (
              <Fragment>
                {merchantIdList && merchantIdList.length > 0 && (
                  <Form>
                    <Row>
                      <Colxx xxs="12" className="mb-4">
                        <Card>
                          <CardBody>
                            <Row>
                              <Colxx xxs="12" sm="6" lg="3">
                                <FormGroup className="form-group has-float-label">
                                  <Label className="float-label">{t('menu.documents.date from')}</Label>
                                  <DatePickerLocaled
                                    name="dateFrom"
                                    selected={values.dateFrom}
                                    dateFormat={DATE_FORMAT_BASIC}
                                    customInput={<InputMask mask={DATE_MASK} />}
                                    maxDate={values.dateTo ? values.dateTo : new Date()}
                                    onChange={(d: Date) => {
                                      setFieldValue('dateFrom', d);
                                      setFieldTouched('dateFrom');
                                    }}
                                  />
                                </FormGroup>
                                {errors.dateFrom && <div className="invalid-feedback d-block">{errors.dateFrom}</div>}
                              </Colxx>
                              <Colxx xxs="12" sm="6" lg="3">
                                <FormGroup className="form-group has-float-label">
                                  <Label className="float-label">{t('menu.documents.date by')}</Label>
                                  <DatePickerLocaled
                                    name="dateTo"
                                    selected={values.dateTo}
                                    dateFormat={DATE_FORMAT_BASIC}
                                    customInput={<InputMask mask={DATE_MASK} />}
                                    minDate={values.dateFrom ? values.dateFrom : undefined}
                                    onChange={(d: Date) => {
                                      setFieldValue('dateTo', d);
                                      setFieldTouched('dateTo');
                                    }}
                                  />
                                  {errors.dateTo && touched.dateTo && (
                                    <div className="invalid-feedback d-block">{errors.dateTo}</div>
                                  )}
                                </FormGroup>
                              </Colxx>
                              <Colxx xxs="12" sm="6" lg="3">
                                <FormGroup className="has-float-label">
                                  <Label className="float-label">{t('menu.documents.service')}</Label>
                                  <Select
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    name="serviceType"
                                    value={values.serviceType}
                                    onChange={(serviceType: SelectorOption) => {
                                      setFieldValue('serviceType', serviceType);
                                      this.setState({ serviceType: serviceType.value });
                                    }}
                                    options={DOCUMENTS_SERVICES_OPTIONS}
                                    placeholder=""
                                  />
                                </FormGroup>
                              </Colxx>
                              {merchantIdList && merchantIdList.length > 1 && (
                                <Colxx xxs="12" sm="6" lg="3">
                                  <FormGroup className="has-float-label">
                                    <Label className="float-label">{t('menu.documents.partner')}</Label>
                                    <Select
                                      className="react-select"
                                      classNamePrefix="react-select"
                                      name="partner"
                                      value={values.partner}
                                      onChange={(partner: SelectorOption) => {
                                        setFieldValue('partner', partner);
                                        getPayoutAccountsAction(
                                          [partner.value],
                                          isAdmin && authUserId && selectedUserId && selectedUserId !== authUserId
                                            ? selectedUserId
                                            : undefined
                                        );
                                      }}
                                      options={this.getPartnerOption(selectedMerchants)}
                                      placeholder=""
                                    />
                                  </FormGroup>
                                </Colxx>
                              )}
                              <Colxx xxs="12" sm="6" lg="3">
                                <FormGroup className="form-group has-float-label">
                                  <Label className="float-label">{t('menu.documents.number')}</Label>
                                  <CustomDropdownTreeSelect
                                    name="accountId"
                                    shouldReset={shouldReset}
                                    data={getDocumentTreeData(accountIdOptions, t)}
                                    onBlur={() => setFieldTouched('accountId')}
                                    onChange={(changedValue, selectedValues) =>
                                      setFieldValue(
                                        'accountId',
                                        selectedValues.length === 1 && selectedValues[0].key === 0
                                          ? accountIdOptions
                                          : selectedValues
                                      )
                                    }
                                  />
                                  {errors.accountId && (
                                    <div className="invalid-feedback d-block">{errors.accountId}</div>
                                  )}
                                </FormGroup>
                              </Colxx>
                              <Colxx xxs="12" className="d-flex justify-content-end">
                                <Button
                                  disabled={Boolean(Object.keys(errors).length) || dataLoading}
                                  color="primary"
                                  type="submit"
                                >
                                  {t('menu.documents.button.search')}
                                </Button>
                              </Colxx>
                            </Row>
                          </CardBody>
                        </Card>
                      </Colxx>
                    </Row>
                  </Form>
                )}

                {currentRows && currentRows.length > 0 && (
                  <Row className="h-100">
                    <Colxx xxs="12" className="mb-4">
                      <Card className="full-width">
                        <CardBody>
                          <ReactTable
                            data={currentRows}
                            columns={getDocumentsTableColumns(values.serviceType.value, t)}
                            page={page}
                            pages={Math.ceil(rows.length / maxPageSize)}
                            defaultPageSize={pageSize}
                            pageSize={pageSize}
                            loading={loading}
                            sortable={true}
                            minRows={0}
                            filterable={false}
                            showPageJump={true}
                            defaultSorted={DEFAULT_SORTER}
                            showPagination={true}
                            PaginationComponent={DataTablePagination}
                            onPageChange={this.setNewPage}
                            showPageSizeOptions={false}
                            loadingText=""
                            noDataText="Нет данных"
                          />
                        </CardBody>
                      </Card>
                    </Colxx>
                  </Row>
                )}

                {!(merchantIdList && merchantIdList.length) && <NotFoundMessage text={t('no_partners_selected')} />}
                {Boolean(rows && !rows.length) && <NotFoundMessage />}
              </Fragment>
            );
          }}
        </Formik>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ documents, settings, authUser, operations }) => ({
  rows: documents.rows,
  loading: documents.loading,
  error: documents.error,
  selectedMerchants: settings.selectedMerchants,
  maxPageSize: settings.gcmSettings.data ? settings.gcmSettings.data.max_page_size : 50,
  merchantIdList: settings.selectedMerchantsId,
  selectedUserId: settings.selectedUser ? settings.selectedUser.id : null,
  authUserId: authUser.user.data ? authUser.user.data.id : null,
  isAdmin: authUser.user.data ? authUser.user.data.is_admin : false,
  accounts: operations.payoutAccounts
});

const mapDispatchToProps = {
  getDocumentsAction: getDocuments,
  resetDocumentsAction: resetDocuments,
  getPayoutAccountsAction: getPayoutAccounts
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Documents)
);
