import { BalanceOperationState } from './interface';
import {
  GET_BALANCE_OPERATION,
  GET_BALANCE_OPERATION_SUCCESS,
  GET_BALANCE_OPERATION_ERROR,
  GET_BALANCE_STAT,
  GET_BALANCE_STAT_SUCCESS,
  GET_BALANCE_STAT_ERROR
} from '../actions';

const INIT_STATE: BalanceOperationState = {
  operations: null,
  commonStat: null,
  totalCount: 0,
  loading: false,
  makeRequest: false,
  error: '',
  operationRequestValues: null
};

export default (state: BalanceOperationState = INIT_STATE, { type, payload }): BalanceOperationState => {
  switch (type) {
    // GET BALANCE OPERATIONS
    case GET_BALANCE_OPERATION:
      return {
        ...state,
        loading: true,
        makeRequest: false,
        totalCount: 0,
        operations: null
      };
    case GET_BALANCE_OPERATION_SUCCESS:
      return {
        ...state,
        makeRequest: true,
        operations: payload.operations,
        totalCount: payload.totalCount,
        loading: false
      };
    case GET_BALANCE_OPERATION_ERROR:
      return {
        ...state,
        operations: null,
        totalCount: 0,
        makeRequest: false,
        loading: false,
        error: payload.error
      };

    // GET BALANCE STAT
    case GET_BALANCE_STAT:
      return {
        ...state,
        loading: true,
        makeRequest: false,
        commonStat: null
      };
    case GET_BALANCE_STAT_SUCCESS:
      return {
        ...state,
        makeRequest: true,
        commonStat: payload.commonStat,
        loading: false
      };
    case GET_BALANCE_STAT_ERROR:
      return {
        ...state,
        commonStat: null,
        makeRequest: false,
        loading: false,
        error: payload.error
      };
    default:
      return { ...state };
  }
};
