export const DEFAULT_MAX_FILE_SIZE = 1;
export const BACKGROUND_MAX_FILE_SIZE = 3;

export const IMAGE_URL = '/custom-design/image/';
export const IMAGE_CONTENT_TYPES = 'image/jpeg,image/png,image/svg+xml';
export const FAVICON_CONTENT_TYPES = 'image/x-icon,image/vnd.microsoft.icon,image/jpeg,image/png,image/svg+xml';
export const START_PAGE_CONTENT_TYPE = 'text/html';

export const EMPTY_DESIGN_CONFIG = {
  images: {},
  imagesBase64: {},
  imageFiles: {},
  settings: {},
  startPage: null,
  domain: window.location.hostname,
  partnerID: 0
};

export const PREVIEW_PAGE_VIEWS = {
  cabinet: 'CABINET',
  loginPage: 'LOGIN_PAGE'
};

export const CUSTOM_CSS_FIELD_SIZE = 5;

export const LOGIN_PAGE_PREVIEW_HINTS = {
  loginCardColor: 1,
  loginBackgroundColor: 2,
  loginLogo: 3,
  browserTab: 0,
  supportEmail: 0
};

export const GENERAL_PREVIEW_HINTS = {
  loginCardColor: 0,
  loginBackgroundColor: 0,
  loginLogo: 0,
  browserTab: 1,
  supportEmail: 2
};
