import React, { Component, Fragment, ReactNode } from 'react';
import { Row, Card, CardBody, Button, ButtonGroup } from 'reactstrap';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import isEqual from 'lodash/isEqual';

import { NotificationManager } from '../../components/common/react-notifications';
import { Colxx, Separator } from '../../components/common/CustomBootstrap';
import { NotFoundMessage } from '../../components/common/NotFoundMessage';
import DataTablePagination from '../../components/DatatablePagination';
import Breadcrumb from '../../containers/navs/Breadcrumb';
import { Loader } from '../../components/common/Loader';
import CreateRequestModal from './CreateRequestModal';
import { getAllRequests, openRequest, resetRequests, sendSupportMessage } from '../../redux/actions';

import { NOTIFICATION_TIMEOUT } from '../../constants/defaultValues';
import { SupportProps, SupportState } from './interface';
import { getRequestColumns } from './helpers';
import { SUPPORT_PAGE_SIZE, SupportStateType } from './constants';
import { withTranslation } from 'react-i18next';
class RequestListPage extends Component<SupportProps, SupportState> {
  state: SupportState = {
    sent: false,
    page: 0,
    type: SupportStateType.Active,
    modalIsOpen: false,
    requests: []
  };

  componentDidMount(): void {
    const { merchantIdList, getAllRequestsAction } = this.props;
    merchantIdList && merchantIdList.length && getAllRequestsAction(merchantIdList);
  }

  componentDidUpdate(prevProps: Readonly<SupportProps>, prevState: Readonly<SupportState>): void {
    const {
      loading,
      error,
      merchantIdList,
      selectedUserId,
      activeRequests,
      archiveRequests,
      openRequestState,
      resetRequestsAction,
      getAllRequestsAction
    } = this.props;
    const { type } = this.state;

    if (
      (merchantIdList && !isEqual(prevProps.merchantIdList, merchantIdList)) ||
      prevProps.selectedUserId !== selectedUserId
    ) {
      resetRequestsAction();
      merchantIdList && merchantIdList.length && getAllRequestsAction(merchantIdList);
    }

    if (
      (activeRequests && !isEqual(prevProps.activeRequests, activeRequests)) ||
      (archiveRequests && !isEqual(prevProps.archiveRequests, archiveRequests))
    ) {
      this.setRequestsState(type);
    }

    if (prevState.type !== type) {
      this.setRequestsState(type);
    }

    if (error && error !== prevProps.error) {
      NotificationManager.info(error, 'Ошибка', NOTIFICATION_TIMEOUT, null, null, '');
    }

    if (!openRequestState.loading && !openRequestState.error && prevProps.openRequestState.loading) {
      const message = 'Обращение успешно создано';
      NotificationManager.info(message, 'Успешно', NOTIFICATION_TIMEOUT, null, null, '');
    }

    if (!loading && prevProps.loading && !error) {
      this.setState({ sent: true });
    }
  }

  componentWillUnmount(): void {
    this.props.resetRequestsAction();
  }

  setRequestsState = (type: SupportStateType) => {
    const { activeRequests, archiveRequests } = this.props;

    if (type === SupportStateType.Active) {
      this.setState({ requests: activeRequests });
    } else {
      this.setState({ requests: archiveRequests });
    }
  };

  onSubmit = ({ merchant, message }): void => {
    this.props.sendSupportMessageAction(merchant.value, message);
  };

  getCurrentPage = (rows, page: number) => {
    const startIndex = page * SUPPORT_PAGE_SIZE;
    const endIndex = (page + 1) * SUPPORT_PAGE_SIZE;
    return rows.filter((item, index) => startIndex <= index && index < endIndex);
  };

  setNewPage = (pageNumber: number) => {
    this.setState({ page: pageNumber });
  };

  handleTypeChange = (type: SupportStateType) => this.setState({ type });

  render(): ReactNode {
    const { loading, match, history, merchantIdList, openRequestAction, selectedMerchants, t } = this.props;
    const { page, type, modalIsOpen, requests } = this.state;

    const currentPageData = requests && requests.length ? this.getCurrentPage(requests, page) : [];

    const pageSize =
      currentPageData && currentPageData.length
        ? currentPageData.length < SUPPORT_PAGE_SIZE
          ? currentPageData.length
          : SUPPORT_PAGE_SIZE
        : 0;

    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.support" match={match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>

        {loading && <Loader />}

        {merchantIdList && merchantIdList.length ? (
          <Row>
            <Colxx xxs="12" className="d-flex justify-content-end mb-5">
              <Button color="primary" onClick={() => this.setState({ modalIsOpen: true })}>
                {t('support.new_request')}
              </Button>
            </Colxx>
          </Row>
        ) : (
          ''
        )}

        <Row>
          <Colxx xxs="12" className="d-flex justify-content-center mb-5">
            <ButtonGroup className="support-switch-buttons">
              <Button
                className={`active-request ${type === SupportStateType.Active ? 'active' : ''}`}
                color="primary"
                onClick={() => this.handleTypeChange(SupportStateType.Active)}
              >
                {t('support.active_requests')}
              </Button>
              <Button
                className={`active-request ${type === SupportStateType.Archive ? 'active' : ''}`}
                color="primary"
                onClick={() => this.handleTypeChange(SupportStateType.Archive)}
              >
                {t('support.request_history')}
              </Button>
            </ButtonGroup>
          </Colxx>
        </Row>

        {requests && requests.length > 0 && merchantIdList && merchantIdList.length ? (
          <Row>
            <Colxx xxs="12">
              <Card className="mb-4">
                <CardBody>
                  <ReactTable
                    data={currentPageData}
                    columns={getRequestColumns(type, selectedMerchants, t)}
                    page={page}
                    pages={Math.ceil(requests.length / SUPPORT_PAGE_SIZE)}
                    defaultPageSize={pageSize}
                    pageSize={pageSize}
                    sortable={false}
                    filterable={false}
                    showPageJump={true}
                    showPagination={true}
                    showPageSizeOptions={false}
                    PaginationComponent={DataTablePagination}
                    manual
                    onPageChange={(newPage: number) => this.setNewPage(newPage)}
                    getTrProps={(state, rowInfo) => {
                      if (rowInfo && rowInfo.row) {
                        return {
                          onClick: () => {
                            history.push({
                              pathname: `/support/${rowInfo.original.id}/`,
                              state: {
                                date: rowInfo.original.create_time,
                                type: rowInfo.original.type_name,
                                contract: rowInfo.original.contract,
                                account: rowInfo.original.partner_account_name
                              }
                            });
                          },
                          style: { cursor: 'pointer' }
                        };
                      }
                    }}
                  />
                </CardBody>
              </Card>
            </Colxx>
          </Row>
        ) : (
          ''
        )}

        {!(merchantIdList && merchantIdList.length) && <NotFoundMessage text={t('no_partners_selected')} />}

        {(!requests || !requests.length) && <NotFoundMessage />}

        {modalIsOpen && (
          <CreateRequestModal
            onCloseModal={() => this.setState({ modalIsOpen: false })}
            onConfirmModal={openRequestAction}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ support, settings, menu }) => ({
  loading: support.requests.loading,
  error: support.requests.error,
  selectedUserId: settings.selectedUser ? settings.selectedUser.id : 0,
  activeRequests: support.requests.activeRequests,
  archiveRequests: support.requests.archiveRequests,
  requestChat: support.currentRequest.data,
  merchantIdList: settings.selectedMerchantsId,
  openRequestState: support.openRequest,
  selectedMerchants: settings.selectedMerchants
});

const mapDispatchToProps = {
  getAllRequestsAction: getAllRequests,
  sendSupportMessageAction: sendSupportMessage,
  openRequestAction: openRequest,
  resetRequestsAction: resetRequests
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RequestListPage)
);
