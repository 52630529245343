import {
  GET_BALANCE_OPERATION,
  GET_BALANCE_OPERATION_SUCCESS,
  GET_BALANCE_OPERATION_ERROR,
  GET_BALANCE_STAT,
  GET_BALANCE_STAT_SUCCESS,
  GET_BALANCE_STAT_ERROR
} from '../actions';

import {
  GetBalanceOperationAction,
  GetBalanceOperationSuccessAction,
  GetBalanceOperationErrorAction,
  GetBalanceStatAction,
  GetBalanceStatSuccessAction,
  GetBalanceStatErrorAction,
  BalanceOperationRequestValues,
  BalanceOperation,
  CommonStat
} from './interface';

/* BalanceOperation */
export const getBalanceOperation = (requestValues: BalanceOperationRequestValues): GetBalanceOperationAction => ({
  type: GET_BALANCE_OPERATION,
  payload: requestValues
});

export const getBalanceOperationSuccess = (
  operations: BalanceOperation[],
  totalCount: number
): GetBalanceOperationSuccessAction => ({
  type: GET_BALANCE_OPERATION_SUCCESS,
  payload: { operations, totalCount }
});

export const getBalanceOperationError = (error: string): GetBalanceOperationErrorAction => ({
  type: GET_BALANCE_OPERATION_ERROR,
  payload: { error }
});

/* BalanceStat */
export const getBalanceStat = (requestValues: BalanceOperationRequestValues): GetBalanceStatAction => ({
  type: GET_BALANCE_STAT,
  payload: requestValues
});

export const getBalanceStatSuccess = (commonStat: CommonStat): GetBalanceStatSuccessAction => ({
  type: GET_BALANCE_STAT_SUCCESS,
  payload: { commonStat }
});

export const getBalanceStatError = (error: string): GetBalanceStatErrorAction => ({
  type: GET_BALANCE_STAT_ERROR,
  payload: { error }
});
