import React, { Component, ReactNode } from 'react';
import { Row, Card, Label, FormGroup, Button } from 'reactstrap';
import { NavLink, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';

import { NOTIFICATION_TIMEOUT } from '../../../constants/defaultValues';
import { loginUser, googleAuth, smsAuth, secondFactorCancellation } from '../../../redux/actions';
import { LoginCredentials } from '../../../redux/authUser/interface';
import { Colxx } from '../../../components/common/CustomBootstrap';
import { NotificationManager } from '../../../components/common/react-notifications';
import { trimSpaces } from '../../../helpers/Utils';
import LanguageSelect from '../../../components/LanguageSelect';
import { withTranslation } from 'react-i18next';

import GoogleAuthModal from './GoogleAuthModal';
import SmsAuthModal from './SmsAuthModal';
import ModalCountDown from './ModalCountDown';
import GoogleQrModal from './GoogleQrModal';
import { LoginProps } from './interface';
import { PASSWORD_MIN_LENGTH, CookieAuthStatus, LOGIN_MIN_LENGTH, RESTORE_LABELS, RESTORE_TYPES } from './constant';
import cn from 'classnames';
import SupportEmailLink from '../../settings/CustomDesign/Views/SupportEmailLink';

class Login extends Component<LoginProps, LoginCredentials> {
  state: LoginCredentials = {
    username: '',
    password: '',
    showPassword: false,
    blockedUserMessage: ''
  };
  resetForm;

  componentDidMount(): void {
    window.addEventListener('popstate', (event) => {
      this.handleGoBackAction(event);
    });
    window.addEventListener('beforeunload', () => this.beforeUnload());
  }

  handleGoBackAction = (event) => {
    const state = event.state;
    if (state !== null) {
      this.resetForm();
      this.secondFactorCancellation();
    }
  };

  componentDidUpdate(prevProps: LoginProps, prevState: LoginCredentials): void {
    const { error } = this.props;
    if (error && prevProps.error !== error && !error.match(/{\d+}/)) {
      NotificationManager.info(error, 'Ошибка', NOTIFICATION_TIMEOUT, null, null, '');
    } else if (error.match(/{\d+}/) && !prevState.blockedUserMessage) {
      this.secondFactorCancellation();
      this.setState({ blockedUserMessage: error });
    }
  }

  validateUsername = (value) => {
    const { t } = this.props;

    let error;
    if (!value) {
      error = t('tooltip.enter_login');
    } else if (value.length < LOGIN_MIN_LENGTH) {
      error = t('tooltip.enter_login');
    }
    return error;
  };

  validatePassword = (value) => {
    const { t } = this.props;

    let error;
    if (!value) {
      error = t('tooltip.enter_password');
    } else if (value.length < PASSWORD_MIN_LENGTH) {
      error = t('tooltip.password_minlength');
    }
    return error;
  };

  onUserLogin = (values) => {
    const { loading, loginAction, history } = this.props;

    const reducedValues: typeof values = trimSpaces(values);

    if (!loading && reducedValues.email !== '' && reducedValues.password !== '') {
      loginAction(values, history);
    }
  };

  localStoreUserExists = (): boolean => {
    const userLogin = localStorage.getItem('userLogin');

    return userLogin != null && userLogin.length > 0;
  };

  secondFactorCancellation = (): void => {
    const { secondFactorCancellationAction } = this.props;
    localStorage.removeItem('userLogin');
    localStorage.removeItem('tfs');
    secondFactorCancellationAction && secondFactorCancellationAction();
  };

  beforeUnload = () => {
    this.secondFactorCancellation();
  };

  componentWillUnmount(): void {
    window.removeEventListener('beforeunload', () => this.beforeUnload());
  }

  render(): ReactNode {
    const { loading, loginStatus, googleAuthAction, smsAuthAction, user, t } = this.props;
    const { password, username, showPassword, blockedUserMessage } = this.state;
    const initialValues = { username, password };

    if (user.data) {
      return <Redirect to="/" />;
    }
    return (
      <Row className="h-100 login-form">
        <Colxx xxs="12" sm="11" md="8" lg="6" className="mx-auto my-auto">
          <Card className="w-100">
            <div className="w-100 login-modal">
              <div className="mb-5 d-flex justify-content-end">
                <LanguageSelect />
              </div>
              <NavLink to="/" className="white">
                <div className="logo-block">
                  <span className="logo-single" />
                </div>
              </NavLink>
              <Formik initialValues={initialValues} onSubmit={this.onUserLogin}>
                {({ errors, touched, isValid, resetForm }) => {
                  this.resetForm = resetForm;

                  return (
                    <Form className="av-tooltip tooltip-label-bottom">
                      <FormGroup className="form-group has-float-label">
                        <Label className="float-label">{t('settings.login')}</Label>
                        <Field className="form-control" name="username" validate={this.validateUsername} />
                        {errors.username && touched.username && (
                          <div className="invalid-feedback d-block">{errors.username}</div>
                        )}
                      </FormGroup>
                      <FormGroup className="form-group has-float-label">
                        <Label className="float-label">{t('customDesign.password')}</Label>
                        <Field
                          className="form-control"
                          type={showPassword ? 'text' : 'password'}
                          name="password"
                          validate={this.validatePassword}
                        />
                        <div
                          className={cn('simple-icon-eye show-password-eye cursor-pointer', {
                            'color-theme-1': showPassword
                          })}
                          onClick={() => this.setState({ showPassword: !showPassword })}
                        />
                        {errors.password && touched.password && (
                          <div className="invalid-feedback d-block">{errors.password}</div>
                        )}
                      </FormGroup>
                      <div className="d-flex justify-content-between align-items-center">
                        <NavLink
                          to={{
                            pathname: '/user/forgot-password',
                            state: { restoreType: RESTORE_TYPES.RestorePassword }
                          }}
                        >
                          {RESTORE_LABELS(t)[RESTORE_TYPES.RestorePassword].text}
                        </NavLink>
                        <Button
                          type="submit"
                          color="primary"
                          disabled={loading}
                          className={`btn-multiple-state ${!isValid ? 'disabled' : ''}${loading ? 'show-spinner' : ''}`}
                        >
                          <span className="spinner d-inline-block">
                            <span className="bounce1" />
                            <span className="bounce2" />
                            <span className="bounce3" />
                          </span>
                          <span className="label">{t('button.Sign In')}</span>
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>

              <div className="login-tooltip">
                {t('customDesign.hint2')} <SupportEmailLink />
              </div>
            </div>
          </Card>
        </Colxx>
        <GoogleAuthModal
          isOpen={loginStatus === CookieAuthStatus.GoogleAuthReq && this.localStoreUserExists()}
          isShowForgotLink={true}
          onSubmit={null as any}
          googleAuthAction={googleAuthAction}
          cancellation={this.secondFactorCancellation}
          loading={loading}
        />
        {blockedUserMessage && (
          <ModalCountDown
            onCloseModal={() => {
              this.setState({ blockedUserMessage: '' });
            }}
            message={blockedUserMessage}
          />
        )}
        <SmsAuthModal
          isOpen={loginStatus === CookieAuthStatus.SmsAuthReq}
          smsAuthAction={smsAuthAction}
          cancellation={this.secondFactorCancellation}
        />
        {loginStatus === CookieAuthStatus.GoogleRegReq && (
          <GoogleQrModal cancellation={this.secondFactorCancellation} />
        )}
      </Row>
    );
  }
}

const mapStateToProps = ({ authUser }) => {
  const { loginStatus, loading, error, user } = authUser;
  return {
    loginStatus,
    loading,
    error,
    user
  };
};

const mapDispatchToProps = {
  loginAction: loginUser,
  googleAuthAction: googleAuth,
  smsAuthAction: smsAuth,
  secondFactorCancellationAction: secondFactorCancellation
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Login)
);
