import React, { Component, Fragment, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Row, Card, CardBody, Label } from 'reactstrap';
import ReactTable from 'react-table';

import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import { NotificationManager } from '../../../components/common/react-notifications';
import { Loader } from '../../../components/common/Loader';
import Breadcrumb from '../../../containers/navs/Breadcrumb';

import { tablecolumns, DEFAULT_SORTER } from './constants';
import { NOTIFICATION_TIMEOUT } from '../../../constants/defaultValues';
import { BalanceProps, BalanceState } from './interface';
import { NotFoundMessage } from '../../../components/common/NotFoundMessage';
import { getAccountsBalance } from '../../../redux/actions';
import Select from 'react-select';
import { SelectorOption } from '../../../interfaces/app';
import isEqual from 'lodash/isEqual';
import { withTranslation } from 'react-i18next';
class Balance extends Component<BalanceProps, BalanceState> {
  state: BalanceState = {
    selectedContract: null,
    contractOptions: null,
    rows: []
  };

  componentDidMount(): void {
    const { merchantIdList, selectedUserId, isAdmin } = this.props;
    if (merchantIdList && ((isAdmin && selectedUserId) || !isAdmin)) {
      const { getAccountsBalanceAction, authUserId } = this.props;
      const userId = isAdmin && selectedUserId && authUserId !== selectedUserId ? selectedUserId : undefined;
      getAccountsBalanceAction(merchantIdList, userId);
    }
  }

  componentDidUpdate(prevProps: BalanceProps): void {
    const { error, data, merchantIdList, selectedUserId, isAdmin, loading } = this.props;
    const { contractOptions } = this.state;

    const isUpdateAccountsBalance =
      merchantIdList && merchantIdList.length && !isEqual(prevProps.merchantIdList, merchantIdList);

    if (!loading && isUpdateAccountsBalance) {
      const { getAccountsBalanceAction, authUserId } = this.props;
      const userId = isAdmin && selectedUserId && !isEqual(authUserId, selectedUserId) ? selectedUserId : undefined;

      getAccountsBalanceAction(merchantIdList ? merchantIdList : [], userId);
      this.setState({ selectedContract: null, rows: [] });
    }
    let newContractOptions: SelectorOption[] = [];
    if (data && Object.keys(data).length > 0) {
      newContractOptions = Object.keys(data).length
        ? Object.keys(data).map((item: any) => ({ label: item, value: item }))
        : [];

      if (!isEqual(newContractOptions, contractOptions)) {
        this.setState({ contractOptions: newContractOptions });
      }
    } else {
      newContractOptions = [{ label: 'Нет данных. У выбранных партнеров нет финансовых операций', value: 0 }];
      if (!isEqual(newContractOptions, contractOptions)) {
        this.setState({ contractOptions: newContractOptions });
      }
    }

    if (error && error !== prevProps.error) {
      NotificationManager.info(error, 'Ошибка', NOTIFICATION_TIMEOUT, null, null, '');
    }
  }

  render(): ReactNode {
    const { match, data, loading, merchantIdList, t } = this.props;
    const { selectedContract, contractOptions, rows } = this.state;

    return (
      <Fragment>
        {loading && <Loader />}

        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.balance" match={match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>

        {merchantIdList && merchantIdList.length > 0 && (
          <Row>
            <Colxx xxs="12" className="mb-4">
              <Card>
                <CardBody>
                  <Row>
                    <Colxx xxs="8" sm="8" md="6" lg="3">
                      <Label className="float-label">{t('balance.contract')}</Label>
                      <Select
                        name="contract"
                        className="react-select"
                        classNamePrefix="react-select"
                        value={selectedContract}
                        options={contractOptions ? contractOptions : []}
                        onChange={(contract: SelectorOption) => {
                          this.setState({ selectedContract: contract });
                          this.setState({ rows: contract.value ? data[contract.value] : [] });
                        }}
                        placeholder={t('balance.select contract number')}
                      />
                    </Colxx>
                  </Row>
                </CardBody>
              </Card>
            </Colxx>
          </Row>
        )}

        {rows && rows.length > 0 && merchantIdList && merchantIdList.length > 0 && (
          <Row className="h-100">
            <Colxx xxs="12" className="mb-4">
              <Card className="w-100">
                <CardBody>
                  <ReactTable
                    data={rows}
                    columns={tablecolumns(t)}
                    defaultPageSize={rows.length}
                    pageSize={rows.length}
                    defaultSorted={DEFAULT_SORTER}
                    showPageSizeOptions={false}
                    showPageJump={false}
                    filterable={false}
                    sortable={true}
                    showPagination={false}
                    loading={loading}
                    loadingText={''}
                  />
                </CardBody>
              </Card>
            </Colxx>
          </Row>
        )}

        {!(merchantIdList && merchantIdList.length) && <NotFoundMessage text={t('no_partners_selected')} />}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ settings, services, authUser }) => ({
  data: services.accountsBalance.data,
  loading: services.accountsBalance.loading,
  error: services.accountsBalance.error,
  merchantIdList: settings.selectedMerchantsId,

  isAdmin: authUser.user.data && authUser.user.data.is_admin ? authUser.user.data.is_admin : false,
  selectedUserId: settings.selectedUser ? settings.selectedUser.id : 0,
  authUserId: authUser.user.data ? authUser.user.data.id : 0
});

const mapDispatchToProps = {
  getAccountsBalanceAction: getAccountsBalance
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Balance)
);
