import { all, call, fork, put, takeLatest, SimpleEffect } from 'redux-saga/effects';
import { CombinatorEffect } from '@redux-saga/types';

import { ApiRequest } from '../../helpers/ApiRequest/ApiRequest';
import { SendSupportMessageResponse } from '../../api.interface';

import {
  SEND_SUPPORT_MESSAGE,
  sendSupportMessageSuccess,
  sendSupportMessageError,
  GET_ALL_REQUESTS,
  getAllRequestsError,
  getAllRequestsSuccess,
  getRequestError,
  getRequestSuccess,
  GET_REQUEST,
  openRequestSuccess,
  openRequestError,
  OPEN_REQUEST,
  GET_REQUEST_TYPES,
  getRequestTypesError,
  getRequestTypesSuccess
} from '../actions';
import { GetAllRequests, GetRequest, OpenRequest, SendSupportMessageAction } from './interface';
import { createQueryString } from '../../helpers/Utils';

// GET ACTIVE SUPPORT REQUESTS
export function* getAllRequests({ payload }: GetAllRequests): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const formData = new FormData();
    payload.merchantId.forEach((itemId, index) => formData.append('p', String(itemId)));

    const { data, error } = yield call(ApiRequest.post, '/support/tickets/', formData);

    if (error) {
      yield put(getAllRequestsError(`${error}`));
    } else {
      yield put(getAllRequestsSuccess(data || []));
    }
  } catch (error) {
    yield put(getAllRequestsError(`${error}`));
  }
}

// GET ONE REQUEST
export function* getRequest({ payload }: GetRequest): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { error, data } = yield call(
      ApiRequest.get,
      `/support/messages/?${createQueryString({ s: payload.ticketId })}`
    );

    if (error) {
      yield put(getRequestError(`${error}`));
    } else {
      yield put(getRequestSuccess(data));
    }
  } catch (error) {
    yield put(getRequestError(`${error}`));
  }
}

// OPEN REQUEST
export function* openRequest({ payload }: OpenRequest): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { doc_file, partner, message, request_type, account, contract } = payload;
    const formData = new FormData();

    doc_file && formData.append('f', doc_file);
    formData.append('p', partner.toString());
    formData.append('m', message);
    formData.append('st', request_type.toString());
    formData.append('c', contract.toString());
    account && formData.append('a', account.toString());

    const { error } = yield call(ApiRequest.post, '/support/create/', formData);

    if (error) {
      yield put(openRequestError(`${error}`));
    } else {
      yield put(openRequestSuccess());
    }
  } catch (error) {
    yield put(openRequestError(`${error}`));
  }
}

// SEND MESSAGE TO TECHNICAL SUPPORT
export function* sendSupportMessage({
  payload
}: SendSupportMessageAction): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { message, requestId, file } = payload;

    const formData = new FormData();
    file && formData.append('f', file);
    message && formData.append('m', message.toString());
    formData.append('s', requestId.toString());

    const { error }: SendSupportMessageResponse = yield call(ApiRequest.post, '/support/messages/create/', formData);

    if (error) {
      yield put(sendSupportMessageError(`${error}`));
    } else {
      yield put(sendSupportMessageSuccess(payload.message, payload.file));
    }
  } catch (error) {
    yield put(sendSupportMessageError(`${error}`));
  }
}

// GET REQUEST TYPES
export function* getRequestTypes(): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { data, error } = yield call(ApiRequest.get, '/support/types/');

    if (error) {
      yield put(getRequestTypesError(`${error}`));
    } else {
      yield put(getRequestTypesSuccess(data));
    }
  } catch (error) {
    yield put(getRequestTypesError(`${error}`));
  }
}

// WATCHERS
export function* watchGetAllRequests(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(GET_ALL_REQUESTS, getAllRequests);
}
export function* watchGetRequest(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(GET_REQUEST, getRequest);
}
export function* watchOpenRequest(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(OPEN_REQUEST, openRequest);
}
export function* watchSendSupportMessage(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(SEND_SUPPORT_MESSAGE, sendSupportMessage);
}
export function* watchGetRequestTypes(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(GET_REQUEST_TYPES, getRequestTypes);
}

export default function* rootSaga(): Generator<CombinatorEffect<string, any>, any, any> {
  yield all([
    fork(watchSendSupportMessage),
    fork(watchGetAllRequests),
    fork(watchOpenRequest),
    fork(watchGetRequest),
    fork(watchGetRequestTypes)
  ]);
}
