import React from 'react';
import { CellInfo } from 'react-table';

import { Merchant } from '../../../redux/menu/interface';
import { UserPartner } from './interface';
import { PayoutAccount } from '../../../redux/operations/interface';
import { Service } from '../../../redux/services/interface';

export const getGlossaryUsersColumns = (
  getPartners: (userId: number) => void,
  partners: {
    data: { [userId: number]: UserPartner[] } | null;
    loading: boolean;
    error: string;
  },
  t: any,
  changeState: (userId: number) => void
) => [
  {
    Header: t('directories.user id'),
    accessor: 'id'
  },
  {
    Header: t('directories.name'),
    accessor: 'fio'
  },
  {
    Header: t('directories.e-mail'),
    accessor: 'email'
  },
  {
    Header: t('directories.phone number'),
    accessor: 'phone'
  },
  {
    Header: t('directories.is active'),
    accessor: 'is_active',
    Cell: ({ value }: CellInfo) => (value ? t('yes') : t('no'))
  },
  {
    expander: true,
    Header: '',
    width: 30,
    Expander: ({ original, isExpanded }) => {
      if (
        !partners.loading &&
        ((isExpanded && partners.data && !partners.data[original.id]) || (isExpanded && !partners.data))
      ) {
        getPartners(Number(original.id));
        changeState(Number(original.id));
      }
      return (
        <div>{isExpanded ? <i className="simple-icon-arrow-up" /> : <i className="simple-icon-arrow-down" />}</div>
      );
    },
    style: {
      fontSize: 12,
      padding: '0',
      textAlign: 'center',
      userSelect: 'none'
    }
  }
];

export const getGlossaryUserPartnersData = (
  prevData: { [userId: number]: UserPartner[] } | null,
  userId: number,
  data: Merchant[]
) => {
  if (!userId || !data) {
    return { [userId]: [] };
  }

  if (!prevData) {
    return { [userId]: data };
  } else {
    const partners = prevData ? { ...prevData } : {};

    if (data && data.length) {
      partners[userId] = data.map((partner: Merchant) => ({ id: partner.id, name: partner.name }));
    }
    return partners;
  }
};

export const getGlossaryUserAccountsData = (
  prevData: { [partnerId: number]: Service[] } | null,
  accounts: { [serviceType: number]: Service[] }
): { [partnerId: number]: Service[] } => {
  const allAccounts: Service[] = [];
  const accountsByPartners: { [partnerId: number]: Service[] } = prevData ? { ...prevData } : {};

  Object.values(accounts).forEach((service: Service[]) => {
    service &&
      Object.values(service).forEach((account: Service) => {
        allAccounts.push(account);
      });
  });

  allAccounts.forEach((account: Service) => {
    if (!accountsByPartners[account.partner_id]) {
      accountsByPartners[account.partner_id] = [];
    } else {
      const isAccountExist = accountsByPartners[account.partner_id].find(
        ({ id, name }: Service) => id === account.id && name === account.name
      );
      !isAccountExist && accountsByPartners[account.partner_id].push(account);
    }
  });

  return accountsByPartners;
};

export const getGlossaryPartnerAccountsData = (
  prevData: { [partnerId: number]: PayoutAccount[] } | null,
  partnerId: number,
  accounts: { [serviceType: number]: PayoutAccount[] }
): { [partnerId: number]: PayoutAccount[] } => {
  const allAccounts: PayoutAccount[] = [];

  Object.values(accounts).forEach((service: PayoutAccount[]) => {
    service &&
      Object.values(service).forEach((account: PayoutAccount) => {
        allAccounts.push(account);
      });
  });

  return prevData ? { ...prevData, [partnerId]: allAccounts } : { [partnerId]: allAccounts };
};
