import React, { Component, Fragment, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';

import { AdminLayoutProps } from './interface';

class AdminLayout extends Component<AdminLayoutProps> {
  render(): ReactNode {
    const { isAdmin, children, loading } = this.props;

    if (!isAdmin && !loading) {
      return <Redirect to={'/error'} />;
    }

    return !loading ? <Fragment>{children}</Fragment> : null;
  }
}

const mapStateToProps = ({ authUser }) => {
  const {
    user: { data, loading }
  } = authUser;

  return {
    isAdmin: Boolean(data && data.is_admin),
    loading
  };
};

export default withRouter(connect(mapStateToProps)(AdminLayout));
