import React, { Component, Fragment, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import { Row, Card, CardTitle, CardBody, Button, FormGroup, Label, ModalHeader, ModalBody, Modal } from 'reactstrap';
import Select from 'react-select';
import isEqual from 'lodash/isEqual';

import { NotificationManager } from '../../../components/common/react-notifications';
import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import { Loader } from '../../../components/common/Loader';
import { NotFoundMessage } from '../../../components/common/NotFoundMessage';
import Breadcrumb from '../../../containers/navs/Breadcrumb';
import { getDestinations, getChartsData, resetCharts, getPayoutAccounts, getCurrencies } from '../../../redux/actions';
import DateRangePicker from '../../../components/common/DateRangePicker/DateRangePicker';
import { SelectorOption } from '../../../interfaces/app';
import { NOTIFICATION_TIMEOUT } from '../../../constants/defaultValues';

import {
  chartValidation,
  directionsOptions,
  getFieldErrorsCount,
  prepareDateByPeriod,
  scrollToTarget,
  setDirectionsData
} from '../../../helpers/Utils';

import {
  STATISTICS_PERIOD_OPTIONS,
  ChartTypes,
  ChartLabels,
  STATISTICS_SERVICES_OPTIONS,
  STATISTICS_INITIAL_VALUES,
  StatisticType,
  NOT_SELECTED_CURRENCY_OPTION
} from '../constants';
import { TIMEZONE_TOOLTIP_TEXT } from '../../../constants/app';
import {
  getDateFrom,
  getAccounts,
  getDirectionsOptions,
  getCurrenciesOptions,
  getActiveCurrencies,
  prepareStatisticParams
} from '../helpers';
import { StatisticsServiceSelectOption } from '../interface';
import ChartPane from './ChartPane';
import { ChartsProps, ChartsState } from './interface';
import { CustomDropdownTreeSelect } from '../../../components/common/CustomDropdownTreeSelect';
import { ServiceTypes } from '../../operations/OperationsList/constants';
import { withTranslation } from 'react-i18next';
class Charts extends Component<ChartsProps, ChartsState> {
  state: ChartsState = {
    modalChartType: '',
    accountOptions: [],
    dateChanged: false,
    activeCurrencies: [],
    shouldReset: false
  };
  TargetRef = React.createRef<HTMLDivElement>();
  setFieldValue;
  resetForm;
  serviceType;
  currentCurrency;

  componentDidMount(): void {
    const {
      merchantIdList,
      destinations,
      accounts,
      currencies,
      isAdmin,
      selectedUserId,
      authUserId,
      getCurrenciesAction,
      getDestinationsAction,
      getPayoutAccountsAction,
      t
    } = this.props;

    const isDestinationsExist = destinations && destinations.length;
    const isAccountsExist = accounts && accounts.data;
    const isCurrenciesExist = currencies && currencies.data && currencies.data;

    !isDestinationsExist && getDestinationsAction();

    if (!isAccountsExist && merchantIdList && merchantIdList.length) {
      getPayoutAccountsAction(
        merchantIdList,
        isAdmin && selectedUserId && authUserId !== selectedUserId ? selectedUserId : undefined
      );
    }
    isAccountsExist && this.setState({ accountOptions: getAccounts(this.serviceType, t, accounts.data) });
    !isCurrenciesExist && getCurrenciesAction();

    const activeCurrencies = getActiveCurrencies(
      currencies.data,
      STATISTICS_SERVICES_OPTIONS(t)[0].value.operationType
    );
    activeCurrencies && this.setState({ activeCurrencies });
  }

  componentDidUpdate(prevProps: Readonly<ChartsProps>, prevState: Readonly<ChartsState>): void {
    const {
      data,
      error,
      merchantIdList,
      currencies,
      selectedUserId,
      isAdmin,
      authUserId,
      accounts,
      getPayoutAccountsAction,
      resetChartsAction,
      t
    } = this.props;
    const { accountOptions } = this.state;

    if (error && error !== prevProps.error) {
      NotificationManager.info(error, 'Ошибка', NOTIFICATION_TIMEOUT, null, null, '');
    }

    if (prevProps.data !== data && data) {
      scrollToTarget(this.TargetRef.current);
    }

    if (prevProps.accounts !== accounts) {
      this.setState({ accountOptions: getAccounts(this.serviceType, t, accounts.data) });
    }

    if (prevState.accountOptions !== accountOptions) {
      this.setFieldValue && this.setFieldValue('account', accountOptions);
    }

    if (prevProps.currencies.data !== currencies.data) {
      const activeCurrenciesList = getActiveCurrencies(
        currencies.data,
        STATISTICS_SERVICES_OPTIONS(t)[0].value.operationType
      );
      activeCurrenciesList && this.setState({ activeCurrencies: activeCurrenciesList });
    }

    if (!isEqual(prevProps.merchantIdList, merchantIdList) || prevProps.selectedUserId !== selectedUserId) {
      resetChartsAction();

      if (merchantIdList && merchantIdList.length) {
        getPayoutAccountsAction(
          merchantIdList,
          isAdmin && selectedUserId && authUserId !== selectedUserId ? selectedUserId : undefined
        );
      }
    }

    if (
      prevProps.merchantIdList &&
      prevProps.selectedUserId &&
      merchantIdList &&
      selectedUserId &&
      (!isEqual(prevProps.merchantIdList, merchantIdList) || prevProps.selectedUserId !== selectedUserId)
    ) {
      this.handleResetForm();
    }

    this.state.shouldReset && this.setState({ shouldReset: false });
  }

  componentWillUnmount(): void {
    this.props.resetChartsAction();
  }

  handleResetForm = () => {
    const { currencies, t } = this.props;

    this.resetForm();
    this.setState({ shouldReset: true });

    const activeCurrenciesList = getActiveCurrencies(
      currencies.data,
      STATISTICS_SERVICES_OPTIONS(t)[0].value.operationType
    );
    activeCurrenciesList && this.setState({ activeCurrencies: activeCurrenciesList });
  };

  onSubmit = (values): void => {
    const { getChartsAction, merchantIdList, isAdmin, authUserId, selectedUserId, accounts, t } = this.props;

    const period = values.period ? values.period.value : '';
    const dates = prepareDateByPeriod(values.dateFrom, values.dateTo, period);
    const userId = isAdmin && authUserId !== selectedUserId ? selectedUserId : undefined;
    const requestParams = prepareStatisticParams(values, merchantIdList, userId, accounts.data, t);

    getChartsAction({
      ...requestParams,
      period,
      from: dates.dateFrom,
      to: dates.dateTo
    });
  };

  validate = (values): { [key: string]: string } => chartValidation(values, this.props.t);

  handleDateChanged = () => this.setState({ dateChanged: true });

  onServiceChange = (service: StatisticsServiceSelectOption) => {
    const { accounts, destinations, currencies, t } = this.props;

    const direction = getDirectionsOptions(service, destinations, t)[0];
    this.setFieldValue && this.setFieldValue('service', service);
    this.setFieldValue && this.setFieldValue('direction', direction);

    const activeCurrencies = getActiveCurrencies(currencies.data, service.value.operationType);
    if (service.key === ServiceTypes.Mc) {
      this.setFieldValue('currency', {
        label: activeCurrencies[0].name,
        value: activeCurrencies[0].id,
        key: activeCurrencies[0].id
      });
    } else {
      this.setFieldValue('currency', NOT_SELECTED_CURRENCY_OPTION(t));
    }

    this.setState({
      accountOptions: getAccounts(
        service.value.operationType,
        t,
        accounts.data,
        direction.value.source,
        activeCurrencies[0].name
      ),
      activeCurrencies
    });
  };

  render(): ReactNode {
    const { loading, data, period, showRefund, match, destinations, error, merchantIdList, t } = this.props;
    const { modalChartType, accountOptions, dateChanged, activeCurrencies, shouldReset } = this.state;
    const { RefundTotal, RefundTotalAmount, ...chartsWithoutRefund } = ChartTypes;
    const charts = showRefund ? ChartTypes : chartsWithoutRefund;

    return (
      <Fragment>
        {loading && <Loader />}

        <Row className="flex-shrink-0">
          <Colxx xxs="12">
            <Breadcrumb heading="menu.statistics" match={match} />
            <div className="time-tip  d-none d-lg-inline-block">{t(TIMEZONE_TOOLTIP_TEXT)}</div>
            <Separator className="mb-5" />
          </Colxx>
        </Row>

        {merchantIdList && merchantIdList.length > 0 && (
          <Formik
            initialValues={STATISTICS_INITIAL_VALUES(t)[StatisticType.Charts]}
            onSubmit={this.onSubmit}
            validate={this.validate}
          >
            {({ values, setFieldValue, errors, touched, setFieldTouched, resetForm }) => {
              this.setFieldValue = setFieldValue;
              this.resetForm = resetForm;
              this.serviceType = values.service.value.operationType;
              this.currentCurrency = values.currency;

              return (
                <Form>
                  <Card className="mb-4">
                    <CardBody>
                      <Row>
                        <DateRangePicker
                          dateFrom={values.dateFrom}
                          dateTo={values.dateTo}
                          period={values.period.value}
                          errors={errors}
                          touched={touched}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          handleDateChanged={this.handleDateChanged}
                        />
                        <Colxx xxs="12" sm="6" lg="4" xl="3">
                          <FormGroup className="has-float-label">
                            <Label className="float-label"> {t('charts.Service')}</Label>
                            <Select
                              id="direction"
                              className="react-select"
                              classNamePrefix="react-select"
                              name="service"
                              value={values.service}
                              onChange={this.onServiceChange}
                              options={STATISTICS_SERVICES_OPTIONS(t)}
                              placeholder=""
                            />
                          </FormGroup>
                        </Colxx>
                        <Colxx xxs="12" sm="6" lg="4" xl="3">
                          <FormGroup className="has-float-label">
                            <Label className="float-label">{t('charts.Destination')}</Label>
                            <CustomDropdownTreeSelect
                              name="direction"
                              data={directionsOptions(values.service.key, destinations, t)}
                              shouldReset={shouldReset}
                              onChange={(changedValue, selectedValues) => {
                                const direction = setDirectionsData(selectedValues);
                                setFieldValue('direction', direction);

                                this.setState({
                                  accountOptions: getAccounts(
                                    values.service.value.operationType,
                                    t,
                                    this.props.accounts.data,
                                    direction ? direction.value.source : undefined,
                                    this.currentCurrency.label
                                  )
                                });
                              }}
                              disabled={values.service.key === ServiceTypes.Mc}
                            />
                            {errors.direction && <div className="invalid-feedback d-block">{errors.direction}</div>}
                          </FormGroup>
                        </Colxx>
                        <Colxx xxs="12" sm="6" lg="4" xl="3">
                          <FormGroup className="has-float-label">
                            <Label for="currency" className="float-label">
                              {t('charts.Currency')}
                            </Label>
                            <Select
                              id="currency"
                              className="react-select"
                              classNamePrefix="react-select"
                              name="currency"
                              value={values.currency}
                              onChange={(currency: SelectorOption) => {
                                setFieldValue('currency', currency);
                                this.setState({
                                  accountOptions: getAccounts(
                                    values.service.value.operationType,
                                    t,
                                    this.props.accounts.data,
                                    values.direction ? values.direction.value.source : undefined,
                                    currency.label
                                  )
                                });
                              }}
                              options={getCurrenciesOptions(activeCurrencies, values.service.key, t, false)}
                              isDisabled={values.service.key === ServiceTypes.Mc}
                              placeholder=""
                            />
                            {errors.currency && <div className="invalid-feedback d-block">{errors.currency}</div>}
                          </FormGroup>
                        </Colxx>
                        <Colxx xxs="12" sm="6" lg="4" xl="3">
                          <FormGroup className="has-float-label">
                            <Label for="period" className="float-label">
                              {t('charts.Period')}
                            </Label>
                            <Select
                              id="period"
                              className="react-select"
                              classNamePrefix="react-select"
                              name="period"
                              value={values.period}
                              onChange={(p: SelectorOption) => {
                                setFieldValue('period', p);
                                if (!dateChanged) {
                                  setFieldValue('dateFrom', getDateFrom(p.value));
                                  setFieldValue('dateTo', new Date());
                                }
                              }}
                              options={STATISTICS_PERIOD_OPTIONS(t)}
                              placeholder=""
                            />
                          </FormGroup>
                        </Colxx>
                        <Colxx xxs="12" sm="6" lg="4" xl="3">
                          <FormGroup className="has-float-label">
                            <Label className="float-label">{t('charts.Merchant Account')}</Label>
                            <CustomDropdownTreeSelect
                              name="account"
                              data={accountOptions}
                              shouldReset={shouldReset}
                              onChange={(changedValue, selectedValues) => setFieldValue('account', selectedValues)}
                              onBlur={() => setFieldTouched('account')}
                              onFocus={() => setFieldTouched('account', false)}
                            />
                            {errors.account && <div className="invalid-feedback d-block">{errors.account}</div>}
                          </FormGroup>
                        </Colxx>
                        <Colxx xxs="12" className="d-flex justify-content-end">
                          <Button disabled={!!getFieldErrorsCount(errors)} color="primary" type="submit">
                            {t('charts.button.search')}
                          </Button>
                        </Colxx>
                      </Row>
                    </CardBody>
                  </Card>
                </Form>
              );
            }}
          </Formik>
        )}

        {!(merchantIdList && merchantIdList.length) && <NotFoundMessage text={t('no_partners_selected')} />}
        {(data && !data.length) || (error && <NotFoundMessage />)}

        <div ref={this.TargetRef}>
          {data && (
            <Row>
              {Object.entries(charts).map(([key]) => (
                <Colxx key={key} xxs="12" sm="6" className="mb-4">
                  <Card onClick={() => this.setState({ modalChartType: ChartTypes[key] })} className="cursor-pointer">
                    <CardBody>
                      <CardTitle>{t(ChartLabels[key])}</CardTitle>
                      <ChartPane data={data} period={period} type={ChartTypes[key]} />
                    </CardBody>
                  </Card>
                </Colxx>
              ))}
            </Row>
          )}
        </div>

        <Modal isOpen={!!modalChartType} toggle={() => this.setState({ modalChartType: '' })} className="mw-100 w-60">
          <ModalHeader toggle={() => this.setState({ modalChartType: '' })}>{ChartLabels[modalChartType]}</ModalHeader>
          <ModalBody>
            <ChartPane data={data} period={period} type={modalChartType} isDetail={true} />
          </ModalBody>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ statistics, settings, authUser, operations }) => ({
  isAdmin: authUser.user.data && authUser.user.data.is_admin ? authUser.user.data.is_admin : false,
  selectedUserId: settings.selectedUser ? settings.selectedUser.id : 0,
  authUserId: authUser.user.data ? authUser.user.data.id : 0,
  data: statistics.charts.data,
  period: statistics.charts.period,
  loading: statistics.charts.loading,
  error: statistics.charts.error,
  showRefund: statistics.charts.showRefund,
  merchantIdList: settings.selectedMerchantsId,
  destinations: operations.destinations.data,
  accounts: operations.payoutAccounts,
  currencies: settings.currencies
});

const mapDispatchToProps = {
  getDestinationsAction: getDestinations,
  getChartsAction: getChartsData,
  resetChartsAction: resetCharts,
  getCurrenciesAction: getCurrencies,
  getPayoutAccountsAction: getPayoutAccounts
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Charts)
);
