import React, { Component, ReactNode } from 'react';
import { Redirect, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Row } from 'reactstrap';

import { Colxx } from '../../../components/common/CustomBootstrap';
import Breadcrumb from '../../../containers/navs/Breadcrumb';
import ChangePasswordForm from './ChangePasswordForm';
import i18n from '../../../i18n';

import { RedirectLayoutProps } from './interface';

class RedirectLayout extends Component<RedirectLayoutProps> {
  render(): ReactNode {
    const { match, shouldChangePassword } = this.props;

    if (!shouldChangePassword) {
      return <Redirect to="/" />;
    }

    return (
      <div className="fixed-background">
        <Row>
          <Colxx xxs="12" className="d-flex justify-content-center  mt-5">
            <Breadcrumb heading="menu.change-password" match={match} />
          </Colxx>
        </Row>
        <Row className="d-flex justify-content-center">
          <Colxx xxs="12" sm="10" md="8" lg="6" className="mb-4">
            <div style={{ textAlign: 'center' }} className="mb-4">
              <p>{i18n.t('change_password_text')}</p>
            </div>
            <ChangePasswordForm />
          </Colxx>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ authUser }) => {
  const {
    user: { data }
  } = authUser;
  return {
    shouldChangePassword: data && data.is_password_expired ? data.is_password_expired : false
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(RedirectLayout)
);
