import React from 'react';
import { CellInfo } from 'react-table';

import { ServiceTypes } from '../../operations/OperationsList/constants';

export const MAX_FILE_SIZE = 50;

export enum ServiceTypesNames {
  Income = 'Прием',
  Mc = 'Мобильная коммерция',
  Outcome = 'Выплаты'
}

export const TABLE_ROWS = [
  {
    name: ServiceTypesNames.Income,
    serviceType: ServiceTypes.Income,
    data: null
  },
  {
    name: ServiceTypesNames.Mc,
    serviceType: ServiceTypes.Mc,
    data: null
  },
  {
    name: ServiceTypesNames.Outcome,
    serviceType: ServiceTypes.Outcome,
    data: null
  }
];

export const getTableColumns = (t: any) => [
  {
    expander: true,
    Header: '',
    width: 30,
    Expander: ({ isExpanded }) => (
      <div>{isExpanded ? <i className="simple-icon-arrow-up" /> : <i className="simple-icon-arrow-down" />}</div>
    ),
    style: {
      fontSize: 12,
      padding: '0',
      textAlign: 'center',
      userSelect: 'none'
    }
  },
  {
    Header: '',
    accessor: 'name',
    Cell: ({ value }: CellInfo) => <div className="font-weight-bold">{t(`settings.services.${value}`)}</div>
  }
];
