import React, { Component, Fragment, ReactNode } from 'react';
import { Row, Card, CardTitle, Button } from 'reactstrap';

import { Colxx } from '../components/common/CustomBootstrap';
import IntlMessages from '../helpers/IntlMessages';

class Error extends Component {
  componentDidMount(): void {
    document.body.classList.add('background');
  }

  componentWillUnmount(): void {
    document.body.classList.remove('background');
  }

  render(): ReactNode {
    return (
      <Fragment>
        <div className="fixed-background" />
        <main>
          <div className="container">
            <Row className="h-100">
              <Colxx xxs="12" md="8" className="mx-auto my-auto">
                <Card className="auth-card">
                  <div className="w-100 p-5 error-center">
                    <p className="display-1 font-weight-bold text-center mb-8 error-number">404</p>
                    <CardTitle className="mb-4 error-title text-center">
                      <IntlMessages id="pages.error-title" />
                    </CardTitle>
                    <Button href="/" color="primary" className="btn-shadow" size="lg">
                      <IntlMessages id="pages.go-back-home" />
                    </Button>
                  </div>
                </Card>
              </Colxx>
            </Row>
          </div>
        </main>
      </Fragment>
    );
  }
}
export default Error;
