import React, { PureComponent, ReactNode } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import { ModalFileRequiredProps } from './interface';

class ModalFileIsRequired extends PureComponent<ModalFileRequiredProps> {
  render(): ReactNode {
    const { onCloseModal, onConfirmModal, t } = this.props;

    return (
      <Modal isOpen={true} toggle={onCloseModal}>
        <ModalHeader toggle={onCloseModal}>{t('operations.generate_csv_header')}</ModalHeader>
        <ModalBody>{t('operations.generate_csv_text')}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onConfirmModal}>
            {t('operations.yes')}
          </Button>{' '}
          <Button color="secondary" onClick={onCloseModal}>
            {t('operations.no')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default withTranslation()(ModalFileIsRequired);
