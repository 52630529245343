export enum OperationTypes {
  Income = 1,
  Mc,
  Outcome
}

export const OperationTypeKeys = {
  [OperationTypes.Income]: 'is_income_active',
  [OperationTypes.Outcome]: 'is_outcome_active'
};

export enum DIRECTIONS_VALUES {
  WP = 1,
  PT,
  FP,
  SWP,
  SB,
  CB
}

export const SITE_NAMES = {
  3: 'GACFN',
  4: 'GACWR',
  5: 'GACII'
};

export const OPERATIONS_ACCOUNTS_OPTIONS = (t: any) => ({
  key: '',
  label: t('all'),
  value: null,
  checked: true
});

export enum OperationIncomeDirectionsOptions {
  All,
  Acquiring,
  MobilePay
}

export const OPERATIONS_INCOME_DIRECTIONS_OPTIONS = [
  {
    label: 'Все',
    value: OperationIncomeDirectionsOptions.All,
    source: [DIRECTIONS_VALUES.WP, DIRECTIONS_VALUES.FP, DIRECTIONS_VALUES.SWP, DIRECTIONS_VALUES.SB]
  },
  {
    label: 'Эквайринг',
    value: OperationIncomeDirectionsOptions.Acquiring,
    source: [DIRECTIONS_VALUES.FP, DIRECTIONS_VALUES.SWP, DIRECTIONS_VALUES.SB]
  },
  {
    label: 'MobilePay',
    value: OperationIncomeDirectionsOptions.MobilePay,
    source: [DIRECTIONS_VALUES.WP]
  }
];

export enum SiteNames {
  Fatpay = 'GACFN',
  Secure = 'GACWR',
  Itpartners = 'GACII'
}

export const OPERATIONS_INCOME_SITE_OPTIONS = [
  {
    label: 'Все',
    value: [DIRECTIONS_VALUES.FP, DIRECTIONS_VALUES.SWP, DIRECTIONS_VALUES.SB, DIRECTIONS_VALUES.CB]
  },
  {
    label: SiteNames.Fatpay,
    value: [DIRECTIONS_VALUES.FP]
  },
  {
    label: SiteNames.Secure,
    value: [DIRECTIONS_VALUES.SWP]
  },
  {
    label: SiteNames.Itpartners,
    value: [DIRECTIONS_VALUES.SB]
  }
];

export enum OPERATIONS_TYPE_NAMES {
  INCOME = 'Прием',
  MC = 'Мобильная коммерция',
  OUTCOME = 'Выплаты'
}

export const OPERATIONS_SERVICES_OPTIONS = {
  [OPERATIONS_TYPE_NAMES.INCOME]: {
    key: '1'
  },
  [OPERATIONS_TYPE_NAMES.MC]: {
    key: '2'
  },
  [OPERATIONS_TYPE_NAMES.OUTCOME]: {
    key: '3'
  }
};

enum ACQUIRING_OPERATIONS_TYPES {
  Payment = 1,
  Refund = 4
}

export const ACQUIRING_OPERATIONS_TYPES_OPTIONS = [
  {
    label: 'Все',
    value: [ACQUIRING_OPERATIONS_TYPES.Payment, ACQUIRING_OPERATIONS_TYPES.Refund],
    key: [ACQUIRING_OPERATIONS_TYPES.Payment, ACQUIRING_OPERATIONS_TYPES.Refund]
  },
  {
    label: 'Платеж',
    value: ACQUIRING_OPERATIONS_TYPES.Payment,
    key: ACQUIRING_OPERATIONS_TYPES.Payment
  },
  {
    label: 'Возврат',
    value: ACQUIRING_OPERATIONS_TYPES.Refund,
    key: ACQUIRING_OPERATIONS_TYPES.Refund
  }
];
