export const ROUTE_FALLBACK_LABEL = 'fallback';
export const ROUTE_TMP_CODE_LABEL = 'code';

export const ROUTE_SIGNIN = '/user/login';
export const ROUTE_CHANGE_PASSWORD = '/change-password-strict';

export const TIMEZONE_TOOLTIP_TEXT = 'general.timezone';

export const ACCOUNT_ID = 'a';
export const SHOP_ID = 's';
export const PARTNER_ID = 'p';

export enum ResponseStatusCode {
  Success = 200,
  NotFound = 404,
  Unauthorized = 401
}

export const EMPTY_FIELD_ERROR = 'Поле не может быть пустым';
export const NOT_SELECTED_LABEL = 'Не выбрано';
export const LOGIN_LOGO_SIZE_ERROR = 'Допустимое значение 20 - 150 pixel';
export const LOGO_SIZE_ERROR = 'Допустимое значение 20 - 140 pixel';
export const ZERO_FIELD_ERROR = 'Поле должно быть больше нуля';
export const FILE_TOO_BIG = 'Слишком большой файл ({{filesize}}MiB). Максимальный размер: {{maxFilesize}}MiB.';
export const INVALID_FILE_TYPE = 'Недопустимый формат файла';
export const EMPTY_ACCOUNTS = 'У выбранной компании отсутствуют договора';
export const INVALID_EMAIL_ERROR = 'Неправильный емейл';
export const INVALID_PHONE_ERROR = 'Неправильный телефон';
export const PAYOUT_AMOUNT_ERROR = 'Сумма зачисления от 1,00 до 15 000,00 руб';

export const MAX_PERCENT = 100;

export const HALF_YEAR = 6;

export const DATE_FORMAT_BASIC = 'dd.MM.yyyy';
export const DATE_TIME_FORMAT_WITH_WEEK_DAY = 'eeeeee dd.MM.yyyy';
export const DATE_TIME_FORMAT_WITH_SEC = 'dd.MM.yyyy HH:mm:ss';
export const DATE_TIME_FORMAT = 'dd.MM.yyyy HH:mm';
export const MONTH_FORMAT = 'LLLL';
export const YEAR_FORMAT = 'yyyy';
export const TIME_FORMAT = 'HH:mm';
export const FULL_TIME_FORMAT = 'HH:mm:ss';

export const AMOUNT_FRACTION_DIGITS = 2;

export const DATE_MASK = '99.99.9999';
export const FULL_DATETIME_MASK = '99.99.9999 99:99:99';

export const PHONE_MASK = '+9(999)999-99-99';
export const PHONE_NUMBER_LENGTH = 12;

export const MERCHANT_ID_LENGTH = 19;

export enum CurrenciesOptions {
  ALL,
  RUB,
  EUR
}

export const ALL_CURRENCY_OPTION = (t: any) => [
  {
    key: CurrenciesOptions.ALL,
    label: t('all'),
    value: '',
    checked: true
  }
];

export const RUB_CURRENCY_OPTION = {
  key: CurrenciesOptions.RUB,
  label: 'RUB',
  value: CurrenciesOptions.RUB,
  checked: true
};

export const EUR_CURRENCY_OPTION = {
  key: CurrenciesOptions.EUR,
  label: 'EUR',
  value: CurrenciesOptions.EUR
};

export enum OperationsStatuses {
  Paid = 1,
  NotPaid,
  NotFound,
  InProgress,
  Refund
}

export enum OperationsStatusesNames {
  Paid = 'Оплачен',
  NotPaid = 'Не оплачен',
  NotFound = 'Не найден',
  InProgress = 'В обработке',
  Refund = 'Возврат'
}

export enum IncomePaymentMethodCodes {
  Acquiring = 3,
  Acquiring2 = 4,
  Acquiring3 = 5,
  MobilePay = 1,
  MobilePay1 = 6,
  All = 0
}

export enum DestinationCodes {
  ApplePay = 1,
  GooglePay = 2,
  SamsungPay = 3
}

export const ALL_TREE = (t: any) => ({
  key: 0,
  label: t('all'),
  value: '',
  checked: true,
  expanded: true
});

export const OPERATION_STATUSES_OPTIONS = (t: any) => [
  {
    label: t(OperationsStatusesNames.Paid),
    value: OperationsStatuses.Paid,
    key: 1
  },
  {
    label: t(OperationsStatusesNames.NotPaid),
    value: OperationsStatuses.NotPaid,
    key: 2
  },
  {
    label: t(OperationsStatusesNames.NotFound),
    value: OperationsStatuses.NotFound,
    key: 3
  },
  {
    label: t(OperationsStatusesNames.InProgress),
    value: OperationsStatuses.InProgress,
    key: 4
  },
  {
    label: t(OperationsStatusesNames.Refund),
    value: OperationsStatuses.Refund,
    key: 5
  }
];

export const OPERATION_STATUSES_OPTIONS_WITH_ALL = (t: any) => [
  {
    ...ALL_TREE(t),
    children: [...OPERATION_STATUSES_OPTIONS(t)]
  }
];

export const DIRECTION_OPTIONS = [
  {
    label: 'ApplePay',
    source: [IncomePaymentMethodCodes.MobilePay, IncomePaymentMethodCodes.MobilePay1],
    value: DestinationCodes.ApplePay,
    destination: DestinationCodes.ApplePay
  },
  {
    label: 'GooglePay',
    source: [IncomePaymentMethodCodes.MobilePay, IncomePaymentMethodCodes.MobilePay1],
    value: DestinationCodes.GooglePay,
    destination: DestinationCodes.GooglePay
  },
  {
    label: 'SamsungPay',
    source: [IncomePaymentMethodCodes.MobilePay, IncomePaymentMethodCodes.MobilePay1],
    value: DestinationCodes.SamsungPay,
    destination: DestinationCodes.SamsungPay
  }
];

export const SOURCE_OPTIONS = (t: any) => [
  {
    label: t('all'),
    checked: true,
    expanded: true,
    value: '',
    source: [
      IncomePaymentMethodCodes.Acquiring,
      IncomePaymentMethodCodes.MobilePay,
      IncomePaymentMethodCodes.Acquiring2,
      IncomePaymentMethodCodes.Acquiring3,
      IncomePaymentMethodCodes.MobilePay1
    ],
    children: [
      {
        label: t('operation.acquiring'),
        value: IncomePaymentMethodCodes.Acquiring,
        expanded: true,
        source: IncomePaymentMethodCodes.Acquiring
      },
      {
        label: 'MobilePay',
        value: IncomePaymentMethodCodes.MobilePay,
        expanded: true,
        source: [IncomePaymentMethodCodes.MobilePay, IncomePaymentMethodCodes.MobilePay1],
        children: DIRECTION_OPTIONS,
        destination: [DestinationCodes.ApplePay, DestinationCodes.GooglePay, DestinationCodes.SamsungPay]
      }
    ]
  }
];
