import {
  CHANGE_LOCALE,
  CHANGE_USER,
  CHANGE_MERCHANT,
  GET_SYSTEM_SETTINGS,
  GET_SYSTEM_SETTINGS_SUCCESS,
  GET_SYSTEM_SETTINGS_ERROR,
  PUT_SYSTEM_SETTINGS,
  PUT_SYSTEM_SETTINGS_SUCCESS,
  PUT_SYSTEM_SETTINGS_ERROR,
  GET_CURRENCIES_ERROR,
  GET_CURRENCIES_SUCCESS,
  GET_CURRENCIES,
  GET_PERMISSIONS_SETTINGS,
  GET_PERMISSIONS_SETTINGS_SUCCESS,
  GET_PERMISSIONS_SETTINGS_ERROR,
  PUT_PERMISSIONS_SETTINGS,
  PUT_PERMISSIONS_SETTINGS_SUCCESS,
  PUT_PERMISSIONS_SETTINGS_ERROR,
  GET_ACCOUNT_SETTINGS,
  GET_ACCOUNT_SETTINGS_SUCCESS,
  GET_ACCOUNT_SETTINGS_ERROR,
  PUT_ACCOUNT_SETTINGS,
  PUT_ACCOUNT_SETTINGS_SUCCESS,
  PUT_ACCOUNT_SETTINGS_ERROR,
  RESET_ACCOUNT_SETTINGS_STORE,
  CHANGE_CURRENCY,
  CHANGE_CURRENCY_SUCCESS,
  CHANGE_CURRENCY_ERROR,
  GET_SITES_SETTINGS,
  GET_SITES_SETTINGS_SUCCESS,
  GET_SITES_SETTINGS_ERROR,
  PUT_SITES_SETTINGS,
  PUT_SITES_SETTINGS_SUCCESS,
  PUT_SITES_SETTINGS_ERROR,
  PUT_CUSTOM_DESIGN,
  GET_CUSTOM_DESIGN,
  APPLY_CUSTOM_DESIGN,
  PUT_CUSTOM_DESIGN_ERROR,
  GET_CUSTOM_DESIGN_ERROR,
  DELETE_CUSTOM_DESIGN_ERROR,
  DELETE_CUSTOM_DESIGN,
  DELETE_CUSTOM_DESIGN_SUCCESS,
  RESET_USER_AND_MERCHANT_STORE,
  PUT_SYSTEM_CACHE,
  PUT_SYSTEM_CACHE_SUCCESS,
  PUT_SYSTEM_CACHE_ERROR
} from '../actions';
import { User, Merchant } from '../menu/interface';
import { AccountSettingsValues } from '../../views/settings/Account/interface';

import {
  ChangeLocaleAction,
  ChangeUserAction,
  ChangeMerchantAction,
  SystemSettings,
  GetSystemSettingsAction,
  GetSystemSettingsSuccessAction,
  GetSystemSettingsErrorAction,
  PutSystemSettingsAction,
  PutSystemSettingsSuccessAction,
  PutSystemSettingsErrorAction,
  GetCurrenciesAction,
  GetCurrenciesSuccess,
  GetCurrenciesError,
  GetPermissionsSettingsAction,
  PermissionsSettings,
  GetPermissionsSettingsSuccessAction,
  GetPermissionsSettingsErrorAction,
  PutPermissionsSettingsAction,
  PutPermissionsSettingsSuccessAction,
  PutPermissionsSettingsErrorAction,
  GetAccountSettingsAction,
  GetAccountSettingsSuccessAction,
  GetAccountSettingsErrorAction,
  PutAccountSettingsAction,
  PutAccountSettingsErrorAction,
  PutAccountSettingsSuccessAction,
  ResetAccountSettingsStore,
  ChangeCurrencyError,
  ChangeCurrencySuccess,
  ChangeCurrencyAction,
  ChangeCurrencyData,
  GetSiteSettingsAction,
  GetSiteSettingsErrorAction,
  Site,
  GetSiteSettingsSuccessAction,
  PutSiteSettingsAction,
  PutSiteSettingsSuccessAction,
  PutSiteSettingsErrorAction,
  CustomDesignConfig,
  ApplyCustomDesignAction,
  GetCustomDesignAction,
  PutCustomDesignAction,
  CustomDesignGet,
  PutCustomDesignErrorAction,
  GetCustomDesignErrorAction,
  DeleteCustomDesignAction,
  DeleteCustomDesignErrorAction,
  DeleteCustomDesignSuccessAction,
  ResetUserAndMerchantStore,
  PutSystemCacheAction,
  PutSystemCacheSuccessAction,
  PutSystemCacheErrorAction,
  SystemCache
} from './interface';

// LOCAL SETTINGS
export const settingsChangeLocale = (locale): ChangeLocaleAction => ({ type: CHANGE_LOCALE, payload: { locale } });

export const settingsChangeUser = (selectedUser: User): ChangeUserAction => ({
  type: CHANGE_USER,
  payload: { selectedUser }
});

export const settingsChangeMerchant = (selectedMerchants: Merchant[]): ChangeMerchantAction => ({
  type: CHANGE_MERCHANT,
  payload: { selectedMerchants }
});

// GET CURRENCIES
export const getCurrencies = (): GetCurrenciesAction => ({
  type: GET_CURRENCIES
});

export const getCurrenciesSuccess = (currencies: any): GetCurrenciesSuccess => ({
  type: GET_CURRENCIES_SUCCESS,
  payload: {
    currencies
  }
});

export const getCurrenciesError = (error: string): GetCurrenciesError => ({
  type: GET_CURRENCIES_ERROR,
  payload: { error }
});

// CHANGE CURRENCY
export const changeCurrencies = (payload: ChangeCurrencyData): ChangeCurrencyAction => ({
  type: CHANGE_CURRENCY,
  payload
});

export const changeCurrenciesSuccess = (): ChangeCurrencySuccess => ({
  type: CHANGE_CURRENCY_SUCCESS
});

export const changeCurrenciesError = (error: string): ChangeCurrencyError => ({
  type: CHANGE_CURRENCY_ERROR,
  payload: { error }
});

// GCM SETTINGS
export const getSystemSettings = (): GetSystemSettingsAction => ({
  type: GET_SYSTEM_SETTINGS
});

export const getSystemSettingsSuccess = (gcmSettings: SystemSettings): GetSystemSettingsSuccessAction => ({
  type: GET_SYSTEM_SETTINGS_SUCCESS,
  payload: { gcmSettings }
});

export const getSystemSettingsError = (error: string): GetSystemSettingsErrorAction => ({
  type: GET_SYSTEM_SETTINGS_ERROR,
  payload: { error }
});

export const putSystemSettings = (gcmSettings: SystemSettings): PutSystemSettingsAction => ({
  type: PUT_SYSTEM_SETTINGS,
  payload: gcmSettings
});

export const putSystemSettingsSuccess = (): PutSystemSettingsSuccessAction => ({
  type: PUT_SYSTEM_SETTINGS_SUCCESS
});

export const putSystemSettingsError = (error: string): PutSystemSettingsErrorAction => ({
  type: PUT_SYSTEM_SETTINGS_ERROR,
  payload: { error }
});

export const putSystemCache = (cacheType: SystemCache): PutSystemCacheAction => ({
  type: PUT_SYSTEM_CACHE,
  payload: cacheType
});

export const putSystemCacheSuccess = (): PutSystemCacheSuccessAction => ({
  type: PUT_SYSTEM_CACHE_SUCCESS
});

export const putSystemCacheError = (error: string): PutSystemCacheErrorAction => ({
  type: PUT_SYSTEM_CACHE_ERROR,
  payload: { error }
});

// PERMISSIONS SETTINGS
export const getPermissionsSettings = (id: string): GetPermissionsSettingsAction => ({
  type: GET_PERMISSIONS_SETTINGS,
  payload: {
    id
  }
});

export const getPermissionsSettingsSuccess = (
  permissionsSettings: PermissionsSettings
): GetPermissionsSettingsSuccessAction => ({
  type: GET_PERMISSIONS_SETTINGS_SUCCESS,
  payload: { permissionsSettings }
});

export const getPermissionsSettingsError = (error: string): GetPermissionsSettingsErrorAction => ({
  type: GET_PERMISSIONS_SETTINGS_ERROR,
  payload: { error }
});

export const putPermissionsSettings = (id: string, permissions: PermissionsSettings): PutPermissionsSettingsAction => ({
  type: PUT_PERMISSIONS_SETTINGS,
  payload: {
    id,
    permissions
  }
});

export const putPermissionsSettingsSuccess = (
  permissions: PermissionsSettings
): PutPermissionsSettingsSuccessAction => ({
  type: PUT_PERMISSIONS_SETTINGS_SUCCESS,
  payload: {
    permissions
  }
});

export const putPermissionsSettingsError = (error: string): PutPermissionsSettingsErrorAction => ({
  type: PUT_PERMISSIONS_SETTINGS_ERROR,
  payload: { error }
});

// SITES SETTINGS

export const getUserSiteSettings = (id: string): GetSiteSettingsAction => ({
  type: GET_SITES_SETTINGS,
  payload: {
    id
  }
});

export const getSitesSettingsSuccess = (userSites: Site[]): GetSiteSettingsSuccessAction => ({
  type: GET_SITES_SETTINGS_SUCCESS,
  payload: { userSites }
});

export const getSitesSettingsError = (error: string): GetSiteSettingsErrorAction => ({
  type: GET_SITES_SETTINGS_ERROR,
  payload: { error }
});

export const putSitesSettings = (id: string, userSites: Site[]): PutSiteSettingsAction => ({
  type: PUT_SITES_SETTINGS,
  payload: {
    id,
    userSites
  }
});

export const putSitesSettingsSuccess = (userSites: Site[]): PutSiteSettingsSuccessAction => ({
  type: PUT_SITES_SETTINGS_SUCCESS,
  payload: {
    userSites
  }
});

export const putSitesSettingsError = (error: string): PutSiteSettingsErrorAction => ({
  type: PUT_SITES_SETTINGS_ERROR,
  payload: { error }
});

// ACCOUNT SETTINGS
export const getAccountSettings = (id: string): GetAccountSettingsAction => ({
  type: GET_ACCOUNT_SETTINGS,
  payload: { id }
});

export const getAccountSettingsSuccess = (data: AccountSettingsValues): GetAccountSettingsSuccessAction => ({
  type: GET_ACCOUNT_SETTINGS_SUCCESS,
  payload: { data }
});

export const getAccountSettingsError = (error: string): GetAccountSettingsErrorAction => ({
  type: GET_ACCOUNT_SETTINGS_ERROR,
  payload: { error }
});

export const putAccountSettings = (id: string, settings: AccountSettingsValues): PutAccountSettingsAction => ({
  type: PUT_ACCOUNT_SETTINGS,
  payload: { id, settings }
});

export const putAccountSettingsSuccess = (): PutAccountSettingsSuccessAction => ({
  type: PUT_ACCOUNT_SETTINGS_SUCCESS
});

export const putAccountSettingsError = (error: string): PutAccountSettingsErrorAction => ({
  type: PUT_ACCOUNT_SETTINGS_ERROR,
  payload: { error }
});

export const resetAccountSettingsStore = (): ResetAccountSettingsStore => ({
  type: RESET_ACCOUNT_SETTINGS_STORE
});

// CUSTOM DESIGN
export const putCustomDesign = (customDesign: CustomDesignConfig): PutCustomDesignAction => ({
  type: PUT_CUSTOM_DESIGN,
  payload: customDesign
});

export const putCustomDesignError = (error: string): PutCustomDesignErrorAction => ({
  type: PUT_CUSTOM_DESIGN_ERROR,
  payload: { error }
});

export const getCustomDesign = (payload: CustomDesignGet): GetCustomDesignAction => ({
  type: GET_CUSTOM_DESIGN,
  payload
});

export const applyCustomDesign = (customDesign: CustomDesignConfig): ApplyCustomDesignAction => ({
  type: APPLY_CUSTOM_DESIGN,
  payload: customDesign
});

export const getCustomDesignError = (error: string): GetCustomDesignErrorAction => ({
  type: GET_CUSTOM_DESIGN_ERROR,
  payload: { error }
});

export const deleteCustomDesign = (domain: string): DeleteCustomDesignAction => ({
  type: DELETE_CUSTOM_DESIGN,
  payload: { domain }
});

export const deleteCustomDesignSuccess = (): DeleteCustomDesignSuccessAction => ({
  type: DELETE_CUSTOM_DESIGN_SUCCESS
});

export const deleteCustomDesignError = (error: string): DeleteCustomDesignErrorAction => ({
  type: DELETE_CUSTOM_DESIGN_ERROR,
  payload: { error }
});

export const resetUserAndMerchantStore = (): ResetUserAndMerchantStore => ({
  type: RESET_USER_AND_MERCHANT_STORE
});
