import {
  USER_CHANGE_PASSWORD,
  USER_CHANGE_PASSWORD_SUCCESS,
  USER_CHANGE_PASSWORD_ERROR,
  USER_UPDATE_PASSWORD,
  USER_UPDATE_PASSWORD_SUCCESS,
  USER_UPDATE_PASSWORD_ERROR
} from '../actions';
import { ProfileReducerState } from './interface';

const INIT_STATE: ProfileReducerState = {
  error: '',
  status: undefined,
  loading: false
};

export default (state: ProfileReducerState = INIT_STATE, { type, payload }): ProfileReducerState => {
  switch (type) {
    case USER_CHANGE_PASSWORD:
    case USER_UPDATE_PASSWORD:
      return {
        ...state,
        loading: true,
        error: '',
        status: undefined
      };
    case USER_CHANGE_PASSWORD_SUCCESS:
    case USER_CHANGE_PASSWORD_ERROR:
    case USER_UPDATE_PASSWORD_SUCCESS:
    case USER_UPDATE_PASSWORD_ERROR:
      return {
        ...state,
        ...payload,
        loading: false
      };
    default:
      return { ...state };
  }
};
