import React, { PureComponent, ReactNode } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ModalFileConfirmProps } from '../../app/interface';

class ModalReset2FaConfirm extends PureComponent<ModalFileConfirmProps> {
  onConfirm = () => {
    const { onCloseModal, onConfirmModal } = this.props;

    onConfirmModal();
    onCloseModal();
  };

  render(): ReactNode {
    const { onCloseModal, t } = this.props;

    return (
      <Modal isOpen={true} toggle={onCloseModal}>
        <ModalHeader toggle={onCloseModal}>{t('second_factor.reset')}</ModalHeader>
        <ModalBody>{t('second_factor.are_you_sure')}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.onConfirm}>
            {t('yes')}
          </Button>{' '}
          <Button color="secondary" onClick={onCloseModal}>
            {t('no')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default withTranslation()(ModalReset2FaConfirm);
