import React, { Component, ReactNode, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Card, CardBody, Row } from 'reactstrap';
import ReactTable from 'react-table';

import { changeDisposalStatus, deleteDisposal, getDisposals } from '../../redux/disposals/actions';
import { Colxx } from '../../components/common/CustomBootstrap';
import { NotFoundMessage } from '../../components/common/NotFoundMessage';
import DataTablePagination from '../../components/DatatablePagination';
import ModalConfirmStatusChange from './ModalConfirmStatusChange';
import ModalConfirmDelete from './ModalConfirmDelete';
import FilterPanel from './FilterPanel';
import ModalFileConfirm from '../app/ModalFileConfirm';
import ModalFileIsCreated from '../app/ModalFileIsCreated';
import { withTranslation } from 'react-i18next';
import { getDisposalsTableColumns, getPartnerOption, prepareSubmitValues } from './helpers';
import { DisposalStatus, DISPOSAL_LIST_FILTER_INITIAL_VALUES, DEFAULT_SORTER } from './constants';
import { DisposalsProps, DisposalsState } from './interface';
import { getCurrencies } from '../../redux/settings/actions';
import { isAllowed } from '../../helpers/Access';
import isEqual from 'lodash/isEqual';
import { getPayoutAccounts } from '../../redux/operations/actions';

const DISPOSAL_MANAGER_PERMISSION_CODE = 20;

class Disposals extends Component<DisposalsProps, DisposalsState> {
  newValues;
  state: DisposalsState = {
    page: 0,
    selectedPartner: null,
    isCloseModalOpen: false,
    isReloadDisposals: false,
    isChangeModalOpen: false,
    mustBeDeleted: [],
    mustBeChanged: null,
    selectedDisposal: null,
    canManage: false,
    flagReset: false,
    fileRequired: false,
    selectedDisposals: [],
    fileCreated: false
  };

  componentDidMount(): void {
    const { selectedUser, user, isAdmin, merchantIdList, getPayoutAccountsAction } = this.props;
    const currentUser = selectedUser ? selectedUser : user;
    this.props.getCurrenciesAction();
    if (currentUser) {
      this.setState({ canManage: !isAdmin && isAllowed(currentUser, DISPOSAL_MANAGER_PERMISSION_CODE) });
    }
    if (merchantIdList && merchantIdList.length) {
      getPayoutAccountsAction(merchantIdList);
    }
  }

  componentDidUpdate(prevProps: any): void {
    const {
      selectedMerchants,
      loading,
      selectedUser,
      isAdmin,
      selectedUserId,
      merchantIdList,
      authUserId,
      getPayoutAccountsAction,
      t
    } = this.props;
    const { selectedPartner, isReloadDisposals } = this.state;

    const selMerchantIds = selectedMerchants && selectedMerchants.length ? selectedMerchants.map(({ id }) => id) : [];
    if (
      selMerchantIds.length &&
      (!selectedPartner || selMerchantIds.indexOf(selectedPartner.key) === -1) &&
      !isEqual(selectedPartner, getPartnerOption(selectedMerchants, t)[0])
    ) {
      this.setState({ selectedPartner: getPartnerOption(selectedMerchants, t)[0] });
    }

    if (isReloadDisposals && !loading) {
      this.setState({ isReloadDisposals: false });
      this.getDisposals(this.newValues || DISPOSAL_LIST_FILTER_INITIAL_VALUES);
    }

    if (
      prevProps.selectedUser !== selectedUser ||
      (prevProps.selectedUser && selectedUser && prevProps.selectedUser.id !== selectedUser.id)
    ) {
      this.setState({ canManage: !isAdmin && isAllowed(selectedUser, DISPOSAL_MANAGER_PERMISSION_CODE) });
    }

    if (
      selectedUser &&
      selectedMerchants &&
      (!isEqual(prevProps.selectedUser, selectedUser) || !isEqual(prevProps.selectedMerchants, selectedMerchants))
    ) {
      this.setState({ flagReset: true });
    }

    if (!isEqual(prevProps.merchantIdList, merchantIdList) || prevProps.selectedUserId !== selectedUserId) {
      if (merchantIdList && merchantIdList.length) {
        getPayoutAccountsAction(
          merchantIdList,
          isAdmin && selectedUserId && authUserId !== selectedUserId ? selectedUserId : undefined
        );
      }
    }
  }

  getDisposals = (newValues) => {
    this.newValues = newValues;
    const { getDisposalsAction, selectedUser, user } = this.props;
    const currentUser = selectedUser ? selectedUser : user;

    getDisposalsAction(currentUser.id, prepareSubmitValues(newValues));

    this.setState({ selectedDisposals: [], selectedDisposal: null, flagReset: false });
  };

  handleChangeDisposal = (status: DisposalStatus, id: number = 0) => {
    const { selectedDisposal, selectedDisposals } = this.state;
    const disposalID = id || (selectedDisposal ? selectedDisposal.id : 0);
    const comment = selectedDisposal ? String(selectedDisposal.comment) : '';
    disposalID &&
      this.setState({
        isChangeModalOpen: true,
        mustBeChanged: {
          id: selectedDisposals,
          comment,
          status
        }
      });
  };

  selectDisposal = (id: number, checked: boolean) => {
    let { selectedDisposals } = this.state;
    if (checked) {
      selectedDisposals.push(id);
    } else {
      selectedDisposals = selectedDisposals.filter((item) => {
        return item !== id;
      });
    }

    this.setState({ selectedDisposals });
  };

  selectRow = (state, rowInfo) => {
    const { selectedDisposal } = this.state;
    const id = rowInfo ? rowInfo.original.id : null;

    return {
      onClick: () => {
        this.setState({ selectedDisposal: rowInfo.original });
      },
      className:
        selectedDisposal && selectedDisposal.id === id && selectedDisposal.id !== null ? 'table-row-selected' : ''
    };
  };

  setNewPage = (pageNumber: number) => this.setState({ page: pageNumber });

  exportFile = () => {
    const { data } = this.props;
    if (data && data.length) {
      this.setState({ fileRequired: true });
    }
  };

  onConfirmFileModal = (values: any) => () => {
    const { getDisposalsAction, selectedUser, user } = this.props;
    const currentUser = selectedUser ? selectedUser : user;

    this.setState({ fileRequired: false, fileCreated: true }, () => {
      getDisposalsAction(currentUser.id, prepareSubmitValues(values, true));
    });
  };

  render(): ReactNode {
    const {
      currencies,
      data,
      loading,
      deleteDisposalAction,
      maxPageSize,
      isAdmin,
      changeDisposalStatusAction,
      selectedMerchants,
      accounts,
      t
    } = this.props;
    const {
      selectedPartner,
      isCloseModalOpen,
      mustBeDeleted,
      page,
      mustBeChanged,
      isChangeModalOpen,
      canManage,
      flagReset,
      selectedDisposals,
      fileRequired,
      fileCreated
    } = this.state;

    const currentPageData = data && data.length ? data : [];
    const elementsLeft = currentPageData ? currentPageData.length - page * maxPageSize : 0;
    const pageSize = elementsLeft >= maxPageSize ? maxPageSize : elementsLeft;
    let accountsHash = {};
    if (accounts) {
      accountsHash = Object.values(accounts)
        .flat()
        .reduce((set, currentValue) => {
          set[currentValue.id] = currentValue.name;
          return set;
        }, {});
    }

    return (
      <>
        {selectedMerchants && selectedMerchants.length > 0 && (
          <Row>
            <Colxx xxs="12">
              <FilterPanel
                currencies={currencies}
                selectedMerchants={selectedMerchants}
                onSubmit={(newValues: any) => this.getDisposals(newValues)}
                exportFile={this.exportFile}
                showExportButton={Boolean(data && data.length)}
                shouldResetForm={true}
                selectedPartner={selectedPartner}
                canManage={canManage}
              />
            </Colxx>
          </Row>
        )}

        {!(selectedMerchants && selectedMerchants.length) && <NotFoundMessage text={t('no_partners_selected')} />}

        {!flagReset && selectedMerchants && selectedMerchants.length > 0 && data && data.length > 0 && (
          <Row>
            <Colxx xxs="12" className="mb-4">
              <Card className="mb-4">
                <CardBody>
                  <ReactTable
                    data={currentPageData}
                    columns={getDisposalsTableColumns(
                      isAdmin,
                      currencies,
                      selectedMerchants,
                      accountsHash,
                      t,
                      this.selectDisposal,
                      selectedDisposals
                    )}
                    page={page}
                    pages={Math.ceil(data.length / maxPageSize)}
                    defaultPageSize={pageSize}
                    pageSize={pageSize}
                    sortable={true}
                    filterable={false}
                    showPageJump={true}
                    showPagination={true}
                    defaultSorted={DEFAULT_SORTER}
                    PaginationComponent={DataTablePagination}
                    onPageChange={this.setNewPage}
                    getTrProps={this.selectRow}
                    showPageSizeOptions={false}
                    loading={loading}
                    loadingText=""
                    noDataText={t('select.no data')}
                  />

                  <Row className="mt-3 mb-1">
                    <Colxx xxs="12" className="d-flex justify-content-end">
                      <Button
                        color="primary"
                        type="submit"
                        className="btn-submit mr-3"
                        onClick={() => this.handleChangeDisposal(DisposalStatus.Canceled)}
                        disabled={!selectedDisposals.length}
                      >
                        {t('button.Decline')}
                      </Button>
                      {isAdmin && (
                        <Fragment>
                          <Button
                            color="primary"
                            type="button"
                            className="btn-submit mr-3"
                            onClick={() => this.handleChangeDisposal(DisposalStatus.Accepted)}
                            disabled={!Boolean(selectedDisposals.length)}
                          >
                            {t('button.Processing')}
                          </Button>
                          <Button
                            color="primary"
                            type="button"
                            className="btn-submit"
                            onClick={() => this.handleChangeDisposal(DisposalStatus.Completed)}
                            disabled={!Boolean(selectedDisposals.length)}
                          >
                            {t('button.Processed')}
                          </Button>
                        </Fragment>
                      )}
                    </Colxx>
                  </Row>
                </CardBody>
              </Card>
            </Colxx>
          </Row>
        )}

        {isCloseModalOpen && mustBeDeleted && (
          <ModalConfirmDelete
            onCloseModal={() => this.setState({ isCloseModalOpen: false })}
            onConfirmModal={deleteDisposalAction}
            mustBeDeleted={mustBeDeleted}
          />
        )}

        {isChangeModalOpen && mustBeChanged && (
          <ModalConfirmStatusChange
            onCloseModal={() => this.setState({ isChangeModalOpen: false })}
            onConfirmModal={(id: number[], status: number, comment: string) => {
              this.setState({ isReloadDisposals: true });
              return changeDisposalStatusAction(id, status, comment);
            }}
            mustBeChanged={mustBeChanged}
          />
        )}

        {fileRequired && (
          <ModalFileConfirm
            onCloseModal={() => this.setState({ fileRequired: false })}
            onConfirmModal={this.onConfirmFileModal(this.newValues)}
          />
        )}
        {fileCreated && <ModalFileIsCreated onCloseModal={() => this.setState({ fileCreated: false })} />}
        {data && !data.length && <NotFoundMessage />}
      </>
    );
  }
}

const mapStateToProps = ({ authUser, disposals, settings, operations }) => ({
  user: authUser.user.data ? authUser.user.data : null,
  isAdmin: authUser.user.data ? authUser.user.data.is_admin : false,
  data: disposals.data,
  selectedUserId: settings.selectedUser ? settings.selectedUser.id : 0,
  authUserId: authUser.user.data ? authUser.user.data.id : 0,
  merchantIdList: settings.selectedMerchantsId,
  currencies: settings.currencies.data,
  maxPageSize: settings.gcmSettings.data ? settings.gcmSettings.data.max_page_size : 50,
  loading: disposals.loading,
  selectedMerchants: settings.selectedMerchants,
  selectedUser: settings.selectedUser,
  accounts: operations.payoutAccounts.data
});

const mapDispatchToProps = {
  getPayoutAccountsAction: getPayoutAccounts,
  getCurrenciesAction: getCurrencies,
  getDisposalsAction: getDisposals,
  deleteDisposalAction: deleteDisposal,
  changeDisposalStatusAction: changeDisposalStatus
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Disposals)
);
