import { all } from 'redux-saga/effects';

import authSagas from './authUser/saga';
import menuSagas from './menu/saga';
import settingsSagas from './settings/saga';
import operationsSagas from './operations/saga';
import servicesSagas from './services/saga';
import glossarySagas from './glossary/saga';
import profileSagas from './profile/saga';
import documentsSagas from './documents/saga';
import disposalsSagas from './disposals/saga';
import statisticsSagas from './statistics/saga';
import supportSagas from './support/saga';
import downloadsSagas from './downloads/saga';
import notificationsSagas from './notifications/saga';
import logsSagas from './journal/saga';
import appSagas from './app/saga';
import sitesSagas from './sites/saga';
import balanceSagas from './balance/saga';

export default function* rootSaga(): Generator {
  yield all([
    authSagas(),
    menuSagas(),
    settingsSagas(),
    operationsSagas(),
    servicesSagas(),
    glossarySagas(),
    profileSagas(),
    documentsSagas(),
    disposalsSagas(),
    statisticsSagas(),
    supportSagas(),
    downloadsSagas(),
    logsSagas(),
    appSagas(),
    sitesSagas(),
    notificationsSagas(),
    appSagas(),
    balanceSagas()
  ]);
}
