import { ThemeColors } from '../../../helpers/ThemeColors';

import { PreparedData, ChartData } from './interface';

const colors = ThemeColors();

export const prepareDataForChart = (data: PreparedData[], t: any): ChartData => {
  const chartData: any = {
    labels: [],
    datasets: [
      {
        label: '',
        borderWidth: 2,
        data: [],
        backgroundColor: [
          colors.themeColor1,
          '#7d9c52',
          '#51b5d9',
          '#2e94cf',
          colors.themeColor5,
          '#751dcf',
          '#e2863b',
          colors.themeColor2,
          '#fc8caa',
          colors.themeColor4,
          colors.themeColor6
        ]
      }
    ]
  };
  data.forEach(({ country, percentage }: { country: string; percentage: number }): void => {
    chartData.labels.push(t(country));
    chartData.datasets[0].data.push(percentage);
  });
  return chartData;
};
