import React from 'react';
import { CellInfo } from 'react-table';
import { differenceInSeconds, addSeconds } from 'date-fns';
import format from '../../../localization';
import { roundDigit, setDirectionsData, toISOString } from '../../../helpers/Utils';
import { TreeNode } from 'react-dropdown-tree-select';
import {
  CurrenciesData,
  OperationsFileRequestValues,
  OperationsListData,
  OperationsListRequestValues,
  PayoutAccount
} from '../../../redux/operations/interface';
import { DIRECTIONS_VALUES, OperationIncomeDirectionsOptions, OPERATIONS_ACCOUNTS_OPTIONS } from '../constants';
import { DestinationKeys, ServiceTypes } from './constants';
import { IOperationsListFilterValues, IOperationsListProps } from './interface';
import {
  ALL_CURRENCY_OPTION,
  IncomePaymentMethodCodes,
  RUB_CURRENCY_OPTION,
  ACCOUNT_ID,
  SHOP_ID,
  PARTNER_ID,
  DATE_TIME_FORMAT_WITH_SEC,
  OperationsStatusesNames,
  OperationsStatuses,
  ALL_TREE
} from '../../../constants/app';
import { getIncomeShopNames } from '../../statistics/helpers';

export const prepareRowsData = (rowsData: OperationsListData[]): OperationsListData[] =>
  rowsData ? rowsData.map((row: any) => ({ ...row, amount: row.amount })) : [];

const getSource = (serviceType, direction, site) => {
  switch (serviceType) {
    case ServiceTypes.Income:
      if (
        direction.value === OperationIncomeDirectionsOptions.All ||
        (direction.value.hasOwnProperty('source') && direction.value.source.includes(DIRECTIONS_VALUES.WP))
      ) {
        if (site && typeof site.label === 'undefined') {
          return site.value;
        } else if (site) {
          return [DIRECTIONS_VALUES.WP, ...site.value];
        } else {
          return undefined;
        }
      } else {
        return site ? site.value : undefined;
      }

    case ServiceTypes.Mc:
      return [DIRECTIONS_VALUES.PT, DIRECTIONS_VALUES.CB];

    case ServiceTypes.Outcome:
      return [DIRECTIONS_VALUES.WP, DIRECTIONS_VALUES.CB];

    default:
      return [
        DIRECTIONS_VALUES.WP,
        DIRECTIONS_VALUES.SWP,
        DIRECTIONS_VALUES.FP,
        DIRECTIONS_VALUES.SB,
        DIRECTIONS_VALUES.PT
      ];
  }
};

export const getAccount = (account) => {
  if (account && account.value && account.value.length) {
    return account.value.map(({ id }) => Number(id));
  } else {
    return '';
  }
};

export const getOperationsShopNames = (selectedAccounts, allAccounts, serviceType: ServiceTypes) => {
  const filteredAccounts = getIncomeShopNames(selectedAccounts, allAccounts);
  return filteredAccounts && serviceType === ServiceTypes.Income ? filteredAccounts : [];
};

export const prepareOperationsListRequestValues = (
  values: IOperationsListFilterValues,
  props: IOperationsListProps,
  page = 0,
  sorting = '',
  pageSize = 50
): OperationsListRequestValues => {
  const {
    dateFrom,
    dateTo,
    account,
    direction,
    card_number,
    currency,
    status,
    orderNumber,
    pid,
    amount,
    site,
    byEndDate
  } = values;
  const { serviceType, merchantIdList, isAdmin, authUserId, selectedUserId, accounts } = props;
  const partnerAccountParam = {};
  const diffInSec = differenceInSeconds(dateTo, new Date());

  let destination;
  if (direction) {
    if (direction.value === 0) {
      destination = [DIRECTIONS_VALUES.WP, DIRECTIONS_VALUES.PT, DIRECTIONS_VALUES.FP];
    } else {
      destination = direction.value.destination;
    }
  }

  let source = getSource(serviceType, direction, site);
  if (direction && direction.key !== 1) {
    source = direction.value.source;
  }

  if (account && account.value && account.value.length) {
    partnerAccountParam[ACCOUNT_ID] = account.value.map(({ account_id }) => account_id);
    partnerAccountParam[SHOP_ID] = account.value.map(({ shop_id }) => shop_id);
  } else {
    partnerAccountParam[PARTNER_ID] = merchantIdList;
  }

  return {
    ...partnerAccountParam,
    user_id: isAdmin && selectedUserId && authUserId !== selectedUserId ? selectedUserId : undefined,
    from: toISOString(dateFrom),
    to: diffInSec ? toISOString(addSeconds(dateTo, 1)) : toISOString(dateTo),
    service_type: serviceType,
    status: status ? status.value : undefined,
    source,
    destination,
    card_number,
    order_number: orderNumber,
    currency: currency.value,
    pid,
    amount: amount ? parseFloat(amount) : undefined,
    by_end_date: byEndDate,
    shop_name: accounts.data ? getOperationsShopNames(account.value, accounts.data[serviceType], serviceType) : [],
    offset: page * pageSize,
    limit: pageSize,
    sorting
  };
};

export const prepareOperationsFileRequestValues = (
  values: IOperationsListFilterValues,
  props: IOperationsListProps
): OperationsFileRequestValues => {
  const {
    dateFrom,
    dateTo,
    account,
    direction,
    currency,
    status,
    site,
    byEndDate,
    amount,
    pid,
    orderNumber,
    card_number
  } = values;
  const { serviceType, merchantIdList, isAdmin, authUserId, selectedUserId, accounts } = props;
  const diffInSec = differenceInSeconds(dateTo, new Date());

  const destinationValue =
    direction && direction.value === OperationIncomeDirectionsOptions.MobilePay
      ? [DestinationKeys.ApplePay, DestinationKeys.GooglePay, DestinationKeys.SamsungPay]
      : direction && direction.value.destination
      ? direction.value.destination
      : [];
  return {
    partner_id: merchantIdList && merchantIdList.length ? merchantIdList : [],
    user_id: isAdmin && selectedUserId && authUserId !== selectedUserId ? selectedUserId : undefined,
    from: toISOString(dateFrom),
    to: diffInSec ? toISOString(addSeconds(dateTo, 1)) : toISOString(dateTo),
    service_type: serviceType,
    card_number,
    sources: getSource(serviceType, direction, site),
    statuses: status && status.value && status.value.length ? status.value : [],
    accounts: account && account.value && account.value.length ? account.value.map(({ account_id }) => account_id) : [],
    destinations: destinationValue,
    currency: currency && typeof currency.value !== 'undefined' && Array.isArray(currency.value) ? currency.value : [],
    by_end_date: byEndDate,
    shop_names: getOperationsShopNames(account.value, accounts.data[serviceType], serviceType),
    amount: amount === '' ? null : Number(amount),
    pid,
    order_number: orderNumber && orderNumber.length ? orderNumber.map(Number) : []
  };
};

export const getOneLevelSelectedData = (selectedValues: TreeNode[]) => {
  if (selectedValues && selectedValues.length === 1 && selectedValues[0].value === '') {
    return selectedValues[0].value;
  } else {
    return selectedValues && selectedValues.length
      ? { value: selectedValues.map((item: TreeNode) => item.value).flat() }
      : null;
  }
};

export const getAccountOptions = (accounts: PayoutAccount[], t: any) => {
  if (accounts && accounts.length) {
    return [
      {
        key: 1,
        label: t('all'),
        value: '',
        checked: true,
        expanded: true,
        children: [
          ...accounts.map(({ name, id, source, shop_id }) => ({
            label: name,
            key: `${id}_${name}`,
            value: { account_id: id, source, name, shop_id }
          }))
        ]
      }
    ];
  } else {
    return [OPERATIONS_ACCOUNTS_OPTIONS(t)];
  }
};

export const getCurrenciesOptions = (currencies: CurrenciesData[], serviceType: ServiceTypes, t: any) => {
  if (serviceType === ServiceTypes.Mc) {
    return [RUB_CURRENCY_OPTION];
  }

  if (currencies && currencies.length) {
    return [
      {
        key: 1,
        label: t('all'),
        value: '',
        checked: true,
        expanded: true,
        children: [
          ...currencies.map(({ name, id }) => ({
            label: name,
            value: id
          }))
        ]
      }
    ];
  } else {
    return ALL_CURRENCY_OPTION(t);
  }
};

export const isSiteDisabled = (directionValue): boolean =>
  directionValue &&
  directionValue.value &&
  directionValue.value.source &&
  directionValue.value.source.find((item: number) => item === IncomePaymentMethodCodes.MobilePay) &&
  !directionValue.value.source.find((item: number) => item === IncomePaymentMethodCodes.Acquiring) &&
  !directionValue.value.source.find((item: number) => item === IncomePaymentMethodCodes.Acquiring2);

export const onDirectionChange = (direction, selectedValues, setFieldValue) => {
  const directionValue = setDirectionsData(selectedValues);
  setFieldValue('direction', directionValue);
};

export const getOperationListTableColumns = (t: any, type) => {
  const data = {
    [ServiceTypes.Income]: [
      {
        Header: t('filter.create_date'),
        accessor: 'order_time',
        Cell: ({ value }: CellInfo) => format(value, DATE_TIME_FORMAT_WITH_SEC)
      },
      {
        Header: t('filter.finish_date'),
        accessor: 'order_end_time',
        Cell: ({ value }: CellInfo) => format(value, DATE_TIME_FORMAT_WITH_SEC)
      },
      {
        Header: t('filter.order_number'),
        accessor: 'order_number'
      },
      {
        Header: t('filter.pid'),
        accessor: 'pid'
      },
      {
        Header: t('filter.status'),
        accessor: 'status',
        Cell: ({ value }: CellInfo) => t(OperationsStatusesNames[OperationsStatuses[value]]) || t(value)
      },
      {
        Header: t('filter.amount'),
        accessor: 'amount',
        Cell: ({ value }: CellInfo) => roundDigit(value)
      },
      {
        Header: t('filter.currency'),
        accessor: 'currency'
      },
      {
        expander: true,
        Header: '',
        width: 30,
        Expander: ({ isExpanded }) => (
          <div>{isExpanded ? <i className="simple-icon-arrow-up" /> : <i className="simple-icon-arrow-down" />}</div>
        ),
        style: {
          fontSize: 12,
          padding: '0',
          textAlign: 'center',
          userSelect: 'none'
        }
      }
    ],
    [ServiceTypes.Mc]: [
      {
        Header: t('filter.operation_datetime'),
        accessor: 'order_time',
        Cell: ({ value }: CellInfo) => format(value, DATE_TIME_FORMAT_WITH_SEC)
      },
      {
        Header: t('filter.order_number'),
        accessor: 'order_number'
      },
      {
        Header: t('filter.pid'),
        accessor: 'pid'
      },
      {
        Header: t('filter.status'),
        accessor: 'status',
        Cell: ({ value }: CellInfo) => t(OperationsStatusesNames[OperationsStatuses[value]]) || t(value)
      },
      {
        Header: t('filter.amount'),
        accessor: 'amount',
        Cell: ({ value }: CellInfo) => roundDigit(value)
      },
      {
        expander: true,
        Header: '',
        width: 30,
        Expander: ({ isExpanded }) => (
          <div>{isExpanded ? <i className="simple-icon-arrow-up" /> : <i className="simple-icon-arrow-down" />}</div>
        ),
        style: {
          fontSize: 12,
          padding: '0',
          textAlign: 'center',
          userSelect: 'none'
        }
      }
    ],
    [ServiceTypes.Outcome]: [
      {
        Header: t('filter.create_date'),
        accessor: 'order_time',
        Cell: ({ value }: CellInfo) => format(value, DATE_TIME_FORMAT_WITH_SEC)
      },
      {
        Header: t('filter.finish_date'),
        accessor: 'order_end_time',
        Cell: ({ value }: CellInfo) => format(value, DATE_TIME_FORMAT_WITH_SEC)
      },
      {
        Header: t('filter.order_number'),
        accessor: 'order_number'
      },
      {
        Header: t('filter.pid'),
        accessor: 'pid'
      },
      {
        Header: t('filter.status'),
        accessor: 'status',
        Cell: ({ value }: CellInfo) => t(value)
      },
      {
        Header: t('filter.amount'),
        accessor: 'amount',
        Cell: ({ value }: CellInfo) => roundDigit(value)
      },
      {
        Header: t('filter.currency'),
        accessor: 'currency'
      },
      {
        expander: true,
        Header: '',
        width: 30,
        Expander: ({ isExpanded }) => (
          <div>{isExpanded ? <i className="simple-icon-arrow-up" /> : <i className="simple-icon-arrow-down" />}</div>
        ),
        style: {
          fontSize: 12,
          padding: '0',
          textAlign: 'center',
          userSelect: 'none'
        }
      }
    ]
  };
  return data[type];
};

export const getAvailableSitesOptions = (options, t: any) => {
  if (options && options.length) {
    return [
      {
        ...ALL_TREE(t),
        value: options.map(({ source_id }, index) => source_id),
        children: [
          ...options.map(({ title, source_id }, index) => ({
            key: `${title}_${index}`,
            label: title,
            value: source_id
          }))
        ]
      }
    ];
  } else {
    return [ALL_TREE(t)];
  }
};
