import React, { Component } from 'react';
import { connect } from 'react-redux';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import VisibilitySensor from 'react-visibility-sensor';
import isEqual from 'lodash/isEqual';

import NotificationItem from './NotificationItem';
import NotificationCenterModal from './NotificationCenterModal';
import { readNotification } from '../../../redux/notifications/actions';

import { TopnavNotificationsProps, TopnavNotificationsState } from './interface';
import { MAX_HEADER_NOTIFICATIONS } from './constant';
import { Notification } from '../../../redux/notifications/interface';
import { withTranslation } from 'react-i18next';

class TopnavNotifications extends Component<TopnavNotificationsProps, TopnavNotificationsState> {
  state: TopnavNotificationsState = {
    data: [],
    isNotifyModalOpen: false,
    moreUnReadCount: 0,
    getElement: null,
    isViewRequest: false
  };

  componentDidMount(): void {
    this.setState({
      getElement: document.getElementById('notificationDropdown')
    });
  }

  componentDidUpdate(prevProps: Readonly<TopnavNotificationsProps>): void {
    const { unread, notifications } = this.props;

    const smallUnreadList = notifications ? notifications.filter((obj: Notification) => !obj.is_viewed) : [];
    if (!isEqual(prevProps.unread, unread)) {
      const moreUnread = unread - smallUnreadList.length;
      this.setState({
        moreUnReadCount: moreUnread > 0 ? moreUnread : 0
      });
    }
  }

  onChange = (isVisible) => {
    const { isViewRequest } = this.state;

    if (!isViewRequest && isVisible) {
      const { notifications, readNotificationAction } = this.props;

      const notificationId = notifications.flatMap((obj: Notification) => {
        if (obj.is_viewed) {
          return [];
        } else {
          return obj.system_id;
        }
      });

      if (notificationId.length) {
        this.setState({ isViewRequest: true });
        readNotificationAction(notificationId);
      }
    }
  };

  openNotifyModal = () => this.setState({ isNotifyModalOpen: true });

  closeNotifyModal = () => this.setState({ isNotifyModalOpen: false });

  render(): React.ReactElement {
    const { notifications, readNotificationAction, unread, t } = this.props;
    const { isNotifyModalOpen, moreUnReadCount } = this.state;

    const shortList = notifications ? notifications.slice(0, MAX_HEADER_NOTIFICATIONS) : [];
    const unReadText = moreUnReadCount === 1 ? 'непрочитанное' : 'непрочитанных';
    const containmentDOMRect = this.state.getElement ? this.state.getElement : null;

    return (
      <>
        <div className="position-relative d-inline-block">
          {notifications && notifications.length > 0 && (
            <UncontrolledDropdown className="dropdown-menu-right">
              <DropdownToggle className="header-icon notificationButton" color="empty">
                <i className="simple-icon-bell" />
                {unread > 0 && <span className="count">{unread}</span>}
              </DropdownToggle>
              <DropdownMenu className="position-absolute mt-3 scroll" right id="notificationDropdown">
                <PerfectScrollbar
                  option={{
                    suppressScrollX: true,
                    wheelPropagation: false
                  }}
                >
                  {shortList.map((notification, index) => (
                    <VisibilitySensor key={index} containment={containmentDOMRect} onChange={this.onChange}>
                      {({ isVisible }) => (
                        <NotificationItem
                          key={index}
                          notification={notification}
                          isVisible={isVisible}
                          readNotification={readNotificationAction}
                        />
                      )}
                    </VisibilitySensor>
                  ))}
                  <div className="show-more text-muted" onClick={this.openNotifyModal}>
                    <div>{t('notification.show_all')}</div>
                    {moreUnReadCount > 0 && (
                      <div className="new-notifications">
                        ({`${t('notification.yet')} ${moreUnReadCount} ${t(unReadText)}`})
                      </div>
                    )}
                  </div>
                </PerfectScrollbar>
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
        </div>

        {isNotifyModalOpen && <NotificationCenterModal cancellation={this.closeNotifyModal} />}
      </>
    );
  }
}

const mapStateToProps = ({ notifications }) => ({
  data: notifications.data,
  unread: notifications.unread
});

const mapDispatchToProps = {
  readNotificationAction: readNotification
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TopnavNotifications)
);
