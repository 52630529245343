import React, { Component, Fragment, ReactNode } from 'react';
import { Row } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';

export default class PermissionDeniedPage extends Component<any, any> {
  render(): ReactNode {
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">У вас нет прав доступа. Обратитесь к администратору</Colxx>
        </Row>
      </Fragment>
    );
  }
}
