import React, { Fragment } from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';

import { fromBinary } from '../../helpers/Utils';
import { withTranslation, useTranslation } from 'react-i18next';

import { BreadcrumbContainerProps, BreadcrumbItemsProps } from './interface';

const getMenuTitle = (t, sub: string, params?: { id?: string; slug?: string }) => {
  if (params && params.id) {
    return fromBinary(params.id);
  }
  if (params && params.slug) {
    return t(`menu.${params.slug}`) || params.slug;
  }
  return t(`menu.${sub}`) || sub;
};

const getUrl = (path: string, sub: string, params?: { id?: string }, additionalPath?: string) => {
  let url = path.split(sub)[0];

  if (sub === ':id' && params && params.id) {
    url = `${url}${params.id}`;

    if (additionalPath) {
      url = `${url}${additionalPath}`;
    }
  } else {
    url = `${url}${sub}`;
  }

  return url;
};

const BreadcrumbContainer = ({ heading, match, additionalPath, t }: BreadcrumbContainerProps) => (
  <Fragment>
    {heading && <h1>{t(`breadcrumb.${heading}`)}</h1>}
    <BreadcrumbItems match={match} additionalPath={additionalPath} />
  </Fragment>
);

export const BreadcrumbItems = ({ match, additionalPath }: BreadcrumbItemsProps) => {
  const { t } = useTranslation();
  const path = match.path.substr(1);
  let paths = path.split('/');

  if (paths[paths.length - 1].indexOf(':') > -1) {
    paths = paths.filter((x: string) => x && x.indexOf(':') === -1);
  } else {
    paths = paths.filter((x: string, i: number) => x && i);
  }

  return (
    <Fragment>
      <Breadcrumb className="pt-0 breadcrumb-container d-none d-lg-inline-block">
        {paths &&
          paths.map((sub, index) => (
            <BreadcrumbItem key={index} active={paths.length === index + 1}>
              {paths.length !== index + 1 ? (
                <NavLink to={`/${getUrl(path, sub, match.params, additionalPath)}`}>
                  {getMenuTitle(t, sub, match.params)}
                </NavLink>
              ) : (
                getMenuTitle(t, sub, match.params)
              )}
            </BreadcrumbItem>
          ))}
      </Breadcrumb>
    </Fragment>
  );
};

export default withTranslation()(BreadcrumbContainer);
