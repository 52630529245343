import { startOfDay } from 'date-fns';
import { CellInfo } from 'react-table';
import format from '../../../../localization';
import { DATE_TIME_FORMAT } from '../../../../constants/app';
import { OPERATIONS_OUTCOME_DIRECTIONS_OPTIONS } from '../constants';

export enum RegistriesHistoryStatuses {
  InProgress = 1,
  InProgressError,
  Done,
  DoneError
}

export enum RegistriesHistoryStatusesNames {
  InProgress = 'В обработке',
  InProgressError = 'В обработке с ошибками',
  Done = 'Выполнен',
  DoneError = 'Выполнен с ошибками'
}

export const RegistriesHistoryStatusesKeys = (t: any) => ({
  [RegistriesHistoryStatuses.InProgress]: t('В обработке'),
  [RegistriesHistoryStatuses.InProgressError]: 'В обработке с ошибками',
  [RegistriesHistoryStatuses.Done]: 'Выполнен',
  [RegistriesHistoryStatuses.DoneError]: 'Выполнен с ошибками'
});

export const PAYOUTS_REGISTRIES_HISTORY_STATUS_OPTIONS = [
  {
    label: 'Все',
    value: '',
    key: 0
  },
  {
    label: RegistriesHistoryStatusesNames.InProgress,
    value: RegistriesHistoryStatuses.InProgress,
    key: RegistriesHistoryStatuses.InProgress
  },
  {
    label: RegistriesHistoryStatusesNames.InProgressError,
    value: RegistriesHistoryStatuses.InProgressError,
    key: RegistriesHistoryStatuses.InProgressError
  },
  {
    label: RegistriesHistoryStatusesNames.Done,
    value: RegistriesHistoryStatuses.Done,
    key: RegistriesHistoryStatuses.Done
  },
  {
    label: RegistriesHistoryStatusesNames.DoneError,
    value: RegistriesHistoryStatuses.DoneError,
    key: RegistriesHistoryStatuses.DoneError
  }
];

export const PAYOUTS_REGISTRIES_HISTORY_INITIAL_VALUES = {
  dateFrom: startOfDay(new Date()),
  dateTo: new Date(),
  account: {
    value: null
  },
  direction: OPERATIONS_OUTCOME_DIRECTIONS_OPTIONS[0],
  status: PAYOUTS_REGISTRIES_HISTORY_STATUS_OPTIONS[0],
  registry_id: ''
};

export const PAYOUTS_REGISTRIES_HISTORY_PAGE_SIZE = 50;

export const PAYOUTS_REGISTRIES_HISTORY_TABLE_COLUMNS = [
  {
    Header: 'Id реестра',
    accessor: 'id'
  },
  {
    Header: 'Дата создания',
    accessor: 'dateCreate',
    Cell: ({ value }: CellInfo) => format(value, DATE_TIME_FORMAT)
  },
  {
    Header: 'Дата выплаты',
    accessor: 'datePayment',
    Cell: ({ value }: CellInfo) => format(value, DATE_TIME_FORMAT)
  },
  {
    Header: 'Валюта',
    accessor: 'currency'
  },
  {
    Header: 'Статус',
    accessor: 'status'
  },
  {
    Header: 'Аккаунт',
    accessor: 'account'
  },
  {
    Header: 'Направление',
    accessor: 'direction'
  }
];
