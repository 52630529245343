import { combineReducers } from 'redux';

import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './authUser/reducer';
import operations from './operations/reducer';
import services from './services/reducer';
import glossary from './glossary/reducer';
import profile from './profile/reducer';
import documents from './documents/reducer';
import disposals from './disposals/reducer';
import statistics from './statistics/reducer';
import support from './support/reducer';
import downloads from './downloads/reducer';
import notifications from './notifications/reducer';
import logs from './journal/reducer';
import app from './app/reducer';
import sites from './sites/reducer';
import balance from './balance/reducer';

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  operations,
  services,
  glossary,
  profile,
  documents,
  disposals,
  statistics,
  support,
  downloads,
  logs,
  app,
  sites,
  notifications,
  balance
});

export default reducers;
