import { RouteComponentProps } from 'react-router';

import {
  CurrenciesData,
  DestinationsData,
  GetDestinations,
  GetPayoutAccounts,
  PayoutAccount
} from '../../redux/operations/interface';
import { SelectorOption } from '../../interfaces/app';
import { StatisticType } from './constants';

import { GetSitesActionResult } from '../../redux/sites/interface';
import { Site } from '../../views/settings/Permissions/interface';

export interface StatisticProps extends RouteComponentProps {
  statisticType: StatisticType;
  isAdmin: boolean;
  selectedUserId: number;
  authUserId: number;
  merchantIdList: number[];
  loading: boolean;
  error: string;
  destinations: DestinationsData[];
  accounts: {
    data: {
      [serviceType: number]: PayoutAccount[];
    };
    loading: boolean;
    error: string;
  };
  currencies: {
    data: CurrenciesData[];
    loading: boolean;
    error: string;
  };
  sites: Site[];
  getDestinationsAction: () => GetDestinations;
  getCurrenciesAction: () => void;
  getPayoutAccountsAction: (merchantId: number[], userId?: number) => GetPayoutAccounts;
  getSitesAction: (id: string) => GetSitesActionResult;
}

export interface StatisticState {
  accountOptions: SelectorOption[];
  dateChanged: boolean;
  activeCurrencies: CurrenciesData[];
}

export interface ChartsPeriod {
  key: number;
  label: string;
  value: ChartsPeriodValue;
}

export enum ChartsPeriodValue {
  Hour = 1,
  Day,
  Week,
  Month
}

export interface ConversionChart {
  period_time: string;
  status_total: string[];
}

export interface StatisticsSelectOption {
  key: number;
  value: string;
  label: string;
}

export interface StatisticsServiceSelectOption {
  key: number;
  value: {
    operationType: number;
    directionsOptions: StatisticsDirectionSelectOptions[];
  };
  label: string;
}

export interface StatisticsDirectionSelectOptions {
  key: number;
  value: {
    source: number[];
    destination?: number | number[];
  };
  label: string;
}
