import React, { Component } from 'react';
import format from '../../../localization';

import { DATE_TIME_FORMAT } from '../../../constants/app';
import { RegistriesHistoryStatusesKeys } from '../../../views/operations/Payouts/PayoutsRegistriesHistory/constants';
import { NotificationType, SYNC_ERROR } from './constant';
import { NotificationItemProps, NotificationItemState } from './interface';
import { withTranslation } from 'react-i18next';

class NotificationItem extends Component<NotificationItemProps, NotificationItemState> {
  state = {
    wasRead: false
  };

  componentDidUpdate(prevProps: Readonly<NotificationItemProps>): void {
    const {
      notification: { is_viewed },
      isVisible
    } = this.props;
    const { wasRead } = this.state;

    if (!is_viewed && isVisible && prevProps.isVisible !== isVisible && !wasRead) {
      this.setState({ wasRead: true });
    }
  }

  render(): React.ReactElement {
    const {
      notification: { dt, status, name, registry_id, id, type },
      t
    } = this.props;
    const { wasRead } = this.state;

    return (
      <div
        className={'single-notification border-bottom'}
        style={{ animation: wasRead ? 'test-bold-highlight 2.5s ease-in' : '' }}
      >
        <div className="pl-3 pr-2 ">
          <div className="item mb-1">{format(dt, DATE_TIME_FORMAT)}</div>
          {type === NotificationType.CronErrorType ? (
            <div className="item mb-1">{SYNC_ERROR}</div>
          ) : (
            <>
              {id && (
                <div className="item mb-1">
                  {t('notification.operation')}: {id}
                </div>
              )}
              {registry_id && (
                <div className="item mb-1">
                  {t('notification.registry')}: {registry_id}
                </div>
              )}
              <div className="item mb-1">
                {t('notification.account')}: {t(name)}
              </div>
              <div className="item mb-1">
                {t('notification.status')}: {RegistriesHistoryStatusesKeys(t)[status]}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(NotificationItem);
