import React, { ReactNode } from 'react';
import { Card, CardBody } from 'reactstrap';
import ReactTable from 'react-table';

import DataTablePagination from '../../../components/DatatablePagination';
import TableSubComponent from './TableSubComponent';

import { ServiceTypes } from './constants';
import { IOperationsListTableProps, IOperationsListTableState } from './interface';
import { getOperationListTableColumns } from './helpers';
import { withTranslation } from 'react-i18next';

class OperationsListTable extends React.Component<IOperationsListTableProps, IOperationsListTableState> {
  state: IOperationsListTableState = {
    selectedOrderNumber: null,
    sorted: [{ id: 'order_time', desc: true }]
  };

  selectRow = (state, rowInfo) => {
    const { selectedOrderNumber } = this.state;
    const id = rowInfo ? rowInfo.original.order_number : null;

    return {
      style: {
        className: selectedOrderNumber === id && selectedOrderNumber !== null ? 'table-row-selected' : ''
      }
    };
  };

  setSorting = (newSorted) => {
    const { onSortedChange } = this.props;
    this.setState({ sorted: newSorted });
    onSortedChange(newSorted);
  };

  render(): ReactNode {
    const {
      tableRef,
      serviceType,
      data,
      count,
      page,
      onPageChange,
      canReturn,
      canCallback,
      maxPageSize,
      t
    } = this.props;
    const { sorted } = this.state;
    const pageSize = data && data.length ? (data.length < maxPageSize ? data.length : maxPageSize) : 0;

    return (
      <div ref={tableRef}>
        {data && data.length > 0 && (
          <Card className="mb-4">
            <CardBody>
              <ReactTable
                data={data}
                columns={getOperationListTableColumns(t, serviceType)}
                page={page}
                pages={Math.ceil(count / maxPageSize)}
                defaultPageSize={pageSize}
                pageSize={pageSize}
                sortable={true}
                sorted={sorted}
                filterable={false}
                showPageJump={true}
                showPagination={true}
                PaginationComponent={DataTablePagination}
                showPageSizeOptions={false}
                manual
                onSortedChange={(newSorted) => {
                  this.setSorting(newSorted);
                }}
                onPageChange={onPageChange}
                loadingText=""
                getTrProps={serviceType === ServiceTypes.Income ? this.selectRow : undefined}
                noDataText="Нет данных"
                freezeWhenExpanded={true}
                SubComponent={({ original }) => (
                  <TableSubComponent
                    serviceType={serviceType}
                    item={original}
                    canCallback={canCallback}
                    canReturn={canReturn}
                  />
                )}
              />

              {/* TODO Бэк пока не готов */}
              {/*{serviceType === ServiceTypes.Income && (*/}
              {/*  <Row>*/}
              {/*    <Colxx xxs="12" className="d-flex justify-content-end mt-3">*/}
              {/*      <NavLink to="#" className={`btn btn-primary ${!selectedRow && 'disabled'} mr-2`}>*/}
              {/*        Оформить возврат*/}
              {/*        </NavLink>*/}
              {/*        <NavLink to="#" className={`btn btn-primary ${!selectedRow && 'disabled'} mr-2`}>*/}
              {/*          Отправить коллбэк*/}
              {/*        </NavLink>*/}
              {/*      <NavLink*/}
              {/*        to={`/operations/acquiring/history/${selectedOrderNumber}`}*/}
              {/*        className={cn('btn', 'btn-primary', { disabled: !selectedOrderNumber })}*/}
              {/*      >*/}
              {/*        История*/}
              {/*      </NavLink>*/}
              {/*    </Colxx>*/}
              {/*  </Row>*/}
              {/*)}*/}
            </CardBody>
          </Card>
        )}
      </div>
    );
  }
}

export default withTranslation()(OperationsListTable);
