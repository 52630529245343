import { Merchant } from '../../../redux/menu/interface';
import { PayoutAccount } from '../../../redux/operations/interface';
import { SelectorOption } from '../../../interfaces/app';
import { ALL_OPTION } from '../constants';

export const getPartnerNameOptions = (partners: Merchant[], t: any, withAllOption = true) => {
  if (partners && partners.length) {
    const partnersVal = partners.map(({ name, id }) => ({
      label: name,
      key: id,
      value: name
    }));

    if (withAllOption) {
      return [ALL_OPTION(t), ...partnersVal];
    } else {
      return [...partnersVal];
    }
  } else {
    return [];
  }
};

export const getAccountOptions = (accounts: { [serviceType: number]: PayoutAccount[] }, t: any): SelectorOption[] => {
  if (!accounts) {
    return [];
  }
  const result: SelectorOption[] = [];

  accounts &&
    Object.keys(accounts).length &&
    Object.values(accounts).forEach((service: PayoutAccount[]) => {
      service &&
        Object.values(service).forEach(({ id, name }: PayoutAccount) => {
          result.push({ label: name, key: id, value: `${id}_${name}` });
        });
    });

  return [ALL_OPTION(t), ...result];
};
