import { all, fork, put, call, takeLatest, SimpleEffect } from 'redux-saga/effects';
import { CombinatorEffect } from '@redux-saga/types';
import { ApiRequest, ContentType } from '../../helpers/ApiRequest/ApiRequest';
import { createQueryString } from '../../helpers/Utils';
import { GET_BALANCE_OPERATION, GET_BALANCE_STAT } from '../actions';
import {
  getBalanceOperationSuccess,
  getBalanceOperationError,
  getBalanceStatSuccess,
  getBalanceStatError
} from './actions';
import { GetBalanceOperationAction, GetBalanceStatAction, CommonStat } from './interface';

export function* getBalanceStat({ payload }: GetBalanceStatAction): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { userId, partnerIds, dateFrom, dateTo, operationType, amount, account } = payload;
    const url = userId ? `/users/${userId}/balance/` : '/user/balance/';

    const formData = {
      p: partnerIds,
      from: dateFrom,
      to: dateTo,
      t: operationType,
      a: account,
      amount
    };

    const { error, in_sum, out_sum, commis_sum, start_balance, end_balance, bill_sum } = yield call(
      ApiRequest.post,
      url,
      createQueryString(formData),
      {},
      ContentType.ApplicationFormUrlencoded
    );

    const commonStat: CommonStat = {
      startBalanceValue: Number(start_balance),
      endBalanceValue: Number(end_balance),
      enrollmentAmount: Number(in_sum) - Number(out_sum),
      unholdingAmount: Number(bill_sum),
      commission: Number(commis_sum)
    };

    if (!error) {
      yield put(getBalanceStatSuccess(commonStat));
    } else {
      yield put(getBalanceStatError(`${error}`));
    }
  } catch (error) {
    yield put(getBalanceStatError(`${error}`));
  }
}

export function* getBalanceOperation({
  payload
}: GetBalanceOperationAction): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { userId, partnerIds, dateFrom, dateTo, operationType, amount, account, offset, limit, sorting } = payload;
    const url = userId ? `/users/${userId}/balance-operations/` : '/user/balance-operations/';

    const formData = {
      p: partnerIds,
      from: dateFrom,
      to: dateTo,
      t: operationType,
      a: account,
      amount,
      offset,
      sorting,
      limit
    };

    const { data, count, error } = yield call(
      ApiRequest.post,
      url,
      createQueryString(formData),
      {},
      ContentType.ApplicationFormUrlencoded
    );

    if (!error) {
      yield put(getBalanceOperationSuccess(data, count));
    } else {
      const errorMsg = typeof error === 'object' ? 'Ошибка запроса / отсутствуют данные' : error;
      yield put(getBalanceOperationError(`${errorMsg}`));
    }
  } catch (error) {
    yield put(getBalanceOperationError(`${error}`));
  }
}

export function* watchGetBalanceOperation(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(GET_BALANCE_OPERATION, getBalanceOperation);
}

export function* watchGetBalanceStat(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(GET_BALANCE_STAT, getBalanceStat);
}

export default function* rootSaga(): Generator<CombinatorEffect<string, any>, any, any> {
  yield all([fork(watchGetBalanceOperation), fork(watchGetBalanceStat)]);
}
