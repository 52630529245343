import { ServiceTypes } from '../../views/operations/OperationsList/constants';

import {
  CONFIRM_PAYOUT,
  CONFIRM_PAYOUT_ERROR,
  CONFIRM_PAYOUT_SUCCESS,
  CREATE_MASS_PAYOUT,
  CREATE_MASS_PAYOUT_ERROR,
  CREATE_MASS_PAYOUT_SUCCESS,
  CREATE_PAYOUT,
  CREATE_PAYOUT_ERROR,
  CREATE_PAYOUT_SUCCESS,
  FLASH_PAYOUT_FIELDS,
  GET_ACQUIRING_HISTORY,
  GET_ACQUIRING_HISTORY_ERROR,
  GET_ACQUIRING_HISTORY_SUCCESS,
  GET_DESTINATIONS,
  GET_DESTINATIONS_ERROR,
  GET_DESTINATIONS_SUCCESS,
  GET_OPERATIONS,
  GET_OPERATIONS_ERROR,
  GET_OPERATIONS_FILE,
  GET_OPERATIONS_FILE_ERROR,
  GET_OPERATIONS_FILE_SUCCESS,
  GET_OPERATIONS_SUCCESS,
  GET_PAYOUT_ACCOUNTS,
  GET_PAYOUT_ACCOUNTS_ERROR,
  GET_PAYOUT_ACCOUNTS_SUCCESS,
  GET_PAYOUT_DIRECTIONS,
  GET_PAYOUT_DIRECTIONS_ERROR,
  GET_PAYOUT_DIRECTIONS_SUCCESS,
  GET_PAYOUT_FIELDS,
  GET_PAYOUT_FIELDS_ERROR,
  GET_PAYOUT_FIELDS_SUCCESS,
  GET_REGISTRIES_HISTORY,
  GET_REGISTRIES_HISTORY_ERROR,
  GET_REGISTRIES_HISTORY_SUCCESS,
  REFUND_OPERATION,
  REFUND_OPERATION_ERROR,
  REFUND_OPERATION_SUCCESS,
  RESET_OPERATIONS,
  RESET_REGISTRIES_HISTORY,
  SEND_CALLBACK,
  SEND_CALLBACK_ERROR,
  SEND_CALLBACK_SUCCESS
} from '../actions';

import {
  ConfirmPayout,
  ConfirmPayoutError,
  ConfirmPayoutResponseValues,
  ConfirmPayoutRequestValues,
  ConfirmPayoutSuccess,
  CreateMassPayoutAction,
  CreateMassPayoutErrorAction,
  CreateMassPayoutRequestValues,
  CreateMassPayoutSuccessAction,
  CreatePayout,
  CreatePayoutError,
  CreatePayoutsRequestValues,
  CreatePayoutsResponseValues,
  CreatePayoutSuccess,
  DestinationsData,
  Direction,
  FlashPayoutFields,
  GetAcquiringHistoryAction,
  GetAcquiringHistoryActionError,
  GetAcquiringHistoryActionSuccess,
  GetDestinations,
  GetDestinationsError,
  GetDestinationsSuccess,
  GetPayoutAccounts,
  GetPayoutAccountsError,
  GetPayoutAccountsSuccess,
  GetPayoutDirections,
  GetPayoutDirectionsError,
  GetPayoutDirectionsSuccess,
  GetPayoutFields,
  GetPayoutFieldsError,
  GetPayoutFieldsSuccess,
  GetRegistriesHistoryAction,
  GetRegistriesHistoryErrorAction,
  GetRegistriesHistorySuccessAction,
  IGetOperations,
  IGetOperationsError,
  IGetOperationsFile,
  IGetOperationsFileError,
  IGetOperationsFileSuccess,
  IGetOperationsSuccess,
  IResetOperations,
  OperationsFileRequestValues,
  OperationsListData,
  OperationsListRequestValues,
  PayoutAccount,
  PayoutField,
  RefundOperation,
  RefundOperationError,
  RefundOperationSuccess,
  RefundRequestData,
  RegistriesHistoryData,
  RegistriesHistoryRequest,
  ResetRegistriesHistory,
  SendCallback,
  SendCallbackError,
  SendCallbackRequestData,
  SendCallbackSuccess,
  RefundResponseValues
} from './interface';

// GET OPERATIONS
export const getOperations = (serviceType: ServiceTypes, values: OperationsListRequestValues): IGetOperations => ({
  type: GET_OPERATIONS,
  payload: {
    serviceType,
    values
  }
});

export const getOperationsSuccess = (
  serviceType: ServiceTypes,
  data: OperationsListData,
  count: number
): IGetOperationsSuccess => ({
  type: GET_OPERATIONS_SUCCESS,
  payload: {
    serviceType,
    data,
    count
  }
});

export const getOperationsError = (serviceType: ServiceTypes, error: string): IGetOperationsError => ({
  type: GET_OPERATIONS_ERROR,
  payload: {
    serviceType,
    error
  }
});

// RESET OPERATIONS
export const resetOperations = (serviceType: ServiceTypes): IResetOperations => ({
  type: RESET_OPERATIONS,
  payload: {
    serviceType
  }
});

// GET OPERATIONS FILE
export const getOperationsFile = (
  serviceType: ServiceTypes,
  values: OperationsFileRequestValues
): IGetOperationsFile => ({
  type: GET_OPERATIONS_FILE,
  payload: {
    serviceType,
    values
  }
});

export const getOperationsFileSuccess = (serviceType: ServiceTypes): IGetOperationsFileSuccess => ({
  type: GET_OPERATIONS_FILE_SUCCESS,
  payload: {
    serviceType
  }
});

export const getOperationsFileError = (serviceType: ServiceTypes, error: string): IGetOperationsFileError => ({
  type: GET_OPERATIONS_FILE_ERROR,
  payload: {
    serviceType,
    error
  }
});

// GET ACQUIRING HISTORY
export const getAcquiringHistory = (
  merchantId: number[],
  orderNumber: string,
  userId?: number
): GetAcquiringHistoryAction => ({
  type: GET_ACQUIRING_HISTORY,
  payload: {
    merchantId,
    orderNumber,
    userId
  }
});

export const getAcquiringHistorySuccess = (
  rows: any,
  total: number,
  orderNumber: string
): GetAcquiringHistoryActionSuccess => ({
  type: GET_ACQUIRING_HISTORY_SUCCESS,
  payload: { rows, total, orderNumber }
});

export const getAcquiringHistoryError = (error: string): GetAcquiringHistoryActionError => ({
  type: GET_ACQUIRING_HISTORY_ERROR,
  payload: { error }
});

// GET PAYOUT ACCOUNTS
export const getPayoutAccounts = (merchantId: number[], userId?: number, serviceType?: number): GetPayoutAccounts => ({
  type: GET_PAYOUT_ACCOUNTS,
  payload: {
    merchantId,
    userId,
    serviceType
  }
});

export const getPayoutAccountsSuccess = (payoutAccounts: PayoutAccount[]): GetPayoutAccountsSuccess => ({
  type: GET_PAYOUT_ACCOUNTS_SUCCESS,
  payload: { payoutAccounts }
});

export const getPayoutAccountsError = (error: string): GetPayoutAccountsError => ({
  type: GET_PAYOUT_ACCOUNTS_ERROR,
  payload: { error }
});

// CREATE MASS PAYOUT
export const createMassPayout = (data: CreateMassPayoutRequestValues): CreateMassPayoutAction => ({
  type: CREATE_MASS_PAYOUT,
  payload: data
});

export const createMassPayoutSuccess = (response: CreatePayoutsResponseValues): CreateMassPayoutSuccessAction => ({
  type: CREATE_MASS_PAYOUT_SUCCESS,
  payload: { response }
});

export const createMassPayoutError = (error: string): CreateMassPayoutErrorAction => ({
  type: CREATE_MASS_PAYOUT_ERROR,
  payload: { error }
});

// GET PAYOUT DIRECTIONS
export const getPayoutDirections = (account: string): GetPayoutDirections => ({
  type: GET_PAYOUT_DIRECTIONS,
  payload: { account }
});

export const getPayoutDirectionsSuccess = (data: Direction[]): GetPayoutDirectionsSuccess => ({
  type: GET_PAYOUT_DIRECTIONS_SUCCESS,
  payload: { data }
});

export const getPayoutDirectionsError = (error: string): GetPayoutDirectionsError => ({
  type: GET_PAYOUT_DIRECTIONS_ERROR,
  payload: { error }
});

// GET PAYOUT FIELDS
export const getPayoutFields = (destination: string): GetPayoutFields => ({
  type: GET_PAYOUT_FIELDS,
  payload: { destination }
});

export const getPayoutFieldsSuccess = (data: PayoutField[]): GetPayoutFieldsSuccess => ({
  type: GET_PAYOUT_FIELDS_SUCCESS,
  payload: { data }
});

export const getPayoutFieldsError = (error: string): GetPayoutFieldsError => ({
  type: GET_PAYOUT_FIELDS_ERROR,
  payload: { error }
});

export const flashPayoutFields = (): FlashPayoutFields => ({
  type: FLASH_PAYOUT_FIELDS
});

// CREATE PAYOUT
export const createPayout = (data: CreatePayoutsRequestValues): CreatePayout => ({
  type: CREATE_PAYOUT,
  payload: data
});

export const createPayoutSuccess = (response: CreatePayoutsResponseValues, phone: string): CreatePayoutSuccess => {
  response.phone = phone;
  return {
    type: CREATE_PAYOUT_SUCCESS,
    payload: { response }
  };
};

export const createPayoutError = (error: string): CreatePayoutError => ({
  type: CREATE_PAYOUT_ERROR,
  payload: { error }
});

// CONFIRM PAYOUT
export const confirmPayout = (data: ConfirmPayoutRequestValues): ConfirmPayout => ({
  type: CONFIRM_PAYOUT,
  payload: data
});

export const confirmPayoutSuccess = (response: ConfirmPayoutResponseValues): ConfirmPayoutSuccess => ({
  type: CONFIRM_PAYOUT_SUCCESS,
  payload: response
});

export const confirmPayoutError = (error: string): ConfirmPayoutError => ({
  type: CONFIRM_PAYOUT_ERROR,
  payload: { error }
});

// GET DESTINATIONS
export const getDestinations = (): GetDestinations => ({
  type: GET_DESTINATIONS
});

export const getDestinationsSuccess = (data: DestinationsData[]): GetDestinationsSuccess => ({
  type: GET_DESTINATIONS_SUCCESS,
  payload: { data }
});

export const getDestinationsError = (error: string): GetDestinationsError => ({
  type: GET_DESTINATIONS_ERROR,
  payload: { error }
});

// GET REGISTRIES HISTORY
export const getRegistriesHistory = (data: RegistriesHistoryRequest): GetRegistriesHistoryAction => ({
  type: GET_REGISTRIES_HISTORY,
  payload: data
});

export const getRegistriesHistorySuccess = (data: RegistriesHistoryData): GetRegistriesHistorySuccessAction => ({
  type: GET_REGISTRIES_HISTORY_SUCCESS,
  payload: { data }
});

export const getRegistriesHistoryError = (error: string): GetRegistriesHistoryErrorAction => ({
  type: GET_REGISTRIES_HISTORY_ERROR,
  payload: { error }
});

export const resetRegistriesHistory = (): ResetRegistriesHistory => ({
  type: RESET_REGISTRIES_HISTORY
});

// REFUND OPERATION
export const refundOperation = (request: RefundRequestData): RefundOperation => ({
  type: REFUND_OPERATION,
  payload: request
});

export const refundOperationSuccess = (response: RefundResponseValues): RefundOperationSuccess => ({
  type: REFUND_OPERATION_SUCCESS,
  payload: { response }
});

export const refundOperationError = (error: string): RefundOperationError => ({
  type: REFUND_OPERATION_ERROR,
  payload: { error }
});

// SEND CALLBACK
export const sendCallback = (request: SendCallbackRequestData): SendCallback => ({
  type: SEND_CALLBACK,
  payload: request
});

export const sendCallbackSuccess = (): SendCallbackSuccess => ({
  type: SEND_CALLBACK_SUCCESS
});

export const sendCallbackError = (error: string): SendCallbackError => ({
  type: SEND_CALLBACK_ERROR,
  payload: { error }
});
