import React, { ChangeEvent, Fragment, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, FormGroup, Row, Label, Input, CardTitle } from 'reactstrap';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import { Colxx } from '../../../../components/common/CustomBootstrap';
import { Field, Form, Formik, FormikErrors } from 'formik';
import { Dropzone, DropzoneFile } from 'dropzone';
import DropZone from '../../../../components/dropzone/Dropzone';
import { CustomDesignTabProps } from '../interface';
import Preview from '../Preview/Preview';
import { CustomDesignConfig } from '../../../../redux/settings/interface';
import {
  EMPTY_DESIGN_CONFIG,
  DEFAULT_MAX_FILE_SIZE,
  PREVIEW_PAGE_VIEWS,
  FAVICON_CONTENT_TYPES,
  START_PAGE_CONTENT_TYPE,
  CUSTOM_CSS_FIELD_SIZE,
  GENERAL_PREVIEW_HINTS
} from '../constants';
import { EMPTY_FIELD_ERROR, INVALID_EMAIL_ERROR } from '../../../../constants/app';
import { getFilesErrors } from '../helpers';
import WithClearButton from '../../../../components/common/WithClearButton';
import { withTranslation } from 'react-i18next';
class GeneralTab extends React.Component<CustomDesignTabProps, any> {
  constructor(props: CustomDesignTabProps) {
    super(props);
    this.designConfigNew = EMPTY_DESIGN_CONFIG;
    this.setFieldValue = () => {};
    this.isValid = true;
    this.errors = {};
  }

  private designConfigNew: CustomDesignConfig;
  private setFieldValue: (field: string, value: any) => void;
  private isValid: boolean;
  private errors: FormikErrors<CustomDesignConfig> | null;
  private dropZoneFavicon: Dropzone;
  private dropZoneStartPage: Dropzone;

  componentDidUpdate(prevProps: Readonly<CustomDesignTabProps>, prevState: any, snapshot?: any): void {
    const { currentDesign, actualDesign } = this.props;
    if (!isEqual(currentDesign, prevProps.currentDesign) && isEqual(actualDesign, currentDesign)) {
      this.dropZoneFavicon.removeAllFiles();
      this.dropZoneStartPage.removeAllFiles();
    }
  }

  validate = (values): { [key: string]: string } => {
    if (!values) {
      return {};
    }
    const errors: { [key: string]: any } = {};
    const { settings, domain } = values;
    const emailRegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i;
    const imageErrors = getFilesErrors({
      ...values.imageFiles,
      startPage: values.startPage
    });

    if (imageErrors && !isEqual(imageErrors, {})) {
      errors.imageFiles = imageErrors;
    }

    if (!domain) {
      errors.domain = EMPTY_FIELD_ERROR;
    }

    if (settings && settings.supportEmail && !emailRegExp.test(settings.supportEmail)) {
      errors.settings = { supportEmail: INVALID_EMAIL_ERROR };
    }

    this.props.setTabSettings(this.designConfigNew, isEqual(errors, {}), 'general');
    return errors;
  };

  render(): ReactNode {
    const { setImage, removeImage, currentDesign, t } = this.props;
    return (
      <Fragment>
        <Row>
          <Colxx className="col-left col-12 col-xl-4 mb-4">
            <Card>
              <CardBody>
                <CardTitle>{t('customDesign.general')}</CardTitle>
                <Formik
                  validate={this.validate}
                  isInitialValid={true}
                  enableReinitialize={true}
                  initialValues={this.props.currentDesign.data}
                  onSubmit={() => {}}
                >
                  {({ values, setFieldValue, errors, isValid, setFieldTouched }) => {
                    this.designConfigNew = values || EMPTY_DESIGN_CONFIG;
                    this.setFieldValue = setFieldValue;
                    this.isValid = isValid;
                    this.errors = errors;
                    return (
                      <Form className="custom-design">
                        <FormGroup className="has-float-label">
                          <Label className="float-label">{t('customDesign.domain')}</Label>
                          <WithClearButton onClear={() => setFieldValue('domain', window.location.hostname)}>
                            <Field
                              className="form-control"
                              type="text"
                              name="domain"
                              placeholder="console.example.com"
                              value={get(values, 'domain', '')}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setFieldValue('domain', event.target.value.replace(/(^\w+:|^)\/\//, ''));
                              }}
                              onBlur={() => setFieldTouched('domain', true)}
                            />
                          </WithClearButton>
                          {errors && errors.domain && <div className="invalid-feedback d-block">{errors.domain}</div>}
                        </FormGroup>
                        <FormGroup className="has-float-label">
                          <Label className="float-label">
                            {t('customDesign.Иконка на вкладке в браузере (блок 1)')}
                          </Label>
                          <DropZone
                            maxFiles={1}
                            init={(dropZone: Dropzone) => (this.dropZoneFavicon = dropZone)}
                            addedfile={(file: DropzoneFile) => {
                              setImage && setImage(file, 'favicon', this.dropZoneFavicon, setFieldValue);
                            }}
                            removedfile={() => {
                              removeImage && removeImage('favicon', setFieldValue);
                            }}
                            maxFilesize={DEFAULT_MAX_FILE_SIZE}
                            acceptedFiles={FAVICON_CONTENT_TYPES}
                          />
                        </FormGroup>
                        <FormGroup className="has-float-label">
                          <Label className="float-label">{t('customDesign.Название вкладки браузера (блок 1)')}</Label>
                          <WithClearButton
                            onClear={() =>
                              setFieldValue('settings', { ...this.designConfigNew.settings, ...{ tabTitle: '' } })
                            }
                          >
                            <Field
                              className="form-control"
                              type="text"
                              name="tabTitle"
                              value={get(values, 'settings.tabTitle', '')}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setFieldValue('settings', {
                                  ...this.designConfigNew.settings,
                                  ...{ tabTitle: event.target.value }
                                });
                              }}
                            />
                          </WithClearButton>
                        </FormGroup>
                        <FormGroup className="has-float-label">
                          <Label className="float-label">{t('customDesign.Email техподдержки (блок 2)')}</Label>
                          <WithClearButton
                            onClear={() =>
                              setFieldValue('settings', { ...this.designConfigNew.settings, ...{ supportEmail: '' } })
                            }
                          >
                            <Field
                              className="form-control"
                              type="text"
                              name="supportEmail"
                              value={get(values, 'settings.supportEmail', '')}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setFieldValue('settings', {
                                  ...this.designConfigNew.settings,
                                  ...{ supportEmail: event.target.value }
                                });
                              }}
                            />
                          </WithClearButton>
                          {errors && errors.settings && 'supportEmail' in errors.settings && (
                            <div className="invalid-feedback d-block">{errors.settings['supportEmail']}</div>
                          )}
                        </FormGroup>
                        <FormGroup className="has-float-label">
                          <Label className="float-label">{t('customDesign.Стартовая страница')}</Label>
                          <DropZone
                            maxFiles={1}
                            init={(dropZone: Dropzone) => (this.dropZoneStartPage = dropZone)}
                            addedfile={(file: DropzoneFile) => {
                              if ('startPage' in this.designConfigNew && this.designConfigNew.startPage) {
                                this.dropZoneStartPage.removeFile(this.designConfigNew.startPage);
                              }
                              const reader = new FileReader();
                              reader.onload = (event: ProgressEvent<FileReader>) => {
                                if (event && event.target) {
                                  setFieldValue('startPage', file);
                                }
                              };
                              reader.readAsDataURL(file);
                            }}
                            removedfile={() => setFieldValue('startPage', null)}
                            maxFilesize={DEFAULT_MAX_FILE_SIZE}
                            acceptedFiles={START_PAGE_CONTENT_TYPE}
                          />
                        </FormGroup>
                        <FormGroup className="has-float-label">
                          <Label className="float-label">{t('customDesign.Дополнительный CSS')}</Label>
                          <WithClearButton
                            onClear={() => {
                              setFieldValue('settings', { ...this.designConfigNew.settings, ...{ customCss: '' } });
                            }}
                          >
                            <Input
                              type="textarea"
                              value={get(values, 'settings.customCss', '')}
                              className="form-control"
                              rows={CUSTOM_CSS_FIELD_SIZE}
                              onChange={({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
                                setFieldValue('settings', {
                                  ...this.designConfigNew.settings,
                                  ...{ customCss: currentTarget.value }
                                });
                              }}
                            />
                          </WithClearButton>
                        </FormGroup>
                        <FormGroup className="has-float-label">
                          <Label className="float-label">{t('customDesign.Тема')}</Label>
                          <WithClearButton
                            onClear={() => {
                              setFieldValue('settings', {
                                ...this.designConfigNew.settings,
                                ...{ theme: 'light.gmc' }
                              });
                            }}
                          >
                            <div className={'theme-colors form-control'}>
                              <div className="d-flex flex-row justify-content-between mt-4">
                                {['purple', 'blue', 'green', 'orange', 'red', 'gmc'].map((color: string) => (
                                  <button
                                    key={`light.${color}`}
                                    className={`theme-color theme-color-${color} ${
                                      this.designConfigNew.settings &&
                                      this.designConfigNew.settings['theme'] === `light.${color}`
                                        ? 'active'
                                        : ''
                                    }`}
                                    onClick={(e) => {
                                      setFieldValue('settings', {
                                        ...this.designConfigNew.settings,
                                        ...{ theme: `light.${color}` }
                                      });
                                    }}
                                  >
                                    <span>{color}</span>
                                  </button>
                                ))}
                              </div>
                            </div>
                          </WithClearButton>
                        </FormGroup>
                      </Form>
                    );
                  }}
                </Formik>
                {this.props.children}
              </CardBody>
            </Card>
          </Colxx>
          <Colxx className="col-left col-12 col-xl-8">
            <div className="mb-4 card preview-card">
              <div className="card-body">
                <Preview
                  view={PREVIEW_PAGE_VIEWS.loginPage}
                  customDesign={currentDesign}
                  hints={GENERAL_PREVIEW_HINTS}
                />
              </div>
            </div>
          </Colxx>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ settings }) => ({ actualDesign: settings.customDesign });
export default withTranslation()(
  connect(
    mapStateToProps,
    {}
  )(GeneralTab)
);
