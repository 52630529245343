import React, { Component, Fragment, ReactNode, SyntheticEvent } from 'react';
import { connect } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { Row, Card, CardBody, FormGroup, Label, Button } from 'reactstrap';
import Select from 'react-select';
import isEqual from 'lodash/isEqual';
import InputMask from 'react-input-mask';

import { NotFoundMessage } from '../../../../components/common/NotFoundMessage';
import { Loader } from '../../../../components/common/Loader';
import { Colxx } from '../../../../components/common/CustomBootstrap';
import CreatePayoutsAdditionalFields from './CreatePayoutsAdditionalFields';
import {
  getPayoutAccounts,
  getPayoutFields,
  createPayout,
  getDestinations,
  flashPayoutFields,
  confirmPayout
} from '../../../../redux/actions';
import { getCurrencies } from '../../../../redux/settings/actions';
import { withTranslation } from 'react-i18next';
import { getAccountOptions, getDirectionsOptions, getCurrenciesOptionsForPayout } from '../helpers';
import { unmaskPhoneNumber } from '../../../../helpers/Utils';
import { floatFieldValue, intFieldValue } from '../../helpers';
import { OPERATIONS_SERVICES_OPTIONS, OPERATIONS_TYPE_NAMES } from '../../constants';
import { EMPTY_FIELD_ERROR, PHONE_MASK, PAYOUT_AMOUNT_ERROR } from '../../../../constants/app';
import {
  CREATE_PAYOUTS_INITIAL_VALUES,
  PAYOUT_CONFIRMATION_ATTEMPTS,
  PAYOUT_CONFIRMATION_EXPIRE_TIME
} from '../constants';
import { CreatePayoutsProps, CreatePayoutsState, CreatePayoutsFormValues } from './interface';
import { FieldValues, PayoutField } from '../../../../redux/operations/interface';
import { SelectorOption } from '../../../../interfaces/app';
import { ConfirmationModal } from '../ConfirmationModal';
import { ResultModal } from '../ResultModal';
import { ResultModalTypes, PayoutsModalWindows } from '../../interface';
import pull from 'lodash/pull';
import { CancelConfirmationModal } from '../CancelConfirmationModal';

class CreatePayouts extends Component<CreatePayoutsProps, CreatePayoutsState> {
  state: CreatePayoutsState = {
    modalsOpen: [],
    confirmationWindowInit: false,
    resultModal: ResultModalTypes.Error,
    initialValues: { ...CREATE_PAYOUTS_INITIAL_VALUES(this.props.t) }
  };
  resetForm;
  values;

  componentDidMount(): void {
    const {
      merchantIdList,
      isAdmin,
      selectedUserId,
      authUserId,
      directions,
      accounts,
      currencies,
      getPayoutAccountsAction,
      getDestinationsAction,
      getCurrenciesAction,
      flashPayoutFieldsAction
    } = this.props;

    const isDestinationsExist = directions && directions.data;
    const isAccountsExist = accounts && accounts.data;
    const isCurrenciesExist = currencies && currencies.data && currencies.data;

    !isDestinationsExist && getDestinationsAction();

    !isCurrenciesExist && getCurrenciesAction();

    if (!isAccountsExist && merchantIdList && merchantIdList.length) {
      getPayoutAccountsAction(
        merchantIdList,
        isAdmin && selectedUserId && authUserId !== selectedUserId ? selectedUserId : undefined
      );
    }
    flashPayoutFieldsAction();
  }

  componentDidUpdate(prevProps: Readonly<CreatePayoutsProps>): void {
    const {
      loading,
      error,
      merchantIdList,
      getPayoutAccountsAction,
      authUserId,
      isAdmin,
      fields,
      selectedUserId,
      confirmation
    } = this.props;

    if (prevProps.loading && !loading && !error && !confirmation) {
      this.setState({ confirmationWindowInit: true });
      this.openModal(PayoutsModalWindows.Confirmation);
    }

    if (error && error !== prevProps.error) {
      this.setState({ resultModal: ResultModalTypes.Error });
      this.openModal(PayoutsModalWindows.Result);
    }

    if (!isEqual(prevProps.merchantIdList, merchantIdList) || prevProps.selectedUserId !== selectedUserId) {
      this.resetForm && this.resetForm();

      if (merchantIdList && merchantIdList.length) {
        getPayoutAccountsAction(
          merchantIdList,
          isAdmin && selectedUserId && authUserId !== selectedUserId ? selectedUserId : undefined
        );
      }
    }

    if (fields.data && !isEqual(prevProps.fields.data, fields.data)) {
      this.setInitialValues(fields.data);
    }

    if (confirmation && confirmation !== prevProps.confirmation && confirmation.is_valid) {
      this.closeModal(PayoutsModalWindows.Confirmation);
      this.setState({ resultModal: ResultModalTypes.Success });
      this.openModal(PayoutsModalWindows.Result);
    }
  }

  setInitialValues = (fields: PayoutField[]) => {
    const { initialValues } = this.state;

    if (fields && fields.length) {
      const newFields = {};
      fields.forEach(({ param_name, param_preset }: PayoutField) => {
        newFields[param_name] = param_preset ? { key: '', value: '', label: '' } : '';
      });

      this.setState({
        initialValues: {
          ...initialValues,
          ...newFields
        }
      });
    } else {
      return;
    }
  };

  onSubmit = (values: CreatePayoutsFormValues): void => {
    const { createPayoutAction, fields } = this.props;

    const fieldsValues = fields.data
      ? fields.data.reduce(
          (acc, { param_name, param_preset }): FieldValues => ({
            ...acc,
            [param_name]: param_preset ? values[param_name].value : values[param_name]
          }),
          {}
        )
      : '';

    const requestValues = {
      account_id: Number(values.account_id.value.id),
      destination: Number(values.destination.value.destination),
      id: values.id,
      phone: unmaskPhoneNumber(values.phone),
      client: values.client,
      payout_currency: values.payout_currency.label,
      amount: values.amount ? parseFloat(values.amount) : undefined,
      ...(fieldsValues ? { add_params: fieldsValues } : null)
    };
    createPayoutAction(requestValues);
  };

  validate = (values: CreatePayoutsFormValues): { [field: string]: string } => {
    const { fields, t } = this.props;

    const errors = Object.getOwnPropertyNames(values).reduce((accumulator: {}, key: string) => {
      if (!values[key] || (values[key].hasOwnProperty('value') && !values[key]['value'])) {
        return { ...accumulator, [key]: t(EMPTY_FIELD_ERROR) };
      }

      return accumulator;
    }, {});

    if (fields.data) {
      fields.data.forEach(({ param_name }) => {
        if (!values[param_name]) {
          errors[param_name] = t(EMPTY_FIELD_ERROR);
        }
      });
    }

    if (Number(values.amount) < 1 || Number(values.amount) > 15000) {
      errors['amount'] = t(PAYOUT_AMOUNT_ERROR);
    }

    return errors;
  };

  isOpenModal = (payoutsModalWindow: number): boolean => this.state.modalsOpen.includes(payoutsModalWindow);

  openModal = (payoutsModalWindow: number): void => {
    if (this.isOpenModal(payoutsModalWindow)) {
      return;
    }
    this.setState({ modalsOpen: [...this.state.modalsOpen, payoutsModalWindow] });
  };

  closeModal = (payoutsModalWindow: number): void => {
    this.setState({ modalsOpen: pull(this.state.modalsOpen, payoutsModalWindow) });
  };

  render(): ReactNode {
    const {
      smsPhone,
      accounts,
      directions,
      getPayoutFieldsAction,
      fields,
      merchantIdList,
      confirmPayoutAction,
      confirmation,
      t,
      currencies
    } = this.props;
    const { initialValues, resultModal, confirmationWindowInit } = this.state;

    const page = OPERATIONS_SERVICES_OPTIONS[OPERATIONS_TYPE_NAMES.OUTCOME];
    const accountByType = accounts.data ? accounts.data[page.key] : [];

    return (
      <Fragment>
        {fields.loading && <Loader />}

        {merchantIdList && merchantIdList.length > 0 && (
          <Formik initialValues={initialValues} onSubmit={this.onSubmit} validate={this.validate}>
            {({ values, setFieldValue, setFieldTouched, isValid, errors, touched, resetForm }) => {
              this.resetForm = resetForm;
              this.values = values;

              return (
                <Fragment>
                  <Card className="mb-5">
                    <CardBody>
                      <Form>
                        <Row className="mb-4">
                          <Colxx xxs="12" sm="6">
                            <FormGroup className="form-group has-float-label">
                              <Label className="float-label">{t('operations.account')}</Label>
                              <Select
                                className="react-select"
                                classNamePrefix="react-select"
                                name="account_id"
                                isLoading={accounts.loading}
                                value={values.account_id}
                                onChange={(account: SelectorOption) => {
                                  setFieldValue('account_id', account);
                                }}
                                options={accountByType ? getAccountOptions(accountByType, t, false) : []}
                                placeholder=""
                              />
                              {errors.account_id && touched.account_id && (
                                <div className="invalid-feedback d-block">{errors.account_id}</div>
                              )}
                            </FormGroup>
                          </Colxx>
                          <Colxx xxs="12" sm="6">
                            <FormGroup className="form-group has-float-label">
                              <Label className="float-label">{t('operations.destination')}</Label>
                              <Select
                                className="react-select"
                                classNamePrefix="react-select"
                                name="destination"
                                isLoading={directions.loading}
                                value={values.destination}
                                onChange={(destination: SelectorOption) => {
                                  setFieldValue('destination', destination);
                                  setFieldValue('payout_currency', null);
                                  if (destination.value.destination) {
                                    getPayoutFieldsAction(destination.value.destination);
                                  }
                                }}
                                options={getDirectionsOptions(directions.data, t, false)}
                                placeholder=""
                              />
                              {errors.destination && touched.destination && (
                                <div className="invalid-feedback d-block">{errors.destination}</div>
                              )}
                            </FormGroup>
                          </Colxx>

                          <Colxx xxs="12" sm="6" md="4" lg="3">
                            <FormGroup className="has-float-label">
                              <Label className="float-label">{t('operations.merchant_transaction_id')}</Label>
                              <Field
                                className="form-control"
                                type="text"
                                name="id"
                                onChange={({ target: { value } }) => intFieldValue(value, 'id', setFieldValue)}
                              />
                              {errors.id && touched.id && <div className="invalid-feedback d-block">{errors.id}</div>}
                            </FormGroup>
                          </Colxx>
                          <Colxx xxs="12" sm="6" md="4" lg="3">
                            <FormGroup className="has-float-label">
                              <Label className="float-label">{t('operations.amount')}</Label>
                              <Field
                                className="form-control"
                                type="text"
                                name="amount"
                                onChange={({ target: { value } }) => floatFieldValue(value, 'amount', setFieldValue)}
                              />
                              {errors.amount && touched.amount && (
                                <div className="invalid-feedback d-block">{errors.amount}</div>
                              )}
                            </FormGroup>
                          </Colxx>
                          <Colxx xxs="12" sm="6" md="4" lg="3">
                            <FormGroup className="has-float-label">
                              <Label for="payout_currency" className="float-label">
                                {t('operations.currency')}
                              </Label>
                              <Select
                                id="currency"
                                className="react-select"
                                classNamePrefix="react-select"
                                name="payout_currency"
                                value={values.payout_currency}
                                onChange={(currency: SelectorOption) => {
                                  setFieldValue('payout_currency', currency);
                                }}
                                options={
                                  currencies && currencies.data
                                    ? getCurrenciesOptionsForPayout(
                                        currencies.data,
                                        values.destination ? Number(values.destination.value.destination) : 0,
                                        t
                                      )
                                    : []
                                }
                                placeholder=""
                              />
                            </FormGroup>
                          </Colxx>
                          <Colxx xxs="12" sm="6" md="4" lg="3">
                            <FormGroup className="has-float-label">
                              <Label className="float-label">{t('operations.phone')}</Label>
                              <InputMask
                                mask={PHONE_MASK}
                                value={values.phone}
                                onChange={({ currentTarget }: SyntheticEvent<HTMLInputElement>) => {
                                  setFieldValue('phone', currentTarget.value);
                                  setFieldTouched('phone', false);
                                }}
                                onBlur={() => setFieldTouched('phone', true)}
                                className="form-control"
                                type="tel"
                                name="phone"
                              />
                              {errors.phone && touched.phone && (
                                <div className="invalid-feedback d-block">{errors.phone}</div>
                              )}
                            </FormGroup>
                          </Colxx>
                          <Colxx xxs="12" sm="6" md="4" lg="3">
                            <FormGroup className="has-float-label">
                              <Label className="float-label">{t('operations.client')}</Label>
                              <Field className="form-control" type="text" name="client" />
                              {errors.client && touched.client && (
                                <div className="invalid-feedback d-block">{errors.client}</div>
                              )}
                            </FormGroup>
                          </Colxx>
                        </Row>

                        {fields.data && (
                          <CreatePayoutsAdditionalFields
                            fields={fields.data}
                            values={values}
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                            errors={errors}
                            t={t}
                            touched={touched}
                          />
                        )}

                        <Row>
                          <Colxx xxs="12" className="d-flex justify-content-end">
                            <Button disabled={!isValid} type="submit" color="primary">
                              {t('operations.payout')}
                            </Button>
                          </Colxx>
                        </Row>
                      </Form>
                    </CardBody>
                  </Card>
                </Fragment>
              );
            }}
          </Formik>
        )}

        <ConfirmationModal
          init={confirmationWindowInit}
          onInit={() => this.setState({ confirmationWindowInit: false })}
          modalIsOpen={this.isOpenModal(PayoutsModalWindows.Confirmation)}
          onCloseModal={() => {
            this.closeModal(PayoutsModalWindows.Confirmation);
            this.openModal(PayoutsModalWindows.CancelConfirmation);
          }}
          onTimeIsOver={() => {
            this.closeModal(PayoutsModalWindows.Confirmation);
            this.setState({ resultModal: ResultModalTypes.CodeExpired });
            this.openModal(PayoutsModalWindows.Result);
          }}
          onAttemptsIsOver={() => {
            this.closeModal(PayoutsModalWindows.Confirmation);
            this.setState({ resultModal: ResultModalTypes.CodeInvalid });
            this.openModal(PayoutsModalWindows.Result);
          }}
          onSubmit={(code) => {
            confirmPayoutAction({ id: this.values.id, code: Number(code) });
          }}
          attempts={
            confirmation
              ? PAYOUT_CONFIRMATION_ATTEMPTS - Number(confirmation.current_attempt)
              : PAYOUT_CONFIRMATION_ATTEMPTS
          }
          counter={PAYOUT_CONFIRMATION_EXPIRE_TIME}
          userPhone={smsPhone}
        />

        <CancelConfirmationModal
          modalIsOpen={this.isOpenModal(PayoutsModalWindows.CancelConfirmation)}
          onPaymentCancel={() => {
            this.closeModal(PayoutsModalWindows.CancelConfirmation);
            this.setState({ resultModal: ResultModalTypes.Canceled });
            this.openModal(PayoutsModalWindows.Result);
          }}
          onPaymentResume={() => {
            this.closeModal(PayoutsModalWindows.CancelConfirmation);
            this.openModal(PayoutsModalWindows.Confirmation);
          }}
        />

        <ResultModal
          modalIsOpen={this.isOpenModal(PayoutsModalWindows.Result)}
          onCloseModal={() => {
            this.closeModal(PayoutsModalWindows.Result);
          }}
          payoutId={this.values && this.values.id ? this.values.id : 0}
          modalType={resultModal}
        />

        {!(merchantIdList && merchantIdList.length) && <NotFoundMessage text={t('no_partners_selected')} />}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ operations, settings, authUser }) => ({
  merchantIdList: settings.selectedMerchantsId,
  accounts: operations.payoutAccounts,
  directions: operations.destinations,
  loading: operations.createPayout.loading,
  error: operations.createPayout.error,
  isAdmin: authUser.user.data && authUser.user.data.is_admin ? authUser.user.data.is_admin : false,
  authUserId: authUser.user.data ? authUser.user.data.id : 0,
  selectedUserId: settings.selectedUser ? settings.selectedUser.id : 0,
  fields: operations.payoutFields,
  smsPhone:
    operations.createPayout && operations.createPayout.response && operations.createPayout.response.phone
      ? operations.createPayout.response.phone
      : '',
  confirmation: operations.createPayout.confirmation ? operations.createPayout.confirmation : null,
  currencies: settings.currencies
});

const mapDispatchToProps = {
  getPayoutAccountsAction: getPayoutAccounts,
  getDestinationsAction: getDestinations,
  getPayoutFieldsAction: getPayoutFields,
  createPayoutAction: createPayout,
  flashPayoutFieldsAction: flashPayoutFields,
  confirmPayoutAction: confirmPayout,
  getCurrenciesAction: getCurrencies
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CreatePayouts)
);
