import { all, call, fork, put, takeLatest, SimpleEffect } from 'redux-saga/effects';
import { CombinatorEffect } from '@redux-saga/types';

import { createQueryString } from '../../helpers/Utils';
import {
  GET_OPERATIONS,
  getOperationsSuccess,
  getOperationsError,
  GET_OPERATIONS_FILE,
  getOperationsFileSuccess,
  getOperationsFileError,
  GET_PAYOUT_ACCOUNTS,
  getPayoutAccountsSuccess,
  getPayoutAccountsError,
  CREATE_MASS_PAYOUT,
  createMassPayoutSuccess,
  createMassPayoutError,
  GET_PAYOUT_DIRECTIONS,
  getPayoutDirectionsSuccess,
  getPayoutDirectionsError,
  GET_PAYOUT_FIELDS,
  getPayoutFieldsSuccess,
  getPayoutFieldsError,
  CREATE_PAYOUT,
  createPayoutSuccess,
  createPayoutError,
  GET_ACQUIRING_HISTORY,
  getAcquiringHistoryError,
  getAcquiringHistorySuccess,
  GET_DESTINATIONS,
  getDestinationsSuccess,
  getDestinationsError,
  refundOperationSuccess,
  refundOperationError,
  REFUND_OPERATION,
  sendCallbackError,
  sendCallbackSuccess,
  SEND_CALLBACK,
  getRegistriesHistorySuccess,
  getRegistriesHistoryError,
  GET_REGISTRIES_HISTORY,
  GET_GLOSSARY_ACCOUNTS_BY_PARTNER,
  getGlossaryAccountsByPartnerSuccess,
  getGlossaryAccountsByPartnerError,
  confirmPayoutError,
  confirmPayoutSuccess,
  CONFIRM_PAYOUT
} from '../actions';
import { ApiRequest, ContentType } from '../../helpers/ApiRequest/ApiRequest';

import { GetPayoutAccountsResponse } from '../../api.interface';
import {
  GetAcquiringHistoryAction,
  IGetOperations,
  IGetOperationsFile,
  CreateMassPayoutAction,
  GetPayoutAccounts,
  GetPayoutDirections,
  GetPayoutFields,
  CreatePayout,
  GetRegistriesHistoryAction,
  RefundOperation,
  SendCallback,
  ConfirmPayout,
  RefundResponseValues
} from './interface';
import { PARTNER_ID } from '../../constants/app';

export function* getOperations({ payload }: IGetOperations): Generator<SimpleEffect<string, any>, any, any> {
  const { serviceType, values } = payload;

  try {
    const { user_id, ...queryParams } = values;

    const url = user_id ? `/users/${user_id}/account-operations/` : '/user/account-operations/';

    const { data, count, error } = yield call(
      ApiRequest.post,
      url,
      createQueryString(queryParams),
      {},
      ContentType.ApplicationFormUrlencoded
    );

    if (error) {
      yield put(getOperationsError(serviceType, `${error}`));
    } else {
      yield put(getOperationsSuccess(serviceType, data, count));
    }
  } catch (error) {
    yield put(getOperationsError(serviceType, `${error}`));
  }
}

export function* getOperationsFile({ payload }: IGetOperationsFile): Generator<SimpleEffect<string, any>, any, any> {
  const { serviceType, values } = payload;
  try {
    const { partner_id, user_id, account_id, statuses, ...body }: any = values;

    if (account_id) {
      body.account_id = account_id;
    }

    if (statuses) {
      body.statuses = statuses;
    }

    if (partner_id) {
      body.partners = partner_id;
    }

    const url = user_id ? `/users/${user_id}/account-operations/csv/` : '/user/account-operations/csv/';

    const { error } = yield call(ApiRequest.post, url, body);

    if (!error) {
      yield put(getOperationsFileSuccess(serviceType));
    } else {
      yield put(getOperationsFileError(serviceType, `${error}`));
    }
  } catch (error) {
    yield put(getOperationsFileError(serviceType, `${error}`));
  }
}

export function* getAcquiringHistory({
  payload
}: GetAcquiringHistoryAction): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { orderNumber, userId } = payload;

    const queryParams = {
      order_number: orderNumber,
      show_all: true
    };

    const url = userId ? `/users/${userId}/account-operations/` : '/user/account-operations/';

    const { data, count, error } = yield call(ApiRequest.get, `${url}?${createQueryString(queryParams)}`);

    if (!error) {
      yield put(getAcquiringHistorySuccess(data, count, orderNumber));
    } else {
      yield put(getAcquiringHistoryError(`${error}`));
    }
  } catch (error) {
    yield put(getAcquiringHistoryError(`${error}`));
  }
}

export function* getPayoutAccounts({ payload }: GetPayoutAccounts): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { userId, merchantId, serviceType } = payload;

    const url = userId ? `/users/${userId}/service-accounts/` : '/user/service-accounts/';
    const queryParams = {
      [PARTNER_ID]: merchantId,
      service_type: serviceType
    };

    const { data, error }: GetPayoutAccountsResponse = yield call(
      ApiRequest.get,
      `${url}?${createQueryString(queryParams)}`
    );

    if (error) {
      yield put(getPayoutAccountsError(`${error}`));
      yield put(getGlossaryAccountsByPartnerError(`${error}`));
    } else {
      yield put(getPayoutAccountsSuccess(merchantId && merchantId.length ? data : []));
      yield put(getGlossaryAccountsByPartnerSuccess(merchantId && merchantId.length ? data : [], merchantId[0]));
    }
  } catch (error) {
    yield put(getPayoutAccountsError(`${error}`));
  }
}

export function* createMassPayout({ payload }: CreateMassPayoutAction): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { csv, ...queryParams } = payload;
    const url = '/payout/csv/';

    const formData = new FormData();
    formData.append('csv', csv);

    const { data, error: payoutCreateError } = yield call(
      ApiRequest.post,
      `${url}?${createQueryString(queryParams)}`,
      formData
    );

    if (payoutCreateError) {
      yield put(createMassPayoutError(`${payoutCreateError}`));
    } else {
      yield put(createMassPayoutSuccess(data));
    }
  } catch (error) {
    yield put(createMassPayoutError(`${error}`));
  }
}

export function* getPayoutDirections({ payload }: GetPayoutDirections): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { account } = payload;
    const { data, error } = yield call(ApiRequest.get, `/operations/payouts/directions/?account=${account}`);

    if (error) {
      yield put(getPayoutDirectionsError(`${error}`));
    } else {
      yield put(getPayoutDirectionsSuccess(data));
    }
  } catch (error) {
    yield put(getPayoutDirectionsError(`${error}`));
  }
}

export function* getPayoutFields({ payload }: GetPayoutFields): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { data, error } = yield call(ApiRequest.get, `/payout/params/?${createQueryString(payload)}`);

    if (error) {
      yield put(getPayoutFieldsError(`${error}`));
    } else {
      yield put(getPayoutFieldsSuccess(data));
    }
  } catch (error) {
    yield put(getPayoutFieldsError(`${error}`));
  }
}

export function* createPayout({ payload }: CreatePayout): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { ...requestValues } = payload;

    const { data, phone, error: payoutCreateError } = yield call(ApiRequest.post, '/payout/', requestValues);

    if (payoutCreateError) {
      yield put(createPayoutError(`${payoutCreateError}`));
    } else {
      yield put(createPayoutSuccess(data, phone));
    }
  } catch (error) {
    yield put(createPayoutError(`${error}`));
  }
}

export function* confirmPayout({ payload }: ConfirmPayout): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { ...requestValues } = payload;

    const { is_valid, current_attempt, expires_at, error: payoutConfirmError } = yield call(
      ApiRequest.post,
      '/payout/confirm/',
      requestValues
    );

    if (payoutConfirmError) {
      yield put(confirmPayoutError(`${payoutConfirmError}`));
    } else {
      yield put(confirmPayoutSuccess({ is_valid, expires_at, current_attempt }));
    }
  } catch (error) {
    yield put(confirmPayoutError(`${error}`));
  }
}

export function* getRegistriesHistory({
  payload
}: GetRegistriesHistoryAction): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { data, error } = yield call(ApiRequest.get, `/payout/?${createQueryString(payload)}`);

    if (error) {
      yield put(getRegistriesHistoryError(`${error}`));
    } else {
      yield put(getRegistriesHistorySuccess(data));
    }
  } catch (error) {
    yield put(getRegistriesHistoryError(`${error}`));
  }
}

export function* getDestinations(): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { data, error } = yield call(ApiRequest.get, '/destinations/');

    if (error) {
      yield put(getDestinationsError(error));
    } else {
      yield put(getDestinationsSuccess(data));
    }
  } catch (error) {
    yield put(getDestinationsError(error));
  }
}

export function* refundOperation({ payload }: RefundOperation): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { success, phone, error, attempt } = yield call(ApiRequest.post, '/refund/', payload);

    if (error) {
      yield put(refundOperationError(error));
    } else {
      const response = { success, phone, attempt } as RefundResponseValues;
      yield put(refundOperationSuccess(response));
    }
  } catch (error) {
    yield put(refundOperationError(error));
  }
}

export function* sendCallback({ payload }: SendCallback): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { error } = yield call(ApiRequest.post, '/notify/', payload);

    if (error) {
      yield put(sendCallbackError(error));
    } else {
      yield put(sendCallbackSuccess());
    }
  } catch (error) {
    yield put(sendCallbackError(error));
  }
}

// WATCHERS
export function* watchGetOperations(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(GET_OPERATIONS, getOperations);
}

export function* watchGetOperationsFile(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(GET_OPERATIONS_FILE, getOperationsFile);
}

export function* watchGetPayoutAccounts(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(GET_PAYOUT_ACCOUNTS, getPayoutAccounts);
  yield takeLatest(GET_GLOSSARY_ACCOUNTS_BY_PARTNER, getPayoutAccounts);
}

export function* watchCreateMassPayout(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(CREATE_MASS_PAYOUT, createMassPayout);
}

export function* watchGetPayoutDirections(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(GET_PAYOUT_DIRECTIONS, getPayoutDirections);
}

export function* watchGetPayoutFields(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(GET_PAYOUT_FIELDS, getPayoutFields);
}

export function* watchCreatePayout(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(CREATE_PAYOUT, createPayout);
}

export function* watchConfirmPayout(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(CONFIRM_PAYOUT, confirmPayout);
}

export function* watchGetAcquiringHistory(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(GET_ACQUIRING_HISTORY, getAcquiringHistory);
}

export function* watchGetDestinations(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(GET_DESTINATIONS, getDestinations);
}

export function* watchGetRegistriesHistory(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(GET_REGISTRIES_HISTORY, getRegistriesHistory);
}

export function* watchRefundOperation(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(REFUND_OPERATION, refundOperation);
}

export function* watchSendCallback(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(SEND_CALLBACK, sendCallback);
}

export default function* rootSaga(): Generator<CombinatorEffect<string, any>, any, any> {
  yield all([
    fork(watchGetOperations),
    fork(watchGetAcquiringHistory),
    fork(watchGetPayoutAccounts),
    fork(watchCreateMassPayout),
    fork(watchGetPayoutDirections),
    fork(watchGetPayoutFields),
    fork(watchCreatePayout),
    fork(watchConfirmPayout),
    fork(watchGetOperationsFile),
    fork(watchGetDestinations),
    fork(watchGetRegistriesHistory),
    fork(watchRefundOperation),
    fork(watchSendCallback)
  ]);
}
