import React, { Component, Fragment, ReactNode } from 'react';
import { Line } from 'react-chartjs-2';
import * as zoom from 'chartjs-plugin-zoom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

import { MAX_PERCENT } from '../../../constants/app';
import { ThemeColors } from '../../../helpers/ThemeColors';
import { roundChartData, updateScrollPane, onPaneScrollX } from '../helpers';
import { ChartTypes } from '../constants';
import { gerSuggestedMaxLength, getChartData, getsMaxTicksByWindowWidth } from './helpers';
import { ChartPaneProps, ChartPaneState } from './interface';
import { ChartsPeriodValue } from '../interface';

const DEFAULT_ELEMENTS_COUNT = 20;

export default class ChartPane extends Component<ChartPaneProps, ChartPaneState> {
  state: ChartPaneState = {
    maxTicksLimit: 0,
    isMaxTicksLimitChanged: false,
    labels: [],
    values: [],
    suggestedMaxLength: 0,
    suggestedMax: MAX_PERCENT
  };
  _isMounted = false;
  chartRef;
  scrollRef;
  scrollPaneRef;

  componentDidMount(): void {
    this._isMounted = true;
    const { data, period, type } = this.props;

    if (data && period && type) {
      const { labels, values } = getChartData(data, period, type);
      this.setState({
        labels,
        values,
        suggestedMax: values && values.length && Math.max(...values) ? Math.max(...values) : MAX_PERCENT,
        suggestedMaxLength:
          values && values.length && gerSuggestedMaxLength(Math.max(...values) ? Math.max(...values) : MAX_PERCENT)
      });
    }

    if (this._isMounted) {
      this.setMaxTicksLimit();
      window.addEventListener('resize', () => {
        this.setMaxTicksLimit();
      });
    }
  }

  componentDidUpdate(prevProps: Readonly<ChartPaneProps>, prevState: Readonly<ChartPaneState>): void {
    const { data, period, type } = this.props;
    const { suggestedMaxLength } = this.state;
    const windowWidth = window.innerWidth;

    if (period !== prevProps.period || data !== prevProps.data) {
      const { labels, values } = getChartData(data, period, type);
      this.setState({
        labels,
        values,
        suggestedMax: values && values.length && Math.max(...values) ? Math.max(...values) : MAX_PERCENT,
        suggestedMaxLength:
          values && values.length && gerSuggestedMaxLength(Math.max(...values) ? Math.max(...values) : MAX_PERCENT)
      });
    }

    if (prevState.suggestedMaxLength !== suggestedMaxLength) {
      this.setState({
        maxTicksLimit: getsMaxTicksByWindowWidth(windowWidth, suggestedMaxLength)
      });
    }
  }

  setMaxTicksLimit(): void {
    const { suggestedMaxLength } = this.state;
    const windowWidth = window.innerWidth;
    this.setState({
      maxTicksLimit: getsMaxTicksByWindowWidth(windowWidth, suggestedMaxLength)
    });
  }

  componentWillUnmount(): void {
    window.removeEventListener('resize', () => this.setMaxTicksLimit());
    this._isMounted = false;
  }

  render(): ReactNode {
    const { data, type, period, isDetail = false } = this.props;
    const { maxTicksLimit, suggestedMax, values, labels } = this.state;

    if (!data) {
      return (
        <div className="mt-4">
          <div className="font-italic">Нет данных</div>
        </div>
      );
    }

    const colors = ThemeColors();

    const roundedChartData = roundChartData(DEFAULT_ELEMENTS_COUNT, values, labels);

    const datasets = [
      {
        label: '',
        lineWidth: 1,
        borderWidth: 2,
        data: roundedChartData.values,
        borderColor: colors.themeColor1,
        pointBackgroundColor: colors.foregroundColor,
        pointBorderColor: colors.themeColor1,
        pointHoverBackgroundColor: colors.themeColor1,
        pointHoverBorderColor: colors.foregroundColor,
        pointRadius: 0,
        pointBorderWidth: 1,
        pointHoverRadius: 6,
        fill: true,
        backgroundColor: 'rgba(20, 83, 136, 0.3)'
      }
    ];

    const options = {
      responsive: true,
      legend: {
        onClick: null,
        display: false
      },
      tooltips: {
        mode: 'index',
        intersect: false,
        callbacks: {
          title(item: any, prevData: any): string {
            // any потому что не установлена типизация для react-chartjs-2
            return `${prevData.labels[item[0].index]}`;
          },
          label: ({ value }: { value: string }): string => parseFloat(value).toLocaleString('ru-RU')
        }
      },
      hover: {
        mode: 'index',
        intersect: false
      },
      scales: {
        xAxes: [
          {
            ticks: {
              callback: (label: string): string | string[] => {
                switch (Number(period)) {
                  case ChartsPeriodValue.Hour:
                  case ChartsPeriodValue.Week:
                    return label.split(' ');
                  default:
                    return label;
                }
              },
              maxRotation: 0,
              padding: 10,
              autoSkip: true,
              maxTicksLimit,
              autoSkipPadding: 20
            }
          }
        ],
        yAxes: [
          {
            ticks: {
              callback: (label: string): number | string | null => {
                if (Number.isInteger(parseFloat(label))) {
                  switch (type) {
                    case ChartTypes.Paid:
                    case ChartTypes.NotPaidOrNotFound:
                    case ChartTypes.RefundTotal:
                    case ChartTypes.Total:
                      return parseInt(label, 10);
                    default:
                      return parseFloat(label)
                        .toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                        .replace(',', '.');
                  }
                }

                return null;
              },
              beginAtZero: true,
              suggestedMax,
              padding: 10,
              suggestedMin: 0
            }
          }
        ]
      }
    };

    const zoomOptions = {
      plugins: {
        zoom
      },
      pan: {
        enabled: true,
        drag: true,
        mode: 'x',
        rangeMin: {
          x: 0,
          y: 0
        },
        rangeMax: {
          y: suggestedMax
        },
        onPan: (): void => updateScrollPane(this.chartRef, this.scrollRef, this.scrollPaneRef)
      },
      zoom: {
        enabled: true,
        drag: false,
        mode: 'x',
        rangeMin: {
          x: 0,
          y: 0
        },
        rangeMax: {
          y: suggestedMax
        },
        onZoom: (): void => updateScrollPane(this.chartRef, this.scrollRef, this.scrollPaneRef)
      }
    };

    return (
      <Fragment>
        <Line
          data={{ labels: roundedChartData.labels, datasets }}
          ref={(ref: any) => (this.chartRef = ref)}
          options={isDetail ? { ...options, ...zoomOptions } : options}
        />

        {isDetail && (
          <PerfectScrollbar
            ref={(ref: any) => (this.scrollRef = ref)}
            onScrollX={(scrollBar: any) => onPaneScrollX(scrollBar, this.chartRef)}
            className="charts-scrollbar"
          >
            <div ref={(ref: any) => (this.scrollPaneRef = ref)} className="scroll-pane" />
          </PerfectScrollbar>
        )}
      </Fragment>
    );
  }
}
