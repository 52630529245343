import { DropzoneFile } from 'dropzone';

export const getFilesErrors = (files: DropzoneFile[]): { [key: string]: string } => {
  const errors: { [key: string]: string } = {};
  if (!files || files.length === 0) {
    return errors;
  }
  const error = 'error';

  for (const [fileName, file] of Object.entries(files)) {
    if (file && file.status === error) {
      errors[fileName] = error;
    }
  }

  return errors;
};

export const getHintHtml = (name: string, hintNumber: number): string => {
  if (hintNumber) {
    return `<div class=&quot;number-hint number-hint-${name}&quot; style=&quot;color: #000000 !important;&quot;>${hintNumber}</div>`;
  }

  return '';
};
