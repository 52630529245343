import React, { Component, ReactNode, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Card, CardBody, FormGroup, Label, Row } from 'reactstrap';
import ReactTable from 'react-table';
import Select from 'react-select';
import isEqual from 'lodash/isEqual';

import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import { NotificationManager } from '../../../components/common/react-notifications';
import { CustomDropdownTreeSelect } from '../../../components/common/CustomDropdownTreeSelect';
import Breadcrumb from '../../../containers/navs/Breadcrumb';
import DataTablePagination from '../../../components/DatatablePagination';
import { changeCurrencies, getCurrencies } from '../../../redux/actions';

import {
  GLOSSARY_CURRENCIES_INITIAL_VALUES,
  GLOSSARY_CURRENCIES_STATUS_OPTIONS,
  StatusTypes,
  DEFAULT_SORTER
} from './constants';
import { ALL_TREE, EMPTY_FIELD_ERROR } from '../../../constants/app';
import { CurrenciesProps, CurrenciesState } from './interface';
import { NOTIFICATION_TIMEOUT } from '../../../constants/defaultValues';
import { getCurrenciesColumns, getOneLevelSelectedData } from './helpers';
import { Field, Form, Formik } from 'formik';
import { StatisticsServiceSelectOption } from '../../statistics/interface';
import { getFieldErrorsCount } from '../../../helpers/Utils';
import { NotFoundMessage } from '../../../components/common/NotFoundMessage';
import { CurrenciesData } from '../../../redux/operations/interface';
import WithTooltip from '../../../components/common/WithTooltip';
import { withTranslation } from 'react-i18next';

class Currencies extends Component<CurrenciesProps, CurrenciesState> {
  state: CurrenciesState = {
    page: 0,
    selectedCurrencies: [],
    allCurrencies: []
  };
  resetForm;

  componentDidMount(): void {
    const { currencies, getCurrenciesAction } = this.props;
    const isCurrenciesExist = currencies && currencies.length;

    !isCurrenciesExist && getCurrenciesAction();
    this.setState({ selectedCurrencies: currencies, allCurrencies: currencies });
  }

  componentDidUpdate(prevProps: CurrenciesProps): void {
    const { error, currencies, merchantIdList, selectedUserId } = this.props;

    if (error && error !== prevProps.error) {
      NotificationManager.info(error, 'Ошибка', NOTIFICATION_TIMEOUT, null, null, '');
    }
    if (prevProps.currencies !== currencies) {
      this.setState({ selectedCurrencies: currencies, allCurrencies: currencies });
    }

    if (!isEqual(prevProps.merchantIdList, merchantIdList) || prevProps.selectedUserId !== selectedUserId) {
      this.resetForm();
    }
  }

  componentWillUnmount(): void {
    const { currencies, getCurrenciesAction } = this.props;
    const { allCurrencies } = this.state;

    if (!isEqual(currencies, allCurrencies)) {
      getCurrenciesAction();
    }
  }

  findCurrencies = (values) => {
    const { allCurrencies } = this.state;
    const newData = allCurrencies.filter((item: CurrenciesData) => {
      const checkName = values.name ? values.name === item.name : true;
      const checkCountry = values.country.value ? values.country.value.includes(item.country) : true;
      let checkStatus = true;

      switch (values.status.value) {
        case StatusTypes.Active:
          checkStatus = item.is_income_active || item.is_outcome_active;
          break;
        case StatusTypes.NotActive:
          checkStatus = !item.is_income_active && !item.is_outcome_active;
          break;
        case StatusTypes.Income:
          checkStatus = item.is_income_active;
          break;
        case StatusTypes.Outcome:
          checkStatus = item.is_outcome_active;
          break;
        default:
          break;
      }
      return checkName && checkCountry && checkStatus;
    });

    this.setState({
      page: 0,
      selectedCurrencies: newData
    });
  };

  updateCurrencies = (id: number, isIncomeActive: boolean, isOutcomeActive: boolean, isDisposalActive: boolean) => {
    const { selectedCurrencies } = this.state;
    const newData = selectedCurrencies.map((item: CurrenciesData) => {
      if (item.id === id) {
        return {
          ...item,
          is_income_active: isIncomeActive,
          is_outcome_active: isOutcomeActive,
          is_disposals_active: isDisposalActive
        };
      } else {
        return item;
      }
    });
    this.setState({ selectedCurrencies: newData, allCurrencies: newData });
    NotificationManager.info('Валюта обновлена', null, NOTIFICATION_TIMEOUT, null, null, '');
  };

  handleCurrencyChanged = (
    id: number,
    isIncomeActive: boolean,
    isOutcomeActive: boolean,
    isDisposalActive: boolean
  ) => {
    const { changeCurrenciesAction } = this.props;
    changeCurrenciesAction({
      id,
      currency: {
        is_income_active: isIncomeActive,
        is_outcome_active: isOutcomeActive,
        is_disposals_active: isDisposalActive
      }
    });
    this.updateCurrencies(id, isIncomeActive, isOutcomeActive, isDisposalActive);
  };

  setNewPage = (pageNumber: number) => {
    this.setState({ page: pageNumber });
  };

  validate = (values) => {
    const errors: { [key: string]: string } = {};

    if (values.country === null) {
      errors['country'] = EMPTY_FIELD_ERROR;
    }

    return errors;
  };

  getCountriesOptions = (t: any) => {
    const { currencies } = this.props;
    if (currencies && currencies.length) {
      return [
        {
          ...ALL_TREE(t),
          children: [
            ...currencies.map(({ country }) => ({
              label: t(`currency.country.${country}`),
              value: country
            }))
          ]
        }
      ];
    } else {
      return [ALL_TREE(t)];
    }
  };

  render(): ReactNode {
    const { match, maxPageSize, t } = this.props;
    const { page, selectedCurrencies } = this.state;

    const currentPageData = selectedCurrencies && selectedCurrencies.length ? selectedCurrencies : [];
    const pageSize =
      currentPageData && currentPageData.length
        ? currentPageData.length < maxPageSize
          ? currentPageData.length
          : maxPageSize
        : 0;

    return (
      <div className="position-relative">
        <Row className="flex-shrink-0">
          <Colxx xxs="12">
            <Breadcrumb heading="menu.settings" match={match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>

        <Row className="flex-shrink-0">
          <Colxx xxs="12" className="mb-4">
            <Formik
              initialValues={GLOSSARY_CURRENCIES_INITIAL_VALUES(t)}
              onSubmit={this.findCurrencies}
              validate={this.validate}
            >
              {({ values, errors, touched, setFieldValue, setFieldTouched, resetForm }) => {
                this.resetForm = resetForm;

                return (
                  <Fragment>
                    <Form>
                      <Card className="mb-4">
                        <CardBody>
                          <Row>
                            <Colxx xxs="12" sm="4" lg="4">
                              <FormGroup className="form-group has-float-label">
                                <Label className="float-label">{t('settings.currency')}</Label>
                                <WithTooltip tooltipText={t('settings')}>
                                  <Field
                                    className="form-control"
                                    type="text"
                                    name="name"
                                    maxLength="255"
                                    onChange={({ target: { value } }) => {
                                      setFieldValue('name', value.trim());
                                    }}
                                  />
                                </WithTooltip>
                                {errors.name && touched.name && (
                                  <div className="invalid-feedback d-block">{errors.name}</div>
                                )}
                              </FormGroup>
                            </Colxx>
                            <Colxx xxs="12" sm="4" lg="4">
                              <FormGroup className="form-group has-float-label">
                                <Label className="float-label">{t('settings.country')}</Label>
                                <CustomDropdownTreeSelect
                                  name="country"
                                  data={this.getCountriesOptions(t)}
                                  onBlur={() => setFieldTouched('country', true)}
                                  onChange={(changedValue, selectedValues) =>
                                    setFieldValue('country', getOneLevelSelectedData(selectedValues))
                                  }
                                />
                                {errors.country && <div className="invalid-feedback d-block">{errors.country}</div>}
                              </FormGroup>
                            </Colxx>
                            <Colxx xxs="12" sm="4" lg="4">
                              <FormGroup className="has-float-label">
                                <Label className="float-label">{t('settings.availability')}</Label>
                                <Select
                                  className="react-select"
                                  classNamePrefix="react-select"
                                  name="service"
                                  value={values.status}
                                  onChange={(status: StatisticsServiceSelectOption) => {
                                    setFieldValue('status', status);
                                  }}
                                  options={GLOSSARY_CURRENCIES_STATUS_OPTIONS(t)}
                                  placeholder=""
                                />
                              </FormGroup>
                            </Colxx>
                            <Colxx xxs="12" className="d-flex justify-content-end">
                              <Button disabled={!!getFieldErrorsCount(errors)} color="primary" type="submit">
                                {t('settings.button.search')}
                              </Button>
                            </Colxx>
                          </Row>
                        </CardBody>
                      </Card>
                    </Form>
                    {selectedCurrencies && !selectedCurrencies.length && <NotFoundMessage />}
                  </Fragment>
                );
              }}
            </Formik>

            {selectedCurrencies && selectedCurrencies.length > 0 && (
              <Card className="mb-4">
                <CardBody>
                  <ReactTable
                    data={currentPageData}
                    columns={getCurrenciesColumns(this.handleCurrencyChanged, t)}
                    page={page}
                    pages={Math.ceil(selectedCurrencies.length / maxPageSize)}
                    defaultPageSize={pageSize}
                    pageSize={pageSize}
                    sortable={true}
                    defaultSorted={DEFAULT_SORTER}
                    filterable={false}
                    showPageJump={true}
                    showPagination={true}
                    showPageSizeOptions={false}
                    PaginationComponent={DataTablePagination}
                    onPageChange={this.setNewPage}
                  />
                </CardBody>
              </Card>
            )}
          </Colxx>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => ({
  currencies: settings.currencies.data,
  maxPageSize: settings.gcmSettings.data ? settings.gcmSettings.data.max_page_size : 50,
  merchantIdList: settings.selectedMerchantsId,
  selectedUserId: settings.selectedUser ? settings.selectedUser.id : 0
});

const mapDispatchToProps = {
  getCurrenciesAction: getCurrencies,
  changeCurrenciesAction: changeCurrencies
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Currencies)
);
