import { startOfMonth } from 'date-fns';
import { CellInfo } from 'react-table';
import format from '../../localization';
import { DATE_TIME_FORMAT } from '../../constants/app';

export const ALL_OPTION = (t: any) => ({
  key: 0,
  label: t('all'),
  value: ''
});

export const INITIAL_VALUES = (t: any) => ({
  user_id: ALL_OPTION(t),
  partner_id: ALL_OPTION(t),
  dateFrom: startOfMonth(new Date()),
  dateTo: new Date()
});

export const DATE_START_TOOLTIP_TEXT = 'Поиск работает по полю "Время начала"';
export const LOGS_PAGE_SIZE = 50;

export const DEFAULT_SORTER = [
  {
    id: 'started_at',
    desc: true
  }
];

export const logsTableColumns = (t: any) => [
  {
    Header: t('journal.user ID'),
    accessor: 'under_user'
  },
  {
    Header: t('journal.IP'),
    accessor: 'ip'
  },
  {
    Header: t('journal.Administrator'),
    accessor: 'is_admin',
    Cell: ({ value }: CellInfo) => (value ? t('journal.yes') : t('journal.no'))
  },
  {
    Header: t('journal.status'),
    accessor: 'status'
  },
  {
    Header: t('journal.action'),
    accessor: 'action',
    Cell: ({ value }: CellInfo) => t(value)
  },
  {
    Header: t('journal.start date'),
    accessor: 'started_at',
    Cell: ({ value }: CellInfo) => format(value, DATE_TIME_FORMAT)
  },
  {
    Header: t('journal.end date'),
    accessor: 'ended_at',
    Cell: ({ value }: CellInfo) => format(value, DATE_TIME_FORMAT)
  }
];
