import { SERVICE_TYPES_SLUGS } from '../views/services/ServicesList/constant';

export const defaultMenuItems = [
  {
    id: 'services',
    icon: 'iconsminds-handshake',
    label: 'menu.services',
    to: `/services/${SERVICE_TYPES_SLUGS.INCOME}`,
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    permission: [4, 5, 6],
    subs: [
      {
        permission: 4,
        label: 'menu.acquiring',
        to: `/services/${SERVICE_TYPES_SLUGS.INCOME}`
      },
      {
        permission: 5,
        label: 'menu.mobile-commerce',
        to: `/services/${SERVICE_TYPES_SLUGS.MC}`
      },
      {
        permission: 6,
        label: 'menu.payouts',
        to: `/services/${SERVICE_TYPES_SLUGS.OUTCOME}`
      }
    ]
  },
  {
    id: 'statistics',
    icon: 'iconsminds-statistic',
    label: 'menu.statistics',
    to: '/statistics/summary',
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    permission: [1, 2, 3, 33, 34],
    subs: [
      {
        permission: 1,
        label: 'menu.summary',
        to: '/statistics/summary'
      },
      {
        permission: 3,
        label: 'menu.by-customers',
        to: '/statistics/by-customers'
      },
      {
        permission: 2,
        label: 'menu.charts',
        to: '/statistics/charts'
      },
      {
        permission: 33,
        label: 'menu.conversion-chart',
        to: '/statistics/conversion-chart'
      },
      {
        permission: 34,
        label: 'menu.period-summary',
        to: '/statistics/period-summary'
      }
    ]
  },
  {
    id: 'operations',
    icon: 'iconsminds-dollar-sign-2',
    label: 'menu.operations',
    to: '/operations/acquiring',
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    permission: [13, 7, 8, 16],
    subs: [
      {
        permission: 13,
        label: 'menu.acquiring',
        to: '/operations/acquiring'
      },
      {
        permission: 13,
        label: 'menu.mobile-commerce',
        to: '/operations/mobile-commerce'
      },
      {
        permission: [7, 8, 13, 16],
        label: 'menu.payouts',
        to: '/operations/payouts'
      }
    ]
  },
  {
    id: 'balance',
    permission: 10,
    icon: 'iconsminds-wallet',
    label: 'menu.balance',
    to: '/balance/balance-operations',
    subs: [
      {
        permission: 10,
        label: 'menu.balance-operations',
        to: '/balance/balance-operations'
      },
      {
        permission: 10,
        label: 'menu.balance-remainder',
        to: '/balance/balance-remainder'
      }
    ]
  },
  {
    id: 'documents',
    permission: 18,
    icon: 'iconsminds-files',
    label: 'menu.documents',
    to: '/documents',
    subs: [
      {
        permission: 18,
        label: 'menu.statement',
        to: '/documents'
      }
    ]
  },
  {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    permission: [19, 20],
    id: 'disposals',
    icon: 'iconsminds-financial',
    label: 'menu.disposals',
    to: '/disposals',
    includes: [
      {
        permission: 20,
        to: '/disposals/create'
      }
    ]
  }
];

export const adminMenuItems = [
  {
    id: 'actionLog',
    permission: 21,
    icon: 'iconsminds-library',
    label: 'menu.actionLog',
    to: '/journal'
  },
  {
    id: 'glossary',
    icon: 'iconsminds-folder',
    label: 'menu.glossary',
    to: '/glossary/users',
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    permission: [22, 23, 24],
    subs: [
      {
        permission: 22,
        label: 'menu.users',
        to: '/glossary/users'
      },
      {
        permission: 23,
        label: 'menu.partners',
        to: '/glossary/partners'
      },
      {
        permission: 24,
        label: 'menu.contracts',
        to: '/glossary/contracts'
      }
    ]
  },
  {
    id: 'settings',
    icon: 'iconsminds-gear',
    label: 'menu.settings',
    to: '/settings/permissions',
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    permission: [25, 26, 27, 28, 29, 30],
    subs: [
      {
        permission: 25,
        label: 'menu.permissions',
        to: '/settings/permissions'
      },
      {
        permission: 26,
        label: 'menu.system-settings',
        to: '/settings/system'
      },
      {
        permission: 29,
        label: 'menu.account',
        to: '/settings/account'
      },
      {
        permission: 27,
        label: 'menu.services',
        to: '/settings/services'
      },
      {
        permission: 28,
        label: 'menu.currencies',
        to: '/settings/currencies'
      },
      {
        permission: 30,
        label: 'menu.custom-design',
        to: '/settings/custom-design'
      }
    ]
  }
];
