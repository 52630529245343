import React, { Component, Fragment, ReactNode } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Row, Label, FormGroup, Button, Card, CardBody, Input, CustomInput } from 'reactstrap';
import { Field, Form, Formik } from 'formik';
import Select from 'react-select';

import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import { Loader } from '../../../components/common/Loader';
import Breadcrumb from '../../../containers/navs/Breadcrumb';
import CustomSelectInput from '../../../components/common/CustomSelectInput';
import { NotificationManager } from '../../../components/common/react-notifications';
import { NOTIFICATION_TIMEOUT } from '../../../constants/defaultValues';
import { unmaskPhoneNumber } from '../../../helpers/Utils';
import { getAccountSettings, putAccountSettings, resetAccountSettingsStore } from '../../../redux/actions';

import { AuthTypes } from './constants';
import { prepareUsersForSelect } from './helpers';
import { AccountProps, AccountState } from './interface';
import { EMPTY_FIELD_ERROR, INVALID_EMAIL_ERROR, INVALID_PHONE_ERROR } from '../../../constants/app';
import { isAllowed } from '../../../helpers/Access';
import { PRMISSIONS_SETTING_PERMISSSION } from '../../../constants/permissions';
import { withTranslation } from 'react-i18next';
class Account extends Component<AccountProps, AccountState> {
  state = {
    selectedUser: {
      label: '',
      value: '',
      key: ''
    },
    initialValues: {
      fio: '',
      username: '',
      email: '',
      phone: '',
      auth_type: 0,
      allow_concurrent_sessions: false,
      allow_multi_partner: false,
      is_blocked: false,
      is_active: false
    }
  };

  componentDidUpdate(prevProps: Readonly<AccountProps>): void {
    const { data, saving, error, getAccountSettingsAction } = this.props;
    const { selectedUser } = this.state;

    if (prevProps.data !== data && data) {
      const initialValues = {
        ...data,
        phone: unmaskPhoneNumber(data.phone)
      };
      this.setState({ initialValues });
    }

    if (prevProps.saving && !saving && !error) {
      NotificationManager.info('Изменения сохранены', null, NOTIFICATION_TIMEOUT, null, null, '');
      getAccountSettingsAction(selectedUser.value);
    }

    if (!prevProps.error && error) {
      NotificationManager.info(error, 'Ошибка', NOTIFICATION_TIMEOUT, null, null, '');
    }
  }

  componentWillUnmount(): void {
    this.props.resetAccountSettingsAction();
  }

  onUserChange = (selectedUser) => {
    this.setState({ selectedUser }, () => {
      this.props.getAccountSettingsAction(selectedUser.value);
    });
  };

  onSubmit = (values) => {
    this.props.putAccountSettingsAction(this.state.selectedUser.value, {
      ...values,
      phone: unmaskPhoneNumber(values.phone)
    });
  };

  validate = (values) => {
    const preparedValues = { ...values, phone: unmaskPhoneNumber(values.phone) };
    const { initialValues } = this.state;

    const errors: { [key: string]: string | boolean } = {};

    const changedFields = Object.keys(preparedValues).filter(
      (key: string) => preparedValues[key] !== initialValues[key]
    );

    if (!changedFields.length) {
      errors.noChanges = true;
    }

    if (!values.email) {
      errors.email = EMPTY_FIELD_ERROR;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = INVALID_EMAIL_ERROR;
    }

    if (!values.phone) {
      errors.phone = EMPTY_FIELD_ERROR;
    } else if (!/^\d+$/i.test(values.phone) && !/^\+\d+$/i.test(values.phone)) {
      errors.phone = INVALID_PHONE_ERROR;
    }

    return errors;
  };

  handleResetForm = (resetForm) => {
    const { initialValues } = this.state;
    resetForm(initialValues);
  };

  render(): ReactNode {
    const { selectedUser, initialValues } = this.state;
    const { users, data, loading, match, authUserId, authUser, t } = this.props;
    const disabled = !Boolean(selectedUser && data && !loading);
    const canManagePermissions = authUser && isAllowed(authUser, PRMISSIONS_SETTING_PERMISSSION);
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.settings" match={match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>

        {loading && <Loader />}

        <Card className="mb-4">
          <CardBody>
            <Row className="mb-4">
              <Colxx xxs="12" sm="6" lg="3">
                <FormGroup className="form-group has-float-label">
                  <Label className="float-label"> {t('settings.user')}</Label>
                  <Select
                    components={{ Input: CustomSelectInput }}
                    className="react-select"
                    classNamePrefix="react-select"
                    name="user"
                    value={selectedUser}
                    onChange={this.onUserChange}
                    options={users.data ? prepareUsersForSelect(users.data) : []}
                    placeholder=""
                    isLoading={users.loading}
                  />
                </FormGroup>
              </Colxx>
              {canManagePermissions ? (
                <Colxx xxs="12" sm="6" lg="3">
                  <FormGroup className="form-group">
                    <NavLink
                      to={{
                        pathname: '/settings/permissions',
                        state: { userId: selectedUser.value }
                      }}
                      className={`btn pl-0 ${disabled && 'disabled'}`}
                    >
                      {t('settings.configure access rights')}
                    </NavLink>
                  </FormGroup>
                </Colxx>
              ) : (
                ''
              )}
            </Row>

            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              onSubmit={this.onSubmit}
              validateOnChange={true}
              validateOnBlur={true}
              validate={this.validate}
            >
              {({ values, setFieldValue, isValid, setFieldTouched, errors, resetForm }) => (
                <Form>
                  <Row className="mb-4">
                    <Colxx xxs="12" sm="6" lg="3">
                      <FormGroup className="form-group has-float-label">
                        <Label className="float-label">{t('settings.name')}</Label>
                        <Field disabled={disabled} className="form-control" type="text" name="fio" />
                      </FormGroup>
                    </Colxx>
                    <Colxx xxs="12" sm="6" lg="3">
                      <FormGroup className="form-group has-float-label">
                        <Label className="float-label">{t('settings.login')}</Label>
                        <Field disabled={disabled} className="form-control" type="text" name="username" />
                      </FormGroup>
                    </Colxx>
                    <Colxx xxs="12" sm="6" lg="3">
                      <FormGroup className="form-group has-float-label">
                        <Label className="float-label">{t('settings.e-mail')}</Label>
                        <Field disabled={disabled} className="form-control" type="text" name="email" />
                        {errors && errors.email && <div className="invalid-feedback d-block">{errors.email}</div>}
                      </FormGroup>
                    </Colxx>
                    <Colxx xxs="12" sm="6" lg="3">
                      <FormGroup className="form-group has-float-label">
                        <Label className="float-label">{t('settings.phone number')}</Label>
                        <Field disabled={disabled} className="form-control" type="text" name="phone" />
                        {errors && errors.phone && <div className="invalid-feedback d-block">{errors.phone}</div>}
                      </FormGroup>
                    </Colxx>
                  </Row>

                  <Row className="border mx-0 mb-3 pt-3">
                    <Colxx xxs="12" sm="6" className="p-0">
                      <FormGroup className="form-group ml-4">
                        <Input
                          disabled={disabled}
                          type="radio"
                          id="auth_type_first"
                          name="auth_type"
                          checked={values.auth_type === AuthTypes.Google}
                          onChange={() => setFieldValue('auth_type', AuthTypes.Google)}
                        />
                        <Label for="auth_type_first">{t('settings.two-factor authentication')}</Label>
                      </FormGroup>
                      <FormGroup className="form-group ml-4">
                        <Input
                          disabled={disabled}
                          type="radio"
                          id="auth_type_third"
                          name="auth_type"
                          checked={values.auth_type === AuthTypes.None}
                          onChange={() => setFieldValue('auth_type', AuthTypes.None)}
                        />
                        <Label for="auth_type_third">{t('settings.without two-factor authentication')}</Label>
                      </FormGroup>
                    </Colxx>

                    <Colxx xxs="12" sm="6" className="p-0">
                      <FormGroup className="form-group ml-4">
                        <Field
                          name="allow_concurrent_sessions"
                          component={() => (
                            <CustomInput
                              id="allow_concurrent_sessions"
                              type="checkbox"
                              label={t('settings.label.parallel')}
                              disabled={disabled}
                              checked={values.allow_concurrent_sessions}
                              onChange={() =>
                                setFieldValue('allow_concurrent_sessions', !values.allow_concurrent_sessions)
                              }
                            />
                          )}
                        />
                      </FormGroup>

                      {authUserId !== selectedUser.value && (
                        <FormGroup className="form-group ml-4">
                          <Field
                            name="is_blocked"
                            component={() => (
                              <CustomInput
                                id="is_blocked"
                                label={t('settings.label.blocked')}
                                type="checkbox"
                                disabled={disabled}
                                checked={values.is_blocked}
                                onChange={() => setFieldValue('is_blocked', !values.is_blocked)}
                              />
                            )}
                          />
                        </FormGroup>
                      )}
                      <FormGroup className="form-group ml-4">
                        <Field
                          name="is_active"
                          component={() => (
                            <CustomInput
                              id="is_active"
                              type="checkbox"
                              label={t('settings.label.active')}
                              disabled={disabled}
                              checked={values.is_active}
                              onChange={() => setFieldValue('is_active', !values.is_active)}
                            />
                          )}
                        />
                      </FormGroup>
                      <FormGroup className="form-group ml-4">
                        <Field
                          name="allow_multi_partner"
                          component={() => (
                            <CustomInput
                              id="allow_multi_partner"
                              type="checkbox"
                              label={t('settings.label.multipartner')}
                              disabled={disabled}
                              checked={values.allow_multi_partner}
                              onChange={() => setFieldValue('allow_multi_partner', !values.allow_multi_partner)}
                            />
                          )}
                        />
                      </FormGroup>
                    </Colxx>
                  </Row>

                  <Row>
                    <Colxx xxs="12" className="d-flex justify-content-end">
                      <Button disabled={disabled || !isValid} color="primary" type="submit">
                        {t('settings.button.save')}
                      </Button>
                      <Button
                        className="ml-2"
                        disabled={disabled || !isValid}
                        color="light"
                        type="reset"
                        onClick={() => this.handleResetForm(resetForm)}
                      >
                        {t('settings.button.cancel')}
                      </Button>
                    </Colxx>
                  </Row>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ menu, settings, authUser }) => {
  const { users } = menu;
  const { accountSettings } = settings;

  return {
    users,
    authUserId: authUser.user.data && authUser.user.data.id,
    authUser: authUser.user.data,
    data: accountSettings.data,
    loading: accountSettings.loading,
    saving: accountSettings.saving,
    error: accountSettings.error
  };
};

const mapDispatchToProps = {
  getAccountSettingsAction: getAccountSettings,
  putAccountSettingsAction: putAccountSettings,
  resetAccountSettingsAction: resetAccountSettingsStore
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Account)
);
