import { all, call, fork, put, SimpleEffect, takeLatest } from 'redux-saga/effects';
import { CombinatorEffect } from '@redux-saga/types';

import { ApiRequest } from '../../helpers/ApiRequest/ApiRequest';

import { getAvailableSitesError, getAvailableSitesSuccess, putSitesSuccess, putSitesError } from './actions';
import { PutSitesActionResult, GetSitesActionResult } from './interface';

import { PUT_AVAILABLE_SITES, GET_AVAILABLE_SITES } from '../actions';

// AVAILABLE SITES
function* getAvailableSites({ payload }: GetSitesActionResult): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { id } = payload;
    const { error, data } = yield call(ApiRequest.get, `/users/${id}/sites/`);

    if (error) {
      yield put(getAvailableSitesError(`${error}`));
    } else {
      yield put(getAvailableSitesSuccess(data));
    }
  } catch (error) {
    yield put(getAvailableSitesError(`${error}`));
  }
}

export function* putAvailableSites({ payload }: PutSitesActionResult): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { id, sites } = payload;

    const putSitesResponse = yield call(ApiRequest.put, `/users/${id}/sites/`, { sites });
    if (!putSitesResponse.error) {
      yield put(putSitesSuccess(sites));
    } else {
      yield put(putSitesError(`${putSitesResponse.error}`));
    }
  } catch (error) {}
}

export function* watchUpdateSites(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(PUT_AVAILABLE_SITES, putAvailableSites);
}

export function* watchGetSites(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(GET_AVAILABLE_SITES, getAvailableSites);
}

export default function* rootSaga(): Generator<CombinatorEffect<string, any>, any, any> {
  yield all([fork(watchGetSites), fork(watchUpdateSites)]);
}
