import React, { Component, Fragment, ReactNode } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { ResultModalProps, ResultModalTypes } from '../interface';
import IntlMessages from '../../../helpers/IntlMessages';

export class ResultModal extends Component<ResultModalProps, any> {
  render(): ReactNode {
    const { onCloseModal, modalIsOpen, modalType, payoutId, confirmationLabel = 'payouts.paymentID' } = this.props;
    return (
      <Modal isOpen={modalIsOpen} toggle={onCloseModal}>
        <ModalHeader toggle={onCloseModal}>
          <Fragment>
            <i className={`iconsminds-${modalType === ResultModalTypes.Success ? 'yes' : 'refresh'} mr-1`} />
            <IntlMessages
              id={`payouts.resultHeader${modalType === ResultModalTypes.Success ? 'Success' : 'Failure'}`}
            />
          </Fragment>
        </ModalHeader>
        <ModalBody>
          <div style={{ fontSize: 15 }}>
            <IntlMessages id={confirmationLabel} /> {payoutId} <IntlMessages id={`payouts.result${modalType}`} />
          </div>
        </ModalBody>
      </Modal>
    );
  }
}
