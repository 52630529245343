import React from 'react';
import { Redirect, Route, Switch } from 'react-router';

import Gcm from './System/Gcm/Gcm';
import System from './System/System';
import Permissions from './Permissions/Permissions';
import Account from './Account/Account';
import Currencies from './Currencies/Currencies';
import Services from './Services/Services';
import CustomDesign from './CustomDesign/CustomDesign';

const Settings = ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}/gcm`} component={Gcm} />
    <Route path={`${match.url}/system`} component={System} />
    <Route exact path={`${match.url}/permissions`} component={Permissions} />
    <Route exact path={`${match.url}/account`} component={Account} />
    <Route exact path={`${match.url}/currencies`} component={Currencies} />
    <Route exact path={`${match.url}/services`} component={Services} />
    <Route path={`${match.url}/custom-design`} component={CustomDesign} />
    <Redirect from={match.url} to={`${match.url}/permissions`} />
    <Redirect to={'/error'} />
  </Switch>
);

export default Settings;
