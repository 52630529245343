import { WithTranslation } from 'react-i18next';

export interface PasswordStrengthMeterProps extends WithTranslation {
  value: string;
}

export const LOW_STRENGTH = 1;
export const MIDDLE_STRENGTH = 2;
export const HIGH_STRENGTH = 3;

export enum StrengthLevel {
  Default = 0,
  Low = LOW_STRENGTH,
  Middle = MIDDLE_STRENGTH,
  High = HIGH_STRENGTH
}
