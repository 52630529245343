import React from 'react';
import { CustomInput } from 'reactstrap';
import { CellInfo } from 'react-table';
import { TreeNode } from 'react-dropdown-tree-select';

export const getCurrenciesColumns = (
  handleCurrencyChanged: (
    id: number,
    isIncomeActive: boolean,
    isOutcomeActive: boolean,
    isDisposalActive: boolean
  ) => void,
  t: any
) => [
  {
    Header: t('settings.currencyId'),
    accessor: 'id'
  },
  {
    Header: t('settings.currency'),
    accessor: 'name'
  },
  {
    Header: t('settings.country'),
    accessor: 'country',
    Cell: ({ value, original }: CellInfo) => t(`currency.country.${value}`)
  },
  {
    Header: t('settings.acquiring'),
    accessor: 'is_income_active',
    Cell: ({ value, original }: CellInfo) => (
      <CustomInput
        type="checkbox"
        key={`${original.id}_is_income_active`}
        id={`${original.id}_is_income_active`}
        name={`${original.id}_is_income_active`}
        defaultChecked={value}
        onChange={({ target: { checked } }) =>
          handleCurrencyChanged(original.id, checked, original.is_outcome_active, original.is_disposals_active)
        }
      />
    )
  },
  {
    Header: t('settings.payouts'),
    accessor: 'is_outcome_active',
    Cell: ({ value, original }: CellInfo) => (
      <CustomInput
        type="checkbox"
        key={`${original.id}is_outcome_active`}
        id={`${original.id}is_outcome_active`}
        name={`${original.id}is_outcome_active`}
        defaultChecked={value}
        onChange={({ target: { checked } }) =>
          handleCurrencyChanged(original.id, original.is_income_active, checked, original.is_disposals_active)
        }
      />
    )
  },
  {
    Header: t('settings.disposals'),
    accessor: 'is_disposals_active',
    Cell: ({ value, original }: CellInfo) => (
      <CustomInput
        type="checkbox"
        key={`${original.id}is_disposals_active`}
        id={`${original.id}is_disposals_active`}
        name={`${original.id}is_disposals_active`}
        defaultChecked={value}
        onChange={({ target: { checked } }) =>
          handleCurrencyChanged(original.id, original.is_income_active, original.is_outcome_active, checked)
        }
      />
    )
  }
];

export const getOneLevelSelectedData = (selectedValues: TreeNode[]) => {
  if (selectedValues && selectedValues.length === 1 && selectedValues[0].value === '') {
    return selectedValues[0].value;
  } else {
    return selectedValues && selectedValues.length
      ? { value: selectedValues.map((item: TreeNode) => item.value).flat() }
      : null;
  }
};
