import React, { Component, Fragment, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { Row, Card, CardBody, Button, FormGroup, Label } from 'reactstrap';
import isEqual from 'lodash/isEqual';
import { getPayoutAccounts, getBalanceOperation, getBalanceStat } from '../../../redux/actions';
import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import { NotFoundMessage } from '../../../components/common/NotFoundMessage';
import Breadcrumb from '../../../containers/navs/Breadcrumb';
import { Loader } from '../../../components/common/Loader';
import { getFieldErrorsCount, toISOString, setDateFrom, setDateTo, roundAmount } from '../../../helpers/Utils';
import { NotificationManager } from '../../../components/common/react-notifications';
import { NOTIFICATION_TIMEOUT } from '../../../constants/defaultValues';
import {
  TIMEZONE_TOOLTIP_TEXT,
  DATE_TIME_FORMAT_WITH_SEC,
  FULL_DATETIME_MASK,
  EMPTY_FIELD_ERROR
} from '../../../constants/app';
import { floatFieldValue } from '../../operations/helpers';
import InputMask from 'react-input-mask';
import { getAccountSelectData, getBalanceOperationTableColumns, DEFAULT_SORTER } from '../helpers';
import { CustomDropdownTreeSelect } from '../../../components/common/CustomDropdownTreeSelect';
import { DatePickerLocaled } from '../../../components/common/DatePickerLocaled/DatePickerLocaled';
import { BalanceOperationsProps, BalanceOperationsState } from './interface';
import ReactTable from 'react-table';
import { BalanceOperationRequestValues } from '../../../redux/balance/interface';
import DataTablePagination from '../../../components/DatatablePagination';

import { OPERATION_TYPE_OPTIONS, BALANCE_OPERATIONS_INITIAL_VALUES } from '../constants';
import Select from 'react-select';
import { SERVICE_TYPES_CODES } from '../../services/ServicesList/constant';
import { withTranslation } from 'react-i18next';
class BalanceOperations extends Component<BalanceOperationsProps, BalanceOperationsState> {
  state: BalanceOperationsState = {
    submit: false,
    page: 0,
    accountOptions: [],
    sorted: [{ id: 'dt', desc: true }],
    sorting: '',
    operationRequestValues: null
  };
  TargetRef = React.createRef<HTMLDivElement>();
  setFieldValue;
  resetForm;
  serviceType;

  componentDidMount(): void {
    const { merchantIdList, getPayoutAccountsAction, selectedUserId, isAdmin, authUserId } = this.props;

    if (merchantIdList && merchantIdList.length) {
      getPayoutAccountsAction(
        merchantIdList,
        isAdmin && selectedUserId && authUserId !== selectedUserId ? selectedUserId : undefined,
        SERVICE_TYPES_CODES.OUTCOME
      );
    }

    this.setState({ submit: false });
  }

  componentDidUpdate(prevProps: Readonly<BalanceOperationsProps>, prevState: Readonly<BalanceOperationsState>): void {
    const {
      merchantIdList,
      getPayoutAccountsAction,
      selectedUserId,
      isAdmin,
      authUserId,
      error,
      accounts
    } = this.props;

    if (!isEqual(prevProps.merchantIdList, merchantIdList) || prevProps.selectedUserId !== selectedUserId) {
      if (merchantIdList && merchantIdList.length && selectedUserId) {
        this.resetForm();
        this.setState({ submit: false });
      }

      if (merchantIdList && merchantIdList.length) {
        getPayoutAccountsAction(
          merchantIdList,
          isAdmin && selectedUserId && authUserId !== selectedUserId ? selectedUserId : undefined,
          SERVICE_TYPES_CODES.OUTCOME
        );
      }
    }

    if (prevProps.accounts.data !== accounts.data && !accounts.loading && !accounts.error) {
      this.setState({ accountOptions: getAccountSelectData(accounts.data) }, () => {
        if (this.state.accountOptions) {
          this.setFieldValue('account', this.state.accountOptions[0]);
        }
      });
    }

    if (error && error !== prevProps.error) {
      NotificationManager.info(error, 'Ошибка', NOTIFICATION_TIMEOUT, null, null, '');
    }
  }

  onSubmit = (values): void => {
    const {
      getBalanceOperationAction,
      getBalanceStatAction,
      selectedUserId,
      isAdmin,
      authUserId,
      maxPageSize,
      merchantIdList
    } = this.props;

    const requestParams: BalanceOperationRequestValues = {
      dateFrom: toISOString(values.dateFrom),
      dateTo: toISOString(values.dateTo),
      sorting: '',
      offset: 0,
      limit: maxPageSize
    };

    if (isAdmin && authUserId !== selectedUserId) {
      requestParams.userId = selectedUserId;
    }

    if (merchantIdList.length) {
      requestParams.partnerIds = merchantIdList;
    }

    if (values.account && values.account.value !== '') {
      requestParams.account = values.account.value;
    }

    if (values.operationType[0].value !== '') {
      requestParams.operationType = values.operationType.map((item: any) => item.value);
    }

    if (values.amount !== '') {
      requestParams.amount = Number(values.amount);
    }

    this.setState({ operationRequestValues: requestParams, page: 0 });

    getBalanceOperationAction(requestParams);
    getBalanceStatAction(requestParams);

    this.setState({ submit: true });
  };

  validate = (values) => {
    const errors: { [key: string]: string } = {};

    if (values.account.length === 0) {
      errors.account = EMPTY_FIELD_ERROR;
    }

    if (values.operationType.length === 0) {
      errors.operationType = EMPTY_FIELD_ERROR;
    }

    return errors;
  };

  setNewPage = (pageNumber: number, sort?: string) => {
    const { operationRequestValues, sorting } = this.state;
    const { getBalanceOperationAction, maxPageSize } = this.props;
    if (operationRequestValues) {
      operationRequestValues.offset = pageNumber * maxPageSize;
      operationRequestValues.sorting = sort || sorting;
      this.setState({ operationRequestValues });
      getBalanceOperationAction(operationRequestValues);
    }
    this.setState({ page: pageNumber });
  };

  setSorting = (newSorted) => {
    const sorting = `${newSorted[0].id} ${newSorted[0].desc ? 'DESC' : 'ASC'}`;
    this.setState({ sorted: newSorted, sorting });
    this.setNewPage(0, sorting);
  };

  getAccountList = () => {
    const { accounts } = this.props;

    const items: any = {};
    Object.values(accounts.data).forEach((dataItems: any) => {
      dataItems.forEach((item: any) => {
        items[item.id] = item.name;
      });
    });

    return items;
  };

  render(): ReactNode {
    const {
      loading,
      operations,
      commonStat,
      match,
      merchantIdList,
      makeRequest,
      totalCount,
      maxPageSize,
      t
    } = this.props;
    const { accountOptions, page, submit, sorted } = this.state;

    const currentRows = operations ? operations : [];
    const pageSize = currentRows.length < maxPageSize ? currentRows.length : maxPageSize;

    return (
      <Fragment>
        {loading && <Loader />}

        <Row className="flex-shrink-0">
          <Colxx xxs="12">
            <Breadcrumb heading="menu.balance" match={match} />
            <div className="time-tip  d-none d-lg-inline-block">{t(TIMEZONE_TOOLTIP_TEXT)}</div>
            <Separator className="mb-5" />
          </Colxx>
        </Row>

        {merchantIdList && merchantIdList.length > 0 && (
          <Formik initialValues={BALANCE_OPERATIONS_INITIAL_VALUES} onSubmit={this.onSubmit} validate={this.validate}>
            {({ values, setFieldValue, errors, touched, setFieldTouched, resetForm }) => {
              this.setFieldValue = setFieldValue;
              this.resetForm = resetForm;

              return (
                <>
                  <Form>
                    <Card className="mb-4">
                      <CardBody>
                        <Row>
                          <Colxx xxs="12" sm="6">
                            <FormGroup className="has-float-label">
                              <Label className="float-label">{t('balance.date from')}</Label>
                              <DatePickerLocaled
                                name="dateFrom"
                                selected={values.dateFrom}
                                autoComplete="off"
                                dateFormat={DATE_TIME_FORMAT_WITH_SEC}
                                customInput={<InputMask mask={FULL_DATETIME_MASK} />}
                                maxDate={values.dateTo ? values.dateTo : new Date()}
                                onChange={(d: Date) => {
                                  const dateFrom = setDateFrom(values.dateFrom, d);
                                  setFieldValue('dateFrom', dateFrom);
                                }}
                                onSelect={(d: Date) => {
                                  const dateFrom = setDateFrom(values.dateFrom, d);
                                  setFieldValue('dateFrom', dateFrom);
                                }}
                              />
                              {errors.dateFrom && <div className="invalid-feedback d-block">{errors.dateFrom}</div>}
                            </FormGroup>
                          </Colxx>
                          <Colxx xxs="12" sm="6">
                            <FormGroup className="has-float-label">
                              <Label className="float-label">{t('balance.date by')}</Label>
                              <DatePickerLocaled
                                name="dateTo"
                                selected={values.dateTo}
                                autoComplete="off"
                                dateFormat={DATE_TIME_FORMAT_WITH_SEC}
                                customInput={<InputMask mask={FULL_DATETIME_MASK} />}
                                minDate={values.dateFrom ? values.dateFrom : undefined}
                                maxDate={new Date()}
                                onChange={(d: Date) => {
                                  const dateTo = setDateTo(values.dateTo, d);
                                  setFieldValue('dateTo', dateTo);
                                }}
                                onSelect={(d: Date) => {
                                  const dateTo = setDateTo(values.dateTo, d);
                                  setFieldValue('dateTo', dateTo);
                                }}
                              />
                              {errors.dateTo && touched.dateTo && (
                                <div className="invalid-feedback d-block">{errors.dateTo}</div>
                              )}
                            </FormGroup>
                          </Colxx>
                          <Colxx>
                            <FormGroup className="has-float-label">
                              <Label className="float-label">{t('balance.account')}</Label>
                              <Select
                                className="react-select"
                                classNamePrefix="react-select"
                                name="account"
                                value={values.account}
                                options={accountOptions}
                                onChange={(selectedValue) => {
                                  setFieldValue('account', selectedValue);
                                }}
                                placeholder=""
                              />
                              {errors.account && <div className="invalid-feedback d-block">{errors.account}</div>}
                            </FormGroup>
                          </Colxx>
                          <Colxx>
                            <FormGroup className="has-float-label">
                              <Label className="float-label">{t('balance.type of operation')}</Label>
                              <CustomDropdownTreeSelect
                                name="operationType"
                                data={OPERATION_TYPE_OPTIONS(t)}
                                onChange={(changedValue, selectedValues) =>
                                  setFieldValue('operationType', selectedValues)
                                }
                              />
                              {errors.operationType && (
                                <div className="invalid-feedback d-block">{errors.operationType}</div>
                              )}
                            </FormGroup>
                          </Colxx>
                          <Colxx>
                            <FormGroup className="has-float-label">
                              <Label className="float-label">{t('balance.amount_income')}</Label>
                              <Field
                                className="form-control"
                                type="text"
                                name="amount"
                                onChange={({ target: { value } }) => floatFieldValue(value, 'amount', setFieldValue)}
                                onBlur={() => setFieldTouched('amount', true)}
                              />
                            </FormGroup>
                          </Colxx>

                          <Colxx xxs="12" className="d-flex justify-content-end">
                            <Button disabled={!!getFieldErrorsCount(errors) || loading} color="primary" type="submit">
                              {t('button.search')}
                            </Button>
                          </Colxx>
                        </Row>
                      </CardBody>
                    </Card>
                  </Form>
                </>
              );
            }}
          </Formik>
        )}

        {submit && !loading && merchantIdList && merchantIdList.length > 0 && commonStat && (
          <Card className="mb-4">
            <CardBody>
              <Row className="mb-2 pb-2 border-bottom">
                <Colxx xxs="12" xs="7" sm="9" className="border-right">
                  {t('balance.Balance')}
                </Colxx>
                <Colxx xxs="12" xs="5" sm="3">
                  {roundAmount(commonStat.startBalanceValue)}
                </Colxx>
              </Row>
              <Row className="mb-2 pb-2 border-bottom">
                <Colxx xxs="12" xs="7" sm="9" className="border-right">
                  {t('balance.Deposit')}
                </Colxx>
                <Colxx xxs="12" xs="5" sm="3">
                  {roundAmount(commonStat.enrollmentAmount)}
                </Colxx>
              </Row>
              <Row className="mb-2 pb-2 border-bottom">
                <Colxx xxs="12" xs="7" sm="9" className="border-right">
                  {t('balance.Unholded')}
                </Colxx>
                <Colxx xxs="12" xs="5" sm="3">
                  {roundAmount(commonStat.unholdingAmount)}
                </Colxx>
              </Row>
              <Row className="mb-2 pb-2 border-bottom">
                <Colxx xxs="12" xs="7" sm="9" className="border-right">
                  {t('balance.Balance at date by')}
                </Colxx>
                <Colxx xxs="12" xs="5" sm="3">
                  {roundAmount(commonStat.endBalanceValue)}
                </Colxx>
              </Row>
              <Row className="mb-2 pb-2 border-bottom">
                <Colxx xxs="12" xs="7" sm="9" className="border-right">
                  {t('balance.Including')}
                </Colxx>
                <Colxx xxs="12" xs="5" sm="3">
                  {roundAmount(commonStat.commission)}
                </Colxx>
              </Row>
            </CardBody>
          </Card>
        )}

        {submit && !loading && merchantIdList && merchantIdList.length > 0 && currentRows && currentRows.length > 0 && (
          <Row className="h-100">
            <Colxx xxs="12" className="mb-4">
              <Card className="full-width">
                <CardBody>
                  <ReactTable
                    data={currentRows}
                    columns={getBalanceOperationTableColumns(this.getAccountList(), t)}
                    page={page}
                    pages={Math.ceil(totalCount / maxPageSize)}
                    defaultPageSize={pageSize}
                    pageSize={pageSize}
                    defaultSorted={DEFAULT_SORTER}
                    loading={loading}
                    sortable={true}
                    filterable={false}
                    showPageJump={true}
                    onSortedChange={this.setSorting}
                    sorted={sorted}
                    showPagination={true}
                    PaginationComponent={DataTablePagination}
                    onPageChange={this.setNewPage}
                    showPageSizeOptions={false}
                    manual
                    loadingText=""
                    noDataText={t('select.no_data')}
                  />
                </CardBody>
              </Card>
            </Colxx>
          </Row>
        )}

        {!(merchantIdList && merchantIdList.length) && <NotFoundMessage text={t('no_partners_selected')} />}
        {makeRequest && (!operations || !operations.length) && <NotFoundMessage />}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ balance, settings, authUser, operations }) => ({
  isAdmin: authUser.user.data && authUser.user.data.is_admin ? authUser.user.data.is_admin : false,
  selectedUserId: settings.selectedUser ? settings.selectedUser.id : 0,
  authUserId: authUser.user.data ? authUser.user.data.id : 0,
  merchantIdList: settings.selectedMerchantsId,
  maxPageSize: settings.gcmSettings.data ? settings.gcmSettings.data.max_page_size : 50,
  accounts: operations.payoutAccounts,
  loading: balance.loading || operations.payoutAccounts.loading,
  operations: balance.operations,
  commonStat: balance.commonStat,
  totalCount: balance.totalCount,
  selectedMerchants: settings.selectedMerchants,
  makeRequest: balance.makeRequest,
  error: balance.error
});

const mapDispatchToProps = {
  getPayoutAccountsAction: getPayoutAccounts,
  getBalanceOperationAction: getBalanceOperation,
  getBalanceStatAction: getBalanceStat
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BalanceOperations as any)
);
