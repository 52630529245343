import {
  GET_SERVICES_BY_NAME,
  GET_SERVICES_BY_MERCHANT,
  GET_PAYOUT_ACCOUNTS_BY_SERVICE,
  GET_PAYOUT_ACCOUNTS_BY_SERVICE_SUCCESS,
  GET_PAYOUT_ACCOUNTS_BY_SERVICE_ERROR,
  SET_PAYOUT_ACCOUNTS_LAST_OPERATION,
  GET_ACCOUNTS_BALANCE,
  GET_ACCOUNTS_BALANCE_SUCCESS,
  GET_ACCOUNTS_BALANCE_ERROR
} from '../actions';
import {
  GetServicesByNameAction,
  GetServiceByMerchantAction,
  GetPayoutAccountsByServiceAction,
  GetPayoutAccountsByServiceSuccessAction,
  GetPayoutAccountsByServiceErrorAction,
  PayoutAccountRow,
  SetPayoutAccountsLastOperationAction,
  AccountBalance,
  GetAccountBalance,
  GetAccountsBalanceSuccess,
  GetAccountBalanceError
} from './interface';

export const getServicesByName = (name: string, offset: number, count: number): GetServicesByNameAction => ({
  type: GET_SERVICES_BY_NAME,
  payload: { name, offset, count }
});

export const getServicesByMerchant = (merchantId: string): GetServiceByMerchantAction => ({
  type: GET_SERVICES_BY_MERCHANT,
  payload: { merchantId }
});

export const getServicesSuccess = (type, data): any => ({
  type: `${type}_SUCCESS`,
  payload: { data }
});

export const getServicesError = (type, error: string): any => ({
  type: `${type}_ERROR`,
  payload: { error }
});

// GET PAYOUT ACCOUNTS BY SERVICE
export const getPayoutAccountsByService = (
  serviceId: string,
  merchantId: string,
  count: number,
  offset: number = 0
): GetPayoutAccountsByServiceAction => ({
  type: GET_PAYOUT_ACCOUNTS_BY_SERVICE,
  payload: { serviceId, merchantId, count, offset }
});

export const getPayoutAccountsByServiceSuccess = (
  data: PayoutAccountRow[]
): GetPayoutAccountsByServiceSuccessAction => ({
  type: GET_PAYOUT_ACCOUNTS_BY_SERVICE_SUCCESS,
  payload: { data }
});

export const getPayoutAccountsByServiceError = (error: string): GetPayoutAccountsByServiceErrorAction => ({
  type: GET_PAYOUT_ACCOUNTS_BY_SERVICE_ERROR,
  payload: { error }
});

export const setPayoutAccountsLastOperation = (data: PayoutAccountRow): SetPayoutAccountsLastOperationAction => ({
  type: SET_PAYOUT_ACCOUNTS_LAST_OPERATION,
  payload: { data }
});

// ACCOUNTS BALANCE
export const getAccountsBalance = (merchantIds: number[], userId?: number): GetAccountBalance => ({
  type: GET_ACCOUNTS_BALANCE,
  payload: {
    merchantIds,
    userId
  }
});

export const getAccountsBalanceSuccess = (data: AccountBalance): GetAccountsBalanceSuccess => ({
  type: GET_ACCOUNTS_BALANCE_SUCCESS,
  payload: { data }
});

export const getAccountsBalanceError = (error: string): GetAccountBalanceError => ({
  type: GET_ACCOUNTS_BALANCE_ERROR,
  payload: { error }
});
