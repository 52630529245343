import React, { Component, ReactNode } from 'react';
import { Row, Card, CardBody, CardTitle } from 'reactstrap';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import IntlMessages from '../../../helpers/IntlMessages';
import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import { NotFoundMessage } from '../../../components/common/NotFoundMessage';
import { Loader } from '../../../components/common/Loader';
import Breadcrumb from '../../../containers/navs/Breadcrumb';
import DataTablePagination from '../../../components/DatatablePagination';
import { getAcquiringHistory } from '../../../redux/actions';

import { TABLE_COLUMNS } from './constants';
import { AcquiringHistoryProps } from './interface';

class AcquiringHistory extends Component<AcquiringHistoryProps> {
  componentDidMount(): void {
    const { merchantIdList, match, getAcquiringHistoryAction, isAdmin, authUserId, selectedUserId } = this.props;

    const userId = isAdmin && authUserId !== selectedUserId ? selectedUserId : undefined;
    merchantIdList &&
      merchantIdList.length &&
      getAcquiringHistoryAction(merchantIdList, match.params.orderNumber, userId);
  }

  onNextPage = (): void => {
    const { merchantIdList, match, getAcquiringHistoryAction, isAdmin, authUserId, selectedUserId } = this.props;

    const userId = isAdmin && authUserId !== selectedUserId ? selectedUserId : undefined;
    merchantIdList &&
      merchantIdList.length &&
      getAcquiringHistoryAction(merchantIdList, match.params.orderNumber, userId);
  };

  render(): ReactNode {
    const { loading, error, rows, match, history } = this.props;

    return (
      <div className="d-flex flex-column h-75 position-relative">
        {loading && <Loader />}

        <Row className="flex-shrink-0">
          <Colxx xxs="12">
            <Breadcrumb heading="menu.history" match={match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>

        <Row>
          <Colxx xxs="5" className="mb-5">
            <NavLink to="#" onClick={history.goBack} className="d-flex align-items-center">
              <div className="glyph-icon simple-icon-arrow-left mr-2" />
              Вернуться к списку операций
            </NavLink>
          </Colxx>
        </Row>

        {error.length > 0 && (
          <Row className="flex-grow-1">
            <Colxx xxs="12" className="mb-4">
              {error}
            </Colxx>
          </Row>
        )}

        {rows && rows.length > 0 && (
          <div className="flex-grow-1">
            <Row>
              <Colxx xxs="12" className="mb-4">
                <Card className="w-100">
                  <CardBody>
                    <CardTitle>
                      <IntlMessages id="operations.history.order" /> № {match.params.orderNumber}
                    </CardTitle>
                    <ReactTable
                      data={rows}
                      columns={TABLE_COLUMNS}
                      defaultPageSize={rows.length}
                      sortable={false}
                      filterable={false}
                      showPageJump={true}
                      showPagination={false}
                      PaginationComponent={DataTablePagination}
                      showPageSizeOptions={false}
                      loading={loading}
                      manual
                      onPageChange={this.onNextPage}
                      loadingText=""
                      noDataText="Нет данных"
                    />
                  </CardBody>
                </Card>
              </Colxx>
            </Row>
            {/*<Row> TODO Пока не готово на бэке
              <Colxx xxs="12" className="mb-4">
                <Button color="primary" className="mb-2 mr-2" size="sm" disabled>
                  <IntlMessages id="button.getRefund" />
                </Button>
                <Button color="primary" className="mb-2" size="sm" disabled>
                  <IntlMessages id="button.sendCallback" />
                </Button>
              </Colxx>
            </Row>*/}
          </div>
        )}

        {rows && !rows.length && <NotFoundMessage />}
      </div>
    );
  }
}

const mapStateToProps = ({ operations, authUser, settings }) => ({
  isAdmin: authUser.user.data && authUser.user.data.is_admin ? authUser.user.data.is_admin : false,
  selectedUserId: settings.selectedUser ? settings.selectedUser.id : 0,
  merchantIdList: settings.selectedMerchantsId,
  authUserId: authUser.user.data ? authUser.user.data.id : 0,
  loading: operations.acquiringHistory.loading,
  error: operations.acquiringHistory.error,
  rows: operations.acquiringHistory.rows,
  total: operations.acquiringHistory.total
});

const mapDispatchToProps = {
  getAcquiringHistoryAction: getAcquiringHistory
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AcquiringHistory);
