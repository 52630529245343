import React, { Component, Fragment, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Button, Card, CardBody, FormGroup, Label, Row } from 'reactstrap';
import { Form, Field, Formik } from 'formik';
import ReactTable from 'react-table';
import Select from 'react-select';
import isEqual from 'lodash/isEqual';

import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import { NotificationManager } from '../../../components/common/react-notifications';
import { NotFoundMessage } from '../../../components/common/NotFoundMessage';
import { Loader } from '../../../components/common/Loader';
import Breadcrumb from '../../../containers/navs/Breadcrumb';
import DataTablePagination from '../../../components/DatatablePagination';
import {
  getGlossaryContracts,
  getGlossaryPartners,
  getPayoutAccounts,
  resetGlossaryContracts
} from '../../../redux/actions';

import { getGlossaryContractsColumns, INITIAL_VALUES, DEFAULT_SORTER } from './constants';
import { ALL_OPTION } from '../constants';
import { ContractsProps, ContractsState } from './interface';
import { NOTIFICATION_TIMEOUT } from '../../../constants/defaultValues';
import { Service } from '../../../redux/services/interface';
import { getAccountOptions, getPartnerNameOptions } from './helpers';
import { SERVICE_TYPES_CODES, SERVICE_TYPES_NAMES } from '../../services/ServicesList/constant';
import { SelectorOption } from '../../../interfaces/app';
import { withTranslation } from 'react-i18next';

class Contracts extends Component<ContractsProps, ContractsState> {
  state: ContractsState = { page: 0, expanded: {} };
  resetForm;

  componentDidUpdate(prevProps: ContractsProps): void {
    const {
      error,
      merchantIdList,
      selectedUserId,
      getAccountsAction,
      isAdmin,
      authUserId,
      resetGlossaryContractsAction
    } = this.props;

    if (error && error !== prevProps.error) {
      NotificationManager.info(error, 'Ошибка', NOTIFICATION_TIMEOUT, null, null, '');
    }

    if (!isEqual(prevProps.merchantIdList, merchantIdList) || prevProps.selectedUserId !== selectedUserId) {
      this.resetForm();
      resetGlossaryContractsAction();

      if (merchantIdList) {
        getAccountsAction(
          merchantIdList,
          isAdmin && selectedUserId && authUserId !== selectedUserId ? selectedUserId : undefined
        );
      }
    }
  }

  componentWillUnmount(): void {
    this.props.resetGlossaryContractsAction();
    this.getPartnerAccounts(0);
  }

  onFindGlossaryContracts = (values) => {
    this.props.getGlossaryContracts({
      account_id: values.account_id.key ? values.account_id.key : undefined,
      partner_name: values.partner_name.value,
      contract_name: values.contract_name
    });
    this.setState({ page: 0 });
  };

  setNewPage = (pageNumber: number) => {
    this.setState({ page: pageNumber });
  };

  getData = (data: [string, Service][]) =>
    data.flatMap((obj: [string, Service]) => {
      if (!obj.length || !obj[1][0]) return [];
      return {
        contract_name: obj[0],
        contract_date: obj[1][0].contract_date,
        partner_name: obj[1][0].partner_name,
        accounts: obj[1]
      };
    });

  getSubComponent = (data: Service[]) =>
    data &&
    data.length && (
      <div className="d-flex justify-content-end mb-1 mr-4">
        <table className="sub-component-table">
          <thead>
            <tr key="head">
              <th key="col1">
                <b>{this.props.t('contracts.account')}</b>
              </th>
              <th key="col2">
                <b>{this.props.t('contracts.service_type')}</b>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((value: Service, index: number) => (
              <tr key={index}>
                <td key={value.name}>{value.name}</td>
                <td key={value.service_type}>
                  {this.props.t(SERVICE_TYPES_NAMES[SERVICE_TYPES_CODES[value.service_type]])}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );

  getPartnerAccounts = (partnerId: number) => {
    const { isAdmin, selectedUserId, authUserId, getAccountsAction, merchantIdList } = this.props;

    getAccountsAction(
      partnerId ? [partnerId] : merchantIdList,
      isAdmin && selectedUserId && authUserId !== selectedUserId ? selectedUserId : undefined
    );
  };

  render(): ReactNode {
    const { loading, rows, match, partners, accounts, maxPageSize, t } = this.props;
    const { page } = this.state;

    const currentPageData = rows && Object.keys(rows).length ? this.getData(Object.entries(rows)) : [];
    const elementsLeft = currentPageData ? currentPageData.length - page * maxPageSize : 0;
    const pageSize = elementsLeft >= maxPageSize ? maxPageSize : elementsLeft;

    return (
      <div className="position-relative">
        {loading && <Loader />}

        <Row className="flex-shrink-0">
          <Colxx xxs="12">
            <Breadcrumb heading="menu.glossary" match={match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>

        <Row className="flex-shrink-0">
          <Colxx xxs="12" className="mb-4">
            <Formik initialValues={INITIAL_VALUES(t)} onSubmit={this.onFindGlossaryContracts} isInitialValid={true}>
              {({ isValid, errors, touched, setFieldValue, resetForm, values }) => {
                this.resetForm = resetForm;

                return (
                  <Fragment>
                    <Form>
                      <Card className="mb-4">
                        <CardBody>
                          <Row>
                            <Colxx xxs="12" sm="6" lg="4">
                              <FormGroup className="form-group has-float-label">
                                <Label className="float-label">{t('contracts.number')}</Label>
                                <Field className="form-control" type="text" name="contract_name" maxLength="255" />
                                {errors.contract_name && touched.contract_name && (
                                  <div className="invalid-feedback d-block">{errors.contract_name}</div>
                                )}
                              </FormGroup>
                            </Colxx>
                            <Colxx xxs="12" sm="6" lg="4">
                              <FormGroup className="form-group has-float-label">
                                <Label className="float-label">{t('contracts.partner')}</Label>
                                <Select
                                  className="react-select"
                                  classNamePrefix="react-select"
                                  name="partner_name"
                                  value={values.partner_name}
                                  options={getPartnerNameOptions(partners, t)}
                                  onChange={(partner: SelectorOption) => {
                                    setFieldValue('partner_name', partner);
                                    setFieldValue('account_id', ALL_OPTION(t));
                                    this.getPartnerAccounts(partner.key);
                                  }}
                                  placeholder=""
                                  maxLength="255"
                                  noOptionsMessage={() => {
                                    t('select.no_data');
                                  }}
                                />
                                {errors.partner_name && touched.partner_name && (
                                  <div className="invalid-feedback d-block">{errors.partner_name}</div>
                                )}
                              </FormGroup>
                            </Colxx>
                            <Colxx xxs="12" sm="6" lg="4">
                              <FormGroup className="form-group has-float-label">
                                <Label className="float-label">{t('contracts.account')}</Label>
                                <Select
                                  className="react-select"
                                  classNamePrefix="react-select"
                                  name="account_id"
                                  value={values.account_id}
                                  onChange={(account: SelectorOption) => {
                                    setFieldValue('account_id', account);
                                  }}
                                  options={getAccountOptions(accounts.data, t)}
                                  placeholder=""
                                  maxLength="255"
                                  noOptionsMessage={() => {
                                    t('select.no_data');
                                  }}
                                />
                                {errors.account_id && touched.account_id && (
                                  <div className="invalid-feedback d-block">{errors.account_id}</div>
                                )}
                              </FormGroup>
                            </Colxx>
                            <Colxx xxs="12" className="d-flex justify-content-end">
                              <Button type="submit" color="primary" disabled={!isValid}>
                                <span className="label">{t('button.find')}</span>
                              </Button>
                            </Colxx>
                          </Row>
                        </CardBody>
                      </Card>
                    </Form>

                    {rows && Object.keys(rows).length > 0 && (
                      <Card className="mb-4">
                        <CardBody>
                          <ReactTable
                            data={currentPageData}
                            columns={getGlossaryContractsColumns(t)}
                            page={page}
                            pages={Math.ceil(Object.keys(rows).length / maxPageSize)}
                            defaultPageSize={pageSize}
                            pageSize={pageSize}
                            sortable={true}
                            filterable={false}
                            showPageJump={true}
                            defaultSorted={DEFAULT_SORTER}
                            showPagination={true}
                            showPageSizeOptions={false}
                            PaginationComponent={DataTablePagination}
                            loading={loading}
                            onPageChange={(newPage: number) => {
                              this.setNewPage(newPage);
                              this.setState({ expanded: {} });
                            }}
                            onExpandedChange={(expanded: object) => this.setState({ expanded })}
                            expanded={this.state.expanded}
                            SubComponent={({ original }) => this.getSubComponent(original.accounts)}
                            loadingText={''}
                            noDataText={t('select.no_data')}
                          />
                        </CardBody>
                      </Card>
                    )}

                    {rows && !Object.values(rows).length && <NotFoundMessage />}
                  </Fragment>
                );
              }}
            </Formik>
          </Colxx>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ glossary, settings, menu, operations, authUser }) => ({
  isAdmin: authUser.user.data ? authUser.user.data.is_admin : false,
  selectedUserId: settings.selectedUser ? settings.selectedUser.id : null,
  authUserId: authUser.user.data ? authUser.user.data.id : null,
  maxPageSize: settings.gcmSettings.data ? settings.gcmSettings.data.max_page_size : 50,
  loading: glossary.contracts.loading,
  error: glossary.contracts.error,
  rows: glossary.contracts.rows,
  partners: menu.merchants.data,
  merchantIdList: settings.selectedMerchantsId,
  accounts: operations.payoutAccounts
});

const mapDispatchToProps = {
  getAccountsAction: getPayoutAccounts,
  getPartnersAction: getGlossaryPartners,
  getGlossaryContracts,
  resetGlossaryContractsAction: resetGlossaryContracts
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Contracts)
);
