import React, { Component } from 'react';

import { TextMessage } from './TextMessage';
import { FileMessage } from './FileMessage';

import { MessageSender, MessageType, SenderKeys } from '../../../views/support/constants';
import { Message as IMessage } from '../../../views/support/interface';

export default class Message extends Component<{ message: IMessage }> {
  renderMessageOfType() {
    const { message } = this.props;

    switch (message.type) {
      case MessageType.Text:
        return <TextMessage {...message} />;
      case MessageType.File:
        return <FileMessage {...message} />;
      default:
        if (message.file_name && message.file_link) {
          return <FileMessage {...message} />;
        } else {
          return <TextMessage {...message} />;
        }
    }
  }

  render() {
    const { message } = this.props;
    const contentClassList = [
      'sc-message--content',
      message.source === SenderKeys.Me ? MessageSender.Sent : MessageSender.Received
    ];

    return (
      <div className="sc-message">
        <div className={contentClassList.join(' ')}>{this.renderMessageOfType()}</div>
      </div>
    );
  }
}
