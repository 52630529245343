import { ServiceTypes } from '../../views/operations/OperationsList/constants';
import {
  GET_GLOSSARY_PARTNERS,
  GET_GLOSSARY_PARTNERS_ERROR,
  GET_GLOSSARY_PARTNERS_SUCCESS,
  GET_GLOSSARY_CONTRACTS,
  GET_GLOSSARY_CONTRACTS_SUCCESS,
  GET_GLOSSARY_CONTRACTS_ERROR,
  GET_GLOSSARY_USERS,
  GET_GLOSSARY_USERS_SUCCESS,
  GET_GLOSSARY_USERS_ERROR,
  GET_SERVICE_TYPE_DOCS,
  GET_SERVICE_TYPE_DOCS_SUCCESS,
  GET_SERVICE_TYPE_DOCS_ERROR,
  POST_SERVICE_TYPE_DOCS,
  POST_SERVICE_TYPE_DOCS_SUCCESS,
  POST_SERVICE_TYPE_DOCS_ERROR,
  DELETE_SERVICE_TYPE_DOCS,
  DELETE_SERVICE_TYPE_DOCS_SUCCESS,
  DELETE_SERVICE_TYPE_DOCS_ERROR,
  RESET_GLOSSARY_USERS,
  RESET_GLOSSARY_PARTNERS,
  GET_GLOSSARY_USER_PARTNERS,
  GET_GLOSSARY_USER_PARTNERS_SUCCESS,
  GET_GLOSSARY_USER_PARTNERS_ERROR,
  RESET_GLOSSARY_CONTRACTS,
  GET_USER_PARTNER_ACCOUNTS,
  GET_USER_PARTNER_ACCOUNTS_SUCCESS,
  GET_USER_PARTNER_ACCOUNTS_ERROR,
  GET_GLOSSARY_ACCOUNTS_BY_PARTNER,
  GET_GLOSSARY_ACCOUNTS_BY_PARTNER_SUCCESS,
  GET_GLOSSARY_ACCOUNTS_BY_PARTNER_ERROR
} from '../actions';

import { GlossaryReducerState } from './interface';
import {
  getGlossaryPartnerAccountsData,
  getGlossaryUserAccountsData,
  getGlossaryUserPartnersData
} from '../../views/glossary/Users/helpers';

const INIT_STATE: GlossaryReducerState = {
  partners: {
    rows: null,
    accounts: {
      data: null,
      loading: false,
      error: ''
    },
    total: 0,
    error: '',
    loading: false
  },
  contracts: {
    rows: null,
    total: 0,
    loading: false,
    error: ''
  },
  users: {
    rows: null,
    partners: {
      data: null,
      loading: false,
      error: ''
    },
    accounts: {
      data: null,
      loading: false,
      error: ''
    },
    total: 0,
    loading: false,
    error: ''
  },
  services: {
    data: {
      [ServiceTypes.Income]: null,
      [ServiceTypes.Mc]: null,
      [ServiceTypes.Outcome]: null
    },
    loading: false,
    saving: false,
    removing: false,
    error: ''
  }
};

export default (state: GlossaryReducerState = INIT_STATE, { type, payload }): GlossaryReducerState => {
  switch (type) {
    case GET_GLOSSARY_CONTRACTS:
      return {
        ...state,
        contracts: {
          ...state.contracts,
          loading: true
        }
      };
    case GET_GLOSSARY_CONTRACTS_SUCCESS:
      return {
        ...state,
        contracts: {
          ...state.contracts,
          rows: payload,
          loading: false
        }
      };
    case GET_GLOSSARY_CONTRACTS_ERROR:
      return {
        ...state,
        contracts: {
          ...state.contracts,
          loading: false,
          rows: null,
          error: payload.error
        }
      };

    case GET_GLOSSARY_PARTNERS:
      return {
        ...state,
        partners: {
          ...state.partners,
          loading: true,
          error: ''
        }
      };
    case GET_GLOSSARY_PARTNERS_SUCCESS:
      return {
        ...state,
        partners: {
          ...state.partners,
          rows: payload.rows,
          total: payload.total,
          loading: false,
          error: ''
        }
      };
    case GET_GLOSSARY_PARTNERS_ERROR:
      return {
        ...state,
        partners: {
          ...state.partners,
          rows: null,
          error: payload.error,
          loading: false
        }
      };

    case GET_GLOSSARY_ACCOUNTS_BY_PARTNER:
      return {
        ...state,
        partners: {
          ...state.partners,
          accounts: {
            ...state.partners.accounts,
            loading: true,
            error: ''
          }
        }
      };
    case GET_GLOSSARY_ACCOUNTS_BY_PARTNER_SUCCESS:
      return {
        ...state,
        partners: {
          ...state.partners,
          accounts: {
            data: getGlossaryPartnerAccountsData(state.partners.accounts.data, payload.partnerId, payload.accounts),
            loading: false,
            error: ''
          }
        }
      };
    case GET_GLOSSARY_ACCOUNTS_BY_PARTNER_ERROR:
      return {
        ...state,
        partners: {
          ...state.partners,
          accounts: {
            data: null,
            error: payload.error,
            loading: false
          }
        }
      };

    case GET_GLOSSARY_USERS:
      return {
        ...state,
        users: {
          ...state.users,
          loading: true,
          error: ''
        }
      };
    case GET_GLOSSARY_USERS_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          rows: payload.rows,
          total: payload.total,
          loading: false,
          error: ''
        }
      };
    case GET_GLOSSARY_USERS_ERROR:
      return {
        ...state,
        users: {
          ...state.users,
          rows: null,
          error: payload.error,
          loading: false
        }
      };

    case GET_GLOSSARY_USER_PARTNERS:
      return {
        ...state,
        users: {
          ...state.users,
          partners: {
            ...state.users.partners,
            loading: true,
            error: ''
          }
        }
      };
    case GET_GLOSSARY_USER_PARTNERS_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          partners: {
            data: getGlossaryUserPartnersData(state.users.partners.data, payload.userId, payload.merchants),
            loading: false,
            error: ''
          }
        }
      };
    case GET_GLOSSARY_USER_PARTNERS_ERROR:
      return {
        ...state,
        users: {
          ...state.users,
          partners: {
            data: null,
            error: payload.error,
            loading: false
          }
        }
      };

    case GET_USER_PARTNER_ACCOUNTS:
      return {
        ...state,
        users: {
          ...state.users,
          accounts: {
            ...state.users.accounts,
            loading: true,
            error: ''
          }
        }
      };
    case GET_USER_PARTNER_ACCOUNTS_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          accounts: {
            data: getGlossaryUserAccountsData(state.users.accounts.data, payload.accounts),
            loading: false,
            error: ''
          }
        }
      };
    case GET_USER_PARTNER_ACCOUNTS_ERROR:
      return {
        ...state,
        users: {
          ...state.users,
          accounts: {
            data: null,
            error: payload.error,
            loading: false
          }
        }
      };

    case GET_SERVICE_TYPE_DOCS:
      return {
        ...state,
        services: {
          ...state.services,
          loading: true,
          error: ''
        }
      };
    case GET_SERVICE_TYPE_DOCS_SUCCESS:
      return {
        ...state,
        services: {
          ...state.services,
          data: {
            ...state.services.data,
            [payload.serviceType]: payload.data
          },
          loading: false
        }
      };
    case GET_SERVICE_TYPE_DOCS_ERROR:
      return {
        ...state,
        services: {
          ...state.services,
          error: payload.error,
          loading: false
        }
      };

    case POST_SERVICE_TYPE_DOCS:
      return {
        ...state,
        services: {
          ...state.services,
          saving: true,
          error: ''
        }
      };
    case POST_SERVICE_TYPE_DOCS_SUCCESS:
      return {
        ...state,
        services: {
          ...state.services,
          saving: false
        }
      };
    case POST_SERVICE_TYPE_DOCS_ERROR:
      return {
        ...state,
        services: {
          ...state.services,
          error: payload.error,
          saving: false
        }
      };

    case DELETE_SERVICE_TYPE_DOCS:
      return {
        ...state,
        services: {
          ...state.services,
          removing: true,
          error: ''
        }
      };
    case DELETE_SERVICE_TYPE_DOCS_SUCCESS:
      return {
        ...state,
        services: {
          ...state.services,
          removing: false
        }
      };
    case DELETE_SERVICE_TYPE_DOCS_ERROR:
      return {
        ...state,
        services: {
          ...state.services,
          error: payload.error,
          removing: false
        }
      };

    case RESET_GLOSSARY_USERS:
      return {
        ...state,
        users: {
          ...state.users,
          rows: null,
          total: 0,
          loading: false,
          error: ''
        }
      };
    case RESET_GLOSSARY_PARTNERS:
      return {
        ...state,
        partners: {
          ...state.partners,
          rows: null,
          total: 0,
          loading: false,
          error: ''
        }
      };
    case RESET_GLOSSARY_CONTRACTS:
      return {
        ...state,
        contracts: {
          ...state.contracts,
          rows: null,
          total: 0,
          loading: false,
          error: ''
        }
      };
    default:
      return { ...state };
  }
};
