import React from 'react';

import { Message } from '../../../views/support/interface';
import { CONFIG } from '../../../config/appConfig';

export const FileMessage = ({ file_link, file_name, create_time, message }: Message) => {
  return (
    <>
      <div className="sc-message--file">
        {message && <div className="mb-2">{message}</div>}
        <a href={CONFIG.apiURL() + file_link} download={file_name}>
          <div className="content">
            <i className="icon simple-icon-paper-clip" />
            <p>{file_name}</p>
          </div>
        </a>
        <div className="sc-message--date">{create_time}</div>
      </div>
    </>
  );
};
