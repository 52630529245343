export const GMS_MIN = 1;
export const DISPLAY_RECORDS_MN = 50;
export const RESTORE_PASSWORD_MINUTES_MIN = 5;
export const OPERATION_DAYS_MIN = 30;

export const GRAPH_DAYS_MAX = 366;
export const CSV_DAYS_MAX = 366;
export const DISPLAY_RECORDS_MAX = 1000;
export const PASSWORD_ATTEMPTS_MAX = 10;
export const PASSWORD_DAYS_MAX = 366;
export const RESTORE_PASSWORD_MINUTES_MAX = 180;
export const OPERATION_DAYS_MAX = 366;
export const FAILED_2FA_MAX = 5;
export const MAX_PAGE_SIZE = 100;
export const MIN_REFUND_COUNT = 10;
export const MAX_REFUND_COUNT = 1000;

export const LIMITS = {
  max_page_size: {
    max: MAX_PAGE_SIZE,
    min: GMS_MIN
  },
  max_graph_days: {
    max: GRAPH_DAYS_MAX,
    min: GMS_MIN
  },
  max_csv_days: {
    max: CSV_DAYS_MAX,
    min: GMS_MIN
  },
  max_display_records: {
    max: DISPLAY_RECORDS_MAX,
    min: DISPLAY_RECORDS_MN
  },
  max_login_attempts: {
    max: PASSWORD_ATTEMPTS_MAX,
    min: GMS_MIN
  },
  max_failed_2fa: {
    max: FAILED_2FA_MAX,
    min: GMS_MIN
  },
  max_password_days: {
    max: PASSWORD_DAYS_MAX,
    min: GMS_MIN
  },
  max_restore_minutes: {
    max: RESTORE_PASSWORD_MINUTES_MAX,
    min: RESTORE_PASSWORD_MINUTES_MIN
  },
  max_operation_days: {
    max: OPERATION_DAYS_MAX,
    min: OPERATION_DAYS_MIN
  },
  max_refunds: {
    max: MAX_REFUND_COUNT,
    min: MIN_REFUND_COUNT
  }
};

export const INITIAL_VALUES = {
  max_graph_days: LIMITS.max_graph_days.min,
  max_csv_days: LIMITS.max_csv_days.min,
  max_display_records: LIMITS.max_display_records.min,
  max_login_attempts: LIMITS.max_login_attempts.min,
  max_failed_2fa: LIMITS.max_failed_2fa.min,
  max_password_days: LIMITS.max_password_days.min,
  max_restore_minutes: LIMITS.max_restore_minutes.min,
  max_operation_days: LIMITS.max_operation_days.min,
  max_page_size: LIMITS.max_page_size.min,
  max_refunds: LIMITS.max_refunds.min,
  cache: 'permissions'
};
