import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import UserLayout from '../../layout/UserLayout';

import Login from './Login/Login';
import ForgotPassword from './ForgotPassword/ForgotPassword';

const User = ({ match }) => (
  <UserLayout>
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/login`} />
      <Route path={`${match.url}/login`} component={Login} />
      <Route path={`${match.url}/forgot-password`} component={ForgotPassword} />
    </Switch>
  </UserLayout>
);

export default User;
