import appLocaleData from '@formatjs/intl-relativetimeformat/dist/locale-data/ru';
import messages from '../locales/ru_RU';

const RuLang = {
  messages,
  locale: 'ru-RU',
  data: appLocaleData
};

export default RuLang;
