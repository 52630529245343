import { CellInfo } from 'react-table';

import { roundDigit } from '../../../helpers/Utils';
import format from '../../../localization';
import { DATE_TIME_FORMAT } from '../../../constants/app';

export const TABLE_COLUMNS = [
  {
    Header: 'Дата и время',
    accessor: 'order_time',
    Cell: ({ value }: CellInfo) => format(value, DATE_TIME_FORMAT)
  },
  {
    Header: 'Номер операции',
    accessor: 'order_number'
  },
  {
    Header: 'Сумма операции',
    accessor: 'amount',
    Cell: ({ value }: CellInfo) => roundDigit(value)
  },
  {
    Header: 'Валюта операции',
    accessor: 'currency'
  },
  {
    Header: 'Описание заказа',
    accessor: 'order_descr'
  }
  /*{ TODO Бек пока не готов ?
    Header: 'Тип операции',
    accessor: 'typeOfOperation'
  }*/
];
