import React, { Component, ReactNode } from 'react';
import { Card, CardBody, Row } from 'reactstrap';
import { connect } from 'react-redux';

import { getRequest, sendSupportMessage } from '../../redux/support/actions';
import { Colxx, Separator } from '../../components/common/CustomBootstrap';
import { Loader } from '../../components/common/Loader';
import Chat from '../../components/Chat/Chat';

import { InfoProps } from './interface';
import { NotificationManager } from '../../components/common/react-notifications';
import { NOTIFICATION_TIMEOUT } from '../../constants/defaultValues';
import { DATE_TIME_FORMAT_WITH_SEC } from '../../constants/app';
import format from '../../localization';
import { withTranslation } from 'react-i18next';

class InfoPage extends Component<InfoProps> {
  componentDidMount(): void {
    const { match, getRequestAction } = this.props;
    const requestId = match.params && match.params.id;

    getRequestAction(Number(requestId));
  }

  componentDidUpdate(prevProps: Readonly<InfoProps>): void {
    const { error, loading, sendMessageState } = this.props;

    if (error && !loading && prevProps.loading) {
      NotificationManager.info(error, 'Ошибка', NOTIFICATION_TIMEOUT, null, null, '');
    }

    if (sendMessageState.error && !sendMessageState.loading && prevProps.sendMessageState.loading) {
      NotificationManager.info(
        sendMessageState.error,
        'Ошибка отправки сообщения',
        NOTIFICATION_TIMEOUT,
        null,
        null,
        ''
      );
    }
  }

  render(): ReactNode {
    const { loading, match, location, messages, sendSupportMessageAction, t } = this.props;

    const requestId = match.params && match.params.id;
    const requestDate = location.state && format(location.state.date, DATE_TIME_FORMAT_WITH_SEC);
    const requestType = location.state && location.state.type;
    const requestAccount = location.state && location.state.account;
    const requestContract = location.state && location.state.contract;

    return (
      <>
        <Row>
          <Colxx xxs="12">
            <h1>{t('menu.support')}</h1>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12" sm="12" md="7" xl="8">
            <Chat
              messages={messages}
              header={`${t('support.support_request')} №${requestId}`}
              requestId={requestId}
              onMessageSend={sendSupportMessageAction}
            />
          </Colxx>
          <Colxx xxs="12" sm="12" md="5" xl="4">
            <Card className="mb-4">
              <CardBody>
                <table className="request-info mb-5">
                  <tbody>
                    <tr key="date" className="item">
                      <td key="title" className="title">
                        {t('support.request_date')}:
                      </td>
                      <td key="data" className="data">
                        {requestDate}
                      </td>
                    </tr>
                    <tr key="type" className="item">
                      <td key="title" className="title">
                        {t('support.request_type')}:
                      </td>
                      <td key="data" className="data">
                        {t(requestType)}
                      </td>
                    </tr>
                    <tr key="contract" className="item">
                      <td key="title" className="title">
                        {t('support.contract')}:
                      </td>
                      <td key="data" className="data">
                        {requestContract}
                      </td>
                    </tr>
                    <tr key="account" className="item">
                      <td key="title" className="title">
                        {t('support.account')}:
                      </td>
                      <td key="data" className="data">
                        {requestAccount}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </Colxx>
        </Row>

        {loading && <Loader />}
      </>
    );
  }
}

const mapStateToProps = ({ support }) => ({
  messages: support.currentRequest.data,
  loading: support.currentRequest.loading,
  error: support.currentRequest.error,
  sendMessageState: support.sendMessage
});

const mapDispatchToProps = {
  sendSupportMessageAction: sendSupportMessage,
  getRequestAction: getRequest
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(InfoPage)
);
