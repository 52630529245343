import React, { Fragment, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, FormGroup, Row, Label, CardTitle } from 'reactstrap';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import { Colxx } from '../../../../components/common/CustomBootstrap';
import { Form, Formik, FormikErrors, Field } from 'formik';
import { Dropzone, DropzoneFile } from 'dropzone';
import DropZone from '../../../../components/dropzone/Dropzone';
import { CustomDesignTabProps } from '../interface';
import ColorPicker from '../../../../components/common/ColorPicker/ColorPicker';
import Preview from '../Preview/Preview';
import { CustomDesignConfig } from '../../../../redux/settings/interface';
import { EMPTY_DESIGN_CONFIG, IMAGE_CONTENT_TYPES, DEFAULT_MAX_FILE_SIZE, PREVIEW_PAGE_VIEWS } from '../constants';
import { getFilesErrors } from '../helpers';
import { LOGO_SIZE_ERROR } from '../../../../constants/app';
import WithClearButton from '../../../../components/common/WithClearButton';
import { withTranslation } from 'react-i18next';
class CabinetTab extends React.Component<CustomDesignTabProps, any> {
  constructor(props: CustomDesignTabProps) {
    super(props);
    this.designConfigNew = EMPTY_DESIGN_CONFIG;
    this.setFieldValue = () => {};
    this.isValid = true;
    this.errors = {};
  }

  private designConfigNew: CustomDesignConfig;
  private setFieldValue: (field: string, value: any) => void;
  private isValid: boolean;
  private errors: FormikErrors<CustomDesignConfig> | null;
  private dropZoneLogo: Dropzone;

  componentDidUpdate(prevProps: Readonly<CustomDesignTabProps>, prevState: any, snapshot?: any): void {
    const { currentDesign, actualDesign } = this.props;
    if (!isEqual(currentDesign, prevProps.currentDesign) && isEqual(actualDesign, currentDesign)) {
      this.dropZoneLogo.removeAllFiles();
    }
  }

  validate = (values): { [key: string]: string } => {
    if (!values) {
      return {};
    }
    const errors: { [key: string]: any } = {};
    const imageErrors = getFilesErrors(values.imageFiles);

    if (imageErrors && !isEqual(imageErrors, {})) {
      errors.imageFiles = imageErrors;
    }

    if (
      values.settings &&
      'logoSize' in values.settings &&
      values.settings.logoSize &&
      (Number(values.settings.logoSize) > 140 || Number(values.settings.logoSize) < 20)
    ) {
      if (!errors.settings) {
        errors.settings = {};
      }
      errors.settings.logoSize = LOGO_SIZE_ERROR;
    }

    this.props.setTabSettings(this.designConfigNew, isEqual(errors, {}), 'cabinet');
    return errors;
  };

  render(): ReactNode {
    const { setImage, removeImage, currentDesign, t } = this.props;
    return (
      <Fragment>
        <Row>
          <Colxx className="col-left col-12 col-xl-4 mb-4">
            <Card>
              <CardBody>
                <CardTitle>{t('customDesign.Personal page design')}</CardTitle>
                <Formik
                  validate={this.validate}
                  isInitialValid={true}
                  enableReinitialize={true}
                  initialValues={this.props.currentDesign.data}
                  onSubmit={() => {}}
                >
                  {({ values, setFieldValue, errors, isValid }) => {
                    this.designConfigNew = values || EMPTY_DESIGN_CONFIG;
                    this.setFieldValue = setFieldValue;
                    this.isValid = isValid;
                    this.errors = errors;
                    return (
                      <Form className="custom-design">
                        <FormGroup className="has-float-label">
                          <Label className="float-label">{t('customDesign.Лого')}</Label>
                          <DropZone
                            maxFiles={1}
                            init={(dropZone: Dropzone) => (this.dropZoneLogo = dropZone)}
                            addedfile={(file: DropzoneFile) => {
                              setImage && setImage(file, 'logo', this.dropZoneLogo, setFieldValue);
                            }}
                            removedfile={() => removeImage && removeImage('logo', setFieldValue)}
                            maxFilesize={DEFAULT_MAX_FILE_SIZE}
                            acceptedFiles={IMAGE_CONTENT_TYPES}
                          />
                        </FormGroup>
                        <FormGroup className="has-float-label">
                          <Label className="float-label">{t('customDesign.Размер лого (блок 6)')}</Label>
                          <WithClearButton
                            onClear={() => {
                              this.setFieldValue('settings', {
                                ...this.designConfigNew.settings,
                                ...{ logoSize: '' }
                              });
                            }}
                          >
                            <Field
                              className="form-control logo-size"
                              type="number"
                              name="logoSize"
                              value={get(values, 'settings.logoSize')}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                this.setFieldValue('settings', {
                                  ...this.designConfigNew.settings,
                                  ...{ logoSize: event.target.value }
                                });
                              }}
                            />
                          </WithClearButton>
                          {errors && errors.settings && 'logoSize' in errors.settings && (
                            <div className="invalid-feedback d-block">{errors.settings.logoSize}</div>
                          )}
                        </FormGroup>
                        <ColorPicker
                          value={get(values, 'settings.menuColor', '')}
                          name="menuColor"
                          setValue={(field: string, value: string) => {
                            setFieldValue('settings', { ...this.designConfigNew.settings, ...{ [field]: value } });
                          }}
                          onClear={() =>
                            setFieldValue('settings', { ...this.designConfigNew.settings, ...{ menuColor: '' } })
                          }
                        />
                        <ColorPicker
                          value={get(values, 'settings.submenuColor', '')}
                          name="submenuColor"
                          setValue={(field: string, value: string) => {
                            setFieldValue('settings', { ...this.designConfigNew.settings, ...{ [field]: value } });
                          }}
                          onClear={() =>
                            setFieldValue('settings', { ...this.designConfigNew.settings, ...{ submenuColor: '' } })
                          }
                        />
                        <ColorPicker
                          value={get(values, 'settings.menuTextColor', '')}
                          name="menuTextColor"
                          setValue={(field: string, value: string) => {
                            setFieldValue('settings', { ...this.designConfigNew.settings, ...{ [field]: value } });
                          }}
                          onClear={() =>
                            setFieldValue('settings', { ...this.designConfigNew.settings, ...{ menuTextColor: '' } })
                          }
                        />
                        <ColorPicker
                          value={get(values, 'settings.menuTextHoverColor', '')}
                          name="menuTextHoverColor"
                          setValue={(field: string, value: string) => {
                            setFieldValue('settings', { ...this.designConfigNew.settings, ...{ [field]: value } });
                          }}
                          onClear={() =>
                            setFieldValue('settings', {
                              ...this.designConfigNew.settings,
                              ...{ menuTextHoverColor: '' }
                            })
                          }
                        />
                        <ColorPicker
                          value={get(values, 'settings.buttonColor', '')}
                          name="buttonColor"
                          setValue={(field: string, value: string) => {
                            setFieldValue('settings', { ...this.designConfigNew.settings, ...{ [field]: value } });
                          }}
                          onClear={() =>
                            setFieldValue('settings', { ...this.designConfigNew.settings, ...{ buttonColor: '' } })
                          }
                        />
                        <ColorPicker
                          value={get(values, 'settings.cardColor', '')}
                          name="cardColor"
                          setValue={(field: string, value: string) => {
                            setFieldValue('settings', { ...this.designConfigNew.settings, ...{ [field]: value } });
                          }}
                          onClear={() =>
                            setFieldValue('settings', { ...this.designConfigNew.settings, ...{ cardColor: '' } })
                          }
                        />
                        <ColorPicker
                          value={get(values, 'settings.backgroundColor', '')}
                          name="backgroundColor"
                          setValue={(field: string, value: string) => {
                            setFieldValue('settings', { ...this.designConfigNew.settings, ...{ [field]: value } });
                          }}
                          onClear={() =>
                            setFieldValue('settings', { ...this.designConfigNew.settings, ...{ backgroundColor: '' } })
                          }
                        />
                      </Form>
                    );
                  }}
                </Formik>
                {this.props.children}
              </CardBody>
            </Card>
          </Colxx>
          <Colxx className="col-left col-12 col-xl-8">
            <div className="mb-4 card preview-card">
              <div className="card-body">
                <Preview view={PREVIEW_PAGE_VIEWS.cabinet} customDesign={currentDesign} />
              </div>
            </div>
          </Colxx>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ settings }) => ({ actualDesign: settings.customDesign });
export default withTranslation()(
  connect(
    mapStateToProps,
    {}
  )(CabinetTab)
);
