import React from 'react';
import { ALL_OPTION } from '../constants';

import { PayoutAccount } from '../../../redux/operations/interface';
import { GetGlossaryAccountsPartner } from '../../../redux/glossary/interface';

export const GLOSSARY_PARTNERS_INITIAL_VALUES = (t: any) => ({
  name: ALL_OPTION(t),
  account_id: '',
  email: '',
  phone: '',
  account_name: ALL_OPTION(t)
});

export const DEFAULT_SORTER = [
  {
    id: 'name',
    desc: false
  }
];

export const TABLE_COLUMN_EMAIL_WIDTH = 200;
export const TABLE_COLUMN_PHONE_WIDTH = 200;

export const getGlossaryPartnersColumns = (
  getAccounts: (merchantId: number[], userId?: number) => GetGlossaryAccountsPartner,
  accounts: {
    data: { [partnerId: number]: PayoutAccount[] };
    loading: boolean;
    error: string;
  },
  t: any,
  userId?: number
) => [
  {
    Header: t('contracts.partner'),
    accessor: 'name'
  },
  {
    expander: true,
    Header: '',
    width: 30,
    Expander: ({ original, isExpanded }) => {
      if (isExpanded && !accounts.loading && !(accounts.data && accounts.data[original.id])) {
        getAccounts([original.id], userId);
      }
      return (
        <div>{isExpanded ? <i className="simple-icon-arrow-up" /> : <i className="simple-icon-arrow-down" />}</div>
      );
    },
    style: {
      fontSize: 12,
      padding: '0',
      textAlign: 'center',
      userSelect: 'none'
    }
  }
];
