import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { reduxStore } from './redux/store';

import App from './App';

const Application = () => (
  <Provider store={reduxStore}>
    <Router>
      <App />
    </Router>
  </Provider>
);

ReactDOM.render(<Application />, document.getElementById('root'));

/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
