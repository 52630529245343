import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_ERROR,
  GET_NOTIFICATIONS_SUCCESS,
  GET_PAYOUT_NOTIFICATION,
  GET_PAYOUT_NOTIFICATION_ERROR,
  GET_PAYOUT_NOTIFICATION_SUCCESS,
  READ_NOTIFICATION,
  READ_NOTIFICATION_ERROR,
  READ_NOTIFICATION_SUCCESS,
  WS_NOTIFICATION_CONNECTED,
  WS_NOTIFICATION_DISCONNECTED,
  WS_NOTIFICATION_GET_MESSAGE
} from '../actions';
import {
  GetNotifications,
  GetNotificationsError,
  GetNotificationsSuccess,
  GetPayoutNotifications,
  GetPayoutNotificationsError,
  GetPayoutNotificationsSuccess,
  GetWsMessage,
  Notification,
  NotificationMessage,
  ReadNotification,
  GetNotificationsValues,
  ReadNotificationError,
  ReadNotificationSuccess,
  WsConnectionClose,
  WsConnectionError,
  WsConnectionStart
} from './interface';
import { NotificationType } from '../../containers/navs/Topnav/constant';

// WEBSOCKET NOTIFICATION
export const wsConnectionStart = (): WsConnectionStart => ({
  type: WS_NOTIFICATION_CONNECTED
});

export const wsConnectionClose = (): WsConnectionClose => ({
  type: WS_NOTIFICATION_DISCONNECTED
});

export const wsConnectionError = (error: string): WsConnectionError => ({
  type: WS_NOTIFICATION_DISCONNECTED,
  payload: { error }
});

export const getWsMessage = (message: NotificationMessage): GetWsMessage => ({
  type: WS_NOTIFICATION_GET_MESSAGE,
  payload: { message }
});

// GET NOTIFICATIONS
export const getNotifications = (values: GetNotificationsValues): GetNotifications => ({
  type: GET_NOTIFICATIONS,
  payload: values
});

export const getNotificationsSuccess = (
  data: Notification[],
  count: number,
  unread: number
): GetNotificationsSuccess => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  payload: {
    data,
    count,
    unread
  }
});

export const getNotificationsError = (error: string): GetNotificationsError => ({
  type: GET_NOTIFICATIONS_ERROR,
  payload: { error }
});

// READ NOTIFICATION
export const readNotification = (id: number[]): ReadNotification => ({
  type: READ_NOTIFICATION,
  payload: { id }
});

export const readNotificationSuccess = (id: number[]): ReadNotificationSuccess => ({
  type: READ_NOTIFICATION_SUCCESS,
  payload: { id }
});

export const readNotificationError = (error: string): ReadNotificationError => ({
  type: READ_NOTIFICATION_ERROR,
  payload: { error }
});

// GET PAYOUT NOTIFICATION
export const getPayoutNotification = (id: number, type: NotificationType): GetPayoutNotifications => ({
  type: GET_PAYOUT_NOTIFICATION,
  payload: { id, type }
});

export const getPayoutNotificationSuccess = (data): GetPayoutNotificationsSuccess => ({
  type: GET_PAYOUT_NOTIFICATION_SUCCESS,
  payload: data
});

export const getPayoutNotificationError = (error: string): GetPayoutNotificationsError => ({
  type: GET_PAYOUT_NOTIFICATION_ERROR,
  payload: { error }
});
