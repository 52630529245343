import { all, call, fork, put, takeLatest, SimpleEffect } from 'redux-saga/effects';
import { CombinatorEffect } from '@redux-saga/types';

import { ApiRequest } from '../../helpers/ApiRequest/ApiRequest';
import { GetDefaultPageResponse } from '../../api.interface';

import {
  GET_DEFAULT_PAGE,
  GET_USER_BY_ID,
  getDefaultPageSuccess,
  getDefaultPageError,
  getUserByIdSuccess,
  getUserByIdError
} from '../actions';
import { GetUserByIdAction } from './interface';

export function* getDefaultPage(): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { data, error }: GetDefaultPageResponse = yield call(ApiRequest.get, '/default-page');
    if (error) {
      yield put(getDefaultPageError(`${error}`));
    } else {
      yield put(getDefaultPageSuccess(data));
    }
  } catch (error) {
    yield put(getDefaultPageError(`${error}`));
  }
}

// GET USER
export function* getUserById({ payload }: GetUserByIdAction): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { id } = payload;
    const { error, data } = yield call(ApiRequest.get, `/users/${id}/`);

    if (!error) {
      yield put(getUserByIdSuccess(data));
    } else {
      yield put(getUserByIdError(`${error}`));
    }
  } catch (error) {
    yield put(getUserByIdError(`${error}`));
  }
}

export function* watchGetUserById(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(GET_USER_BY_ID, getUserById);
}

export function* watchGetDefaultPage(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(GET_DEFAULT_PAGE, getDefaultPage);
}

export default function* rootSaga(): Generator<CombinatorEffect<string, any>, any, any> {
  yield all([fork(watchGetDefaultPage)]);
  yield all([fork(watchGetUserById)]);
}
