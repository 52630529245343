import React, { Fragment, ReactNode } from 'react';
import { Button, Card, CardBody, FormGroup, Input, Label, Row } from 'reactstrap';
import { Field, Form, Formik } from 'formik';
import InputMask from 'react-input-mask';
import { differenceInSeconds } from 'date-fns';
import WithTooltip from '../../../components/common/WithTooltip';
import { Colxx } from '../../../components/common/CustomBootstrap';
import { DatePickerLocaled } from '../../../components/common/DatePickerLocaled/DatePickerLocaled';
import { CustomDropdownTreeSelect } from '../../../components/common/CustomDropdownTreeSelect';
import { CustomTagInputField } from '../../../components/common/CustomTagInputField';

import {
  DATE_TIME_FORMAT_WITH_SEC,
  EMPTY_FIELD_ERROR,
  ZERO_FIELD_ERROR,
  FULL_DATETIME_MASK,
  OPERATION_STATUSES_OPTIONS_WITH_ALL
} from '../../../constants/app';
import { directionsOptions, getFieldErrorsCount, setDateFrom, setDateTo } from '../../../helpers/Utils';
import { floatFieldValue } from '../helpers';
import { OPERATIONS_LIST_FILTER_INITIAL_VALUES, ServiceTypes } from './constants';
import { IOperationsListFilterProps, IOperationsListFilterState } from './interface';
import { MIN_DATE_LIMIT } from '../../../constants/dateValues';
import {
  getAccountOptions,
  getCurrenciesOptions,
  getOneLevelSelectedData,
  onDirectionChange,
  getAvailableSitesOptions
} from './helpers';
import { withTranslation } from 'react-i18next';

class OperationsListFilter extends React.Component<IOperationsListFilterProps, IOperationsListFilterState> {
  state: IOperationsListFilterState = {
    shouldResetField: false
  };
  resetForm;

  componentDidUpdate(prevProps: IOperationsListFilterProps): void {
    const { shouldResetForm } = this.props;
    if (prevProps.shouldResetForm !== shouldResetForm && shouldResetForm) {
      this.resetForm();
      this.setState({ shouldResetField: true });
    }

    this.state.shouldResetField && this.setState({ shouldResetField: false });
  }

  validate = (values) => {
    const { t } = this.props;

    const errors: { [key: string]: string } = {};
    const { dateFrom, dateTo } = values;

    const diffInSec = dateFrom && dateTo ? differenceInSeconds(dateTo, dateFrom) : 0;

    if (values.direction === null) {
      errors['direction'] = t(EMPTY_FIELD_ERROR);
    }

    if (values.account === null) {
      errors['account'] = t(EMPTY_FIELD_ERROR);
    }

    if (values.currency === null) {
      errors['currency'] = t(EMPTY_FIELD_ERROR);
    }

    if (values.card_number === null) {
      errors['card_number'] = t(EMPTY_FIELD_ERROR);
    }

    if (values.amount && Number(values.amount) === 0) {
      errors['amount'] = t(ZERO_FIELD_ERROR);
    }

    if (values.status === null) {
      errors['status'] = t(EMPTY_FIELD_ERROR);
    }

    if (diffInSec < 1) {
      errors['dateFrom'] = t(MIN_DATE_LIMIT.text);
    }

    return errors;
  };

  prepareSiteData = (options) => {
    if (options && options.length) {
      // Временный костыль (до ввыполнения задачи GMC-1192)
      enum blockSource {
        Source2 = 2,
        Source6 = 6
      }

      return options.filter(({ source_id }) => !Object.values(blockSource).includes(source_id));
    } else {
      return [];
    }
  };

  render(): ReactNode {
    const {
      serviceType,
      onSubmit,
      showExportButton,
      exportFile,
      destinations,
      currencies,
      accounts,
      sites,
      t
    } = this.props;
    const { shouldResetField } = this.state;

    return (
      <div>
        <Formik
          initialValues={OPERATIONS_LIST_FILTER_INITIAL_VALUES(t)[serviceType]}
          validate={this.validate}
          onSubmit={onSubmit}
        >
          {({ values, setFieldValue, errors, touched, setFieldTouched, resetForm }) => {
            this.resetForm = resetForm;

            return (
              <Form className="mb-4">
                <Card>
                  <CardBody>
                    <Row>
                      {(serviceType === ServiceTypes.Income || serviceType === ServiceTypes.Outcome) && (
                        <Fragment>
                          <Colxx xxs="12" sm="6" className="p-0">
                            <div className="mb-3 pb-1 d-md-flex flex-row">
                              <Colxx xxs="12" sm="6" className="p-0">
                                <div className="ml-2">
                                  <Input
                                    type="radio"
                                    id="byEndDateRadioBtn-1"
                                    name="byEndDate"
                                    checked={!values.byEndDate}
                                    className="radio-btn-align-center"
                                    onChange={() => setFieldValue('byEndDate', false)}
                                  />
                                  <Label for="byEndDateRadioBtn-1" className="mb-1">
                                    {t('operations.button.search_by_create_date')}
                                  </Label>
                                </div>
                              </Colxx>
                              <Colxx xxs="12" sm="6" className="p-0">
                                <div className="ml-2">
                                  <Input
                                    type="radio"
                                    id="byEndDateRadioBtn-2"
                                    name="byEndDate"
                                    checked={values.byEndDate}
                                    className="radio-btn-align-center"
                                    onChange={() => setFieldValue('byEndDate', true)}
                                  />
                                  <Label for="byEndDateRadioBtn-2" className="mb-1">
                                    {t('operations.button.search_by_end_date')}
                                  </Label>
                                </div>
                              </Colxx>
                            </div>
                          </Colxx>
                          <Colxx xxs="0" sm="6" />
                        </Fragment>
                      )}

                      <Colxx xxs="12" sm="6">
                        <FormGroup className="has-float-label">
                          <Label for="dateFrom" className="float-label">
                            {t('operations.date from')}
                          </Label>
                          <DatePickerLocaled
                            id="dateFrom"
                            name="dateFrom"
                            selected={values.dateFrom}
                            autoComplete="off"
                            dateFormat={DATE_TIME_FORMAT_WITH_SEC}
                            customInput={<InputMask mask={FULL_DATETIME_MASK} />}
                            maxDate={values.dateTo ? values.dateTo : new Date()}
                            onChange={(d: Date) => {
                              const dateFrom = setDateFrom(values.dateFrom, d);
                              setFieldValue('dateFrom', dateFrom);
                            }}
                            onSelect={(d: Date) => {
                              const dateFrom = setDateFrom(values.dateFrom, d);
                              setFieldValue('dateFrom', dateFrom);
                            }}
                            onBlur={() => setFieldTouched('dateFrom', true)}
                          />
                          {errors.dateFrom && <div className="invalid-feedback d-block">{errors.dateFrom}</div>}
                        </FormGroup>
                      </Colxx>

                      <Colxx xxs="12" sm="6">
                        <FormGroup className="has-float-label">
                          <Label for="dateTo" className="float-label">
                            {t('operations.date by')}
                          </Label>
                          <DatePickerLocaled
                            id="dateTo"
                            name="dateTo"
                            selected={values.dateTo}
                            autoComplete="off"
                            dateFormat={DATE_TIME_FORMAT_WITH_SEC}
                            customInput={<InputMask mask={FULL_DATETIME_MASK} />}
                            minDate={values.dateFrom ? values.dateFrom : undefined}
                            maxDate={new Date()}
                            onChange={(d: Date) => {
                              const dateTo = setDateTo(values.dateTo, d);
                              setFieldValue('dateTo', dateTo);
                            }}
                            onSelect={(d: Date) => {
                              const dateTo = setDateTo(values.dateTo, d);
                              setFieldValue('dateTo', dateTo);
                            }}
                            onBlur={() => setFieldTouched('dateTo', true)}
                            tooltip={
                              serviceType === ServiceTypes.Outcome ? t('operations.payout_datetime_tooltip') : ''
                            }
                          />
                          {errors.dateTo && touched.dateTo && (
                            <div className="invalid-feedback d-block">{errors.dateTo}</div>
                          )}
                        </FormGroup>
                      </Colxx>

                      {(serviceType === ServiceTypes.Income || serviceType === ServiceTypes.Outcome) && (
                        <Colxx xxs="12" sm="6" lg="3">
                          <FormGroup className="has-float-label">
                            <Label className="float-label">{t('operations.destination')}</Label>
                            <CustomDropdownTreeSelect
                              name="direction"
                              data={directionsOptions(serviceType, destinations, t)}
                              shouldReset={shouldResetField}
                              onBlur={() => setFieldTouched('direction', true)}
                              onChange={(changedValue, selectedValues) =>
                                onDirectionChange(values.direction, selectedValues, setFieldValue)
                              }
                            />
                            {errors.direction && <div className="invalid-feedback d-block">{errors.direction}</div>}
                          </FormGroup>
                        </Colxx>
                      )}

                      <Colxx xxs="12" sm="6" lg="3">
                        <FormGroup className="has-float-label">
                          <Label className="float-label">{t('operations.currency')}</Label>
                          <CustomDropdownTreeSelect
                            name="currency"
                            data={getCurrenciesOptions(currencies, serviceType, t)}
                            disabled={serviceType === ServiceTypes.Mc}
                            shouldReset={shouldResetField}
                            onBlur={() => setFieldTouched('currency', true)}
                            onChange={(changedValue, selectedValues) =>
                              setFieldValue('currency', getOneLevelSelectedData(selectedValues))
                            }
                          />
                          {errors.currency && <div className="invalid-feedback d-block">{errors.currency}</div>}
                        </FormGroup>
                      </Colxx>

                      <Colxx xxs="12" sm="6" lg="3">
                        <FormGroup className="form-group has-float-label">
                          <Label className="float-label">
                            {serviceType === ServiceTypes.Income ? t('operations.shop') : t('operations.account')}
                          </Label>
                          <CustomDropdownTreeSelect
                            name="account"
                            data={getAccountOptions(accounts, t)}
                            shouldReset={shouldResetField}
                            onBlur={() => setFieldTouched('account', true)}
                            onChange={(changedValue, selectedValues) => {
                              setFieldValue('account', getOneLevelSelectedData(selectedValues));
                            }}
                          />
                          {errors.account && <div className="invalid-feedback d-block">{errors.account}</div>}
                        </FormGroup>
                      </Colxx>

                      <Colxx xxs="12" sm="6" lg="3">
                        <FormGroup className="form-group has-float-label">
                          <Label className="float-label">{t('operations.status')}</Label>
                          <CustomDropdownTreeSelect
                            name="status"
                            data={OPERATION_STATUSES_OPTIONS_WITH_ALL(t)}
                            shouldReset={shouldResetField}
                            onBlur={() => setFieldTouched('status', true)}
                            onChange={(changedValue, selectedValues) =>
                              setFieldValue('status', getOneLevelSelectedData(selectedValues))
                            }
                          />
                          {errors.status && <div className="invalid-feedback d-block">{errors.status}</div>}
                        </FormGroup>
                      </Colxx>

                      {serviceType === ServiceTypes.Mc && (
                        <Colxx xxs="12" sm="6" lg="3">
                          <FormGroup className="form-group has-float-label">
                            <Label className="float-label">{t('operations.payer')}</Label>
                            <WithTooltip tooltipText={t('operation.phone_tooltip')}>
                              <Field
                                className="form-control"
                                maxLength="11"
                                type="text"
                                name="card_number"
                                onChange={({ target: { value } }) =>
                                  floatFieldValue(value, 'card_number', setFieldValue)
                                }
                              />
                            </WithTooltip>
                            {errors.card_number && touched.card_number && (
                              <div className="invalid-feedback d-block">{errors.card_number}</div>
                            )}
                          </FormGroup>
                        </Colxx>
                      )}

                      <Colxx xxs="12" sm="6" lg="3">
                        <FormGroup className="form-group has-float-label">
                          <Label className="float-label">{t('operations.merchant_transaction_id')}</Label>
                          <CustomTagInputField
                            name="pid"
                            setFieldValue={setFieldValue}
                            tooltip={t('operation.transaction_tooltip')}
                          />
                        </FormGroup>
                      </Colxx>

                      <Colxx xxs="12" sm="6" lg="3">
                        <FormGroup className="has-float-label">
                          <Label className="float-label">{t('operations.amount_income')}</Label>
                          <Field
                            className="form-control"
                            type="text"
                            name="amount"
                            onChange={({ target: { value } }) => floatFieldValue(value, 'amount', setFieldValue)}
                          />
                          {errors.amount && touched.amount && (
                            <div className="invalid-feedback d-block">{errors.amount}</div>
                          )}
                        </FormGroup>
                      </Colxx>

                      <Colxx xxs="12" sm="6" lg="3">
                        <FormGroup className="form-group has-float-label">
                          <Label className="float-label">{t('operations.wp transaction id')}</Label>
                          <CustomTagInputField
                            name="orderNumber"
                            type="number"
                            setFieldValue={setFieldValue}
                            tooltip={t('operation.order_number_tooltip')}
                          />
                        </FormGroup>
                      </Colxx>

                      {serviceType === ServiceTypes.Income && (
                        <Colxx xxs="12" sm="6" lg="3">
                          <FormGroup className="form-group has-float-label">
                            <Label className="float-label">{t('operations.alias')}</Label>
                            <CustomDropdownTreeSelect
                              name="site"
                              data={getAvailableSitesOptions(this.prepareSiteData(sites), t)}
                              shouldReset={shouldResetField}
                              onBlur={() => setFieldTouched('site', true)}
                              onChange={(changedValue, selectedValues) => {
                                setFieldValue('site', getOneLevelSelectedData(selectedValues));
                              }}
                            />
                            {errors.site && <div className="invalid-feedback d-block">{errors.site}</div>}
                          </FormGroup>
                        </Colxx>
                      )}

                      {/* TODO Бэк пока не готов */}
                      {/*(serviceType === ServiceTypes.Mc || serviceType === ServiceTypes.Outcome) && (
                      <Colxx xxs="12" sm="6" lg="3">
                        <FormGroup className="has-float-label">
                          <Label className="float-label">Описание заказа</Label>
                          <Field className="form-control" type="text" name="description" />
                          {errors.description && touched.description && (
                            <div className="invalid-feedback d-block">{errors.description}</div>
                          )}
                        </FormGroup>
                      </Colxx>
                    )*/}

                      {/* TODO Бэк пока не готов */}
                      {/*{(serviceType === ServiceTypes.Outcome) && <Row className="border mx-0 mb-3 pt-3">
                        <Colxx xxs="12" sm="6">
                          <FormGroup className="form-group ml-4">
                            <Field
                              name="registryPayments"
                              component={() => (
                                <Input
                                  id="registryPayments"
                                  type="checkbox"
                                  checked={values.registryPayments}
                                  onChange={() => setFieldValue('registryPayments', !values.registryPayments)}
                                />
                              )}
                            />
                            <Label for="registryPayments">Скрыть выплаты из реестров</Label>
                          </FormGroup>
                        </Colxx>
                        <Colxx xxs="12" sm="6">
                          <FormGroup className="form-group ml-4">
                            <Field
                              name="singlePayments"
                              component={() => (
                                <Input
                                  id="singlePayments"
                                  type="checkbox"
                                  checked={values.singlePayments}
                                  onChange={() => setFieldValue('singlePayments', !values.singlePayments)}
                                />
                              )}
                            />
                            <Label for="singlePayments">Скрыть единичные выплаты</Label>
                          </FormGroup>
                        </Colxx>
                      </Row>}*/}

                      <Colxx xxs="12" className="d-flex justify-content-end">
                        {showExportButton && (
                          <Button color="primary" onClick={exportFile} className="mr-3">
                            {t('operations.button.download file')}
                          </Button>
                        )}
                        <Button disabled={!!getFieldErrorsCount(errors)} color="primary" type="submit">
                          {t('button.find')}
                        </Button>
                      </Colxx>
                    </Row>
                  </CardBody>
                </Card>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

export default withTranslation()(OperationsListFilter);
