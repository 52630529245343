import {
  GET_GLOSSARY_PARTNERS,
  GET_GLOSSARY_PARTNERS_SUCCESS,
  GET_GLOSSARY_PARTNERS_ERROR,
  GET_GLOSSARY_CONTRACTS,
  GET_GLOSSARY_CONTRACTS_SUCCESS,
  GET_GLOSSARY_CONTRACTS_ERROR,
  GET_GLOSSARY_USERS,
  GET_GLOSSARY_USERS_SUCCESS,
  GET_GLOSSARY_USERS_ERROR,
  GET_SERVICE_TYPE_DOCS,
  GET_SERVICE_TYPE_DOCS_SUCCESS,
  GET_SERVICE_TYPE_DOCS_ERROR,
  POST_SERVICE_TYPE_DOCS,
  POST_SERVICE_TYPE_DOCS_SUCCESS,
  POST_SERVICE_TYPE_DOCS_ERROR,
  DELETE_SERVICE_TYPE_DOCS,
  DELETE_SERVICE_TYPE_DOCS_SUCCESS,
  DELETE_SERVICE_TYPE_DOCS_ERROR,
  RESET_GLOSSARY_USERS,
  RESET_GLOSSARY_PARTNERS,
  GET_GLOSSARY_USER_PARTNERS,
  GET_GLOSSARY_USER_PARTNERS_SUCCESS,
  GET_GLOSSARY_USER_PARTNERS_ERROR,
  RESET_GLOSSARY_CONTRACTS,
  GET_USER_PARTNER_ACCOUNTS,
  GET_USER_PARTNER_ACCOUNTS_SUCCESS,
  GET_USER_PARTNER_ACCOUNTS_ERROR,
  GET_GLOSSARY_ACCOUNTS_BY_PARTNER,
  GET_GLOSSARY_ACCOUNTS_BY_PARTNER_SUCCESS,
  GET_GLOSSARY_ACCOUNTS_BY_PARTNER_ERROR
} from '../actions';
import {
  GetGlossaryPartnersActionValues,
  GetGlossaryPartnersActionResult,
  GetGlossaryPartnersActionSuccess,
  GetGlossaryPartnersActionError,
  GetGlossaryContracts,
  GetGlossaryContractsSuccess,
  GetGlossaryContractsError,
  GetGlossaryUsersAction,
  GetGlossaryUsersSuccessAction,
  GetGlossaryUsersErrorAction,
  ResetGlossaryUsers,
  GlossaryPartner,
  GlossaryUser,
  ResetGlossaryPartners,
  GetUserPartnersAction,
  GetUserPartnersSuccessAction,
  GetUserPartnersErrorAction,
  ResetGlossaryContracts,
  GetUserPartnerAccounts,
  GetUserPartnerAccountsSuccess,
  GetUserPartnerAccountsError,
  GetGlossaryAccountsPartner,
  GetGlossaryAccountsPartnerSuccess,
  GetGlossaryAccountsPartnerError
} from './interface';
import { GlossaryUsersRequestValues } from '../../views/glossary/Users/interface';
import { ServiceTypes } from '../../views/operations/OperationsList/constants';
import { ContractRequestData } from '../../views/glossary/Contracts/interface';
import { Service } from '../services/interface';
import { Merchant } from '../menu/interface';
import { PayoutAccount } from '../operations/interface';

// GET GLOSSARY PARTNERS
export const getGlossaryPartners = (values: GetGlossaryPartnersActionValues): GetGlossaryPartnersActionResult => ({
  type: GET_GLOSSARY_PARTNERS,
  payload: values
});

export const getGlossaryPartnersSuccess = (rows: GlossaryPartner[]): GetGlossaryPartnersActionSuccess => ({
  type: GET_GLOSSARY_PARTNERS_SUCCESS,
  payload: {
    rows
  }
});

export const getGlossaryPartnersError = (error: string): GetGlossaryPartnersActionError => ({
  type: GET_GLOSSARY_PARTNERS_ERROR,
  payload: {
    error
  }
});

// GET GLOSSARY ACCOUNTS BY PARTNERS
export const getGlossaryAccountsByPartner = (merchantId: number[], userId?: number): GetGlossaryAccountsPartner => ({
  type: GET_GLOSSARY_ACCOUNTS_BY_PARTNER,
  payload: {
    merchantId,
    userId
  }
});

export const getGlossaryAccountsByPartnerSuccess = (
  accounts: PayoutAccount[],
  partnerId: number
): GetGlossaryAccountsPartnerSuccess => ({
  type: GET_GLOSSARY_ACCOUNTS_BY_PARTNER_SUCCESS,
  payload: { accounts, partnerId }
});

export const getGlossaryAccountsByPartnerError = (error: string): GetGlossaryAccountsPartnerError => ({
  type: GET_GLOSSARY_ACCOUNTS_BY_PARTNER_ERROR,
  payload: { error }
});

// GET CONTRACTS
export const getGlossaryContracts = (requestValues: ContractRequestData): GetGlossaryContracts => ({
  type: GET_GLOSSARY_CONTRACTS,
  payload: requestValues
});

export const getGlossaryContractsSuccess = (data: { [key: string]: Service[] }): GetGlossaryContractsSuccess => ({
  type: GET_GLOSSARY_CONTRACTS_SUCCESS,
  payload: data
});

export const getGlossaryContractsError = (error: string): GetGlossaryContractsError => ({
  type: GET_GLOSSARY_CONTRACTS_ERROR,
  payload: { error }
});

// GET USERS
export const getGlossaryUsers = (
  filterValues?: GlossaryUsersRequestValues,
  delayRequired: boolean = false
): GetGlossaryUsersAction => ({
  type: GET_GLOSSARY_USERS,
  payload: { filterValues, delayRequired }
});

export const getGlossaryUsersSuccess = (rows: GlossaryUser[], total: number): GetGlossaryUsersSuccessAction => ({
  type: GET_GLOSSARY_USERS_SUCCESS,
  payload: {
    rows,
    total
  }
});

export const getGlossaryUsersError = (error: string): GetGlossaryUsersErrorAction => ({
  type: GET_GLOSSARY_USERS_ERROR,
  payload: { error }
});

// GET USER PARTNERS
export const getGlossaryUserPartners = (userId?: number): GetUserPartnersAction => ({
  type: GET_GLOSSARY_USER_PARTNERS,
  payload: { userId }
});

export const getGlossaryUserPartnersSuccess = (
  userId: number,
  merchants: Merchant[]
): GetUserPartnersSuccessAction => ({
  type: GET_GLOSSARY_USER_PARTNERS_SUCCESS,
  payload: {
    userId,
    merchants
  }
});

export const getGlossaryUserPartnersError = (error: string): GetUserPartnersErrorAction => ({
  type: GET_GLOSSARY_USER_PARTNERS_ERROR,
  payload: { error }
});

// GET USER PARTNER ACCOUNTS
export const getUserPartnerAccounts = (merchantId: number[], userId?: number): GetUserPartnerAccounts => ({
  type: GET_USER_PARTNER_ACCOUNTS,
  payload: { merchantId, userId }
});

export const getUserPartnerAccountsSuccess = (accounts: PayoutAccount[]): GetUserPartnerAccountsSuccess => ({
  type: GET_USER_PARTNER_ACCOUNTS_SUCCESS,
  payload: { accounts }
});

export const getUserPartnerAccountsError = (error: string): GetUserPartnerAccountsError => ({
  type: GET_USER_PARTNER_ACCOUNTS_ERROR,
  payload: { error }
});

// SERVICES
export const getServiceTypeDocs = (serviceType: ServiceTypes): any => ({
  type: GET_SERVICE_TYPE_DOCS,
  payload: { serviceType }
});

export const getServiceTypeDocsSuccess = (serviceType: ServiceTypes, data: any): any => ({
  type: GET_SERVICE_TYPE_DOCS_SUCCESS,
  payload: {
    serviceType,
    data
  }
});

export const getServiceTypeDocsError = (error: string): any => ({
  type: GET_SERVICE_TYPE_DOCS_ERROR,
  payload: { error }
});

export const postServiceTypeDocs = (serviceType: ServiceTypes, doc: File): any => ({
  type: POST_SERVICE_TYPE_DOCS,
  payload: {
    serviceType,
    doc
  }
});

export const postServiceTypeDocsSuccess = (): any => ({
  type: POST_SERVICE_TYPE_DOCS_SUCCESS
});

export const postServiceTypeDocsError = (error: string): any => ({
  type: POST_SERVICE_TYPE_DOCS_ERROR,
  payload: { error }
});

export const deleteServiceTypeDocs = (id: number): any => ({
  type: DELETE_SERVICE_TYPE_DOCS,
  payload: { id }
});

export const deleteServiceTypeDocsSuccess = (): any => ({
  type: DELETE_SERVICE_TYPE_DOCS_SUCCESS
});

export const deleteServiceTypeDocsError = (error: string): any => ({
  type: DELETE_SERVICE_TYPE_DOCS_ERROR,
  payload: { error }
});

// RESET ACTIONS
export const resetGlossaryUsers = (): ResetGlossaryUsers => ({
  type: RESET_GLOSSARY_USERS
});

export const resetGlossaryPartners = (): ResetGlossaryPartners => ({
  type: RESET_GLOSSARY_PARTNERS
});

export const resetGlossaryContracts = (): ResetGlossaryContracts => ({
  type: RESET_GLOSSARY_CONTRACTS
});
