import React, { Component, Fragment, ReactNode } from 'react';
import { Row } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';

export default class EmptyPage extends Component<any, any> {
  render(): ReactNode {
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12" />
        </Row>
      </Fragment>
    );
  }
}
