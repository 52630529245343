import React, { Fragment, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Row } from 'reactstrap';
import isEqual from 'lodash/isEqual';

import { NotificationManager } from '../../../components/common/react-notifications';
import { NOTIFICATION_TIMEOUT } from '../../../constants/defaultValues';
import { scrollToTarget } from '../../../helpers/Utils';
import { Loader } from '../../../components/common/Loader';
import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import Breadcrumb from '../../../containers/navs/Breadcrumb';
import { NotFoundMessage } from '../../../components/common/NotFoundMessage';
import {
  getCurrencies,
  getDestinations,
  getOperations,
  getOperationsFile,
  getPayoutAccounts,
  resetOperations,
  getAvailableSites
} from '../../../redux/actions';
import { withTranslation } from 'react-i18next';
import OperationsListFilter from './OperationsListFilter';
import OperationsListTable from './OperationsListTable';
import ModalFileRequired from '../../app/ModalFileIsRequired';
import ModalFileIsCreated from '../../app/ModalFileIsCreated';
import ModalFileConfirm from '../../app/ModalFileConfirm';
import { prepareOperationsFileRequestValues, prepareOperationsListRequestValues, prepareRowsData } from './helpers';
import { ServiceTypes, RETURN_PERMISSION_CODE, CALLBACK_PERMISSION_CODE } from './constants';
import { TIMEZONE_TOOLTIP_TEXT } from '../../../constants/app';
import { IOperationsListProps, IOperationsListState } from './interface';
import { CurrenciesData } from '../../../redux/operations/interface';
import { OperationTypeKeys } from '../constants';
import { isAllowed } from '../../../helpers/Access';

class OperationsList extends React.Component<IOperationsListProps, IOperationsListState> {
  state: IOperationsListState = {
    page: 0,
    sorting: '',
    filterValuesChanged: false,
    fileRequired: false,
    fileCreated: false,
    smallFileRequired: false,
    smallFileCreated: false,
    values: {},
    shouldResetForm: false,
    activeCurrencies: [],
    fetchedServiceType: 0,
    canReturn: false,
    canCallback: false
  };
  tableRef = React.createRef<HTMLDivElement>();

  componentDidMount(): void {
    const {
      serviceType,
      merchantIdList,
      destinations,
      accounts,
      currencies,
      isAdmin,
      authUserId,
      selectedUserId,
      getPayoutAccountsAction,
      getCurrenciesAction,
      getDestinationsAction,
      getSitesAction,
      authUser,
      selectedUser
    } = this.props;

    const isDestinationsExist = destinations && destinations.data;
    const isAccountsExist = accounts && accounts.data;
    const isCurrenciesExist = currencies && currencies.data && currencies.data;

    if (!isDestinationsExist && serviceType === ServiceTypes.Outcome) {
      getDestinationsAction();
    }

    if (!isAccountsExist && merchantIdList && merchantIdList.length) {
      getPayoutAccountsAction(
        merchantIdList,
        isAdmin && selectedUserId && authUserId !== selectedUserId ? selectedUserId : undefined
      );
    }

    !isCurrenciesExist && getCurrenciesAction();
    isCurrenciesExist && this.setActiveCurrencies();
    this.setActiveCurrencies();
    getSitesAction(String(selectedUserId || authUserId));

    const currentUser = selectedUser ? selectedUser : authUser;
    this.props.getCurrenciesAction();
    if (currentUser) {
      this.setState({
        canReturn: currentUser.dev_mode && isAllowed(currentUser, RETURN_PERMISSION_CODE),
        canCallback: isAllowed(currentUser, CALLBACK_PERMISSION_CODE)
      });
    }
  }

  componentDidUpdate(prevProps: Readonly<IOperationsListProps>): void {
    const {
      merchantIdList,
      isAdmin,
      authUserId,
      selectedUserId,
      count,
      maxCount,
      fileLoading,
      error,
      currencies,
      data,
      serviceType,
      getPayoutAccountsAction,
      resetOperationsAction,
      getSitesAction,
      loading,
      selectedUser
    } = this.props;
    const { filterValuesChanged, shouldResetForm } = this.state;

    if (error && error !== prevProps.error) {
      NotificationManager.info(error, 'Ошибка', NOTIFICATION_TIMEOUT, null, null, '');
    }

    if (filterValuesChanged && prevProps.data !== data && maxCount && count > maxCount) {
      this.setState({ filterValuesChanged: false, fileRequired: true });
    }

    if (prevProps.fileLoading && !fileLoading && !error) {
      this.setState({ fileCreated: true });
    }

    if (
      isEqual(prevProps.merchantIdList, merchantIdList) &&
      prevProps.selectedUserId === selectedUserId &&
      shouldResetForm
    ) {
      this.setState({ shouldResetForm: false });
    }

    if (prevProps.selectedUserId !== selectedUserId) {
      getSitesAction(String(selectedUserId || authUserId));
    }

    if (!isEqual(prevProps.merchantIdList, merchantIdList) || prevProps.selectedUserId !== selectedUserId) {
      resetOperationsAction(serviceType);

      this.setState({ shouldResetForm: true });

      if (merchantIdList && merchantIdList.length) {
        getPayoutAccountsAction(
          merchantIdList,
          isAdmin && selectedUserId && authUserId !== selectedUserId ? selectedUserId : undefined
        );
      }
    }

    if (prevProps.serviceType !== serviceType || !isEqual(prevProps.currencies, currencies)) {
      this.setActiveCurrencies();
    }

    if (prevProps.data !== data && data && data.length) {
      scrollToTarget(this.tableRef.current);
    }

    if (prevProps.loading !== loading && loading) {
      this.setState({ fetchedServiceType: serviceType });
    }

    if (
      prevProps.selectedUser !== selectedUser ||
      (prevProps.selectedUser && selectedUser && prevProps.selectedUser.id !== selectedUser.id)
    ) {
      this.setState({
        canReturn: isAllowed(selectedUser, RETURN_PERMISSION_CODE),
        canCallback: isAllowed(selectedUser, CALLBACK_PERMISSION_CODE)
      });
    }
  }

  componentWillUnmount(): void {
    const { resetOperationsAction, serviceType } = this.props;

    resetOperationsAction(serviceType);
  }

  setActiveCurrencies = () => {
    const { currencies, serviceType } = this.props;
    const activeCurrencies =
      currencies &&
      currencies.data &&
      currencies.data.filter((item: CurrenciesData) => item[OperationTypeKeys[serviceType]]);
    this.setState({ activeCurrencies });
  };

  getOperations = (values, page = 0, sorting = '') => {
    const { serviceType, getOperationsAction, merchantIdList, maxPageSize } = this.props;

    merchantIdList &&
      this.setState(
        (state: IOperationsListState) =>
          values === state.values
            ? { ...state, page, sorting }
            : { ...state, values, page, sorting, filterValuesChanged: true },
        () => {
          getOperationsAction(
            serviceType,
            prepareOperationsListRequestValues(values, this.props, page, sorting, maxPageSize)
          );
        }
      );
  };

  setSorting = (newSorted, values) => {
    const sorting = `${newSorted[0].id} ${newSorted[0].desc ? 'DESC' : 'ASC'}`;
    this.setState({ sorting: newSorted });
    this.getOperations(values, 0, sorting);
  };

  onConfirmModal = (values: any) => () => {
    const { serviceType, getOperationsFileAction, merchantIdList } = this.props;

    merchantIdList &&
      this.setState({ fileRequired: false }, () => {
        getOperationsFileAction(serviceType, prepareOperationsFileRequestValues(values, this.props));
      });
  };

  onConfirmSmallFileModal = (values: any) => () => {
    const { serviceType, getOperationsFileAction } = this.props;

    this.setState({ smallFileRequired: false }, () => {
      getOperationsFileAction(serviceType, prepareOperationsFileRequestValues(values, this.props));
    });
  };

  exportFile = () => {
    const { data } = this.props;
    if (data && data.length) {
      this.setState({ smallFileRequired: true });
    }
  };

  render(): ReactNode {
    const {
      loading,
      fileLoading,
      data,
      count,
      accounts,
      destinations,
      match,
      merchantIdList,
      maxPageSize,
      serviceType,
      sites,
      t
    } = this.props;
    const {
      page,
      values,
      fileRequired,
      fileCreated,
      smallFileCreated,
      smallFileRequired,
      activeCurrencies,
      shouldResetForm,
      fetchedServiceType,
      canCallback,
      canReturn,
      sorting
    } = this.state;

    const accountByServiceType = accounts.data && accounts.data[serviceType] ? accounts.data[serviceType] : [];

    return (
      <Fragment>
        {(loading || fileLoading) && <Loader />}

        {(serviceType === ServiceTypes.Income || serviceType === ServiceTypes.Mc) && (
          <Row className="flex-shrink-0">
            <Colxx xxs="12">
              <Breadcrumb heading="menu.operations" match={match} />
              <div className="time-tip  d-none d-lg-inline-block">{t(TIMEZONE_TOOLTIP_TEXT)}</div>
              <Separator className="mb-5" />
            </Colxx>
          </Row>
        )}

        {merchantIdList && merchantIdList.length > 0 && (
          <OperationsListFilter
            accounts={accountByServiceType}
            currencies={activeCurrencies}
            destinations={destinations.data}
            onSubmit={(newValues: any) => this.getOperations(newValues)}
            serviceType={serviceType}
            exportFile={this.exportFile}
            sites={sites}
            showExportButton={Boolean(data && data.length)}
            shouldResetForm={shouldResetForm}
          />
        )}

        {!(merchantIdList && merchantIdList.length) && <NotFoundMessage text={t('no_partners_selected')} />}
        {fetchedServiceType === serviceType && !data && !loading && <NotFoundMessage />}

        <OperationsListTable
          tableRef={this.tableRef}
          data={prepareRowsData(data)}
          count={count}
          page={page}
          maxPageSize={maxPageSize}
          onPageChange={(newPage: number) => this.getOperations(values, newPage, sorting)}
          onSortedChange={(newSorted) => {
            this.setSorting(newSorted, values);
          }}
          destinations={destinations.data}
          serviceType={serviceType}
          canReturn={canReturn}
          canCallback={canCallback}
        />

        {fileRequired && (
          <ModalFileRequired
            onCloseModal={() => this.setState({ fileRequired: false })}
            onConfirmModal={this.onConfirmModal(values)}
          />
        )}

        {smallFileRequired && (
          <ModalFileConfirm
            onCloseModal={() => this.setState({ smallFileRequired: false })}
            onConfirmModal={this.onConfirmSmallFileModal(values)}
          />
        )}

        {(fileCreated || smallFileCreated) && (
          <ModalFileIsCreated onCloseModal={() => this.setState({ fileCreated: false })} />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ operations, settings, authUser, sites }, { serviceType }) => ({
  isAdmin: authUser.user.data ? authUser.user.data.is_admin : false,
  selectedUserId: settings.selectedUser ? settings.selectedUser.id : null,
  selectedUser: settings.selectedUser,
  authUserId: authUser.user.data ? authUser.user.data.id : null,
  authUser: authUser.user.data,
  merchantIdList: settings.selectedMerchantsId,
  maxPageSize: settings.gcmSettings.data ? settings.gcmSettings.data.max_page_size : 50,
  maxCount: settings.gcmSettings.data ? settings.gcmSettings.data.max_display_records : null,
  data: operations[serviceType].data,
  count: operations[serviceType].count,
  loading: operations[serviceType].loading,
  error: operations[serviceType].error,
  fileLoading: operations[serviceType].fileLoading,
  accounts: operations.payoutAccounts,
  destinations: operations.destinations,
  currencies: settings.currencies,
  sites: sites.sites.data || []
});

const mapDispatchToProps = {
  getPayoutAccountsAction: getPayoutAccounts,
  getDestinationsAction: getDestinations,
  getCurrenciesAction: getCurrencies,
  getOperationsAction: getOperations,
  getOperationsFileAction: getOperationsFile,
  resetOperationsAction: resetOperations,
  getSitesAction: getAvailableSites
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OperationsList as any)
);
