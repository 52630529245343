import {
  GET_DEFAULT_PAGE,
  GET_DEFAULT_PAGE_SUCCESS,
  GET_DEFAULT_PAGE_ERROR,
  GET_USER_BY_ID,
  GET_USER_BY_ID_SUCCESS,
  GET_USER_BY_ID_ERROR
} from '../actions';

import { AppState } from './interface';

const INIT_STATE: AppState = {
  defaultPage: {
    data: null,
    loading: false,
    error: ''
  },
  userById: {
    data: null,
    loading: false,
    error: ''
  }
};

export default (state: AppState = INIT_STATE, { type, payload }): AppState => {
  switch (type) {
    case GET_DEFAULT_PAGE:
      return {
        ...state,
        defaultPage: {
          ...state.defaultPage,
          loading: true,
          error: ''
        }
      };
    case GET_DEFAULT_PAGE_SUCCESS:
      return {
        ...state,
        defaultPage: {
          ...state.defaultPage,
          data: payload.data,
          loading: false
        }
      };
    case GET_DEFAULT_PAGE_ERROR:
      return {
        ...state,
        defaultPage: {
          ...state.defaultPage,
          error: payload.error,
          loading: false
        }
      };
    case GET_USER_BY_ID:
      return {
        ...state,
        userById: {
          ...state.userById,
          loading: true,
          error: ''
        }
      };
    case GET_USER_BY_ID_SUCCESS:
      return {
        ...state,
        userById: {
          ...state.userById,
          data: payload.data,
          loading: false
        }
      };
    case GET_USER_BY_ID_ERROR:
      return {
        ...state,
        userById: {
          ...state.userById,
          error: payload.error,
          loading: false
        }
      };
    default:
      return { ...state };
  }
};
