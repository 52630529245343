export const colourStyles = {
  option: (styles, { data }) => ({
    ...styles,
    color: data.color,
    borderColor: data.color
  }),
  multiValue: (styles, { data }) => ({
    ...styles,
    color: data.color,
    borderColor: data.color
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
    borderColor: data.color
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    borderColor: data.color
  })
};
