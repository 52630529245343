import { startOfDay } from 'date-fns';
export const OPERATION_TYPES = (t: any) => ({
  IN: t('balance.status.in'),
  OUT: t('balance.status.out'),
  BILL: t('balance.status.bill')
});

export const OPERATION_TYPE_OPTIONS = (t: any) => [
  {
    label: t('all'),
    value: '',
    key: '',
    expanded: true,
    checked: true,
    children: Object.entries(OPERATION_TYPES(t)).map(([key, desc]) => ({ key, value: key, label: desc }))
  }
];

export const BALANCE_OPERATIONS_INITIAL_VALUES = {
  dateFrom: startOfDay(new Date()),
  dateTo: new Date(),
  account: {
    key: 0,
    label: '',
    value: 0
  },
  operationType: [{ value: '' }],
  amount: ''
};
