import React, { Component, Fragment, ReactNode } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import Gcm from './Gcm/Gcm';
import Vault from './Vault/Vault';
import { Card, CardBody, Nav, NavItem, Row } from 'reactstrap';
import { Colxx } from '../../../components/common/CustomBootstrap';
import { NavLink } from 'react-router-dom';
import { SystemSettingsState, SystemSettingsProps } from './interface';
import { VAULT_SETTING_PERMISSION, GMC_SETTING_PERMISSSION } from '../../../constants/permissions';
import { isAllowed } from '../../../helpers/Access';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

class System extends Component<SystemSettingsProps, SystemSettingsState> {
  constructor(props: any) {
    super(props);
    this.state = {
      tabItems: []
    };
  }

  componentDidMount(): void {
    this.updateTabItems();
  }

  componentDidUpdate(prevProps: any): void {
    const { selectedUser } = this.props;

    if (
      prevProps.selectedUser !== selectedUser ||
      (prevProps.selectedUser && selectedUser && prevProps.selectedUser.id !== selectedUser.id)
    ) {
      this.updateTabItems();
    }
  }

  tabItems = () => [
    { label: 'gcm', path: 'gcm', permission: GMC_SETTING_PERMISSSION },
    { label: 'vault', path: 'vault', permission: VAULT_SETTING_PERMISSION }
  ];

  updateTabItems = () => {
    const { selectedUser, user } = this.props;
    const currentUser = selectedUser ? selectedUser : user;
    this.setState({
      tabItems: this.tabItems().filter((item) => {
        return isAllowed(currentUser, item.permission);
      })
    });
  };

  render(): ReactNode {
    const { match, t } = this.props;
    const { tabItems } = this.state;
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Card className="mb-5">
              <CardBody>
                <Nav className="nav-pills nav-fill">
                  {tabItems.map((menuItem: { label: string; path: string }) => (
                    <Colxx key={menuItem.path} xxs="12" lg="3">
                      <NavItem key={menuItem.path}>
                        <NavLink
                          className="payouts nav-link px-0 text-lg-center"
                          isActive={() => window.location.pathname.split('/').includes(menuItem.path)}
                          to={`${match.url}/${menuItem.path}`}
                        >
                          {t(`systemSettings.${menuItem.label}`)}
                        </NavLink>
                      </NavItem>
                    </Colxx>
                  ))}
                </Nav>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
        <Switch>
          <Redirect exact from={match.url} to={`${match.url}/gcm`} />
          <Route exact path={`${match.url}/gcm`} component={Gcm} />
          <Route exact path={`${match.url}/vault`} component={Vault} />
        </Switch>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ authUser, settings }) => ({
  user: authUser.user.data ? authUser.user.data : null,
  selectedUser: settings.selectedUser
});

export default withTranslation()(connect(mapStateToProps)(System));
