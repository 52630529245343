import React, { Component, Fragment, ReactNode } from 'react';
import { DesignPreviewProps } from '../interface';
import { GenCustomDesignStyles } from '../../../../helpers/CustomDesign';
import BrowserBar from './BrowserBar';
import LoginPagePreview from './LoginPagePreview';
import CabinetPagePreview from './CabinetPagePreview';
import { PREVIEW_PAGE_VIEWS } from '../constants';

export default class Preview extends Component<DesignPreviewProps> {
  render(): ReactNode {
    const { view, customDesign, hints } = this.props;
    const customDesignCSS = GenCustomDesignStyles(customDesign, true);
    const loginPreview = (
      <LoginPagePreview customDesignCSS={customDesignCSS} customDesignConfig={customDesign.data} hints={hints} />
    );
    const cabinetPreview = <CabinetPagePreview customDesignCSS={customDesignCSS} />;
    return (
      <Fragment>
        <BrowserBar designCurrentConfig={customDesign.data} hint={!!(hints && hints.browserTab)} />
        {view === PREVIEW_PAGE_VIEWS.loginPage ? loginPreview : ''}
        {view === PREVIEW_PAGE_VIEWS.cabinet ? cabinetPreview : ''}
      </Fragment>
    );
  }
}
