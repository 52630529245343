import { ALL_CURRENCY_OPTION } from '../../../constants/app';

export enum StatusTypes {
  All = 0,
  Income,
  Outcome,
  Active,
  NotActive
}

export const DEFAULT_SORTER = [
  {
    id: 'id',
    desc: false
  }
];

export const GLOSSARY_CURRENCIES_STATUS_OPTIONS = (t: any) => [
  {
    key: StatusTypes.All,
    label: t('all'),
    value: StatusTypes.All
  },
  {
    key: StatusTypes.Active,
    label: t('currency.active'),
    value: StatusTypes.Active
  },
  {
    key: StatusTypes.NotActive,
    label: t('currency.not_active'),
    value: StatusTypes.NotActive
  }
];

export const GLOSSARY_CURRENCIES_INITIAL_VALUES = (t: any) => ({
  id: '',
  name: '',
  country: ALL_CURRENCY_OPTION(t)[0],
  status: GLOSSARY_CURRENCIES_STATUS_OPTIONS(t)[0]
});
