import React from 'react';
import DropdownTreeSelect, { DropdownTreeSelectProps, TreeNode } from 'react-dropdown-tree-select';
import Select from 'react-select';
import isEqual from 'lodash/isEqual';
import i18n from '../../i18n';

import { SelectorOption } from '../../interfaces/app';
import 'react-dropdown-tree-select/dist/styles.css';

const { t } = i18n;

interface CustomDropdownTreeSelectProps extends DropdownTreeSelectProps {
  data: TreeNode[] | SelectorOption[];
  name: string;
  shouldReset?: boolean;
}

interface CustomDropdownTreeSelectState {
  data: SelectorOption[];
}

export class CustomDropdownTreeSelect extends React.Component<
  CustomDropdownTreeSelectProps,
  CustomDropdownTreeSelectState
> {
  state: CustomDropdownTreeSelectState = { data: this.props.data };

  componentWillReceiveProps = (nextProps) => {
    if (!isEqual(nextProps.data, this.state.data)) {
      this.setState({ data: nextProps.data });
    }
  };

  shouldComponentUpdate = (nextProps) => {
    if (nextProps.disabled !== this.props.disabled || nextProps.shouldReset) {
      return true;
    }

    return !isEqual(nextProps.data, this.state.data);
  };

  render() {
    const { data, name, ...rest } = this.props;

    const ALL_OPTIONS = [
      {
        key: 'all',
        label: t('all'),
        value: ''
      }
    ];

    return (
      <>
        {data && data.length === 1 && data[0].key === 'all' && !data[0]['children'] ? (
          <Select
            onChange={rest.onChange}
            className="react-select"
            classNamePrefix="react-select"
            name={name}
            value={ALL_OPTIONS[0]}
            options={ALL_OPTIONS}
            placeholder=""
          />
        ) : (
          <DropdownTreeSelect
            data={this.state.data}
            keepOpenOnSelect={true}
            clearSearchOnChange={true}
            texts={{ noMatches: t('Не найдено'), placeholder: t('Выбрать') }}
            inlineSearchInput={true}
            showPartiallySelected={true}
            className="custom-tree-select"
            {...rest}
          />
        )}
      </>
    );
  }
}
