export default {
  /* General */
  'general.copyright': 'Gogo React © 2018 All Rights Reserved.',

  /* User Login, Logout, Register */
  'user.login-title': 'Вход',
  'user.register': 'Register',
  'user.forgot-password': 'Восстановить доступ',
  'user.email': 'Почта',
  'user.password': 'Пароль',
  'user.old-password': 'Текущий пароль',
  'user.new-password': 'Новый пароль',
  'user.repeat-new-password': 'Повторить новый пароль',
  'user.temp-password': 'Временный пароль',
  'user.restore-access': 'Восстановить доступ',
  'user.restore-password': 'Восстановить пароль',
  'user.password-strength-level': 'Сложность пароля',
  'user.password-strength-low': 'низкая',
  'user.password-strength-medium': 'средняя',
  'user.password-strength-high': 'высокая',
  'user.fullname': 'Full Name',
  'user.phone': 'Телефон',
  'user.login-button': 'Войти',
  'user.register-button': 'REGISTER',
  'user.reset-password-button': 'RESET',
  'user.get-tmp-password': 'Получить ссылку для восстановления',
  'user.set-tmp-password': 'Ввести временный пароль',
  'user.retry-tmp-password': 'Повторить',
  'user.buy': 'BUY',
  'user.username': 'Логин',

  /* Menu */
  'menu.app': 'Главная',
  'menu.blank-page': 'Пустая страница',
  'menu.restore-password': 'Восстановить пароль',
  'menu.change-password': 'Сменить пароль',
  'menu.services': 'Услуги',
  'menu.statistics': 'Статистика',
  'menu.summary': 'Сводная',
  'menu.by-customers': 'По клиентам',
  'menu.charts': 'Графики',
  'menu.conversion-chart': 'График конверсии',
  'menu.period-summary': 'Сводная статистика в разрезе',
  'menu.operations': 'Финансовые операции',
  'menu.acquiring': 'Прием',
  'menu.mobile-commerce': 'Мобильная коммерция',
  'menu.history': 'История',
  'menu.balance': 'Баланс',
  'menu.balance-operations': 'Операции с балансом',
  'menu.balance-remainder': 'Сумма баланса',
  'menu.payouts': 'Выплаты',
  'menu.documents': 'Финансовые документы',
  'menu.disposals': 'Распоряжения',
  'menu.statement': 'Отчет Statement',
  'menu.actionLog': 'Журнал действий',
  'menu.glossary': 'Справочники',
  'menu.users': 'Пользователи',
  'menu.partners': 'Партнеры',
  'menu.contracts': 'Договоры',
  'menu.currencies': 'Валюты',
  'menu.settings': 'Настройки',
  'menu.profile': 'Профиль',
  'menu.gcm': 'Настройки ГКМ',
  'menu.system-settings': 'Общие настройки',
  'menu.permissions': 'Права доступа',
  'menu.create': 'Создать распоряжение',
  'menu.account': 'Учетная запись',
  'menu.support': 'Обращения в техническую поддержку',
  'menu.downloads': 'Загрузки',
  'menu.custom-design': 'Дизайн',

  /* Error Page */
  'pages.error-title':
    'Кажется что-то пошло не так! Страницу, которую вы запрашиваете, не существует. Возможно, она устарела или был введен неверный адрес в адресной строке.',
  'pages.error-code': 'Error code',
  'pages.go-back-home': 'ВЕРНУТЬСЯ НАЗАД',

  /* Topnav */
  'topnav.user.profile': 'Профиль',
  'topnav.user.change-password': 'Сменить пароль',
  'topnav.user.exit': 'Выйти',
  'topnav.easyAccess.writeToTechnicalSupport': 'Написать в техническую поддержку',
  'topnav.easyAccess.downloadPage': 'Страница загрузок',
  'topnav.easyAccess.currencyRates': 'Курсы валют',

  /* Buttons */
  'button.change-password': 'Сменить пароль',
  'button.getRefund': 'Оформить возврат',
  'button.sendCallback': 'Отправить колбэк',
  'button.find': 'Искать',
  'button.showStatements': 'Отобразить стейтменты',
  'button.save': 'Сохранить',
  'button.cancel': 'Отменить',
  'button.confirm': 'Подтвердить',
  'button.yes': 'Да',
  'button.no': 'Нет',

  /* Operations */
  'operations.history.order': 'Заказ',
  'operations.history.noData': 'Нет данных',
  'operations.history.loading': 'Загрузка',

  'glossary.service-name': 'Наименование услуги',
  'glossary.services.loading': 'Загрузка',
  'glossary.merchant': 'Мерчант',
  'glossary.merchant-id': 'Id мерчанта',
  'services.noData': 'Нет данных',

  /* Tables */
  'tables.noData': 'Нет данных',
  'tables.loading': 'Загрузка',

  /* Balance */
  'balance.on-balance': 'На выплатном балансе',
  'balance.last-replenishment': 'Последнее пополнение',

  /* Settings */
  'settings.day': 'День',
  'settings.month': 'Месяц',
  'settings.year': 'Год',
  'settings.writeToTechnicalSupport': 'В случае проблем со вторым фактором, обратитесь в техническую поддержку',

  /* fields */
  'field.dateFrom': 'Дата с',
  'field.dateTo': 'По',

  /* Custom Design */
  'customDesign.logo': 'Лого',
  'customDesign.buttonColor': 'Цвет кнопок (блок 3)',
  'customDesign.menuTextColor': 'Цвет текста меню (блок 1, 2)',
  'customDesign.menuTextHoverColor': 'Цвет текста меню при наведении (блок 1, 2)',
  'customDesign.customCss': 'Дополнительный CSS',
  'customDesign.theme': 'Тема',
  'customDesign.menuColor': 'Цвет меню (блок 1)',
  'customDesign.logoSize': 'Размер лого (блок 6)',
  'customDesign.loginLogoSize': 'Размер лого (блок 3)',
  'customDesign.align.left': 'Слева',
  'customDesign.align.right': 'Справа',
  'customDesign.align.center': 'По центру',
  'customDesign.logoPlacement': 'Выравнивания лого (блок 3)',
  'customDesign.submenuColor': 'Цвет подменю (блок 2)',
  'customDesign.backgroundColor': 'Цвет фона страницы (блок 5)',
  'customDesign.cardColor': 'Цвет фона контента (блок 4)',
  'customDesign.themeColor1': 'Основной цвет',
  'customDesign.loginBackgroundColor': 'Цвет фона страницы входа (блок 2)',
  'customDesign.loginCardColor': 'Цвет фона формы входа (блок 1)',
  'customDesign.loginCardBackground': 'Фон формы входа (блок 1)',
  'customDesign.loginLogo': 'Лого формы входа (блок 3)',
  'customDesign.loginBackground': 'Фон страницы входа (блок 2)',
  'customDesign.cabinetSettings': 'Дизайн кабинета',
  'customDesign.loginSettings': 'Дизайн страницы входа',
  'customDesign.generalSettings': 'Дизайн общих настроек',
  'customDesign.domain': 'Домен',
  'customDesign.preview': 'Предпросмотр',
  'customDesign.resetToDefaultPreview': 'Сбросить предпросмотр',
  'customDesign.resetToDefault': 'Сбросить',
  'customDesign.modalTitle': 'Сбросить настройки дизайна',
  'customDesign.modalText': 'Вы действительно хотите сбросить настройки кастомного дизайна?',
  'customDesign.favicon': 'Иконка на вкладке в браузере (блок 1)',
  'customDesign.tabTitle': 'Название вкладки браузера (блок 1)',
  'customDesign.supportEmail': 'Email техподдержки (блок 2)',
  'customDesign.startPage': 'Стартовая страница',
  'customDesign.modalConfirm': 'Да',
  'customDesign.modalCancel': 'Нет',
  'customDesign.loginPage': 'Страница входа',
  'customDesign.cabinet': 'Личный кабинет',
  'customDesign.general': 'Общие настройки',

  /* System settings */
  'systemSettings.gcm': 'Настройки ГКМ',
  'systemSettings.vault': 'Настройки Vault',
  'systemSettings.lastUpdateTime': 'Дата и время последнего обновления',
  'systemSettings.update': 'Обновить',
  'systemSettings.updateInterval': 'Интервал регулярного обновления (часы)',
  'systemSettings.regularlyDataUpdate': 'Регулярное обновление данных с Vault',
  'systemSettings.maxValue': 'Максимальное значение',
  'systemSettings.minValue': 'Минимальное значение',
  'systemSettings.integerOnly': 'Только целые числа',

  /* Payouts */
  'payouts.actionConfirm': 'Подтверждение действия',
  'payouts.toNumber': 'На номер',
  'payouts.smsWasSend': 'было отправлено SMS с кодом подтверждения.',
  'payouts.inputCodeToConfirm': 'Для подтверждения выплаты нужно ввести этот код ниже:',
  'payouts.inputCodeToRefundConfirm': 'Для подтверждения возврата нужно ввести этот код ниже:',
  'payouts.code': 'Код',
  'payouts.codeDeadline': 'Код действительный еще',
  'payouts.sec': 'сек.',
  'payouts.attemptsWarning': 'Код введен неверно, попробуйте еще раз.',
  'payouts.attemptsLeft': 'Осталось попыток:',
  'payouts.paymentID': 'Выплата ID:',
  'payouts.result0': 'отправлена в обработку.',
  'payouts.result1': 'отменена. Введен неверный код подтверждения. Повторите операцию.',
  'payouts.result2': 'отменена. Срок подтверждения выплаты с помощью отправленого кода истек.',
  'payouts.result3': 'отменена.',
  'payouts.result4': 'отменена (либо не выполнена). Возникла внутренняя ошибка. Обратитесь в техническую поддержку.',
  'payouts.resultHeaderSuccess': 'Подтверждено',
  'payouts.resultHeaderFailure': 'Не подтверждено',
  'payouts.cancel': 'Отмена выплаты',
  'payouts.cancelConfirmationText': 'Вы действительно хотите отменить подтверждение выплаты?',
  'payouts.cancelRefundext': 'Вы действительно хотите отменить операцию?',
  'payouts.error.codeLength': 'Введите шестизначный код',
  'payouts.refundID': 'Возврат транзакции ID:'
};
