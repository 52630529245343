import React from 'react';
import { localeOptions } from '../constants/defaultValues';
import { UncontrolledDropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import { LocaleOption } from '../constants/interface';
import i18n from '../i18n';

export default class LanguageSelect extends React.Component<any, any> {
  handleChangeLocale = (locale: string): void => {
    i18n.changeLanguage(locale);
    window.location.reload();
  };

  render() {
    return (
      <div className="d-inline-block">
        <UncontrolledDropdown className="ml-2">
          <DropdownToggle caret color="light" size="sm" className="language-button">
            <span className="name">{(i18n.resolvedLanguage || 'ru').toUpperCase()}</span>
          </DropdownToggle>
          <DropdownMenu className="mt-3" right>
            {localeOptions.map((localeOption: LocaleOption) => (
              <DropdownItem onClick={() => this.handleChangeLocale(localeOption.id)} key={localeOption.id}>
                {localeOption.name}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    );
  }
}
