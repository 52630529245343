import React, { ReactNode } from 'react';
import { Badge } from 'reactstrap';

interface WithClearButtonProps {
  onClear: () => void;
}

export default class WithClearButton extends React.Component<WithClearButtonProps, any> {
  render(): ReactNode {
    const { children, onClear } = this.props;

    return (
      <>
        {children}
        <div className="badge-top-right position-absolute cursor-pointer input-clear-button">
          <Badge color="empty" onClick={onClear}>
            <i className="simple-icon-close" />
          </Badge>
        </div>
      </>
    );
  }
}
