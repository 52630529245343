/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';
export const CHANGE_USER = 'CHANGE_USER';
export const RESET_USER_AND_MERCHANT_STORE = 'RESET_USER_AND_MERCHANT_STORE';
export const CHANGE_MERCHANT = 'CHANGE_MERCHANT';

export const GET_SYSTEM_SETTINGS = 'GET_SYSTEM_SETTINGS';
export const GET_SYSTEM_SETTINGS_SUCCESS = 'GET_SYSTEM_SETTINGS_SUCCESS';
export const GET_SYSTEM_SETTINGS_ERROR = 'GET_SYSTEM_SETTINGS_ERROR';

export const PUT_SYSTEM_SETTINGS = 'PUT_SYSTEM_SETTINGS';
export const PUT_SYSTEM_SETTINGS_SUCCESS = 'PUT_SYSTEM_SETTINGS_SUCCESS';
export const PUT_SYSTEM_SETTINGS_ERROR = 'PUT_SYSTEM_SETTINGS_ERROR';

export const GET_PERMISSIONS_SETTINGS = 'GET_PERMISSIONS_SETTINGS';
export const GET_PERMISSIONS_SETTINGS_SUCCESS = 'GET_PERMISSIONS_SETTINGS_SUCCESS';
export const GET_PERMISSIONS_SETTINGS_ERROR = 'GET_PERMISSIONS_SETTINGS_ERROR';

export const PUT_PERMISSIONS_SETTINGS = 'PUT_PERMISSIONS_SETTINGS';
export const PUT_PERMISSIONS_SETTINGS_SUCCESS = 'PUT_PERMISSIONS_SETTINGS_SUCCESS';
export const PUT_PERMISSIONS_SETTINGS_ERROR = 'PUT_PERMISSIONS_SETTINGS_ERROR';

export const GET_CURRENCIES = 'GET_CURRENCIES';
export const GET_CURRENCIES_SUCCESS = 'GET_CURRENCIES_SUCCESS';
export const GET_CURRENCIES_ERROR = 'GET_CURRENCIES_ERROR';

export const CHANGE_CURRENCY = 'CHANGE_CURRENCY';
export const CHANGE_CURRENCY_SUCCESS = 'CHANGE_CURRENCY_SUCCESS';
export const CHANGE_CURRENCY_ERROR = 'CHANGE_CURRENCY_ERROR';

export const GET_ACCOUNT_SETTINGS = 'GET_ACCOUNT_SETTINGS';
export const GET_ACCOUNT_SETTINGS_SUCCESS = 'GET_ACCOUNT_SETTINGS_SUCCESS';
export const GET_ACCOUNT_SETTINGS_ERROR = 'GET_ACCOUNT_SETTINGS_ERROR';

export const PUT_ACCOUNT_SETTINGS = 'PUT_ACCOUNT_SETTINGS';
export const PUT_ACCOUNT_SETTINGS_SUCCESS = 'PUT_ACCOUNT_SETTINGS_SUCCESS';
export const PUT_ACCOUNT_SETTINGS_ERROR = 'PUT_ACCOUNT_SETTINGS_ERROR';

export const RESET_ACCOUNT_SETTINGS_STORE = 'RESET_ACCOUNT_SETTINGS_STORE';

/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const GOOGLE_AUTH = 'GOOGLE_AUTH';
export const GOOGLE_AUTH_SUCCESS = 'GOOGLE_AUTH_SUCCESS';
export const GOOGLE_AUTH_ERROR = 'GOOGLE_AUTH_ERROR';
export const SMS_AUTH = 'SMS_AUTH';
export const SMS_AUTH_SUCCESS = 'SMS_AUTH_SUCCESS';
export const SMS_AUTH_ERROR = 'SMS_AUTH_ERROR';
export const GET_GOOGLE_QR = 'GET_GOOGLE_QR';
export const GET_GOOGLE_QR_SUCCESS = 'GET_GOOGLE_QR_SUCCESS';
export const GET_GOOGLE_QR_ERROR = 'GET_GOOGLE_QR_ERROR';
export const GET_GOOGLE_CODE = 'GET_GOOGLE_CODE';
export const GET_GOOGLE_CODE_SUCCESS = 'GET_GOOGLE_CODE_SUCCESS';
export const GET_GOOGLE_CODE_ERROR = 'GET_GOOGLE_CODE_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const GET_TEMPORARY_PASSWORD = 'GET_TEMPORARY_PASSWORD';
export const GET_TEMPORARY_PASSWORD_SUCCESS = 'GET_TEMPORARY_PASSWORD_SUCCESS';
export const GET_TEMPORARY_PASSWORD_ERROR = 'GET_TEMPORARY_PASSWORD_ERROR';
export const RESTORE_2FA = 'RESTORE_2FA';
export const RESTORE_2FA_SUCCESS = 'RESTORE_2FA_SUCCESS';
export const RESTORE_2FA_ERROR = 'RESTORE_2FA_ERROR';
export const CHECK_TMP_CODE = 'CHECK_TMP_CODE';
export const CHECK_TMP_CODE_SUCCESS = 'CHECK_TMP_CODE_SUCCESS';
export const CHECK_TMP_CODE_ERROR = 'CHECK_TMP_CODE_ERROR';
export const HANDLE_NON_AUTHORIZED = 'HANDLE_NON_AUTHORIZED';
export const SECOND_FACTOR_CANCELLATION = 'SECOND_FACTOR_CANCELLATION';
export const GET_USER = 'GET_USER';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_ERROR = 'GET_USER_ERROR';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_ERROR = 'REFRESH_TOKEN_ERROR';

/* PROFILE */
export const USER_CHANGE_PASSWORD = 'USER_CHANGE_PASSWORD';
export const USER_CHANGE_PASSWORD_SUCCESS = 'USER_CHANGE_PASSWORD_SUCCESS';
export const USER_CHANGE_PASSWORD_ERROR = 'USER_CHANGE_PASSWORD_ERROR';
export const USER_UPDATE_PASSWORD = 'USER_UPDATE_PASSWORD';
export const USER_UPDATE_PASSWORD_SUCCESS = 'USER_UPDATE_PASSWORD_SUCCESS';
export const USER_UPDATE_PASSWORD_ERROR = 'USER_UPDATE_PASSWORD_ERROR';

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS = 'MENU_CHANGE_HAS_SUB_ITEM_STATUS';
export const MENU_GET_USERS = 'MENU_GET_USERS';
export const MENU_GET_USERS_SUCCESS = 'MENU_GET_USERS_SUCCESS';
export const MENU_GET_USERS_ERROR = 'MENU_GET_USERS_ERROR';
export const MENU_GET_MERCHANTS = 'MENU_GET_MERCHANTS';
export const MENU_GET_MERCHANTS_SUCCESS = 'MENU_GET_MERCHANTS_SUCCESS';
export const MENU_GET_MERCHANTS_ERROR = 'MENU_GET_MERCHANTS_ERROR';
export const MENU_SET_PERMISSION_ID = 'MENU_SET_PERMISSION_ID';

/* SERVICES */
export const GET_SERVICES_BY_NAME = 'GET_SERVICES_BY_NAME';
export const GET_SERVICES_BY_NAME_SUCCESS = 'GET_SERVICES_BY_NAME_SUCCESS';
export const GET_SERVICES_BY_NAME_ERROR = 'GET_SERVICES_BY_NAME_ERROR';
export const GET_SERVICES_BY_MERCHANT = 'GET_SERVICES_BY_MERCHANT';
export const GET_SERVICES_BY_MERCHANT_SUCCESS = 'GET_SERVICES_BY_MERCHANT_SUCCESS';
export const GET_SERVICES_BY_MERCHANT_ERROR = 'GET_SERVICES_BY_MERCHANT_ERROR';
export const GET_PAYOUT_ACCOUNTS_BY_SERVICE = 'GET_PAYOUT_ACCOUNTS_BY_SERVICE_ID';
export const GET_PAYOUT_ACCOUNTS_BY_SERVICE_SUCCESS = 'GET_PAYOUT_ACCOUNTS_BY_SERVICE_ID_SUCCESS';
export const GET_PAYOUT_ACCOUNTS_BY_SERVICE_ERROR = 'GET_PAYOUT_ACCOUNTS_BY_SERVICE_ID_ERROR';
export const SET_PAYOUT_ACCOUNTS_LAST_OPERATION = 'SET_PAYOUT_ACCOUNTS_LAST_OPERATION';
export const GET_ACCOUNTS_BALANCE = 'GET_ACCOUNTS_BALANCE';
export const GET_ACCOUNTS_BALANCE_SUCCESS = 'GET_ACCOUNTS_BALANCE_SUCCESS';
export const GET_ACCOUNTS_BALANCE_ERROR = 'GET_ACCOUNTS_BALANCE_ERROR';

/* GLOSSARY */
export const GET_GLOSSARY_PARTNERS = 'GET_GLOSSARY_PARTNERS';
export const GET_GLOSSARY_PARTNERS_SUCCESS = 'GET_GLOSSARY_PARTNERS_SUCCESS';
export const GET_GLOSSARY_PARTNERS_ERROR = 'GET_GLOSSARY_PARTNERS_ERROR';
export const GET_GLOSSARY_ACCOUNTS_BY_PARTNER = 'GET_GLOSSARY_ACCOUNTS_BY_PARTNER';
export const GET_GLOSSARY_ACCOUNTS_BY_PARTNER_SUCCESS = 'GET_GLOSSARY_ACCOUNTS_BY_PARTNER_SUCCESS';
export const GET_GLOSSARY_ACCOUNTS_BY_PARTNER_ERROR = 'GET_GLOSSARY_ACCOUNTS_BY_PARTNER_ERROR';

export const GET_GLOSSARY_CONTRACTS = 'GET_GLOSSARY_CONTRACTS';
export const GET_GLOSSARY_CONTRACTS_SUCCESS = 'GET_GLOSSARY_CONTRACTS_SUCCESS';
export const GET_GLOSSARY_CONTRACTS_ERROR = 'GET_GLOSSARY_CONTRACTS_ERROR';

export const GET_GLOSSARY_USERS = 'GET_GLOSSARY_USERS';
export const GET_GLOSSARY_USERS_SUCCESS = 'GET_GLOSSARY_USERS_SUCCESS';
export const GET_GLOSSARY_USERS_ERROR = 'GET_GLOSSARY_USERS_ERROR';
export const GET_GLOSSARY_USER_PARTNERS = 'GET_GLOSSARY_USER_PARTNERS';
export const GET_GLOSSARY_USER_PARTNERS_SUCCESS = 'GET_GLOSSARY_USER_PARTNERS_SUCCESS';
export const GET_GLOSSARY_USER_PARTNERS_ERROR = 'GET_GLOSSARY_USER_PARTNERS_ERROR';
export const GET_USER_PARTNER_ACCOUNTS = 'GET_USER_PARTNER_ACCOUNTS';
export const GET_USER_PARTNER_ACCOUNTS_SUCCESS = 'GET_USER_PARTNER_ACCOUNTS_SUCCESS';
export const GET_USER_PARTNER_ACCOUNTS_ERROR = 'GET_USER_PARTNER_ACCOUNTS_ERROR';

export const GET_SERVICE_TYPE_DOCS = 'GET_SERVICE_TYPE_DOCS';
export const GET_SERVICE_TYPE_DOCS_SUCCESS = 'GET_SERVICE_TYPE_DOCS_SUCCESS';
export const GET_SERVICE_TYPE_DOCS_ERROR = 'GET_SERVICE_TYPE_DOCS_ERROR';
export const POST_SERVICE_TYPE_DOCS = 'POST_SERVICE_TYPE_DOCS';
export const POST_SERVICE_TYPE_DOCS_SUCCESS = 'POST_SERVICE_TYPE_DOCS_SUCCESS';
export const POST_SERVICE_TYPE_DOCS_ERROR = 'POST_SERVICE_TYPE_DOCS_ERROR';
export const DELETE_SERVICE_TYPE_DOCS = 'DELETE_SERVICE_TYPE_DOCS';
export const DELETE_SERVICE_TYPE_DOCS_SUCCESS = 'DELETE_SERVICE_TYPE_DOCS_SUCCESS';
export const DELETE_SERVICE_TYPE_DOCS_ERROR = 'DELETE_SERVICE_TYPE_DOCS_ERROR';
export const RESET_GLOSSARY_USERS = 'RESET_GLOSSARY_USERS';
export const RESET_GLOSSARY_PARTNERS = 'RESET_GLOSSARY_PARTNERS';
export const RESET_GLOSSARY_CONTRACTS = 'RESET_GLOSSARY_CONTRACTS';

/* DOCUMENTS */
export const GET_DOCUMENTS = 'GET_DOCUMENTS';
export const GET_DOCUMENTS_SUCCESS = 'GET_DOCUMENTS_SUCCESS';
export const GET_DOCUMENTS_ERROR = 'GET_DOCUMENTS_ERROR';
export const RESET_DOCUMENTS = 'RESET_DOCUMENTS';

/* OPERATIONS */
export const GET_OPERATIONS = 'GET_OPERATIONS';
export const GET_OPERATIONS_SUCCESS = 'GET_OPERATIONS_SUCCESS';
export const GET_OPERATIONS_ERROR = 'GET_OPERATIONS_ERROR';
export const RESET_OPERATIONS = 'RESET_OPERATIONS';
export const GET_OPERATIONS_FILE = 'GET_OPERATIONS_FILE';
export const GET_OPERATIONS_FILE_SUCCESS = 'GET_OPERATIONS_FILE_SUCCESS';
export const GET_OPERATIONS_FILE_ERROR = 'GET_OPERATIONS_FILE_ERROR';
export const GET_ACQUIRING_HISTORY = 'GET_ACQUIRING_HISTORY';
export const GET_ACQUIRING_HISTORY_SUCCESS = 'GET_ACQUIRING_HISTORY_SUCCESS';
export const GET_ACQUIRING_HISTORY_ERROR = 'GET_ACQUIRING_HISTORY_ERROR';
export const GET_PAYOUT_ACCOUNTS = 'GET_PAYOUT_ACCOUNTS';
export const GET_PAYOUT_ACCOUNTS_SUCCESS = 'GET_PAYOUT_ACCOUNTS_SUCCESS';
export const GET_PAYOUT_ACCOUNTS_ERROR = 'GET_PAYOUT_ACCOUNTS_ERROR';
export const CREATE_MASS_PAYOUT = 'CREATE_MASS_PAYOUT';
export const CREATE_MASS_PAYOUT_SUCCESS = 'CREATE_MASS_PAYOUT_SUCCESS';
export const CREATE_MASS_PAYOUT_ERROR = 'CREATE_MASS_PAYOUT_ERROR';
export const GET_PAYOUT_DIRECTIONS = 'GET_PAYOUT_DIRECTIONS';
export const GET_PAYOUT_DIRECTIONS_SUCCESS = 'GET_PAYOUT_DIRECTIONS_SUCCESS';
export const GET_PAYOUT_DIRECTIONS_ERROR = 'GET_PAYOUT_DIRECTIONS_ERROR';
export const GET_PAYOUT_FIELDS = 'GET_PAYOUT_FIELDS';
export const GET_PAYOUT_FIELDS_SUCCESS = 'GET_PAYOUT_FIELDS_SUCCESS';
export const GET_PAYOUT_FIELDS_ERROR = 'GET_PAYOUT_FIELDS_ERROR';
export const CREATE_PAYOUT = 'CREATE_PAYOUT';
export const CONFIRM_PAYOUT = 'CONFIRM_PAYOUT';
export const CONFIRM_PAYOUT_SUCCESS = 'CONFIRM_PAYOUT_SUCCESS';
export const CONFIRM_PAYOUT_ERROR = 'CONFIRM_PAYOUT_ERROR';
export const CREATE_PAYOUT_SUCCESS = 'CREATE_PAYOUT_SUCCESS';
export const CREATE_PAYOUT_ERROR = 'CREATE_PAYOUT_ERROR';
export const FLASH_PAYOUT_FIELDS = 'FLASH_PAYOUT_FIELDS';
export const GET_DESTINATIONS = 'GET_DESTINATIONS';
export const GET_DESTINATIONS_SUCCESS = 'GET_DESTINATIONS_SUCCESS';
export const GET_DESTINATIONS_ERROR = 'GET_DESTINATIONS_ERROR';
export const GET_REGISTRIES_HISTORY = 'GET_REGISTRIES_HISTORY';
export const GET_REGISTRIES_HISTORY_SUCCESS = 'GET_REGISTRIES_HISTORY_SUCCESS';
export const GET_REGISTRIES_HISTORY_ERROR = 'GET_REGISTRIES_HISTORY_ERROR';
export const RESET_REGISTRIES_HISTORY = 'RESET_REGISTRIES_HISTORY';
export const REFUND_OPERATION = 'REFUND_OPERATION';
export const REFUND_OPERATION_SUCCESS = 'REFUND_OPERATION_SUCCESS';
export const REFUND_OPERATION_ERROR = 'REFUND_OPERATION_ERROR';
export const SEND_CALLBACK = 'SEND_CALLBACK';
export const SEND_CALLBACK_SUCCESS = 'SEND_CALLBACK_SUCCESS';
export const SEND_CALLBACK_ERROR = 'SEND_CALLBACK_ERROR';

/* STATISTICS */
export const GET_SUMMARY_STATISTICS = 'GET_SUMMARY_STATISTICS';
export const GET_SUMMARY_STATISTICS_SUCCESS = 'GET_SUMMARY_STATISTICS_SUCCESS';
export const GET_SUMMARY_STATISTICS_ERROR = 'GET_SUMMARY_STATISTICS_ERROR';
export const RESET_SUMMARY_STATISTICS = 'RESET_SUMMARY_STATISTICS';
export const GET_CUSTOMER_STATISTICS = 'GET_CUSTOMER_STATISTICS';
export const GET_CUSTOMER_STATISTICS_SUCCESS = 'GET_CUSTOMER_STATISTICS_SUCCESS';
export const GET_CUSTOMER_STATISTICS_ERROR = 'GET_CUSTOMER_STATISTICS_ERROR';
export const RESET_CUSTOMER_STATISTICS = 'RESET_CUSTOMER_STATISTICS';
export const GET_CHARTS = 'GET_CHARTS';
export const GET_CHARTS_SUCCESS = 'GET_CHARTS_SUCCESS';
export const GET_CHARTS_ERROR = 'GET_CHARTS_ERROR';
export const RESET_CHARTS = 'RESET_CHARTS';
export const GET_CONVERSION_CHART = 'GET_CONVERSION_CHART';
export const GET_CONVERSION_CHART_SUCCESS = 'GET_CONVERSION_CHART_SUCCESS';
export const GET_CONVERSION_CHART_ERROR = 'GET_CONVERSION_CHART_ERROR';
export const RESET_CONVERSION_CHART = 'RESET_CONVERSION_CHART';
export const GET_PERIOD_SUMMARY_STATISTICS = 'GET_PERIOD_SUMMARY_STATISTICS';
export const GET_PERIOD_SUMMARY_STATISTICS_SUCCESS = 'GET_PERIOD_SUMMARY_STATISTICS_SUCCESS';
export const GET_PERIOD_SUMMARY_STATISTICS_ERROR = 'GET_PERIOD_SUMMARY_STATISTICS_ERROR';
export const RESET_PERIOD_SUMMARY_STATISTICS = 'RESET_PERIOD_SUMMARY_STATISTICS';
export const GET_PERIOD_SUMMARY_STATISTICS_FILE = 'GET_PERIOD_SUMMARY_STATISTICS_FILE';
export const GET_PERIOD_SUMMARY_STATISTICS_FILE_ERROR = 'GET_PERIOD_SUMMARY_STATISTICS_FILE_ERROR';
export const GET_PERIOD_SUMMARY_STATISTICS_FILE_SUCCESS = 'GET_PERIOD_SUMMARY_STATISTICS_FILE_SUCCESS';

/* DOWNLOADS */
export const GET_CSV_LIST = 'GET_CSV_LIST';
export const GET_CSV_LIST_SUCCESS = 'GET_CSV_LIST_SUCCESS';
export const GET_CSV_LIST_ERROR = 'GET_CSV_LIST_ERROR';
export const RESET_DOWNLOADS = 'RESET_DOWNLOADS';

/* DISPOSALS */
export const GET_DISPOSALS = 'GET_DISPOSALS';
export const GET_DISPOSALS_SUCCESS = 'GET_DISPOSALS_SUCCESS';
export const GET_DISPOSALS_ERROR = 'GET_DISPOSALS_ERROR';
export const GET_STATEMENTS = 'GET_STATEMENTS';
export const GET_STATEMENTS_SUCCESS = 'GET_STATEMENTS_SUCCESS';
export const GET_STATEMENTS_ERROR = 'GET_STATEMENTS_ERROR';
export const RESET_STATEMENTS = 'RESET_STATEMENTS';
export const DELETE_DISPOSAL = 'DELETE_DISPOSAL';
export const DELETE_DISPOSAL_SUCCESS = 'DELETE_DISPOSAL_SUCCESS';
export const DELETE_DISPOSAL_ERROR = 'DELETE_DISPOSAL_ERROR';
export const GET_DISPOSALS_PARTNERS = 'GET_DISPOSALS_PARTNERS';
export const GET_DISPOSALS_PARTNERS_SUCCESS = 'GET_DISPOSALS_PARTNERS_SUCCESS';
export const GET_DISPOSALS_PARTNERS_ERROR = 'GET_DISPOSALS_PARTNERS_ERROR';
export const CHANGE_DISPOSAL_STATUS = 'CHANGE_DISPOSAL_STATUS';
export const CHANGE_DISPOSAL_STATUS_SUCCESS = 'CHANGE_DISPOSAL_STATUS_SUCCESS';
export const CHANGE_DISPOSAL_STATUS_ERROR = 'CHANGE_DISPOSAL_STATUS_ERROR';
export const CREATE_DISPOSAL = 'CREATE_DISPOSAL';
export const CREATE_DISPOSAL_SUCCESS = 'CREATE_DISPOSAL_SUCCESS';
export const CREATE_DISPOSAL_ERROR = 'CREATE_DISPOSAL_ERROR';

/* TECHNICAL SUPPORT */
export const SEND_SUPPORT_MESSAGE = 'SEND_SUPPORT_MESSAGE';
export const SEND_SUPPORT_MESSAGE_SUCCESS = 'SEND_SUPPORT_MESSAGE_SUCCESS';
export const SEND_SUPPORT_MESSAGE_ERROR = 'SEND_SUPPORT_MESSAGE_ERROR';

export const GET_ALL_REQUESTS = 'GET_ALL_REQUESTS';
export const GET_ALL_REQUESTS_SUCCESS = 'GET_ALL_REQUESTS_SUCCESS';
export const GET_ALL_REQUESTS_ERROR = 'GET_ALL_REQUESTS_ERROR';

export const GET_REQUEST_TYPES = 'GET_REQUEST_TYPES';
export const GET_REQUEST_TYPES_SUCCESS = 'GET_REQUEST_TYPES_SUCCESS';
export const GET_REQUEST_TYPES_ERROR = 'GET_REQUEST_TYPES_ERROR';

export const GET_REQUEST = 'GET_REQUEST';
export const GET_REQUEST_SUCCESS = 'GET_REQUEST_SUCCESS';
export const GET_REQUEST_ERROR = 'GET_REQUEST_ERROR';

export const OPEN_REQUEST = 'OPEN_REQUEST';
export const OPEN_REQUEST_SUCCESS = 'OPEN_REQUEST_SUCCESS';
export const OPEN_REQUEST_ERROR = 'OPEN_REQUEST_ERROR';

export const RESET_REQUESTS = 'RESET_REQUESTS';

/* GET USER LOGS */
export const GET_USER_LOGS = 'GET_USER_LOGS';
export const GET_USER_LOGS_SUCCESS = 'GET_USER_LOGS_SUCCESS';
export const GET_USER_LOGS_ERROR = 'GET_USER_LOGS_ERROR';

/* APP */
export const GET_DEFAULT_PAGE = 'GET_DEFAULT_PAGE';
export const GET_DEFAULT_PAGE_SUCCESS = 'GET_DEFAULT_PAGE_SUCCESS';
export const GET_DEFAULT_PAGE_ERROR = 'GET_DEFAULT_PAGE_ERROR';
export const GET_USER_BY_ID = 'GET_USER_BY_ID';
export const GET_USER_BY_ID_SUCCESS = 'GET_USER_BY_ID_SUCCESS';
export const GET_USER_BY_ID_ERROR = 'GET_USER_BY_ID_ERROR';

export const GET_AVAILABLE_SITES = 'GET_AVAILABLE_SITES';
export const GET_AVAILABLE_SITES_SUCCESS = 'GET_AVAILABLE_SITES_SUCCESS';
export const GET_AVAILABLE_SITES_ERROR = 'GET_AVAILABLE_SITES_ERROR';
export const PUT_AVAILABLE_SITES = 'PUT_AVAILABLE_SITES';
export const PUT_AVAILABLE_SITES_SUCCESS = 'PUT_AVAILABLE_SITES_SUCCESS';
export const PUT_AVAILABLE_SITES_ERROR = 'PUT_AVAILABLE_SITES_ERROR';
/* WEBSOCKET NOTIFICATION */
export const WS_NOTIFICATION_CONNECTED = 'WS_NOTIFICATION_CONNECTED';
export const WS_NOTIFICATION_DISCONNECTED = 'WS_NOTIFICATION_DISCONNECTED';
export const WS_NOTIFICATION_GET_MESSAGE = 'WS_NOTIFICATION_GET_MESSAGE';
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_ERROR = 'GET_NOTIFICATIONS_ERROR';
export const READ_NOTIFICATION = 'READ_NOTIFICATION';
export const READ_NOTIFICATION_SUCCESS = 'READ_NOTIFICATION_SUCCESS';
export const READ_NOTIFICATION_ERROR = 'READ_NOTIFICATION_ERROR';
export const GET_PAYOUT_NOTIFICATION = 'GET_PAYOUT_NOTIFICATION';
export const GET_PAYOUT_NOTIFICATION_SUCCESS = 'GET_PAYOUT_NOTIFICATION_SUCCESS';
export const GET_PAYOUT_NOTIFICATION_ERROR = 'GET_PAYOUT_NOTIFICATION_ERROR';

export const GET_SITES_SETTINGS = 'GET_SITES_SETTINGS';
export const GET_SITES_SETTINGS_SUCCESS = 'GET_SITES_SETTINGS_SUCCESS';
export const GET_SITES_SETTINGS_ERROR = 'GET_SITES_SETTINGS_ERROR';
export const PUT_SITES_SETTINGS = 'PUT_SITES_SETTINGS';
export const PUT_SITES_SETTINGS_SUCCESS = 'PUT_SITES_SETTINGS_SUCCESS';
export const PUT_SITES_SETTINGS_ERROR = 'PUT_SITES_SETTINGS_ERROR';

/* CUSTOM DESIGN SETTINGS */
export const PUT_CUSTOM_DESIGN = 'PUT_CUSTOM_DESIGN';
export const PUT_CUSTOM_DESIGN_ERROR = 'PUT_CUSTOM_DESIGN_ERROR';
export const GET_CUSTOM_DESIGN = 'GET_CUSTOM_DESIGN';
export const GET_CUSTOM_DESIGN_ERROR = 'GET_CUSTOM_DESIGN_ERROR';
export const APPLY_CUSTOM_DESIGN = 'APPLY_CUSTOM_DESIGN';
export const DELETE_CUSTOM_DESIGN = 'DELETE_CUSTOM_DESIGN';
export const DELETE_CUSTOM_DESIGN_SUCCESS = 'DELETE_CUSTOM_DESIGN_SUCCESS';
export const DELETE_CUSTOM_DESIGN_ERROR = 'DELETE_CUSTOM_DESIGN_ERROR';

/* BALANCE */
export const GET_BALANCE_OPERATION = 'GET_BALANCE_OPERATION';
export const GET_BALANCE_OPERATION_SUCCESS = 'GET_BALANCE_OPERATION_SUCCESS';
export const GET_BALANCE_OPERATION_ERROR = 'GET_BALANCE_OPERATION_ERROR';

export const GET_BALANCE_STAT = 'GET_BALANCE_STAT';
export const GET_BALANCE_STAT_SUCCESS = 'GET_BALANCE_STAT_SUCCESS';
export const GET_BALANCE_STAT_ERROR = 'GET_BALANCE_STAT_ERROR';

export const PUT_SYSTEM_CACHE = 'PUT_SYSTEM_CACHE';
export const PUT_SYSTEM_CACHE_SUCCESS = 'PUT_SYSTEM_CACHE_SUCCESS';
export const PUT_SYSTEM_CACHE_ERROR = 'PUT_SYSTEM_CACHE_ERROR';
export const GET_SYSTEM_CACHE_ERROR = 'GET_SYSTEM_CACHE_ERROR';
export const GET_SYSTEM_CACHE_SUCCESS = 'GET_SYSTEM_CACHE_SUCCESS';
export const GET_SYSTEM_CACHE = 'GET_SYSTEM_CACHE';

export * from './menu/actions';
export * from './settings/actions';
export * from './authUser/actions';
export * from './profile/actions';
export * from './operations/actions';
export * from './services/actions';
export * from './glossary/actions';
export * from './documents/actions';
export * from './statistics/actions';
export * from './downloads/actions';
export * from './notifications/actions';
export * from './support/actions';
export * from './journal/actions';
export * from './app/actions';
export * from './sites/actions';
export * from './balance/actions';
