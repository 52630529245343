import React, { Component, ReactNode, Fragment } from 'react';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { Loader } from '../../../components/common/Loader';
import { StartPageProps, StartPageState } from './interface';
import { CONFIG } from '../../../config/appConfig';
import { Redirect } from 'react-router-dom';
import { CustomDesignConfig } from '../../../redux/settings/interface';

class StartPage extends Component<StartPageProps, StartPageState> {
  constructor(props: StartPageProps) {
    super(props);
    const { customDesign } = this.props;
    this.state = { startPage: customDesign.loading ? undefined : this.getStartPageStatus(customDesign.data) };
  }

  componentDidUpdate(prevProps: Readonly<StartPageProps>, prevState: any, snapshot?: any): void {
    const { customDesign } = this.props;
    if (!customDesign.loading && !isEqual(customDesign, prevProps.customDesign)) {
      this.setState({ startPage: this.getStartPageStatus(customDesign.data) });
    }
  }

  getStartPageStatus = (config: CustomDesignConfig | null): boolean =>
    !!(config && config.settings && 'startPage' in config.settings && config.settings.startPage);

  render(): ReactNode {
    const { customDesign } = this.props;
    const { startPage } = this.state;
    return (
      <Fragment>
        {startPage === undefined && <Loader />}
        {startPage === false && <Redirect to="/services" />}
        <div>
          {startPage && customDesign.data && (
            <div
              dangerouslySetInnerHTML={{
                __html: `<iframe src="${`${CONFIG.apiURL()}/custom-design/start-page/?domain=${
                  customDesign.data.domain
                }`}" style="border: none; min-width: 100%; min-height:100%" />`
              }}
            />
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ settings }) => ({ customDesign: settings.customDesign });
export default connect(
  mapStateToProps,
  {}
)(StartPage);
