import React, { PureComponent, ReactNode, Fragment } from 'react';
import { withTranslation } from 'react-i18next';

import { HIGH_STRENGTH, LOW_STRENGTH, MIDDLE_STRENGTH, PasswordStrengthMeterProps, StrengthLevel } from './interface';

class PasswordStrengthMeter extends PureComponent<PasswordStrengthMeterProps> {
  render(): ReactNode {
    const { value, t } = this.props;
    let level: StrengthLevel = 0;

    const uppercaseMatch = value.match(/[A-Z]/g);
    const symbolsMatch = value.match(/[!@#$%^&*(),.?":;{}+\-|<>[\]/_]/g);

    if (value) {
      level = LOW_STRENGTH;
    }

    if (uppercaseMatch || symbolsMatch) {
      level = MIDDLE_STRENGTH;
    }

    if (uppercaseMatch && symbolsMatch) {
      level = HIGH_STRENGTH;
    }

    return (
      <div className="pass-level">
        <div className="pass-level-val">
          <div className={`pass-level-item pass-level-low ${level >= StrengthLevel.Low && 'pass-level-selected'}`} />
          <div
            className={`pass-level-item pass-level-middle ${level >= StrengthLevel.Middle && 'pass-level-selected'}`}
          />
          <div className={`pass-level-item pass-level-high ${level >= StrengthLevel.High && 'pass-level-selected'}`} />
        </div>
        {value.length > 0 && (
          <Fragment>
            {t('user.password-strength-level')}
            :&nbsp;
            {level === StrengthLevel.Low && t('user.password-strength-low')}
            {level === StrengthLevel.Middle && t('user.password-strength-medium')}
            {level === StrengthLevel.High && t('user.password-strength-high')}
          </Fragment>
        )}
      </div>
    );
  }
}

export default withTranslation()(PasswordStrengthMeter);
