import appLocaleData from '@formatjs/intl-relativetimeformat/dist/locale-data/en';
import messages from '../locales/en_US';

const EnLang = {
  messages,
  locale: 'en-US',
  data: appLocaleData
};

export default EnLang;
