import {
  GET_SUMMARY_STATISTICS,
  GET_SUMMARY_STATISTICS_SUCCESS,
  GET_SUMMARY_STATISTICS_ERROR,
  RESET_SUMMARY_STATISTICS,
  GET_CUSTOMER_STATISTICS,
  GET_CUSTOMER_STATISTICS_SUCCESS,
  GET_CUSTOMER_STATISTICS_ERROR,
  RESET_CUSTOMER_STATISTICS,
  GET_CHARTS,
  GET_CHARTS_SUCCESS,
  GET_CHARTS_ERROR,
  RESET_CHARTS,
  GET_CONVERSION_CHART,
  GET_CONVERSION_CHART_SUCCESS,
  GET_CONVERSION_CHART_ERROR,
  RESET_CONVERSION_CHART,
  RESET_PERIOD_SUMMARY_STATISTICS,
  GET_PERIOD_SUMMARY_STATISTICS_ERROR,
  GET_PERIOD_SUMMARY_STATISTICS_SUCCESS,
  GET_PERIOD_SUMMARY_STATISTICS,
  GET_PERIOD_SUMMARY_STATISTICS_FILE,
  GET_PERIOD_SUMMARY_STATISTICS_FILE_ERROR,
  GET_PERIOD_SUMMARY_STATISTICS_FILE_SUCCESS
} from '../actions';

import {
  GetSummaryStatisticsAction,
  GetSummaryStatisticsSuccessAction,
  GetSummaryStatisticsErrorAction,
  SummaryStatistics,
  GetCustomerStatisticsAction,
  GetCustomerStatisticsSuccessAction,
  GetCustomerStatisticsErrorAction,
  CustomerStatisticsRequestValues,
  CustomerStatistics,
  GetChartsAction,
  GetChartsError,
  GetChartsSuccess,
  Charts,
  ConversionChart,
  ConversionChartRequestValues,
  GetConversionChartAction,
  GetConversionChartSuccessAction,
  GetConversionChartErrorAction,
  SummaryStatisticsRequestValues,
  ChartsRequestValues,
  ResetSummaryStatistics,
  ResetCharts,
  ResetConversionChart,
  ResetCustomerStatistics,
  ResetPeriodSummaryStatistic,
  GetPeriodSummaryStatisticError,
  GetPeriodSummaryStatisticSuccess,
  GetPeriodSummaryStatistic,
  PeriodSummaryData,
  ChartsRequestValuesFile,
  GetPeriodSummaryStatisticFile,
  GetPeriodSummaryStatisticFileError,
  GetPeriodSummaryStatisticFileSuccess
} from './interface';

// SUMMARY STATISTICS
export const getSummaryStatistics = (requestValues: SummaryStatisticsRequestValues): GetSummaryStatisticsAction => ({
  type: GET_SUMMARY_STATISTICS,
  payload: requestValues
});

export const getSummaryStatisticsSuccess = (
  data: SummaryStatistics,
  showRefund?: boolean
): GetSummaryStatisticsSuccessAction => ({
  type: GET_SUMMARY_STATISTICS_SUCCESS,
  payload: {
    summaryStatistics: { data, showRefund }
  }
});

export const getSummaryStatisticsError = (error: string): GetSummaryStatisticsErrorAction => ({
  type: GET_SUMMARY_STATISTICS_ERROR,
  payload: { error }
});

export const resetSummaryStatistics = (): ResetSummaryStatistics => ({
  type: RESET_SUMMARY_STATISTICS
});

// CUSTOMER STATISTICS
export const getCustomerStatistics = (requestValues: CustomerStatisticsRequestValues): GetCustomerStatisticsAction => ({
  type: GET_CUSTOMER_STATISTICS,
  payload: requestValues
});

export const getCustomerStatisticsSuccess = (data: CustomerStatistics[]): GetCustomerStatisticsSuccessAction => ({
  type: GET_CUSTOMER_STATISTICS_SUCCESS,
  payload: { data }
});

export const getCustomerStatisticsError = (error: string): GetCustomerStatisticsErrorAction => ({
  type: GET_CUSTOMER_STATISTICS_ERROR,
  payload: { error }
});

export const resetCustomerStatistics = (): ResetCustomerStatistics => ({
  type: RESET_CUSTOMER_STATISTICS
});

// CHARTS
export const getChartsData = (requestValues: ChartsRequestValues): GetChartsAction => ({
  type: GET_CHARTS,
  payload: requestValues
});

export const getChartsDataSuccess = (data: Charts[], period: string, showRefund?: boolean): GetChartsSuccess => ({
  type: GET_CHARTS_SUCCESS,
  payload: {
    charts: {
      data,
      period,
      showRefund
    }
  }
});

export const getChartsDataError = (error: string): GetChartsError => ({
  type: GET_CHARTS_ERROR,
  payload: { error }
});

export const resetCharts = (): ResetCharts => ({
  type: RESET_CHARTS
});

// GET CONVERSION CHART
export const getConversionChartData = (formValues: ConversionChartRequestValues): GetConversionChartAction => ({
  type: GET_CONVERSION_CHART,
  payload: formValues
});

export const getConversionChartDataSuccess = (
  data: ConversionChart[],
  period: string,
  status: string[]
): GetConversionChartSuccessAction => ({
  type: GET_CONVERSION_CHART_SUCCESS,
  payload: {
    conversionChart: {
      data,
      period,
      status
    }
  }
});

export const getConversionChartDataError = (error: string): GetConversionChartErrorAction => ({
  type: GET_CONVERSION_CHART_ERROR,
  payload: { error }
});

export const resetConversionChart = (): ResetConversionChart => ({
  type: RESET_CONVERSION_CHART
});

// PERIOD SUMMARY STATISTICS
export const getPeriodSummaryStatistic = (requestValues: ChartsRequestValues): GetPeriodSummaryStatistic => ({
  type: GET_PERIOD_SUMMARY_STATISTICS,
  payload: requestValues
});

export const getPeriodSummaryStatisticSuccess = (
  data: PeriodSummaryData,
  currency: string | number,
  period: string,
  byAccount: boolean
): GetPeriodSummaryStatisticSuccess => ({
  type: GET_PERIOD_SUMMARY_STATISTICS_SUCCESS,
  payload: { data, currency, period, byAccount }
});

export const getPeriodSummaryStatisticError = (error: string): GetPeriodSummaryStatisticError => ({
  type: GET_PERIOD_SUMMARY_STATISTICS_ERROR,
  payload: { error }
});

export const resetPeriodSummaryStatistic = (): ResetPeriodSummaryStatistic => ({
  type: RESET_PERIOD_SUMMARY_STATISTICS
});

// PERIOD SUMMARY STATISTICS CSV
export const getPeriodSummaryStatisticFile = (
  requestValues: ChartsRequestValuesFile
): GetPeriodSummaryStatisticFile => ({
  type: GET_PERIOD_SUMMARY_STATISTICS_FILE,
  payload: requestValues
});

export const getPeriodSummaryStatisticFileError = (error: string): GetPeriodSummaryStatisticFileError => ({
  type: GET_PERIOD_SUMMARY_STATISTICS_FILE_ERROR,
  payload: { error }
});

export const getPeriodSummaryStatisticFileSuccess = (id: number): GetPeriodSummaryStatisticFileSuccess => ({
  type: GET_PERIOD_SUMMARY_STATISTICS_FILE_SUCCESS,
  payload: { id }
});
