import {
  CHANGE_DISPOSAL_STATUS,
  CHANGE_DISPOSAL_STATUS_SUCCESS,
  CHANGE_DISPOSAL_STATUS_ERROR,
  GET_DISPOSALS_PARTNERS_SUCCESS,
  GET_DISPOSALS_PARTNERS,
  GET_DISPOSALS_PARTNERS_ERROR,
  GET_DISPOSALS,
  GET_DISPOSALS_SUCCESS,
  GET_DISPOSALS_ERROR,
  CREATE_DISPOSAL,
  CREATE_DISPOSAL_SUCCESS,
  CREATE_DISPOSAL_ERROR,
  DELETE_DISPOSAL,
  DELETE_DISPOSAL_SUCCESS,
  DELETE_DISPOSAL_ERROR,
  GET_STATEMENTS,
  GET_STATEMENTS_SUCCESS,
  GET_STATEMENTS_ERROR,
  RESET_STATEMENTS
} from '../actions';
import { DisposalsState } from './interface';
import { getAccountsFromStatements } from '../../views/disposals/helpers';

const INIT_STATE: DisposalsState = {
  data: null,
  loading: false,
  error: '',
  partners: {
    data: null,
    loading: false,
    error: ''
  },
  createDisposal: {
    loading: false,
    id: null,
    error: ''
  },
  deleteDisposal: {
    loading: false,
    error: ''
  },
  statements: {
    statementsData: null,
    accountsData: null,
    loading: false,
    error: ''
  }
};

export default (state: DisposalsState = INIT_STATE, { type, payload }): DisposalsState => {
  switch (type) {
    // CHANGE DISPOSAL STATUS
    case CHANGE_DISPOSAL_STATUS:
      return {
        ...state,
        loading: true,
        error: ''
      };
    case CHANGE_DISPOSAL_STATUS_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case CHANGE_DISPOSAL_STATUS_ERROR:
      return {
        ...state,
        error: payload.error,
        loading: false
      };

    // GET DISPOSALS
    case GET_DISPOSALS:
      return {
        ...state,
        loading: true,
        error: ''
      };
    case GET_DISPOSALS_SUCCESS:
      return {
        ...state,
        data: payload.data,
        loading: false
      };
    case GET_DISPOSALS_ERROR:
      return {
        ...state,
        data: null,
        error: payload.error,
        loading: false
      };

    // CREATE DISPOSAL
    case CREATE_DISPOSAL:
      return {
        ...state,
        createDisposal: {
          ...state.createDisposal,
          error: '',
          loading: true
        }
      };
    case CREATE_DISPOSAL_SUCCESS:
      return {
        ...state,
        createDisposal: {
          ...state.createDisposal,
          loading: false,
          id: payload.id
        }
      };
    case CREATE_DISPOSAL_ERROR:
      return {
        ...state,
        createDisposal: {
          error: payload.error,
          loading: false,
          id: null
        }
      };

    // DELETE DISPOSAL
    case DELETE_DISPOSAL:
      return {
        ...state,
        deleteDisposal: {
          ...state.deleteDisposal,
          loading: true
        }
      };
    case DELETE_DISPOSAL_SUCCESS:
      return {
        ...state,
        deleteDisposal: {
          ...state.deleteDisposal,
          loading: false
        }
      };
    case DELETE_DISPOSAL_ERROR:
      return {
        ...state,
        deleteDisposal: {
          error: payload.error,
          loading: false
        }
      };

    // GET DISPOSALS PARTNERS
    case GET_DISPOSALS_PARTNERS:
      return {
        ...state,
        partners: {
          ...state.partners,
          loading: true,
          error: ''
        }
      };
    case GET_DISPOSALS_PARTNERS_SUCCESS:
      return {
        ...state,
        partners: {
          ...state.partners,
          data: payload.data,
          loading: false
        }
      };
    case GET_DISPOSALS_PARTNERS_ERROR:
      return {
        ...state,
        partners: {
          data: null,
          error: payload.error,
          loading: false
        }
      };

    // GET STATEMENTS
    case GET_STATEMENTS:
      return {
        ...state,
        statements: {
          ...state.statements,
          loading: true,
          error: ''
        }
      };
    case GET_STATEMENTS_SUCCESS:
      return {
        ...state,
        statements: {
          statementsData: payload.data,
          accountsData: getAccountsFromStatements(payload.data),
          loading: false,
          error: ''
        }
      };
    case GET_STATEMENTS_ERROR:
      return {
        ...state,
        statements: {
          statementsData: null,
          accountsData: null,
          error: payload.error,
          loading: false
        }
      };
    case RESET_STATEMENTS:
      return {
        ...state,
        statements: {
          statementsData: null,
          accountsData: null,
          loading: false,
          error: ''
        }
      };
    default:
      return { ...state };
  }
};
