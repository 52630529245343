import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { ModalHeader, ModalBody, Modal } from 'reactstrap';
import ReactTable from 'react-table';

import DataTablePagination from '../../../components/DatatablePagination';
import { readNotification } from '../../../redux/notifications/actions';
import { getNotifications } from '../../../redux/actions';
import isEqual from 'lodash/isEqual';
import { withTranslation } from 'react-i18next';

import { NotificationCenterModalProps, NotificationCenterModalState } from './interface';
import { Notification } from '../../../redux/notifications/interface';
import { NOTIFICATIONS_COLUMNS } from './constant';
import { prepareNotificationRequestValues } from './helpers';

class NotificationCenterModal extends Component<NotificationCenterModalProps, NotificationCenterModalState> {
  state: NotificationCenterModalState = {
    page: 0,
    sorting: ''
  };

  componentDidMount(): void {
    this.getNotifications();
    this.readNotifications();
  }

  componentDidUpdate(
    prevProps: Readonly<NotificationCenterModalProps>,
    _: Readonly<NotificationCenterModalState>
  ): void {
    const { notifications } = this.props;

    if (!isEqual(prevProps.notifications, notifications)) {
      this.readNotifications();
    }
  }

  readNotifications = () => {
    const { notifications, readNotificationAction } = this.props;

    if (!notifications) return;

    const notificationId = notifications.flatMap((obj: Notification) => {
      if (obj.is_viewed) {
        return [];
      } else {
        return obj.system_id;
      }
    });
    if (notificationId.length) {
      setTimeout(() => readNotificationAction(notificationId), 2000);
    }
  };

  getNotifications = (page = 0, sorting = '') => {
    const { getNotificationsAction, maxPageSize } = this.props;

    this.setState({ page, sorting });
    getNotificationsAction(prepareNotificationRequestValues(page, sorting, maxPageSize));
  };

  render(): ReactNode {
    const { cancellation, notifications, count, maxPageSize, t } = this.props;
    const { page } = this.state;

    const pageSize =
      notifications && notifications.length
        ? notifications.length < maxPageSize
          ? notifications.length
          : maxPageSize
        : 0;

    return (
      notifications && (
        <Modal size="lg" isOpen={true} toggle={cancellation}>
          <ModalHeader> {t('Notifications')}</ModalHeader>
          <ModalBody>
            <ReactTable
              data={notifications}
              columns={NOTIFICATIONS_COLUMNS(t)}
              pages={Math.ceil(count / maxPageSize)}
              defaultPageSize={pageSize}
              pageSize={pageSize}
              page={page}
              sortable={false}
              filterable={false}
              showPageJump={true}
              showPagination={true}
              showPageSizeOptions={false}
              PaginationComponent={DataTablePagination}
              onPageChange={this.getNotifications}
              manual
            />
          </ModalBody>
        </Modal>
      )
    );
  }
}

const mapStateToProps = ({ notifications, settings }) => ({
  reading: notifications.readMessage,
  count: notifications.count,
  maxPageSize: settings.gcmSettings.data ? settings.gcmSettings.data.max_page_size : 50,
  loading: notifications.loading,
  notifications: notifications.data
});

const mapDispatchToProps = {
  readNotificationAction: readNotification,
  getNotificationsAction: getNotifications
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(NotificationCenterModal)
);
