import React from 'react';
import { Button, FormGroup, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { Field, Form, Formik } from 'formik';
import { getFieldErrorsCount } from '../../../helpers/Utils';
import { Colxx } from '../../../components/common/CustomBootstrap';

import { checkAmountFieldValue } from '../helpers';

const validateAmount = (value, t) => {
  let error;
  const v = Number(value);
  if (isNaN(v) || v < 1) {
    error = t('modal.refund.error');
  }
  return error;
};

export const RefundModal = ({ onConfirmModal, onCloseModal, amount, t }) => (
  <Modal isOpen={true} toggle={onCloseModal}>
    <ModalHeader>{t('modal.refund.title')}</ModalHeader>
    <ModalBody>
      <Formik initialValues={{ amount }} onSubmit={onConfirmModal}>
        {({ touched, errors, setFieldValue }) => (
          <Form>
            <FormGroup className="form-group has-float-label">
              <Label className="float-label">{t('modal.refund.description')}</Label>
              <Field
                className="form-control"
                type="text"
                name="amount"
                validate={(value: any) => validateAmount(value, t)}
                onChange={({ target: { value } }) => {
                  if (checkAmountFieldValue(value)) {
                    setFieldValue('amount', value);
                  }
                }}
              />
              {errors.amount && touched.amount && <div className="invalid-feedback d-block">{errors.amount}</div>}
              <p className="small text-muted">{t('modal.refund.tooltip')}</p>
            </FormGroup>
            <Row>
              <Colxx xxs="12" className="d-flex justify-content-end">
                <Button color="primary" type="submit" disabled={!!getFieldErrorsCount(errors) && !!touched.amount}>
                  {t('modal.refund.button')}
                </Button>
              </Colxx>
            </Row>
          </Form>
        )}
      </Formik>
    </ModalBody>
  </Modal>
);
