import React, { Component, Fragment, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Button, Card, CardBody, CustomInput, FormGroup, Label, Row } from 'reactstrap';
import { Form, Formik } from 'formik';
import Select from 'react-select';

import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import { Loader } from '../../../components/common/Loader';
import Breadcrumb from '../../../containers/navs/Breadcrumb';
import {
  getGlossaryPartners,
  getGlossaryUsers,
  getPermissionsSettings,
  getUserById,
  putPermissionsSettings,
  resetGlossaryPartners,
  resetGlossaryUsers,
  getAvailableSites,
  putAvailableSites,
  getUserSiteSettings,
  menuGetUsers
} from '../../../redux/actions';
import { withTranslation } from 'react-i18next';
import { PERMISSION_INITIAL_VALUES, PERMISSIONS_DEFAULT, PERMISSIONS_ADMIN, PermissionSelectOption } from './constants';
import { Merchant, PermissionsProps, PermissionsState, PermissionSubItem, PermissionTopLevel, Site } from './interface';
import { prepareUsersForSelect } from '../Account/helpers';
import { NotificationManager } from '../../../components/common/react-notifications';
import { NOTIFICATION_TIMEOUT } from '../../../constants/defaultValues';

class Permissions extends Component<PermissionsProps, PermissionsState> {
  state: PermissionsState = {
    changed: false,
    disabled: true,
    user: {
      id: '',
      name: ''
    },
    merchant: 0,
    parsedPermissions: [],
    allowedSites: []
  };

  componentDidMount(): void {
    const { location, getPartnersAction, getSitesAction, authUserId } = this.props;
    const userId = location.state && location.state.userId ? location.state.userId : null;
    userId && this.setDefaultUser();
    getPartnersAction({});
    getSitesAction(String(authUserId));
  }

  componentDidUpdate(prevProps: Readonly<PermissionsProps>): void {
    const { users, permissions, defaultUser, location, userSites } = this.props;
    const { merchant } = this.state;

    if (prevProps.users.data !== users.data || prevProps.permissions.loading !== permissions.loading) {
      this.setState({
        changed: false
      });
    }

    if (prevProps.users.data !== users.data || prevProps.userSites.loading !== userSites.loading) {
      const allowedSites = (userSites.data || []).map((x: any) => String(x.id));
      this.setState({
        allowedSites
      });
    }

    if (prevProps.users.data !== users.data || prevProps.permissions.loading !== permissions.loading) {
      this.onSerializePermissions(merchant);
    }

    if (this.props.t !== prevProps.t) {
      this.onSerializePermissions(merchant);
    }

    const nextMatchUserId = location.state && location.state.userId ? location.state.userId : null;
    const nextDefaultUser = defaultUser ? defaultUser : null;

    if (
      (!prevProps.defaultUser && nextDefaultUser && nextMatchUserId) ||
      (nextDefaultUser && nextMatchUserId && nextDefaultUser.id !== prevProps.defaultUser.id)
    ) {
      this.setDefaultUser();
    }

    if (prevProps.location.state && prevProps.location.state.userId && !nextMatchUserId && nextDefaultUser) {
      this.cleanDefaultUser();
    }
  }

  componentWillUnmount(): void {
    this.props.resetGlossaryUsersAction();
    this.props.resetGlossaryPartnersAction();
  }

  cleanDefaultUser = () => {
    const { location } = this.props;
    const userId = location.state && location.state.userId ? location.state.userId : null;

    !userId &&
      this.setState({
        allowedSites: [],
        user: {
          id: '',
          name: ''
        }
      });
  };

  setDefaultUser = () => {
    const { location, getUserByIdAction, defaultUser, authUserId } = this.props;
    const userId = Number(location.state.userId);
    userId && getUserByIdAction(userId);

    if (defaultUser) {
      this.setState(
        {
          disabled: authUserId === Number(defaultUser.id),
          user: {
            id: defaultUser.id,
            name: defaultUser.fio
          }
        },
        () => {
          this.props.getPermissionsAction(`${defaultUser.id}`);
          this.props.getUserSitesAction(`${defaultUser.id}`);
        }
      );
    }
  };

  getMerchantList = () => {
    const { permissions, merchants } = this.props;
    let merchantList =
      merchants && permissions.data
        ? Object.keys(permissions.data).map((merchantId: string | number) =>
            merchants.find((merchant: Merchant) => Number(merchant.id) === Number(merchantId))
          )
        : ([] as any);

    merchantList = merchantList.sort((a: any, b: any) => {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    });

    return merchantList && merchantList.length
      ? merchantList.reduce(
          (acc, value) => (value ? [...acc, { key: value.id, value: value.id, label: value.name }] : acc),
          []
        )
      : [];
  };

  onSiteChange = ({ target }) => {
    const { checked, value } = target;

    const { allowedSites } = this.state;

    if (checked) {
      allowedSites.push(value);
    } else {
      const index = allowedSites.indexOf(value);
      if (index > -1) {
        allowedSites.splice(index, 1);
      }
    }

    this.setState({
      allowedSites
    });
    return true;
  };

  onPermissionChange = ({ target }) => {
    const { permissions, isAdmin } = this.props;
    const { merchant } = this.state;
    const parsedPermissions = [...this.state.parsedPermissions];
    const { name, checked } = target;

    if (!permissions.data || !permissions.data[merchant]) {
      if (isAdmin) {
        permissions.data = permissions.data || {};
        permissions.data[merchant] = [];
      } else {
        return;
      }
    }

    let changed = false;

    for (let i = 0, max = parsedPermissions.length; i < max; i++) {
      if (parsedPermissions[i].name === name) {
        changed = parsedPermissions[i].checked !== checked;
        parsedPermissions[i].checked = checked;
        parsedPermissions[i].subItems.forEach((s: PermissionSubItem) => (s.checked = checked));
      }

      let parentChanged = parsedPermissions[i].checked;
      for (let q = 0, qmax = parsedPermissions[i].subItems.length; q < qmax; q++) {
        const permissionId = parsedPermissions[i].subItems[q].id;
        const permissionItem =
          permissions && permissions.data && permissions.data[merchant].find((item: number) => item === permissionId);

        if (parsedPermissions[i].subItems[q].name === name) {
          parsedPermissions[i].subItems[q].checked = checked;
        }

        parentChanged =
          (parentChanged && !parsedPermissions[i].subItems[q].checked) || parsedPermissions[i].subItems[q].checked;

        if (
          (permissionItem && !parsedPermissions[i].subItems[q].checked) ||
          (!permissionItem && parsedPermissions[i].subItems[q].checked)
        ) {
          changed = true;
        }
      }

      parsedPermissions[i].checked = parentChanged;
    }

    this.setState({
      changed,
      parsedPermissions
    });
  };

  onUserNameChange = (userData) => {
    const { authUserId } = this.props;
    this.setState({
      disabled: authUserId === userData.key,
      user: {
        id: userData.key,
        name: userData.label
      }
    });
  };

  onSelectUser = async (user) => {
    this.onUserNameChange(user);
    this.props.getPermissionsAction(user.key);
    this.props.getUserSitesAction(user.key);
  };

  loadPermissions = (id) => {
    this.props.getPermissionsAction(id);
  };

  onSerializePermissions = (merchant: number) => {
    const { permissions, isAdmin, t } = this.props;
    if (!permissions.data) {
      if (isAdmin) {
        permissions.data = {};
      } else {
        return;
      }
    }
    const currentPermissions = new Map();
    if (merchant === 0 && isAdmin && !permissions.data[merchant]) {
      permissions.data[merchant] = [];
    }
    permissions.data[merchant].forEach((p: number) => currentPermissions.set(p, true));
    let allowedPermisssions = PERMISSIONS_DEFAULT(t);

    if (isAdmin) {
      allowedPermisssions = [...PERMISSIONS_DEFAULT(t), ...PERMISSIONS_ADMIN(t)];
    }

    let parsedPermissions;
    parsedPermissions = [];
    this.setState({
      parsedPermissions
    });
    for (let i = 0, max = allowedPermisssions.length; i < max; i++) {
      let parentChecked =
        allowedPermisssions[i].subItems.length === 0 && currentPermissions.has(allowedPermisssions[i].id);

      for (let q = 0, qmax = allowedPermisssions[i].subItems.length; q < qmax; q++) {
        const checked = currentPermissions.has(allowedPermisssions[i].subItems[q].id);

        allowedPermisssions[i].subItems[q].checked = checked;

        parentChecked = parentChecked && !checked ? parentChecked : checked;
      }
      allowedPermisssions[i].checked = parentChecked;
      parsedPermissions = [...allowedPermisssions];
    }

    this.setState({
      changed: false,
      merchant,
      parsedPermissions
    });
  };

  onSave = () => {
    const { parsedPermissions, merchant, user } = this.state;
    const { permissions, putPermissionsAction, isAdmin, getUsers } = this.props;

    const permissionsIds: number[] = [];
    parsedPermissions.forEach((p: PermissionTopLevel) => {
      if (p.subItems.length === 0 && p.checked && p.id) {
        permissionsIds.push(p.id);
      }
      p.subItems.forEach((s: PermissionSubItem) => {
        if (s.checked) {
          permissionsIds.push(s.id);
        }
      });
    });

    const newPermissions = { ...permissions.data };
    if (isAdmin && merchant === 0) {
      newPermissions[merchant] = permissionsIds;
    }

    if (merchant !== null && newPermissions[merchant]) {
      newPermissions[merchant] = permissionsIds;
      putPermissionsAction(user.id, newPermissions);
      NotificationManager.info('Изменения сохранены', null, NOTIFICATION_TIMEOUT, null, null, '');
      getUsers();
    }
  };

  onSiteSave = () => {
    const { user, allowedSites } = this.state;
    const { putSitesAction } = this.props;

    const sites: number[] = [];
    allowedSites.forEach((s: string) => {
      sites.push(Number(s));
    });
    putSitesAction(user.id, sites);
    NotificationManager.info('Изменения сохранены', null, NOTIFICATION_TIMEOUT, null, null, '');
  };

  onReset = () => {
    this.setState(
      {
        changed: false
      },
      () => this.onSerializePermissions(this.state.merchant)
    );
  };

  render(): ReactNode {
    const { users, permissions, match, sites, t } = this.props;
    const { parsedPermissions, changed, user, allowedSites, disabled } = this.state;
    const merchantList = this.getMerchantList();
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.settings" match={match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>

        {permissions.loading && <Loader />}

        <Formik initialValues={PERMISSION_INITIAL_VALUES} onSubmit={() => {}}>
          {({ values, setFieldValue }) => (
            <Fragment>
              <Form>
                <Card className="mb-4">
                  <CardBody>
                    <Row>
                      <Colxx xxs="12" sm="6">
                        <FormGroup className={`form-group has-float-label${users.loading ? ' input-loader' : ''}`}>
                          <Label for="userName" className="float-label">
                            {t('settings.user')}
                          </Label>
                          <Select
                            className="react-select"
                            classNamePrefix="react-select"
                            value={{ key: user.id, value: user.id, label: user.name }}
                            options={users.data ? prepareUsersForSelect(users.data) : []}
                            onChange={(userData: PermissionSelectOption) => {
                              this.onSelectUser(userData);
                              setFieldValue('user', userData);
                              setFieldValue('merchant', PERMISSION_INITIAL_VALUES.merchant);
                              this.onSerializePermissions(0);
                            }}
                            placeholder=""
                            name="user"
                          />
                        </FormGroup>
                      </Colxx>
                      <Colxx xxs="12" sm="6">
                        <FormGroup className="has-float-label">
                          <Label className="float-label">{t('settings.partner')}</Label>
                          <Select
                            className="react-select"
                            classNamePrefix="react-select"
                            name="merchant"
                            value={values.merchant}
                            onChange={(merchant: PermissionSelectOption) => {
                              setFieldValue('merchant', merchant);
                              merchant.value && this.setState({ merchant: merchant.value });
                              this.onSerializePermissions(merchant.value);
                            }}
                            options={merchantList}
                            placeholder=""
                            disabled={!permissions.data}
                            noOptionsMessage={() => t('settings.no_data')}
                          />
                        </FormGroup>
                      </Colxx>
                    </Row>
                  </CardBody>
                </Card>
              </Form>

              {values.user.value && (
                <Card className="mb-4">
                  <CardBody>
                    <Row>
                      <Colxx xxs="12" className="d-flex flex-wrap justify-content-between">
                        {sites.map((p: Site) => (
                          <div className="w-30 mb-5" key={p.title}>
                            <div className="mb-2">
                              <CustomInput
                                type="checkbox"
                                id={p.title}
                                label={p.title}
                                name={p.title}
                                key={p.title}
                                checked={allowedSites.includes(String(p.id))}
                                value={p.id}
                                onChange={this.onSiteChange}
                              />
                            </div>
                          </div>
                        ))}
                      </Colxx>
                      <Colxx xxs="12" className="d-flex justify-content-end">
                        <Button type="submit" color="primary" onClick={this.onSiteSave}>
                          {t('button.save')}
                        </Button>
                      </Colxx>
                    </Row>
                  </CardBody>
                </Card>
              )}
              {((merchantList.length === 0 && values.user.value && !permissions.loading) ||
                (merchantList.length > 0 && values.merchant.value)) &&
                parsedPermissions.length > 0 && (
                  <Card className="mb-4">
                    <CardBody>
                      <Row>
                        <Colxx xxs="12" className="d-flex flex-wrap justify-content-between">
                          {parsedPermissions.map((p: PermissionTopLevel) => (
                            <div className="w-30 mb-5" key={p.name}>
                              <div className="mb-2">
                                <CustomInput
                                  type="checkbox"
                                  id={p.name}
                                  label={p.label}
                                  name={p.name}
                                  checked={p.checked}
                                  disabled={disabled}
                                  onChange={this.onPermissionChange}
                                />
                              </div>
                              {p.subItems.map((s: PermissionSubItem) => (
                                <CustomInput
                                  className="ml-4"
                                  key={s.name}
                                  type="checkbox"
                                  id={s.name}
                                  label={s.label}
                                  name={s.name}
                                  checked={s.checked}
                                  disabled={disabled}
                                  onChange={this.onPermissionChange}
                                />
                              ))}
                            </div>
                          ))}
                        </Colxx>
                        <Colxx xxs="12" className="d-flex justify-content-end">
                          <Button type="submit" color="primary" disabled={!changed} onClick={this.onSave}>
                            {t('button.save')}
                          </Button>
                          <Button
                            type="reset"
                            color="primary"
                            className="ml-2"
                            disabled={!changed || permissions.loading || disabled}
                            onClick={this.onReset}
                          >
                            {t('button.cancel')}
                          </Button>
                        </Colxx>
                      </Row>
                    </CardBody>
                  </Card>
                )}
            </Fragment>
          )}
        </Formik>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ settings, glossary, app, menu, sites, authUser }) => ({
  defaultUser: app.userById.data,
  users: menu.users,
  isAdmin: authUser.user.data ? authUser.user.data.is_admin : false,
  sites: sites.sites.data || [],
  authUserId: authUser.user.data ? authUser.user.data.id : 0,
  merchants: glossary.partners.rows,
  permissions: settings.permissionsSettings,
  userSites: settings.userSites
});

const mapDispatchToProps = {
  getPartnersAction: getGlossaryPartners,
  getUserByIdAction: getUserById,
  getGlossaryUsersAction: getGlossaryUsers,
  getPermissionsAction: getPermissionsSettings,
  getUserSitesAction: getUserSiteSettings,
  putPermissionsAction: putPermissionsSettings,
  resetGlossaryUsersAction: resetGlossaryUsers,
  resetGlossaryPartnersAction: resetGlossaryPartners,
  getSitesAction: getAvailableSites,
  putSitesAction: putAvailableSites,
  getUsers: menuGetUsers
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Permissions)
);
