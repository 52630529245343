import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Button, Card, CardBody, FormGroup, Label, Row } from 'reactstrap';
import { Form, Formik, FormikValues } from 'formik';
import Select from 'react-select';
import ReactTable from 'react-table';
import isEqual from 'lodash/isEqual';

import { unmaskPhoneNumber } from '../../../helpers/Utils';
import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import { NotFoundMessage } from '../../../components/common/NotFoundMessage';
import { Loader } from '../../../components/common/Loader';
import Breadcrumb from '../../../containers/navs/Breadcrumb';
import DataTablePagination from '../../../components/DatatablePagination';
import { SelectorOption } from '../../../interfaces/app';
import {
  getGlossaryAccountsByPartner,
  getGlossaryPartners,
  resetGlossaryPartners
} from '../../../redux/glossary/actions';
import { NotificationManager } from '../../../components/common/react-notifications';
import { NOTIFICATION_TIMEOUT } from '../../../constants/defaultValues';

import { getGlossaryPartnersColumns, GLOSSARY_PARTNERS_INITIAL_VALUES, DEFAULT_SORTER } from './constant';
import { getPartnerAccounts } from './helpers';
import { GlossaryPartnersProps, GlossaryPartnersState } from './interface';
import { getAccountOptions, getPartnerNameOptions } from '../Contracts/helpers';
import { getPayoutAccounts } from '../../../redux/operations/actions';
import { PayoutAccount } from '../../../redux/operations/interface';
import { withTranslation } from 'react-i18next';

class Partners extends Component<GlossaryPartnersProps, GlossaryPartnersState> {
  state: GlossaryPartnersState = {
    page: 0,
    expanded: {}
  };
  resetForm;

  componentDidUpdate(prevProps: Readonly<GlossaryPartnersProps>): void {
    const {
      error,
      merchantIdList,
      selectedUserId,
      resetGlossaryPartnersAction,
      getPayoutAccountsAction,
      isAdmin,
      authUserId
    } = this.props;

    if (prevProps.error !== error) {
      NotificationManager.info(error, 'Ошибка загрузки', NOTIFICATION_TIMEOUT, null, null, '');
    }

    if (!isEqual(prevProps.merchantIdList, merchantIdList) || prevProps.selectedUserId !== selectedUserId) {
      this.resetForm();
      resetGlossaryPartnersAction();

      if (merchantIdList && merchantIdList.length) {
        getPayoutAccountsAction(
          merchantIdList,
          isAdmin && selectedUserId && authUserId !== selectedUserId ? selectedUserId : undefined
        );
      }
    }
  }

  componentDidMount(): void {
    const { getPayoutAccountsAction, isAdmin, authUserId, merchantIdList, selectedUserId } = this.props;

    if (merchantIdList && merchantIdList.length) {
      getPayoutAccountsAction(
        merchantIdList,
        isAdmin && selectedUserId && authUserId !== selectedUserId ? selectedUserId : undefined
      );
    }
  }

  componentWillUnmount(): void {
    const { resetGlossaryPartnersAction, getAccountsAction, merchantIdList } = this.props;
    resetGlossaryPartnersAction();
    getAccountsAction(merchantIdList);
  }

  setNewPage = (pageNumber: number) => {
    this.setState({ page: pageNumber });
  };

  onFindGlossaryPartners = ({ name, account_id, email, phone, account_name }: FormikValues) => {
    const { maxPageSize } = this.props;

    this.setState({ expanded: {} });
    this.props.getGlossaryPartnersAction({
      name: name.value,
      account_id,
      email,
      phone: phone ? unmaskPhoneNumber(phone) : undefined,
      limit: maxPageSize,
      offset: 0
    });
  };

  getSubComponent = (original) => {
    const {
      accounts: { data, loading },
      t
    } = this.props;
    const currentData = getPartnerAccounts(data, original.id);
    const isAccountsExist = currentData && currentData.length;

    if (!loading && !isAccountsExist) {
      return <div className="text-muted d-flex justify-content-center mt-2 mb-3">{t('no_accounts_attached')}</div>;
    }

    return (
      !loading &&
      isAccountsExist && (
        <div className="d-flex justify-content-end mb-1 mr-4">
          <table className="sub-component-table">
            <thead>
              <tr key="head">
                <th key="col1">
                  <b>{t('contracts.account_id')}</b>
                </th>
                <th key="col2">
                  <b>{t('contracts.account')}</b>
                </th>
              </tr>
            </thead>
            <tbody>
              {currentData.map((value: PayoutAccount, index: number) => (
                <tr key={index}>
                  <td key={value.id}>{value.id}</td>
                  <td key={value.name}>{value.name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )
    );
  };

  render(): ReactNode {
    const {
      loading,
      rows,
      match,
      getAccountsAction,
      accounts,
      isAdmin,
      authUserId,
      selectedUserId,
      maxPageSize,
      t,
      selectedMerchants
    } = this.props;
    const { page } = this.state;

    const userId = isAdmin && authUserId !== selectedUserId ? selectedUserId : undefined;
    const currentPageData = rows && rows.length ? rows : [];

    const pageSize =
      currentPageData && currentPageData.length
        ? currentPageData.length < maxPageSize
          ? currentPageData.length
          : maxPageSize
        : 0;

    return (
      <div className="d-flex flex-column h-100 position-relative">
        {loading && <Loader />}

        <Row className="flex-shrink-0">
          <Colxx xxs="12">
            <Breadcrumb heading="menu.glossary" match={match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>

        <Formik
          initialValues={GLOSSARY_PARTNERS_INITIAL_VALUES(t)}
          onSubmit={this.onFindGlossaryPartners}
          isInitialValid={true}
        >
          {({ values, setFieldValue, isValid, errors, touched, resetForm }) => {
            this.resetForm = resetForm;

            return (
              <Form className="av-tooltip tooltip-label-bottom">
                <Card className="mb-4">
                  <CardBody>
                    <Row>
                      <Colxx xxs="12" sm="6" lg="6">
                        <FormGroup className="form-group has-float-label">
                          <Label className="float-label">{t('contracts.partner')}</Label>
                          <Select
                            className="react-select"
                            classNamePrefix="react-select"
                            name="name"
                            value={values.name}
                            onChange={(partner: SelectorOption) => {
                              setFieldValue('name', partner);
                            }}
                            options={getPartnerNameOptions(selectedMerchants, t)}
                            placeholder=""
                            maxLength="255"
                            noOptionsMessage={() => {
                              t('select.no_data');
                            }}
                          />
                          {errors.name && touched.name && <div className="invalid-feedback d-block">{errors.name}</div>}
                        </FormGroup>
                      </Colxx>
                      <Colxx xxs="12" sm="6" lg="6">
                        <FormGroup className="form-group has-float-label">
                          <Label className="float-label">{t('contracts.account')}</Label>
                          <Select
                            className="react-select"
                            classNamePrefix="react-select"
                            name="account_name"
                            value={values.account_name}
                            onChange={(partner: SelectorOption) => {
                              setFieldValue('account_name', partner);
                              setFieldValue('account_id', partner.key);
                            }}
                            options={getAccountOptions(accounts.data, t)}
                            placeholder=""
                            maxLength="255"
                            noOptionsMessage={() => {
                              t('select.no_data');
                            }}
                          />
                          {errors.account_name && touched.account_name && (
                            <div className="invalid-feedback d-block">{errors.account_name}</div>
                          )}
                        </FormGroup>
                      </Colxx>

                      <Colxx xxs="12" className="d-flex justify-content-end">
                        <Button type="submit" color="primary" disabled={!isValid}>
                          <span className="label">{t('button.find')}</span>
                        </Button>
                      </Colxx>
                    </Row>
                  </CardBody>
                </Card>
              </Form>
            );
          }}
        </Formik>

        {rows && rows.length > 0 && (
          <Row className="mb-4">
            <Colxx xxs="12">
              <Card>
                <CardBody>
                  <ReactTable
                    data={currentPageData}
                    columns={getGlossaryPartnersColumns(getAccountsAction, accounts, t, userId)}
                    pages={Math.ceil(rows.length / maxPageSize)}
                    defaultPageSize={pageSize}
                    pageSize={pageSize}
                    page={page}
                    sortable={true}
                    filterable={false}
                    showPageJump={true}
                    showPagination={true}
                    defaultSorted={DEFAULT_SORTER}
                    showPageSizeOptions={false}
                    PaginationComponent={DataTablePagination}
                    onPageChange={this.setNewPage}
                    expanded={this.state.expanded}
                    onExpandedChange={(expanded: object) => this.setState({ expanded })}
                    SubComponent={({ original }) => this.getSubComponent(original)}
                    loadingText=""
                    freezeWhenExpanded={true}
                    noDataText={t('select.no_data')}
                  />
                </CardBody>
              </Card>
            </Colxx>
          </Row>
        )}
        {rows && !rows.length && <NotFoundMessage />}
      </div>
    );
  }
}

const mapStateToProps = ({ glossary, settings, authUser, operations, menu }) => ({
  isAdmin: authUser.user.data && authUser.user.data.is_admin ? authUser.user.data.is_admin : false,
  selectedUserId: settings.selectedUser ? settings.selectedUser.id : 0,
  authUserId: authUser.user.data ? authUser.user.data.id : 0,
  loading: glossary.partners.loading,
  error: glossary.partners.error,
  maxPageSize: settings.gcmSettings.data ? settings.gcmSettings.data.max_page_size : 50,
  rows: glossary.partners.rows,
  merchantIdList: settings.selectedMerchantsId,
  accounts: glossary.partners.accounts,
  payoutAccounts: operations.payoutAccounts,
  selectedMerchants: settings.selectedMerchants
});

const mapDispatchToProps = {
  getPayoutAccountsAction: getPayoutAccounts,
  getGlossaryPartnersAction: getGlossaryPartners,
  resetGlossaryPartnersAction: resetGlossaryPartners,
  getAccountsAction: getGlossaryAccountsByPartner
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Partners)
);
