import React, { Fragment, Component, ReactNode, Suspense } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { IntlProvider } from 'react-intl';

import AppLocale from './lang';
import { getUser, getCustomDesign, applyCustomDesign } from './redux/actions';
import NotificationContainer from './components/common/react-notifications/NotificationContainer';
import ViewUser from './views/user/User';
import ViewOperations from './views/operations/Operations';
import ViewServices from './views/services/Services';
import ViewBalance from './views/balance/Balance';
import ViewDocuments from './views/documents/Documents';
import ViewDisposals from './views/disposals/ViewDisposals';
import ViewProfile from './views/profile/Profile';
import ViewGlossary from './views/glossary/Glossary';
import ViewSettings from './views/settings/Settings';
import ViewStatistics from './views/statistics/Statistics';
import ViewDownloads from './views/downloads/Downloads';
import ViewError from './views/Error';
import ViewBlankPage from './views/app/BlankPage';
import ViewSectionInDevelopment from './views/app/SectionInDevelopment';
import RedirectLayout from './views/profile/ChangePassword/RedirectLayout';
import AppLayout from './layout/AppLayout';
import AdminLayout from './layout/AdminLayout';
import UserLogs from './views/journal/UserLogs';
import Main from './views/Main';
import EmptyPage from './views/app/EmptyPage';
import PermissionDeniedPage from './views/app/PermissionDeniedPage';
import Support from './views/support/Support';

import { AppProps } from './App.interface';
import { GenCustomDesignStyles } from './helpers/CustomDesign';
import { isUserAuth } from './helpers/Access';
import StartPage from './views/app/StartPage/StartPage';

class App extends Component<AppProps> {
  componentDidMount(): void {
    this.props.getUserData();
    this.props.getCustomDesign({ merchantId: null, domain: window.location.hostname });
  }

  render(): ReactNode {
    const { locale, customDesign } = this.props;
    const currentAppLocale = AppLocale[locale];

    return (
      <Suspense fallback="loading">
        <div className="h-100">
          <style>{GenCustomDesignStyles(customDesign)}</style>
          <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
            <Fragment>
              <NotificationContainer />

              <Switch>
                <Route path="/user" component={ViewUser} />
                <Route path="/error" component={ViewError} />
                <Route path="/change-password-strict" component={RedirectLayout} />
                <Route path="/permission-denied" component={PermissionDeniedPage} />

                <Route path="/">
                  <AppLayout>
                    <Switch>
                      <Route exact path="/" component={Main} />
                      <Route path="/services" component={ViewServices} />
                      <Route path="/statistics" component={ViewStatistics} />
                      <Route path="/operations" component={ViewOperations} />
                      <Route path="/balance" component={ViewBalance} />
                      <Route path="/documents" component={ViewDocuments} />
                      <Route path="/disposals" component={ViewDisposals} />
                      <Route path="/profile" component={ViewProfile} />
                      <Route path="/support" component={Support} />
                      <Route path="/downloads" component={ViewDownloads} />
                      <Route path="/exchange-rates" component={ViewSectionInDevelopment} />
                      <Route path="/app/blank-page" component={ViewBlankPage} />
                      <Route path="/empty" component={EmptyPage} />
                      <Route path="/start-page" component={StartPage} />

                      <Route path="/">
                        <AdminLayout>
                          <Switch>
                            <Route path="/glossary" component={ViewGlossary} />
                            <Route path="/settings" component={ViewSettings} />
                            <Route path="/journal" component={UserLogs} />
                            <Redirect to="/error" />
                          </Switch>
                        </AdminLayout>
                      </Route>
                    </Switch>
                  </AppLayout>
                </Route>

                <Redirect to="/error" />
              </Switch>
            </Fragment>
          </IntlProvider>
        </div>
      </Suspense>
    );
  }
}

const mapStateToProps = ({ settings, authUser }) => {
  const { locale, customDesign } = settings;
  const {
    loginStatus,
    user: { data, loading, error }
  } = authUser;

  return {
    locale,
    customDesign,
    isAuth: isUserAuth(loginStatus, loading, error, data),
    loading
  };
};

const mapDispatchToProps = {
  getUserData: getUser,
  getCustomDesign,
  applyCustomDesign
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
