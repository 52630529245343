export default {
  /* General */
  'general.copyright': 'Gogo React © 2018 All Rights Reserved.',

  /* User Login, Logout, Register */
  'user.login-title': 'Login',
  'user.register': 'Register',
  'user.forgot-password': 'Forgot Password',
  'user.email': 'E-mail',
  'user.password': 'Password',
  'user.old-password': 'Old Password',
  'user.new-password': 'New Password',
  'user.repeat-new-password': 'Repeat New Password',
  'user.temp-password': 'Temp Password',
  'user.restore-access': 'Restore-access',
  'user.password-strength-level': 'Password strength',
  'user.password-strength-low': 'low',
  'user.password-strength-medium': 'middle',
  'user.password-strength-high': 'high',
  'user.fullname': 'Full Name',
  'user.phone': 'Phone',
  'user.login-button': 'LOGIN',
  'user.register-button': 'REGISTER',
  'user.reset-password-button': 'RESET',
  'user.get-tmp-password': 'Get temporary password',
  'user.set-tmp-password': 'Accept temporary password',
  'user.retry-tmp-password': 'Retry',
  'user.buy': 'BUY',
  'user.username': 'Username',

  /* Menu */
  'menu.app': 'Home',
  'menu.dashboards': 'Dashboards',
  'menu.gogo': 'Gogo',
  'menu.start': 'Start',
  'menu.second-menu': 'Second Menu',
  'menu.second': 'Second',
  'menu.ui': 'UI',
  'menu.charts': 'Charts',
  'menu.chat': 'Chat',
  'menu.survey': 'Survey',
  'menu.todo': 'Todo',
  'menu.search': 'Search',
  'menu.docs': 'Docs',
  'menu.blank-page': 'Blank Page',
  'menu.restore-password': 'Restore password',
  'menu.profile': 'Profile',
  'menu.balance': 'Balance',
  'menu.settings': 'Settings',
  'menu.settings.gcm': 'GCM Settings',
  'menu.system-settings': 'System settings',
  'menu.custom-design': 'Design',

  /* Error Page */
  'pages.error-title': 'Ooops... looks like an error occurred!',
  'pages.error-code': 'Error code',
  'pages.go-back-home': 'GO BACK HOME',

  /* Topnav */
  'topnav.user.profile': 'Profile',

  /* Buttons */
  'button.change-password': 'Change password',
  'button.find': 'Search',
  'button.save': 'Save',
  'button.cancel': 'Cancel',
  'button.confirm': 'Confirm',
  'button.yes': 'Yes',
  'button.no': 'No',

  'glossary.service-name': 'Service name',
  'glossary.services.loading': 'Loading',
  'glossary.merchant': 'Merchant',
  'glossary.merchant-id': 'Merchant Id',
  'services.noData': 'No data',

  /* Settings */
  'settings.writeToTechnicalSupport': 'In case of problems with the second factor, contact technical support',

  /* Custom Design */
  'customDesign.logo': 'Logo',
  'customDesign.buttonColor': 'Button color (block 3)',
  'customDesign.menuTextColor': 'Menu text color (block 1, 2)',
  'customDesign.menuTextHoverColor': 'Menu text color hover (block 1, 2)',
  'customDesign.customCss': 'Custom CSS',
  'customDesign.theme': 'Theme',
  'customDesign.menuColor': 'Menu color  (block 1)',
  'customDesign.logoSize': 'Logo size (block 6)',
  'customDesign.loginLogoSize': 'Logo size (block 3)',
  'customDesign.align.left': 'Left',
  'customDesign.align.right': 'Right',
  'customDesign.align.center': 'Center',
  'customDesign.logoPlacement': 'Logo layout (block 3)',
  'customDesign.submenuColor': 'Submenu color  (block 2)',
  'customDesign.backgroundColor': 'Background color (block 5)',
  'customDesign.cardColor': 'Content color (block 4)',
  'customDesign.themeColor1': 'Main theme color',
  'customDesign.loginBgColor': 'Login background color (block 7)',
  'customDesign.loginCardColor': 'Login card color (block 1)',
  'customDesign.loginCardBackground': 'Login card background (block 1)',
  'customDesign.loginLogo': 'Login page logo (block 3)',
  'customDesign.loginBackground': 'Login page background (block 2)',
  'customDesign.cabinetSettings': 'Cabinet design settings',
  'customDesign.generalSettings': 'General settings',
  'customDesign.loginSettings': 'Login page settings',
  'customDesign.domain': 'Domain name',
  'customDesign.preview': 'Preview',
  'customDesign.resetToDefaultPreview': 'Reset preview',
  'customDesign.resetToDefault': 'Reset',
  'customDesign.modalTitle': 'Reset design settings',
  'customDesign.modalText': 'Are you sure you want to reset custom design settings?',
  'customDesign.modalConfirm': 'Yes',
  'customDesign.modalCancel': 'No',
  'customDesign.loginPage': 'Login page',
  'customDesign.cabinet': 'Cabinet',
  'customDesign.general': 'General',
  'customDesign.loginBackgroundColor': 'Login page background color (block 2)',
  'customDesign.tabTitle': 'Tab tittle (block 1)',
  'customDesign.favicon': 'Favicon (block 1)',
  'customDesign.supportEmail': 'Support Email (block 2)',

  /* System settings */
  'systemSettings.gcm': 'GCM settings',
  'systemSettings.vault': 'Vault settings',
  'systemSettings.lastUpdateTime': 'Time of the last update',
  'systemSettings.update': 'Update',
  'systemSettings.updateInterval': 'Regular update interval (hours)',
  'systemSettings.regularlyDataUpdate': 'Regular data updates from Vault',
  'systemSettings.maxValue': 'Max value',
  'systemSettings.minValue': 'Min value',
  'systemSettings.integerOnly': 'Numbers only',

  /* Payouts */
  'payouts.actionConfirm': 'Confirm action',
  'payouts.toNumber': 'To this phone number',
  'payouts.smsWasSend': 'was sent SMS.',
  'payouts.inputCodeToConfirm': 'To confirm the payment, you need to enter this code below:',
  'payouts.code': 'Code',
  'payouts.codeDeadline': 'Code valid until',
  'payouts.sec': 'sec',
  'payouts.attemptsWarning': 'The code is entered incorrectly, please try again.',
  'payouts.attemptsLeft': 'Attempts left:',
  'payouts.paymentID': 'Payment ID:',
  'payouts.result0': 'processing.',
  'payouts.result1': 'canceled. Invalid confirmation code entered. Try the operation again.',
  'payouts.result2': 'canceled. The term for confirming payment using the sent code has expired.',
  'payouts.result3': 'canceled.',
  'payouts.result4': 'canceled or not executed. An internal error has occurred. Please contact technical support.',
  'payouts.resultHeaderSuccess': 'Confirmed',
  'payouts.resultHeaderFailure': 'Not confirmed',
  'payouts.cancel': 'Payouts cancel',
  'payouts.cancelConfirmationText': 'Are you sure you want to cancel the payment confirmation?',
  'payouts.error.codeLength': 'Enter the six-digit code'
};
