import { CellInfo } from 'react-table';
import format from '../../../localization';
import { roundAmount } from '../../../helpers/Utils';

import { DATE_FORMAT_BASIC } from '../../../constants/app';

export const tablecolumns = (t: any) => [
  {
    Header: t('balance.account name'),
    accessor: 'name'
  },
  {
    Header: t('balance.number'),
    accessor: 'contract_name'
  },
  {
    Header: t('balance.date'),
    accessor: 'contract_date',
    Cell: ({ value }: CellInfo) => format(value, DATE_FORMAT_BASIC)
  },
  {
    Header: t('balance.balance'),
    accessor: 'balance',
    Cell: ({ value }: CellInfo) => roundAmount(value)
  },
  {
    Header: t('currency.currency'),
    accessor: 'currency'
  }
];

export const DEFAULT_SORTER = [
  {
    id: 'contract_date',
    desc: true
  }
];
