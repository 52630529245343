import {
  MENU_SET_CLASSNAMES,
  MENU_CONTAINER_ADD_CLASSNAME,
  MENU_CLICK_MOBILE_MENU,
  MENU_CHANGE_HAS_SUB_ITEM_STATUS,
  MENU_GET_USERS,
  MENU_GET_USERS_SUCCESS,
  MENU_GET_USERS_ERROR,
  MENU_GET_MERCHANTS,
  MENU_GET_MERCHANTS_SUCCESS,
  MENU_GET_MERCHANTS_ERROR,
  GET_ACCOUNT_SETTINGS_SUCCESS,
  MENU_SET_PERMISSION_ID
} from '../actions';
import {
  defaultMenuType,
  subHiddenBreakpoint,
  menuHiddenBreakpoint,
  defaultMenuPmId
} from '../../constants/defaultValues';
import { MenuReducerState } from './interface';
import { updatedUserData } from './helpers';

const INIT_STATE: MenuReducerState = {
  containerClassnames: defaultMenuType,
  subHiddenBreakpoint,
  menuHiddenBreakpoint,
  menuClickCount: 0,
  // if you use menu-sub-hidden as default menu type, set value of this variable to false
  selectedMenuHasSubItems: defaultMenuType === 'menu-default',
  selectedMenuPmId: defaultMenuPmId,
  users: {
    data: null,
    loading: false,
    error: ''
  },
  merchants: {
    data: null,
    loading: false,
    error: ''
  }
};

export default (state: MenuReducerState = INIT_STATE, { type, payload }): MenuReducerState => {
  switch (type) {
    case MENU_SET_PERMISSION_ID:
      return { ...state, selectedMenuPmId: payload.setMenuPmId };
    case MENU_CHANGE_HAS_SUB_ITEM_STATUS:
      return { ...state, selectedMenuHasSubItems: payload.selectedMenuHasSubItems };
    case MENU_SET_CLASSNAMES:
      return {
        ...state,
        containerClassnames: payload.containerClassnames,
        menuClickCount: payload.menuClickCount
      };
    case MENU_CLICK_MOBILE_MENU:
      return {
        ...state,
        containerClassnames: payload.containerClassnames,
        menuClickCount: payload.menuClickCount
      };
    case MENU_CONTAINER_ADD_CLASSNAME:
      return { ...state, containerClassnames: payload.containerClassnames };

    case MENU_GET_USERS:
      return {
        ...state,
        users: {
          ...state.users,
          loading: true,
          error: ''
        }
      };
    case MENU_GET_USERS_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          data: payload.users,
          loading: false
        }
      };
    case MENU_GET_USERS_ERROR:
      return {
        ...state,
        users: {
          ...state.users,
          error: payload.error,
          loading: false
        }
      };
    case MENU_GET_MERCHANTS:
      return {
        ...state,
        merchants: {
          ...state.merchants,
          loading: true,
          error: ''
        }
      };
    case MENU_GET_MERCHANTS_SUCCESS:
      return {
        ...state,
        merchants: {
          ...state.merchants,
          data: payload.merchants,
          loading: false
        }
      };
    case MENU_GET_MERCHANTS_ERROR:
      return {
        ...state,
        merchants: {
          ...state.merchants,
          error: payload.error,
          loading: false
        }
      };

    case GET_ACCOUNT_SETTINGS_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          data: updatedUserData(state.users.data, payload.data),
          loading: false
        }
      };
    default:
      return { ...state };
  }
};
