import React, { FunctionComponent } from 'react';
import { Redirect, RouteComponentProps, Switch } from 'react-router-dom';
import { SERVICE_TYPES_SLUGS } from './services/ServicesList/constant';

const Main: FunctionComponent<RouteComponentProps> = () => (
  <Switch>
    <Redirect from={'/'} to={`services/${SERVICE_TYPES_SLUGS.INCOME}`} />
    <Redirect to={'/error'} />
  </Switch>
);

export default Main;
