import React, { Component, Fragment, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Button, Card, CardBody, FormGroup, Label, Row } from 'reactstrap';
import ReactTable from 'react-table';
import Select from 'react-select';
import { Form, Formik } from 'formik';
import InputMask from 'react-input-mask';
import isEqual from 'lodash/isEqual';
import { startOfDay, addDays } from 'date-fns';
import { withTranslation } from 'react-i18next';
import { getUserLogs } from '../../redux/journal/actions';
import { DatePickerLocaled } from '../../components/common/DatePickerLocaled/DatePickerLocaled';
import { NotificationManager } from '../../components/common/react-notifications';
import { Colxx, Separator } from '../../components/common/CustomBootstrap';
import { NotFoundMessage } from '../../components/common/NotFoundMessage';
import DataTablePagination from '../../components/DatatablePagination';
import Breadcrumb from '../../containers/navs/Breadcrumb';
import { Loader } from '../../components/common/Loader';

import { getUsersOptions } from './helpers';
import { getFieldErrorsCount, toISOString } from '../../helpers/Utils';
import { DATE_START_TOOLTIP_TEXT, INITIAL_VALUES, logsTableColumns, DEFAULT_SORTER } from './constants';
import { NOTIFICATION_TIMEOUT } from '../../constants/defaultValues';
import { DATE_FORMAT_BASIC, DATE_MASK } from '../../constants/app';
import { ContractsProps } from '../glossary/Contracts/interface';
import { SelectorOption } from '../../interfaces/app';
import { GetUserLogsFormValues } from './interface';

class UserLogs extends Component<any, any> {
  state = {
    page: 0,
    submit: false,
    sorting: ''
  };
  resetForm;

  componentDidMount(): void {
    this.setState({ submit: false });
  }

  componentDidUpdate(prevProps: ContractsProps): void {
    const { error, merchantIdList, selectedUserId } = this.props;

    if (error && error !== prevProps.error) {
      NotificationManager.info(error, 'Ошибка', NOTIFICATION_TIMEOUT, null, null, '');
    }

    if (!isEqual(prevProps.merchantIdList, merchantIdList) || prevProps.selectedUserId !== selectedUserId) {
      this.resetForm();
    }
  }

  onSubmit = (values: GetUserLogsFormValues, page = 0, sorting = '') => {
    const { maxPageSize } = this.props;

    this.setState({ submit: true });

    const requestValues = {
      from: toISOString(values.dateFrom),
      to: toISOString(startOfDay(addDays(values.dateTo, 1))),
      user_id: values.user_id.value,
      offset: page * maxPageSize,
      limit: maxPageSize,
      sorting
    };

    this.setState({ page }, () => this.props.getUserLogsAction(requestValues));
  };

  setSorting = (newSorted, values, page) => {
    const sorting = `${newSorted[0].id} ${newSorted[0].desc ? 'DESC' : 'ASC'}`;
    this.setState({ sorting });
    this.onSubmit(values, page, sorting);
  };

  render(): ReactNode {
    const { loading, match, users, logsData, count, maxPageSize, t } = this.props;
    const { page, submit, sorting } = this.state;

    const pageSize = logsData && logsData.length ? (logsData.length < maxPageSize ? logsData.length : maxPageSize) : 0;

    return (
      <>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.actionLog" match={match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>

        {loading && <Loader />}

        <Row className="flex-shrink-0">
          <Colxx xxs="12" className="mb-4">
            <Formik
              initialValues={INITIAL_VALUES(t)}
              onSubmit={(newValues: GetUserLogsFormValues) => this.onSubmit(newValues)}
            >
              {({ errors, touched, setFieldValue, resetForm, values, setFieldTouched }) => {
                this.resetForm = resetForm;

                return (
                  <Fragment>
                    <Form>
                      <Card className="mb-4">
                        <CardBody>
                          <Row>
                            <Colxx xxs="12" sm="6" lg="4">
                              <FormGroup className="has-float-label">
                                <Label className="float-label" for="dateFrom">
                                  {t('journal.date from')}
                                </Label>
                                <DatePickerLocaled
                                  id="dateFrom"
                                  name="dateFrom"
                                  selected={values.dateFrom}
                                  customInput={<InputMask mask={DATE_MASK} />}
                                  dateFormat={DATE_FORMAT_BASIC}
                                  onChange={(d: Date) => setFieldValue('dateFrom', d)}
                                  autoComplete="off"
                                  maxDate={values.dateTo ? values.dateTo : new Date()}
                                  onBlur={() => setFieldTouched('dateFrom', true)}
                                  tooltip={t(DATE_START_TOOLTIP_TEXT)}
                                />
                                {errors.dateFrom && <div className="invalid-feedback d-block">{errors.dateFrom}</div>}
                              </FormGroup>
                            </Colxx>
                            <Colxx xxs="12" sm="6" lg="4">
                              <FormGroup className="has-float-label">
                                <Label className="float-label" for="dateTo">
                                  {t('journal.date by')}
                                </Label>
                                <DatePickerLocaled
                                  id="dateTo"
                                  name="dateTo"
                                  selected={values.dateTo}
                                  customInput={<InputMask mask={DATE_MASK} />}
                                  dateFormat={DATE_FORMAT_BASIC}
                                  onChange={(d: Date) => setFieldValue('dateTo', d)}
                                  autoComplete="off"
                                  minDate={values.dateFrom ? values.dateFrom : undefined}
                                  maxDate={new Date()}
                                  onBlur={() => setFieldTouched('dateTo', true)}
                                  tooltip={t(DATE_START_TOOLTIP_TEXT)}
                                />
                                {errors.dateTo && touched.dateTo && (
                                  <div className="invalid-feedback d-block">{errors.dateTo}</div>
                                )}
                              </FormGroup>
                            </Colxx>
                            <Colxx xxs="12" sm="6" lg="4">
                              <FormGroup className="form-group has-float-label">
                                <Label className="float-label"> {t('journal.user')}</Label>
                                <Select
                                  className="react-select"
                                  classNamePrefix="react-select"
                                  name="user_id"
                                  value={values.user_id}
                                  onChange={(user: SelectorOption) => {
                                    setFieldValue('user_id', user);
                                  }}
                                  options={getUsersOptions(users, t)}
                                  placeholder=""
                                  maxLength="255"
                                  noOptionsMessage={() => 'Нет данных'}
                                />
                              </FormGroup>
                            </Colxx>
                            <Colxx xxs="12" className="d-flex justify-content-end">
                              <Button disabled={!!getFieldErrorsCount(errors)} color="primary" type="submit">
                                {t('journal.button.search')}
                              </Button>
                            </Colxx>
                          </Row>
                        </CardBody>
                      </Card>
                    </Form>

                    {submit && logsData && logsData.length > 0 && (
                      <Card className="mb-4">
                        <CardBody>
                          <ReactTable
                            data={logsData}
                            columns={logsTableColumns(t)}
                            page={page}
                            pages={Math.ceil(count / maxPageSize)}
                            defaultPageSize={pageSize}
                            defaultSorted={DEFAULT_SORTER}
                            pageSize={pageSize}
                            sortable={true}
                            filterable={false}
                            showPageJump={true}
                            showPagination={true}
                            showPageSizeOptions={false}
                            PaginationComponent={DataTablePagination}
                            loading={loading}
                            manual
                            onPageChange={(newPage: number) => this.onSubmit(values, newPage, sorting)}
                            onSortedChange={(newSorted) => {
                              this.setSorting(newSorted, values, page);
                            }}
                            loadingText={''}
                          />
                        </CardBody>
                      </Card>
                    )}

                    {logsData && !logsData.length && <NotFoundMessage />}
                  </Fragment>
                );
              }}
            </Formik>
          </Colxx>
        </Row>
      </>
    );
  }
}

const mapStateToProps = ({ settings, menu, glossary, logs }) => ({
  selectedUserId: settings.selectedUser ? settings.selectedUser.id : null,
  merchantIdList: settings.selectedMerchantsId,
  logsData: logs.data,
  maxPageSize: settings.gcmSettings.data ? settings.gcmSettings.data.max_page_size : 50,
  count: logs.count,
  users: menu.users.data,
  loading: logs.loading,
  error: logs.error
});

const mapDispatchToProps = {
  getUserLogsAction: getUserLogs
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UserLogs)
);
