import { GET_USER_LOGS, GET_USER_LOGS_SUCCESS, GET_USER_LOGS_ERROR } from '../actions';
import { UserLogsState } from './interface';

const INIT_STATE: UserLogsState = {
  error: '',
  data: null,
  count: 0,
  loading: false
};

export default (state: UserLogsState = INIT_STATE, { type, payload }): UserLogsState => {
  switch (type) {
    case GET_USER_LOGS:
      return {
        ...state,
        loading: true,
        error: ''
      };
    case GET_USER_LOGS_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false
      };
    case GET_USER_LOGS_ERROR:
      return {
        ...state,
        ...payload,
        loading: false
      };
    default:
      return { ...state };
  }
};
