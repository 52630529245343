import React, { FunctionComponent } from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import ServicesList from './ServicesList/ServicesList';
import { SERVICE_TYPES_SLUGS } from './ServicesList/constant';

const Services: FunctionComponent<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/:slug`} component={ServicesList} />
    <Redirect from={`${match.url}/`} to={`${match.url}/${SERVICE_TYPES_SLUGS.INCOME}`} />
    <Redirect to={'/error'} />
  </Switch>
);

export default Services;
