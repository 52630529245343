import React from 'react';
import { CellInfo } from 'react-table';
import format from '../../../localization';

import { ALL_OPTION } from '../constants';
import { DATE_FORMAT_BASIC } from '../../../constants/app';

export const getGlossaryContractsColumns = (t: any) => [
  {
    Header: t('contracts.number'),
    accessor: 'contract_name'
  },
  {
    Header: t('contracts.date'),
    accessor: 'contract_date',
    Cell: ({ original }: CellInfo) => format(original.contract_date, DATE_FORMAT_BASIC)
  },
  {
    Header: t('contracts.partner'),
    accessor: 'partner_name'
  },
  {
    expander: true,
    Header: '',
    width: 30,
    Expander: ({ isExpanded }) => (
      <div>{isExpanded ? <i className="simple-icon-arrow-up" /> : <i className="simple-icon-arrow-down" />}</div>
    ),
    style: {
      fontSize: 12,
      padding: '0',
      textAlign: 'center',
      userSelect: 'none'
    }
  }
];

export const DEFAULT_SORTER = [
  {
    id: 'contract_name',
    desc: false
  }
];

export const INITIAL_VALUES = (t: any) => ({
  account_id: ALL_OPTION(t),
  partner_name: ALL_OPTION(t),
  contract_name: ''
});
