import React, { Fragment, ReactNode } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { Button, Card, CardBody, Row, NavItem, Nav } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import { connect } from 'react-redux';
import { Dropzone, DropzoneFile } from 'dropzone';
import { CustomDesignProps, CustomDesignState } from './interface';
import Breadcrumb from '../../../containers/navs/Breadcrumb';
import { NotificationManager } from '../../../components/common/react-notifications';
import ModalResetConfirm from './ModalResetConfirm';
import { NOTIFICATION_TIMEOUT, themeColorStorageKey } from '../../../constants/defaultValues';
import { EMPTY_DESIGN_CONFIG } from './constants';
import {
  applyCustomDesign,
  deleteCustomDesign,
  getCustomDesign,
  putCustomDesign
} from '../../../redux/settings/actions';
import omit from 'lodash/omit';
import merge from 'lodash/merge';
import LoginPageTab from './Tabs/LoginPageTab';
import CabinetTab from './Tabs/CabinetTab';
import GeneralTab from './Tabs/GeneralTab';
import { CustomDesignConfig } from '../../../redux/settings/interface';
import { withTranslation } from 'react-i18next';
class CustomDesign extends React.Component<CustomDesignProps, CustomDesignState> {
  constructor(props: CustomDesignProps) {
    super(props);
    this.state = this.getInitialState();
    this.setFieldValue = () => {};
  }
  private setFieldValue: (field: string, value: any) => void;

  getInitialState = () => ({
    customDesign: this.props.actualDesign,
    designConfigNew: EMPTY_DESIGN_CONFIG,
    customDesignCurrent: this.props.actualDesign,
    errors: null,
    isResetModalOpen: false,
    isTabsValid: {},
    tabItems: [
      { label: 'loginPage', path: 'login-page' },
      { label: 'cabinet', path: 'cabinet' },
      { label: 'general', path: 'general' }
    ]
  });

  componentDidUpdate(
    prevProps: Readonly<CustomDesignProps>,
    prevState: Readonly<CustomDesignState>,
    snapshot?: any
  ): void {
    const { actualDesign } = this.props;
    const { error, saving } = actualDesign;
    const { designConfigNew } = this.state;

    if (prevProps.actualDesign.saving && !saving && !error) {
      NotificationManager.info('Изменения сохранены', null, NOTIFICATION_TIMEOUT, null, null, '');
    }

    if (!prevProps.actualDesign.error && error) {
      NotificationManager.info(error, 'Ошибка', NOTIFICATION_TIMEOUT, null, null, '');
    }

    if (actualDesign.data && prevProps.actualDesign !== actualDesign) {
      this.setState({ customDesignCurrent: actualDesign });
    }

    if (designConfigNew !== prevState.designConfigNew && designConfigNew !== EMPTY_DESIGN_CONFIG) {
      this.setState({
        customDesignCurrent: {
          ...actualDesign,
          data: merge({}, actualDesign.data, this.state.designConfigNew)
        }
      });
    }
  }

  setImage = (
    file: DropzoneFile,
    field: string,
    dropzone: Dropzone,
    setFieldValue: (field: string, value: any) => void
  ): void => {
    if (this.state.designConfigNew.imageFiles && field in this.state.designConfigNew.imageFiles) {
      dropzone.removeFile(this.state.designConfigNew.imageFiles[field]);
    }
    setFieldValue('imageFiles', {
      ...this.state.designConfigNew.imageFiles,
      ...{ [field]: file }
    });
    const reader = new FileReader();
    reader.onload = (event: ProgressEvent<FileReader>) => {
      if (event && event.target) {
        setFieldValue('imagesBase64', {
          ...this.state.designConfigNew.imagesBase64,
          ...{ [field]: event.target.result }
        });
      }
    };
    reader.readAsDataURL(file);
  };

  removeImage = (field: string, setFieldValue: (field: string, value: any) => void): void => {
    setFieldValue('imageFiles', omit(this.state.designConfigNew.imageFiles, [field]));
    setFieldValue('imagesBase64', omit(this.state.designConfigNew.imagesBase64, [field]));
  };

  showResetModal = (): void => {
    this.setState({ isResetModalOpen: true });
  };

  resetDesign = (): void => {
    this.props.deleteCustomDesign(window.location.hostname);
    this.props.applyCustomDesign(EMPTY_DESIGN_CONFIG);
    this.setState(this.getInitialState());
  };

  saveCustomDesign = (): void => {
    this.props.putCustomDesign({
      ...this.state.designConfigNew,
      partnerID: this.props.selectedMerchantsId ? this.props.selectedMerchantsId[0] : 0
    });
    const currentTheme = localStorage.getItem(themeColorStorageKey);
    setTimeout(() => {
      if (
        currentTheme &&
        this.state.designConfigNew.settings &&
        'theme' in this.state.designConfigNew.settings &&
        this.state.designConfigNew.settings.theme !== currentTheme
      ) {
        localStorage.setItem(themeColorStorageKey, this.state.designConfigNew.settings.theme);
        window.location.reload();
      }
    }, 500);
  };

  setTabSettings = (designConfigNew: CustomDesignConfig, isTabValid: boolean, tabName: string): void => {
    const { isTabsValid } = this.state;
    this.setState({
      designConfigNew: {
        ...this.state.designConfigNew,
        ...designConfigNew
      },
      isTabsValid: {
        ...isTabsValid,
        [tabName]: isTabValid
      }
    });
  };

  isValid = (): boolean => {
    const { isTabsValid } = this.state;
    return (
      isTabsValid === {} ||
      Object.values(isTabsValid).every((element) => {
        return !!element;
      })
    );
  };

  render(): ReactNode {
    const { match, t } = this.props;
    const { isResetModalOpen, customDesignCurrent, tabItems } = this.state;
    const formControlButtons = (
      <Fragment>
        <Button color="primary" className="btn-block" onClick={this.saveCustomDesign} disabled={!this.isValid()}>
          {t('button.save')}
        </Button>
        <Colxx xxs="12" className="d-flex p-0">
          <Button
            color="success btn-outline-primary text-dark"
            className="btn-block mt-2 mr-1"
            onClick={() => this.setState(this.getInitialState())}
          >
            {t('Reset preview')}
          </Button>
          <Button className="btn-block btn-reset" onClick={this.showResetModal}>
            {t('Reset all')}
          </Button>
        </Colxx>
      </Fragment>
    );

    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.settings" match={match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>

        <Row>
          <Colxx xxs="12">
            <Card className="mb-5">
              <CardBody>
                <Nav className="nav-pills nav-fill">
                  {tabItems.map((menuItem: { label: string; path: string }) => (
                    <Colxx key={menuItem.path} xxs="12" lg="3">
                      <NavItem key={menuItem.path}>
                        <NavLink
                          className="payouts nav-link px-0 text-lg-center"
                          isActive={() => window.location.pathname.split('/').includes(menuItem.path)}
                          to={`${match.url}/${menuItem.path}`}
                        >
                          {t(`customDesign.${menuItem.label}`)}
                        </NavLink>
                      </NavItem>
                    </Colxx>
                  ))}
                </Nav>
              </CardBody>
            </Card>
          </Colxx>
        </Row>

        <Switch>
          <Redirect exact from={match.url} to={`${match.url}/login-page`} />
          <Route exact path={`${match.url}/login-page`}>
            <LoginPageTab
              currentDesign={customDesignCurrent}
              setTabSettings={this.setTabSettings}
              setImage={this.setImage}
              removeImage={this.removeImage}
            >
              {formControlButtons}
            </LoginPageTab>
          </Route>
          <Route exact path={`${match.url}/cabinet`}>
            <CabinetTab
              currentDesign={customDesignCurrent}
              setTabSettings={this.setTabSettings}
              setImage={this.setImage}
              removeImage={this.removeImage}
            >
              {formControlButtons}
            </CabinetTab>
          </Route>
          <Route exact path={`${match.url}/general`}>
            <GeneralTab
              currentDesign={customDesignCurrent}
              setTabSettings={this.setTabSettings}
              setImage={this.setImage}
              removeImage={this.removeImage}
            >
              {formControlButtons}
            </GeneralTab>
          </Route>
        </Switch>

        {isResetModalOpen && (
          <ModalResetConfirm
            onConfirmModal={this.resetDesign}
            onCloseModal={() => this.setState({ isResetModalOpen: false })}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { selectedMerchantsId } = settings;
  const actualDesign = settings.customDesign;
  return { actualDesign, selectedMerchantsId };
};

const mapDispatchToProps = { putCustomDesign, getCustomDesign, deleteCustomDesign, applyCustomDesign };
export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CustomDesign)
);
