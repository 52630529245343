import React from 'react';
import format from '../../localization';

import { CellInfo } from 'react-table';
import { DATE_TIME_FORMAT, DATE_FORMAT_BASIC } from '../../constants/app';
import { CONFIG } from '../../config/appConfig';
import { Merchant } from '../settings/Permissions/interface';
import { DOCUMENT_TYPES, DOCUMENT_TYPE_OPTIONS } from './constants';

export const SERVICE_TYPES = (t: any) => ({
  1: t('Acquiring'),
  2: t('Mobile commerce'),
  3: t('Payouts')
});

export const getDownloadsTableColumns = (partners: Merchant[], t: any, userId?: number) => [
  {
    Header: t('menu.documents.id'),
    width: 100,
    accessor: 'id'
  },
  {
    Header: t('menu.documents.service'),
    width: 100,
    accessor: 'service_type',
    Cell: ({ value }: CellInfo) => SERVICE_TYPES(t)[value]
  },

  {
    Header: t('menu.documents.partner'),
    width: 200,
    accessor: 'partner_id',
    Cell: ({ value }: CellInfo) => {
      const currPartner = partners && partners.length && partners.find((partner: Merchant) => partner.id === value[0]);
      return currPartner ? currPartner.name : String(value[0]);
    }
  },
  {
    expander: true,
    width: 30,
    Expander: ({ isExpanded, ...rest }) => {
      if (rest.original.partner_id.length <= 1) {
        return null;
      } else {
        return (
          <div>
            {isExpanded ? <span className="simple-icon-arrow-up" /> : <span className="simple-icon-arrow-down" />}
          </div>
        );
      }
    },
    style: {
      fontSize: 10,
      padding: '0',
      textAlign: 'center',
      userSelect: 'none'
    }
  },
  {
    Header: t('dowloads.Document type'),
    width: 250,
    accessor: 'document_type',
    Cell: ({ value, original }: CellInfo) => {
      if (value === DOCUMENT_TYPE_OPTIONS.operations) {
        return `${DOCUMENT_TYPES(t)[DOCUMENT_TYPE_OPTIONS.operations]} -> ${SERVICE_TYPES(t)[original.service_type]}`;
      }

      return DOCUMENT_TYPES(t)[value] === undefined ? '' : DOCUMENT_TYPES(t)[value];
    }
  },
  {
    Header: t('dowloads.Request date'),
    accessor: 'created_at',
    Cell: ({ value }: CellInfo) => (
      <span data-tip={format(value, DATE_TIME_FORMAT)}>{format(value, DATE_FORMAT_BASIC)}</span>
    )
  },
  {
    Header: t('dowloads.End date'),
    accessor: 'updated_at',
    Cell: ({ value }: CellInfo) => (
      <span data-tip={format(value, DATE_TIME_FORMAT)}>{format(value, DATE_FORMAT_BASIC)}</span>
    )
  },
  {
    Header: t('dowloads.Status'),
    accessor: 'id',
    Cell: ({ value, original }: CellInfo) => {
      if (original.error) {
        return t('dowloads.failed');
      } else if (original.updated_at) {
        return t('dowloads.processed');
      } else {
        return t('dowloads.processing');
      }
    }
  },
  {
    Header: t('disposals.button.download file'),
    accessor: 'id',
    Cell: ({ value, original }: CellInfo) => {
      if (!original.error && original.updated_at) {
        return (
          <a
            href={`${CONFIG.apiURL()}${userId ? `/users/${userId}/csv/${value}/` : `/user/csv/${value}/`}`}
            className="btn pl-0"
            download={true}
          >
            <i className="simple-icon-cloud-download left-text-icon" />
          </a>
        );
      }

      return null;
    }
  }
];

export const compareDownloadsById = (item1, item2) => {
  if (item1.id > item2.id) {
    return -1;
  }
  if (item1.id < item2.id) {
    return 1;
  }
  return 0;
};
