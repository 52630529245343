import React, { Component, ReactNode } from 'react';
import {
  Pagination,
  PaginationItem,
  PaginationLink,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Button
} from 'reactstrap';
import { intFieldValue } from '../views/operations/helpers';
import { Field, Form, Formik } from 'formik';
import { EMPTY_FIELD_ERROR } from '../constants/app';
import FormGroup from 'reactstrap/lib/FormGroup';
import { withTranslation } from 'react-i18next';

class DataTablePagination extends Component<any, any> {
  constructor(props) {
    super(props);

    this.getSafePage = this.getSafePage.bind(this);
    this.changePage = this.changePage.bind(this);
    this.applyPage = this.applyPage.bind(this);
    this.pageClick = this.pageClick.bind(this);
    this.renderPages = this.renderPages.bind(this);
    this.changePageSize = this.changePageSize.bind(this);
    this.renderPageJump = this.renderPageJump.bind(this);

    this.state = {
      page: props.page,
      pageSize: this.props.defaultPageSize,
      currentPageInInput: ''
    };
  }

  componentDidUpdate(prevProps: any): void {
    if (this.props.pages && this.props.pages !== prevProps.pages) {
      this.setCurrentPageInInput('');
    }
  }

  static getDerivedStateFromProps(props) {
    return { page: props.page };
  }

  getSafePage(page) {
    if (Number.isNaN(page)) {
      page = this.props.page;
    }
    return Math.min(Math.max(page, 0), this.props.pages - 1);
  }

  changePageSize(size) {
    this.props.onPageSizeChange(size);
    this.setState({ pageSize: size });
  }

  changePage(page) {
    page = this.getSafePage(page);
    page && this.setState({ page });
    if (this.props.page !== page) {
      this.props.onPageChange(page);
    }
  }

  applyPage(e) {
    if (e) {
      e.preventDefault();
    }
    const { page } = this.state;
    this.changePage(page === '' ? this.props.page : page);
  }

  pageClick(pageIndex) {
    this.changePage(pageIndex);
  }

  renderPages() {
    const pageCount = this.props.pages;
    const currentPage = this.state.page;
    const pageButtons: ReactNode[] = [];
    const maxDisplayPages = 15;
    const maxDisplayGroupPages = 5;
    if (pageCount > maxDisplayPages) {
      if (currentPage + 1 < maxDisplayGroupPages) {
        for (let i = 0; i < maxDisplayGroupPages; i++) {
          pageButtons.push(
            <PaginationItem tag="div" key={i} active={currentPage === i}>
              <PaginationLink onClick={() => this.pageClick(i)}>{i + 1}</PaginationLink>
            </PaginationItem>
          );
        }
        pageButtons.push(<div key={'dot'} className="text-muted pagination-dots" />);
        pageButtons.push(
          <PaginationItem tag="div" key={pageCount} active={false}>
            <PaginationLink onClick={() => this.pageClick(pageCount)}>{pageCount}</PaginationLink>
          </PaginationItem>
        );
      } else if (currentPage >= pageCount - 4) {
        pageButtons.push(
          <PaginationItem tag="div" key={0} active={currentPage === 0}>
            <PaginationLink onClick={() => this.pageClick(0)}>{1}</PaginationLink>
          </PaginationItem>
        );
        pageButtons.push(<div className="text-muted pagination-dots" />);
        for (let i = pageCount - maxDisplayGroupPages; i < pageCount; i++) {
          pageButtons.push(
            <PaginationItem tag="div" key={i} active={currentPage === i}>
              <PaginationLink onClick={() => this.pageClick(i)}>{i + 1}</PaginationLink>
            </PaginationItem>
          );
        }
      } else {
        pageButtons.push(
          <PaginationItem tag="div" key={0} active={currentPage === 0}>
            <PaginationLink onClick={() => this.pageClick(0)}>{1}</PaginationLink>
          </PaginationItem>
        );
        pageButtons.push(<div className="text-muted pagination-dots" />);
        for (let i = currentPage - 2; i < currentPage + 3; i++) {
          pageButtons.push(
            <PaginationItem tag="div" key={i} active={currentPage === i}>
              <PaginationLink onClick={() => this.pageClick(i)}>{i + 1}</PaginationLink>
            </PaginationItem>
          );
        }
        pageButtons.push(<div className="text-muted pagination-dots" />);
        pageButtons.push(
          <PaginationItem tag="div" key={pageCount} active={false}>
            <PaginationLink onClick={() => this.pageClick(pageCount)}>{pageCount}</PaginationLink>
          </PaginationItem>
        );
      }
    } else {
      for (let i = 0; i < pageCount; i++) {
        const active = this.state.page === i;
        pageButtons.push(
          <PaginationItem tag="div" key={i} active={active}>
            <PaginationLink onClick={() => this.pageClick(i)}>{i + 1}</PaginationLink>
          </PaginationItem>
        );
      }
    }
    return pageButtons;
  }

  renderPageJump() {
    const { pages } = this.props;
    const pageNumbers: any = [];
    for (let i = 0; i < pages; i++) {
      pageNumbers.push(
        <DropdownItem key={i} onClick={() => this.changePage(i)}>
          {i + 1}
        </DropdownItem>
      );
    }
    return pageNumbers;
  }

  jumpPage = (values) => {
    const { currentPageInInput } = this.state;
    currentPageInInput && this.changePage(currentPageInInput - 1);
  };

  validatePageNumber = (values) => {
    const errors: { [key: string]: string } = {};
    const { pages, t } = this.props;
    const { currentPageInInput } = this.state;

    if (!currentPageInInput) {
      errors.dateFrom = EMPTY_FIELD_ERROR;
    }

    if (currentPageInInput < 1 || currentPageInInput > pages) {
      errors['pageNumber'] = t('datatable.wrong_page_number');
    }

    return errors;
  };

  setCurrentPageInInput = (value) => {
    this.setState({
      currentPageInInput: value
    });
  };

  render() {
    const { page, pages, canPrevious, canNext, pageSizeOptions, showPageSizeOptions, showPageJump, t } = this.props;

    return (
      <div className="d-flex justify-content-between mt-4">
        {showPageJump && (
          <Formik
            initialValues={{ pageNumber: '' }}
            onSubmit={(newValues: any) => this.jumpPage(newValues)}
            validate={this.validatePageNumber}
          >
            {({ values, setFieldValue, errors, touched, isValid, setFieldTouched }) => (
              <Form>
                <div className="pt-2 pagination-page-control">
                  <span>{t('datatable.page')}</span>
                  <FormGroup>
                    <Field
                      className="form-control page-number"
                      type="text"
                      name="pageNumber"
                      value={this.state.currentPageInInput}
                      onChange={({ target: { value } }) => {
                        intFieldValue(value, 'pageNumber', setFieldValue);
                        this.setCurrentPageInInput(value);
                      }}
                      onBlur={() => setFieldTouched('pageNumber', true)}
                    />
                    {errors.pageNumber && touched.pageNumber && values.pageNumber && (
                      <div className="invalid-feedback d-block">{errors.pageNumber}</div>
                    )}
                  </FormGroup>
                  <span>{t('datatable.of')}</span>
                  <span> {pages} </span>
                  <Button color="primary" type="submit" hidden={!this.state.currentPageInInput} disabled={!isValid}>
                    {t('datatable.button')}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        )}

        <Pagination listTag="div" className="mb-0 d-none d-md-block" size="sm">
          <PaginationItem tag="div" className={`${!canPrevious && 'disabled'}`}>
            <PaginationLink
              className="prev"
              onClick={() => {
                if (!canPrevious) return;
                this.changePage(page - 1);
              }}
              disabled={!canPrevious}
            >
              <i className="simple-icon-arrow-left" />
            </PaginationLink>
          </PaginationItem>

          {this.renderPages()}

          <PaginationItem tag="div" className={`${!canNext && 'disabled'}`}>
            <PaginationLink
              className="next"
              onClick={() => {
                if (!canNext) return;
                this.changePage(page + 1);
              }}
              disabled={!canNext}
            >
              <i className="simple-icon-arrow-right" />
            </PaginationLink>
          </PaginationItem>
        </Pagination>

        {showPageSizeOptions && (
          <div className="pt-2">
            <span className="text-muted text-small mr-1">Количество элементов </span>
            <UncontrolledDropdown className="d-inline-block">
              <DropdownToggle caret color="outline-primary" size="xs">
                {this.state.pageSize}
              </DropdownToggle>
              <DropdownMenu right>
                {pageSizeOptions.map((size, index) => (
                  <DropdownItem key={index} onClick={() => this.changePageSize(size)}>
                    {size}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(DataTablePagination);
