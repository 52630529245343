import React, { Component, Fragment, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardTitle, Row } from 'reactstrap';
import ReactTable from 'react-table';

import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import { NotFoundMessage } from '../../../components/common/NotFoundMessage';
import Breadcrumb from '../../../containers/navs/Breadcrumb';
import { Loader } from '../../../components/common/Loader';
import { getServicesByMerchant } from '../../../redux/services/actions';
import { getServiceTypeDocs } from '../../../redux/glossary/actions';
import { NotificationManager } from '../../../components/common/react-notifications';
import { withTranslation } from 'react-i18next';

import { CONFIG } from '../../../config/appConfig';
import {
  getTableColumns,
  SERVICE_TYPES_CODES,
  SERVICE_TYPES_NAMES,
  SERVICE_TYPES_BY_NAME,
  SERVICE_TYPES_NAMES_BY_SLUGS
} from './constant';
import { NOTIFICATION_TIMEOUT } from '../../../constants/defaultValues';
import { reduceData } from './helpers';
import { getPayoutAccounts } from '../../../redux/operations/actions';
import isEqual from 'lodash/isEqual';

class ServicesList extends Component<any, any> {
  state = {
    serviceName: '',
    preparedData: {}
  };

  componentDidMount(): void {
    const { match, getPayoutAccountsAction, merchantIdList, user, selectedUser, getServiceDocsAction } = this.props;

    const serviceName = match.params.slug ? SERVICE_TYPES_NAMES_BY_SLUGS[match.params.slug] : '';
    const serviceTypeId = SERVICE_TYPES_BY_NAME[serviceName];

    getServiceDocsAction(serviceTypeId);
    this.setState({ serviceName });

    const userId =
      user.data && selectedUser && user.data.is_admin && user.data.id !== selectedUser.id ? selectedUser.id : undefined;
    if (merchantIdList && merchantIdList.length) {
      getPayoutAccountsAction(merchantIdList, userId);
    }
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>): void {
    const {
      data,
      error,
      match,
      getPayoutAccountsAction,
      merchantIdList,
      user,
      selectedUser,
      getServiceDocsAction,
      t
    } = this.props;

    const userId =
      user.data && selectedUser && user.data.is_admin && user.data.id !== selectedUser.id ? selectedUser.id : undefined;
    const serviceName = match.params.slug ? SERVICE_TYPES_NAMES_BY_SLUGS[match.params.slug] : '';
    const serviceId = Object.keys(SERVICE_TYPES_NAMES).find(
      (name: string) => SERVICE_TYPES_NAMES[name] === serviceName
    );
    const serviceIdType = serviceId ? SERVICE_TYPES_CODES[serviceId] : SERVICE_TYPES_CODES.INCOME;

    if ((prevState.serviceName !== serviceName || prevProps.data !== data) && data) {
      getServiceDocsAction(serviceIdType);
      const currValues = data[serviceIdType] ? data[serviceIdType] : {};
      const reducedData = reduceData(currValues, t);

      this.setState({ serviceName, preparedData: reducedData });
    }

    if (error && error !== prevProps.error) {
      NotificationManager.info(error, 'Ошибка', NOTIFICATION_TIMEOUT, null, null, '');
    }

    if (
      merchantIdList &&
      merchantIdList.length &&
      (!isEqual(prevProps.merchantIdList, merchantIdList) ||
        (selectedUser && prevProps.selectedUser && prevProps.selectedUser.id !== selectedUser.id))
    ) {
      getPayoutAccountsAction(merchantIdList, userId);
    }
  }

  getDocumentLink = (): string => {
    const { serviceName } = this.state;
    const serviceKey = Object.keys(SERVICE_TYPES_CODES).find((key: string) => SERVICE_TYPES_NAMES[key] === serviceName);

    return serviceKey
      ? `${CONFIG.apiURL()}/service-type-docs-archive/?service_type=${SERVICE_TYPES_CODES[serviceKey]}`
      : '';
  };

  render(): ReactNode {
    const { loading, match, data, serviceFiles, merchantIdList, t } = this.props;
    const { serviceName, preparedData } = this.state;

    const serviceTypeId = SERVICE_TYPES_BY_NAME[serviceName];
    const isServiceFileExist = serviceFiles[serviceTypeId] && serviceFiles[serviceTypeId].length;

    if (loading) {
      return <Loader />;
    }

    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.services" match={match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>

        {merchantIdList && merchantIdList.length > 0 && !serviceName && (
          <Row>
            <Colxx xxs="12" className="mb-4">
              <p>Выберите тип услуги</p>
            </Colxx>
          </Row>
        )}

        {Boolean(isServiceFileExist && preparedData && Object.keys(preparedData)) && (
          <Card key="documentation" className="mb-4">
            <CardBody>
              <CardTitle>{t('services')}</CardTitle>
              <Row>
                <Colxx xxs="12">
                  <a href={this.getDocumentLink()} className="btn pl-0" download={true}>
                    <i className="simple-icon-cloud-download left-text-icon" />
                    {t('services.download')}
                  </a>
                </Colxx>
              </Row>
            </CardBody>
          </Card>
        )}

        {!(merchantIdList && merchantIdList.length) && <NotFoundMessage text={t('no_partners_selected')} />}

        {merchantIdList &&
          merchantIdList.length > 0 &&
          data &&
          !loading &&
          serviceName &&
          !(preparedData && Object.keys(preparedData).length) && (
            <Row>
              <Colxx xxs="12" className="mb-4">
                <p>Услуг такого типа не подключено</p>
              </Colxx>
            </Row>
          )}

        {Object.keys(preparedData).map(
          (key: string, i: number) =>
            Boolean(
              preparedData[key] &&
                preparedData[key].data &&
                preparedData[key].data.length &&
                merchantIdList &&
                merchantIdList.length
            ) && (
              <Card key={i} className="mb-4">
                <CardBody>
                  <CardTitle>{key}</CardTitle>
                  <ReactTable
                    data={preparedData[key].data}
                    columns={getTableColumns(t)}
                    pageSize={preparedData[key].data.length}
                    defaultPageSize={0}
                    sortable={false}
                    filterable={false}
                    showPagination={false}
                    showPageJump={false}
                    showPageSizeOptions={false}
                    loading={loading}
                    loadingText=""
                    noDataText="Нет данных"
                  />
                </CardBody>
              </Card>
            )
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ operations, glossary, settings, authUser }) => {
  const { payoutAccounts } = operations;
  const { user } = authUser;

  return {
    data: payoutAccounts.data,
    loading: payoutAccounts.loading,
    error: payoutAccounts.error,
    merchantIdList: settings.selectedMerchantsId,
    serviceFiles: glossary.services.data,
    selectedUser: settings.selectedUser,
    user
  };
};

const mapDispatchToProps = {
  getServicesByMerchantAction: getServicesByMerchant,
  getServiceDocsAction: getServiceTypeDocs,
  getPayoutAccountsAction: getPayoutAccounts
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ServicesList)
);
