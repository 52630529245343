import { createStore, applyMiddleware, compose, Store } from 'redux';
import createSagaMiddleware from 'redux-saga';

import reducers from './reducers';
import sagas from './sagas';
import { AppStore } from './interface';
import { socketMiddleware } from './notifications/middleware';

const sagaMiddleware = createSagaMiddleware();
const newSocketMiddleware = socketMiddleware();

const middlewares = [newSocketMiddleware, sagaMiddleware];
type composeType = typeof compose;

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: composeType;
  }
}

export function configureStore(initialState: AppStore): Store {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    reducers,
    initialState,
    compose(
      applyMiddleware(...middlewares),
      composeEnhancers()
    )
  );

  sagaMiddleware.run(sagas);

  return store;
}

export const reduxStore = configureStore({} as AppStore);
