import { all, call, fork, put, takeLatest, SimpleEffect } from 'redux-saga/effects';
import { CombinatorEffect } from '@redux-saga/types';

import { ApiRequest, ContentType } from '../../helpers/ApiRequest/ApiRequest';
import { createQueryString } from '../../helpers/Utils';

import { GET_DOCUMENTS, getDocumentsSuccess, getDocumentsError } from '../actions';
import { GetDocumentsActions } from './interface';

function* getDocuments({ payload }: GetDocumentsActions): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { data, error } = yield call(
      ApiRequest.post,
      '/statements/',
      createQueryString(payload),
      {},
      ContentType.ApplicationFormUrlencoded
    );

    if (error) {
      yield put(getDocumentsError(`${error}`));
    } else {
      yield put(getDocumentsSuccess(data));
    }
  } catch (error) {
    yield put(getDocumentsError(`${error}`));
  }
}

export function* watchGetDocuments(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(GET_DOCUMENTS, getDocuments);
}

export default function* rootSaga(): Generator<CombinatorEffect<string, any>, any, any> {
  yield all([fork(watchGetDocuments)]);
}
