import React, { PureComponent, ReactNode } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ModalFileConfirmProps } from '../../app/interface';
import { themeColorStorageKey } from '../../../constants/defaultValues';
import { withTranslation } from 'react-i18next';

class ModalResetConfirm extends PureComponent<ModalFileConfirmProps> {
  onConfirm = () => {
    const { onCloseModal, onConfirmModal } = this.props;

    onConfirmModal();
    onCloseModal();
    localStorage.removeItem(themeColorStorageKey);

    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  render(): ReactNode {
    const { onCloseModal, t } = this.props;

    return (
      <Modal isOpen={true} toggle={onCloseModal}>
        <ModalHeader toggle={onCloseModal}>{t('customDesign.modalTitle')}</ModalHeader>
        <ModalBody>{t('customDesign.modalText')}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.onConfirm}>
            {t('customDesign.modalConfirm')}
          </Button>{' '}
          <Button color="secondary" onClick={onCloseModal}>
            {t('customDesign.modalCancel')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default withTranslation()(ModalResetConfirm);
