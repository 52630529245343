import React, { Component, Fragment, ReactNode } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import { Formik, Form } from 'formik';
import { Row, Card, CardBody, Button, FormGroup, Label, Input } from 'reactstrap';
import Select from 'react-select';
import isEqual from 'lodash/isEqual';

import {
  getDestinations,
  getPayoutAccounts,
  getCurrencies,
  getPeriodSummaryStatistic,
  resetPeriodSummaryStatistic,
  getPeriodSummaryStatisticFile
} from '../../../redux/actions';
import DateRangePicker from '../../../components/common/DateRangePicker/DateRangePicker';
import { NotificationManager } from '../../../components/common/react-notifications';
import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import { NotFoundMessage } from '../../../components/common/NotFoundMessage';
import DataTablePagination from '../../../components/DatatablePagination';
import Breadcrumb from '../../../containers/navs/Breadcrumb';
import { Loader } from '../../../components/common/Loader';

import { SelectorOption } from '../../../interfaces/app';
import { NOTIFICATION_TIMEOUT } from '../../../constants/defaultValues';
import {
  chartValidation,
  directionsOptions,
  getFieldErrorsCount,
  prepareDateByPeriod,
  scrollToTarget,
  setDirectionsData
} from '../../../helpers/Utils';
import {
  STATISTICS_PERIOD_OPTIONS,
  STATISTICS_SERVICES_OPTIONS,
  STATISTICS_INITIAL_VALUES,
  StatisticType,
  NOT_SELECTED_CURRENCY_OPTION
} from '../constants';
import { TIMEZONE_TOOLTIP_TEXT } from '../../../constants/app';
import {
  getDateFrom,
  getDirectionsOptions,
  getCurrenciesOptions,
  getActiveCurrencies,
  prepareStatisticParams,
  getAccounts
} from '../helpers';

import ModalFileConfirm from '../../app/ModalFileConfirm';
import ModalFileRequired from '../../app/ModalFileIsRequired';
import ModalFileIsCreated from '../../app/ModalFileIsCreated';
import { StatisticsServiceSelectOption } from '../interface';
import { PeriodSummaryProps, PeriodSummaryState } from './interface';
import { getPeriodSummaryData, DEFAULT_SORTER } from './helpers';
import { CustomDropdownTreeSelect } from '../../../components/common/CustomDropdownTreeSelect';
import { ServiceTypes } from '../../operations/OperationsList/constants';
import { withTranslation } from 'react-i18next';
// import { CurrenciesData } from '../../../redux/operations/interface';
// import { CONFIG } from '../../../config/appConfig';

class PeriodSummary extends Component<PeriodSummaryProps, PeriodSummaryState> {
  state: PeriodSummaryState = {
    page: 0,
    accountOptions: [],
    dateChanged: false,
    activeCurrencies: [],
    currencyValue: '',
    shouldReset: false,
    hideTable: false,
    fileRequired: false,
    smallFileRequired: false,
    values: {
      byAccount: true
    },
    fileCreated: false
  };
  TargetRef = React.createRef<HTMLDivElement>();
  setFieldValue;
  resetForm;
  serviceType;
  currentCurrency;

  componentDidMount(): void {
    const {
      merchantIdList,
      destinations,
      accounts,
      currencies,
      isAdmin,
      selectedUserId,
      authUserId,
      getCurrenciesAction,
      getDestinationsAction,
      getPayoutAccountsAction,
      t
    } = this.props;

    const isDestinationsExist = destinations && destinations.length;
    const isAccountsExist = accounts && accounts.data;
    const isCurrenciesExist = currencies && currencies.data && currencies.data;

    !isDestinationsExist && getDestinationsAction();

    if (!isAccountsExist && merchantIdList && merchantIdList.length) {
      getPayoutAccountsAction(
        merchantIdList,
        isAdmin && selectedUserId && authUserId !== selectedUserId ? selectedUserId : undefined
      );
    }
    isAccountsExist && this.setState({ accountOptions: getAccounts(this.serviceType, t, accounts.data) });
    !isCurrenciesExist && getCurrenciesAction();

    const activeCurrencies = getActiveCurrencies(
      currencies.data,
      STATISTICS_SERVICES_OPTIONS(t)[0].value.operationType
    );
    activeCurrencies && this.setState({ activeCurrencies });
  }

  componentDidUpdate(prevProps: Readonly<PeriodSummaryProps>, prevState: Readonly<PeriodSummaryState>): void {
    const {
      data,
      error,
      merchantIdList,
      currencies,
      selectedUserId,
      isAdmin,
      authUserId,
      accounts,
      getPayoutAccountsAction,
      resetPeriodSummaryStatisticAction,
      maxCount,
      fileLoading,
      t
    } = this.props;

    const { accountOptions } = this.state;

    if (error && error !== prevProps.error) {
      NotificationManager.info(error, 'Ошибка', NOTIFICATION_TIMEOUT, null, null, '');
    }

    if (prevProps.data !== data && data && maxCount) {
      scrollToTarget(this.TargetRef.current);
    }

    if (prevProps.fileLoading && !fileLoading && !error) {
      this.setState({ fileCreated: true });
    }

    if (prevProps.accounts !== accounts) {
      this.setState({ accountOptions: getAccounts(this.serviceType, t, accounts.data) });
    }

    if (!isEqual(prevState.accountOptions, accountOptions)) {
      this.setFieldValue && this.setFieldValue('account', accountOptions);
    }

    if (prevProps.currencies.data !== currencies.data) {
      const activeCurrenciesList = getActiveCurrencies(
        currencies.data,
        STATISTICS_SERVICES_OPTIONS(t)[0].value.operationType
      );
      activeCurrenciesList && this.setState({ activeCurrencies: activeCurrenciesList });
    }

    if (!isEqual(prevProps.merchantIdList, merchantIdList) || prevProps.selectedUserId !== selectedUserId) {
      resetPeriodSummaryStatisticAction();

      if (merchantIdList && merchantIdList.length) {
        getPayoutAccountsAction(
          merchantIdList,
          isAdmin && selectedUserId && authUserId !== selectedUserId ? selectedUserId : undefined
        );
      }
    }

    if (
      prevProps.merchantIdList &&
      prevProps.selectedUserId &&
      merchantIdList &&
      selectedUserId &&
      (!isEqual(prevProps.merchantIdList, merchantIdList) || prevProps.selectedUserId !== selectedUserId) &&
      merchantIdList &&
      merchantIdList.length
    ) {
      this.handleResetForm();
    }

    this.state.shouldReset && this.setState({ shouldReset: false });
  }

  componentWillUnmount(): void {
    this.props.resetPeriodSummaryStatisticAction();
  }

  handleResetForm = () => {
    const { currencies, t } = this.props;

    const activeCurrenciesList = getActiveCurrencies(
      currencies.data,
      STATISTICS_SERVICES_OPTIONS(t)[0].value.operationType
    );

    this.resetForm();
    this.setState({ shouldReset: true });
    activeCurrenciesList && this.setState({ activeCurrencies: activeCurrenciesList });
  };

  onSubmit = (values): void => {
    const { merchantIdList, isAdmin, authUserId, selectedUserId, accounts, getPeriodStatisticAction, t } = this.props;

    const period = values.period ? values.period.value : '';
    const dates = prepareDateByPeriod(values.dateFrom, values.dateTo, period);
    const userId = isAdmin && authUserId !== selectedUserId ? selectedUserId : undefined;
    this.setState(
      (state: PeriodSummaryState) => (values === this.state.values ? { ...this.state } : { ...this.state, values }),
      () => {
        const requestParams = prepareStatisticParams(values, merchantIdList, userId, accounts.data, t);
        getPeriodStatisticAction({
          ...requestParams,
          period,
          from: dates.dateFrom,
          to: dates.dateTo
        });
      }
    );

    this.setState({ currencyValue: values.currency.label, hideTable: false, page: 0 });
  };

  validate = (values): { [key: string]: string } => chartValidation(values, this.props.t);

  handleDateChanged = () => this.setState({ dateChanged: true });

  onServiceChange = (service: StatisticsServiceSelectOption) => {
    const { accounts, destinations, currencies, t } = this.props;

    const direction = getDirectionsOptions(service, destinations, t)[0];
    this.setFieldValue && this.setFieldValue('service', service);
    this.setFieldValue && this.setFieldValue('direction', direction);
    this.setFieldValue && this.setFieldValue('account', []);

    const activeCurrencies = getActiveCurrencies(currencies.data, service.value.operationType);
    if (service.key === ServiceTypes.Mc) {
      this.setFieldValue('currency', {
        label: activeCurrencies[0].name,
        value: activeCurrencies[0].id,
        key: activeCurrencies[0].id
      });
    } else {
      this.setFieldValue('currency', NOT_SELECTED_CURRENCY_OPTION(t));
    }

    this.setState({
      accountOptions: getAccounts(
        service.value.operationType,
        t,
        accounts.data,
        direction.value.source,
        activeCurrencies[0].name
      ),
      activeCurrencies
    });
  };

  onNextPage = (pageNumber: number) => {
    this.setState({ page: pageNumber });
  };

  onConfirmModal = (values: any) => () => {
    const {
      getPeriodStatisticFileAction,
      merchantIdList,
      accounts,
      isAdmin,
      authUserId,
      selectedUserId,
      t
    } = this.props;
    const period = values.period ? values.period.value : '';
    const dates = prepareDateByPeriod(values.dateFrom, values.dateTo, period);
    const userId = isAdmin && authUserId !== selectedUserId ? selectedUserId : undefined;

    merchantIdList &&
      this.setState({ fileRequired: false }, () => {
        const requestParams = prepareStatisticParams(values, merchantIdList, userId, accounts.data, t);
        getPeriodStatisticFileAction({
          ...requestParams,
          period,
          from: dates.dateFrom,
          to: dates.dateTo
        });
      });
  };

  onConfirmSmallFileModal = (values: any) => () => {
    const {
      getPeriodStatisticFileAction,
      merchantIdList,
      accounts,
      isAdmin,
      authUserId,
      selectedUserId,
      t
    } = this.props;
    const period = values.period ? values.period.value : '';
    const dates = prepareDateByPeriod(values.dateFrom, values.dateTo, period);
    const userId = isAdmin && authUserId !== selectedUserId ? selectedUserId : undefined;

    this.setState({ smallFileRequired: false }, () => {
      const requestParams = prepareStatisticParams(values, merchantIdList, userId, accounts.data, t);
      getPeriodStatisticFileAction({
        ...requestParams,
        period,
        from: dates.dateFrom,
        to: dates.dateTo
      });
    });
  };

  exportFile = () => {
    const { data } = this.props;
    if (data && data.length) {
      this.setState({ smallFileRequired: true });
    }
  };

  render(): ReactNode {
    const { loading, data, match, destinations, accounts, merchantIdList, maxPageSize, t } = this.props;
    const {
      accountOptions,
      dateChanged,
      activeCurrencies,
      page,
      currencyValue,
      shouldReset,
      fileRequired,
      smallFileRequired,
      fileCreated
    } = this.state;

    const currentPageData = data && data.length ? data : [];
    const showExportButton = Boolean(data && data.length);
    const pageSize = maxPageSize;

    return (
      <Fragment>
        {loading && <Loader />}

        <Row className="flex-shrink-0">
          <Colxx xxs="12">
            <Breadcrumb heading="menu.statistics" match={match} />
            <div className="time-tip  d-none d-lg-inline-block">{t(TIMEZONE_TOOLTIP_TEXT)}</div>
            <Separator className="mb-5" />
          </Colxx>
        </Row>

        {merchantIdList && merchantIdList.length > 0 && (
          <Formik
            initialValues={STATISTICS_INITIAL_VALUES(t)[StatisticType.PeriodSummary]}
            onSubmit={this.onSubmit}
            validate={this.validate}
          >
            {({ values, setFieldValue, errors, touched, setFieldTouched, resetForm }) => {
              this.setFieldValue = setFieldValue;
              this.resetForm = resetForm;
              this.serviceType = values.service.value.operationType;
              this.currentCurrency = values.currency;

              return (
                <>
                  <Form>
                    <Card className="mb-4">
                      <CardBody>
                        <Row>
                          <>
                            <Colxx xxs="12" sm="6">
                              <Row className="mb-3">
                                <Colxx xxs="12" sm="6">
                                  <div className="ml-2">
                                    <Input
                                      type="radio"
                                      id="byAccountRadioBtn"
                                      name="byAccount"
                                      checked={values.byAccount}
                                      className="radio-btn-align-center"
                                      onChange={() => {
                                        setFieldValue('byAccount', true);
                                        this.setState({ hideTable: true });
                                      }}
                                    />
                                    <Label for="byAccountRadioBtn" className="mb-1">
                                      {t('period.By customers')}
                                    </Label>
                                  </div>
                                </Colxx>
                                <Colxx xxs="12" sm="6">
                                  <div className="ml-2">
                                    <Input
                                      type="radio"
                                      id="byPeriodRadioBtn"
                                      name="byAccount"
                                      checked={!values.byAccount}
                                      className="radio-btn-align-center"
                                      onChange={() => {
                                        setFieldValue('byAccount', false);
                                        this.setState({ hideTable: true });
                                      }}
                                    />
                                    <Label for="byPeriodRadioBtn" className="mb-1">
                                      {t('period.By periods')}
                                    </Label>
                                  </div>
                                </Colxx>
                              </Row>
                            </Colxx>
                            <Colxx xxs="0" sm="6" />
                          </>
                          <DateRangePicker
                            dateFrom={values.dateFrom}
                            dateTo={values.dateTo}
                            period={values.period.value}
                            errors={errors}
                            touched={touched}
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                            handleDateChanged={this.handleDateChanged}
                          />
                          <Colxx xxs="12" sm="6" xl="3">
                            <FormGroup className="has-float-label">
                              <Label className="float-label">{t('period.Service')}</Label>
                              <Select
                                className="react-select"
                                classNamePrefix="react-select"
                                name="service"
                                value={values.service}
                                onChange={this.onServiceChange}
                                options={STATISTICS_SERVICES_OPTIONS(t)}
                                placeholder=""
                              />
                            </FormGroup>
                          </Colxx>
                          <Colxx xxs="12" sm="6" xl="3">
                            <FormGroup className="has-float-label">
                              <Label className="float-label">{t('period.Destination')}</Label>
                              <CustomDropdownTreeSelect
                                name="direction"
                                data={directionsOptions(values.service.key, destinations, t)}
                                shouldReset={shouldReset}
                                onChange={(changedValue, selectedValues) => {
                                  const direction = setDirectionsData(selectedValues);
                                  setFieldValue('direction', direction);

                                  const newAccountOptions = getAccounts(
                                    values.service.value.operationType,
                                    t,
                                    this.props.accounts.data,
                                    direction ? direction.value.source : undefined,
                                    this.currentCurrency.label
                                  );

                                  if (!isEqual(this.state.accountOptions, newAccountOptions)) {
                                    this.setState({ accountOptions: newAccountOptions });
                                  }
                                }}
                                disabled={values.service.key === ServiceTypes.Mc}
                              />
                              {errors.direction && <div className="invalid-feedback d-block">{errors.direction}</div>}
                            </FormGroup>
                          </Colxx>
                          <Colxx xxs="12" sm="6" xl="3">
                            <FormGroup className="has-float-label">
                              <Label for="currency" className="float-label">
                                {t('period.Currency')}
                              </Label>
                              <Select
                                className="react-select"
                                classNamePrefix="react-select"
                                name="currency"
                                value={values.currency}
                                onChange={(currency: SelectorOption) => {
                                  setFieldValue('currency', currency);
                                  this.setState({
                                    accountOptions: getAccounts(
                                      values.service.value.operationType,
                                      t,
                                      this.props.accounts.data,
                                      values.direction ? values.direction.value.source : undefined,
                                      currency.label
                                    )
                                  });
                                }}
                                options={getCurrenciesOptions(activeCurrencies, values.service.key, t, false)}
                                isDisabled={values.service.key === ServiceTypes.Mc}
                                placeholder=""
                              />
                              {errors.currency && <div className="invalid-feedback d-block">{errors.currency}</div>}
                            </FormGroup>
                          </Colxx>
                          <Colxx xxs="12" sm="6" xl="3">
                            <FormGroup className="has-float-label">
                              <Label className="float-label">{t('period.Period')}</Label>
                              <Select
                                id="period"
                                className="react-select"
                                classNamePrefix="react-select"
                                name="period"
                                value={values.period}
                                onChange={(period: SelectorOption) => {
                                  resetForm();
                                  setFieldValue('period', period);
                                  if (!dateChanged) {
                                    setFieldValue('dateFrom', getDateFrom(period.value));
                                    setFieldValue('dateTo', new Date());
                                  }
                                }}
                                options={STATISTICS_PERIOD_OPTIONS(t)}
                                placeholder=""
                              />
                            </FormGroup>
                          </Colxx>
                          <Colxx xxs="12" sm="6" xl="3">
                            <FormGroup className="has-float-label">
                              <Label className="float-label">{t('period.Merchant Account')}</Label>
                              <CustomDropdownTreeSelect
                                name="account"
                                shouldReset={shouldReset}
                                data={accountOptions}
                                onChange={(changedValue, selectedValues) => setFieldValue('account', selectedValues)}
                                onBlur={() => setFieldTouched('account')}
                                onFocus={() => setFieldTouched('account', false)}
                              />
                              {errors.account && <div className="invalid-feedback d-block">{errors.account}</div>}
                            </FormGroup>
                          </Colxx>
                          <Colxx xxs="12" className="d-flex justify-content-end">
                            {showExportButton && (
                              <Button
                                color="primary"
                                className="mr-3"
                                onClick={(e) => {
                                  this.exportFile();
                                }}
                              >
                                {t('period.button.download file')}
                              </Button>
                            )}
                            <Button disabled={!!getFieldErrorsCount(errors)} color="primary" type="submit">
                              {t('period.button.search')}
                            </Button>
                          </Colxx>
                        </Row>
                      </CardBody>
                    </Card>
                  </Form>

                  <div ref={this.TargetRef}>
                    {!this.state.hideTable && data && Object.keys(data).length > 0 && (
                      <Card className="mb-5">
                        <CardBody>
                          <ReactTable
                            data={currentPageData}
                            columns={getPeriodSummaryData(
                              data,
                              Object.values(accounts.data).flat(),
                              currencyValue,
                              values.byAccount,
                              t
                            )}
                            page={page}
                            pages={Math.ceil(data.length / maxPageSize)}
                            pageSize={pageSize}
                            defaultPageSize={pageSize}
                            sortable={true}
                            defaultSorted={DEFAULT_SORTER}
                            filterable={false}
                            multiSort={false}
                            minRows={0}
                            showPageJump={true}
                            showPagination={true}
                            showPageSizeOptions={false}
                            PaginationComponent={DataTablePagination}
                            loading={loading}
                            onPageChange={this.onNextPage}
                            loadingText=""
                            noDataText={t('select.no_data')}
                          />
                        </CardBody>
                      </Card>
                    )}
                  </div>
                </>
              );
            }}
          </Formik>
        )}

        {!(merchantIdList && merchantIdList.length) && <NotFoundMessage text={t('no_partners_selected')} />}

        {smallFileRequired && (
          <ModalFileConfirm
            onCloseModal={() => this.setState({ smallFileRequired: false })}
            onConfirmModal={this.onConfirmSmallFileModal(this.state.values)}
          />
        )}

        {fileRequired && (
          <ModalFileRequired
            onCloseModal={() => this.setState({ fileRequired: false })}
            onConfirmModal={this.onConfirmModal(this.state.values)}
          />
        )}

        {fileCreated && <ModalFileIsCreated onCloseModal={() => this.setState({ fileCreated: false })} />}

        {data && !Object.keys(data).length && <NotFoundMessage />}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ statistics, settings, authUser, operations }) => ({
  isAdmin: authUser.user.data && authUser.user.data.is_admin ? authUser.user.data.is_admin : false,
  selectedUserId: settings.selectedUser ? settings.selectedUser.id : 0,
  authUserId: authUser.user.data ? authUser.user.data.id : 0,
  data: statistics.periodSummary.data,
  currency: statistics.periodSummary.currency,
  loading: statistics.periodSummary.loading,
  error: statistics.periodSummary.error,
  maxPageSize: settings.gcmSettings.data ? settings.gcmSettings.data.max_page_size : 50,
  merchantIdList: settings.selectedMerchantsId,
  destinations: operations.destinations.data,
  accounts: operations.payoutAccounts,
  currencies: settings.currencies,
  maxCount: settings.gcmSettings.data ? settings.gcmSettings.data.max_display_records : null,
  fileLoading: statistics.periodSummaryFile.fileLoading
});

const mapDispatchToProps = {
  getDestinationsAction: getDestinations,
  getCurrenciesAction: getCurrencies,
  getPayoutAccountsAction: getPayoutAccounts,
  getPeriodStatisticAction: getPeriodSummaryStatistic,
  resetPeriodSummaryStatisticAction: resetPeriodSummaryStatistic,
  getPeriodStatisticFileAction: getPeriodSummaryStatisticFile
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PeriodSummary as any)
);
