import React, { Component, ReactNode } from 'react';
import { Row } from 'reactstrap';
import ReactTable from 'react-table';
import isEqual from 'lodash/isEqual';

import { Colxx } from '../../../components/common/CustomBootstrap';
import { NotFoundMessage } from '../../../components/common/NotFoundMessage';

import { getAccountTableColumn, SCROLL_TABLE_STYLE } from './constants';
import { StatementItem } from '../../../redux/disposals/interface';
import { AccountToAccountProps } from '../interface';
import { withTranslation } from 'react-i18next';

class AccountToAccount extends Component<AccountToAccountProps> {
  selectAccount = (account: StatementItem) => this.props.onAccountSelect(account);

  selectAccount2 = (account: StatementItem) => this.props.onAccount2Select(account);

  shouldComponentUpdate(nextProps: Readonly<AccountToAccountProps>): boolean {
    return !isEqual(nextProps, this.props);
  }

  render(): ReactNode {
    const { accounts, accounts2, currencies, t } = this.props;

    return (
      <>
        <Row>
          <Colxx xxs="12" className="mb-4">
            <Row className="d-flex align-content-center">
              <Colxx xxs="12" md="6" className="pl-4">
                {accounts2 && accounts2.length > 0 && (
                  <ReactTable
                    className="disposals-table"
                    data={accounts2}
                    columns={getAccountTableColumn(this.selectAccount2, currencies, t, true)}
                    pageSize={accounts2 && accounts2.length ? accounts2.length : 0}
                    sortable={false}
                    filterable={false}
                    showPageJump={false}
                    showPagination={false}
                    showPageSizeOptions={false}
                    style={SCROLL_TABLE_STYLE}
                    loadingText=""
                    noDataText="Нет данных"
                  />
                )}
                {accounts2 && !accounts2.length && <NotFoundMessage text="Нет данных аккаунтов" />}
              </Colxx>

              <Colxx xxs="12" md="6" className="pl-4">
                {accounts && accounts.length > 0 && (
                  <ReactTable
                    className="disposals-table"
                    data={accounts}
                    columns={getAccountTableColumn(this.selectAccount, currencies, t)}
                    pageSize={accounts && accounts.length ? accounts.length : 0}
                    sortable={false}
                    filterable={false}
                    showPageJump={false}
                    showPagination={false}
                    showPageSizeOptions={false}
                    style={SCROLL_TABLE_STYLE}
                    loadingText=""
                    noDataText="Нет данных"
                  />
                )}
                {accounts && !accounts.length && <NotFoundMessage text="Нет данных аккаунтов" />}
              </Colxx>
            </Row>
          </Colxx>
        </Row>
      </>
    );
  }
}

export default withTranslation()(AccountToAccount);
