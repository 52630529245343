import {
  GET_SUMMARY_STATISTICS,
  GET_SUMMARY_STATISTICS_SUCCESS,
  GET_SUMMARY_STATISTICS_ERROR,
  RESET_SUMMARY_STATISTICS,
  GET_CUSTOMER_STATISTICS,
  GET_CUSTOMER_STATISTICS_SUCCESS,
  GET_CUSTOMER_STATISTICS_ERROR,
  RESET_CUSTOMER_STATISTICS,
  GET_CHARTS,
  GET_CHARTS_SUCCESS,
  GET_CHARTS_ERROR,
  RESET_CHARTS,
  GET_CONVERSION_CHART,
  GET_CONVERSION_CHART_SUCCESS,
  GET_CONVERSION_CHART_ERROR,
  RESET_CONVERSION_CHART,
  GET_PERIOD_SUMMARY_STATISTICS,
  RESET_PERIOD_SUMMARY_STATISTICS,
  GET_PERIOD_SUMMARY_STATISTICS_SUCCESS,
  GET_PERIOD_SUMMARY_STATISTICS_ERROR,
  GET_PERIOD_SUMMARY_STATISTICS_FILE,
  GET_PERIOD_SUMMARY_STATISTICS_FILE_ERROR,
  GET_PERIOD_SUMMARY_STATISTICS_FILE_SUCCESS
} from '../actions';

import { StatisticState } from './interface';
import { preparePeriodSummaryData } from '../../views/statistics/PeriodSummary/helpers';

const INIT_STATE: StatisticState = {
  summaryStatistics: {
    data: null,
    loading: false,
    error: '',
    showRefund: false
  },
  customerStatistics: {
    data: null,
    loading: false,
    error: ''
  },
  charts: {
    data: null,
    period: '',
    loading: false,
    error: '',
    showRefund: false
  },
  conversionChart: {
    data: null,
    period: '',
    status: [],
    loading: false,
    error: ''
  },
  periodSummary: {
    data: null,
    currency: '',
    loading: false,
    error: ''
  },
  periodSummaryFile: {
    data: null,
    fileLoading: false,
    loading: false,
    error: ''
  }
};

export default (state: StatisticState = INIT_STATE, { type, payload }): StatisticState => {
  switch (type) {
    // SUMMARY STATISTICS
    case GET_SUMMARY_STATISTICS:
      return {
        ...state,
        summaryStatistics: {
          ...state.summaryStatistics,
          loading: true,
          error: ''
        }
      };
    case GET_SUMMARY_STATISTICS_SUCCESS:
      return {
        ...state,
        summaryStatistics: {
          ...state.summaryStatistics,
          ...payload.summaryStatistics,
          loading: false
        }
      };
    case GET_SUMMARY_STATISTICS_ERROR:
      return {
        ...state,
        summaryStatistics: {
          ...state.summaryStatistics,
          error: payload.error,
          data: null,
          loading: false
        }
      };
    case RESET_SUMMARY_STATISTICS:
      return {
        ...state,
        summaryStatistics: {
          ...state.summaryStatistics,
          data: null,
          loading: false,
          error: '',
          showRefund: false
        }
      };

    // CUSTOMER_STATISTICS
    case GET_CUSTOMER_STATISTICS:
      return {
        ...state,
        customerStatistics: {
          ...state.customerStatistics,
          loading: true,
          error: ''
        }
      };
    case GET_CUSTOMER_STATISTICS_SUCCESS:
      return {
        ...state,
        customerStatistics: {
          ...state.customerStatistics,
          data: payload.data,
          loading: false
        }
      };
    case GET_CUSTOMER_STATISTICS_ERROR:
      return {
        ...state,
        customerStatistics: {
          ...state.customerStatistics,
          error: payload.error,
          data: null,
          loading: false
        }
      };
    case RESET_CUSTOMER_STATISTICS:
      return {
        ...state,
        customerStatistics: {
          error: '',
          data: null,
          loading: false
        }
      };

    // CHARTS
    case GET_CHARTS:
      return {
        ...state,
        charts: {
          ...state.charts,
          loading: true,
          error: ''
        }
      };
    case GET_CHARTS_SUCCESS:
      return {
        ...state,
        charts: {
          ...state.charts,
          ...payload.charts,
          loading: false
        }
      };
    case GET_CHARTS_ERROR:
      return {
        ...state,
        charts: {
          ...state.charts,
          data: null,
          error: payload.error,
          loading: false
        }
      };
    case RESET_CHARTS:
      return {
        ...state,
        charts: {
          ...state.charts,
          data: null,
          period: '',
          loading: false,
          error: '',
          showRefund: false
        }
      };

    // CONVERSION CHART
    case GET_CONVERSION_CHART:
      return {
        ...state,
        conversionChart: {
          ...state.conversionChart,
          loading: true,
          error: ''
        }
      };
    case GET_CONVERSION_CHART_SUCCESS:
      return {
        ...state,
        conversionChart: {
          ...state.conversionChart,
          ...payload.conversionChart,
          loading: false
        }
      };
    case GET_CONVERSION_CHART_ERROR:
      return {
        ...state,
        conversionChart: {
          ...state.conversionChart,
          error: payload.error,
          data: null,
          loading: false
        }
      };
    case RESET_CONVERSION_CHART:
      return {
        ...state,
        conversionChart: {
          ...state.conversionChart,
          data: null,
          loading: false,
          error: ''
        }
      };

    // PERIOD SUMMARY STATISTICS
    case GET_PERIOD_SUMMARY_STATISTICS:
      return {
        ...state,
        periodSummary: {
          ...state.periodSummary,
          loading: true,
          error: ''
        }
      };
    case GET_PERIOD_SUMMARY_STATISTICS_SUCCESS:
      return {
        ...state,
        periodSummary: {
          ...state.periodSummary,
          ...payload,
          data: preparePeriodSummaryData(payload.data, payload.period, payload.byAccount),
          loading: false
        }
      };
    case GET_PERIOD_SUMMARY_STATISTICS_ERROR:
      return {
        ...state,
        periodSummary: {
          ...state.periodSummary,
          data: null,
          error: payload.error,
          loading: false
        }
      };
    case RESET_PERIOD_SUMMARY_STATISTICS:
      return {
        ...state,
        periodSummary: {
          ...state.periodSummary,
          data: null,
          currency: '',
          loading: false,
          error: ''
        }
      };
    case GET_PERIOD_SUMMARY_STATISTICS_FILE:
      return {
        ...state,
        periodSummaryFile: {
          ...state.periodSummaryFile,
          loading: true,
          fileLoading: true,
          error: ''
        }
      };
    case GET_PERIOD_SUMMARY_STATISTICS_FILE_ERROR:
      return {
        ...state,
        periodSummaryFile: {
          ...state.periodSummaryFile,
          data: null,
          fileLoading: false,
          error: payload.error,
          loading: false
        }
      };
    case GET_PERIOD_SUMMARY_STATISTICS_FILE_SUCCESS:
      return {
        ...state,
        periodSummaryFile: {
          ...state.periodSummaryFile,
          data: null,
          fileLoading: false,
          error: '',
          loading: false
        }
      };
    default:
      return { ...state };
  }
};
