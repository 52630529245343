import React from 'react';

import { Message } from '../../../views/support/interface';
import { DATE_TIME_FORMAT } from '../../../constants/app';
import format from '../../../localization';

export const TextMessage = ({ message, create_time }: Message) => {
  return (
    <div className="sc-message--text">
      {message}
      <div className="sc-message--date">{format(create_time, DATE_TIME_FORMAT)}</div>
    </div>
  );
};
