import { TreeNode } from 'react-dropdown-tree-select';

import { Merchant } from '../../../redux/menu/interface';
import { Notification, GetNotificationsValues } from '../../../redux/notifications/interface';
import { ALL_TREE } from '../../../constants/app';

export const getPartnersOption = (partners: Merchant[], t: any): TreeNode[] => {
  if (!(partners && partners.length)) {
    return [];
  } else {
    return [
      {
        ...ALL_TREE(t),
        children: partners.map((partner: Merchant) => ({
          label: partner.name,
          value: partner.id
        }))
      }
    ];
  }
};

export const prepareNotificationRequestValues = (page = 0, sorting = '', pageSize = 50): GetNotificationsValues => ({
  offset: page * pageSize,
  limit: pageSize,
  sorting
});

export const readNotifications = (idList: number[], data: Notification[] | null): Notification[] | null => {
  if (!(idList && idList.length)) {
    return data;
  }
  const newData = data ? [...data] : [];

  data &&
    idList.forEach((itemId: number) => {
      data.forEach((notification: Notification, index: number) => {
        if (itemId === notification.id || itemId === notification.registry_id) {
          newData[index] = {
            ...notification,
            is_viewed: true
          };
        }
        return;
      });
    });

  return newData;
};
