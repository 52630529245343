import React, { Component, Fragment, ReactNode } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Row, Card, CardTitle, CardText } from 'reactstrap';

import { checkTmpCode, changePassword, getUser, googleAuth } from '../../../redux/actions';
import { NotificationManager } from '../../../components/common/react-notifications';
import { Colxx } from '../../../components/common/CustomBootstrap';
import { Loader } from '../../../components/common/Loader';
import GoogleAuthModal from '../Login/GoogleAuthModal';
import ModalCountDown from '../Login/ModalCountDown';
import SetNewPasswordForm from './SetNewPasswordForm';
import GoogleQrModal from '../Login/GoogleQrModal';
import RestorePassword from './RestorePassword';
import Restore2FA from './Restore2FA';
import { withTranslation } from 'react-i18next';
import { CookieAuthStatus, RESTORE_TYPES } from '../Login/constant';
import { NOTIFICATION_TIMEOUT } from '../../../constants/defaultValues';
import { ForgotPasswordProps, ForgotPasswordState } from './interface';
import { ROUTE_TMP_CODE_LABEL } from '../../../constants/app';

class ForgotPassword extends Component<ForgotPasswordProps, ForgotPasswordState> {
  state: ForgotPasswordState = {
    tmpCodeChecked: false,
    is2faModalOpen: false,
    updatePasswordAttributes: {},
    blockedUserMessage: ''
  };

  componentDidMount(): void {
    const tmpCode = new URLSearchParams(window.location.search).get(ROUTE_TMP_CODE_LABEL);

    if (tmpCode) {
      this.props.checkTmpCodeAction(tmpCode);
    }
  }

  componentDidUpdate(prevProps: Readonly<ForgotPasswordProps>, prevState: Readonly<ForgotPasswordState>): void {
    const { loading, error, checkTmpCodeState, profile, history, getUserAction, loginStatus, user, t } = this.props;
    const { tmpCodeChecked } = this.state;

    if (prevProps.checkTmpCodeState.loading && !checkTmpCodeState.loading && !checkTmpCodeState.error) {
      this.setState({ tmpCodeChecked: true });
    }

    if (
      prevProps.checkTmpCodeState.error !== checkTmpCodeState.error &&
      checkTmpCodeState.error &&
      !checkTmpCodeState.error.match(/{\d+}/)
    ) {
      NotificationManager.info(checkTmpCodeState.error, 'Ошибка', NOTIFICATION_TIMEOUT, null, null, '');
      this.setState({ tmpCodeChecked: false });
    } else if (checkTmpCodeState.error.match(/{\d+}/) && !prevState.blockedUserMessage) {
      this.setState({ is2faModalOpen: false, blockedUserMessage: checkTmpCodeState.error });
    }

    if (prevProps.profile.loading && !profile.loading && !profile.error) {
      if (!user.data) {
        getUserAction();
      }
      history.push('/');
    }

    if (
      prevProps.loginStatus === CookieAuthStatus.GoogleRegReq &&
      (loginStatus === CookieAuthStatus.Authorized || !loginStatus)
    ) {
      history.push('/');
    }

    if (prevProps.profile.error !== profile.error && profile.error && !profile.error.match(/{\d+}/)) {
      NotificationManager.info(profile.error, 'Ошибка', NOTIFICATION_TIMEOUT, null, null, '');
    } else if (profile.error.match(/{\d+}/) && !prevState.blockedUserMessage) {
      this.setState({ is2faModalOpen: false, blockedUserMessage: profile.error });
    }

    if (prevProps.loading && !loading && !error) {
      let message = t('password_recovery_notif');
      let title: string | null = t('success');
      if (tmpCodeChecked) {
        message = t('success');
        title = null;
      }
      NotificationManager.info(message, title, NOTIFICATION_TIMEOUT, null, null, '');
    }

    if (!prevProps.error && error && !error.match(/{\d+}/)) {
      NotificationManager.info(error, 'Ошибка', NOTIFICATION_TIMEOUT, null, null, '');
    } else if (error.match(/{\d+}/) && !prevState.blockedUserMessage) {
      this.setState({ is2faModalOpen: false, blockedUserMessage: error });
    }
  }

  secondFactorClose = (): void => {
    this.setState({ is2faModalOpen: false });
  };

  skipTwoFactorAuth = (): void => {
    const { secondFactorCancellationAction } = this.props;

    localStorage.removeItem('userLogin');
    localStorage.removeItem('tfs');
    secondFactorCancellationAction && secondFactorCancellationAction();
  };

  componentWillUnmount(): void {
    this.skipTwoFactorAuth();
  }

  updatePassword = (values, _2faCode = ''): void => {
    this.setState({ updatePasswordAttributes: values });
    const { changePasswordAction, loginStatus } = this.props;
    const tmpCode = new URLSearchParams(window.location.search).get(ROUTE_TMP_CODE_LABEL) || '';

    if (loginStatus === CookieAuthStatus.GoogleAuthReq && !_2faCode) {
      this.setState({ is2faModalOpen: true, blockedUserMessage: '' });
    } else {
      changePasswordAction({ ...values, tmpCode, _2faCode });
    }
  };

  getRestore2FAComponent(): ReactNode {
    return (
      <GoogleQrModal
        cancellation={() => {
          this.skipTwoFactorAuth();
          window.location.href = '/';
        }}
      />
    );
  }

  render(): ReactNode {
    const { loading, checkTmpCodeState, profile, loginStatus, googleAuthAction, location, t } = this.props;
    const { tmpCodeChecked, is2faModalOpen, updatePasswordAttributes, blockedUserMessage } = this.state;
    const restoreType = location && location.state ? location.state.restoreType || 0 : 0;

    return (
      <Row className="h-100">
        <Colxx xxs="12" sm="11" md="8" lg="6" className="mx-auto my-auto">
          {tmpCodeChecked && loginStatus === CookieAuthStatus.GoogleRegReq ? (
            this.getRestore2FAComponent()
          ) : (
            <Card className="auth-card">
              <div className="w-100 login-modal">
                {checkTmpCodeState.loading && <Loader />}

                {!checkTmpCodeState.loading && !checkTmpCodeState.error && (
                  <Fragment>
                    {!tmpCodeChecked && restoreType === RESTORE_TYPES.RestorePassword && (
                      <RestorePassword loading={loading} />
                    )}

                    {!tmpCodeChecked && restoreType === RESTORE_TYPES.Restore2FA && <Restore2FA loading={loading} />}

                    {tmpCodeChecked && loginStatus !== CookieAuthStatus.GoogleRegReq && (
                      <SetNewPasswordForm
                        loading={profile.loading}
                        onSubmit={(data: object) => this.updatePassword(data)}
                      />
                    )}

                    <GoogleAuthModal
                      isOpen={tmpCodeChecked && is2faModalOpen}
                      isShowForgotLink={false}
                      onSubmit={(_2faCode: string) => this.updatePassword(updatePasswordAttributes, _2faCode)}
                      googleAuthAction={googleAuthAction}
                      cancellation={this.secondFactorClose}
                      loading={loading}
                    />
                  </Fragment>
                )}
                {!checkTmpCodeState.loading && checkTmpCodeState.error && (
                  <Colxx xs="12">
                    <CardTitle>{t('link_outdated_header')}</CardTitle>
                    <CardText>{t('link_outdated_body')}</CardText>
                  </Colxx>
                )}
                {blockedUserMessage && (
                  <ModalCountDown
                    onCloseModal={() => {
                      this.setState({ blockedUserMessage: '' });
                    }}
                    message={blockedUserMessage}
                  />
                )}
                <div className="back-to-login">
                  <NavLink
                    to="#"
                    onClick={() => {
                      window.location.href = '/';
                    }}
                  >
                    {t('button.link')}
                  </NavLink>
                </div>
              </div>
            </Card>
          )}
        </Colxx>
      </Row>
    );
  }
}

const mapStateToProps = ({ authUser, profile }) => {
  const { loading, error, tmpPassRequested, userLogin, loginStatus, user } = authUser;

  return {
    loading,
    error,
    loginStatus,
    tmpPassRequested,
    userLogin,
    checkTmpCodeState: authUser.checkTmpCodeState,
    profile,
    user
  };
};

const mapDispatchToProps = {
  checkTmpCodeAction: checkTmpCode,
  changePasswordAction: changePassword,
  googleAuthAction: googleAuth,
  getUserAction: getUser
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ForgotPassword)
);
