import { CSSProperties } from 'react';
export const DOWNLOADS_DEFAULT_PAGE_SIZE = 10;

export const DOCUMENT_TYPES = (t: any) => ({
  1: t('Statistics_by period'),
  2: t('dowloads.Transactions'),
  3: t('settings.disposals')
});

export const DOCUMENT_TYPE_OPTIONS = {
  periodSummary: 1,
  operations: 2,
  disposals: 3
};

export const DEFAULT_SORTER = [
  {
    id: 'created_at',
    desc: true
  }
];

export const STYLE_SUB_COMPONENT: CSSProperties = {
  marginLeft: '195px',
  marginTop: '5px',
  listStyleType: 'none'
};
