import React, { Component, Fragment, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Button, Card, CardBody, FormGroup, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { Form, Formik } from 'formik';
import Select from 'react-select';
import InputMask from 'react-input-mask';
import { isAfter, isSameDay } from 'date-fns';
import isEqual from 'lodash/isEqual';

import {
  DATE_FORMAT_BASIC,
  DATE_MASK,
  EMPTY_FIELD_ERROR,
  MAX_PERCENT,
  TIMEZONE_TOOLTIP_TEXT
} from '../../../constants/app';
import DoughnutChart from '../../../components/charts/Doughnut';
import BarChart from '../../../components/charts/Bar';
import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import { NotificationManager } from '../../../components/common/react-notifications';
import { NotFoundMessage } from '../../../components/common/NotFoundMessage';
import { CustomDropdownTreeSelect } from '../../../components/common/CustomDropdownTreeSelect';
import { DatePickerLocaled } from '../../../components/common/DatePickerLocaled/DatePickerLocaled';
import { Loader } from '../../../components/common/Loader';
import Breadcrumb from '../../../containers/navs/Breadcrumb';
import {
  getCurrencies,
  getCustomerStatistics,
  getDestinations,
  getPayoutAccounts,
  resetCustomerStatistics
} from '../../../redux/actions';
import { directionsOptions, getFieldErrorsCount, scrollToTarget, setDirectionsData } from '../../../helpers/Utils';
import { NOTIFICATION_TIMEOUT } from '../../../constants/defaultValues';
import { withTranslation } from 'react-i18next';
import {
  getAccounts,
  getActiveCurrencies,
  getCurrenciesOptions,
  getDirectionsOptions,
  prepareStatisticParams
} from '../helpers';
import {
  STATISTICS_SERVICES_OPTIONS,
  DATE_FROM_TOOLTIP_TEXT,
  DATE_TO_TOOLTIP_TEXT,
  STATISTICS_INITIAL_VALUES,
  StatisticType
} from '../constants';
import { StatisticsServiceSelectOption } from '../interface';
import { prepareDataForChart } from './helpers';
import {
  ByCustomersProps,
  ByCustomersState,
  CustomerStatisticsFilterValues,
  DataItem,
  PreparedData
} from './interface';
import { ServiceTypes } from '../../operations/OperationsList/constants';
import { getOneLevelSelectedData } from '../../operations/OperationsList/helpers';

class ByCustomers extends Component<ByCustomersProps, ByCustomersState> {
  state: ByCustomersState = {
    preparedData: [],
    modalChartType: '',
    accountOptions: [],
    activeCurrencies: [],
    shouldReset: false
  };
  TargetRef = React.createRef<HTMLDivElement>();
  setFieldValue;
  resetForm;
  serviceType;

  componentDidMount(): void {
    const {
      merchantIdList,
      destinations,
      accounts,
      currencies,
      isAdmin,
      selectedUserId,
      authUserId,
      getDestinationsAction,
      getCurrenciesAction,
      getPayoutAccountsAction,
      t
    } = this.props;

    const isDestinationsExist = destinations && destinations.length;
    const isAccountsExist = accounts && accounts.data;
    const isCurrenciesExist = currencies && currencies.data && currencies.data;

    !isDestinationsExist && getDestinationsAction();

    if (!isAccountsExist && merchantIdList && merchantIdList.length) {
      getPayoutAccountsAction(
        merchantIdList,
        isAdmin && selectedUserId && authUserId !== selectedUserId ? selectedUserId : undefined
      );
    }
    isAccountsExist && this.setState({ accountOptions: getAccounts(this.serviceType, t, accounts.data) });
    !isCurrenciesExist && getCurrenciesAction();

    const activeCurrencies = getActiveCurrencies(
      currencies.data,
      STATISTICS_SERVICES_OPTIONS(t)[0].value.operationType
    );
    activeCurrencies && this.setState({ activeCurrencies });
  }

  componentDidUpdate(prevProps: Readonly<ByCustomersProps>, prevState: Readonly<ByCustomersState>): void {
    const {
      data,
      error,
      merchantIdList,
      selectedUserId,
      isAdmin,
      authUserId,
      accounts,
      currencies,
      getPayoutAccountsAction,
      resetCustomerStatisticsAction,
      t
    } = this.props;
    const { accountOptions } = this.state;

    if (error && error !== prevProps.error) {
      NotificationManager.info(error, 'Ошибка', NOTIFICATION_TIMEOUT, null, null, '');
    }

    if (prevProps.data !== data && data) {
      const preparedData = this.prepareData(data);
      this.setState({ preparedData });

      scrollToTarget(this.TargetRef.current);
    }

    if (prevProps.accounts !== accounts) {
      this.setState({ accountOptions: getAccounts(this.serviceType, t, accounts.data) });
    }

    if (prevState.accountOptions !== accountOptions) {
      this.setFieldValue && this.setFieldValue('account', accountOptions);
    }

    if (prevProps.currencies.data !== currencies.data) {
      const activeCurrencies = getActiveCurrencies(
        currencies.data,
        STATISTICS_SERVICES_OPTIONS(t)[0].value.operationType
      );
      activeCurrencies && this.setState({ activeCurrencies });
    }

    if (!isEqual(prevProps.merchantIdList, merchantIdList) || prevProps.selectedUserId !== selectedUserId) {
      resetCustomerStatisticsAction();

      if (merchantIdList && merchantIdList.length) {
        getPayoutAccountsAction(
          merchantIdList,
          isAdmin && selectedUserId && authUserId !== selectedUserId ? selectedUserId : undefined
        );
      }
    }

    if (
      prevProps.merchantIdList &&
      prevProps.selectedUserId &&
      merchantIdList &&
      selectedUserId &&
      (!isEqual(prevProps.merchantIdList, merchantIdList) || prevProps.selectedUserId !== selectedUserId)
    ) {
      this.handleResetForm();
      this.props.resetCustomerStatisticsAction();
      this.setState({ preparedData: [] });
    }

    this.state.shouldReset && this.setState({ shouldReset: false });
  }

  componentWillUnmount(): void {
    this.props.resetCustomerStatisticsAction();
    this.setState({ preparedData: [] });
  }

  handleResetForm = () => {
    const { currencies, t } = this.props;

    this.resetForm();
    this.setState({ shouldReset: true });

    const activeCurrenciesList = getActiveCurrencies(
      currencies.data,
      STATISTICS_SERVICES_OPTIONS(t)[0].value.operationType
    );
    activeCurrenciesList && this.setState({ activeCurrencies: activeCurrenciesList });
  };

  prepareData = (data: DataItem[]): PreparedData[] => {
    const { t } = this.props;

    if (!data.length) {
      return [];
    }

    const otherCountryName = t('other');
    const nullValue = 'NULL';
    const totalOperations = data.reduce((prevEl, el) => prevEl + ((el as { total: number }).total || 0), 0);
    const otherCountry = data.find((item: DataItem) => item.country === otherCountryName);
    const nullCountry = data.find((item: DataItem) => item.country === nullValue);

    if (nullCountry) {
      return [
        {
          percentage: 100,
          country: otherCountryName
        }
      ];
    }

    const filteredData = data.filter((item) => {
      if (otherCountry && !item.country && item.total) {
        otherCountry['total'] += item.total;
      }
      return item.country && item.total;
    });

    if (filteredData.length) {
      return filteredData.reduce<PreparedData[]>((prevEl, el) => {
        const percentage = el.total && totalOperations ? Math.round((el.total * MAX_PERCENT) / totalOperations) : 0;
        return [...prevEl, { country: el.country, percentage }];
      }, []);
    }

    return [];
  };

  onSubmit = (values: CustomerStatisticsFilterValues): void => {
    const {
      getCustomerStatisticsAction,
      merchantIdList,
      isAdmin,
      authUserId,
      selectedUserId,
      accounts,
      t
    } = this.props;

    const userId = isAdmin && authUserId !== selectedUserId ? selectedUserId : undefined;
    const requestParams = prepareStatisticParams(values, merchantIdList, userId, accounts.data, t);

    getCustomerStatisticsAction(requestParams);
  };

  validate = (values: CustomerStatisticsFilterValues): { [key: string]: string } => {
    const { dateFrom, dateTo } = values;
    const errors: { [key: string]: string } = {};

    if (values.direction === null) {
      errors['direction'] = EMPTY_FIELD_ERROR;
    }

    if (values.currency === null) {
      errors['currency'] = EMPTY_FIELD_ERROR;
    }

    if (dateFrom && dateTo && isAfter(dateFrom, dateTo) && !isSameDay(dateFrom, dateTo)) {
      errors['dateFrom'] = 'Начальная дата должна быть меньше или равна конечной';
    }

    return errors;
  };

  onServiceChange = (service: StatisticsServiceSelectOption) => {
    const { accounts, destinations, currencies, t } = this.props;

    const direction = getDirectionsOptions(service, destinations, t)[0];
    this.setFieldValue && this.setFieldValue('service', service);
    this.setFieldValue && this.setFieldValue('direction', direction);

    this.setState({
      accountOptions: getAccounts(service.value.operationType, t, accounts.data, direction.value.source),
      activeCurrencies: getActiveCurrencies(currencies.data, service.value.operationType)
    });
  };

  render(): ReactNode {
    const { loading, data, match, destinations, merchantIdList, t } = this.props;
    const { preparedData, modalChartType, accountOptions, activeCurrencies, shouldReset } = this.state;

    return (
      <Fragment>
        {loading && <Loader />}

        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.statistics" match={match} />
            <div className="time-tip  d-none d-lg-inline-block">{t(TIMEZONE_TOOLTIP_TEXT)}</div>
            <Separator className="mb-5" />
          </Colxx>
        </Row>

        {merchantIdList && merchantIdList.length > 0 && (
          <Row>
            <Colxx xxs="12" className="mb-4">
              <Formik
                initialValues={STATISTICS_INITIAL_VALUES(t)[StatisticType.ByCustomers]}
                onSubmit={this.onSubmit}
                validate={this.validate}
              >
                {({ values, setFieldValue, errors, touched, setFieldTouched, resetForm }) => {
                  this.setFieldValue = setFieldValue;
                  this.resetForm = resetForm;
                  this.serviceType = values.service.value.operationType;

                  return (
                    <Form>
                      <Card>
                        <CardBody>
                          <Row>
                            <Colxx xxs="12" sm="6">
                              <FormGroup className="has-float-label">
                                <Label for="dateFrom" className="float-label">
                                  {t('by-customers.Date from')}
                                </Label>
                                <DatePickerLocaled
                                  id="dateFrom"
                                  name="dateFrom"
                                  selected={values.dateFrom}
                                  customInput={<InputMask mask={DATE_MASK} />}
                                  dateFormat={DATE_FORMAT_BASIC}
                                  onChange={(d: Date) => setFieldValue('dateFrom', d)}
                                  autoComplete="off"
                                  maxDate={values.dateTo ? values.dateTo : new Date()}
                                  onBlur={() => setFieldTouched('dateFrom', true)}
                                  tooltip={t(DATE_FROM_TOOLTIP_TEXT)}
                                />
                                {errors.dateFrom && <div className="invalid-feedback d-block">{errors.dateFrom}</div>}
                              </FormGroup>
                            </Colxx>
                            <Colxx xxs="12" sm="6">
                              <FormGroup className="has-float-label">
                                <Label for="dateTo" className="float-label">
                                  {t('by-customers.Date by')}
                                </Label>
                                <DatePickerLocaled
                                  id="dateTo"
                                  name="dateTo"
                                  selected={values.dateTo}
                                  customInput={<InputMask mask={DATE_MASK} />}
                                  dateFormat={DATE_FORMAT_BASIC}
                                  onChange={(d: Date) => setFieldValue('dateTo', d)}
                                  autoComplete="off"
                                  minDate={values.dateFrom ? values.dateFrom : undefined}
                                  maxDate={new Date()}
                                  onBlur={() => setFieldTouched('dateTo', true)}
                                  tooltip={t(DATE_TO_TOOLTIP_TEXT)}
                                />
                                {errors.dateTo && touched.dateTo && (
                                  <div className="invalid-feedback d-block">{errors.dateTo}</div>
                                )}
                              </FormGroup>
                            </Colxx>
                            <Colxx xxs="12" sm="6" xl="3">
                              <FormGroup className="has-float-label">
                                <Label className="float-label">{t('by-customers.Service')}</Label>
                                <Select
                                  className="react-select"
                                  classNamePrefix="react-select"
                                  name="service"
                                  value={values.service}
                                  onChange={this.onServiceChange}
                                  options={STATISTICS_SERVICES_OPTIONS(t)}
                                  placeholder=""
                                />
                              </FormGroup>
                            </Colxx>
                            <Colxx xxs="12" sm="6" xl="3">
                              <FormGroup className="has-float-label">
                                <Label className="float-label">{t('by-customers.Destination')}</Label>
                                <CustomDropdownTreeSelect
                                  name="direction"
                                  data={directionsOptions(values.service.key, destinations, t)}
                                  shouldReset={shouldReset}
                                  onChange={(changedValue, selectedValues) => {
                                    const direction = setDirectionsData(selectedValues);
                                    setFieldValue('direction', direction);

                                    this.setState({
                                      accountOptions: getAccounts(
                                        values.service.value.operationType,
                                        t,
                                        this.props.accounts.data,
                                        direction ? direction.value.source : undefined
                                      )
                                    });
                                  }}
                                  disabled={values.service.key === ServiceTypes.Mc}
                                />
                                {errors.direction && <div className="invalid-feedback d-block">{errors.direction}</div>}
                              </FormGroup>
                            </Colxx>
                            <Colxx xxs="12" sm="6" xl="3">
                              <FormGroup className="has-float-label">
                                <Label className="float-label">{t('by-customers.Currency')}</Label>
                                <CustomDropdownTreeSelect
                                  name="currency"
                                  data={getCurrenciesOptions(activeCurrencies, values.service.key, t)}
                                  disabled={values.service.key === ServiceTypes.Mc}
                                  shouldReset={shouldReset}
                                  onChange={(changedValue, selectedValues) =>
                                    setFieldValue('currency', getOneLevelSelectedData(selectedValues))
                                  }
                                />
                                {errors.currency && <div className="invalid-feedback d-block">{errors.currency}</div>}
                              </FormGroup>
                            </Colxx>
                            <Colxx xxs="12" sm="6" xl="3">
                              <FormGroup className="has-float-label">
                                <Label className="float-label">{t('by-customers.Merchant Account')}</Label>
                                <CustomDropdownTreeSelect
                                  name="account"
                                  data={accountOptions}
                                  shouldReset={shouldReset}
                                  onChange={(changedValue, selectedValues) => setFieldValue('account', selectedValues)}
                                  onBlur={() => setFieldTouched('account')}
                                  onFocus={() => setFieldTouched('account', false)}
                                />
                                {errors.account && <div className="invalid-feedback d-block">{errors.account}</div>}
                              </FormGroup>
                            </Colxx>
                            <Colxx xxs="12" className="d-flex justify-content-end">
                              <Button disabled={!!getFieldErrorsCount(errors)} color="primary" type="submit">
                                {t('by-customers.button.search')}
                              </Button>
                            </Colxx>
                          </Row>
                        </CardBody>
                      </Card>
                    </Form>
                  );
                }}
              </Formik>
            </Colxx>
          </Row>
        )}

        {!(merchantIdList && merchantIdList.length) && <NotFoundMessage text={t('no_partners_selected')} />}
        {data && preparedData && !preparedData.length && <NotFoundMessage />}

        <div ref={this.TargetRef}>
          {preparedData && preparedData.length > 0 && (
            <Row className="mb-4">
              <Colxx xxs="12" sm="6" className="mb-4">
                <Card>
                  <CardBody className="chart-container d-flex flex-column justify-content-between">
                    {preparedData.map((item: { country: string; percentage: number }, index: number) => (
                      <Row key={index} className="border-bottom">
                        <Colxx xxs="6">{t(item.country)}</Colxx>
                        <Colxx xxs="6">{item.percentage} %</Colxx>
                      </Row>
                    ))}
                  </CardBody>
                </Card>
              </Colxx>
              <Colxx xxs="12" sm="6" className="mb-4">
                <Card onClick={() => this.setState({ modalChartType: 'pieChart' })}>
                  <CardBody className="chart-container">
                    <DoughnutChart data={prepareDataForChart(preparedData, t)} displayLegend={false} />
                  </CardBody>
                </Card>
              </Colxx>
              <Colxx xxs="12" sm="6">
                <Card onClick={() => this.setState({ modalChartType: 'barChart' })}>
                  <CardBody className="chart-container">
                    <BarChart data={prepareDataForChart(preparedData, t)} displayLegend={false} horizontal={true} />
                  </CardBody>
                </Card>
              </Colxx>

              <Modal
                isOpen={!!modalChartType}
                toggle={() => this.setState({ modalChartType: '' })}
                className="mw-100 w-60"
              >
                <ModalHeader toggle={() => this.setState({ modalChartType: '' })}>{t('chart.by_customer')}</ModalHeader>
                <ModalBody>
                  <Row>
                    {modalChartType === 'pieChart' && (
                      <Colxx xxs="12" className="vh-100">
                        <DoughnutChart data={prepareDataForChart(preparedData, t)} displayLegend={false} />
                      </Colxx>
                    )}
                    {modalChartType === 'barChart' && (
                      <Colxx xxs="12" className="vh-100">
                        <BarChart data={prepareDataForChart(preparedData, t)} displayLegend={false} horizontal={true} />
                      </Colxx>
                    )}
                  </Row>
                </ModalBody>
              </Modal>
            </Row>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ statistics, settings, authUser, operations }) => ({
  isAdmin: authUser.user.data && authUser.user.data.is_admin ? authUser.user.data.is_admin : false,
  selectedUserId: settings.selectedUser ? settings.selectedUser.id : 0,
  authUserId: authUser.user.data ? authUser.user.data.id : 0,
  data: statistics.customerStatistics.data,
  loading: statistics.customerStatistics.loading,
  error: statistics.customerStatistics.error,
  merchantIdList: settings.selectedMerchantsId,
  destinations: operations.destinations.data,
  accounts: operations.payoutAccounts,
  currencies: settings.currencies
});

const mapDispatchToProps = {
  getDestinationsAction: getDestinations,
  getCustomerStatisticsAction: getCustomerStatistics,
  getCurrenciesAction: getCurrencies,
  getPayoutAccountsAction: getPayoutAccounts,
  resetCustomerStatisticsAction: resetCustomerStatistics
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ByCustomers)
);
