/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
import { LocaleOption } from './interface';

export const defaultMenuType = 'menu-default';
export const defaultMenuPmId = 0;

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'ru';
export const localeOptions: LocaleOption[] = [
  {
    id: 'ru',
    name: 'Русский',
    direction: 'ltr'
  },
  {
    id: 'en',
    name: 'English',
    direction: 'ltr'
  }
];

export const firebaseConfig = {
  apiKey: 'AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg',
  authDomain: 'gogo-react-login.firebaseapp.com',
  databaseURL: 'https://gogo-react-login.firebaseio.com',
  projectId: 'gogo-react-login',
  storageBucket: 'gogo-react-login.appspot.com',
  messagingSenderId: '216495999563'
};

export const searchPath = '/app/pages/search';

/*
Color Options:
"light.purple",
"light.blue",
"light.green",
"light.orange",
"light.red",
"dark.purple",
"dark.blue",
"dark.green",
"dark.orange",
"dark.red"
*/
export const isMultiColorActive = true;
export const defaultColor = 'light.gmc';
export const defaultLanguage = 'ru';
export const defaultDirection = 'ltr';
export const isDarkSwitchActive = true;
export const languageStorageKey = 'i18nextLng';
export const themeColorStorageKey = '__theme_color';
export const themeRadiusStorageKey = '__theme_radius';
export const themeColors = {
  'light.green': '#576a3d',
  'light.red': '#880a1f',
  'light.gmc': '#145388',
  'light.purple': '#922c88',
  'light.blue': '#145388',
  'light.orange': '#e2863b'
};

export const NOTIFICATION_TIMEOUT = 3000;
export const NOTIFICATION_TIMEOUT_L = 5000;
