import format from '../../../localization';
import {
  CHART_MAX_TIPS_LIMITS,
  ticks8,
  width1000,
  width1500,
  width1800,
  width1900,
  width400,
  width576,
  width800
} from './constants';
import { Charts } from '../../../redux/statistics/interface';
import { ChartsPeriodValue } from '../interface';
import {
  DATE_FORMAT_BASIC,
  DATE_TIME_FORMAT,
  DATE_TIME_FORMAT_WITH_WEEK_DAY,
  MONTH_FORMAT
} from '../../../constants/app';
import { ChartTypes } from '../constants';

export const gerSuggestedMaxLength = (suggestedMax: number) => {
  const valueToString = suggestedMax
    .toLocaleString('ru-RU', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
    .replace(',', '.');

  return valueToString.length;
};

export const getsMaxTicksByWindowWidth = (windowWidth: number, suggestedMaxLength?): number => {
  const boundaryValue = 6;
  let isSmaller = false;
  let maxTicksLimit = 0;

  if (suggestedMaxLength > boundaryValue) {
    isSmaller = true;
  }

  switch (true) {
    case windowWidth < width400:
      maxTicksLimit = isSmaller ? CHART_MAX_TIPS_LIMITS[width400] - 1 : CHART_MAX_TIPS_LIMITS[width400];
      break;
    case windowWidth < width576:
      maxTicksLimit = isSmaller ? CHART_MAX_TIPS_LIMITS[width576] - 1 : CHART_MAX_TIPS_LIMITS[width576];
      break;
    case windowWidth < width800:
      maxTicksLimit = isSmaller ? CHART_MAX_TIPS_LIMITS[width800] - 1 : CHART_MAX_TIPS_LIMITS[width800];
      break;
    case windowWidth < width1000:
      maxTicksLimit = isSmaller ? CHART_MAX_TIPS_LIMITS[width1000] - 1 : CHART_MAX_TIPS_LIMITS[width1000];
      break;
    case windowWidth < width1500:
      maxTicksLimit = isSmaller ? CHART_MAX_TIPS_LIMITS[width1500] - 1 : CHART_MAX_TIPS_LIMITS[width1500];
      break;
    case windowWidth < width1800:
      maxTicksLimit = isSmaller ? CHART_MAX_TIPS_LIMITS[width1800] - 1 : CHART_MAX_TIPS_LIMITS[width1800];
      break;
    case windowWidth < width1900:
      maxTicksLimit = isSmaller ? CHART_MAX_TIPS_LIMITS[width1900] - 1 : CHART_MAX_TIPS_LIMITS[width1900];
      break;
    default:
      maxTicksLimit = ticks8;
      break;
  }
  return maxTicksLimit;
};

export const getChartData = (data: Charts[], period, type) => {
  const labels: string[] = [];
  const values: number[] = [];

  data.forEach((item: Charts) => {
    const { period_time: date } = item;

    switch (Number(period)) {
      case ChartsPeriodValue.Month:
        labels.push(format(date, MONTH_FORMAT));
        break;
      case ChartsPeriodValue.Week:
        labels.push(format(date, DATE_TIME_FORMAT_WITH_WEEK_DAY));
        break;
      case ChartsPeriodValue.Day:
        labels.push(format(date, DATE_FORMAT_BASIC));
        break;
      default:
        labels.push(format(date, DATE_TIME_FORMAT));
    }

    let value;

    switch (type) {
      case ChartTypes.Paid:
        value = item.paid;
        break;
      case ChartTypes.NotPaidOrNotFound:
        value = item.not_paid;
        break;
      case ChartTypes.Total:
        value = item.total;
        break;
      case ChartTypes.TotalAmount:
        value = item.paid_amount + item.not_paid_amount + item.refund_amount;
        break;
      case ChartTypes.AvgAmount:
        value = item.avg_paid_amount;
        break;
      case ChartTypes.RefundTotal:
        value = item.refund;
        break;
      case ChartTypes.RefundTotalAmount:
        value = item.refund_amount;
        break;
      case ChartTypes.SumPaid:
        value = item.paid_amount;
        break;
      case ChartTypes.SumNotPaid:
        value = item.not_paid_amount;
        break;
      default:
        value = 0;
    }

    values.push(value || 0);
  });

  return {
    labels,
    values
  };
};
