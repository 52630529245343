import {
  GET_SERVICES_BY_NAME,
  GET_SERVICES_BY_NAME_SUCCESS,
  GET_SERVICES_BY_MERCHANT,
  GET_SERVICES_BY_MERCHANT_ERROR,
  GET_SERVICES_BY_MERCHANT_SUCCESS,
  GET_PAYOUT_ACCOUNTS_BY_SERVICE,
  GET_PAYOUT_ACCOUNTS_BY_SERVICE_SUCCESS,
  GET_PAYOUT_ACCOUNTS_BY_SERVICE_ERROR,
  SET_PAYOUT_ACCOUNTS_LAST_OPERATION,
  GET_SERVICES_BY_NAME_ERROR,
  GET_ACCOUNTS_BALANCE,
  GET_ACCOUNTS_BALANCE_SUCCESS,
  GET_ACCOUNTS_BALANCE_ERROR
} from '../actions';

import { ServicesReducerState } from './interface';

const INIT_STATE: ServicesReducerState = {
  servicesList: {
    rows: null,
    error: '',
    loading: false,
    message: ''
  },
  servicesByName: {
    rows: null,
    error: '',
    loading: false
  },
  payoutAccountsByService: {
    rows: null,
    total: null,
    lastOperation: null,
    error: '',
    loading: false
  },
  accountsBalance: {
    data: null,
    loading: false,
    error: ''
  }
};

export default (state: ServicesReducerState = INIT_STATE, { type, payload }): ServicesReducerState => {
  switch (type) {
    case GET_SERVICES_BY_NAME:
      return {
        ...state,
        servicesByName: {
          ...state.servicesByName,
          loading: true,
          error: ''
        }
      };
    case GET_SERVICES_BY_NAME_SUCCESS:
      return {
        ...state,
        servicesByName: {
          ...state.servicesByName,
          ...payload.data,
          loading: false
        }
      };
    case GET_SERVICES_BY_NAME_ERROR:
      return {
        ...state,
        servicesByName: {
          ...state.servicesByName,
          error: payload.error,
          loading: false
        }
      };
    case GET_SERVICES_BY_MERCHANT:
      return {
        ...state,
        servicesList: {
          ...state.servicesList,
          loading: true,
          error: ''
        }
      };
    case GET_SERVICES_BY_MERCHANT_SUCCESS:
      return {
        ...state,
        servicesList: {
          ...state.servicesList,
          rows: payload.data,
          loading: false
        }
      };
    case GET_SERVICES_BY_MERCHANT_ERROR:
      return {
        ...state,
        servicesList: {
          ...state.servicesList,
          rows: null,
          error: payload.error,
          loading: false
        }
      };
    case GET_PAYOUT_ACCOUNTS_BY_SERVICE:
      return {
        ...state,
        payoutAccountsByService: {
          ...state.payoutAccountsByService,
          loading: true
        }
      };
    case GET_PAYOUT_ACCOUNTS_BY_SERVICE_SUCCESS:
      return {
        ...state,
        payoutAccountsByService: {
          ...state.payoutAccountsByService,
          rows: payload.data,
          error: '',
          loading: false
        }
      };
    case GET_PAYOUT_ACCOUNTS_BY_SERVICE_ERROR:
      return {
        ...state,
        payoutAccountsByService: {
          ...state.payoutAccountsByService,
          error: payload.error,
          loading: false
        }
      };
    case SET_PAYOUT_ACCOUNTS_LAST_OPERATION:
      return {
        ...state,
        payoutAccountsByService: {
          ...state.payoutAccountsByService,
          lastOperation: payload.data
        }
      };
    case GET_ACCOUNTS_BALANCE:
      return {
        ...state,
        accountsBalance: {
          ...state.accountsBalance,
          loading: true,
          error: ''
        }
      };
    case GET_ACCOUNTS_BALANCE_SUCCESS:
      return {
        ...state,
        accountsBalance: {
          ...state.accountsBalance,
          data: payload.data,
          loading: false
        }
      };
    case GET_ACCOUNTS_BALANCE_ERROR:
      return {
        ...state,
        accountsBalance: {
          ...state.accountsBalance,
          error: payload.error,
          loading: false
        }
      };
    default:
      return { ...state };
  }
};
