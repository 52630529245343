import React from 'react';
import { Row } from 'reactstrap';

import { Colxx } from './CustomBootstrap';
import i18n from '../../i18n';

const NOT_FOUND = i18n.t('Нет данных');

interface NotFoundMessageProps {
  text?: string;
}

export const NotFoundMessage = ({ text }: NotFoundMessageProps) => (
  <Row>
    <Colxx xxs="12" className="mb-4 text-center">
      {text ? i18n.t(text) : NOT_FOUND}
    </Colxx>
  </Row>
);
