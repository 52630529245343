import { all, call, fork, put, takeLatest, SimpleEffect } from 'redux-saga/effects';
import { CombinatorEffect } from '@redux-saga/types';

import { ApiRequest } from '../../helpers/ApiRequest/ApiRequest';

import { GET_CSV_LIST, getCsvListSuccess, getCsvListError } from '../actions';
import { GetCsvList } from './interface';

function* getCsvList({ payload }: GetCsvList): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { userId } = payload;

    const url = userId ? `/users/${userId}/csv/` : '/user/csv/';

    const { data, error } = yield call(ApiRequest.get, url);

    if (error) {
      yield put(getCsvListError(`${error}`));
    } else {
      yield put(getCsvListSuccess(data));
    }
  } catch (error) {
    yield put(getCsvListError(`${error}`));
  }
}

export function* watchGetCsvList(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(GET_CSV_LIST, getCsvList);
}

export default function* rootSaga(): Generator<CombinatorEffect<string, any>, any, any> {
  yield all([fork(watchGetCsvList)]);
}
