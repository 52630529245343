import { PayoutAccount } from '../../redux/operations/interface';
import { DATE_TIME_FORMAT_WITH_SEC } from '../../constants/app';
import { OPERATION_TYPES } from './constants';
import { CellInfo } from 'react-table';
import format from '../../localization';
import { roundAmount } from '../../helpers/Utils';
import { SERVICE_TYPES_CODES } from '../services/ServicesList/constant';

export const getAccountSelectData = (accounts: { [key: number]: PayoutAccount[] }) => {
  const items: any = [];
  if (accounts) {
    Object.values(accounts).forEach((payoutAccounts: PayoutAccount[]) => {
      payoutAccounts.forEach((payoutAccount: PayoutAccount) => {
        if ('service_type' in payoutAccount && payoutAccount.service_type === SERVICE_TYPES_CODES.OUTCOME) {
          items.push({ value: payoutAccount.id, key: payoutAccount.id, label: payoutAccount.name });
        }
      });
    });
  }
  return items;
};

export const DEFAULT_SORTER = [
  {
    id: 'dt',
    desc: true
  }
];

export const getBalanceOperationTableColumns = (accounts: any, t: any) => [
  {
    Header: t('balance.datetime'),
    accessor: 'dt',
    Cell: ({ value }: CellInfo) => format(value, DATE_TIME_FORMAT_WITH_SEC)
  },
  {
    Header: t('balance.account'),
    accessor: 'partner_id',
    Cell: ({ value }: CellInfo) => (typeof accounts[value] === 'undefined' ? value : accounts[value])
  },
  {
    Header: t('balance.type of operation'),
    accessor: 'type',
    Cell: ({ value }: CellInfo) => OPERATION_TYPES(t)[value]
  },
  {
    Header: t('balance.amount_income'),
    accessor: 'amount',
    Cell: ({ value }: CellInfo) => roundAmount(value)
  },
  {
    Header: t('balance.comment'),
    accessor: 'comment'
  }
];
