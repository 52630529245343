import React, { FunctionComponent } from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import BalanceOperations from './BalanceOperations/BalanceOperations';
import Balance from './BalanceRemainder/Balance';

const Services: FunctionComponent<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}/balance-operations`} component={BalanceOperations} />
    <Route exact path={`${match.url}/balance-remainder`} component={Balance} />
    <Redirect from={match.url} to={`${match.url}/balance-operations`} />
    <Redirect to={'/error'} />
  </Switch>
);

export default Services;
