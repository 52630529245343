import {
  SEND_SUPPORT_MESSAGE,
  SEND_SUPPORT_MESSAGE_SUCCESS,
  SEND_SUPPORT_MESSAGE_ERROR,
  GET_ALL_REQUESTS_SUCCESS,
  GET_ALL_REQUESTS,
  GET_ALL_REQUESTS_ERROR,
  GET_REQUEST,
  GET_REQUEST_SUCCESS,
  GET_REQUEST_ERROR,
  RESET_REQUESTS,
  OPEN_REQUEST,
  OPEN_REQUEST_SUCCESS,
  OPEN_REQUEST_ERROR,
  GET_REQUEST_TYPES,
  GET_REQUEST_TYPES_SUCCESS,
  GET_REQUEST_TYPES_ERROR
} from '../actions';
import {
  SendSupportMessageAction,
  SendSupportMessageSuccessAction,
  SendSupportMessageErrorAction,
  GetAllRequestsError,
  GetAllRequestsSuccess,
  GetAllRequests,
  RequestData,
  GetRequestSuccess,
  GetRequest,
  GetRequestError,
  ResetRequestsAction,
  OpenRequest,
  OpenRequestSuccess,
  OpenRequestError,
  OpenRequestData,
  GetRequestTypes,
  GetRequestTypesSuccess,
  GetRequestTypesError,
  RequestTypeItem
} from './interface';
import { Message } from '../../views/support/interface';

// GET ALL SUPPORT REQUESTS
export const getAllRequests = (merchantId: number[]): GetAllRequests => ({
  type: GET_ALL_REQUESTS,
  payload: { merchantId }
});

export const getAllRequestsSuccess = (requests: RequestData[]): GetAllRequestsSuccess => ({
  type: GET_ALL_REQUESTS_SUCCESS,
  payload: { requests }
});

export const getAllRequestsError = (error: string): GetAllRequestsError => ({
  type: GET_ALL_REQUESTS_ERROR,
  payload: { error }
});

// GET ONE REQUEST
export const getRequest = (ticketId: number): GetRequest => ({
  type: GET_REQUEST,
  payload: { ticketId }
});

export const getRequestSuccess = (currentRequest: Message[]): GetRequestSuccess => ({
  type: GET_REQUEST_SUCCESS,
  payload: { currentRequest }
});

export const getRequestError = (error: string): GetRequestError => ({
  type: GET_REQUEST_ERROR,
  payload: { error }
});

// OPEN REQUEST
export const openRequest = (request: OpenRequestData): OpenRequest => ({
  type: OPEN_REQUEST,
  payload: request
});

export const openRequestSuccess = (): OpenRequestSuccess => ({
  type: OPEN_REQUEST_SUCCESS
});

export const openRequestError = (error: string): OpenRequestError => ({
  type: OPEN_REQUEST_ERROR,
  payload: { error }
});

// SEND SUPPORT MESSAGE
export const sendSupportMessage = (requestId: string, message?: string, file?: File): SendSupportMessageAction => ({
  type: SEND_SUPPORT_MESSAGE,
  payload: { requestId, message, file }
});

export const sendSupportMessageSuccess = (message?: string, file?: File): SendSupportMessageSuccessAction => ({
  type: SEND_SUPPORT_MESSAGE_SUCCESS,
  payload: { message, file }
});

export const sendSupportMessageError = (error: string): SendSupportMessageErrorAction => ({
  type: SEND_SUPPORT_MESSAGE_ERROR,
  payload: { error }
});

// GET REQUEST TYPES
export const getRequestTypes = (): GetRequestTypes => ({
  type: GET_REQUEST_TYPES
});

export const getRequestTypesSuccess = (types: RequestTypeItem[]): GetRequestTypesSuccess => ({
  type: GET_REQUEST_TYPES_SUCCESS,
  payload: { types }
});

export const getRequestTypesError = (error: string): GetRequestTypesError => ({
  type: GET_REQUEST_TYPES_ERROR,
  payload: { error }
});

// RESET REQUESTS DATA
export const resetRequests = (): ResetRequestsAction => ({
  type: RESET_REQUESTS
});
