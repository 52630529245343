import { PARTNER_ID } from '../../../constants/app';
import { ALL_OPTION } from '../constants';

export const GLOSSARY_USERS_INITIAL_VALUES = (t: any) => ({
  id: '',
  name: ALL_OPTION(t),
  phone: '',
  email: '',
  account_id: ALL_OPTION(t),
  [PARTNER_ID]: ALL_OPTION(t)
});

export const DEFAULT_SORTER = [
  {
    id: 'id',
    desc: false
  }
];
