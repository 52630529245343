import { CellInfo } from 'react-table';
import format from '../../../localization';

import { DATE_TIME_FORMAT } from '../../../constants/app';
import { RegistriesHistoryStatusesKeys } from '../../../views/operations/Payouts/PayoutsRegistriesHistory/constants';
export const RELOAD_PAGE_TIMEOUT = 500;
export const RESIZE_PAGE_TIMEOUT = 350;
export const MAX_HEADER_NOTIFICATIONS = 5;
export const SYNC_ERROR = 'Data sync error';

export enum NotificationType {
  PayoutType = 1,
  CronErrorType = 2
}

export const NOTIFICATIONS_COLUMNS = (t: any) => [
  {
    Header: t('Date and time'),
    accessor: 'dt',
    Cell: ({ value }: CellInfo) => format(value, DATE_TIME_FORMAT)
  },
  {
    Header: t('Merchant Account'),
    accessor: 'name',
    Cell: ({ value }: CellInfo) => t(value)
  },
  {
    Header: t('Message'),
    accessor: 'name',
    Cell: ({ original }: CellInfo) => getNotificationMessage(original, t)
  }
];

export const getNotificationMessage = ({ type, id, registry_id, status }, t) => {
  const curId = registry_id ? registry_id : id;
  return type === NotificationType.CronErrorType
    ? t(SYNC_ERROR)
    : `${t('notification.payout')} ${curId}, ${t('notification.status')}: ${RegistriesHistoryStatusesKeys(t)[status]}`;
};
