import { AnyAction, Middleware, Dispatch } from 'redux';

import { getNotifications, getWsMessage, wsConnectionClose, wsConnectionError, wsConnectionStart } from './actions';
import { GET_USER_SUCCESS, LOGOUT_USER_SUCCESS, WS_NOTIFICATION_GET_MESSAGE } from '../actions';
import { WS_CONFIG } from '../../config/appConfig';

const NOTIFICATION_URL = `${WS_CONFIG.apiURL()}/ws-notifications/`;

export function socketMiddleware(): Middleware {
  let socket: WebSocket | null = null;

  return ({ dispatch }) => (next: Dispatch<AnyAction>) => (action: AnyAction) => {
    switch (action.type) {
      case GET_USER_SUCCESS:
        socket = new WebSocket(NOTIFICATION_URL);

        socket.onopen = () => {
          next(wsConnectionStart());
        };
        socket.onmessage = ({ data }) => {
          dispatch(getWsMessage(JSON.parse(data)));
        };
        socket.onclose = ({ wasClean }) => {
          if (wasClean) {
            next(wsConnectionClose());
          }
        };
        socket.onerror = () => {
          next(wsConnectionError('Error!!!'));
        };
        break;

      case LOGOUT_USER_SUCCESS:
        socket && socket.close();
        next(wsConnectionClose());
        break;

      case WS_NOTIFICATION_GET_MESSAGE:
        next(getNotifications({}));
        break;

      default:
        break;
    }
    next(action);
  };
}
