import React, { Component, ReactNode } from 'react';
import { Label, FormGroup, Button, CardBody, Card } from 'reactstrap';
import { Field, Form, Formik } from 'formik';
import { connect } from 'react-redux';

import { NotificationManager } from '../../../components/common/react-notifications';
import { PasswordStrengthMeter } from '../../../components/common/PasswordStrengthMeter';
import { NOTIFICATION_TIMEOUT } from '../../../constants/defaultValues';
import { updatePassword } from '../../../redux/profile/actions';
import cn from 'classnames';

import { MIN_PASSWORD_LENGTH, MAX_PASSWORD_LENGTH } from './constant';
import { ChangePasswordProps, ChangePasswordFormValues, ChangePasswordState } from './interface';
import { trimSpaces } from '../../../helpers/Utils';
import { getUser } from '../../../redux/authUser/actions';
import { withTranslation } from 'react-i18next';

class ChangePasswordForm extends Component<ChangePasswordProps, ChangePasswordState> {
  state: ChangePasswordState = {
    showNewPassword: false,
    showOldPassword: false,
    showNewPasswordConfirm: false
  };
  setFieldError;

  componentDidUpdate(prevProps: Readonly<ChangePasswordProps>): void {
    const { error, loading, getUserData, shouldChangePassword, t } = this.props;

    if (!prevProps.error && error) {
      NotificationManager.info(error, 'Ошибка', NOTIFICATION_TIMEOUT, null, null, '');
    }

    if (prevProps.loading && !loading && !error) {
      NotificationManager.info(t('password_changed'), null, NOTIFICATION_TIMEOUT, null, null, '');
      shouldChangePassword && getUserData();
    }
  }

  onForm = (values) => {
    const { changePasswordAction, getUserData, shouldChangePassword, error, loading } = this.props;

    changePasswordAction(trimSpaces(values));

    if (shouldChangePassword && !loading && !error) {
      getUserData();
    }
  };

  validate = (values: ChangePasswordFormValues) => {
    const { t } = this.props;
    const { newPassword, newPasswordConfirm, oldPassword }: typeof values = trimSpaces(values);

    const errors = Object.getOwnPropertyNames(values).reduce(
      (accumulator, key) => (values[key] ? accumulator : { ...accumulator, [key]: t('Поле не может быть пустым') }),
      {}
    );

    const digitMatch = newPassword ? newPassword.match(/[0-9]/g) : null;
    const uppercaseMatch = newPassword ? newPassword.match(/[A-Z]/g) : null;
    const symbolsMatch = newPassword ? newPassword.match(/[!@#$%^&*(),.?":;{}+\-|<>[\]/_]/g) : null;

    if (oldPassword === newPassword) {
      errors['newPassword'] = t('old_password_match');
    }

    if (oldPassword === newPasswordConfirm) {
      errors['newPasswordConfirm'] = t('old_password_match');
    }

    if (newPassword && !(digitMatch && uppercaseMatch && symbolsMatch)) {
      errors['newPassword'] = t('tooltip.password_contains');
    }

    if ((newPassword && newPassword.length < MIN_PASSWORD_LENGTH) || newPassword.length > MAX_PASSWORD_LENGTH) {
      errors['newPassword'] = t('tooltip.password_length_between');
    }

    if (newPassword && newPasswordConfirm && newPassword !== newPasswordConfirm) {
      errors['newPasswordConfirm'] = t('password_not_match');
    }

    return errors;
  };

  render(): ReactNode {
    const { loading, t } = this.props;
    const { showNewPassword, showNewPasswordConfirm, showOldPassword } = this.state;

    const initialValues = {
      oldPassword: '',
      newPassword: '',
      newPasswordConfirm: ''
    };

    return (
      <Formik initialValues={initialValues} onSubmit={this.onForm} validate={this.validate}>
        {({ errors, touched, values, setFieldValue, setFieldError, setErrors, setFieldTouched, isValid }) => {
          this.setFieldError = setFieldError;

          return (
            <Form>
              <Card>
                <CardBody>
                  <FormGroup className="form-group has-float-label tooltip-right-bottom mb-5">
                    <Label className="float-label">{t('user.current-password')}</Label>
                    <Field
                      className="form-control"
                      type={showOldPassword ? 'text' : 'password'}
                      name="oldPassword"
                      onChange={(e) => {
                        const { oldPassword, ...newErrors } = errors;
                        setErrors(newErrors);
                        setFieldValue('oldPassword', e.target.value);
                      }}
                      onBlur={() => setFieldTouched('oldPassword', true)}
                    />
                    <div
                      className={cn('simple-icon-eye show-password-eye cursor-pointer', {
                        'color-theme-1': showOldPassword
                      })}
                      onClick={() => this.setState({ showOldPassword: !showOldPassword })}
                    />
                    {errors.oldPassword && touched.oldPassword && (
                      <div className="invalid-feedback d-block">{errors.oldPassword}</div>
                    )}
                  </FormGroup>

                  <FormGroup className="form-group has-float-label tooltip-right-bottom mb-5">
                    <Label className="float-label">{t('user.new-password')}</Label>
                    <Field
                      className="form-control"
                      type={showNewPassword ? 'text' : 'password'}
                      name="newPassword"
                      onChange={(e) => {
                        const { newPassword, ...newErrors } = errors;
                        setErrors(newErrors);
                        setFieldValue('newPassword', e.target.value);
                      }}
                      onBlur={() => setFieldTouched('newPassword', true)}
                    />
                    <div
                      className={cn('simple-icon-eye show-password-eye cursor-pointer', {
                        'color-theme-1': showNewPassword
                      })}
                      onClick={() => this.setState({ showNewPassword: !showNewPassword })}
                    />
                    {errors.newPassword && touched.newPassword && (
                      <div className="invalid-feedback d-block">{errors.newPassword}</div>
                    )}
                  </FormGroup>

                  <FormGroup className="form-group has-float-label tooltip-right-bottom mb-5">
                    <Label className="float-label">{t('user.repeat-new-password')}</Label>
                    <Field
                      className="form-control"
                      type={showNewPasswordConfirm ? 'text' : 'password'}
                      name="newPasswordConfirm"
                      onChange={(e) => {
                        const { newPasswordConfirm, ...newErrors } = errors;
                        setErrors(newErrors);
                        setFieldValue('newPasswordConfirm', e.target.value);
                      }}
                      onBlur={() => setFieldTouched('newPasswordConfirm', true)}
                    />
                    <div
                      className={cn('simple-icon-eye show-password-eye cursor-pointer', {
                        'color-theme-1': showNewPasswordConfirm
                      })}
                      onClick={() => this.setState({ showNewPasswordConfirm: !showNewPasswordConfirm })}
                    />
                    {errors.newPasswordConfirm && touched.newPasswordConfirm && (
                      <div className="invalid-feedback d-block">{errors.newPasswordConfirm}</div>
                    )}
                  </FormGroup>

                  <FormGroup className="mb-5">
                    <PasswordStrengthMeter value={values.newPassword} />
                  </FormGroup>

                  <div className="d-flex justify-content-end align-items-center">
                    <Button
                      disabled={!isValid}
                      color="primary"
                      className={`btn-multiple-state ${loading ? 'show-spinner' : ''}`}
                    >
                      <span className="spinner d-inline-block">
                        <span className="bounce1" />
                        <span className="bounce2" />
                        <span className="bounce3" />
                      </span>
                      <span className="label">{t('button.change-password')}</span>
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const mapStateToProps = ({ profile, authUser }) => {
  const { loading, error, status } = profile;
  const {
    user: { data }
  } = authUser;

  return {
    loading,
    error,
    status,
    shouldChangePassword: data && data.is_password_expired ? data.is_password_expired : false
  };
};

const mapDispatchToProps = {
  changePasswordAction: updatePassword,
  getUserData: getUser
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ChangePasswordForm)
);
