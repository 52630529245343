import { CellInfo } from 'react-table';
import format from '../../../localization';

import { Charts, PeriodSummaryData, PeriodSummaryItem } from '../../../redux/statistics/interface';
import { roundDigit } from '../../../helpers/Utils';
import { MAX_PERCENT, DATE_TIME_FORMAT_WITH_SEC } from '../../../constants/app';
import { PayoutAccount } from '../../../redux/operations/interface';
import { ChartsPeriodValue } from '../interface';
import { AVG_CNT_ITEMS, DAYS_COUNT_IN_WEEK } from '../constants';

export const getPeriodSummaryData = (data: Charts[], accounts: PayoutAccount[], currency, byAccount, t: any) => {
  const result = [
    {
      Header: 'Период',
      accessor: 'period_time',
      Cell: ({ original }: CellInfo) => format(original.period_time, DATE_TIME_FORMAT_WITH_SEC),
      show: false
    },
    {
      Header: t('period.Period'),
      accessor: 'period_time',
      Cell: ({ original }: CellInfo) => original.period
    },
    {
      Header: t('period.Amount'),
      accessor: 'turnoverAmount',
      Cell: ({ original }: CellInfo) => roundDigit(original.turnoverAmount)
    },
    {
      Header: t('period.Average amount'),
      accessor: 'avgPaidAmount',
      Cell: ({ original }: CellInfo) => roundDigit(original.avgPaidAmount)
    },
    {
      Header: t('period.Count'),
      accessor: 'totalAmount',
      Cell: ({ original }: CellInfo) => roundDigit(original.totalAmount)
    },
    {
      Header: t('period.Successful'),
      accessor: 'paidAmount',
      Cell: ({ original }: CellInfo) => roundDigit(original.paidAmount)
    },
    {
      Header: t('period.Successful, %'),
      accessor: 'paidAmountPerc',
      Cell: ({ original }: CellInfo) => roundDigit(original.paidAmountPerc)
    },
    {
      Header: t('period.Failed'),
      accessor: 'notPaidAmount',
      Cell: ({ original }: CellInfo) => roundDigit(original.notPaidAmount)
    },
    {
      Header: t('period.Failed, %'),
      accessor: 'notPaidAmountPerc',
      Cell: ({ original }: CellInfo) => roundDigit(original.notPaidAmountPerc)
    },
    {
      Header: t('period.Processing'),
      accessor: 'processing',
      Cell: ({ original }: CellInfo) => roundDigit(original.processing, 0)
    }
  ];

  if (byAccount) {
    result.splice(1, 0, {
      Header: t('period.By customers'),
      accessor: 'accountId',
      Cell: ({ value }: CellInfo) => {
        return accontName(accounts, value);
      }
    });
  }

  return result;
};

const accontName = (accounts, value) => {
  const splited = value.split('_');
  let selectedAccount = accounts.find(
    ({ id, shop_id }) => Number(splited[0]) === Number(shop_id) && Number(splited[1]) === Number(id)
  );
  if (selectedAccount === undefined) {
    selectedAccount = accounts.find(({ id, shop_id }) => Number(splited[1]) === Number(id));
  }
  return selectedAccount ? selectedAccount.name : '-';
};

export const DEFAULT_SORTER = [
  {
    id: 'period_time',
    desc: false
  }
];

export const preparePeriodSummaryData = (data: PeriodSummaryData, period: string, byAccount: boolean) => {
  let prepareGroupData = {};

  if (data && Object.keys(data).length) {
    Object.entries(data).forEach(([accountId, accountValue]) => {
      accountValue &&
        accountValue.length &&
        accountValue.forEach((item: PeriodSummaryItem) => {
          const position = item.period_time.indexOf('+');
          let periodStart;
          if (position > -1) {
            periodStart = new Date(item.period_time.substring(0, position));
          } else {
            periodStart = new Date(item.period_time);
          }
          let periodValue = '';
          switch (Number(period)) {
            case ChartsPeriodValue.Hour:
              periodValue = format(periodStart, 'dd.MM HH:mm');
              break;
            case ChartsPeriodValue.Day:
              periodValue = format(periodStart, 'dd.MM.yyyy');
              break;
            case ChartsPeriodValue.Week:
              const periodEnd = new Date(item.period_time);
              periodEnd.setDate(periodEnd.getDate() + DAYS_COUNT_IN_WEEK);
              periodValue = format(periodStart, 'dd.MM.yyyy').concat(' - ', format(periodEnd, 'dd.MM.yyyy'));
              break;
            case ChartsPeriodValue.Month:
              periodValue = format(periodStart, 'MMM yyyy');
              break;
          }

          const groupKey = byAccount ? accountId.toString().concat(periodValue) : periodValue;
          if (prepareGroupData.hasOwnProperty(groupKey)) {
            prepareGroupData[groupKey].turnoverAmount += item.paid_amount + item.not_paid_amount + item.refund_amount;
            prepareGroupData[groupKey].avgPaidAmount =
              (prepareGroupData[groupKey].avgPaidAmount + item.avg_paid_amount) / AVG_CNT_ITEMS;
            prepareGroupData[groupKey].totalAmount += item.total_amount;
            prepareGroupData[groupKey].total += item.total;
            prepareGroupData[groupKey].paidAmount += item.paid_amount;
            prepareGroupData[groupKey].paid += item.paid;
            prepareGroupData[groupKey].notPaidAmount += item.not_paid_amount;
            prepareGroupData[groupKey].notPaid += item.not_paid;
            prepareGroupData[groupKey].processing += item.processing;
          } else {
            const sortIndex = byAccount
              ? accountId.toString().concat('-', periodStart.getTime().toString())
              : periodStart.getTime().toString();
            const newItem = {
              [groupKey]: {
                period: periodValue,
                period_time: new Date(item.period_time),
                accountId,

                turnoverAmount: item.paid_amount + item.not_paid_amount + item.refund_amount, // Оборот
                avgPaidAmount: item.avg_paid_amount, // Средний размер платежа

                totalAmount: item.total_amount, // Общая сумма
                total: item.total, // Общее кол-во

                paidAmount: item.paid_amount, // Сумма успешных
                paid: item.paid, // Кол-во успешных

                notPaidAmount: item.not_paid_amount, // Сумма неуспешных
                notPaid: item.not_paid, // Кол-во неуспешных

                processing: item.processing, // Кол-во в обработке

                sortIndex
              }
            };

            prepareGroupData = { ...prepareGroupData, ...newItem };
          }
        });
    });
  }

  for (const key of Object.keys(prepareGroupData)) {
    prepareGroupData[key].notPaidAmountPerc =
      (prepareGroupData[key].notPaid * MAX_PERCENT) / prepareGroupData[key].total || 0;
    prepareGroupData[key].paidAmountPerc =
      (prepareGroupData[key].paid * MAX_PERCENT) / prepareGroupData[key].total || 0;
  }

  return Object.values(prepareGroupData).sort((t1: any, t2: any) => {
    if (t1.sortIndex > t2.sortIndex) {
      return 1;
    }

    if (t1.sortIndex < t2.sortIndex) {
      return -1;
    }

    return 0;
  });
};
