import React, { PureComponent, ReactNode } from 'react';
import { Modal, ModalBody, ModalHeader, FormGroup } from 'reactstrap';
import { ModalCountDownProps, ModalCountDownState, Timer } from './interface';
import SupportEmailLink from '../../settings/CustomDesign/Views/SupportEmailLink';
import { withTranslation } from 'react-i18next';

class ModalCountDown extends PureComponent<ModalCountDownProps, ModalCountDownState> {
  constructor(props: ModalCountDownProps) {
    super(props);
    this.countDown = this.countDown.bind(this);
    this.startTimer = this.startTimer.bind(this);
    this.state = { time: {} as Timer, seconds: 0, intervalId: null };
  }

  componentDidMount(): void {
    const { message } = this.props;
    const secondsExtractRegEx = /{(\d+)}/;
    const matches = secondsExtractRegEx.exec(message);
    const seconds = matches ? parseInt(matches[1], 10) : 1;
    const timeLeftVar = this.secondsToTime(seconds);

    this.setState({ time: timeLeftVar, seconds });
    this.startTimer(seconds);
  }

  secondsToTime(secs: number): Timer {
    const hours = Math.floor(secs / (60 * 60));

    const divisorForMinutes = secs % (60 * 60);
    const minutes = Math.floor(divisorForMinutes / 60);

    const divisorForSeconds = divisorForMinutes % 60;
    const seconds = Math.ceil(divisorForSeconds);
    return {
      hours,
      minutes,
      seconds
    };
  }

  startTimer(seconds: number): void {
    if (seconds > 0) {
      const intervalId = setInterval(this.countDown, 1000);
      this.setState({ intervalId });
    }
  }

  countDown(): void {
    const { seconds, intervalId } = this.state;
    const currentSecond = seconds - 1;

    if (currentSecond === 0) {
      clearInterval(intervalId);
    }
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: currentSecond
    });
  }

  componentWillUnmount(): void {
    const { intervalId } = this.state;

    if (intervalId) {
      clearInterval(intervalId);
    }
  }

  formatTime(time: number): string | number {
    if (!time) {
      return '00';
    } else if (String(time).length < 2) {
      return `0${time}`;
    } else {
      return time;
    }
  }

  render(): ReactNode {
    const {
      time: { minutes, hours, seconds }
    } = this.state;
    const { onCloseModal, t } = this.props;
    return (
      <FormGroup className="has-float-label">
        <Modal isOpen={true} toggle={onCloseModal}>
          <ModalHeader>{t('login_block_header')}</ModalHeader>
          <ModalBody>
            {t('login_block_text')}
            <p style={{ color: 'red' }}>
              {t('login_block_timer')} {this.formatTime(hours)} : {this.formatTime(minutes)} :{' '}
              {this.formatTime(seconds)}
            </p>
            {t('login_block_contact')} <SupportEmailLink />
          </ModalBody>
        </Modal>
      </FormGroup>
    );
  }
}

export default withTranslation()(ModalCountDown);
