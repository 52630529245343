import React, { FunctionComponent } from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';

import RequestListPage from './RequestListPage';
import InfoPage from './InfoPage';

const Support: FunctionComponent<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}/`} component={RequestListPage} />
    <Route path={`${match.url}/:id`} component={InfoPage} />
    <Redirect to={'/error'} />
  </Switch>
);

export default Support;
