import React from 'react';
import { FormGroup, Label, Row } from 'reactstrap';
import { Field } from 'formik';
import Select from 'react-select';

import { Colxx } from '../../../../components/common/CustomBootstrap';
import { PayoutField } from '../../../../redux/operations/interface';
import { SelectorOption } from '../../../../interfaces/app';

const CreatePayoutsAdditionalFields = ({ fields, values, setFieldValue, setFieldTouched, errors, touched, t }) => (
  <Row className="mb-4">
    {fields.map(({ param_desc, param_name, param_preset }: PayoutField) => (
      <Colxx key={param_name} xxs="12" sm="6" lg="3">
        <FormGroup className="has-float-label">
          <Label className="float-label">{t(`payout_addition.${param_desc}`)}</Label>

          {!param_preset && (
            <Field
              className="form-control"
              type="text"
              name={param_name}
              id={param_name}
              onBlur={() => setFieldTouched(param_name, true)}
            />
          )}

          {param_preset && (
            <Select
              className="react-select"
              classNamePrefix="react-select"
              name={param_name}
              id={param_name}
              value={values[param_name]}
              onChange={(value: SelectorOption) => setFieldValue(param_name, value)}
              options={param_preset
                .split(',')
                .map((value: string, index: number) => ({ key: index, value, label: value }))}
              placeholder=""
            />
          )}

          {errors[param_name] && touched[param_name] && (
            <div className="invalid-feedback d-block">{errors[param_name]}</div>
          )}
        </FormGroup>
      </Colxx>
    ))}
  </Row>
);

export default CreatePayoutsAdditionalFields;
