import React, { ReactNode } from 'react';
import cn from 'classnames';

interface WithTooltipProps {
  tooltipText: string;
}
interface WithTooltipPState {
  isHover: boolean;
}

export default class WithTooltip extends React.Component<WithTooltipProps, WithTooltipPState> {
  state = {
    isHover: false
  };

  handleMouseOver = () => {
    const { isHover } = this.state;
    !isHover && this.setState({ isHover: true });
  };

  handleMouseOut = () => {
    const { isHover } = this.state;
    isHover && this.setState({ isHover: false });
  };

  render(): ReactNode {
    const { children, tooltipText } = this.props;
    const { isHover } = this.state;

    return (
      <>
        {children}
        <div
          className={cn('simple-icon-question tooltip-question cursor-pointer')}
          onMouseEnter={this.handleMouseOver}
          onMouseLeave={this.handleMouseOut}
        >
          {isHover && <div className="invalid-feedback d-block">{tooltipText}</div>}
        </div>
      </>
    );
  }
}
