import {
  GET_AVAILABLE_SITES,
  GET_AVAILABLE_SITES_SUCCESS,
  GET_AVAILABLE_SITES_ERROR,
  PUT_AVAILABLE_SITES_ERROR
} from '../actions';

import { SitesReducerState } from './interface';

const INIT_STATE: SitesReducerState = {
  sites: {
    data: null,
    error: '',
    loading: false
  }
};

export default (state: SitesReducerState = INIT_STATE, { type, payload }): SitesReducerState => {
  switch (type) {
    case GET_AVAILABLE_SITES:
      return {
        ...state,
        sites: {
          ...state.sites,
          loading: true
        }
      };
    case GET_AVAILABLE_SITES_SUCCESS:
      return {
        ...state,
        sites: {
          data: payload.data,
          loading: false,
          error: ''
        }
      };
    case GET_AVAILABLE_SITES_ERROR:
      return {
        ...state,
        sites: {
          ...state.sites,
          loading: false,
          data: null,
          error: payload.error
        }
      };
    case PUT_AVAILABLE_SITES_ERROR:
      return {
        ...state,
        sites: {
          ...state.sites,
          loading: false,
          data: null,
          error: payload.error
        }
      };
    default:
      return { ...state };
  }
};
