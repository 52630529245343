import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Row, Card, CardBody } from 'reactstrap';
import { NavLink } from 'react-router-dom';

import { restore2FA } from '../../../redux/authUser/actions';
import { NotificationManager } from '../../../components/common/react-notifications';
import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import Breadcrumb from '../../../containers/navs/Breadcrumb';
import ModalReset2FaConfirm from './ModalReset2FaConfirm';

import { NOTIFICATION_TIMEOUT } from '../../../constants/defaultValues';
import { RESTORE_2FA_KEYS } from '../../user/Login/constant';
import { AuthType } from './constant';
import { UserProfileProps, UserProfileState } from './interface';
import { withTranslation } from 'react-i18next';
class UserProfile extends Component<UserProfileProps, UserProfileState> {
  state: UserProfileState = {
    isResetModalOpen: false
  };

  componentDidUpdate(prevProps: Readonly<UserProfileProps>): void {
    const { error, loading } = this.props;

    if (prevProps.error !== error && error) {
      NotificationManager.info(error, 'Ошибка', NOTIFICATION_TIMEOUT, null, null, '');
    }

    if (prevProps.loading && !loading && !error) {
      const message = 'Ссылка для восстановления отправлена на почту';
      NotificationManager.info(message, 'Успешно', NOTIFICATION_TIMEOUT, null, null, '');
    }
  }

  onReset2FA = () => {
    this.props.restoreCodeAction({ restoreType: RESTORE_2FA_KEYS.ResetSecondFactor });
  };

  render(): ReactNode {
    const { authUser, match, t } = this.props;
    const { isResetModalOpen } = this.state;

    return (
      <>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.profile" match={match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>

        <Card className="profile mb-4">
          <CardBody>
            {authUser && (
              <Row>
                <Colxx xs="12">
                  <div className="d-flex">
                    <div className="user-avatar-icon d-sm-block">
                      <i className="simple-icon-user" />
                    </div>

                    <div className="table mb-3">
                      <table>
                        <tbody>
                          <tr key="fio">
                            <td key="title">{t('name')}</td>
                            <td key="value">{authUser.fio}</td>
                          </tr>
                          <tr key="phone">
                            <td key="title">{t('Phone number')}</td>
                            <td key="value">{authUser.phone}</td>
                          </tr>
                          <tr key="email">
                            <td key="title">{t('e-mail')}</td>
                            <td key="value">{authUser.email}</td>
                          </tr>
                        </tbody>
                      </table>

                      <div className="action-links">
                        <NavLink to="/profile/change-password" className="link d-block mb-2">
                          {t('Change password')}
                        </NavLink>
                        {authUser.auth_type === AuthType.GoogleAuthRequired && (
                          <NavLink
                            to="#"
                            className="link d-block mb-2"
                            onClick={() => this.setState({ isResetModalOpen: true })}
                          >
                            {t('reset_2fa')}
                          </NavLink>
                        )}
                      </div>
                    </div>
                  </div>
                </Colxx>
              </Row>
            )}
          </CardBody>
        </Card>

        {isResetModalOpen && (
          <ModalReset2FaConfirm
            onConfirmModal={this.onReset2FA}
            onCloseModal={() => this.setState({ isResetModalOpen: false })}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = ({ authUser }) => ({
  authUser: authUser.user.data,
  error: authUser.restore2Fa.error,
  loading: authUser.restore2Fa.loading
});

const mapDispatchToProps = {
  restoreCodeAction: restore2FA
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UserProfile)
);
