import React, { PureComponent, ReactNode } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { ModalConfirmDeleteProps } from './interface';

class ModalConfirmDelete extends PureComponent<ModalConfirmDeleteProps> {
  onSubmit = () => {
    const { onConfirmModal, mustBeDeleted, onCloseModal } = this.props;
    onConfirmModal(mustBeDeleted);
    onCloseModal();
  };

  render(): ReactNode {
    const { onCloseModal, t } = this.props;

    return (
      <Modal isOpen={true} toggle={onCloseModal}>
        <ModalHeader toggle={onCloseModal}>{t('modal.order_delete.title')}</ModalHeader>
        <ModalBody>{t('modal.order_delete.description')}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.onSubmit}>
            {t('yes')}
          </Button>{' '}
          <Button color="secondary" onClick={onCloseModal}>
            {t('no')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default withTranslation()(ModalConfirmDelete);
