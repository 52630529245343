import { addDays, isSameDay, startOfDay, startOfHour, startOfMonth, startOfWeek, subDays } from 'date-fns';
import { TreeNode } from 'react-dropdown-tree-select';

import { ServiceTypes } from '../operations/OperationsList/constants';
import { SelectorOption } from '../../interfaces/app';
import { ChartsPeriodValue, StatisticsDirectionSelectOptions } from './interface';
import {
  ALL_SOURCES,
  STATISTICS_OUTCOME_DIRECTIONS_OPTIONS,
  STATISTICS_SERVICES,
  STATISTICS_SERVICES_OPTIONS
} from './constants';
import { CurrenciesData, DestinationsData, PayoutAccount } from '../../redux/operations/interface';
import { ACCOUNT_ID, ALL_CURRENCY_OPTION, ALL_TREE, PARTNER_ID, RUB_CURRENCY_OPTION } from '../../constants/app';
import { DIRECTIONS_VALUES, OperationTypeKeys, OperationTypes } from '../operations/constants';
import { toISOString } from '../../helpers/Utils';

export const getDateFrom = (period: ChartsPeriodValue) => {
  switch (period) {
    case ChartsPeriodValue.Hour:
      return startOfHour(subDays(new Date(), 1));
    case ChartsPeriodValue.Week:
      return startOfWeek(new Date(), { weekStartsOn: 1 });
    case ChartsPeriodValue.Day:
    case ChartsPeriodValue.Month:
      return startOfMonth(new Date());
    default:
      return new Date();
  }
};

export const roundChartData = (maxCount: number, values: number[], labels: string[]): any =>
  values.reduce(
    (acc: any, val, i) => {
      if (val !== undefined) {
        return { ...acc, values: [...acc.values, val], labels: [...acc.labels, labels[i]] };
      }
      return acc;
    },
    {
      values: [],
      labels: []
    }
  );

export const updateScrollPane = (chartRef: any, scrollRef: any, scrollPaneRef: any) => {
  const allLabels = (chartRef as any).chartInstance.config.data.labels;
  const displayedLabels = (chartRef as any).chartInstance.scales['x-axis-0'].ticks;
  const chartZoomRatio = allLabels.length / displayedLabels.length;
  const scrollContainerWidth = (scrollRef as any)._container.clientWidth;
  const scrollPaneWidth = Math.round(scrollContainerWidth * chartZoomRatio);
  const maxScrollSize = scrollPaneWidth - scrollContainerWidth;
  const maxLabelsOffset = allLabels.length - displayedLabels.length;
  const pxPerLabel = maxLabelsOffset ? maxScrollSize / maxLabelsOffset : 0;
  const firstDisplayedLabel = (chartRef as any).chartInstance.scales['x-axis-0'].options.ticks.min;
  const indexOfFirstLabel = allLabels.indexOf(firstDisplayedLabel);
  (scrollPaneRef as any).style.width = `${scrollPaneWidth}px`;
  (scrollRef as any)._container.scrollLeft = Math.round(pxPerLabel * indexOfFirstLabel);
  (scrollRef as any).updateScroll();
};

export const onPaneScrollX = (scrollBar: any, chartRef: any): void => {
  const maxScrollSize = scrollBar.scrollWidth - scrollBar.offsetWidth;
  const scrollSize = scrollBar.scrollLeft;
  const allLabels = (chartRef as any).chartInstance.config.data.labels;
  const displayedLabels = (chartRef as any).chartInstance.scales['x-axis-0'].ticks;
  const maxLabelsOffset = allLabels.length - displayedLabels.length;
  const pxPerLabel = maxScrollSize / maxLabelsOffset;
  const labelsOffset = Math.round(scrollSize / pxPerLabel);
  (chartRef as any).chartInstance.scales['x-axis-0'].options.ticks.min = allLabels[labelsOffset];
  (chartRef as any).chartInstance.scales['x-axis-0'].options.ticks.max =
    allLabels[labelsOffset + displayedLabels.length - 1];
  (chartRef as any).chartInstance.update(0);
};

export const getAccountTreeData = (accounts: PayoutAccount[], t: any) => {
  if (accounts && accounts.length) {
    return [
      {
        ...ALL_TREE(t),
        children: [
          ...accounts.map(({ name, id, source }, index) => ({
            key: `${id}_${name}_${index}`,
            label: name,
            value: id,
            source,
            account_id: id,
            name
          }))
        ]
      }
    ];
  } else {
    return [ALL_TREE(t)];
  }
};

export const getAccountsBySource = (
  serviceType: ServiceTypes,
  accounts: PayoutAccount[],
  t: any,
  directions = ALL_SOURCES,
  destinations: number[] = []
) => {
  if (!(accounts && accounts.length)) {
    return [];
  }
  const accountsBySource = accounts.filter(({ source, destination }) => {
    let flag = directions.length === ALL_SOURCES.length;
    directions &&
      directions.forEach((direction: number) => {
        if (destination && destinations.includes(destination)) {
          flag = true;
        } else if (direction === source && !destination) {
          flag = true;
        }
      });
    return flag;
  });

  return getAccountTreeData(accountsBySource, t);
};

export const getAccountsByCurrency = (accounts: PayoutAccount[], selectedCurrency: string | null) => {
  if (!(accounts && accounts.length)) {
    return [];
  }
  if (!selectedCurrency || selectedCurrency.length > 3) {
    return accounts;
  }

  const accountsByCurrency = accounts.filter(({ currency }) => {
    return !selectedCurrency || selectedCurrency === currency;
  });
  return accountsByCurrency;
};

export const getAccounts = (
  serviceType: ServiceTypes,
  t: any,
  accounts: { [key: number]: PayoutAccount[] },
  directions = ALL_SOURCES,
  selectedCurrency = '',
  destinations: number[] = []
): SelectorOption[] => {
  if (!accounts) {
    return [];
  }
  return getAccountsBySource(
    serviceType,
    getAccountsByCurrency(accounts[serviceType], selectedCurrency),
    t,
    directions,
    destinations
  );
};

export const getDirectionsOptions = (selectedService, destinations, t): StatisticsDirectionSelectOptions[] => {
  if (
    selectedService === STATISTICS_SERVICES_OPTIONS(t)[STATISTICS_SERVICES.OUTCOME] &&
    destinations &&
    destinations.length
  ) {
    return [
      ...STATISTICS_OUTCOME_DIRECTIONS_OPTIONS(t),
      ...destinations.map((destination: DestinationsData) => ({
        label: destination.name,
        value: {
          source: STATISTICS_OUTCOME_DIRECTIONS_OPTIONS(t)[0].value.source,
          destination: destination.id
        },
        key: destination.id
      }))
    ];
  } else {
    return selectedService.value.directionsOptions;
  }
};

export const getCurrenciesOptions = (currencies, serviceType: number, t: any, withAllOption = true) => {
  if (serviceType === ServiceTypes.Mc) {
    return [RUB_CURRENCY_OPTION];
  }

  if (currencies && currencies.length) {
    const currenciesOptions = currencies.map(({ name, id }) => ({
      label: name,
      value: id
    }));

    if (withAllOption) {
      return [
        {
          ...ALL_TREE(t),
          children: currenciesOptions
        }
      ];
    } else {
      return currenciesOptions;
    }
  } else {
    return ALL_CURRENCY_OPTION(t);
  }
};

export const getActiveCurrencies = (allCurrencies: CurrenciesData[], serviceType: number) => {
  if (serviceType === OperationTypes.Mc) {
    return [
      {
        id: 1,
        name: 'RUB',
        country: 'Россия',
        is_income_active: false,
        is_outcome_active: false,
        is_disposals_active: false
      }
    ];
  } else {
    return allCurrencies ? allCurrencies.filter((item: CurrenciesData) => item[OperationTypeKeys[serviceType]]) : [];
  }
};

export const getShopNames = (values, accountsData: { [serviceType: number]: PayoutAccount[] }) => {
  const allAccounts =
    accountsData && values.service.value && values.service.value.operationType
      ? accountsData[values.service.value.operationType]
      : null;

  if (
    allAccounts &&
    values.account.length &&
    values.service.value.operationType === ServiceTypes.Income &&
    values.direction.value &&
    values.direction.value.source &&
    values.direction.value.source.find(
      (source: number) => source === DIRECTIONS_VALUES.FP || source === DIRECTIONS_VALUES.SWP
    ) &&
    allAccounts.length !== values.account.length
  ) {
    return getIncomeShopNames(values.account, allAccounts);
  } else {
    return [];
  }
};

export const getIncomeShopNames = (selectedAccounts, allAccounts) => {
  if (!(selectedAccounts && selectedAccounts.length)) {
    return [];
  }

  const newData = selectedAccounts.filter(
    ({ source }) => source === DIRECTIONS_VALUES.FP || source === DIRECTIONS_VALUES.SWP
  );

  const unselectedAccounts = removeDuplicatesByProp(getUnselectedAccounts(selectedAccounts, allAccounts), 'id');
  let filteredAccounts: string[] = [];

  unselectedAccounts &&
    unselectedAccounts.length &&
    unselectedAccounts.forEach(({ id }) => {
      const matches = newData && newData.filter(({ account_id }) => account_id === id);
      if (matches && matches.length) {
        filteredAccounts = [...filteredAccounts, ...matches.map(({ account_id, name }) => `${account_id}_${name}`)];
      }
    });
  return filteredAccounts;
};

export const getRequestAccounts = (accounts: SelectorOption[]): number[] => {
  const selectorValues =
    accounts && accounts.length ? accounts.map((accountItem: SelectorOption) => accountItem.value) : [];

  return selectorValues.filter((item: number, index: number) => item && selectorValues.indexOf(item) === index);
};

export const getUnselectedAccounts = (selected, all): { id: number; name: string; index: number }[] => {
  if (!(selected && selected.length && all && all.length)) {
    return [];
  }

  const selectedAccounts =
    selected && selected.length ? selected.map(({ account_id, name }, index) => ({ id: account_id, name, index })) : [];
  const allAccounts = all && all.length ? all.map(({ id, name }, index) => ({ id, name, index })) : [];

  const comparer = (otherArray) => {
    return (current) => {
      return (
        otherArray.filter((other) => {
          return other.account_id === current.account_id && other.name === current.name;
        }).length === 0
      );
    };
  };

  return allAccounts.filter(comparer(selectedAccounts));
};

export const prepareStatisticParams = (
  values,
  merchantIdList: number[],
  userId: number | undefined,
  accounts: { [serviceType: number]: PayoutAccount[] },
  t: any
) => {
  const partnerAccountParam = {};
  const allAccounts = values.account.length === 1 && !values.account[0].value;

  partnerAccountParam[PARTNER_ID] = merchantIdList;

  if (values.account && values.account.length && !allAccounts) {
    partnerAccountParam[ACCOUNT_ID] = getRequestAccounts(values.account);
  }

  const siteIds = values.site ? values.site.value : values.direction.value.source;

  return {
    ...partnerAccountParam,
    userId,
    from: toISOString(startOfDay(values.dateFrom)),
    to: isSameDay(new Date(), values.dateTo)
      ? toISOString(new Date())
      : toISOString(startOfDay(addDays(values.dateTo, 1))),
    service_type: values.service.value.operationType,
    source: getDirectionsByAccounts(values.account, siteIds),
    destination: values.direction ? values.direction.value.destination : undefined,
    currency: values.currency.value,
    showRefund: values.service === STATISTICS_SERVICES_OPTIONS(t)[0],
    shop_name: getShopNames(values, accounts),
    byAccount: values.byAccount
  };
};

export const getDirectionsByAccounts = (accounts: TreeNode[], selectedSources: number[]): number[] => {
  const sources: number[] = [];

  if ((accounts && accounts.length === 1 && !accounts[0].value) || !(accounts && accounts.length)) {
    return selectedSources;
  }

  accounts &&
    accounts.length &&
    accounts.forEach((account: TreeNode) => {
      if (account.source && !sources.includes(account.source)) {
        sources.push(account.source);
      }
    });
  return sources;
};

export const removeDuplicatesByProp = (array, prop: string) =>
  array.filter((obj, pos, arr) => arr.map((mapObj: Object) => mapObj[prop]).indexOf(obj[prop]) === pos);
