import axios, { AxiosResponse, AxiosError } from 'axios';

import { CONFIG } from '../../config/appConfig';
import { ResponseStatusCode } from '../../constants/app';
import { reduxStore } from '../../redux/store';
import { handleNonAuthorized } from '../../redux/actions';

export enum ContentType {
  ApplicationFormUrlencoded = 'application/x-www-form-urlencoded',
  ApplicationJson = 'application/json'
}

function prepareHeaders(headers: object, contentType?: ContentType): object {
  return {
    'Content-Type': contentType ? contentType : ContentType.ApplicationJson,
    ...headers
  };
}

function prepareErrorResponse(error: AxiosError): { error: string; status?: number } {
  if (error.response) {
    if (error.response.status === ResponseStatusCode.Unauthorized) {
      reduxStore.dispatch(handleNonAuthorized());

      return {
        status: error.response.status,
        error: error.response.data.message
      };
    }

    return {
      error: error.response.data.message || error,
      status: error.response.status
    };
  }

  return {
    error: 'empty'
  };
}

function get<T>(url: string, headers: object = {}): Promise<T | { error: string }> {
  if (!url || url.length === 0) {
    return new Promise((resolve, reject) => {
      reject('No passed url!');
    });
  }

  return axios
    .get<T>(`${CONFIG.apiURL()}${url}`, {
      headers: prepareHeaders(headers) as HeadersInit,
      withCredentials: true
    })
    .then(({ data }: AxiosResponse<T>) => data)
    .catch((error: AxiosError) => prepareErrorResponse(error));
}

function post<T>(
  url: string,
  body?: object | FormData | string,
  headers: object = {},
  contentType?: ContentType
): Promise<T | { error: string }> {
  if (!url || url.length === 0) {
    return new Promise((resolve, reject) => {
      reject('No passed url!');
    });
  }

  const requestBody = typeof body === 'string' ? body : body instanceof FormData ? body : JSON.stringify(body);

  return axios
    .post<T>(`${CONFIG.apiURL()}${url}`, requestBody, {
      headers: body instanceof FormData ? headers : (prepareHeaders(headers, contentType) as HeadersInit),
      withCredentials: true
    })
    .then(({ data }: AxiosResponse<T>) => data)
    .catch((error: AxiosError) => prepareErrorResponse(error));
}

function put<T>(url: string, body: object | FormData | string, headers: object = {}): Promise<T | { error: string }> {
  if (!url || url.length === 0) {
    return new Promise((resolve, reject) => {
      reject('No passed url!');
    });
  }

  const requestBody = typeof body === 'string' ? body : body instanceof FormData ? body : JSON.stringify(body);

  return axios
    .put<T>(`${CONFIG.apiURL()}${url}`, requestBody, {
      headers: prepareHeaders(headers) as HeadersInit,
      withCredentials: true
    })
    .then(({ data }: AxiosResponse<T>) => data)
    .catch((error: AxiosError) => prepareErrorResponse(error));
}

function remove<T>(url: string, body?: object, headers: object = {}): Promise<T | { error: string }> {
  if (!url || url.length === 0) {
    return new Promise((resolve, reject) => {
      reject('No passed url!');
    });
  }

  return axios
    .delete(`${CONFIG.apiURL()}${url}`, {
      headers: prepareHeaders(headers) as HeadersInit,
      withCredentials: true
    })
    .then(({ data }: AxiosResponse<T>) => data)
    .catch((error: AxiosError) => prepareErrorResponse(error));
}

function getFile<T>(url: string): Promise<AxiosResponse<T> | { error: string }> {
  if (!url || url.length === 0) {
    return new Promise((resolve, reject) => {
      reject('No passed url!');
    });
  }

  return axios
    .get<T>(`${CONFIG.apiURL()}${url}`, {
      withCredentials: true,
      responseType: 'blob'
    })
    .then((response: AxiosResponse<T>) => response)
    .catch((error: AxiosError) => prepareErrorResponse(error));
}

export const ApiRequest = {
  get,
  post,
  put,
  remove,
  getFile
};
