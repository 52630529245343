import React, { PureComponent, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Label, FormGroup, Button, CardTitle } from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import { RESTORE_TYPES, RESTORE_LABELS } from '../Login/constant';

import { trimSpaces, validateEmail } from '../../../helpers/Utils';
import { withTranslation } from 'react-i18next';
import { RestorePasswordProps } from './interface';
import { getTemporaryPassword } from '../../../redux/authUser/actions';

class RestorePassword extends PureComponent<RestorePasswordProps> {
  validateEmailHandle = (value: string): string | undefined => {
    const { t } = this.props;

    let error;
    if (!value) {
      error = t('tooltip.enter_email');
    } else if (!validateEmail(value)) {
      error = t('tooltip.unvalid_email');
    }

    return error;
  };

  render(): ReactNode {
    const { loading, getTmpPassAction, t } = this.props;

    return (
      <Formik initialValues={{ email: '' }} onSubmit={getTmpPassAction}>
        {({ errors, touched, values, setFieldValue }) => (
          <Form className="av-tooltip tooltip-label-bottom">
            <CardTitle className="mb-4">
              <Label for="reset_type">{RESTORE_LABELS(t)[RESTORE_TYPES.RestorePassword].text}</Label>
            </CardTitle>
            <FormGroup className="form-group has-float-label">
              <Label className="float-label">{t('directories.e-mail')}</Label>
              <Field
                className="form-control"
                name="email"
                validate={this.validateEmailHandle}
                onChange={(e) => {
                  setFieldValue('email', trimSpaces(e.target.value));
                }}
              />
              {errors.email && touched.email && <div className="invalid-feedback d-block">{errors.email}</div>}
            </FormGroup>
            <div className="d-flex justify-content-center">
              <Button
                type="submit"
                color="primary"
                className={`btn-multiple-state ${loading ? 'show-spinner' : ''}`}
                disabled={values.email.length === 0 || !!errors.email}
              >
                <span className="spinner d-inline-block">
                  <span className="bounce1" />
                  <span className="bounce2" />
                  <span className="bounce3" />
                </span>
                <span className="label">{t('button.Send password')}</span>
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

const mapDispatchToProps = {
  getTmpPassAction: getTemporaryPassword
};

export default withTranslation()(
  connect(
    null,
    mapDispatchToProps
  )(RestorePassword)
);
