import {
  GET_AVAILABLE_SITES,
  GET_AVAILABLE_SITES_SUCCESS,
  GET_AVAILABLE_SITES_ERROR,
  PUT_AVAILABLE_SITES,
  PUT_AVAILABLE_SITES_ERROR,
  PUT_AVAILABLE_SITES_SUCCESS
} from '../actions';
import { GetSitesActionResult, PutSitesActionResult } from './interface';

// GET AVAILABLE SITES
export const getAvailableSites = (id: string): GetSitesActionResult => ({
  type: GET_AVAILABLE_SITES,
  payload: { id }
});

export const getAvailableSitesSuccess = (data: any): any => ({
  type: GET_AVAILABLE_SITES_SUCCESS,
  payload: {
    data
  }
});

export const getAvailableSitesError = (error: string): any => ({
  type: GET_AVAILABLE_SITES_ERROR,
  payload: { error }
});

export const putAvailableSites = (id: string, sites: number[]): PutSitesActionResult => ({
  type: PUT_AVAILABLE_SITES,
  payload: {
    id,
    sites
  }
});

export const putSitesSuccess = (sites: number[]): any => ({
  type: PUT_AVAILABLE_SITES_SUCCESS,
  payload: {
    sites
  }
});

export const putSitesError = (error: string): any => ({
  type: PUT_AVAILABLE_SITES_ERROR,
  payload: {
    error
  }
});
