import { User } from './interface';

export const updatedUserData = (storeData: User[] | null, newData: User): User[] | null => {
  const updatedUsers: User[] = storeData ? [...storeData] : [];

  storeData &&
    storeData.forEach(({ id }, index) => {
      if (newData.id === id) {
        updatedUsers[index] = newData;
      }
    });

  return updatedUsers ? updatedUsers : storeData;
};
