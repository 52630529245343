import { PayoutAccount } from '../../../redux/operations/interface';

export const getPartnerAccounts = (
  accounts: { [serviceType: number]: PayoutAccount[] },
  partnerId: number
): PayoutAccount[] => {
  const partnerAccounts: PayoutAccount[] = [];

  Object.values(accounts).forEach((service: PayoutAccount[]) => {
    service &&
      service.forEach((account: PayoutAccount) => {
        if (account.partner_id === partnerId) {
          partnerAccounts.push(account);
        }
      });
  });

  return partnerAccounts;
};
