import React, { Fragment, Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Row, Card, CardBody } from 'reactstrap';
import ReactTable from 'react-table';

import { NotificationManager } from '../../components/common/react-notifications';
import { Colxx, Separator } from '../../components/common/CustomBootstrap';
import { NotFoundMessage } from '../../components/common/NotFoundMessage';
import { Loader } from '../../components/common/Loader';
import Breadcrumb from '../../containers/navs/Breadcrumb';
import { getCsvList, resetDownloads, resetGlossaryPartners } from '../../redux/actions';

import { withTranslation } from 'react-i18next';
import { getDownloadsTableColumns } from './helpers';
import { DownloadsProps, DownloadsState } from './interface';
import { NOTIFICATION_TIMEOUT } from '../../constants/defaultValues';
import { STYLE_SUB_COMPONENT, DEFAULT_SORTER } from './constants';
import DataTablePagination from '../../components/DatatablePagination';
import { Merchant } from '../settings/Permissions/interface';
import ReactTooltip from 'react-tooltip';

class Documents extends Component<DownloadsProps, DownloadsState> {
  state: DownloadsState = {
    page: 0,
    expanded: {}
  };

  componentDidMount(): void {
    const { isAdmin, authUserId, selectedUserId, getCsvListAction } = this.props;

    const userId = isAdmin && authUserId !== selectedUserId ? selectedUserId : undefined;
    getCsvListAction(userId);
  }

  componentDidUpdate(prevProps: DownloadsProps): void {
    const { isAdmin, selectedUserId, authUserId, error, getCsvListAction } = this.props;

    if (isAdmin && prevProps.selectedUserId !== selectedUserId) {
      const userId = isAdmin && authUserId !== selectedUserId ? selectedUserId : undefined;
      getCsvListAction(userId);
    }

    if (error && error !== prevProps.error) {
      NotificationManager.info(error, 'Ошибка', NOTIFICATION_TIMEOUT, null, null, '');
    }
  }

  componentWillUnmount(): void {
    this.props.resetDownloadsAction();
    this.props.resetGlossaryPartnersAction();
  }

  setNewPage = (pageNumber: number) => {
    this.setState({ page: pageNumber });
  };

  render(): ReactNode {
    const { data, loading, isAdmin, authUserId, selectedUserId, match, partners, maxPageSize, t } = this.props;
    const { page } = this.state;

    return (
      <Fragment>
        {loading && <Loader />}

        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.downloads" match={match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>

        {data && data.length > 0 && (
          <Card className="mb-4">
            <CardBody>
              <ReactTooltip place="left" type="success" effect="solid" backgroundColor="grey" />
              <ReactTable
                data={data}
                columns={getDownloadsTableColumns(
                  partners ? partners : [],
                  t,
                  isAdmin && authUserId !== selectedUserId ? selectedUserId : undefined
                )}
                page={page}
                pages={Math.ceil(data.length / maxPageSize)}
                defaultPageSize={maxPageSize}
                pageSize={maxPageSize}
                sortable={true}
                filterable={false}
                showPageJump={true}
                showPagination={true}
                PaginationComponent={DataTablePagination}
                onPageChange={this.setNewPage}
                showPageSizeOptions={false}
                loading={loading}
                minRows={0}
                defaultSorted={DEFAULT_SORTER}
                loadingText=""
                noDataText="Нет данных"
                onExpandedChange={(expanded, index, event) => {
                  this.setState({ expanded });
                }}
                className="-striped -highlight"
                expanded={this.state.expanded}
                SubComponent={(row: any) => (
                  <ul style={STYLE_SUB_COMPONENT}>
                    {row.original.partner_id.slice(1).map((item, i) => {
                      const currPartner =
                        partners && partners.length && partners.find((partner: Merchant) => partner.id === item);
                      return <li key={i}>{currPartner ? currPartner.name : item}</li>;
                    })}
                  </ul>
                )}
              />
            </CardBody>
          </Card>
        )}

        {Boolean(!data || (data && !data.length)) && <NotFoundMessage text={t('menu.downloads.not_found')} />}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ downloads, authUser, settings, menu }) => ({
  data: downloads.csvList.data,
  loading: downloads.csvList.loading,
  error: downloads.csvList.error,
  maxPageSize: settings.gcmSettings.data ? settings.gcmSettings.data.max_page_size : 50,
  partners: menu.merchants.data,
  isAdmin: authUser.user.data && authUser.user.data.is_admin ? authUser.user.data.is_admin : false,
  authUserId: authUser.user.data ? authUser.user.data.id : 0,
  selectedUserId: settings.selectedUser ? settings.selectedUser.id : 0
});

const mapDispatchToProps = {
  getCsvListAction: getCsvList,
  resetDownloadsAction: resetDownloads,
  resetGlossaryPartnersAction: resetGlossaryPartners
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Documents)
);
