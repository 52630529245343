import { all, call, fork, put, SimpleEffect, delay, takeLatest, takeEvery } from 'redux-saga/effects';
import { CombinatorEffect } from '@redux-saga/types';

import { createQueryString } from '../../helpers/Utils';
import { ApiRequest } from '../../helpers/ApiRequest/ApiRequest';
import {
  GET_GLOSSARY_PARTNERS,
  getGlossaryPartnersSuccess,
  getGlossaryPartnersError,
  GET_GLOSSARY_CONTRACTS,
  getGlossaryContractsSuccess,
  getGlossaryContractsError,
  GET_GLOSSARY_USERS,
  getGlossaryUsersSuccess,
  getGlossaryUsersError,
  GET_SERVICE_TYPE_DOCS,
  getServiceTypeDocsSuccess,
  getServiceTypeDocsError,
  POST_SERVICE_TYPE_DOCS,
  postServiceTypeDocsSuccess,
  postServiceTypeDocsError,
  DELETE_SERVICE_TYPE_DOCS,
  deleteServiceTypeDocsSuccess,
  deleteServiceTypeDocsError,
  getGlossaryUserPartnersError,
  getGlossaryUserPartnersSuccess,
  GET_GLOSSARY_USER_PARTNERS,
  getUserPartnerAccountsError,
  getUserPartnerAccountsSuccess,
  GET_USER_PARTNER_ACCOUNTS
} from '../actions';

import {
  GetGlossaryPartnersActionResult,
  GetGlossaryContracts,
  GetGlossaryUsersAction,
  GetUserPartnersAction,
  GetUserPartnerAccounts
} from './interface';
import { PARTNER_ID } from '../../constants/app';
import { GetPayoutAccountsResponse } from '../../api.interface';

// GET GLOSSARY PARTNERS
function* getGlossaryPartners({
  payload
}: GetGlossaryPartnersActionResult): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { error, data } = yield call(ApiRequest.get, `/partners/?${createQueryString(payload)}`);

    if (error) {
      yield put(getGlossaryPartnersError(`${error}`));
    } else {
      yield put(getGlossaryPartnersSuccess(data && data.length ? data : []));
    }
  } catch (error) {
    yield put(getGlossaryPartnersError(`${error}`));
  }
}

// GET GLOSSARY CONTRACTS
function* getGlossaryContracts({ payload }: GetGlossaryContracts): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { data, error } = yield call(ApiRequest.get, `/contracts/?${createQueryString(payload)}`);

    if (!error) {
      yield put(getGlossaryContractsSuccess(data));
    } else {
      yield put(getGlossaryContractsError(`${error}`));
    }
  } catch (error) {
    yield put(getGlossaryContractsError(`${error}`));
  }
}

// GET GLOSSARY USERS
function* getGlossaryUsers({ payload }: GetGlossaryUsersAction): Generator<SimpleEffect<string, any>, any, any> {
  const { filterValues, delayRequired } = payload;

  if (delayRequired) {
    yield delay(+'2000');
  }

  try {
    const { data, count, error } = yield call(ApiRequest.get, `/users/?${createQueryString(filterValues)}`);

    if (error) {
      yield put(getGlossaryUsersError(`${error}`));
    } else {
      yield put(getGlossaryUsersSuccess(data, count));
    }
  } catch (error) {
    yield put(getGlossaryUsersError(`${error}`));
  }
}

// GET GLOSSARY USER PARTNERS
function* getGlossaryUserPartners({ payload }: GetUserPartnersAction): Generator<SimpleEffect<string, any>, any, any> {
  const { userId } = payload;

  try {
    const { data, error } = yield call(ApiRequest.get, `/users/${userId}/partners/`);

    if (error) {
      yield put(getGlossaryUserPartnersError(`${error}`));
    } else if (userId) {
      yield put(getGlossaryUserPartnersSuccess(userId, data));
    }
  } catch (error) {
    yield put(getGlossaryUserPartnersError(`${error}`));
  }
}

// GET USER PARTNER ACCOUNTS
function* getGlossaryUserPartnerAccounts({
  payload
}: GetUserPartnerAccounts): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { userId, merchantId } = payload;

    const url = userId ? `/users/${userId}/service-accounts/` : '/user/service-accounts/';
    const queryParams = {
      [PARTNER_ID]: merchantId
    };
    const { data, error }: GetPayoutAccountsResponse = yield call(
      ApiRequest.get,
      `${url}?${createQueryString(queryParams)}`
    );

    if (error) {
      yield put(getUserPartnerAccountsError(`${error}`));
    } else if (userId) {
      yield put(getUserPartnerAccountsSuccess(data ? data : []));
    }
  } catch (error) {
    yield put(getUserPartnerAccountsError(`${error}`));
  }
}

// SERVICES
function* getServiceTypeDocs({ payload }: any): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { serviceType } = payload;

    const { data, error } = yield call(ApiRequest.get, `/service-type-docs/?service_type=${serviceType}`);

    if (error) {
      yield put(getServiceTypeDocsError(`${error}`));
    } else {
      yield put(getServiceTypeDocsSuccess(serviceType, data));
    }
  } catch (error) {
    yield put(getServiceTypeDocsError(`${error}`));
  }
}

function* postServiceTypeDocs({ payload }: any): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { serviceType, doc } = payload;

    const formData = new FormData();
    formData.append('doc', doc);

    const { error } = yield call(ApiRequest.post, `/service-type-docs/?service_type=${serviceType}`, formData);

    if (error) {
      yield put(postServiceTypeDocsError(`${error}`));
    } else {
      yield put(postServiceTypeDocsSuccess());
    }
  } catch (error) {
    yield put(postServiceTypeDocsError(`${error}`));
  }
}

function* deleteServiceTypeDocs({ payload }: any): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { id } = payload;

    const { error } = yield call(ApiRequest.remove, `/service-type-docs/${id}/`);

    if (error) {
      yield put(deleteServiceTypeDocsError(`${error}`));
    } else {
      yield put(deleteServiceTypeDocsSuccess());
    }
  } catch (error) {
    yield put(deleteServiceTypeDocsError(`${error}`));
  }
}

export function* watchGetGlossaryPartners(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(GET_GLOSSARY_PARTNERS, getGlossaryPartners);
}

export function* watchGetGlossaryContracts(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(GET_GLOSSARY_CONTRACTS, getGlossaryContracts);
}

export function* watchGetGlossaryUsers(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(GET_GLOSSARY_USERS, getGlossaryUsers);
}

export function* watchGetGlossaryUserPartners(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(GET_GLOSSARY_USER_PARTNERS, getGlossaryUserPartners);
}

export function* watchGetGlossaryUserPartnerAccounts(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(GET_USER_PARTNER_ACCOUNTS, getGlossaryUserPartnerAccounts);
}

export function* watchGetServiceTypeDocs(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeEvery(GET_SERVICE_TYPE_DOCS, getServiceTypeDocs);
}

export function* watchPostServiceTypeDocs(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeEvery(POST_SERVICE_TYPE_DOCS, postServiceTypeDocs);
}

export function* watchDeleteServiceTypeDocs(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeEvery(DELETE_SERVICE_TYPE_DOCS, deleteServiceTypeDocs);
}

export default function* rootSaga(): Generator<CombinatorEffect<string, any>, any, any> {
  yield all([
    fork(watchGetGlossaryPartners),
    fork(watchGetGlossaryContracts),
    fork(watchGetGlossaryUsers),
    fork(watchGetGlossaryUserPartners),
    fork(watchGetGlossaryUserPartnerAccounts),
    fork(watchGetServiceTypeDocs),
    fork(watchPostServiceTypeDocs),
    fork(watchDeleteServiceTypeDocs)
  ]);
}
