import { ChartsPeriodValue } from '../views/statistics/interface';

export const MIN_DATE_LIMIT = {
  value: 1,
  text: 'Начальная дата должна быть меньше конечной хотя бы на 1 секунду'
};

const daysCount = 3;
const hoursInDay = 24;
const minutesInHour = 60;
const secondsInMinute = 60;

export const MAX_DATE_LIMITS = (t: any) => ({
  [ChartsPeriodValue.Hour]: {
    value: daysCount * hoursInDay * minutesInHour * secondsInMinute, // in seconds
    text: t('statistics.time_interval.hour')
  },
  [ChartsPeriodValue.Day]: {
    value: 3, // in months
    text: t('statistics.time_interval.day')
  },
  [ChartsPeriodValue.Week]: {
    value: 1, // in years
    text: t('statistics.time_interval.week')
  },
  [ChartsPeriodValue.Month]: {
    value: 1, // in years
    text: t('statistics.time_interval.month')
  }
});
