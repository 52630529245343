import { DIRECTIONS_VALUES } from '../constants';
import { CreatePayoutsFormValues } from './CreatePayouts/interface';
export const getPayoutsMenuItems = (t: any) => [
  {
    label: t('operations.create payment'),
    permission: 7,
    path: 'create'
  },
  {
    label: t('operations.сreate batch payment'),
    permission: 8,
    path: 'create-mass'
  },
  {
    label: t('operations.payment history'),
    permission: 13,
    path: 'history'
  },
  {
    label: t('operations.history of payment register'),
    permission: 16,
    path: 'registries-history'
  }
];

export const OPERATIONS_OUTCOME_DIRECTIONS_OPTIONS = [
  {
    key: 1,
    label: 'Все',
    value: {
      source: [DIRECTIONS_VALUES.WP]
    }
  }
];

export const OPERATIONS_DEFAULT_OPTION = (t: any) => ({
  key: '',
  label: t('Не выбрано'),
  value: 0,
  checked: true
});

export const CREATE_PAYOUTS_INITIAL_VALUES = (t: any): CreatePayoutsFormValues => ({
  account_id: OPERATIONS_DEFAULT_OPTION(t),
  destination: OPERATIONS_DEFAULT_OPTION(t),
  id: '',
  amount: '',
  phone: '',
  client: '',
  payout_currency: OPERATIONS_DEFAULT_OPTION(t)
});

export const PAYOUT_CONFIRMATION_EXPIRE_TIME = 600; // time in sec
export const PAYOUT_CONFIRMATION_ATTEMPTS = 3;
