import React, { Component, Fragment, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import { Row, Card, CardBody, Button, FormGroup, Label } from 'reactstrap';
import Select from 'react-select';
import InputMask from 'react-input-mask';
import { isAfter, isSameDay } from 'date-fns';
import isEqual from 'lodash/isEqual';

import { NotificationManager } from '../../../components/common/react-notifications';
import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import { Loader } from '../../../components/common/Loader';
import { DatePickerLocaled } from '../../../components/common/DatePickerLocaled/DatePickerLocaled';
import Breadcrumb from '../../../containers/navs/Breadcrumb';
import { CustomDropdownTreeSelect } from '../../../components/common/CustomDropdownTreeSelect';
import { directionsOptions, getFieldErrorsCount, scrollToTarget, setDirectionsData } from '../../../helpers/Utils';
import {
  getCurrencies,
  getDestinations,
  getPayoutAccounts,
  getSummaryStatistics,
  resetSummaryStatistics,
  getAvailableSites
} from '../../../redux/actions';
import { DATE_FORMAT_BASIC, DATE_MASK, EMPTY_FIELD_ERROR, TIMEZONE_TOOLTIP_TEXT } from '../../../constants/app';
import { NOTIFICATION_TIMEOUT } from '../../../constants/defaultValues';
import { StatisticsServiceSelectOption } from '../interface';
import { SelectorOption } from '../../../interfaces/app';

import {
  getAccounts,
  getActiveCurrencies,
  getCurrenciesOptions,
  getDirectionsOptions,
  prepareStatisticParams
} from '../helpers';
import { prepareData } from './helpers';
import {
  STATISTICS_INITIAL_VALUES,
  STATISTICS_SERVICES_OPTIONS,
  DATE_FROM_TOOLTIP_TEXT,
  DATE_TO_TOOLTIP_TEXT,
  StatisticType,
  NOT_SELECTED_CURRENCY_OPTION,
  STATISTICS_OUTCOME_DIRECTIONS_OPTIONS
} from '../constants';
import { SummaryProps, SummaryState } from './interface';
import { ServiceTypes } from '../../operations/OperationsList/constants';
import { NotFoundMessage } from '../../../components/common/NotFoundMessage';
import { withTranslation } from 'react-i18next';
import { getAvailableSitesOptions, getOneLevelSelectedData } from '../../operations/OperationsList/helpers';

class Summary extends Component<SummaryProps, SummaryState> {
  state: SummaryState = {
    accountOptions: [],
    activeCurrencies: [],
    shouldReset: false
  };
  TargetRef = React.createRef<HTMLDivElement>();
  setFieldValue;
  resetForm;
  serviceType;
  currentCurrency;

  componentDidMount(): void {
    const {
      merchantIdList,
      destinations,
      accounts,
      currencies,
      isAdmin,
      selectedUserId,
      authUserId,
      getDestinationsAction,
      getCurrenciesAction,
      getPayoutAccountsAction,
      getSitesAction,
      t
    } = this.props;

    const isDestinationsExist = destinations && destinations.length;
    const isAccountsExist = accounts && accounts.data;
    const isCurrenciesExist = currencies && currencies.data;

    !isDestinationsExist && getDestinationsAction();

    if (!isAccountsExist && merchantIdList && merchantIdList.length) {
      getPayoutAccountsAction(
        merchantIdList,
        isAdmin && selectedUserId && authUserId !== selectedUserId ? selectedUserId : undefined
      );
    }

    isAccountsExist && this.setState({ accountOptions: getAccounts(this.serviceType, t, accounts.data) });
    !isCurrenciesExist && getCurrenciesAction();

    const activeCurrencies = getActiveCurrencies(
      currencies.data,
      STATISTICS_SERVICES_OPTIONS(t)[0].value.operationType
    );
    activeCurrencies && this.setState({ activeCurrencies });
    getSitesAction(String(selectedUserId || authUserId));
  }

  componentDidUpdate(prevProps: Readonly<SummaryProps>, prevState: Readonly<any>): void {
    const {
      merchantIdList,
      isAdmin,
      authUserId,
      selectedUserId,
      data,
      error,
      currencies,
      accounts,
      resetSummaryStatisticsAction,
      getPayoutAccountsAction,
      getSitesAction,
      t
    } = this.props;
    const { accountOptions } = this.state;

    if (error && error !== prevProps.error) {
      NotificationManager.info(error, 'Ошибка', NOTIFICATION_TIMEOUT, null, null, '');
    }

    if (prevProps.data !== data && data) {
      scrollToTarget(this.TargetRef.current);
    }

    if (prevProps.accounts.data !== accounts.data && !accounts.loading && !accounts.error) {
      this.setState({ accountOptions: getAccounts(this.serviceType, t, accounts.data) });
    }

    if (prevState.accountOptions !== accountOptions) {
      this.setFieldValue && this.setFieldValue('account', accountOptions);
    }

    if (prevProps.currencies.data !== currencies.data) {
      const activeCurrenciesList = getActiveCurrencies(
        currencies.data,
        STATISTICS_SERVICES_OPTIONS(t)[0].value.operationType
      );
      activeCurrenciesList && this.setState({ activeCurrencies: activeCurrenciesList });
    }

    if (!isEqual(prevProps.merchantIdList, merchantIdList) || prevProps.selectedUserId !== selectedUserId) {
      resetSummaryStatisticsAction();

      if (merchantIdList && merchantIdList.length) {
        getPayoutAccountsAction(
          merchantIdList,
          isAdmin && selectedUserId && authUserId !== selectedUserId ? selectedUserId : undefined
        );
      }
    }

    if (
      prevProps.merchantIdList &&
      prevProps.selectedUserId &&
      merchantIdList &&
      selectedUserId &&
      (!isEqual(prevProps.merchantIdList, merchantIdList) || prevProps.selectedUserId !== selectedUserId)
    ) {
      this.handleResetForm();
    }

    if (prevProps.selectedUserId !== selectedUserId) {
      getSitesAction(String(selectedUserId || authUserId));
    }

    this.state.shouldReset && this.setState({ shouldReset: false });
  }

  componentWillUnmount(): void {
    this.props.resetSummaryStatisticsAction();
  }

  getSiteOptionData(service: number): any[] {
    const { sites, t } = this.props;

    const allowSites: any[] = [];
    STATISTICS_SERVICES_OPTIONS(t).forEach((item: any) => {
      if (item.key === service) {
        sites.forEach((site: any) => {
          if (item.value.source.includes(site.source_id)) {
            allowSites.push(site);
          }
        });
      }
    });

    return getAvailableSitesOptions(allowSites, t);
  }

  handleResetForm = () => {
    const { currencies, t } = this.props;

    this.resetForm();
    this.setState({ shouldReset: true });

    const activeCurrenciesList = getActiveCurrencies(
      currencies.data,
      STATISTICS_SERVICES_OPTIONS(t)[0].value.operationType
    );
    activeCurrenciesList && this.setState({ activeCurrencies: activeCurrenciesList });
  };

  onSubmit = (values) => {
    const { getSummaryStatisticsAction, merchantIdList, isAdmin, authUserId, selectedUserId, accounts, t } = this.props;

    const userId = isAdmin && authUserId !== selectedUserId ? selectedUserId : undefined;
    const requestParams = prepareStatisticParams(values, merchantIdList, userId, accounts.data, t);

    getSummaryStatisticsAction(requestParams);
  };

  validate = (values): { [key: string]: string } => {
    const { t } = this.props;
    const { dateFrom, dateTo, currency } = values;
    const errors = Object.getOwnPropertyNames(values).reduce(
      (accumulator, key) => (values[key] ? accumulator : { ...accumulator, [key]: EMPTY_FIELD_ERROR }),
      {}
    );

    if (dateFrom && dateTo && isAfter(dateFrom, dateTo) && !isSameDay(dateFrom, dateTo)) {
      errors['dateFrom'] = 'Начальная дата должна быть меньше или равна конечной';
    }

    if (currency === null || !currency.value) {
      errors['currency'] = t('choose_currency');
    }

    return errors;
  };

  onServiceChange = (service: StatisticsServiceSelectOption) => {
    const { accounts, destinations, currencies, t } = this.props;

    const direction = getDirectionsOptions(service, destinations, t)[0];
    this.setFieldValue('service', service);
    this.setFieldValue('direction', direction);

    this.setFieldValue('site', undefined);

    const activeCurrencies = getActiveCurrencies(currencies.data, service.value.operationType);
    if (service.key === ServiceTypes.Mc) {
      this.setFieldValue('currency', {
        label: activeCurrencies[0].name,
        value: activeCurrencies[0].id,
        key: activeCurrencies[0].id
      });
    } else {
      this.setFieldValue('currency', NOT_SELECTED_CURRENCY_OPTION(t));
    }

    this.setState({
      accountOptions: getAccounts(
        service.value.operationType,
        t,
        accounts.data,
        direction.value.source,
        activeCurrencies[0].name
      ),
      activeCurrencies
    });
  };

  render(): ReactNode {
    const { loading, data, showRefund, match, destinations, merchantIdList, t } = this.props;
    const { accountOptions, activeCurrencies, shouldReset } = this.state;

    const preparedData = prepareData(data);

    return (
      <Fragment>
        {loading && <Loader />}

        <Row className="flex-shrink-0">
          <Colxx xxs="12">
            <Breadcrumb heading="menu.statistics" match={match} />
            <div className="time-tip  d-none d-lg-inline-block">{t(TIMEZONE_TOOLTIP_TEXT)}</div>
            <Separator className="mb-5" />
          </Colxx>
        </Row>

        <Formik
          initialValues={STATISTICS_INITIAL_VALUES(t)[StatisticType.Summary]}
          onSubmit={this.onSubmit}
          validate={this.validate}
        >
          {({ values, setFieldValue, errors, touched, setFieldTouched, resetForm }) => {
            this.setFieldValue = setFieldValue;
            this.resetForm = resetForm;
            this.serviceType = values.service.value.operationType;
            this.currentCurrency = values.currency;

            return (
              <Fragment>
                {merchantIdList && merchantIdList.length > 0 && (
                  <Form>
                    <Card className="mb-4">
                      <CardBody>
                        <Row>
                          <Colxx xxs="12" sm="6">
                            <FormGroup className="has-float-label">
                              <Label className="float-label"> {t('statistics.Date from')} </Label>
                              <DatePickerLocaled
                                name="dateFrom"
                                selected={values.dateFrom}
                                onChange={(d: Date) => setFieldValue('dateFrom', d)}
                                customInput={<InputMask mask={DATE_MASK} />}
                                dateFormat={DATE_FORMAT_BASIC}
                                autoComplete="off"
                                maxDate={values.dateTo ? values.dateTo : new Date()}
                                onBlur={() => setFieldTouched('dateFrom', true)}
                                tooltip={t(DATE_FROM_TOOLTIP_TEXT)}
                              />
                              {errors.dateFrom && <div className="invalid-feedback d-block">{errors.dateFrom}</div>}
                            </FormGroup>
                          </Colxx>
                          <Colxx xxs="12" sm="6">
                            <FormGroup className="has-float-label">
                              <Label className="float-label"> {t('statistics.Date by')}</Label>
                              <DatePickerLocaled
                                name="dateTo"
                                selected={values.dateTo}
                                onChange={(d: Date) => setFieldValue('dateTo', d)}
                                customInput={<InputMask mask={DATE_MASK} />}
                                dateFormat={DATE_FORMAT_BASIC}
                                autoComplete="off"
                                minDate={values.dateFrom ? values.dateFrom : undefined}
                                maxDate={new Date()}
                                onBlur={() => setFieldTouched('dateTo', true)}
                                tooltip={t(DATE_TO_TOOLTIP_TEXT)}
                              />
                              {errors.dateTo && touched.dateTo && (
                                <div className="invalid-feedback d-block">{errors.dateTo}</div>
                              )}
                            </FormGroup>
                          </Colxx>
                          <Colxx xxs="12" sm="6" xl="3">
                            <FormGroup className="has-float-label">
                              <Label className="float-label"> {t('statistics.Service')}</Label>
                              <Select
                                className="react-select"
                                classNamePrefix="react-select"
                                name="service"
                                value={values.service}
                                onChange={this.onServiceChange}
                                options={STATISTICS_SERVICES_OPTIONS(t)}
                                placeholder=""
                              />
                            </FormGroup>
                          </Colxx>
                          <Colxx xxs="12" sm="6" xl="3">
                            <FormGroup className="has-float-label">
                              <Label className="float-label"> {t('statistics.Destination')}</Label>
                              <CustomDropdownTreeSelect
                                name="direction"
                                data={directionsOptions(values.service.key, destinations, t)}
                                shouldReset={shouldReset}
                                onChange={(changedValue, selectedValues) => {
                                  const direction = setDirectionsData(
                                    selectedValues,
                                    values.service.value.operationType === ServiceTypes.Outcome
                                  );
                                  setFieldValue('direction', direction || STATISTICS_OUTCOME_DIRECTIONS_OPTIONS(t)[0]);
                                  const newAccountOptions = getAccounts(
                                    values.service.value.operationType,
                                    t,
                                    this.props.accounts.data,
                                    direction ? direction.value.source : undefined,
                                    this.currentCurrency.label,
                                    direction ? direction.value.destination : []
                                  );

                                  if (!isEqual(this.state.accountOptions, newAccountOptions)) {
                                    this.setState({ accountOptions: newAccountOptions });
                                  }
                                }}
                                disabled={values.service.key === ServiceTypes.Mc}
                              />
                              {errors.direction && <div className="invalid-feedback d-block">{errors.direction}</div>}
                            </FormGroup>
                          </Colxx>
                          <Colxx xxs="12" sm="6" xl="3">
                            <FormGroup className="has-float-label">
                              <Label for="currency" className="float-label">
                                {t('statistics.Currency')}
                              </Label>
                              <Select
                                className="react-select"
                                classNamePrefix="react-select"
                                name="currency"
                                value={values.currency}
                                onChange={(currency: SelectorOption) => {
                                  setFieldValue('currency', currency);
                                  const newAccountOptions = getAccounts(
                                    values.service.value.operationType,
                                    t,
                                    this.props.accounts.data,
                                    values.direction ? values.direction.value.source : undefined,
                                    currency.label
                                  );
                                  if (!isEqual(this.state.accountOptions, newAccountOptions)) {
                                    this.setState({ accountOptions: newAccountOptions });
                                  }
                                }}
                                options={getCurrenciesOptions(activeCurrencies, values.service.key, t, false)}
                                isDisabled={values.service.key === ServiceTypes.Mc}
                                placeholder=""
                              />
                              {errors.currency && <div className="invalid-feedback d-block">{errors.currency}</div>}
                            </FormGroup>
                          </Colxx>
                          <Colxx xxs="12" sm="6" xl="3">
                            <FormGroup className="has-float-label">
                              <Label className="float-label">{t('statistics.Merchant Account')}</Label>
                              <CustomDropdownTreeSelect
                                name="account"
                                shouldReset={shouldReset}
                                data={accountOptions}
                                onChange={(changedValue, selectedValues) => setFieldValue('account', selectedValues)}
                              />
                              {errors.account && <div className="invalid-feedback d-block">{errors.account}</div>}
                            </FormGroup>
                          </Colxx>
                          <Colxx xxs="12" sm="6" lg="3">
                            <FormGroup className="form-group has-float-label">
                              <Label for="source" className="float-label">
                                {t('statistics.Alias')}
                              </Label>
                              <CustomDropdownTreeSelect
                                name="source"
                                data={this.getSiteOptionData(values.service.key)}
                                shouldReset={shouldReset}
                                onBlur={() => setFieldTouched('site', true)}
                                onChange={(changedValue, selectedValues) => {
                                  if (selectedValues && selectedValues.length === 1 && !selectedValues[0].value) {
                                    setFieldValue('site', undefined);
                                  } else {
                                    setFieldValue('site', getOneLevelSelectedData(selectedValues));
                                  }
                                }}
                              />
                            </FormGroup>
                          </Colxx>

                          <Colxx xxs="12" className="d-flex justify-content-end">
                            <Button disabled={!!getFieldErrorsCount(errors)} color="primary" type="submit">
                              {t('statistics.button.search')}
                            </Button>
                          </Colxx>
                        </Row>
                      </CardBody>
                    </Card>
                  </Form>
                )}

                {!(merchantIdList && merchantIdList.length) && <NotFoundMessage text={t('no_partners_selected')} />}

                <div ref={this.TargetRef}>
                  {preparedData && (
                    <Card className="mb-4">
                      <CardBody>
                        <Row className="mb-2 pb-2 border-bottom">
                          <Colxx xxs="12" xs="7" sm="9" className="border-right">
                            {t('statistics.Transactions amount')}
                          </Colxx>
                          <Colxx xxs="12" xs="5" sm="3">
                            {preparedData.total_amount}
                          </Colxx>
                        </Row>
                        <Row className="mb-2 pb-2 border-bottom">
                          <Colxx xxs="12" xs="7" sm="9" className="border-right">
                            {t('statistics.Transactions count')}
                          </Colxx>
                          <Colxx xxs="12" xs="5" sm="3">
                            {preparedData.total}
                          </Colxx>
                        </Row>
                        <Row className="mb-2 pb-2 border-bottom">
                          <Colxx xxs="12" xs="7" sm="9" className="border-right">
                            {t('statistics.Successful transactions amount')}
                          </Colxx>
                          <Colxx xxs="12" xs="5" sm="3">
                            {preparedData.paid_amount}
                          </Colxx>
                        </Row>
                        <Row className="mb-2 pb-2 border-bottom">
                          <Colxx xxs="12" xs="7" sm="9" className="border-right">
                            {t('statistics.Average successful transactions amount')}
                          </Colxx>
                          <Colxx xxs="12" xs="5" sm="3">
                            {preparedData.avg_amount}
                          </Colxx>
                        </Row>
                        <Row className="mb-2 pb-2 border-bottom">
                          <Colxx xxs="12" xs="7" sm="9" className="border-right">
                            {t('statistics.Successful transactions count')}
                          </Colxx>
                          <Colxx xxs="12" xs="5" sm="3">
                            {preparedData.paid}
                          </Colxx>
                        </Row>
                        <Row className="mb-2 pb-2 border-bottom">
                          <Colxx xxs="12" xs="7" sm="9" className="border-right">
                            {t('statistics.Successful transactions count, %')}
                          </Colxx>
                          <Colxx xxs="12" xs="5" sm="3">
                            {preparedData.paid_percentage}
                          </Colxx>
                        </Row>
                        <Row className="mb-2 pb-2 border-bottom">
                          <Colxx xxs="12" xs="7" sm="9" className="border-right">
                            {t('statistics.Successful transactions count')}
                          </Colxx>
                          <Colxx xxs="12" xs="5" sm="3">
                            {preparedData.not_paid_amount}
                          </Colxx>
                        </Row>
                        <Row className="mb-2 pb-2 border-bottom">
                          <Colxx xxs="12" xs="7" sm="9" className="border-right">
                            {t('statistics.Failed transactions count')}
                          </Colxx>
                          <Colxx xxs="12" xs="5" sm="3">
                            {preparedData.not_paid}
                          </Colxx>
                        </Row>
                        <Row className="mb-2 pb-2 border-bottom">
                          <Colxx xxs="12" xs="7" sm="9" className="border-right">
                            {t('statistics.Failed transactions count, %')}
                          </Colxx>
                          <Colxx xxs="12" xs="5" sm="3">
                            {preparedData.not_paid_percentage}
                          </Colxx>
                        </Row>
                        <Row className="mb-2 pb-2 border-bottom">
                          <Colxx xxs="12" xs="7" sm="9" className="border-right">
                            {t('statistics.Pending transactions count')}
                          </Colxx>
                          <Colxx xxs="12" xs="5" sm="3">
                            {preparedData.processing}
                          </Colxx>
                        </Row>

                        {showRefund && (
                          <Fragment>
                            <Row className="mb-2 pb-2 border-bottom">
                              <Colxx xxs="12" xs="7" sm="9" className="border-right">
                                {t('statistics.Refunds amount')}
                              </Colxx>
                              <Colxx xxs="12" xs="5" sm="3">
                                {preparedData.refund_total_amount}
                              </Colxx>
                            </Row>
                            <Row className="mb-2 pb-2 border-bottom">
                              <Colxx xxs="12" xs="7" sm="9" className="border-right">
                                {t('statistics.Refunds count')}
                              </Colxx>
                              <Colxx xxs="12" xs="5" sm="3">
                                {preparedData.refund_total}
                              </Colxx>
                            </Row>
                            <Row>
                              <Colxx xxs="12" xs="7" sm="9" className="border-right">
                                {t('statistics.Refunds count, %')}
                              </Colxx>
                              <Colxx xxs="12" xs="5" sm="3">
                                {preparedData.refund_total_percentage}
                              </Colxx>
                            </Row>
                          </Fragment>
                        )}
                      </CardBody>
                    </Card>
                  )}
                </div>
              </Fragment>
            );
          }}
        </Formik>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ statistics, settings, authUser, operations, sites }) => ({
  isAdmin: authUser.user.data && authUser.user.data.is_admin ? authUser.user.data.is_admin : false,
  selectedUserId: settings.selectedUser ? settings.selectedUser.id : 0,
  authUserId: authUser.user.data ? authUser.user.data.id : 0,
  data: statistics.summaryStatistics.data,
  loading: statistics.summaryStatistics.loading,
  error: statistics.summaryStatistics.error,
  showRefund: statistics.summaryStatistics.showRefund,
  merchantIdList: settings.selectedMerchantsId,
  destinations: operations.destinations.data,
  accounts: operations.payoutAccounts,
  currencies: settings.currencies,
  sites: sites.sites.data || []
});

const mapDispatchToProps = {
  getDestinationsAction: getDestinations,
  getCurrenciesAction: getCurrencies,
  getSummaryStatisticsAction: getSummaryStatistics,
  resetSummaryStatisticsAction: resetSummaryStatistics,
  getPayoutAccountsAction: getPayoutAccounts,
  getSitesAction: getAvailableSites
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Summary)
);
