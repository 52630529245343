import React, { Component, Fragment, ReactNode, SyntheticEvent } from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { Row, Card, CardBody, FormGroup, Label, Button } from 'reactstrap';
import ReactTable from 'react-table';
import Select from 'react-select';
import InputMask from 'react-input-mask';
import isEqual from 'lodash/isEqual';
import { withTranslation } from 'react-i18next';
import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import { NotFoundMessage } from '../../../components/common/NotFoundMessage';
import { Loader } from '../../../components/common/Loader';
import { unmaskPhoneNumber, validateEmail } from '../../../helpers/Utils';
import Breadcrumb from '../../../containers/navs/Breadcrumb';
import DataTablePagination from '../../../components/DatatablePagination';
import { SelectorOption } from '../../../interfaces/app';
import {
  getGlossaryPartners,
  getGlossaryUserPartners,
  getGlossaryUsers,
  getPayoutAccounts,
  getUserPartnerAccounts,
  resetGlossaryPartners,
  resetGlossaryUsers
} from '../../../redux/actions';
import { User } from '../../../redux/authUser/interface';
import { Merchant } from '../../settings/Permissions/interface';
import { getAccountOptions } from '../Contracts/helpers';
import { UsersProps, UsersState, GlossaryUsersFilterValues, UserPartner } from './interface';
import { NotificationManager } from '../../../components/common/react-notifications';
import { intFieldValue } from '../../operations/helpers';
import { GlossaryUser } from '../../../redux/glossary/interface';
import { getGlossaryUsersColumns } from './helpers';
import { Service } from '../../../redux/services/interface';

import { ALL_OPTION } from '../constants';
import { PARTNER_ID, PHONE_MASK, PHONE_NUMBER_LENGTH, MERCHANT_ID_LENGTH } from '../../../constants/app';
import { GLOSSARY_USERS_INITIAL_VALUES, DEFAULT_SORTER } from './constants';
import { NOTIFICATION_TIMEOUT } from '../../../constants/defaultValues';

class Users extends Component<UsersProps, UsersState> {
  state: UsersState = {
    page: 0,
    lastOpenedUserId: null
  };
  resetForm;

  componentDidUpdate(prevProps: UsersProps): void {
    const {
      error,
      merchantIdList,
      selectedUserId,
      resetGlossaryUsersAction,
      getAccountsAction,
      isAdmin,
      authUserId,
      partners
    } = this.props;
    const { lastOpenedUserId } = this.state;

    if (error && error !== prevProps.error) {
      NotificationManager.info(error, 'Ошибка', NOTIFICATION_TIMEOUT, null, null, '');
    }

    if (!isEqual(prevProps.merchantIdList, merchantIdList) || prevProps.selectedUserId !== selectedUserId) {
      this.resetForm();
      resetGlossaryUsersAction();

      if (merchantIdList) {
        getAccountsAction(
          merchantIdList,
          isAdmin && selectedUserId && authUserId !== selectedUserId ? selectedUserId : undefined
        );
      }
    }

    if (
      partners.data &&
      lastOpenedUserId &&
      partners.data[lastOpenedUserId] &&
      !isEqual(prevProps.partners.data, partners.data)
    ) {
      this.getAccountsByPartners(partners.data[lastOpenedUserId]);
    }
  }

  componentDidMount(): void {
    this.props.getPartnersAction({});
  }

  componentWillUnmount(): void {
    this.props.resetGlossaryPartnersAction();
    this.props.resetGlossaryUsersAction();
  }

  getUsers = (values, page = 0, sorting = '') => {
    const { maxPageSize } = this.props;

    this.setState({ page }, () => {
      this.props.getGlossaryUsersAction({
        [PARTNER_ID]: values[PARTNER_ID].key ? values[PARTNER_ID].key : undefined,
        name: undefined,
        account_id: values.account_id.key,
        email: values.email,
        sorting,
        id: Number(values.id),
        phone: values.phone ? unmaskPhoneNumber(values.phone) : undefined,
        limit: maxPageSize,
        offset: maxPageSize * page
      });
    });
  };

  setSorting = (newSorted, values) => {
    const sorting = `${newSorted[0].id} ${newSorted[0].desc ? 'DESC' : 'ASC'}`;
    this.getUsers(values, 0, sorting);
  };

  getGlossaryPartners = ({ name, account_id, email, phone }) => {
    const { maxPageSize } = this.props;

    this.props.getPartnersAction({
      name,
      account_id,
      email,
      phone: phone ? unmaskPhoneNumber(phone) : undefined,
      limit: maxPageSize,
      offset: 0
    });
  };

  validate = (values) => {
    const { t } = this.props;

    const errors: { [key: string]: string } = {};
    const phone = unmaskPhoneNumber(values.phone);

    if (values.email && !validateEmail(values.email)) {
      errors.email = t('Введите корректную почту');
    }

    if (phone && phone.length !== PHONE_NUMBER_LENGTH) {
      errors.phone = t('Enter correct mobile number');
    }

    return errors;
  };

  getSubComponent = (original: GlossaryUser) => {
    const {
      partners: { data, loading },
      accountsByPartner,
      t
    } = this.props;
    const isPartnersExist = data && data[original.id] && data[original.id].length;

    if (!loading && !isPartnersExist) {
      return <div className="text-muted d-flex justify-content-center mt-2 mb-3">{t('no_partners_attached')}</div>;
    }

    return (
      !loading &&
      isPartnersExist && (
        <div className="d-flex justify-content-end mb-1 mr-4">
          <table className="sub-component-table">
            <thead>
              <tr key="head">
                <th key="col_partner">
                  <b>{t('contracts.partner')}</b>
                </th>
                <th key="col_account">
                  <b>{t('contracts.account')}</b>
                </th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data[original.id].map(
                  (partner: UserPartner) =>
                    accountsByPartner &&
                    accountsByPartner[partner.id] &&
                    accountsByPartner[partner.id].map((account: Service, index: number) => (
                      <tr key={`${partner.name}_${account.name}_${index}`}>
                        <td key={`partner_${partner.name}`}>{partner.name}</td>
                        <td key={`account_${account.name}`}>{account.name}</td>
                      </tr>
                    ))
                )}
            </tbody>
          </table>
        </div>
      )
    );
  };

  getPartnerAccounts = (partnerId: number) => {
    const { isAdmin, selectedUserId, authUserId, getAccountsAction, merchantIdList } = this.props;

    getAccountsAction(
      partnerId ? [partnerId] : merchantIdList,
      isAdmin && selectedUserId && authUserId !== selectedUserId ? selectedUserId : undefined
    );
  };

  getUserOptions = (data: User[]): SelectorOption[] => {
    const { t } = this.props;

    if (data && data.length) {
      const result = data.map((user: User) => ({ label: user.fio, value: user.username, key: user.id }));
      return [ALL_OPTION(t), ...result];
    }
    return [];
  };

  getPartnerOptions = (data: Merchant[]): SelectorOption[] => {
    const { t } = this.props;

    if (data && data.length) {
      const result = data.map((merchant: Merchant) => ({ label: merchant.name, value: merchant.id, key: merchant.id }));
      return [ALL_OPTION(t), ...result];
    }
    return [];
  };

  changeLastOpenedUser = (userId: number) => {
    const { lastOpenedUserId } = this.state;

    if (lastOpenedUserId !== userId) {
      this.setState({ lastOpenedUserId: userId });
    }
  };

  getAccountsByPartners = (partners: UserPartner[]) => {
    const { getUserAccounts } = this.props;
    const { lastOpenedUserId } = this.state;

    const partnerIdList = partners && partners.length ? partners.map(({ id }: UserPartner) => Number(id)) : [];

    lastOpenedUserId && partnerIdList && partnerIdList.length && getUserAccounts(partnerIdList, lastOpenedUserId);
  };

  render(): ReactNode {
    const {
      loading,
      rows,
      total,
      match,
      partners,
      getPartners,
      accounts,
      users,
      merchants,
      maxPageSize,
      t
    } = this.props;
    const { page } = this.state;

    const pageSize = rows && rows.length ? (rows.length < maxPageSize ? rows.length : maxPageSize) : 0;

    return (
      <Fragment>
        {loading && <Loader />}

        <Row className="flex-shrink-0">
          <Colxx xxs="12">
            <Breadcrumb heading="menu.glossary" match={match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>

        <Formik
          initialValues={GLOSSARY_USERS_INITIAL_VALUES(t)}
          onSubmit={(values: GlossaryUsersFilterValues) => this.getUsers(values)}
          validate={this.validate}
          isInitialValid={true}
        >
          {({ values, errors, touched, setFieldValue, setFieldTouched, isValid, resetForm }) => {
            this.resetForm = resetForm;

            return (
              <Fragment>
                <Form>
                  <Card className="mb-4">
                    <CardBody>
                      <Row>
                        <Colxx xxs="12" sm="6" lg="3">
                          <FormGroup className="has-float-label">
                            <Label className="float-label">{t('directories.user id')}</Label>
                            <Field
                              className="form-control"
                              type="text"
                              name="id"
                              value={Number(values.id) === 0 ? '' : values.id}
                              maxLength="255"
                              onChange={({ target: { value } }) => {
                                intFieldValue(value, 'id', setFieldValue);
                                let user = this.getUserOptions(users.data)[value];
                                if (user) {
                                  setFieldValue('name', user);
                                } else {
                                  user = this.getUserOptions(users.data)[0];
                                  setFieldValue('name', user);
                                }
                              }}
                            />
                          </FormGroup>
                        </Colxx>
                        <Colxx xxs="12" sm="6" lg="3">
                          <FormGroup className="has-float-label">
                            <Label className="float-label">{t('directories.name')}</Label>
                            <Select
                              className="react-select"
                              classNamePrefix="react-select"
                              name="name"
                              value={values.name}
                              onChange={(user: SelectorOption) => {
                                setFieldValue('name', user);
                                setFieldValue('id', user.key);
                              }}
                              options={this.getUserOptions(users.data)}
                              placeholder=""
                              maxLength="255"
                              noOptionsMessage={() => t('select.no_data')}
                            />
                          </FormGroup>
                        </Colxx>
                        <Colxx xxs="12" sm="6" lg="3">
                          <FormGroup className="has-float-label">
                            <Label className="float-label">{t('directories.e-mail')}</Label>
                            <Field className="form-control" type="text" name="email" maxLength="255" />
                            {errors.email && touched.email && (
                              <div className="invalid-feedback d-block">{errors.email}</div>
                            )}
                          </FormGroup>
                        </Colxx>
                        <Colxx xxs="12" sm="6" lg="3">
                          <FormGroup className="form-group has-float-label">
                            <Label className="float-label">{t('directories.phone number')}</Label>
                            <InputMask
                              mask={PHONE_MASK}
                              value={values.phone}
                              onChange={({ currentTarget }: SyntheticEvent<HTMLInputElement>) => {
                                setFieldValue('phone', currentTarget.value);
                                setFieldTouched('phone', false);
                              }}
                              onBlur={() => setFieldTouched('phone', true)}
                              className="form-control"
                              type="tel"
                              name="phone"
                            />
                            {errors.phone && touched.phone && (
                              <div className="invalid-feedback d-block">{errors.phone}</div>
                            )}
                          </FormGroup>
                        </Colxx>
                        <Colxx xxs="12" sm="6" lg="3">
                          <FormGroup className="form-group has-float-label">
                            <Label className="float-label">{t('directories.merchant')}</Label>
                            <Select
                              className="react-select"
                              classNamePrefix="react-select"
                              name={PARTNER_ID}
                              value={values[PARTNER_ID]}
                              options={this.getPartnerOptions(merchants)}
                              onChange={(partner: SelectorOption) => {
                                setFieldValue(PARTNER_ID, partner);
                                setFieldValue('account_id', ALL_OPTION(t));
                                this.getPartnerAccounts(partner.key);
                              }}
                              placeholder=""
                              maxLength="255"
                              noOptionsMessage={() => t('select.no_data')}
                            />
                            {errors[PARTNER_ID] && touched[PARTNER_ID] && (
                              <div className="invalid-feedback d-block">{errors[PARTNER_ID]}</div>
                            )}
                          </FormGroup>
                        </Colxx>
                        <Colxx xxs="12" sm="6" lg="3">
                          <FormGroup className="form-group has-float-label">
                            <Label className="float-label">{t('directories.merchant account')}</Label>
                            <Select
                              className="react-select"
                              classNamePrefix="react-select"
                              name="account_id"
                              value={values.account_id}
                              onChange={(account: SelectorOption) => {
                                setFieldValue('account_id', account);
                              }}
                              options={getAccountOptions(accounts.data, t)}
                              placeholder=""
                              maxLength={MERCHANT_ID_LENGTH}
                              noOptionsMessage={() => t('select.no_data')}
                            />
                            {errors.account_id && touched.account_id && (
                              <div className="invalid-feedback d-block">{errors.account_id}</div>
                            )}
                          </FormGroup>
                        </Colxx>

                        <Colxx xxs="12" className="d-flex justify-content-end">
                          <Button color="primary" type="submit" disabled={!isValid}>
                            {t('directories.button.search')}
                          </Button>
                        </Colxx>
                      </Row>
                    </CardBody>
                  </Card>
                </Form>

                {rows && rows.length > 0 && (
                  <Card className="mb-4">
                    <CardBody>
                      <ReactTable
                        data={rows}
                        columns={getGlossaryUsersColumns(getPartners, partners, t, this.changeLastOpenedUser)}
                        page={page}
                        pages={Math.ceil(total / maxPageSize)}
                        defaultPageSize={pageSize}
                        pageSize={pageSize}
                        sortable={true}
                        filterable={false}
                        showPageJump={true}
                        PaginationComponent={DataTablePagination}
                        showPageSizeOptions={false}
                        loading={loading}
                        defaultSorted={DEFAULT_SORTER}
                        onSortedChange={(newSorted) => {
                          this.setSorting(newSorted, values);
                        }}
                        manual
                        onPageChange={(newPage: number) => this.getUsers(values, newPage)}
                        loadingText={''}
                        noDataText="Нет данных"
                        SubComponent={({ original }) => this.getSubComponent(original)}
                        freezeWhenExpanded={true}
                      />
                    </CardBody>
                  </Card>
                )}

                {rows && !rows.length && <NotFoundMessage />}
              </Fragment>
            );
          }}
        </Formik>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ glossary, settings, menu, operations, authUser }) => ({
  isAdmin: authUser.user.data ? authUser.user.data.is_admin : false,
  merchants: glossary.partners.rows,
  users: menu.users,
  accounts: operations.payoutAccounts,
  rows: glossary.users.rows,
  total: glossary.users.total,
  maxPageSize: settings.gcmSettings.data ? settings.gcmSettings.data.max_page_size : 50,
  loading: glossary.users.loading,
  error: glossary.users.error,
  merchantIdList: settings.selectedMerchantsId,
  selectedUserId: settings.selectedUser ? settings.selectedUser.id : 0,
  partners: glossary.users.partners,
  accountsByPartner: glossary.users.accounts.data
});

const mapDispatchToProps = {
  getGlossaryUsersAction: getGlossaryUsers,
  getAccountsAction: getPayoutAccounts,
  getPartnersAction: getGlossaryPartners,
  resetGlossaryUsersAction: resetGlossaryUsers,
  resetGlossaryPartnersAction: resetGlossaryPartners,
  getPartners: getGlossaryUserPartners,
  getUserAccounts: getUserPartnerAccounts
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Users)
);
