import { ServiceTypes } from '../../views/operations/OperationsList/constants';

import {
  GET_OPERATIONS,
  GET_OPERATIONS_SUCCESS,
  GET_OPERATIONS_ERROR,
  GET_OPERATIONS_FILE,
  GET_OPERATIONS_FILE_SUCCESS,
  GET_OPERATIONS_FILE_ERROR,
  GET_PAYOUT_ACCOUNTS,
  GET_PAYOUT_ACCOUNTS_SUCCESS,
  GET_PAYOUT_ACCOUNTS_ERROR,
  GET_ACQUIRING_HISTORY,
  GET_ACQUIRING_HISTORY_SUCCESS,
  GET_ACQUIRING_HISTORY_ERROR,
  CREATE_MASS_PAYOUT,
  CREATE_MASS_PAYOUT_SUCCESS,
  CREATE_MASS_PAYOUT_ERROR,
  GET_PAYOUT_DIRECTIONS,
  GET_PAYOUT_DIRECTIONS_SUCCESS,
  GET_PAYOUT_DIRECTIONS_ERROR,
  CREATE_PAYOUT,
  CREATE_PAYOUT_SUCCESS,
  CREATE_PAYOUT_ERROR,
  GET_PAYOUT_FIELDS,
  GET_PAYOUT_FIELDS_SUCCESS,
  GET_PAYOUT_FIELDS_ERROR,
  GET_DESTINATIONS,
  GET_DESTINATIONS_SUCCESS,
  GET_DESTINATIONS_ERROR,
  RESET_OPERATIONS,
  REFUND_OPERATION,
  REFUND_OPERATION_SUCCESS,
  REFUND_OPERATION_ERROR,
  SEND_CALLBACK,
  SEND_CALLBACK_SUCCESS,
  SEND_CALLBACK_ERROR,
  GET_REGISTRIES_HISTORY,
  GET_REGISTRIES_HISTORY_SUCCESS,
  GET_REGISTRIES_HISTORY_ERROR,
  RESET_REGISTRIES_HISTORY,
  FLASH_PAYOUT_FIELDS,
  CONFIRM_PAYOUT,
  CONFIRM_PAYOUT_SUCCESS,
  CONFIRM_PAYOUT_ERROR
} from '../actions';

import { OperationsReducerState } from './interface';

const INIT_OPERATIONS_DATA = {
  data: null,
  count: 0,
  fileLoading: false,
  loading: false,
  error: ''
};

const INIT_STATE: OperationsReducerState = {
  [ServiceTypes.Income]: INIT_OPERATIONS_DATA,
  [ServiceTypes.Mc]: INIT_OPERATIONS_DATA,
  [ServiceTypes.Outcome]: INIT_OPERATIONS_DATA,
  payoutAccounts: {
    data: null,
    loading: false,
    error: ''
  },
  createMassPayout: {
    loading: false,
    error: '',
    response: null
  },
  payoutDirections: {
    data: null,
    loading: false,
    error: ''
  },
  payoutFields: {
    data: null,
    loading: false,
    error: ''
  },
  createPayout: {
    loading: false,
    error: '',
    response: null,
    confirmation: null
  },
  acquiringHistory: {
    rows: null,
    total: 0,
    orderNumber: null,
    loading: false,
    error: ''
  },
  destinations: {
    data: null,
    loading: false,
    error: ''
  },
  registriesHistory: {
    data: null,
    loading: false,
    error: ''
  },
  refund: {
    response: null,
    loading: false,
    error: '',
    confirmation: null
  },
  sendCallback: {
    loading: false,
    error: ''
  }
};

export default (state: OperationsReducerState = INIT_STATE, { type, payload }): OperationsReducerState => {
  switch (type) {
    case GET_OPERATIONS:
      return {
        ...state,
        [payload.serviceType]: {
          ...state[payload.serviceType],
          data: null,
          count: 0,
          loading: true,
          error: ''
        }
      };
    case GET_OPERATIONS_SUCCESS:
      return {
        ...state,
        [payload.serviceType]: {
          ...state[payload.serviceType],
          data: payload.data,
          count: payload.count,
          loading: false
        }
      };
    case GET_OPERATIONS_ERROR:
      return {
        ...state,
        [payload.serviceType]: {
          ...state[payload.serviceType],
          error: payload.error,
          data: null,
          loading: false
        }
      };
    case RESET_OPERATIONS:
      return {
        ...state,
        [payload.serviceType]: {
          ...state[payload.serviceType],
          data: null,
          count: 0,
          loading: false,
          fileLoading: false,
          error: ''
        }
      };
    case GET_OPERATIONS_FILE:
      return {
        ...state,
        [payload.serviceType]: {
          ...state[payload.serviceType],
          fileLoading: true,
          error: ''
        }
      };
    case GET_OPERATIONS_FILE_SUCCESS:
      return {
        ...state,
        [payload.serviceType]: {
          ...state[payload.serviceType],
          fileLoading: false
        }
      };
    case GET_OPERATIONS_FILE_ERROR:
      return {
        ...state,
        [payload.serviceType]: {
          ...state[payload.serviceType],
          fileLoading: false,
          error: payload.error
        }
      };
    case GET_PAYOUT_ACCOUNTS:
      return {
        ...state,
        payoutAccounts: {
          ...state.payoutAccounts,
          loading: true,
          error: ''
        }
      };
    case GET_PAYOUT_ACCOUNTS_SUCCESS:
      return {
        ...state,
        payoutAccounts: {
          ...state.payoutAccounts,
          data: payload.payoutAccounts,
          loading: false
        }
      };
    case GET_PAYOUT_ACCOUNTS_ERROR:
      return {
        ...state,
        payoutAccounts: {
          ...state.payoutAccounts,
          data: null,
          error: payload.error,
          loading: false
        }
      };
    case CREATE_MASS_PAYOUT:
      return {
        ...state,
        createMassPayout: {
          ...state.createMassPayout,
          loading: true,
          error: ''
        }
      };
    case CREATE_MASS_PAYOUT_SUCCESS:
      return {
        ...state,
        createMassPayout: {
          ...state.createMassPayout,
          loading: false,
          response: payload.response
        }
      };
    case CREATE_MASS_PAYOUT_ERROR:
      return {
        ...state,
        createMassPayout: {
          ...state.createMassPayout,
          error: payload.error,
          loading: false
        }
      };
    case GET_PAYOUT_DIRECTIONS:
      return {
        ...state,
        payoutDirections: {
          ...state.payoutDirections,
          loading: true,
          error: ''
        }
      };
    case GET_PAYOUT_DIRECTIONS_SUCCESS:
      return {
        ...state,
        payoutDirections: {
          ...state.payoutDirections,
          data: payload.data,
          loading: false
        }
      };
    case GET_PAYOUT_DIRECTIONS_ERROR:
      return {
        ...state,
        payoutDirections: {
          ...state.payoutDirections,
          error: payload.error,
          loading: false
        }
      };
    case GET_PAYOUT_FIELDS:
      return {
        ...state,
        payoutFields: {
          ...state.payoutFields,
          loading: true,
          error: ''
        }
      };
    case GET_PAYOUT_FIELDS_SUCCESS:
      return {
        ...state,
        payoutFields: {
          ...state.payoutFields,
          data: payload.data,
          loading: false
        }
      };
    case GET_PAYOUT_FIELDS_ERROR:
      return {
        ...state,
        payoutFields: {
          ...state.payoutFields,
          error: payload.error,
          loading: false
        }
      };
    case FLASH_PAYOUT_FIELDS:
      return {
        ...state,
        payoutFields: {
          data: null,
          loading: false,
          error: ''
        }
      };
    case CREATE_PAYOUT:
      return {
        ...state,
        createPayout: {
          ...state.createPayout,
          loading: true,
          error: '',
          confirmation: null
        }
      };
    case CREATE_PAYOUT_SUCCESS:
      return {
        ...state,
        createPayout: {
          ...state.createPayout,
          loading: false,
          response: payload.response,
          confirmation: null
        }
      };
    case CREATE_PAYOUT_ERROR:
      return {
        ...state,
        createPayout: {
          ...state.createPayout,
          error: payload.error,
          loading: false,
          confirmation: null
        }
      };
    case CONFIRM_PAYOUT:
      return {
        ...state,
        createPayout: {
          ...state.createPayout,
          loading: true
        }
      };
    case CONFIRM_PAYOUT_SUCCESS:
      return {
        ...state,
        createPayout: {
          ...state.createPayout,
          confirmation: payload,
          loading: false
        }
      };
    case CONFIRM_PAYOUT_ERROR:
      return {
        ...state,
        createPayout: {
          ...state.createPayout,
          error: payload.error,
          loading: false
        }
      };
    case GET_ACQUIRING_HISTORY:
      return {
        ...state,
        acquiringHistory: {
          ...state.acquiringHistory,
          loading: true
        }
      };
    case GET_ACQUIRING_HISTORY_SUCCESS:
      return {
        ...state,
        acquiringHistory: {
          ...state.acquiringHistory,
          loading: false,
          rows: payload.rows,
          total: payload.total,
          orderNumber: payload.orderNumber
        }
      };
    case GET_ACQUIRING_HISTORY_ERROR:
      return {
        ...state,
        acquiringHistory: {
          ...state.acquiringHistory,
          loading: false,
          error: payload.error
        }
      };
    case GET_DESTINATIONS:
      return {
        ...state,
        destinations: {
          ...state.destinations,
          loading: true,
          error: ''
        }
      };
    case GET_DESTINATIONS_SUCCESS:
      return {
        ...state,
        destinations: {
          ...state.destinations,
          data: payload.data,
          loading: false,
          error: ''
        }
      };
    case GET_DESTINATIONS_ERROR:
      return {
        ...state,
        destinations: {
          ...state.destinations,
          error: payload.error,
          loading: false
        }
      };
    case REFUND_OPERATION:
      return {
        ...state,
        refund: {
          ...state.refund,
          loading: true,
          error: ''
        }
      };
    case REFUND_OPERATION_SUCCESS:
      return {
        ...state,
        refund: {
          ...state.refund,
          loading: false,
          response: payload.response
        }
      };
    case REFUND_OPERATION_ERROR:
      return {
        ...state,
        refund: {
          ...state.refund,
          error: payload.error,
          loading: false
        }
      };
    case SEND_CALLBACK:
      return {
        ...state,
        sendCallback: {
          ...state.sendCallback,
          loading: true,
          error: ''
        }
      };
    case SEND_CALLBACK_SUCCESS:
      return {
        ...state,
        sendCallback: {
          ...state.sendCallback,
          loading: false
        }
      };
    case SEND_CALLBACK_ERROR:
      return {
        ...state,
        sendCallback: {
          ...state.sendCallback,
          error: payload.error,
          loading: false
        }
      };

    case GET_REGISTRIES_HISTORY:
      return {
        ...state,
        registriesHistory: {
          ...state.registriesHistory,
          loading: true
        }
      };
    case GET_REGISTRIES_HISTORY_SUCCESS:
      return {
        ...state,
        registriesHistory: {
          ...state.registriesHistory,
          loading: false,
          data: payload.data
        }
      };
    case GET_REGISTRIES_HISTORY_ERROR:
      return {
        ...state,
        registriesHistory: {
          ...state.registriesHistory,
          loading: false,
          error: payload.error
        }
      };
    case RESET_REGISTRIES_HISTORY:
      return {
        ...state,
        registriesHistory: {
          ...state.registriesHistory,
          data: null,
          loading: false,
          error: ''
        }
      };
    default:
      return { ...state };
  }
};
