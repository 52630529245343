import React, { Fragment } from 'react';
import InputMask from 'react-input-mask';
import { FormGroup, Label } from 'reactstrap';

import { DATE_FROM, DATE_TO } from './constants';
import { Colxx } from '../CustomBootstrap';
import { DatePickerLocaled } from '../DatePickerLocaled/DatePickerLocaled';

import { DATE_FORMAT_BASIC, DATE_MASK, DATE_TIME_FORMAT_WITH_SEC, FULL_DATETIME_MASK } from '../../../constants/app';
import { ChartsPeriodValue } from '../../../views/statistics/interface';
import { DateRangePickerProps } from './interface';
import { setDateFrom, setDateTo } from '../../../helpers/Utils';
import { withTranslation } from 'react-i18next';
const DateRangePicker = ({
  dateFrom,
  dateTo,
  period,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  handleDateChanged,
  t
}: DateRangePickerProps) => {
  const periodIsHour = period === ChartsPeriodValue.Hour;

  return (
    <Fragment>
      <Colxx xxs="12" sm="6">
        <FormGroup className="has-float-label">
          <Label className="float-label">{periodIsHour ? t('disposals.date from') : t('statistics.Date from')}</Label>
          <DatePickerLocaled
            name="dateFrom"
            selected={dateFrom}
            onChange={(d: Date) => {
              const from = setDateFrom(dateFrom, d);
              setFieldValue(DATE_FROM, from);
              if (from >= dateTo) {
                const to = setDateTo(dateTo, d);
                setFieldValue(DATE_TO, to);
              }

              handleDateChanged && handleDateChanged();
            }}
            onSelect={(d: Date) => {
              const from = setDateFrom(dateFrom, d);
              if (from >= dateTo) {
                const to = setDateTo(dateTo, d);
                setFieldValue(DATE_TO, to);
              }
              setFieldValue(DATE_FROM, from);
            }}
            autoComplete="off"
            dateFormat={periodIsHour ? DATE_TIME_FORMAT_WITH_SEC : DATE_FORMAT_BASIC}
            customInput={<InputMask mask={periodIsHour ? FULL_DATETIME_MASK : DATE_MASK} />}
            maxDate={new Date()}
            startDate={dateFrom}
            endDate={dateTo}
            onBlur={() => setFieldTouched(DATE_FROM, true)}
          />
          {errors.dateFrom && <div className="invalid-feedback d-block">{errors.dateFrom}</div>}
        </FormGroup>
      </Colxx>
      <Colxx xxs="12" sm="6">
        <FormGroup className="has-float-label">
          <Label className="float-label">{periodIsHour ? t('disposals.date by') : t('statistics.Date by')}</Label>
          <DatePickerLocaled
            name="dateTo"
            selected={dateTo}
            onChange={(d: Date) => {
              const to = setDateTo(dateTo, d);
              setFieldValue(DATE_TO, to);
              handleDateChanged && handleDateChanged();
            }}
            onSelect={(d: Date) => {
              const to = setDateTo(dateTo, d);
              setFieldValue(DATE_TO, to);
            }}
            autoComplete="off"
            dateFormat={periodIsHour ? DATE_TIME_FORMAT_WITH_SEC : DATE_FORMAT_BASIC}
            customInput={<InputMask mask={periodIsHour ? FULL_DATETIME_MASK : DATE_MASK} />}
            minDate={dateFrom ? dateFrom : undefined}
            maxDate={new Date()}
            startDate={dateFrom}
            endDate={dateTo}
            onBlur={() => setFieldTouched(DATE_TO, true)}
          />
          {errors.dateTo && touched.dateTo && <div className="invalid-feedback d-block">{errors.dateTo}</div>}
        </FormGroup>
      </Colxx>
    </Fragment>
  );
};

export default withTranslation()(DateRangePicker);
