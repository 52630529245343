import React, { Fragment, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, FormGroup, Row, Label, CardTitle, Input } from 'reactstrap';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import { Colxx } from '../../../../components/common/CustomBootstrap';
import { Form, Formik, FormikErrors, Field } from 'formik';
import { Dropzone, DropzoneFile } from 'dropzone';
import DropZone from '../../../../components/dropzone/Dropzone';
import { CustomDesignTabProps } from '../interface';
import ColorPicker from '../../../../components/common/ColorPicker/ColorPicker';
import Preview from '../Preview/Preview';
import { CustomDesignConfig } from '../../../../redux/settings/interface';
import { LOGIN_LOGO_SIZE_ERROR } from '../../../../constants/app';
import WithClearButton from '../../../../components/common/WithClearButton';
import {
  BACKGROUND_MAX_FILE_SIZE,
  EMPTY_DESIGN_CONFIG,
  IMAGE_CONTENT_TYPES,
  DEFAULT_MAX_FILE_SIZE,
  PREVIEW_PAGE_VIEWS,
  LOGIN_PAGE_PREVIEW_HINTS
} from '../constants';
import { getFilesErrors } from '../helpers';
import { withTranslation } from 'react-i18next';
class LoginPageTab extends React.Component<CustomDesignTabProps, any> {
  constructor(props: CustomDesignTabProps) {
    super(props);
    this.designConfigNew = EMPTY_DESIGN_CONFIG;
    this.setFieldValue = () => {};
    this.isValid = true;
    this.errors = {};
  }

  private designConfigNew: CustomDesignConfig;
  private setFieldValue: (field: string, value: any) => void;
  private isValid: boolean;
  private errors: FormikErrors<CustomDesignConfig> | null;
  private dropZoneLoginLogo: Dropzone;
  private dropZoneLoginBg: Dropzone;

  componentDidUpdate(prevProps: Readonly<CustomDesignTabProps>, prevState: any, snapshot?: any): void {
    const { currentDesign, actualDesign } = this.props;
    if (!isEqual(currentDesign, prevProps.currentDesign) && isEqual(actualDesign, currentDesign)) {
      this.dropZoneLoginBg.removeAllFiles();
      this.dropZoneLoginLogo.removeAllFiles();
    }
  }

  validate = (values): { [key: string]: string } => {
    if (!values) {
      return {};
    }
    const errors: { [key: string]: any } = {};
    const imageErrors = getFilesErrors(values.imageFiles);

    if (imageErrors && !isEqual(imageErrors, {})) {
      errors.imageFiles = imageErrors;
    }

    if (
      values.settings &&
      'loginLogoSize' in values.settings &&
      values.settings.loginLogoSize &&
      (Number(values.settings.loginLogoSize) > 150 || Number(values.settings.loginLogoSize) < 20)
    ) {
      if (!errors.settings) {
        errors.settings = {};
      }
      errors.settings.loginLogoSize = LOGIN_LOGO_SIZE_ERROR;
    }

    this.props.setTabSettings(this.designConfigNew, isEqual(errors, {}), 'loginPage');
    return errors;
  };

  render(): ReactNode {
    const { setImage, removeImage, currentDesign, t } = this.props;
    return (
      <Fragment>
        <Row>
          <Colxx className="col-left col-12 col-xl-4 mb-4">
            <Card>
              <CardBody>
                <CardTitle>{t('customDesign.Login page design')}</CardTitle>
                <Formik
                  validate={this.validate}
                  isInitialValid={true}
                  enableReinitialize={true}
                  initialValues={this.props.currentDesign.data}
                  onSubmit={() => {}}
                >
                  {({ values, setFieldValue, errors, isValid }) => {
                    this.designConfigNew = values || EMPTY_DESIGN_CONFIG;
                    this.setFieldValue = setFieldValue;
                    this.isValid = isValid;
                    this.errors = errors;
                    return (
                      <Form className="custom-design">
                        <ColorPicker
                          value={get(values, 'settings.loginCardColor', '')}
                          name="loginCardColor"
                          setValue={(field: string, value: string) => {
                            setFieldValue('settings', { ...this.designConfigNew.settings, ...{ [field]: value } });
                          }}
                          onClear={() =>
                            setFieldValue('settings', { ...this.designConfigNew.settings, ...{ loginCardColor: '' } })
                          }
                        />
                        <ColorPicker
                          value={get(values, 'settings.loginBackgroundColor', '')}
                          name="loginBackgroundColor"
                          setValue={(field: string, value: string) => {
                            setFieldValue('settings', { ...this.designConfigNew.settings, ...{ [field]: value } });
                          }}
                          onClear={() =>
                            setFieldValue('settings', {
                              ...this.designConfigNew.settings,
                              ...{ loginBackgroundColor: '' }
                            })
                          }
                        />
                        <FormGroup className="has-float-label">
                          <Label className="float-label">{t('customDesign.Размер лого (блок 3)')}</Label>
                          <WithClearButton
                            onClear={() => {
                              this.setFieldValue('settings', {
                                ...this.designConfigNew.settings,
                                ...{ loginLogoSize: '' }
                              });
                            }}
                          >
                            <Field
                              className="form-control logo-size"
                              type="number"
                              name="loginLogoSize"
                              value={get(values, 'settings.loginLogoSize')}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                this.setFieldValue('settings', {
                                  ...this.designConfigNew.settings,
                                  ...{ loginLogoSize: event.target.value }
                                });
                              }}
                            />
                          </WithClearButton>
                          {errors && errors.settings && 'loginLogoSize' in errors.settings && (
                            <div className="invalid-feedback d-block">{errors.settings.loginLogoSize}</div>
                          )}
                        </FormGroup>
                        <FormGroup className="has-float-label">
                          <Label className="float-label">{t('customDesign.Выравнивания лого (блок 3)')}</Label>
                          <WithClearButton
                            onClear={() => {
                              this.setFieldValue('settings', {
                                ...this.designConfigNew.settings,
                                ...{ loginLogoPlacement: '' }
                              });
                            }}
                          >
                            <div className="form-control">
                              <Row>
                                {['left', 'center', 'right'].map((side: string) => (
                                  <Colxx xxs="12" key={side} sm="4" className="p-0">
                                    <div className="ml-2">
                                      <Input
                                        type="radio"
                                        id={side}
                                        name={side}
                                        checked={
                                          !!(
                                            values &&
                                            values.settings &&
                                            'loginLogoPlacement' in values.settings &&
                                            values.settings.loginLogoPlacement === side
                                          )
                                        }
                                        className="radio-btn-align-center"
                                        onChange={() =>
                                          this.setFieldValue('settings', {
                                            ...this.designConfigNew.settings,
                                            ...{ loginLogoPlacement: side }
                                          })
                                        }
                                      />
                                      <Label for={side} className="mb-1">
                                        {t(`customDesign.align.${side}`)}
                                      </Label>
                                    </div>
                                  </Colxx>
                                ))}
                              </Row>
                            </div>
                          </WithClearButton>
                        </FormGroup>
                        <FormGroup className="has-float-label">
                          <Label className="float-label">{t('customDesign.Фон формы входа (блок 1)')}</Label>
                          <DropZone
                            maxFiles={1}
                            init={(dropZone: Dropzone) => (this.dropZoneLoginBg = dropZone)}
                            addedfile={(file: DropzoneFile) => {
                              setImage && setImage(file, 'loginCardBackground', this.dropZoneLoginBg, setFieldValue);
                            }}
                            removedfile={() => {
                              removeImage && removeImage('loginCardBackground', setFieldValue);
                            }}
                            maxFilesize={BACKGROUND_MAX_FILE_SIZE}
                            acceptedFiles={IMAGE_CONTENT_TYPES}
                          />
                        </FormGroup>
                        <FormGroup className="has-float-label">
                          <Label className="float-label">{t('customDesign.Фон страницы входа (блок 2)')}</Label>
                          <DropZone
                            maxFiles={1}
                            init={(dropZone: Dropzone) => (this.dropZoneLoginBg = dropZone)}
                            addedfile={(file: DropzoneFile) => {
                              setImage && setImage(file, 'loginBackground', this.dropZoneLoginBg, setFieldValue);
                            }}
                            removedfile={() => {
                              removeImage && removeImage('loginBackground', setFieldValue);
                            }}
                            maxFilesize={BACKGROUND_MAX_FILE_SIZE}
                            acceptedFiles={IMAGE_CONTENT_TYPES}
                          />
                        </FormGroup>
                        <FormGroup className="has-float-label">
                          <Label className="float-label">{t('customDesign.Лого формы входа (блок 3)')}</Label>
                          <DropZone
                            maxFiles={1}
                            init={(dropZone: Dropzone) => (this.dropZoneLoginLogo = dropZone)}
                            addedfile={(file: DropzoneFile) => {
                              setImage && setImage(file, 'loginLogo', this.dropZoneLoginLogo, setFieldValue);
                            }}
                            removedfile={() => {
                              removeImage && removeImage('loginLogo', setFieldValue);
                            }}
                            maxFilesize={DEFAULT_MAX_FILE_SIZE}
                            acceptedFiles={IMAGE_CONTENT_TYPES}
                          />
                        </FormGroup>
                      </Form>
                    );
                  }}
                </Formik>
                {this.props.children}
              </CardBody>
            </Card>
          </Colxx>
          <Colxx className="col-left col-12 col-xl-8">
            <div className="mb-4 card preview-card">
              <div className="card-body">
                <Preview
                  view={PREVIEW_PAGE_VIEWS.loginPage}
                  customDesign={currentDesign}
                  hints={LOGIN_PAGE_PREVIEW_HINTS}
                />
              </div>
            </div>
          </Colxx>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ settings }) => ({ actualDesign: settings.customDesign });
export default withTranslation()(
  connect(
    mapStateToProps,
    {}
  )(LoginPageTab)
);
