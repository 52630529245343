import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Button, Card, CardBody, FormGroup, Label, Row, Input } from 'reactstrap';
import { Field, Form, Formik } from 'formik';
import Select from 'react-select';
import isEqual from 'lodash/isEqual';

import { createDisposal, getStatements, resetStatements } from '../../../redux/disposals/actions';
import { getPayoutAccounts } from '../../../redux/operations/actions';
import { getCurrencies } from '../../../redux/settings/actions';
import { Colxx } from '../../../components/common/CustomBootstrap';
import StatementToCheckingAccount from './StatementToCheckingAccount';
import WithTooltip from '../../../components/common/WithTooltip';
import StatementToAccount from './StatementToAccount';
import AccountToAccount from './AccountToAccount';
import ModalCreatedInfo from './ModalCreatedInfo';
import { Redirect } from 'react-router-dom';
import { EMPTY_FIELD_ERROR, ZERO_FIELD_ERROR } from '../../../constants/app';
import { NotFoundMessage } from '../../../components/common/NotFoundMessage';
import { NotificationManager } from '../../../components/common/react-notifications';
import { NOTIFICATION_TIMEOUT_L } from '../../../constants/defaultValues';
import { Loader } from '../../../components/common/Loader';

import {
  CREATE_DISPOSAL_INITIAL_VALUES,
  CREATE_DISPOSAL_TYPE_OPTIONS,
  CreateDisposalType,
  SUM_STATEMENT_TYPE_OPTIONS,
  DISPOSAL_CONTRACT_ACCOUNT_TYPE,
  GROUPED_STATEMENT_TYPE_OPTIONS
} from '../constants';
import {
  getDisposalCurrenciesOptions,
  getContractOptions,
  getPartnerOption,
  getActiveStatements,
  getActiveAccounts
} from '../helpers';
import { SelectorOption } from '../../../interfaces/app';
import {
  AMOUNT_TOOLTIP,
  ERROR_STATEMENT_TO_ACCOUNT_TOOLTIP,
  ERROR_STATEMENT_TO_CHECKING_ACCOUNT_TOOLTIP,
  ERROR_ACCOUNT_TO_ACCOUNT_TOOLTIP,
  ERROR_TOTAL_AMOUNT_TOOLTIP
} from './constants';
import { withTranslation } from 'react-i18next';
import { floatFieldValue } from '../../operations/helpers';
import { CreateDisposalFormValues, CreateDisposalProps, CreateDisposalState } from '../interface';

class CreateDisposal extends Component<CreateDisposalProps, CreateDisposalState> {
  state: CreateDisposalState = {
    sumAmount: 0,
    documentOptions: [],
    selectedStatements: [],
    selectedAccounts: [],
    selectedAccounts2: [],
    activeCurrencies: [],
    isCloseModalOpen: false,
    redirect: null,
    isChangeTableValue: false,
    selectedMerchant: null,
    selectedContract: null
  };

  setFieldValue;
  values;

  componentDidMount(): void {
    const {
      currencies,
      getCurrenciesAction,
      getPayoutAccountsAction,
      selectedMerchants,
      allowMultiPartner
    } = this.props;
    !currencies && getCurrenciesAction();
    !allowMultiPartner &&
      selectedMerchants &&
      selectedMerchants.length &&
      this.setState({ selectedMerchant: selectedMerchants[0].id }) &&
      getPayoutAccountsAction([selectedMerchants[0].id]);
  }

  componentDidUpdate(prevProps: Readonly<CreateDisposalProps>, prevState: Readonly<CreateDisposalState>): void {
    const {
      contractAccounts,
      currencies,
      selectedMerchants,
      getPayoutAccountsAction,
      getStatementsAction,
      statements,
      createdDisposalId,
      createdDisposalError,
      allowMultiPartner,
      selectedMerchantsId,
      t
    } = this.props;
    const { selectedMerchant, selectedContract } = this.state;
    if (!isEqual(prevProps.contractAccounts, contractAccounts)) {
      if (Object.keys(contractAccounts).length) {
        const contracts = getContractOptions(contractAccounts);
        this.setState({ documentOptions: contracts });
        this.setFieldValue('contract', contracts[0]);
        this.setState({ selectedContract: contracts[0].label });
      } else {
        this.setState({ documentOptions: [] });
        this.setFieldValue('contract', null);
        this.setState({ selectedContract: null });
      }
    }

    if (!isEqual(prevState.selectedContract, selectedContract)) {
      this.setAccountsItems(
        statements,
        selectedMerchant,
        contractAccounts[DISPOSAL_CONTRACT_ACCOUNT_TYPE],
        this.values.currency.value,
        this.values.currency.label
      );
    }

    if (currencies && !isEqual(prevProps.currencies, currencies)) {
      this.setState({ activeCurrencies: getDisposalCurrenciesOptions(currencies) });
      this.setFieldValue('currency', { key: currencies[0].id, value: currencies[0].id, label: currencies[0].name });
      this.setFieldValue('currency_income', {
        key: currencies[0].id,
        value: currencies[0].id,
        label: currencies[0].name
      });
    }

    if (
      !allowMultiPartner &&
      selectedMerchants &&
      selectedMerchants.length &&
      !isEqual(prevProps.selectedMerchants, selectedMerchants)
    ) {
      this.setFieldValue('partner', {
        key: selectedMerchants[0].id,
        value: selectedMerchants[0].id,
        label: selectedMerchants[0].name
      });
      this.setState({ selectedMerchant: selectedMerchantsId[0] });

      this.setFieldValue('partner', {
        key: selectedMerchants[0].id,
        value: selectedMerchants[0].id,
        label: selectedMerchants[0].name
      });
      getPayoutAccountsAction([selectedMerchantsId[0]]);
    }
    if (
      allowMultiPartner &&
      selectedMerchantsId &&
      selectedMerchantsId.length &&
      !isEqual(prevProps.selectedMerchantsId, selectedMerchantsId)
    ) {
      this.setState({ selectedMerchant: selectedMerchantsId[0] });

      this.setFieldValue('partner', {
        key: selectedMerchants[0].id,
        value: selectedMerchants[0].id,
        label: selectedMerchants[0].name
      });
      getPayoutAccountsAction([selectedMerchantsId[0]]);
    }

    if (!selectedMerchant && selectedMerchantsId) {
      getPayoutAccountsAction([selectedMerchantsId[0]]);
      this.setState({ selectedMerchant: selectedMerchantsId[0] });
    }
    if (selectedMerchant && !isEqual(prevState.selectedMerchant, selectedMerchant)) {
      getPayoutAccountsAction([selectedMerchant]);
      getStatementsAction(selectedMerchant, this.values.contract ? this.values.contract.value : 0);

      this.setStatementsItems(statements, this.values.currency.value, this.values.service.value);
      if (contractAccounts) {
        this.setAccountsItems(
          statements,
          selectedMerchant,
          contractAccounts[DISPOSAL_CONTRACT_ACCOUNT_TYPE],
          this.values.currency.value,
          this.values.currency.label
        );
      }
    }

    if (
      (statements &&
        selectedMerchants &&
        selectedMerchants.length &&
        contractAccounts &&
        currencies &&
        !isEqual(prevProps.statements, statements)) ||
      (statements &&
        selectedMerchants &&
        selectedMerchants.length &&
        contractAccounts &&
        currencies &&
        !isEqual(prevProps.selectedMerchants, selectedMerchants)) ||
      (statements &&
        selectedMerchants &&
        contractAccounts &&
        currencies &&
        !isEqual(prevProps.contractAccounts, contractAccounts)) ||
      (statements && selectedMerchants && contractAccounts && currencies && !isEqual(prevProps.currencies, currencies))
    ) {
      this.setFieldValue('currency', { key: currencies[0].id, value: currencies[0].id, label: currencies[0].name });
      this.setAccountsItems(
        statements,
        selectedMerchant,
        contractAccounts[DISPOSAL_CONTRACT_ACCOUNT_TYPE],
        String(currencies[0].id),
        String(currencies[0].name)
      );
    }

    if (
      (statements && currencies && !isEqual(prevProps.statements, statements)) ||
      (statements && currencies && !isEqual(prevProps.currencies, currencies))
    ) {
      this.setStatementsItems(statements, String(currencies[0].id), SUM_STATEMENT_TYPE_OPTIONS(t)[0].value);
    }

    if (createdDisposalId && !isEqual(prevProps.createdDisposalId, createdDisposalId)) {
      this.setState({ isCloseModalOpen: true });
    }

    if (createdDisposalError && createdDisposalError !== prevProps.createdDisposalError) {
      const msg = t('disposals.not_created');
      NotificationManager.info(msg, 'Ошибка', NOTIFICATION_TIMEOUT_L, null, null, '');
    }
  }

  handleSelectedStatements = (statement, isChecked) => {
    const { selectedStatements } = this.state;

    const newStatements = selectedStatements.map((item: any) =>
      statement.id === item.id ? { ...item, checked: isChecked } : item
    );
    let newAmount = 0;

    newStatements.forEach(({ amount, checked }) => {
      if (checked) {
        newAmount += amount;
      }
    });

    this.setState({
      selectedStatements: newStatements,
      sumAmount: Number(parseFloat(String(newAmount)).toFixed(2))
    });

    this.setState({ isChangeTableValue: true });
  };

  getNewAccountData = (account: any, selectedAccounts: any[]) =>
    selectedAccounts.map((item: any) =>
      account.id === item.id ? { ...item, checked: true } : { ...item, checked: false }
    );

  disableNewAccountData = (account: any, selectedAccounts: any[]) =>
    selectedAccounts.map((item: any) =>
      account.source_id === item.source_id ? { ...item, disabled: true } : { ...item, disabled: false }
    );

  handleSelectAccount = (account: any) => {
    const { selectedAccounts, selectedAccounts2 } = this.state;
    this.setState({ isChangeTableValue: true });
    this.setState({ selectedAccounts: this.getNewAccountData(account, selectedAccounts) });
    this.setState({ selectedAccounts2: this.disableNewAccountData(account, selectedAccounts2) });
  };

  handleSelectAccount2 = (account: any) => {
    const { selectedAccounts2, selectedAccounts } = this.state;
    this.setState({ selectedAccounts2: this.getNewAccountData(account, selectedAccounts2) });
    this.setState({ selectedAccounts: this.disableNewAccountData(account, selectedAccounts) });
  };

  getTables = (type: CreateDisposalType): ReactNode => {
    const { sumAmount, selectedStatements, selectedAccounts, selectedAccounts2 } = this.state;
    const { currencies } = this.props;

    switch (type) {
      case CreateDisposalType.StatementToAccount:
        return (
          <StatementToAccount
            statements={selectedStatements}
            accounts={selectedAccounts}
            currencies={currencies}
            onStatementsSelect={this.handleSelectedStatements}
            onAccountSelect={this.handleSelectAccount}
            sumAmount={sumAmount}
          />
        );
      case CreateDisposalType.StatementToCheckingAccount:
        return (
          <StatementToCheckingAccount
            statements={selectedStatements}
            onStatementsSelect={this.handleSelectedStatements}
            sumAmount={sumAmount}
            currencies={currencies}
          />
        );
      case CreateDisposalType.AccountToAccount:
        return (
          <AccountToAccount
            accounts={selectedAccounts}
            accounts2={selectedAccounts2}
            onAccountSelect={this.handleSelectAccount}
            onAccount2Select={this.handleSelectAccount2}
            currencies={currencies}
          />
        );
      default:
        return null;
    }
  };

  validate = ({ amount, checking_account, type }: CreateDisposalFormValues) => {
    const errors: { [key: string]: string } = {};

    const { selectedStatements, selectedAccounts, selectedAccounts2 } = this.state;
    const { t } = this.props;

    const statementsValue = selectedStatements ? selectedStatements.filter(({ checked }) => checked) : [];
    const accountValue = selectedAccounts ? selectedAccounts.find(({ checked }) => checked) : [];
    const account2Value = selectedAccounts2 ? selectedAccounts2.find(({ checked }) => checked) : [];

    let totalAmount = 0;

    switch (type.value) {
      case CreateDisposalType.StatementToAccount:
        if (!(statementsValue && statementsValue.length && accountValue)) {
          errors.selectedTableItems = t(ERROR_STATEMENT_TO_ACCOUNT_TOOLTIP);
        }
        if (statementsValue && statementsValue.length) {
          statementsValue.forEach((item: any) => {
            totalAmount += Number(item.amount);
          });
        }
        break;
      case CreateDisposalType.StatementToCheckingAccount:
        if (statementsValue && statementsValue.length) {
          statementsValue.forEach((item: any) => {
            totalAmount += Number(item.amount);
          });
        } else {
          errors.selectedTableItems = t(ERROR_STATEMENT_TO_CHECKING_ACCOUNT_TOOLTIP);
        }
        break;
      case CreateDisposalType.AccountToAccount:
        if (!(accountValue && account2Value)) {
          errors.selectedTableItems = t(ERROR_ACCOUNT_TO_ACCOUNT_TOOLTIP);
        }
        if (account2Value) {
          totalAmount += Number(account2Value['balance']);
        }
        break;
    }

    if (!amount) {
      errors.amount = t(EMPTY_FIELD_ERROR);
    } else if (Number(amount) === 0) {
      errors.amount = ZERO_FIELD_ERROR;
    } else if (totalAmount > 0 && Number(amount) > totalAmount) {
      errors.amount = ERROR_TOTAL_AMOUNT_TOOLTIP;
    }
    if (type.value === CreateDisposalType.StatementToCheckingAccount && !checking_account) {
      errors.checking_account = t(EMPTY_FIELD_ERROR);
    }

    this.setState({ isChangeTableValue: false });

    return errors;
  };

  createDisposal = (values: CreateDisposalFormValues) => {
    const { createDisposalAction } = this.props;
    const { selectedStatements, selectedAccounts, selectedAccounts2 } = this.state;

    const statementsValue = selectedStatements ? selectedStatements.filter(({ checked }) => checked) : [];
    const accountValue = selectedAccounts ? selectedAccounts.find(({ checked }) => checked) : '';
    const account2Value = selectedAccounts2 ? selectedAccounts2.find(({ checked }) => checked) : '';

    let sources: number[] = [];
    let to: string | null = null;

    switch (values.type.value) {
      case CreateDisposalType.StatementToAccount:
        sources = statementsValue ? statementsValue.map(({ id }) => id) : [];
        to = String(accountValue ? accountValue.id : '');
        break;

      case CreateDisposalType.StatementToCheckingAccount:
        sources = statementsValue ? statementsValue.map(({ id }) => id) : [];
        to = values.checking_account;
        break;

      case CreateDisposalType.AccountToAccount:
        sources = account2Value ? [account2Value.id] : [];
        to = String(accountValue ? accountValue.id : '');
        break;

      default:
        to = '';
        break;
    }

    createDisposalAction({
      partner: values.partner.value,
      type: values.type.value,
      sum_type: values.service.value,
      currency: values.currency.value,
      sources,
      to,
      amount: parseFloat(values.amount),
      comment: values.comment,
      contract: values.contract.label,
      currency_income: values.currency_income.value || 0
    });
  };

  setStatementsItems = (statements, currencyValue, serviceValue) => {
    this.setState({
      selectedStatements: getActiveStatements(statements, serviceValue, currencyValue),
      sumAmount: 0
    });
  };

  setAccountsItems = (statements, partnerValue, contractAccounts, currencyValue, currencyCode) => {
    const { selectedContract } = this.state;
    this.setState({
      selectedAccounts: getActiveAccounts(
        statements,
        partnerValue,
        contractAccounts,
        currencyValue,
        currencyCode,
        false,
        selectedContract
      ),
      selectedAccounts2: getActiveAccounts(
        statements,
        partnerValue,
        contractAccounts,
        currencyValue,
        currencyCode,
        true,
        selectedContract
      ),
      sumAmount: 0
    });
  };

  render(): ReactNode {
    const {
      statements,
      contractAccounts,
      selectedMerchants,
      createDisposalLoading,
      statementsLoading,
      isAdmin,
      t
    } = this.props;
    const { documentOptions, activeCurrencies, isCloseModalOpen, redirect, isChangeTableValue } = this.state;
    if (redirect) {
      return <Redirect to={redirect} />;
    }

    return (
      <>
        <Row>
          <Colxx xxs="12" className="mb-4">
            <Formik
              initialValues={CREATE_DISPOSAL_INITIAL_VALUES(t)}
              onSubmit={this.createDisposal}
              validate={this.validate}
            >
              {({ values, errors, setFieldValue, isValid, setFieldTouched, touched }) => {
                this.setFieldValue = setFieldValue;
                this.values = values;
                return (
                  <Form>
                    {!(selectedMerchants && selectedMerchants.length) && (
                      <NotFoundMessage text={t('no_partners_selected')} />
                    )}
                    {selectedMerchants && selectedMerchants.length > 0 && (
                      <Card>
                        <CardBody>
                          {(createDisposalLoading || !contractAccounts) && <Loader />}
                          <Row className="mb-4">
                            <Colxx xxs="12" sm="6" lg="3">
                              <FormGroup className="form-group has-float-label">
                                <Label className="float-label">{t('disposals.partner')}</Label>
                                <Select
                                  className="react-select"
                                  classNamePrefix="react-select"
                                  name="partner"
                                  value={values.partner}
                                  isDisabled={!contractAccounts}
                                  onChange={(partner: SelectorOption) => {
                                    this.setState({ selectedMerchant: partner.value });
                                    this.setStatementsItems(statements, values.currency.value, values.service.value);
                                    this.setAccountsItems(
                                      statements,
                                      partner.value,
                                      contractAccounts[DISPOSAL_CONTRACT_ACCOUNT_TYPE],
                                      values.currency.value,
                                      values.currency.label
                                    );
                                    setFieldValue('partner', partner);
                                  }}
                                  options={getPartnerOption(selectedMerchants, t, true)}
                                  placeholder=""
                                />
                              </FormGroup>
                            </Colxx>
                            <Colxx xxs="12" sm="6" lg="3">
                              <FormGroup className="form-group has-float-label">
                                <Label className="float-label">{t('disposals.destination')}</Label>
                                <Select
                                  className="react-select"
                                  classNamePrefix="react-select"
                                  name="type"
                                  value={values.type}
                                  isDisabled={!contractAccounts}
                                  onChange={(type: SelectorOption) => {
                                    setFieldValue('type', type);
                                    this.setStatementsItems(statements, values.currency.value, values.service.value);
                                    this.setAccountsItems(
                                      statements,
                                      values.partner.value,
                                      contractAccounts[DISPOSAL_CONTRACT_ACCOUNT_TYPE],
                                      values.currency.value,
                                      values.currency.label
                                    );
                                  }}
                                  options={CREATE_DISPOSAL_TYPE_OPTIONS(t)}
                                  placeholder=""
                                />
                              </FormGroup>
                            </Colxx>
                            <Colxx xxs="12" sm="6" lg="3">
                              <FormGroup className="form-group has-float-label">
                                <Label className="float-label">{t('disposals.services')}</Label>
                                <Select
                                  className="react-select"
                                  classNamePrefix="react-select"
                                  name="service"
                                  value={values.service}
                                  isDisabled={!contractAccounts}
                                  onChange={(service: SelectorOption) => {
                                    setFieldValue('service', service);
                                    this.setStatementsItems(statements, values.currency.value, service.value);
                                    this.setAccountsItems(
                                      statements,
                                      values.partner.value,
                                      contractAccounts[DISPOSAL_CONTRACT_ACCOUNT_TYPE],
                                      values.currency.value,
                                      values.currency.label
                                    );
                                  }}
                                  options={GROUPED_STATEMENT_TYPE_OPTIONS(t)}
                                  placeholder=""
                                />
                              </FormGroup>
                            </Colxx>
                            <Colxx xxs="12" sm="6" lg="3">
                              <FormGroup className="form-group has-float-label">
                                <Label className="float-label">{t('disposals.documents_number')}</Label>
                                <Select
                                  className="react-select"
                                  classNamePrefix="react-select"
                                  name="contract"
                                  value={values.contract}
                                  isDisabled={!contractAccounts}
                                  onChange={(contract: SelectorOption) => {
                                    this.setState({ selectedContract: contract.label });
                                    setFieldValue('contract', contract);
                                    this.setStatementsItems(statements, values.currency.value, values.service.value);
                                    this.setAccountsItems(
                                      statements,
                                      values.partner.value,
                                      contractAccounts[DISPOSAL_CONTRACT_ACCOUNT_TYPE],
                                      values.currency.value,
                                      values.currency.label
                                    );
                                  }}
                                  options={documentOptions}
                                  placeholder=""
                                />
                              </FormGroup>
                            </Colxx>
                            <Colxx xxs="12" sm="6" lg="3">
                              <FormGroup className="has-float-label">
                                <Label className="float-label">{t('disposals.amount')}</Label>
                                <WithTooltip tooltipText={t(AMOUNT_TOOLTIP)}>
                                  <Field
                                    className="form-control"
                                    type="text"
                                    name="amount"
                                    onChange={({ target: { value } }) =>
                                      floatFieldValue(value, 'amount', setFieldValue)
                                    }
                                    onBlur={() => setFieldTouched('amount', true)}
                                  />
                                </WithTooltip>
                                {errors.amount && touched.amount && !isChangeTableValue && (
                                  <div className="invalid-feedback d-block">{errors.amount}</div>
                                )}
                              </FormGroup>
                            </Colxx>
                            <Colxx xxs="12" sm="6" lg="3">
                              <FormGroup className="has-float-label">
                                <Label for="currency" className="float-label">
                                  {t('disposals.currency')}
                                </Label>
                                <Select
                                  id="currency"
                                  className="react-select"
                                  classNamePrefix="react-select"
                                  name="currency"
                                  value={values.currency}
                                  isDisabled={!contractAccounts}
                                  onChange={(currency: SelectorOption) => {
                                    setFieldValue('currency', currency);
                                    this.setStatementsItems(statements, currency.value, values.service.value);
                                    this.setAccountsItems(
                                      statements,
                                      values.partner.value,
                                      contractAccounts[DISPOSAL_CONTRACT_ACCOUNT_TYPE],
                                      currency.value,
                                      currency.label
                                    );
                                    setFieldValue('currency_income', currency);
                                  }}
                                  options={activeCurrencies}
                                  placeholder=""
                                />
                              </FormGroup>
                            </Colxx>

                            {values.type.value === CreateDisposalType.StatementToCheckingAccount && (
                              <Colxx xxs="12" md="6" lg="6">
                                <Row>
                                  <Colxx xxs="12" md="6" lg="6">
                                    <FormGroup className="has-float-label">
                                      <Label className="float-label">№ расчетного счета</Label>
                                      <Field
                                        className="form-control"
                                        type="text"
                                        name="checking_account"
                                        onBlur={() => setFieldTouched('checking_account', true)}
                                      />
                                      {errors.checking_account && touched.checking_account && (
                                        <div className="invalid-feedback d-block">{errors.checking_account}</div>
                                      )}
                                    </FormGroup>
                                  </Colxx>
                                  <Colxx xxs="12" sm="6" lg="6">
                                    <FormGroup className="has-float-label">
                                      <Label for="currency" className="float-label">
                                        Валюта приема
                                      </Label>
                                      <Select
                                        id="currency"
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        name="currency"
                                        disabled={true}
                                        value={values.currency_income}
                                        onChange={(currency: SelectorOption) => {
                                          setFieldValue('currency_income', currency);
                                          setFieldValue('currency', currency);
                                        }}
                                        options={activeCurrencies}
                                        placeholder=""
                                      />
                                    </FormGroup>
                                  </Colxx>
                                </Row>
                              </Colxx>
                            )}
                            <Colxx xxs="12" sm="6" lg="3">
                              <FormGroup className="has-float-label">
                                <Label for="currency" className="float-label">
                                  {t('disposals.comment')}
                                </Label>
                                <WithTooltip tooltipText={t('tooltip.add_comment')}>
                                  <Input
                                    type="textarea"
                                    name="message"
                                    maxLength={210}
                                    className="form-control"
                                    value={values.comment}
                                    onChange={({ target: { value } }) => setFieldValue('comment', value)}
                                    onBlur={() => setFieldTouched('comment')}
                                  />
                                </WithTooltip>
                              </FormGroup>
                            </Colxx>
                          </Row>

                          {this.getTables(values.type.value)}
                          {(statementsLoading || !contractAccounts) && <Loader />}
                          {errors.selectedTableItems && touched.selectedTableItems && !isChangeTableValue && (
                            <div className="invalid-feedback d-block">{errors.selectedTableItems}</div>
                          )}
                          {!isAdmin && (
                            <Row>
                              <Colxx xxs="12" className="d-flex flex-row justify-content-end align-items-center">
                                <Button color="primary" type="submit" disabled={createDisposalLoading}>
                                  {t('disposals.button.create_disposal')}
                                </Button>
                              </Colxx>
                            </Row>
                          )}
                        </CardBody>
                      </Card>
                    )}
                  </Form>
                );
              }}
            </Formik>
          </Colxx>
        </Row>

        {isCloseModalOpen && (
          <ModalCreatedInfo
            onCloseModal={() => {
              this.setState({ isCloseModalOpen: false, redirect: '/disposals' });
            }}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = ({ operations, disposals, settings, authUser }) => {
  const { selectedUser } = settings;

  const isAdmin = authUser.user.data && authUser.user.data.is_admin ? authUser.user.data.is_admin : false;
  return {
    contractAccounts: operations.payoutAccounts.data,
    statements: disposals.statements.statementsData,
    statementsLoading: disposals.statements.loading,
    accounts: disposals.statements.accountsData,
    currencies: settings.currencies.data,
    createdDisposalId: disposals.createDisposal.id,
    createdDisposalError: disposals.createDisposal.error,
    createDisposalLoading: disposals.createDisposal.loading,
    selectedMerchants: settings.selectedMerchants,
    selectedMerchantsId: settings.selectedMerchantsId,
    selectedUserId: settings.selectedUser ? settings.selectedUser.id : 0,
    isAdmin,
    allowMultiPartner: isAdmin
      ? selectedUser
        ? selectedUser.allow_multi_partner
        : false
      : authUser.user.data
      ? authUser.user.data.allow_multi_partner
      : false
  };
};

const mapDispatchToProps = {
  createDisposalAction: createDisposal,
  getPayoutAccountsAction: getPayoutAccounts,
  getCurrenciesAction: getCurrencies,
  getStatementsAction: getStatements,
  resetStatementsAction: resetStatements
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CreateDisposal)
);
