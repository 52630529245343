import { ALL_CURRENCY_OPTION, RUB_CURRENCY_OPTION, EUR_CURRENCY_OPTION } from '../../../constants/app';
import { OPERATIONS_OUTCOME_DIRECTIONS_OPTIONS } from './constants';
import { OPERATIONS_ACCOUNTS_OPTIONS } from '../constants';
import { DestinationsData } from '../../../redux/operations/interface';

export const prepareDataForSelect = (data: { name: string; id: string }[]) =>
  data.map(({ name, id }) => ({
    label: name,
    value: id,
    key: id
  }));

export const getCurrenciesOptions = (currencies, t) => {
  if (currencies && currencies.length) {
    return [
      ...ALL_CURRENCY_OPTION(t),
      ...currencies.map(({ name, id }) => ({
        label: name,
        value: id
      }))
    ];
  } else {
    return ALL_CURRENCY_OPTION(t);
  }
};

const interval = (x, y): number[] => {
  const result: number[] = [];
  let i = x + 1;
  while (i < y) {
    result.push(Number(i));
    i++;
  }
  return result;
};

const EUR_VALUES = interval(27, 40)
  .concat(interval(42, 46))
  .concat(interval(52, 54))
  .concat(interval(56, 72));

const ALL_VALUES = [3];

export const getCurrenciesOptionsForPayout = (currencies, accountId, t) => {
  if (currencies && currencies.length) {
    if (ALL_VALUES.includes(accountId)) {
      return [
        ...ALL_CURRENCY_OPTION(t),
        ...currencies.map(({ name, id }) => ({
          label: name,
          value: id
        }))
      ];
    } else if (EUR_VALUES.includes(accountId)) {
      return [...ALL_CURRENCY_OPTION(t), EUR_CURRENCY_OPTION];
    } else {
      return [...ALL_CURRENCY_OPTION(t), RUB_CURRENCY_OPTION];
    }
  } else {
    return ALL_CURRENCY_OPTION(t);
  }
};

export const getAccountOptions = (accounts, t: any, allOption = true) => {
  if (accounts && accounts.length) {
    const accountOptions = accounts.map(({ name, id, source }) => ({
      label: name,
      key: `${id}_${name}`,
      value: { id, source }
    }));
    if (!allOption) {
      return accountOptions;
    }
    return [OPERATIONS_ACCOUNTS_OPTIONS(t), ...accountOptions];
  } else {
    return [OPERATIONS_ACCOUNTS_OPTIONS(t)];
  }
};

export const getDirectionsOptions = (destinations, t: any, allOption = true) => {
  if (destinations && destinations.length) {
    const directionsOptions = destinations.map((destination: DestinationsData) => ({
      label: t(destination.name),
      value: {
        source: OPERATIONS_OUTCOME_DIRECTIONS_OPTIONS[0].value.source,
        destination: destination.id
      },
      key: destination.id
    }));
    if (!allOption) {
      return directionsOptions;
    }
    return [...OPERATIONS_OUTCOME_DIRECTIONS_OPTIONS, ...directionsOptions];
  } else {
    return OPERATIONS_OUTCOME_DIRECTIONS_OPTIONS;
  }
};
