import React, { Component, ReactNode } from 'react';
import { Label, FormGroup, Button, ModalHeader, ModalBody, Modal, Row } from 'reactstrap';
import { Formik, Form, Field } from 'formik';

import { SMS_PASSWORD_LENGTH } from './constant';
import { SmsAuthModalProps } from './interface';
import { Colxx } from '../../../components/common/CustomBootstrap';
import setInputFocus from './helper';

export default class SmsAuthModal extends Component<SmsAuthModalProps> {
  constructor(props: SmsAuthModalProps) {
    super(props);
    this.codeInput = React.createRef();
  }
  private codeInput: any;

  componentDidUpdate(prevProps: Readonly<SmsAuthModalProps>, prevState: Readonly<{}>, snapshot?: any): void {
    setInputFocus(this.codeInput);
  }

  validateSmsPassword = (value: string): string | undefined => {
    let error;
    if (!value) {
      error = 'Введите код';
    } else if (value.length < SMS_PASSWORD_LENGTH || value.length > SMS_PASSWORD_LENGTH) {
      error = 'Код должен состоять из 6-ти цифр';
    }
    return error;
  };

  render(): ReactNode {
    const { isOpen, smsAuthAction, cancellation } = this.props;

    return (
      <Modal isOpen={isOpen} toggle={cancellation}>
        <ModalHeader>Авторизация через смс</ModalHeader>
        <ModalBody>
          <Formik initialValues={{ smsPassword: '' }} onSubmit={({ smsPassword }) => smsAuthAction(smsPassword)}>
            {({ touched, errors, submitForm }) => (
              <Form>
                <FormGroup className="form-group has-float-label">
                  <Label className="float-label">Код</Label>
                  <Field
                    className="form-control"
                    type="password"
                    name="smsPassword"
                    validate={this.validateSmsPassword}
                    tabIndex="0"
                    innerRef={(node) => {
                      this.codeInput = node;
                    }}
                  />
                  {errors.smsPassword && touched.smsPassword && (
                    <div className="invalid-feedback d-block">{errors.smsPassword}</div>
                  )}
                </FormGroup>
                <Row>
                  <Colxx xxs="12" className="d-flex justify-content-end">
                    <Button color="primary" type="submit" onClick={submitForm}>
                      Войти
                    </Button>
                  </Colxx>
                </Row>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}
