import { all, call, fork, put, takeLatest, SimpleEffect } from 'redux-saga/effects';
import { CombinatorEffect } from '@redux-saga/types';

import { ApiRequest } from '../../helpers/ApiRequest/ApiRequest';
import { MENU_GET_USERS, MENU_GET_MERCHANTS } from '../actions';

import { GetMerchantAction } from './interface';
import { menuGetUsersSuccess, menuGetUsersError, menuGetMerchantsSuccess, menuGetMerchantsError } from './actions';

export function* getUsers(): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { data, error } = yield call(ApiRequest.get, '/users/');

    if (error) {
      yield put(menuGetUsersError(`${error}`));
    } else {
      yield put(menuGetUsersSuccess(data));
    }
  } catch (error) {
    yield put(menuGetUsersError(`${error}`));
  }
}

export function* getMerchants({ payload }: GetMerchantAction): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { userId } = payload;

    const url = userId ? `/users/${userId}/partners/` : '/user/partners/';

    const { data, error } = yield call(ApiRequest.get, url);

    if (error) {
      yield put(menuGetMerchantsError(`${error}`));
    } else {
      yield put(menuGetMerchantsSuccess(data));
    }
  } catch (error) {
    yield put(menuGetMerchantsError(`${error}`));
  }
}

export function* watchGetUsers(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(MENU_GET_USERS, getUsers);
}

export function* watchGetMerchants(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(MENU_GET_MERCHANTS, getMerchants);
}

export default function* rootSaga(): Generator<CombinatorEffect<string, any>, any, any> {
  yield all([fork(watchGetUsers), fork(watchGetMerchants)]);
}
