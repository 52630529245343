import React, { Component, Fragment, ReactNode } from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import { Nav, NavItem, Collapse } from 'reactstrap';
import { NavLink, withRouter } from 'react-router-dom';
import classnames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { withTranslation } from 'react-i18next';

import {
  setContainerClassnames,
  addContainerClassname,
  changeSelectedMenuHasSubItems,
  getAccountsBalance,
  setMenuPermissionId
} from '../../../redux/actions';

import { checkMenuItems } from '../../../helpers/Access';

import { SidebarState } from './interface';

class Sidebar extends Component<any, SidebarState> {
  constructor(props: any) {
    super(props);
    this.state = {
      selectedParentMenu: '',
      viewingParentMenu: '',
      collapsedMenus: [],
      menuItems: []
    };
  }

  updateMenuItems = () => {
    const { user, selectedUser, merchantIdList } = this.props;
    const currentUser = selectedUser ? selectedUser : user.data;
    const currentMerchantIdList = merchantIdList || 0;
    this.setState({ menuItems: checkMenuItems(currentUser, currentMerchantIdList) });
  };

  componentDidMount(): void {
    window.addEventListener('resize', this.handleWindowResize);
    this.handleWindowResize(null);
    this.handleProps();
    this.setSelectedLiActive(this.setHasSubItemStatus);
    this.updateMenuItems();
  }

  componentDidUpdate(prevProps: any, prevState: Readonly<SidebarState>): void {
    const { location, selectedUser } = this.props;

    if (
      prevProps.selectedUser !== selectedUser ||
      (prevProps.selectedUser && selectedUser && prevProps.selectedUser.id !== selectedUser.id)
    ) {
      this.updateMenuItems();
    }

    if (prevProps.location.pathname !== location.pathname) {
      this.setSelectedLiActive(this.setHasSubItemStatus);
      window.scrollTo(0, 0);
    }

    if (prevProps.location.pathname !== location.pathname && !this.isMenuLink()) {
      this.setState({ selectedParentMenu: '' });
    }

    this.handleProps();
  }

  componentWillUnmount(): void {
    this.removeEvents();
    window.removeEventListener('resize', this.handleWindowResize);
  }

  handleWindowResize = (event) => {
    if (event && !event.isTrusted) {
      return;
    }
    const { containerClassnames } = this.props;
    const nextClasses = this.getMenuClassesForResize(containerClassnames);
    this.props.setContainerClassnames(0, nextClasses.join(' '), this.props.selectedMenuHasSubItems);
  };

  handleDocumentClick = (e) => {
    const container = this.getContainer() as any;
    let isMenuClick = false;
    if (
      e.target &&
      e.target.classList &&
      (e.target.classList.contains('menu-button') || e.target.classList.contains('menu-button-mobile'))
    ) {
      isMenuClick = true;
    } else if (
      e.target.parentElement &&
      e.target.parentElement.classList &&
      (e.target.parentElement.classList.contains('menu-button') ||
        e.target.parentElement.classList.contains('menu-button-mobile'))
    ) {
      isMenuClick = true;
    } else if (
      e.target.parentElement &&
      e.target.parentElement.parentElement &&
      e.target.parentElement.parentElement.classList &&
      (e.target.parentElement.parentElement.classList.contains('menu-button') ||
        e.target.parentElement.parentElement.classList.contains('menu-button-mobile'))
    ) {
      isMenuClick = true;
    }
    if (container.contains(e.target) || container === e.target || isMenuClick) {
      return;
    }
    this.setState({
      viewingParentMenu: ''
    });
    this.toggle();
  };

  getMenuClassesForResize = (classes) => {
    const { menuHiddenBreakpoint, subHiddenBreakpoint } = this.props;
    let nextClasses = classes.split(' ').filter((x: string) => x !== '');
    const windowWidth = window.innerWidth;
    if (windowWidth < menuHiddenBreakpoint) {
      nextClasses.push('menu-mobile');
    } else if (windowWidth < subHiddenBreakpoint) {
      nextClasses = nextClasses.filter((x: string) => x !== 'menu-mobile');
      if (nextClasses.includes('menu-default') && !nextClasses.includes('menu-sub-hidden')) {
        nextClasses.push('menu-sub-hidden');
      }
    } else {
      nextClasses = nextClasses.filter((x: string) => x !== 'menu-mobile');
      if (nextClasses.includes('menu-default') && nextClasses.includes('menu-sub-hidden')) {
        nextClasses = nextClasses.filter((x: string) => x !== 'menu-sub-hidden');
      }
    }
    return nextClasses;
  };

  getContainer = () => ReactDOM.findDOMNode(this);

  toggle = () => {
    const hasSubItems = this.getIsHasSubItem();
    this.props.changeSelectedMenuHasSubItems(hasSubItems);
    const { containerClassnames, menuClickCount } = this.props;
    const currentClasses = containerClassnames ? containerClassnames.split(' ').filter((x: string) => x !== '') : '';
    let clickIndex = -1;

    if (!hasSubItems) {
      if (currentClasses.includes('menu-default') && (menuClickCount % +'4' === 0 || menuClickCount % +'4' === +'3')) {
        clickIndex = 1;
      } else if (currentClasses.includes('menu-sub-hidden') && (menuClickCount === +'2' || menuClickCount === +'3')) {
        clickIndex = 0;
      } else if (currentClasses.includes('menu-hidden') || currentClasses.includes('menu-mobile')) {
        clickIndex = 0;
      }
    } else if (currentClasses.includes('menu-sub-hidden') && menuClickCount === +'3') {
      clickIndex = +'2';
    } else if (currentClasses.includes('menu-hidden') || currentClasses.includes('menu-mobile')) {
      clickIndex = 0;
    }
    if (clickIndex >= 0) {
      this.props.setContainerClassnames(clickIndex, containerClassnames, hasSubItems);
    }
  };

  handleProps = () => {
    this.addEvents();
  };

  addEvents = () => {
    ['click', 'touchstart', 'touchend'].forEach((event: any) =>
      document.addEventListener(event, this.handleDocumentClick, true)
    );
  };

  removeEvents = () => {
    ['click', 'touchstart', 'touchend'].forEach((event: any) =>
      document.removeEventListener(event, this.handleDocumentClick, true)
    );
  };

  setSelectedLiActive = (callback) => {
    const oldli = document.querySelector('.sub-menu  li.active');
    if (oldli != null) {
      oldli.classList.remove('active');
    }

    const oldliSub = document.querySelector('.third-level-menu  li.active');
    if (oldliSub != null) {
      oldliSub.classList.remove('active');
    }

    /* set selected parent menu */
    const selectedSublink = document.querySelector('.third-level-menu  a.active') as any;
    if (selectedSublink != null) {
      selectedSublink.parentElement.classList.add('active');
    }

    const selectedlink = document.querySelector('.sub-menu  a.active') as any;
    if (selectedlink != null) {
      selectedlink.parentElement.classList.add('active');
      this.setState(
        {
          selectedParentMenu: selectedlink.parentElement.parentElement.getAttribute('data-parent')
        },
        callback
      );
    } else {
      const selectedParentNoSubItem = document.querySelector('.main-menu  li a.active');
      if (selectedParentNoSubItem != null) {
        this.setState(
          {
            selectedParentMenu: selectedParentNoSubItem.getAttribute('data-flag')
          },
          callback
        );
      } else if (this.state.selectedParentMenu === '') {
        this.setState(
          {
            selectedParentMenu: ''
          },
          callback
        );
      }
    }
  };

  isMenuLink = () => {
    const {
      location: { pathname }
    } = this.props;
    const { menuItems } = this.state;
    const section = pathname.split('/').filter((x: string) => x)[0];

    const menuItem = menuItems.find((item: any) => {
      const currSection = item.to.split('/').filter((x: string) => x)[0];
      return currSection === section;
    });

    return !!menuItem;
  };

  setHasSubItemStatus = () => {
    const hasSubmenu = this.getIsHasSubItem();
    this.props.changeSelectedMenuHasSubItems(hasSubmenu);
    this.toggle();
  };

  getIsHasSubItem = (): boolean => {
    const { selectedParentMenu, menuItems } = this.state;
    const menuItem = menuItems.find((x: any) => x.id === selectedParentMenu) as any;
    if (menuItem) {
      return (menuItem && menuItem.subs && menuItem.subs.length > 0) || menuItem.withServices || menuItem.withContracts;
    }
    return false;
  };

  selectedSubItem = (subItem) => {
    this.props.setMenuPermissionIdAction(subItem.permission);
  };

  openSubMenu = (e, menuItem) => {
    this.props.setMenuPermissionIdAction(menuItem.permission);
    const selectedParent = menuItem.id;
    const hasSubMenu = (menuItem.subs && menuItem.subs.length > 0) || menuItem.withServices || menuItem.withContracts;

    this.props.changeSelectedMenuHasSubItems(hasSubMenu);
    if (!hasSubMenu) {
      this.setState({
        viewingParentMenu: selectedParent,
        selectedParentMenu: selectedParent
      });
      this.toggle();
    } else {
      const { containerClassnames, menuClickCount } = this.props;
      const currentClasses = containerClassnames ? containerClassnames.split(' ').filter((x: string) => x !== '') : '';

      if (!currentClasses.includes('menu-mobile')) {
        if (currentClasses.includes('menu-sub-hidden') && (menuClickCount === +'2' || menuClickCount === 0)) {
          this.props.setContainerClassnames(+'3', containerClassnames, hasSubMenu);
        } else if (currentClasses.includes('menu-hidden') && (menuClickCount === 1 || menuClickCount === +'3')) {
          this.props.setContainerClassnames(+'2', containerClassnames, hasSubMenu);
        } else if (
          currentClasses.includes('menu-default') &&
          !currentClasses.includes('menu-sub-hidden') &&
          (menuClickCount === 1 || menuClickCount === +'3')
        ) {
          this.props.setContainerClassnames(0, containerClassnames, hasSubMenu);
        }
      } else {
        this.props.addContainerClassname('sub-show-temporary', containerClassnames);
      }
      this.setState({
        viewingParentMenu: selectedParent
      });
      e.preventDefault();
    }
  };

  toggleMenuCollapse = (e, menuKey) => {
    e.preventDefault();

    const { collapsedMenus } = this.state;
    if (collapsedMenus.indexOf(menuKey) > -1) {
      this.setState({
        collapsedMenus: collapsedMenus.filter((x: any) => x !== menuKey)
      });
    } else {
      collapsedMenus.push(menuKey);
      this.setState({
        collapsedMenus
      });
    }
    return false;
  };

  render(): ReactNode {
    const { accountsBalance, selectedMerchants, merchants, t } = this.props;
    const { selectedParentMenu, viewingParentMenu, collapsedMenus, menuItems } = this.state;

    return (
      <div className="sidebar">
        <div className="main-menu">
          <div className="scroll">
            <PerfectScrollbar
              options={{
                suppressScrollX: true,
                wheelPropagation: false
              }}
            >
              <Nav vertical className="list-unstyled">
                {menuItems &&
                  menuItems.map((item: any) => (
                    <NavItem
                      key={item.id}
                      className={classnames({
                        active:
                          (selectedParentMenu === item.id && viewingParentMenu === '') || viewingParentMenu === item.id
                      })}
                    >
                      {item.newWindow ? (
                        <a href={item.to} rel="noopener noreferrer" target="_blank">
                          <i className={item.icon} /> {t(item.label)}
                        </a>
                      ) : (
                        <NavLink to={item.to} onClick={(e: any) => this.openSubMenu(e, item)} data-flag={item.id}>
                          <i className={item.icon} /> {t(item.label)}
                        </NavLink>
                      )}
                    </NavItem>
                  ))}
              </Nav>
            </PerfectScrollbar>
          </div>
        </div>

        <div className="sub-menu">
          <div className="scroll">
            <PerfectScrollbar
              options={{
                suppressScrollX: true,
                wheelPropagation: false
              }}
            >
              {menuItems &&
                menuItems.map((item: any) => (
                  <Nav
                    key={item.id}
                    className={classnames({
                      'd-block':
                        (this.state.selectedParentMenu === item.id && this.state.viewingParentMenu === '') ||
                        this.state.viewingParentMenu === item.id
                    })}
                    data-parent={item.id}
                  >
                    {item.withServices && !selectedMerchants && !merchants.loading && (
                      <div className="text-center text-muted">Не выбран партнер</div>
                    )}
                    {item.withServices &&
                      selectedMerchants &&
                      !accountsBalance.loading &&
                      !accountsBalance.error &&
                      (!accountsBalance.data || !Object.keys(accountsBalance.data).length) && (
                        <div className="text-center text-muted">Нет данных</div>
                      )}
                    {!(item.withServices && (accountsBalance.loading || accountsBalance.error)) &&
                      item.subs &&
                      item.subs.map((sub, index) => (
                        <NavItem
                          key={`${item.id}_${index}`}
                          className={`${sub.subs && sub.subs.length > 0 ? 'has-sub-item' : ''}`}
                        >
                          {sub.newWindow ? (
                            <a href={sub.to} rel="noopener noreferrer" target="_blank">
                              <i className={sub.icon} /> {t(sub.label)}
                            </a>
                          ) : sub.subs && sub.subs.length > 0 ? (
                            <Fragment>
                              <NavLink
                                className={`rotate-arrow-icon opacity-50 ${
                                  collapsedMenus.indexOf(`${item.id}_${index}`) === -1 ? '' : 'collapsed'
                                }`}
                                to={sub.to}
                                id={`${item.id}_${index}`}
                                onClick={(e: any) => this.toggleMenuCollapse(e, `${item.id}_${index}`)}
                              >
                                <i className="simple-icon-arrow-down" /> {t(sub.label)}
                              </NavLink>

                              <Collapse isOpen={collapsedMenus.indexOf(`${item.id}_${index}`) === -1}>
                                <Nav className="third-level-menu">
                                  {sub.subs.map((thirdSub, thirdIndex) => (
                                    <NavItem key={`${item.id}_${index}_${thirdIndex}`}>
                                      {thirdSub.newWindow ? (
                                        <a href={thirdSub.to} rel="noopener noreferrer" target="_blank">
                                          <i className={thirdSub.icon} /> {t(thirdSub.label)}
                                        </a>
                                      ) : (
                                        <NavLink to={thirdSub.to}>
                                          <i className={thirdSub.icon} /> {t(thirdSub.label)}
                                        </NavLink>
                                      )}
                                    </NavItem>
                                  ))}
                                </Nav>
                              </Collapse>
                            </Fragment>
                          ) : (
                            <NavLink
                              to={sub.to}
                              onClick={(e: any) => {
                                this.selectedSubItem(sub);
                              }}
                            >
                              <i className={sub.icon} />{' '}
                              {item.withServices || item.withContracts ? sub.label : t(sub.label)}
                            </NavLink>
                          )}
                        </NavItem>
                      ))}
                  </Nav>
                ))}
            </PerfectScrollbar>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ menu, services, settings, authUser, operations }) => {
  const {
    containerClassnames,
    subHiddenBreakpoint,
    menuHiddenBreakpoint,
    menuClickCount,
    selectedMenuHasSubItems,
    merchants
  } = menu;
  const { accountsBalance } = services;
  const { selectedMerchants, selectedUser } = settings;
  const { user } = authUser;
  const { payoutAccounts } = operations;
  return {
    containerClassnames,
    subHiddenBreakpoint,
    menuHiddenBreakpoint,
    menuClickCount,
    selectedMenuHasSubItems,
    accountsBalance,
    selectedMerchants,
    merchantIdList: settings.selectedMerchantsId,
    selectedUser,
    user,
    merchants,
    payoutAccounts,
    isAdmin: authUser.user.data && authUser.user.data.is_admin ? authUser.user.data.is_admin : false
  };
};
export default withTranslation()(
  withRouter(connect(
    mapStateToProps,
    {
      setMenuPermissionIdAction: setMenuPermissionId,
      setContainerClassnames,
      addContainerClassname,
      changeSelectedMenuHasSubItems,
      getAccountsBalanceAction: getAccountsBalance
    }
  )(Sidebar) as any)
);
