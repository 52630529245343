import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { Row, Label, FormGroup, Button, ModalHeader, ModalBody, Modal } from 'reactstrap';

import { getGoogleCode, getGoogleQr, googleAuth } from '../../../redux/authUser/actions';
import { Colxx } from '../../../components/common/CustomBootstrap';
import { Loader } from '../../../components/common/Loader';

import { GOOGLE_PASSWORD_LENGTH, RESTORE_2FA_TEXT } from './constant';
import { GoogleQrModalProps } from './interface';
import { intFieldValue } from '../../operations/helpers';
import { withTranslation } from 'react-i18next';

class GoogleQrModal extends Component<GoogleQrModalProps> {
  componentDidMount(): void {
    this.props.getGoogleQrAction();
    this.props.getGoogleCodeAction();
  }

  onSubmit = ({ code }) => {
    const { googleAuthAction } = this.props;
    googleAuthAction(code);
  };

  validateCode = (value: string): string | undefined => {
    const { t } = this.props;

    let error;
    if (!value) {
      error = t('second_factor.enter_key');
    } else if (value.length < GOOGLE_PASSWORD_LENGTH || value.length > GOOGLE_PASSWORD_LENGTH) {
      error = t('second_factor.key_length');
    }
    return error;
  };

  render(): ReactNode {
    const { loading, googleQr, googleCode, cancellation, restoreCode, t } = this.props;

    return (
      <Modal size="lg" isOpen={true} toggle={cancellation}>
        <ModalHeader>{restoreCode ? RESTORE_2FA_TEXT(t)[restoreCode] : t('second_factor.connect')}</ModalHeader>
        <ModalBody>
          {loading && <Loader />}
          <Row>
            <Colxx xxs="12" lg="6">
              {googleQr && <img className="w-70" alt="qr" src={`data:image/jpeg;base64,${googleQr}`} />}
            </Colxx>
            <Colxx xxs="12" lg="6" className="d-flex flex-column justify-content-center">
              <div className="text-muted mb-5">{t('second_factor.description')}</div>

              {googleCode && (
                <div className="mb-5">
                  <div className="mb-1">{t('second_factor.manual_key')}</div>
                  <b>{googleCode}</b>
                </div>
              )}

              <Formik initialValues={{ code: '' }} onSubmit={this.onSubmit}>
                {({ errors, touched, setFieldValue }) => (
                  <Form>
                    <FormGroup className="form-group has-float-label">
                      <Label className="float-label">{t('second_factor.key')}</Label>
                      <Field
                        className="form-control"
                        type="text"
                        name="code"
                        validate={this.validateCode}
                        onChange={({ target: { value } }) => intFieldValue(value, 'code', setFieldValue)}
                      />
                      {errors.code && touched.code && <div className="invalid-feedback d-block">{errors.code}</div>}
                    </FormGroup>
                    <Button type="submit" color="primary" className="btn-shadow w-100">
                      {restoreCode ? RESTORE_2FA_TEXT(t)[restoreCode] : t('second_factor.connect')}
                    </Button>
                  </Form>
                )}
              </Formik>
            </Colxx>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = ({ authUser }) => {
  const { loading, error, googleQr, googleCode, restoreCode } = authUser;
  return {
    googleQr,
    restoreCode,
    googleCode,
    loading,
    error
  };
};

const mapDispatchToProps = {
  getGoogleQrAction: getGoogleQr,
  getGoogleCodeAction: getGoogleCode,
  googleAuthAction: googleAuth
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(GoogleQrModal)
);
