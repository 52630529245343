import { all, call, fork, put, takeLatest, SimpleEffect } from 'redux-saga/effects';
import { CombinatorEffect } from '@redux-saga/types';

import { ApiRequest } from '../../helpers/ApiRequest/ApiRequest';

import {
  changePasswordSuccess,
  changePasswordError,
  USER_CHANGE_PASSWORD,
  updatePasswordSuccess,
  updatePasswordError,
  USER_UPDATE_PASSWORD
} from '../actions';
import { ChangePasswordActionResult, UpdatePasswordActionResult } from './interface';

// CHANGE PASSWORD
function* changePassword({ payload }: ChangePasswordActionResult): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { oldPassword, newPassword, tmpCode, _2faCode } = payload;

    const requestBody: {
      new_password: string;
      old_password?: string;
      tfa_code?: string;
      code: string;
    } = { new_password: newPassword, code: tmpCode, tfa_code: _2faCode };

    if (newPassword) {
      requestBody.old_password = oldPassword;
    }

    const { error, status } = yield call(ApiRequest.put, '/user/password/', requestBody);

    if (error) {
      yield put(changePasswordError(`${error}`, status));
    } else {
      yield put(changePasswordSuccess());
    }
  } catch (error) {
    yield put(changePasswordError(`${error}`));
  }
}

function* updatePassword({ payload }: UpdatePasswordActionResult): Generator<SimpleEffect<string, any>, any, any> {
  try {
    const { oldPassword, newPassword } = payload;

    const requestBody: {
      new_password: string;
      old_password?: string;
    } = { new_password: newPassword };

    if (newPassword) {
      requestBody.old_password = oldPassword;
    }

    const { error, status } = yield call(ApiRequest.put, '/user/update-password/', requestBody);

    if (error) {
      yield put(updatePasswordError(`${error}`, status));
    } else {
      yield put(updatePasswordSuccess());
    }
  } catch (error) {
    yield put(updatePasswordError(`${error}`));
  }
}

export function* watchChangePassword(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(USER_CHANGE_PASSWORD, changePassword);
}

export function* watchUpdatePassword(): Generator<SimpleEffect<string, any>, any, any> {
  yield takeLatest(USER_UPDATE_PASSWORD, updatePassword);
}

export default function* rootSaga(): Generator<CombinatorEffect<string, any>, any, any> {
  yield all([fork(watchChangePassword), fork(watchUpdatePassword)]);
}
