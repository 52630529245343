import React, { Component, ReactNode } from 'react';
import { LoginPagePreviewProps } from '../interface';
import { getSupportEmail } from '../../../../helpers/CustomDesign';
import { LOGIN_PAGE_PREVIEW_HINTS } from '../constants';
import { getHintHtml } from '../helpers';
import { withTranslation } from 'react-i18next';

class LoginPagePreview extends Component<LoginPagePreviewProps, any> {
  render(): ReactNode {
    const { customDesignConfig, t } = this.props;
    const hints = this.props.hints || LOGIN_PAGE_PREVIEW_HINTS;
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: `
            <iframe class="design-preview-iframe" srcdoc='
            <!DOCTYPE html><html lang=&quot;en&quot;><head> <meta charset=&quot;utf-8&quot;> <script src=&quot;/config.js&quot; type=&quot;text/javascript&quot;></script> <link rel=&quot;shortcut icon&quot; href=&quot;/favicon.ico&quot;> <meta name=&quot;viewport&quot; content=&quot;width=device-width, initial-scale=1, shrink-to-fit=no&quot;> <meta name=&quot;theme-color&quot; content=&quot;#000000&quot;> <link rel=&quot;stylesheet&quot; href=&quot;/assets/fonts/simple-line-icons/css/simple-line-icons.css&quot;> <link rel=&quot;stylesheet&quot; href=&quot;/assets/fonts/iconsmind-s/css/iconsminds.css&quot;> <link rel=&quot;stylesheet&quot; href=&quot;/assets/custom-design/login-preview.css&quot;> <title>GFI</title></head><body class=&quot;rounded background&quot; style=&quot;overflow: hidden&quot;><div id=&quot;links-blocker&quot; style=&quot;position: absolute; top: 0; left: 0; width: 100%; height: 100%; opacity: 0; z-index: 9999;&quot;></div>
            <div id=&quot;root&quot;> <div class=&quot;h-100&quot;> <div class=&quot;notification-container notification-container-empty&quot;> <div></div></div><div class=&quot;fixed-background&quot;></div><main> <div class=&quot;container&quot;> <div class=&quot;h-100 login-form row&quot;>
            ${getHintHtml('loginBackgroundColor', hints.loginBackgroundColor)}
            <div class=&quot;mx-auto my-auto col-12 col-sm-11 col-md-8 col-lg-6&quot;> <div class=&quot;w-100 card&quot;> <div class=&quot;w-100 login-modal&quot;>
            ${getHintHtml('loginCardColor', hints.loginCardColor)}
            ${getHintHtml('loginLogo', hints.loginLogo)}
            <div class="mb-5 d-flex justify-content-end"><div class="d-inline-block"><div class="ml-2 dropdown"><button type="button" aria-haspopup="true" aria-expanded="false" class="language-button dropdown-toggle btn btn-light btn-sm"><span class="name">EN</span></button><div tabindex="-1" role="menu" aria-hidden="true" class="mt-3 dropdown-menu dropdown-menu-right"><button type="button" tabindex="0" role="menuitem" class="dropdown-item">Русский</button><button type="button" tabindex="0" role="menuitem" class="dropdown-item">English</button></div></div></div></div>
            <a aria-current=&quot;page&quot; class=&quot;white active&quot; href=&quot;/&quot;><div class=&quot;logo-block&quot; style=&quot;height: 50px; width: 100%;margin-bottom: 10px;&quot;><span class=&quot;logo-single&quot;></span></div></a>
            <form class=&quot;av-tooltip tooltip-label-bottom&quot;> <div class=&quot;form-group has-float-label form-group&quot;><label class=&quot;float-label&quot;>${t(
              'settings.login'
            )}</label> <inputname=&quot;username&quot; class=&quot;form-control&quot; value=&quot;&quot;> </div><div class=&quot;form-group has-float-label form-group&quot;><label class=&quot;float-label&quot;>${t(
            'customDesign.password'
          )}</label> <inputname=&quot;password&quot; class=&quot;form-control&quot; type=&quot;password&quot; value=&quot;&quot;> <div class=&quot;simple-icon-eye show-password-eye cursor-pointer&quot;></div></div><div class=&quot;d-flex justify-content-between align-items-center&quot;><a href=&quot;/user/forgot-password&quot;>${t(
            'login.restore-password'
          )}</a> <button type=&quot;submit&quot; class=&quot;btn-multiple-state btn btn-primary&quot;><span class=&quot;spinner d-inline-block&quot;><span class=&quot;bounce1&quot;></span><span class=&quot;bounce2&quot;></span><span class=&quot;bounce3&quot;></span></span><span class=&quot;label&quot;>${t(
            'button.Sign In'
          )}</span></button> </div></form>
            <div class=&quot;login-tooltip&quot;>
            ${t('customDesign.hint')}
            <a href=&quot;&quot;>${getSupportEmail(customDesignConfig)}</a>
            <div class=&quot;hint-container-supportEmail&quot;>
            ${getHintHtml('supportEmail', hints.supportEmail)}
            </div>
            </div></div></div></div></div></div></main> </div></div></body></html>
            <style>${this.props.customDesignCSS}</style>' />`
        }}
      />
    );
  }
}

export default withTranslation()(LoginPagePreview);
