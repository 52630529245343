import { GET_USER_LOGS, GET_USER_LOGS_ERROR, GET_USER_LOGS_SUCCESS } from '../actions';
import {
  GetUserLogsAction,
  GetUserLogsActionError,
  GetUserLogsActionSuccess,
  GetUserLogsRequestValues,
  UserLogsData
} from './interface';

// GET USER LOGS
export const getUserLogs = (request: GetUserLogsRequestValues): GetUserLogsAction => ({
  type: GET_USER_LOGS,
  payload: request
});

export const getUserLogsSuccess = (data: UserLogsData[], count: number): GetUserLogsActionSuccess => ({
  type: GET_USER_LOGS_SUCCESS,
  payload: { data, count }
});

export const getUserLogsError = (error: string): GetUserLogsActionError => ({
  type: GET_USER_LOGS_ERROR,
  payload: { error }
});
