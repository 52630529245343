import { UserInformation } from '../../redux/settings/interface';
import { ALL_OPTION } from './constants';
import { Merchant } from '../../redux/menu/interface';

export const getUsersOptions = (users: UserInformation[], t: any) => {
  if (users && users.length) {
    return [
      { ...ALL_OPTION(t) },
      ...users.map(({ fio, id }) => ({
        label: fio,
        key: `${id}_${fio}`,
        value: id
      }))
    ];
  } else {
    return [];
  }
};

export const getPartnerOptions = (partners: { [userId: number]: Merchant[] }, userId: number) => {
  if (partners && partners[userId] && partners[userId].length) {
    return [
      { ...ALL_OPTION },
      ...partners[userId].map(({ name, id }) => ({
        label: name,
        key: `${id}_${name}`,
        value: { id }
      }))
    ];
  } else {
    return [];
  }
};
