import React from 'react';
import DatePicker from 'react-datepicker';
import { ru, enUS } from 'date-fns/locale';
import cn from 'classnames';
import i18next from 'i18next';

import { DatePickerLocaledProps } from './interface';

export class DatePickerLocaled extends React.Component<DatePickerLocaledProps> {
  state = {
    isHover: false
  };

  handleMouseOver = () => {
    const { isHover } = this.state;
    !isHover && this.setState({ isHover: true });
  };

  handleMouseOut = () => {
    const { isHover } = this.state;
    isHover && this.setState({ isHover: false });
  };

  render() {
    const { tooltip } = this.props;
    const { isHover } = this.state;
    const lang = i18next.language === 'en' ? enUS : ru;

    return (
      <>
        <DatePicker
          {...this.props}
          locale={lang}
          popperPlacement="bottom-start"
          popperModifiers={{
            flip: {
              behavior: ['bottom']
            }
          }}
        />
        {tooltip && (
          <div
            className={cn('simple-icon-question tooltip-question cursor-pointer')}
            onMouseEnter={this.handleMouseOver}
            onMouseLeave={this.handleMouseOut}
          >
            {isHover && <div className="invalid-feedback d-block">{tooltip}</div>}
          </div>
        )}
      </>
    );
  }
}
