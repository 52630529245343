export const ALL_OPTION = (t: any) => ({
  key: 0,
  label: t('all'),
  value: ''
});

export const ALL = [
  {
    key: 0,
    label: 'Все',
    value: ''
  }
];
