import React from 'react';
import { Redirect, Route, Switch } from 'react-router';

import Partners from './Partners/Partners';
import Contracts from './Contracts/Contracts';
import Users from './Users/Users';

const Glossary = ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}/partners`} component={Partners} />
    <Route exact path={`${match.url}/contracts`} component={Contracts} />
    <Route exact path={`${match.url}/users`} component={Users} />
    <Redirect from={match.url} to={`${match.url}/users`} />
    <Redirect to={'/error'} />
  </Switch>
);

export default Glossary;
