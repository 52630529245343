import React, { ChangeEvent, Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Form, Formik } from 'formik';
import Select from 'react-select';
import { TreeSelect } from 'antd';
import isEqual from 'lodash/isEqual';

import { NotificationManager } from '../../components/common/react-notifications';
import { Colxx } from '../../components/common/CustomBootstrap';
import { getRequestTypes } from '../../redux/support/actions';
import { getPayoutAccounts } from '../../redux/operations/actions';

import { getTreeSelectData, reduceData } from './helpers';
import { SelectorOption } from '../../interfaces/app';
import { SUPPORT_INITIAL_VALUES, FINANCES_IDS } from './constants';
import { EMPTY_FIELD_ERROR } from '../../constants/app';
import { CreateRequestFormValues, CreateRequestModalProps, CreateRequestModalState } from './interface';
import { Merchant } from '../../redux/menu/interface';
import { NOTIFICATION_TIMEOUT } from '../../constants/defaultValues';
import { withTranslation } from 'react-i18next';
class CreateRequestModal extends Component<CreateRequestModalProps, CreateRequestModalState> {
  setFieldValue;

  constructor(props: CreateRequestModalProps) {
    super(props);
    this.state = {
      uploadFileName: '',
      initialValues: SUPPORT_INITIAL_VALUES,
      typesOptions: [],
      documentOptions: [],
      accountOptions: []
    };
  }

  componentDidMount(): void {
    this.props.getRequestTypesAction();
  }

  componentDidUpdate(prevProps: Readonly<CreateRequestModalProps>): void {
    const { error, loading, types, onCloseModal, contractAccounts, t } = this.props;
    if (!isEqual(prevProps.types, types)) {
      this.setState({ typesOptions: getTreeSelectData(types, t) });
    }

    if (contractAccounts && !isEqual(prevProps.contractAccounts, contractAccounts)) {
      this.setState({ documentOptions: reduceData(contractAccounts) });
    }

    if (error && error !== prevProps.error) {
      NotificationManager.info(error, 'Ошибка', NOTIFICATION_TIMEOUT, null, null, '');
    }

    if (prevProps.loading && !loading && !error) {
      onCloseModal();
    }
  }

  getPayoutAccounts = (partnerId) => {
    this.props.getPayoutAccountsAction([partnerId]);
    this.setFieldValue('account', { value: '', label: '', key: '' });
    this.setFieldValue('contract', { accounts: [], label: '', key: '', value: '' });
  };

  setAccounts = (contract) => {
    this.setFieldValue('account', { value: '', label: '', key: '' });
    this.setState({ accountOptions: contract.accounts });
  };

  onSubmit = ({ partner, message, doc_file, request_type, contract, account }: CreateRequestFormValues) => {
    const { onConfirmModal } = this.props;
    onConfirmModal({
      partner: partner.value,
      message,
      account: account.key,
      contract: contract.key,
      request_type: Number(request_type),
      doc_file: doc_file && doc_file[0]
    });
  };

  validate = ({ message, request_type, contract, partner, account }) => {
    const { t } = this.props;

    const errors: { [key: string]: string } = {};
    if (request_type && FINANCES_IDS.includes(request_type) && !account.key) {
      errors.account = t(EMPTY_FIELD_ERROR);
    }
    if (!message) {
      errors.message = t(EMPTY_FIELD_ERROR);
    }
    if (!request_type) {
      errors.request_type = t(EMPTY_FIELD_ERROR);
    }
    if (!contract.key) {
      errors.contract = t(EMPTY_FIELD_ERROR);
    }
    if (!partner.key) {
      errors.partner = t(EMPTY_FIELD_ERROR);
    }
    return errors;
  };

  getPartnerNameOptions = (partners: Merchant[]) => {
    if (partners && partners.length) {
      return [...partners.map(({ name, id }) => ({ label: name, key: id, value: id }))];
    } else {
      return [];
    }
  };

  render(): ReactNode {
    const { onCloseModal, selectedMerchants, t } = this.props;
    const { uploadFileName, initialValues, typesOptions, documentOptions, accountOptions } = this.state;

    return (
      <Modal isOpen={true} toggle={onCloseModal}>
        <ModalHeader>{t('support.new_request')}</ModalHeader>
        <ModalBody>
          <Formik validate={this.validate} initialValues={initialValues} onSubmit={this.onSubmit}>
            {({ values, setFieldValue, errors, touched, isValid, setFieldTouched }) => {
              this.setFieldValue = setFieldValue;

              return (
                <Form>
                  <FormGroup className="form-group has-float-label">
                    <Label className="float-label">{t('support.request_type')}</Label>
                    <TreeSelect
                      className="one-choice-select"
                      treeData={typesOptions}
                      value={values.request_type}
                      onChange={(value: string) => setFieldValue('request_type', value)}
                      style={{ width: '100%' }}
                      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                      onBlur={() => setFieldTouched('request_type')}
                      showSearch
                      allowClear
                      treeDefaultExpandAll
                    />
                    {errors.request_type && touched.request_type && (
                      <div className="invalid-feedback d-block">{errors.request_type}</div>
                    )}
                  </FormGroup>
                  <FormGroup className="form-group has-float-label">
                    <Label className="float-label">{t('support.merchant')}</Label>
                    <Select
                      className="react-select"
                      classNamePrefix="react-select"
                      name="partner"
                      value={values.partner}
                      options={this.getPartnerNameOptions(selectedMerchants)}
                      onChange={(partner: SelectorOption) => {
                        setFieldValue('partner', partner);
                        this.getPayoutAccounts(partner.key);
                      }}
                      placeholder=""
                      onBlur={() => setFieldTouched('partner')}
                      maxLength="255"
                      noOptionsMessage={() => t('Нет данных')}
                    />
                    {errors.partner && touched.partner && (
                      <div className="invalid-feedback d-block">{errors.partner}</div>
                    )}
                  </FormGroup>
                  <FormGroup className="form-group has-float-label">
                    <Label className="float-label">{t('support.contract')}</Label>
                    <Select
                      className="react-select"
                      classNamePrefix="react-select"
                      name="contract"
                      value={values.contract}
                      onChange={(contract: SelectorOption) => {
                        setFieldValue('contract', contract);
                        this.setAccounts(contract);
                      }}
                      onBlur={() => setFieldTouched('contract')}
                      options={documentOptions}
                      placeholder=""
                      noOptionsMessage={() => t('Нет данных')}
                    />
                    {errors.contract && touched.contract && (
                      <div className="invalid-feedback d-block">{errors.contract}</div>
                    )}
                  </FormGroup>
                  <FormGroup className="has-float-label">
                    <Label className="float-label">{t('Merchant Account')}</Label>
                    <Select
                      className="react-select"
                      classNamePrefix="react-select"
                      name="account"
                      value={values.account}
                      onBlur={() => setFieldTouched('account')}
                      options={accountOptions}
                      onChange={(selectedValue) => {
                        setFieldValue('account', selectedValue);
                      }}
                      placeholder=""
                      noOptionsMessage={() => t('Нет данных')}
                    />
                    {errors.account && touched.account && (
                      <div className="invalid-feedback d-block">{errors.account}</div>
                    )}
                  </FormGroup>
                  <FormGroup className="form-group has-float-label">
                    <Label className="float-label">{t('Message')}</Label>
                    <Input
                      type="textarea"
                      name="message"
                      value={values.message}
                      onChange={({ currentTarget }: ChangeEvent<HTMLInputElement>) =>
                        setFieldValue('message', currentTarget.value)
                      }
                      onBlur={() => setFieldTouched('message')}
                    />
                    {errors.message && touched.message && (
                      <div className="invalid-feedback d-block">{errors.message}</div>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor="doc_file" className="text-muted cursor-pointer">
                      <i className="simple-icon-cloud-upload left-text-icon" />
                      {t('settings.services')}
                    </label>
                    <div>{uploadFileName}</div>
                    <input
                      type="file"
                      id="doc_file"
                      name="doc_file"
                      style={{ display: 'none' }}
                      onChange={({ currentTarget }) => {
                        currentTarget.files && this.setState({ uploadFileName: currentTarget.files[0].name });
                        setFieldValue('doc_file', currentTarget.files);
                      }}
                    />
                  </FormGroup>
                  <Colxx xxs="12" className="d-flex flex-row justify-content-end align-items-center p-0">
                    <Button disabled={!isValid} color="primary" type="submit">
                      {t('Send')}
                    </Button>
                  </Colxx>
                </Form>
              );
            }}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = ({ support, settings, operations }) => ({
  types: support.types.data,
  loading: support.openRequest.loading,
  error: support.openRequest.error,
  selectedMerchants: settings.selectedMerchants,
  contractAccounts: operations.payoutAccounts.data
});

const mapDispatchToProps = {
  getRequestTypesAction: getRequestTypes,
  getPayoutAccountsAction: getPayoutAccounts
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CreateRequestModal)
);
