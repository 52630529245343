import React from 'react';
import WithTooltip from '../../../components/common/WithTooltip';

interface CustomTagInputFieldProps {
  name: string;
  type: string;
  tooltip: string;
  setFieldValue: (fieldName: string, fieldValue: Date) => void;
}

interface CustomTagInputFieldState {
  data: any[];
}

export class CustomTagInputField extends React.Component<CustomTagInputFieldProps, CustomTagInputFieldState> {
  state: CustomTagInputFieldState = {
    data: []
  };
  inputValue: any;

  static defaultProps = {
    type: 'text',
    tooltip: ''
  };

  removeTag = (i) => {
    const name = this.props.name;
    const newTags = [...this.state.data];
    newTags.splice(i, 1);
    const data = {};
    data[name] = newTags;
    this.props.setFieldValue(name, data[name]);
    this.setState({ data: data[name] });
  };

  onBlur = (e) => {
    const val = e.target.value;
    if (val.trim()) {
      const isExist = this.state.data.find((tag: any) => tag.toLowerCase() === val.toLowerCase());
      if (!isExist) {
        e.key = ' ';
        this.inputKeyDown(e);
      }
    }
  };

  inputKeyDown = (e) => {
    const name = this.props.name;
    const val = e.target.value;
    if ((e.key === ' ' || e.key === 'Enter') && val.trim()) {
      const isExist = this.state.data.find((tag: any) => tag.toLowerCase() === val.toLowerCase());
      if (isExist) {
        return;
      }
      this.inputValue.value = null;
      const data = {};
      data[name] = [...this.state.data, val.trim()];
      this.setState({ data: data[name] });
      this.props.setFieldValue(name, data[name]);
    } else if (e.key === 'Backspace' && !val) {
      this.removeTag(this.state.data.length - 1);
    }
  };

  render() {
    const { data } = this.state;
    const { type, tooltip } = this.props;
    return (
      <div className="input-tag">
        <ul className="input-tag__tags">
          {tooltip ? (
            <WithTooltip tooltipText={tooltip}>
              <input
                type={type}
                className="form-control"
                onKeyDown={(e) => {
                  this.inputKeyDown(e);
                }}
                onBlur={(e) => {
                  this.inputKeyDown(e);
                }}
                ref={(c) => {
                  this.inputValue = c;
                }}
              />
            </WithTooltip>
          ) : (
            <input
              type={type}
              className="form-control"
              onKeyDown={(e) => {
                this.inputKeyDown(e);
              }}
              onBlur={(e) => {
                this.onBlur(e);
              }}
              ref={(c) => {
                this.inputValue = c;
              }}
            />
          )}
          {data && data.length > 0 && (
            <div className="option-group">
              {data.map((tag, i) => (
                <li key={tag}>
                  {tag}
                  <button
                    type="button"
                    onClick={() => {
                      this.removeTag(i);
                    }}
                  >
                    +
                  </button>
                </li>
              ))}
            </div>
          )}
        </ul>
      </div>
    );
  }
}

export default CustomTagInputField;
