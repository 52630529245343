import { GET_DOCUMENTS, GET_DOCUMENTS_SUCCESS, GET_DOCUMENTS_ERROR, RESET_DOCUMENTS } from '../actions';
import { DocumentsState } from './interface';

const INIT_STATE: DocumentsState = {
  rows: null,
  loading: false,
  error: ''
};

export default (state: DocumentsState = INIT_STATE, { type, payload }): DocumentsState => {
  switch (type) {
    case GET_DOCUMENTS:
      return {
        ...state,
        rows: null,
        loading: true,
        error: ''
      };
    case GET_DOCUMENTS_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false
      };
    case GET_DOCUMENTS_ERROR:
      return {
        ...state,
        error: payload.error,
        rows: null,
        loading: false
      };
    case RESET_DOCUMENTS:
      return {
        ...state,
        rows: null,
        loading: false,
        error: ''
      };
    default:
      return { ...state };
  }
};
