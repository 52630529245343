import { defaultLocale } from '../../constants/defaultValues';
import {
  CHANGE_LOCALE,
  CHANGE_USER,
  RESET_USER_AND_MERCHANT_STORE,
  CHANGE_MERCHANT,
  GET_SYSTEM_SETTINGS,
  GET_SYSTEM_SETTINGS_SUCCESS,
  GET_SYSTEM_SETTINGS_ERROR,
  PUT_SYSTEM_SETTINGS,
  PUT_SYSTEM_SETTINGS_SUCCESS,
  PUT_SYSTEM_SETTINGS_ERROR,
  GET_CURRENCIES,
  GET_CURRENCIES_SUCCESS,
  GET_CURRENCIES_ERROR,
  GET_PERMISSIONS_SETTINGS,
  GET_PERMISSIONS_SETTINGS_SUCCESS,
  GET_PERMISSIONS_SETTINGS_ERROR,
  PUT_PERMISSIONS_SETTINGS,
  PUT_PERMISSIONS_SETTINGS_SUCCESS,
  PUT_PERMISSIONS_SETTINGS_ERROR,
  GET_ACCOUNT_SETTINGS,
  GET_ACCOUNT_SETTINGS_SUCCESS,
  GET_ACCOUNT_SETTINGS_ERROR,
  PUT_ACCOUNT_SETTINGS,
  PUT_ACCOUNT_SETTINGS_SUCCESS,
  PUT_ACCOUNT_SETTINGS_ERROR,
  RESET_ACCOUNT_SETTINGS_STORE,
  CHANGE_CURRENCY_ERROR,
  CHANGE_CURRENCY_SUCCESS,
  CHANGE_CURRENCY,
  GET_SITES_SETTINGS,
  GET_SITES_SETTINGS_SUCCESS,
  GET_SITES_SETTINGS_ERROR,
  PUT_SITES_SETTINGS,
  PUT_SITES_SETTINGS_SUCCESS,
  PUT_SITES_SETTINGS_ERROR,
  PUT_CUSTOM_DESIGN,
  GET_CUSTOM_DESIGN,
  APPLY_CUSTOM_DESIGN,
  PUT_CUSTOM_DESIGN_ERROR,
  GET_CUSTOM_DESIGN_ERROR,
  DELETE_CUSTOM_DESIGN_SUCCESS,
  DELETE_CUSTOM_DESIGN
} from '../actions';
import { SettingsReducerState } from './interface';
import { getMerchantIdList } from '../../helpers/Utils';

const locale = localStorage.getItem('currentLanguage') || defaultLocale;

const INIT_STATE: SettingsReducerState = {
  locale,
  currencies: {
    data: null,
    loading: true,
    error: ''
  },
  selectedUser: null,
  selectedMerchants: null,
  selectedMerchantsId: null,
  gcmSettings: {
    data: null,
    loading: false,
    saving: false,
    error: ''
  },
  permissionsSettings: {
    data: null,
    loading: false,
    error: ''
  },
  accountSettings: {
    data: null,
    loading: false,
    saving: false,
    error: ''
  },
  userSites: {
    data: null,
    loading: false,
    error: ''
  },
  customDesign: {
    data: null,
    error: '',
    saving: false,
    loading: true
  }
};

export default (state: SettingsReducerState = INIT_STATE, { type, payload }): SettingsReducerState => {
  switch (type) {
    case CHANGE_LOCALE:
      return {
        ...state,
        locale: payload.locale
      };
    case CHANGE_USER:
      return {
        ...state,
        selectedUser: {
          ...state.selectedUser,
          ...payload.selectedUser
        }
      };
    case CHANGE_MERCHANT:
      return {
        ...state,
        selectedMerchants: payload.selectedMerchants,
        selectedMerchantsId: getMerchantIdList(payload.selectedMerchants)
      };
    case RESET_USER_AND_MERCHANT_STORE:
      return {
        ...state,
        selectedUser: null,
        selectedMerchants: null,
        selectedMerchantsId: null
      };
    case GET_CURRENCIES:
      return {
        ...state,
        currencies: {
          ...state.currencies,
          loading: true
        }
      };
    case GET_CURRENCIES_SUCCESS:
      return {
        ...state,
        currencies: {
          ...state.currencies,
          data: payload.currencies,
          loading: false
        }
      };
    case GET_CURRENCIES_ERROR:
      return {
        ...state,
        currencies: {
          ...state.currencies,
          error: payload.error,
          loading: false
        }
      };
    case CHANGE_CURRENCY:
      return {
        ...state,
        currencies: {
          ...state.currencies,
          loading: true
        }
      };
    case PUT_CUSTOM_DESIGN:
      return {
        ...state,
        customDesign: {
          ...state.customDesign,
          saving: true,
          loading: false
        }
      };
    case PUT_CUSTOM_DESIGN_ERROR:
      return {
        ...state,
        customDesign: {
          ...state.customDesign,
          error: payload.error,
          saving: false,
          loading: false
        }
      };
    case GET_CUSTOM_DESIGN:
      return {
        ...state,
        customDesign: {
          ...state.customDesign,
          error: payload.error,
          saving: false,
          loading: true
        }
      };
    case APPLY_CUSTOM_DESIGN:
      return {
        ...state,
        customDesign: {
          data: {
            ...state.customDesign.data,
            ...payload
          },
          saving: false,
          loading: false,
          error: ''
        }
      };
    case DELETE_CUSTOM_DESIGN:
      return {
        ...state,
        customDesign: {
          ...state.customDesign,
          error: '',
          saving: true,
          loading: false
        }
      };
    case DELETE_CUSTOM_DESIGN_SUCCESS:
      return {
        ...state,
        customDesign: {
          ...state.customDesign,
          error: '',
          saving: false,
          loading: false
        }
      };
    case GET_CUSTOM_DESIGN_ERROR:
      return {
        ...state,
        customDesign: {
          ...state.customDesign,
          error: payload.error,
          saving: false
        }
      };
    case CHANGE_CURRENCY_SUCCESS:
      return {
        ...state,
        currencies: {
          ...state.currencies,
          loading: false
        }
      };
    case CHANGE_CURRENCY_ERROR:
      return {
        ...state,
        currencies: {
          ...state.currencies,
          error: payload.error,
          loading: false
        }
      };
    case GET_SYSTEM_SETTINGS:
      return {
        ...state,
        gcmSettings: {
          ...state.gcmSettings,
          loading: true
        }
      };
    case GET_SYSTEM_SETTINGS_SUCCESS:
      return {
        ...state,
        gcmSettings: {
          ...state.gcmSettings,
          ...payload.gcmSettings,
          loading: false
        }
      };
    case GET_SYSTEM_SETTINGS_ERROR:
      return {
        ...state,
        gcmSettings: {
          ...state.gcmSettings,
          ...payload,
          loading: false
        }
      };
    case PUT_SYSTEM_SETTINGS:
      return {
        ...state,
        gcmSettings: {
          ...state.gcmSettings,
          saving: true
        }
      };
    case PUT_SYSTEM_SETTINGS_SUCCESS:
      return {
        ...state,
        gcmSettings: {
          ...state.gcmSettings,
          saving: false
        }
      };
    case PUT_SYSTEM_SETTINGS_ERROR:
      return {
        ...state,
        gcmSettings: {
          ...state.gcmSettings,
          ...payload,
          saving: false
        }
      };
    case GET_PERMISSIONS_SETTINGS:
      return {
        ...state,
        permissionsSettings: {
          ...state.permissionsSettings,
          loading: true
        }
      };
    case GET_PERMISSIONS_SETTINGS_SUCCESS:
      return {
        ...state,
        permissionsSettings: {
          ...state.permissionsSettings,
          ...payload.permissionsSettings,
          loading: false
        }
      };
    case GET_PERMISSIONS_SETTINGS_ERROR:
      return {
        ...state,
        permissionsSettings: {
          ...state.permissionsSettings,
          ...payload,
          data: null,
          loading: false
        }
      };
    case PUT_PERMISSIONS_SETTINGS:
      return {
        ...state,
        permissionsSettings: {
          ...state.permissionsSettings,
          loading: true
        }
      };
    case PUT_PERMISSIONS_SETTINGS_SUCCESS:
      return {
        ...state,
        permissionsSettings: {
          ...state.permissionsSettings,
          data: payload.permissions,
          loading: false
        }
      };
    case PUT_PERMISSIONS_SETTINGS_ERROR:
      return {
        ...state,
        permissionsSettings: {
          ...state.permissionsSettings,
          ...payload,
          loading: false
        }
      };
    case GET_SITES_SETTINGS:
      return {
        ...state,
        userSites: {
          ...state.userSites,
          loading: true
        }
      };
    case GET_SITES_SETTINGS_SUCCESS:
      return {
        ...state,
        userSites: {
          ...payload.userSites,
          loading: false
        }
      };
    case GET_SITES_SETTINGS_ERROR:
      return {
        ...state,
        userSites: {
          ...state.userSites,
          ...payload,
          data: null,
          loading: false
        }
      };
    case PUT_SITES_SETTINGS:
      return {
        ...state,
        userSites: {
          ...state.userSites,
          error: ''
        }
      };
    case PUT_SITES_SETTINGS_SUCCESS:
      return {
        ...state,
        userSites: {
          ...state.userSites
        }
      };
    case PUT_SITES_SETTINGS_ERROR:
      return {
        ...state,
        userSites: {
          ...state.userSites,
          error: payload.error
        }
      };
    case GET_ACCOUNT_SETTINGS:
      return {
        ...state,
        accountSettings: {
          ...state.accountSettings,
          loading: true,
          error: ''
        }
      };
    case GET_ACCOUNT_SETTINGS_SUCCESS:
      return {
        ...state,
        selectedUser:
          state.selectedUser && state.selectedUser.id === payload.data.id ? payload.data : state.selectedUser,
        accountSettings: {
          ...state.accountSettings,
          ...payload,
          loading: false
        }
      };
    case GET_ACCOUNT_SETTINGS_ERROR:
      return {
        ...state,
        accountSettings: {
          ...state.accountSettings,
          error: payload.error,
          loading: false
        }
      };
    case PUT_ACCOUNT_SETTINGS:
      return {
        ...state,
        accountSettings: {
          ...state.accountSettings,
          saving: true,
          error: ''
        }
      };
    case PUT_ACCOUNT_SETTINGS_SUCCESS:
      return {
        ...state,
        accountSettings: {
          ...state.accountSettings,
          saving: false
        }
      };
    case PUT_ACCOUNT_SETTINGS_ERROR:
      return {
        ...state,
        accountSettings: {
          ...state.accountSettings,
          error: payload.error,
          saving: false
        }
      };
    case RESET_ACCOUNT_SETTINGS_STORE:
      return {
        ...state,
        accountSettings: {
          ...state.accountSettings,
          ...INIT_STATE.accountSettings
        }
      };
    default:
      return { ...state };
  }
};
