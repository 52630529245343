import {
  READ_NOTIFICATION_SUCCESS,
  READ_NOTIFICATION,
  READ_NOTIFICATION_ERROR,
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_ERROR
} from '../actions';

import { NotificationsState } from './interface';
import { readNotifications } from '../../containers/navs/Topnav/helpers';

const INIT_STATE: NotificationsState = {
  data: null,
  loading: false,
  count: 0,
  unread: 0,
  error: '',
  readMessage: {
    loading: false,
    error: '',
    lastId: null
  }
};

export default (state: NotificationsState = INIT_STATE, { type, payload }): NotificationsState => {
  switch (type) {
    case GET_NOTIFICATIONS:
      return {
        ...state,
        loading: true
      };
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: payload.data,
        count: payload.count,
        unread: payload.unread
      };
    case GET_NOTIFICATIONS_ERROR:
      return {
        ...state,
        loading: false,
        data: null,
        error: payload.error
      };

    case READ_NOTIFICATION:
      return {
        ...state,
        data: readNotifications(payload.id, state.data),
        readMessage: {
          ...state.readMessage,
          lastId: payload.id,
          loading: true
        }
      };
    case READ_NOTIFICATION_SUCCESS:
      return {
        ...state,
        readMessage: {
          ...state.readMessage,
          loading: false
        }
      };
    case READ_NOTIFICATION_ERROR:
      return {
        ...state,
        readMessage: {
          ...state.readMessage,
          loading: false,
          error: payload.error
        }
      };
    default:
      return { ...state };
  }
};
