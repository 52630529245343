export const width400 = 400;
export const width576 = 576;
export const width800 = 800;
export const width1000 = 1000;
export const width1500 = 1500;
export const width1800 = 1800;
export const width1900 = 1900;

export const ticks2 = 2;
export const ticks3 = 3;
export const ticks4 = 4;
export const ticks5 = 5;
export const ticks6 = 6;
export const ticks7 = 7;
export const ticks8 = 8;

export const CHART_MAX_TIPS_LIMITS = {
  [width400]: ticks3,
  [width576]: ticks4,
  [width800]: ticks2,
  [width1000]: ticks3,
  [width1500]: ticks4,
  [width1800]: ticks5,
  [width1900]: ticks6
};
