import React, { Component, Fragment, ReactNode } from 'react';
import { RouteComponentProps } from 'react-router';
import { Row } from 'reactstrap';

import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import Breadcrumb from '../../../containers/navs/Breadcrumb';
import ChangePasswordForm from './ChangePasswordForm';

export default class Layout extends Component<RouteComponentProps> {
  render(): ReactNode {
    const { match } = this.props;

    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.profile" match={match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row className="d-flex justify-content-center">
          <Colxx xxs="12" lg="6" className="mb-4">
            <ChangePasswordForm />
          </Colxx>
        </Row>
      </Fragment>
    );
  }
}
