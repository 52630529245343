import { subMonths } from 'date-fns';
import i18n from '../../i18n';
const { t } = i18n;
export const DOCUMENTS_SERVICES_OPTIONS = [
  {
    label: t('Acquiring'),
    value: 1
  },
  {
    label: t('Mobile commerce'),
    value: 2
  }
];

export const DEFAULT_SORTER = [
  {
    id: 'date_begin',
    desc: true
  }
];

export const DOCUMENTS_INITIAL_VALUES = {
  dateFrom: subMonths(new Date(), 1),
  dateTo: new Date(),
  serviceType: DOCUMENTS_SERVICES_OPTIONS[0],
  accountId: null,
  partner: {
    key: '',
    value: 0,
    label: ''
  }
};
