import React, { Component, Fragment, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import { Row, Card, CardBody, Button, FormGroup, Label, ModalHeader, Modal, ModalBody } from 'reactstrap';
import Select from 'react-select';
import isEqual from 'lodash/isEqual';

import {
  getDestinations,
  getConversionChartData,
  getPayoutAccounts,
  resetConversionChart,
  getCurrencies
} from '../../../redux/actions';
import { Colxx, Separator } from '../../../components/common/CustomBootstrap';
import { NotificationManager } from '../../../components/common/react-notifications';
import { Loader } from '../../../components/common/Loader';
import { NotFoundMessage } from '../../../components/common/NotFoundMessage';
import Breadcrumb from '../../../containers/navs/Breadcrumb';
import ConversionChartPane from './ConversionChartPane';
import DateRangePicker from '../../../components/common/DateRangePicker/DateRangePicker';
import { CustomDropdownTreeSelect } from '../../../components/common/CustomDropdownTreeSelect';

import {
  chartValidation,
  directionsOptions,
  getFieldErrorsCount,
  prepareDateByPeriod,
  scrollToTarget,
  setDirectionsData
} from '../../../helpers/Utils';
import {
  getDateFrom,
  getAccounts,
  getDirectionsOptions,
  getCurrenciesOptions,
  getActiveCurrencies,
  prepareStatisticParams
} from '../helpers';
import { colourStyles } from './helpers';
import { getOneLevelSelectedData } from '../../operations/OperationsList/helpers';
import { ServiceTypes } from '../../operations/OperationsList/constants';
import { NOTIFICATION_TIMEOUT } from '../../../constants/defaultValues';
import { TIMEZONE_TOOLTIP_TEXT } from '../../../constants/app';
import {
  STATISTICS_PERIOD_OPTIONS,
  STATISTICS_SERVICES_OPTIONS,
  DEFAULT_CONVERSION_CHART_HEIGHT,
  STATISTICS_INITIAL_VALUES,
  StatisticType
} from '../constants';
import { ConversionChartProps, ConversionChartState, ConversionChartSelectOption } from './interface';
import { StatisticsServiceSelectOption } from '../interface';
import { SelectorOption } from '../../../interfaces/app';
import { withTranslation } from 'react-i18next';

class ConversionChart extends Component<ConversionChartProps, ConversionChartState> {
  state: ConversionChartState = {
    modalIsOpen: false,
    chartHeight: DEFAULT_CONVERSION_CHART_HEIGHT,
    accountOptions: [],
    dateChanged: false,
    activeCurrencies: [],
    shouldReset: false
  };
  ChartLayoutRef = React.createRef<HTMLDivElement>();
  ChartRef = React.createRef<HTMLDivElement>();
  setFieldValue;
  resetForm;
  serviceType;

  componentDidMount(): void {
    const {
      merchantIdList,
      destinations,
      accounts,
      currencies,
      isAdmin,
      selectedUserId,
      authUserId,
      getDestinationsAction,
      getCurrenciesAction,
      getPayoutAccountsAction,
      t
    } = this.props;

    this.setChartHeight();
    window.addEventListener('resize', () => {
      this.setChartHeight();
    });

    const isDestinationsExist = destinations && destinations.length;
    const isAccountsExist = accounts && accounts.data;
    const isCurrenciesExist = currencies && currencies.data && currencies.data;

    !isDestinationsExist && getDestinationsAction();

    if (!isAccountsExist && merchantIdList && merchantIdList.length) {
      getPayoutAccountsAction(
        merchantIdList,
        isAdmin && selectedUserId && authUserId !== selectedUserId ? selectedUserId : undefined
      );
    }
    isAccountsExist && this.setState({ accountOptions: getAccounts(this.serviceType, t, accounts.data) });
    !isCurrenciesExist && getCurrenciesAction();

    const activeCurrencies = getActiveCurrencies(
      currencies.data,
      STATISTICS_SERVICES_OPTIONS(t)[0].value.operationType
    );
    activeCurrencies && this.setState({ activeCurrencies });
  }

  componentDidUpdate(prevProps: Readonly<ConversionChartProps>, prevState: Readonly<ConversionChartState>): void {
    const {
      data,
      error,
      merchantIdList,
      selectedUserId,
      isAdmin,
      currencies,
      authUserId,
      accounts,
      getPayoutAccountsAction,
      resetConversionChartAction,
      t
    } = this.props;
    const { accountOptions } = this.state;

    if (error && error !== prevProps.error) {
      NotificationManager.info(error, 'Ошибка', NOTIFICATION_TIMEOUT, null, null, '');
    }

    if (prevProps.data !== data && data) {
      scrollToTarget(this.ChartLayoutRef.current);
      this.setChartHeight();
    }

    if (prevProps.accounts !== accounts) {
      this.setState({ accountOptions: getAccounts(this.serviceType, t, accounts.data) });
    }

    if (prevState.accountOptions !== accountOptions && this.setFieldValue) {
      this.setFieldValue('account', accountOptions);
    }

    if (prevProps.currencies.data !== currencies.data) {
      const activeCurrencies = getActiveCurrencies(
        currencies.data,
        STATISTICS_SERVICES_OPTIONS(t)[0].value.operationType
      );
      activeCurrencies && this.setState({ activeCurrencies });
    }

    if (!isEqual(prevProps.merchantIdList, merchantIdList) || prevProps.selectedUserId !== selectedUserId) {
      resetConversionChartAction();

      if (merchantIdList) {
        getPayoutAccountsAction(
          merchantIdList,
          isAdmin && selectedUserId && authUserId !== selectedUserId ? selectedUserId : undefined
        );
      }
    }

    if (
      prevProps.merchantIdList &&
      prevProps.selectedUserId &&
      merchantIdList &&
      selectedUserId &&
      (!isEqual(prevProps.merchantIdList, merchantIdList) || prevProps.selectedUserId !== selectedUserId)
    ) {
      this.handleResetForm();
    }

    this.state.shouldReset && this.setState({ shouldReset: false });
  }

  componentWillUnmount(): void {
    window.removeEventListener('resize', () => this.setChartHeight());
    this.props.resetConversionChartAction();
  }

  handleResetForm = () => {
    const { currencies, t } = this.props;

    this.resetForm();
    this.setState({ shouldReset: true });

    const activeCurrenciesList = getActiveCurrencies(
      currencies.data,
      STATISTICS_SERVICES_OPTIONS(t)[0].value.operationType
    );
    activeCurrenciesList && this.setState({ activeCurrencies: activeCurrenciesList });
  };

  setChartHeight(): void {
    const chart = this.ChartRef.current;
    const chartLayout = this.ChartLayoutRef.current;
    const chartRect: ClientRect | null = chart ? chart.getBoundingClientRect() : null;
    const chartOffsetTop = chartRect ? chartRect.top : 0;

    const chartLayoutRect: ClientRect | null = chartLayout ? chartLayout.getBoundingClientRect() : null;
    const chartLayoutOffsetTop = chartLayoutRect ? chartLayoutRect.top : 0;

    const chartPaddingBottom = 50;
    const maxHeaderHeight = 90;
    const chartStatusHeaderHeight = chartOffsetTop - chartLayoutOffsetTop;
    const chartHeight = window.innerHeight - maxHeaderHeight - chartStatusHeaderHeight - chartPaddingBottom;

    if (chartHeight < DEFAULT_CONVERSION_CHART_HEIGHT) {
      this.setState({
        chartHeight: Number(chartHeight.toFixed(0))
      });
    }
  }

  onSubmit = (values): void => {
    const {
      getConversionChartDataAction,
      merchantIdList,
      isAdmin,
      authUserId,
      selectedUserId,
      accounts,
      t
    } = this.props;

    const period = values.period ? values.period.value : '';
    const dates = prepareDateByPeriod(values.dateFrom, values.dateTo, period);
    const userId = isAdmin && authUserId !== selectedUserId ? selectedUserId : undefined;
    const requestParams = prepareStatisticParams(values, merchantIdList, userId, accounts.data, t);

    getConversionChartDataAction({
      ...requestParams,
      period,
      status: values.status.map((statusItem: ConversionChartSelectOption) => statusItem.value),
      from: dates.dateFrom,
      to: dates.dateTo
    });
  };

  validate = (values): { [key: string]: string } => chartValidation(values, this.props.t, false);

  toggleModal = (modalIsOpen: boolean) => () => this.setState({ modalIsOpen });

  handleDateChanged = () => this.setState({ dateChanged: true });

  onServiceChange = (service: StatisticsServiceSelectOption) => {
    const { accounts, destinations, currencies, t } = this.props;

    const direction = getDirectionsOptions(service, destinations, t)[0];
    this.setFieldValue && this.setFieldValue('service', service);
    this.setFieldValue && this.setFieldValue('direction', direction);

    this.setState({
      accountOptions: getAccounts(service.value.operationType, t, accounts.data, direction.value.source),
      activeCurrencies: getActiveCurrencies(currencies.data, service.value.operationType)
    });
  };

  render(): ReactNode {
    const { loading, data, period, statuses, match, destinations, error, merchantIdList, t } = this.props;
    const { modalIsOpen, chartHeight, accountOptions, dateChanged, activeCurrencies, shouldReset } = this.state;

    return (
      <Fragment>
        {loading && <Loader />}

        <Row className="flex-shrink-0">
          <Colxx xxs="12">
            <Breadcrumb heading="menu.statistics" match={match} />
            <div className="time-tip  d-none d-lg-inline-block">{t(TIMEZONE_TOOLTIP_TEXT)}</div>
            <Separator className="mb-5" />
          </Colxx>
        </Row>

        {merchantIdList && merchantIdList.length > 0 && (
          <Formik
            initialValues={STATISTICS_INITIAL_VALUES(t)[StatisticType.ConversionChart]}
            onSubmit={this.onSubmit}
            validate={this.validate}
          >
            {({ values, setFieldValue, errors, touched, setFieldTouched, resetForm }) => {
              this.setFieldValue = setFieldValue;
              this.resetForm = resetForm;
              this.serviceType = values.service.value.operationType;

              return (
                <Fragment>
                  <Form>
                    <Card className="mb-4">
                      <CardBody>
                        <Row>
                          <DateRangePicker
                            dateFrom={values.dateFrom}
                            dateTo={values.dateTo}
                            period={values.period.value}
                            errors={errors}
                            touched={touched}
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                            handleDateChanged={this.handleDateChanged}
                          />
                          <Colxx xxs="12" sm="6" lg="4" xl="3">
                            <FormGroup className="has-float-label">
                              <Label className="float-label">{t('conversion-chart.Service')}</Label>
                              <Select
                                id="direction"
                                className="react-select"
                                classNamePrefix="react-select"
                                name="service"
                                value={values.service}
                                onChange={this.onServiceChange}
                                options={STATISTICS_SERVICES_OPTIONS(t)}
                                placeholder=""
                              />
                            </FormGroup>
                          </Colxx>
                          <Colxx xxs="12" sm="6" lg="4" xl="3">
                            <FormGroup className="has-float-label">
                              <Label className="float-label">{t('conversion-chart.Destination')}</Label>
                              <CustomDropdownTreeSelect
                                name="direction"
                                data={directionsOptions(values.service.key, destinations, t)}
                                shouldReset={shouldReset}
                                onChange={(changedValue, selectedValues) => {
                                  const direction = setDirectionsData(selectedValues);
                                  setFieldValue('direction', direction);

                                  this.setState({
                                    accountOptions: getAccounts(
                                      values.service.value.operationType,
                                      t,
                                      this.props.accounts.data,
                                      direction ? direction.value.source : undefined
                                    )
                                  });
                                }}
                                disabled={values.service.key === ServiceTypes.Mc}
                              />
                              {errors.direction && <div className="invalid-feedback d-block">{errors.direction}</div>}
                            </FormGroup>
                          </Colxx>
                          <Colxx xxs="12" sm="6" lg="4" xl="3">
                            <FormGroup className="has-float-label">
                              <Label className="float-label">{t('conversion-chart.Currency')}</Label>
                              <CustomDropdownTreeSelect
                                name="currency"
                                data={getCurrenciesOptions(activeCurrencies, values.service.key, t)}
                                disabled={values.service.key === ServiceTypes.Mc}
                                shouldReset={shouldReset}
                                onChange={(changedValue, selectedValues) =>
                                  setFieldValue('currency', getOneLevelSelectedData(selectedValues))
                                }
                              />
                              {errors.currency && <div className="invalid-feedback d-block">{errors.currency}</div>}
                            </FormGroup>
                          </Colxx>
                          <Colxx xxs="12" sm="6" lg="4" xl="3">
                            <FormGroup className="has-float-label">
                              <Label for="period" className="float-label">
                                {t('conversion-chart.Period')}
                              </Label>
                              <Select
                                id="period"
                                className="react-select"
                                classNamePrefix="react-select"
                                name="period"
                                value={values.period}
                                onChange={(p: SelectorOption) => {
                                  setFieldValue('period', p);
                                  if (!dateChanged) {
                                    setFieldValue('dateFrom', getDateFrom(p.value));
                                    setFieldValue('dateTo', new Date());
                                  }
                                }}
                                options={STATISTICS_PERIOD_OPTIONS(t)}
                                placeholder=""
                              />
                            </FormGroup>
                          </Colxx>
                          <Colxx xxs="12" sm="6" lg="4" xl="3">
                            <FormGroup className="has-float-label">
                              <Label className="float-label">{t('conversion-chart.Merchant Account')}</Label>
                              <CustomDropdownTreeSelect
                                name="account"
                                data={accountOptions}
                                shouldReset={shouldReset}
                                onChange={(changedValue, selectedValues) => setFieldValue('account', selectedValues)}
                                onBlur={() => setFieldTouched('account')}
                                onFocus={() => setFieldTouched('account', false)}
                              />
                              {errors.account && <div className="invalid-feedback d-block">{errors.account}</div>}
                            </FormGroup>
                          </Colxx>
                          <Colxx xxs="12" className="d-flex justify-content-end">
                            <Button disabled={!!getFieldErrorsCount(errors)} color="primary" type="submit">
                              {t('conversion-chart.button.search')}
                            </Button>
                          </Colxx>
                        </Row>
                      </CardBody>
                    </Card>

                    <div ref={this.ChartLayoutRef}>
                      {data && (
                        <Card className="mb-4">
                          <CardBody>
                            <Row>
                              <Colxx xxs="12" className="mb-4">
                                <FormGroup className="has-float-label">
                                  <Label for="status" className="float-label">
                                    {t('conversion-chart.status')}
                                  </Label>
                                  <Select
                                    id="status"
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    name="status"
                                    isMulti
                                    value={values.status}
                                    onChange={(status: ConversionChartSelectOption[]) => {
                                      if (status.length) {
                                        setFieldValue('status', status);
                                        this.onSubmit({ ...values, status });
                                      }
                                    }}
                                    options={STATISTICS_SERVICES_OPTIONS(t)}
                                    placeholder=""
                                    styles={colourStyles}
                                    isClearable={false}
                                  />
                                </FormGroup>
                              </Colxx>
                              <Colxx xxs="12" className="cursor-pointer" onClick={this.toggleModal(true)}>
                                <ConversionChartPane
                                  data={data}
                                  period={period}
                                  statuses={statuses}
                                  chartRef={this.ChartRef}
                                  height={chartHeight}
                                />
                              </Colxx>
                            </Row>
                          </CardBody>
                        </Card>
                      )}
                    </div>
                  </Form>
                </Fragment>
              );
            }}
          </Formik>
        )}

        {(data && !data.length) || (error && <NotFoundMessage />)}
        {!(merchantIdList && merchantIdList.length) && <NotFoundMessage text={t('no_partners_selected')} />}

        <Modal isOpen={modalIsOpen} toggle={this.toggleModal(false)} className="mw-100 chart-modal">
          <ModalHeader toggle={this.toggleModal(false)}>{t('menu.conversion-chart')}</ModalHeader>
          <ModalBody>
            <ConversionChartPane data={data} period={period} statuses={statuses} />
          </ModalBody>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ statistics, settings, authUser, operations }) => ({
  isAdmin: authUser.user.data && authUser.user.data.is_admin ? authUser.user.data.is_admin : false,
  selectedUserId: settings.selectedUser ? settings.selectedUser.id : 0,
  authUserId: authUser.user.data ? authUser.user.data.id : 0,
  data: statistics.conversionChart.data,
  period: statistics.conversionChart.period,
  statuses: statistics.conversionChart.status,
  loading: statistics.conversionChart.loading,
  error: statistics.conversionChart.error,
  merchantIdList: settings.selectedMerchantsId,
  destinations: operations.destinations.data,
  accounts: operations.payoutAccounts,
  currencies: settings.currencies
});

const mapDispatchToProps = {
  getDestinationsAction: getDestinations,
  getCurrenciesAction: getCurrencies,
  getConversionChartDataAction: getConversionChartData,
  getPayoutAccountsAction: getPayoutAccounts,
  resetConversionChartAction: resetConversionChart
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ConversionChart)
);
