export const INITIAL_VALUES = {
  vault_data_synced_at: '',
  update_vault_interval: '',
  is_update_vault_data: false
};

export const MAX_UPDATE_VAULT_INTERVAL = 72;
export const MIN_UPDATE_VAULT_INTERVAL = 1;

export const LIMITS = {
  update_vault_interval: {
    max: MAX_UPDATE_VAULT_INTERVAL,
    min: MIN_UPDATE_VAULT_INTERVAL
  }
};
